import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { AuditLogDto } from "../../types/AuditLog";
import { baseQueryHandleError } from '../index.rtk';


const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}auditLog`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export interface GetAuditLogUserRequest {
    limit: number;
    offset: number;
    userId: number;
}

export const auditLogApi = createApi({
    reducerPath: 'auditLogApi',
    baseQuery: baseQueryHandleError(baseQuery),
    endpoints: (builder) => ({
        getAuditLogs: builder.query<AuditLogDto[], void>({
            query: () => `/`,
        }),
        getAuditLogsActionByUserId: builder.query<AuditLogDto[], GetAuditLogUserRequest>({
            query: (request) => ({
                url: `/action/${request.userId}`,
                params: request
            }),
        }),
        getAuditLogsOnUserByUserId: builder.query<AuditLogDto[], GetAuditLogUserRequest>({
            query: (request) => ({
                url: `/onUser/${request.userId}`,
                params: request
            }),
        })
    }),
})


export const { useGetAuditLogsQuery, useGetAuditLogsActionByUserIdQuery, useGetAuditLogsOnUserByUserIdQuery } = auditLogApi;
