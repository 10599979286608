import {
  faArrowLeft,
  faPieChart,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "./../../assets/logo.png";
import { Link } from "react-router-dom";
import {
  menuBottomListClient,
  menuListClient,
} from "../../data/MenuDashboardClient";
import { useAppSelector } from "../../redux/store";
import { isEmpty } from "../../function/Utils";
import { MenuDashBoardUserBoolState } from "../Context/AppContext";
import { useFetchTokenQuery } from "../../redux/features/tokenSlice";
import { useGetUserWithAllInfoForCustomerQuery } from "../../redux/features/userSlice";
import { logout } from "../../function/logout";

const Menu = () => {
  const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();
  const { data: user, isLoading: loading } =
    useGetUserWithAllInfoForCustomerQuery(userData?.id ?? 0, {
      skip: !userData,
    });

  const menuBoolState = useContext(MenuDashBoardUserBoolState);

  // const handleIconClick = () => {
  //   let icon = document.querySelectorAll(".toggleclassDashAdmin");
  //   for (let e of icon) {
  //     e.classList.toggle("activeIcon");
  //   }
  //   menuBoolState?.setToggleMenu(!menuBoolState.toggleMenu);
  // };

  return (
    <React.Fragment>
      <p
        className={`toggleclassDashAdmin pointer ${
          menuBoolState?.toggleMenu ? "activeIcon" : ""
        }`}
        onClick={() => {
          menuBoolState?.setToggleMenu(!menuBoolState.toggleMenu);
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </p>
      {!!menuBoolState?.toggleMenu && (
        <div className="Menu_container">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
            <div className="flex relative flex-col">
              <p>
                Espace <br /> Client
              </p>
              {!!(!isEmpty(user) && user?.admin) && (
                <Link
                  to={"/DashBoardAdmin/BackOffice"}
                  className="switch_dashboard "
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> Espace Admin
                </Link>
              )}
            </div>
          </div>
          <div className="menuTop_container">
            {menuListClient.map((value, key) => (
              <NavLink
                to={`/DashBoardClient/${value.path}`}
                className="element"
                key={key}
              >
                <div className="flex gap-2 items-center">
                  <FontAwesomeIcon icon={value.icon} />
                  {value.name}
                </div>
              </NavLink>
            ))}
          </div>
          <div className="menuBottom_container">
            {menuBottomListClient.map((value, key) => (
              <div className="itemMenu_container" key={key}>
                <NavLink
                  to={`/DashBoardClient/${value.path}`}
                  className="element"
                >
                  <div className="flex gap-2  items-center">
                    <div className="relative">
                      {!!(
                        value.name === "Données personnelles" &&
                        user?.status === "Doit remplir ses données personnelles"
                      ) && <Reminder />}
                      {user?.statusUser?.find((s) => s.url === value.path) ? (
                        <Reminder />
                      ) : null}
                      <FontAwesomeIcon icon={value.icon} />
                    </div>
                    {value.name}
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
          <div onClick={() => logout()} className="logout cursor-pointer">
            <span className="text-mainColor">Déconnexion</span>{" "}
            <FontAwesomeIcon
              icon={faRightFromBracket}
              className="text-titleColor"
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Menu;

export function Reminder() {
  return <div className="reminder"></div>;
}
