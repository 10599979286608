import { XIcon } from '@heroicons/react/outline';
import { useMemo, useState } from 'react';
import {
    DeepPartial,
    DefaultValues,
    useForm,
    UseFormReturn,
    UseFormWatch,
} from 'react-hook-form';
import countryList from 'react-select-country-list';
import { statusCompany } from '../../../../data/investAccountFormData';
import { useCreateInvestAccountMutation } from '../../../../redux/features/userSlice';
import {
    DocumentUserName,
    FormCreateInvestAccountAndUpdateUserInfoDto,
    FormCreateInvestAccountDto,
    InvestAccountDto,
    UserWithAllInfoDto,
} from '../../../../types/user';
import { useNotificationContext } from '../../../Context/notification-context';
import { TitleDocumentUser } from '../../../DashboardUser/Profile/FormInvest/DocumentsIdentity1';
import { PrimaryButton, WhiteButton } from '../../Buttons';
import AdressCompletion from '../AdressCompletion';
import FieldArray from '../FieldArray';
import FormTemplate from '../FormTemplate';
import HeadlessRadioComponent from '../HeadlessRadioComponent';
import InputComponent from '../InputComponent';
import LabelComponentForm from '../LayoutComponents/LabelComponentForm';
import RadioComponent from '../RadioComponent';
import SelectComponent from '../SelectComponent';
import UploaderSingle from '../UploaderSingle';
import { onSumbitAddInvestAccount } from './OnSubmitHelper';

interface Props {
    user: UserWithAllInfoDto;
    useSubmitBtn: boolean;
    beforeSubmitLogic?: (props: {
        isDirty: boolean;
        isDirtyForm: boolean;
        data:
            | FormCreateInvestAccountAndUpdateUserInfoDto
            | FormCreateInvestAccountDto;
        watch: UseFormWatch<
            | FormCreateInvestAccountAndUpdateUserInfoDto
            | FormCreateInvestAccountDto
        >;
    }) => Promise<boolean>;
    onSubmitSuccess?: (props: { investAccount: InvestAccountDto }) => void;
    children?: (props: {
        disabled: boolean;
        loading: boolean;
        watch: UseFormWatch<
            | FormCreateInvestAccountAndUpdateUserInfoDto
            | FormCreateInvestAccountDto
        >;
        formState: UseFormReturn<
            | FormCreateInvestAccountAndUpdateUserInfoDto
            | FormCreateInvestAccountDto
        >;
        user: UserWithAllInfoDto;
    }) => React.ReactNode;
    defaultValues:
        | DeepPartial<
              | FormCreateInvestAccountAndUpdateUserInfoDto
              | FormCreateInvestAccountDto
          >
        | DefaultValues<
              | FormCreateInvestAccountAndUpdateUserInfoDto
              | FormCreateInvestAccountDto
          >;
}

const AddInvestAccountComponent = ({
    user,
    onSubmitSuccess,
    children,
    beforeSubmitLogic,
    defaultValues,
    useSubmitBtn,
}: Props) => {
    const [loading, setLoading] = useState(false);
    const { showError, showSuccess } = useNotificationContext();

    const formState = useForm<
        FormCreateInvestAccountAndUpdateUserInfoDto | FormCreateInvestAccountDto
    >({
        defaultValues: defaultValues,
    });

    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        formState: { dirtyFields, isValid, isDirty },
    } = formState;

    const [addInvestAccount] = useCreateInvestAccountMutation();

    const optionsCountries = useMemo(() => countryList().getData(), []);

    const [isSizeFileGood, setIsSizeFileGood] = useState(true);

    const onSubmit = async (
        data:
            | FormCreateInvestAccountAndUpdateUserInfoDto
            | FormCreateInvestAccountDto
    ) => {
        setLoading((curr) => !curr);
        if (beforeSubmitLogic) {
            const shouldContinue = await beforeSubmitLogic({
                isDirty,
                isDirtyForm,
                watch,
                data,
            });

            if (!shouldContinue) {
                return;
            }
        }

        await onSumbitAddInvestAccount({
            data,
            showError,
            showSuccess,
            userId: user.id!,
            addInvestAccount,
            onSuccess: ({ investAccount }) => {
                if (onSubmitSuccess) {
                    onSubmitSuccess({ investAccount });
                }
            },
        });
        setLoading((curr) => !curr);
    };

    const watchedValues = {
        bic: watch('bic'),
        iban: watch('iban'),
        physicalOrMoral: watch('physicalOrMoral'),
        documentRib: watch('documentRib'),
        documentKbis: watch('documentKbis'),
        documentStatus: watch('documentStatus'),
        documentDeclarationBeneficial_Owner: watch(
            'documentDeclarationBeneficial_Owner'
        ),
        beneficialOwners: watch('moralAccount.beneficialOwners'),
    };

    const IsValidForm = useMemo((): boolean => {
        if (
            (watchedValues.iban?.length > 0 || watchedValues.bic?.length > 0) &&
            !(watchedValues.documentRib?.length > 0)
        ) {
            return false;
        }
        if (
            watchedValues.documentRib?.length > 0 &&
            (!(watchedValues.iban?.length > 6) ||
                !(watchedValues.bic?.length > 3))
        ) {
            return false;
        }
        if (watchedValues.physicalOrMoral === 'moral') {
            if (!(watchedValues.documentKbis?.length > 0)) {
                return false;
            }
            if (!(watchedValues.documentStatus?.length > 0)) {
                return false;
            }
            if (
                !(watchedValues.documentDeclarationBeneficial_Owner?.length > 0)
            ) {
                return false;
            }
            if (watchedValues.beneficialOwners?.length > 0) {
                for (const beneficial of watchedValues.beneficialOwners) {
                    if (!beneficial.file) {
                        return false;
                    }
                }
            }
        }
        return true;
    }, [
        watchedValues.beneficialOwners,
        watchedValues.bic?.length,
        watchedValues.documentDeclarationBeneficial_Owner?.length,
        watchedValues.documentKbis?.length,
        watchedValues.documentRib?.length,
        watchedValues.documentStatus?.length,
        watchedValues.iban?.length,
        watchedValues.physicalOrMoral,
    ]);

    const isDirtyForm = useMemo(() => {
        if (!isDirty && watch('documentRib')?.length === 0) {
            return false;
        } else {
            if (
                watch('iban')?.length === 0 &&
                watch('bic')?.length === 0 &&
                watch('documentRib')?.length === 0 &&
                watch('physicalOrMoral') === 'physical'
            ) {
                return false;
            } else {
                return true;
            }
        }
    }, [isDirty, watch]);

    const disabled = useMemo(() => {
        return !(isValid && IsValidForm && isSizeFileGood && isDirtyForm);
    }, [isValid, IsValidForm, isSizeFileGood, isDirtyForm]);

    return (
        <FormTemplate
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            isValid={!disabled}
            loading={loading}
            useSubmitBtn={useSubmitBtn}
            className="form_investisseur_container"
        >
            <div className="w-full md:w-11/12 md:mx-auto lg:w-9/12">
                <HeadlessRadioComponent
                    register={register}
                    name={'physicalOrMoral'}
                    watch={watch}
                    className="mx-auto mb-4 md:w-[400px]"
                    control={control}
                    values={[
                        { label: 'Personne physique', value: 'physical' },
                        { label: 'Personne morale', value: 'moral' },
                    ]}
                ></HeadlessRadioComponent>
                {watch('physicalOrMoral') === 'physical' &&
                user?.investAccounts?.find(
                    (i) => i.physicalOrMoral === 'physical'
                ) ? (
                    <div className="my-4 text-justify md:w-10/12 md:mx-auto">
                        Vous ne pouvez pas créer 2 comptes d'investissements
                        physique. Si vous voulez utiliser un autre RIB pour
                        votre compte physique, vous devez modifier le compte
                        physique existant.
                    </div>
                ) : (
                    <>
                        <div className="grid md:grid-cols-2 mt-4 gap-x-2">
                            <InputComponent
                                value={'iban'}
                                required={false}
                                register={register}
                            >
                                {!(watch('iban')?.length > 6) &&
                                watch('documentRib')?.length > 0 ? (
                                    <p className=" text-red-700">
                                        Vous avez uploadé un rib, vous devez
                                        renseigner votre IBAN.
                                    </p>
                                ) : null}
                                <label htmlFor="iban">IBAN </label>
                            </InputComponent>
                            <InputComponent
                                value={'bic'}
                                required={false}
                                register={register}
                            >
                                {!(watch('bic')?.length > 3) &&
                                watch('documentRib')?.length > 0 ? (
                                    <p className=" text-red-700">
                                        Vous avez uploadé un rib, vous devez
                                        renseigner votre BIC.
                                    </p>
                                ) : null}
                                <label htmlFor="iban">BIC</label>
                            </InputComponent>
                        </div>
                        <>
                            <UploaderSingle
                                setSizeGood={setIsSizeFileGood}
                                register={register}
                                value={'documentRib'}
                                watch={watch}
                                setValue={setValue}
                                required={false}
                                button={false}
                                componentEnd={() => {
                                    return (
                                        <p className="italic commentaireInput text-justify text-[0.8rem]">
                                            <span className="font-bold">
                                                Documents acceptés:
                                            </span>{' '}
                                            Documents recto et verso.
                                        </p>
                                    );
                                }}
                            >
                                <h3 className="text-2xl mb-5">
                                    Ajoutez un RIB{' '}
                                    {dirtyFields.iban &&
                                    watch('iban') !== '' &&
                                    !(watch('documentRib')?.length > 0) ? (
                                        <span className=" text-red-500">*</span>
                                    ) : null}
                                </h3>
                                {(watch('iban')?.length > 0 ||
                                    watch('bic')?.length > 0) &&
                                !(watch('documentRib')?.length > 0) ? (
                                    <p className=" text-red-700">
                                        Vous avez renseigné votre IBAN et/ou
                                        votre BIC, vous devez ajouter un RIB.
                                    </p>
                                ) : null}
                            </UploaderSingle>
                        </>
                        {watch('physicalOrMoral') === 'moral' ? (
                            <>
                                <InputComponent
                                    value={'moralAccount.companyName'}
                                    register={register}
                                >
                                    <LabelComponentForm>
                                        Raison sociale
                                        <span className=" text-red-500">*</span>
                                    </LabelComponentForm>
                                </InputComponent>
                                <div className="grid md:gap-x-2 md:grid-cols-2">
                                    <InputComponent
                                        value={
                                            'moralAccount.registrationNumber'
                                        }
                                        register={register}
                                    >
                                        <LabelComponentForm>
                                            N° d'immatriculation
                                            <span className=" text-red-500">
                                                *
                                            </span>
                                        </LabelComponentForm>
                                    </InputComponent>
                                    <div className="">
                                        <SelectComponent
                                            control={control}
                                            setValue={setValue}
                                            watch={watch}
                                            value={'moralAccount.legalStatus'}
                                            register={register}
                                            optionValues={statusCompany.map(
                                                (s) => {
                                                    return {
                                                        value: s,
                                                        label: s,
                                                    };
                                                }
                                            )}
                                        >
                                            <LabelComponentForm>
                                                Statut juridique
                                                <span className=" text-red-500">
                                                    *
                                                </span>
                                            </LabelComponentForm>
                                        </SelectComponent>
                                    </div>
                                </div>
                                {watch('moralAccount.legalStatus') ===
                                'Autre (à préciser)' ? (
                                    <InputComponent
                                        register={register}
                                        value={'moralAccount.legalStatusOther'}
                                    >
                                        <LabelComponentForm>
                                            Précisez le statut juridique
                                            <span className=" text-red-500">
                                                *
                                            </span>
                                        </LabelComponentForm>
                                    </InputComponent>
                                ) : null}
                                <div className="grid grid-cols-2 gap-3 md:grid-cols-[auto,auto] items-center mt-4">
                                    <RadioComponent
                                        register={register}
                                        watch={watch}
                                        name={
                                            'moralAccount.investorCategorization'
                                        }
                                        className={{
                                            container:
                                                'grid justify-center col-span-2 md:col-span-1 center md:grid-cols-2 items-center w-full',
                                            div: '',
                                        }}
                                        values={[
                                            {
                                                value: 'pro',
                                                label: 'Investisseur non-professionnel',
                                            },
                                            {
                                                value: 'noPro',
                                                label: 'Investisseur professionnel',
                                            },
                                        ]}
                                    >
                                        <LabelComponentForm className="col-span-2 md:col-span-1">
                                            Catégorisation de l'investisseur{' '}
                                            <span className="required">*</span>
                                        </LabelComponentForm>
                                    </RadioComponent>
                                </div>
                                <AdressCompletion
                                    control={control}
                                    register={register}
                                    value={'moralAccount.address'}
                                >
                                    <LabelComponentForm>
                                        Adresse{' '}
                                        <span className="required">*</span>
                                    </LabelComponentForm>
                                </AdressCompletion>
                                <InputComponent
                                    value={'moralAccount.addressComplement'}
                                    register={register}
                                    required={false}
                                >
                                    <LabelComponentForm>
                                        Complément d'adresse
                                    </LabelComponentForm>
                                </InputComponent>
                                <h2 className=" font-mainFontFamily text-2xl my-4 text-mainColor font-semibold">
                                    Informations sur les bénéficiaires effectifs
                                </h2>
                                <FieldArray
                                    control={control}
                                    name={'moralAccount.beneficialOwners'}
                                >
                                    {({ fields, append, remove }) => (
                                        <div className="w-full flex flex-col gap-2">
                                            {fields.map((field, index) => (
                                                <div
                                                    key={field.id}
                                                    className="w-full flex-col "
                                                >
                                                    <h3 className=" font-mainFontFamily font-semibold text-xl text-start">
                                                        Bénéficiaire effectif{' '}
                                                        {index + 1}
                                                    </h3>
                                                    <div className="grid gap-2 grid-cols-2">
                                                        <InputComponent
                                                            register={register}
                                                            value={`moralAccount.beneficialOwners.${index}.lastName`}
                                                        >
                                                            <LabelComponentForm>
                                                                Nom{' '}
                                                                <span className=" text-red-500">
                                                                    *
                                                                </span>
                                                            </LabelComponentForm>
                                                        </InputComponent>
                                                        <InputComponent
                                                            register={register}
                                                            value={`moralAccount.beneficialOwners.${index}.firstName`}
                                                        >
                                                            <LabelComponentForm>
                                                                Prénom{' '}
                                                                <span className=" text-red-500">
                                                                    *
                                                                </span>
                                                            </LabelComponentForm>
                                                        </InputComponent>
                                                        <div>
                                                            <SelectComponent
                                                                control={
                                                                    control
                                                                }
                                                                optionValues={
                                                                    optionsCountries
                                                                }
                                                                register={
                                                                    register
                                                                }
                                                                value={`moralAccount.beneficialOwners.${index}.nationality`}
                                                            >
                                                                <LabelComponentForm>
                                                                    Nationalité{' '}
                                                                    <span className=" text-red-500">
                                                                        *
                                                                    </span>
                                                                </LabelComponentForm>
                                                            </SelectComponent>
                                                        </div>
                                                        <InputComponent
                                                            register={register}
                                                            value={`moralAccount.beneficialOwners.${index}.proportionShareholder`}
                                                            type="number"
                                                        >
                                                            <LabelComponentForm>
                                                                Pourcentage
                                                                détenu{' '}
                                                                <span className=" text-red-500">
                                                                    *
                                                                </span>
                                                            </LabelComponentForm>
                                                        </InputComponent>
                                                    </div>
                                                    <div className="">
                                                        <HeadlessRadioComponent
                                                            register={register}
                                                            name={`moralAccount.beneficialOwners.${index}.name`}
                                                            watch={watch}
                                                            className="mx-auto mt-2 mb-4 max-w-sm"
                                                            control={control}
                                                            values={[
                                                                {
                                                                    label: "Carte Nationale d'Identité",
                                                                    value: DocumentUserName.IDENTITY_CARD,
                                                                },
                                                                {
                                                                    label: 'Passeport',
                                                                    value: DocumentUserName.PASSPORT,
                                                                },
                                                            ]}
                                                        >
                                                            <h3 className="">
                                                                Choisisser une
                                                                pièce d'identité{' '}
                                                                <span className="required">
                                                                    *
                                                                </span>
                                                            </h3>
                                                        </HeadlessRadioComponent>
                                                        {watch(
                                                            `moralAccount.beneficialOwners.${index}.name`
                                                        ) ? (
                                                            <UploaderSingle
                                                                setSizeGood={
                                                                    setIsSizeFileGood
                                                                }
                                                                register={
                                                                    register
                                                                }
                                                                value={`moralAccount.beneficialOwners.${index}.file`}
                                                                watch={watch}
                                                                setValue={
                                                                    setValue
                                                                }
                                                                button={false}
                                                                componentEnd={() => {
                                                                    return (
                                                                        <p className="italic commentaireInput text-justify text-[0.8rem]">
                                                                            <span className="font-bold">
                                                                                Documents
                                                                                acceptés:
                                                                            </span>{' '}
                                                                            Documents
                                                                            recto
                                                                            et
                                                                            verso.
                                                                        </p>
                                                                    );
                                                                }}
                                                            >
                                                                <h3 className="text-2xl mb-5">
                                                                    {TitleDocumentUser(
                                                                        watch(
                                                                            `moralAccount.beneficialOwners.${index}.name`
                                                                        )
                                                                    )}{' '}
                                                                    pour{' '}
                                                                    {watch(
                                                                        `moralAccount.beneficialOwners.${index}.lastName`
                                                                    )}{' '}
                                                                    {watch(
                                                                        `moralAccount.beneficialOwners.${index}.firstName`
                                                                    )}
                                                                    <span className="required">
                                                                        *
                                                                    </span>
                                                                </h3>
                                                            </UploaderSingle>
                                                        ) : null}
                                                    </div>

                                                    <div className="w-full flex justify-center items-center gap-2">
                                                        <WhiteButton
                                                            onClick={() =>
                                                                remove(index)
                                                            }
                                                        >
                                                            <XIcon className="w-4 h-4" />
                                                            <p className="ml-3">
                                                                Supprimer ce
                                                                bénéficiaire
                                                            </p>
                                                        </WhiteButton>
                                                    </div>
                                                </div>
                                            ))}
                                            <PrimaryButton
                                                className=" mx-auto mt-3 mb-2"
                                                onClick={() =>
                                                    append({} as any)
                                                }
                                            >
                                                Ajouter un bénéficiaire
                                            </PrimaryButton>
                                        </div>
                                    )}
                                </FieldArray>
                                <div className="w-full mt-3 flex flex-col">
                                    <h2 className=" font-mainFontFamily text-2xl text-mainColor font-semibold">
                                        Documents
                                    </h2>
                                    <UploaderSingle
                                        setSizeGood={setIsSizeFileGood}
                                        register={register}
                                        value={'documentStatus'}
                                        watch={watch}
                                        setValue={setValue}
                                        button={false}
                                        componentEnd={() => {
                                            return (
                                                <p className="italic commentaireInput text-justify text-[0.8rem]">
                                                    <span className="font-bold">
                                                        Documents acceptés:
                                                    </span>{' '}
                                                    Documents recto et verso.
                                                </p>
                                            );
                                        }}
                                    >
                                        <h3 className="text-2xl mb-5">
                                            Statuts{' '}
                                            <span className=" text-red-500">
                                                *
                                            </span>
                                        </h3>
                                    </UploaderSingle>

                                    <UploaderSingle
                                        setSizeGood={setIsSizeFileGood}
                                        register={register}
                                        value={'documentKbis'}
                                        watch={watch}
                                        setValue={setValue}
                                        button={false}
                                        componentEnd={() => {
                                            return (
                                                <p className="italic commentaireInput text-justify text-[0.8rem]">
                                                    <span className="font-bold">
                                                        Documents acceptés:
                                                    </span>{' '}
                                                    Documents recto et verso.
                                                </p>
                                            );
                                        }}
                                    >
                                        <h3 className="text-2xl mb-5">
                                            Kbis{' '}
                                            <span className=" text-red-500">
                                                *
                                            </span>
                                        </h3>
                                    </UploaderSingle>

                                    <UploaderSingle
                                        setSizeGood={setIsSizeFileGood}
                                        register={register}
                                        value={
                                            'documentDeclarationBeneficial_Owner'
                                        }
                                        watch={watch}
                                        setValue={setValue}
                                        button={false}
                                        componentEnd={() => {
                                            return (
                                                <p className="italic commentaireInput text-justify text-[0.8rem]">
                                                    <span className="font-bold">
                                                        Documents acceptés:
                                                    </span>{' '}
                                                    Documents recto et verso.
                                                </p>
                                            );
                                        }}
                                    >
                                        <h3 className="text-2xl mb-5">
                                            Déclaration de bénéficiaire
                                            effectifs{' '}
                                            <span className=" text-red-500">
                                                *
                                            </span>
                                        </h3>
                                    </UploaderSingle>
                                </div>
                            </>
                        ) : null}
                    </>
                )}
            </div>
            {children
                ? children({ disabled, formState, watch, loading, user })
                : null}
        </FormTemplate>
    );
};

export default AddInvestAccountComponent;
