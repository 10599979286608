import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import {
    FieldValues,
    Path,
    UseFormRegister,
    UseFormWatch,
    useForm,
} from 'react-hook-form';
import {
    useEditProjectImpactsMutation,
    useGetProjectAllInfoForFormDataQuery,
    useGetProjectByIdQuery,
    useGetProjectImpactsQuery,
    useGetProjectSectorsQuery,
} from '../../../redux/features/projectSlice';
import { useGetProjectTransactionsQuery } from '../../../redux/features/transactionSlice';
import {
    TypeVariable,
    UpdateProjectAllImpactsDto,
} from '../../../types/project';
import {
    TransactionStatus,
    TypeTransaction,
} from '../../../types/transactions';
import { PrimaryButton } from '../../commun/Buttons';
import FieldArray from '../../commun/formComponent/FieldArray';
import InputComponent from '../../commun/formComponent/InputComponent';
import RadioComponent from '../../commun/formComponent/RadioComponent';
import RemoveFieldArrayComponent from '../../commun/formComponent/RemoveFieldArrayComponent';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import Loading from '../../commun/Loading';
import { useNotificationContext } from '../../Context/notification-context';
import AddProjectSubSector from './AddProjectSubSector';

export function Impact({ projectId }: { projectId: number }) {
    const { showError, showSuccess } = useNotificationContext();

    const { data: projectImpact, isLoading: isLoadingImpact } =
        useGetProjectImpactsQuery(projectId, {
            skip: projectId === undefined,
        });

    const {
        data: project,
        isLoading: isLoadingProject,
        isFetching: isFetchingProject,
        refetch: refetchProject,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const { data: formData, isLoading: isLoadingFormData } =
        useGetProjectAllInfoForFormDataQuery();

    const { seniorityLevel, projectMarketTypology } = formData ?? {};

    const { data: projectSectors } = useGetProjectSectorsQuery();

    const [editProjectImpact, { isLoading: isLoadingImpactEdit }] =
        useEditProjectImpactsMutation();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        reset,
        control,
    } = useForm<UpdateProjectAllImpactsDto>();

    useEffect(() => {
        if (projectImpact && project && projectSectors) {
            reset({
                projectId: project.id,
                projectImpactGeneral: {
                    ...projectImpact.projectImpactGeneral,
                    projectId: project.id,
                    fondsId: project.fondsId,
                    sectorId: projectImpact?.projectImpactGeneral?.sectorId
                        ? projectImpact?.projectImpactGeneral?.sectorId
                        : project.fondsId === 1
                          ? projectSectors?.find(
                                (s) => s.value === 'Immobilier'
                            )?.id
                          : undefined,
                    subSectorId:
                        projectImpact?.projectImpactGeneral?.subSectorId,
                },
                projectImpactMarketTypology:
                    projectImpact.projectImpactMarketTypology
                        ? projectImpact.projectImpactMarketTypology
                              .map((p) => {
                                  return p.marketTypologyImpacts?.map((m) => {
                                      return {
                                          id: m?.result?.id
                                              ? m?.result?.id
                                              : undefined,
                                          marketTypologyImpactId: m?.id!,
                                          value: m?.result?.value,
                                          projectId: project.id,
                                      };
                                  });
                              })
                              .flat()
                        : [],
                projectImpactAccordingFunds:
                    projectImpact.projectImpactAccordingFunds?.map((p) => {
                        return {
                            impactVariableId: p.id!,
                            id: p.impactValue?.id
                                ? p.impactValue?.id
                                : undefined,
                            value:
                                p.typeVariable === TypeVariable.boolean
                                    ? p.impactValue?.value == undefined
                                        ? undefined
                                        : p.impactValue?.value
                                    : p.impactValue?.value,
                            projectId: project.id,
                        };
                    }),
            });
        }
    }, [projectImpact, project, projectSectors]);

    const onSubmit = async (data: UpdateProjectAllImpactsDto) => {
        await editProjectImpact({
            projectId: data.projectId,
            projectImpactGeneral: data.projectImpactGeneral,
            projectImpactMarketTypology:
                data.projectImpactMarketTypology?.filter((v) => v.value),
            projectImpactAccordingFunds:
                data.projectImpactAccordingFunds?.filter((v) => v.value),
            projectImpactSectors: [],
        })
            .unwrap()
            .then(async (res) => {
                showSuccess('Edited', 'Project impact edited successfully');
            })
            .catch((err) => {
                showError('Error', 'Error editing project impact');
            });
    };

    const {
        data: transactions,
        isLoading: isLoadingTransactionsProject,
        isFetching: isFetchingTransactionsProject,
        refetch,
    } = useGetProjectTransactionsQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    let cumulativeIndex2 = 0;

    const [openAddProjectSubSector, setOpenAddProjectSubSector] =
        useState<boolean>(false);

    return (
        <>
            {isLoadingImpact || isLoadingProject || isLoadingFormData ? (
                <Loading />
            ) : (
                <>
                    {openAddProjectSubSector ? (
                        <AddProjectSubSector
                            setOpen={setOpenAddProjectSubSector}
                            open={openAddProjectSubSector}
                        />
                    ) : null}
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={`formTemplateSingUp`}
                        style={{ width: `92%` }}
                    >
                        <div
                            className={`w-full mx-auto grid grid-cols-1 lg:grid-cols-2
                lg:gap-x-6
            `}
                        >
                            <TitleComponentForm>
                                DATA IMPACTS GENERAL
                            </TitleComponentForm>
                            <InputComponent
                                register={register}
                                required={false}
                                value="projectImpactGeneral.equityCapital"
                            >
                                <LabelComponentForm>
                                    Fonds propre{' '}
                                    {project &&
                                    project.projectInfo?.fundingType?.includes(
                                        'corporate'
                                    )
                                        ? 'société'
                                        : 'projet'}
                                </LabelComponentForm>
                            </InputComponent>
                            <InputComponent
                                register={register}
                                required={false}
                                value="projectImpactGeneral.equityCrowdfunding"
                            >
                                <LabelComponentForm>
                                    Levé en financement participatif
                                </LabelComponentForm>
                            </InputComponent>

                            <div>
                                <p>
                                    Notre impact est de{' '}
                                    {transactions &&
                                        watch(
                                            'projectImpactGeneral.equityCapital'
                                        ) &&
                                        watch(
                                            'projectImpactGeneral.equityCrowdfunding'
                                        ) &&
                                        (
                                            (transactions
                                                .filter(
                                                    (t) =>
                                                        t.type ===
                                                            TypeTransaction.cashProject &&
                                                        t.status ===
                                                            TransactionStatus.VALIDATED
                                                )
                                                .reduce((sum, curr) => {
                                                    return sum + curr.amount;
                                                }, 0) /
                                                ((parseFloat(
                                                    watch(
                                                        'projectImpactGeneral.equityCapital'
                                                    ) as unknown as string
                                                ) || 0) +
                                                    (parseFloat(
                                                        watch(
                                                            'projectImpactGeneral.equityCrowdfunding'
                                                        ) as unknown as string
                                                    ) || 0))) *
                                            100
                                        ).toFixed(3)}{' '}
                                    % du projet
                                </p>
                            </div>

                            <SelectComponent
                                register={register}
                                control={control}
                                container={true}
                                setValue={setValue}
                                getValues={getValues}
                                value={'projectImpactGeneral.sectorId'}
                                optionValues={
                                    projectSectors?.map((s) => {
                                        return { value: s.id, label: s.value };
                                    }) || []
                                }
                            >
                                <LabelComponentForm>
                                    Secteur <span className="required">*</span>
                                </LabelComponentForm>
                            </SelectComponent>

                            <SelectComponent
                                register={register}
                                control={control}
                                container={true}
                                setValue={setValue}
                                required={false}
                                getValues={getValues}
                                value={'projectImpactGeneral.subSectorId'}
                                optionValues={
                                    projectSectors
                                        ?.find(
                                            (s) =>
                                                s.id ===
                                                watch(
                                                    'projectImpactGeneral.sectorId'
                                                )
                                        )
                                        ?.subSectors?.map((s) => {
                                            return {
                                                value: s.id,
                                                label: s.value,
                                            };
                                        }) || []
                                }
                            >
                                <div className="w-full grid md:grid-cols-2 mb-2">
                                    <LabelComponentForm>
                                        Sous secteur
                                    </LabelComponentForm>
                                    <PrimaryButton
                                        onClick={() =>
                                            setOpenAddProjectSubSector(true)
                                        }
                                        className=" mr-2 place-self-end"
                                    >
                                        Add new
                                    </PrimaryButton>
                                </div>
                            </SelectComponent>

                            {project?.projectInfo?.projectMarketTypology ? (
                                <div className="">
                                    <LabelComponentForm>
                                        Typologie de marché du projet
                                    </LabelComponentForm>
                                    <div className="flex gap-2 items-center flex-wrap">
                                        {project?.projectInfo.projectMarketTypology.map(
                                            (p, index) => (
                                                <p
                                                    key={index}
                                                    className="flex items-center gap-1"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        className="text-mainColor h-2 w-2"
                                                    />{' '}
                                                    {p.value}
                                                </p>
                                            )
                                        )}
                                    </div>
                                </div>
                            ) : null}

                            {projectImpact?.projectImpactMarketTypology &&
                                projectImpact?.projectImpactMarketTypology?.map(
                                    (p, index) => (
                                        <div
                                            key={index}
                                            className="mt-2 md:col-span-2"
                                        >
                                            <TitleComponentForm>
                                                Impact {p.value}
                                            </TitleComponentForm>
                                            {p.marketTypologyImpacts.map(
                                                (m, index2) => (
                                                    <DisplayFormAccordingTypeVariable
                                                        register={register}
                                                        name={`projectImpactMarketTypology.${
                                                            index2 +
                                                            cumulativeIndex2
                                                        }.value`}
                                                        form={m}
                                                        watch={watch}
                                                        key={index2}
                                                    />
                                                )
                                            )}
                                            <p className=" hidden absolute">
                                                {
                                                    (cumulativeIndex2 +=
                                                        p.marketTypologyImpacts
                                                            .length)
                                                }
                                            </p>
                                        </div>
                                    )
                                )}

                            {projectImpact?.projectImpactAccordingFunds ? (
                                <div className="mt-2 md:col-span-2">
                                    {projectImpact?.projectImpactAccordingFunds?.map(
                                        (p, index) => (
                                            <div key={index}>
                                                {p.title ? (
                                                    <TitleComponentForm>
                                                        {p.title}
                                                    </TitleComponentForm>
                                                ) : null}
                                                <DisplayFormAccordingTypeVariable
                                                    register={register}
                                                    name={`projectImpactAccordingFunds.${index}.value`}
                                                    form={{
                                                        variable: p.variable,
                                                        typeVariable:
                                                            p.typeVariable,
                                                        comment: p.comment,
                                                    }}
                                                    watch={watch}
                                                />
                                            </div>
                                        )
                                    )}
                                </div>
                            ) : null}

                            <InputComponent
                                register={register}
                                value={'projectImpactGeneral.E_score'}
                                type="number"
                                required={false}
                                className={{
                                    containerAbove:
                                        '!grid !grid-cols-2 md:col-span-2',
                                    input: ' self-center',
                                }}
                            >
                                <LabelComponentForm className=" self-center">
                                    E-score
                                </LabelComponentForm>
                            </InputComponent>

                            <InputComponent
                                register={register}
                                value={'projectImpactGeneral.S_score'}
                                type="number"
                                required={false}
                                className={{
                                    containerAbove:
                                        '!grid !grid-cols-2 md:col-span-2',
                                    input: ' self-center',
                                }}
                            >
                                <LabelComponentForm className=" self-center">
                                    S-score
                                </LabelComponentForm>
                            </InputComponent>

                            <InputComponent
                                register={register}
                                value={'projectImpactGeneral.G_score'}
                                type="number"
                                required={false}
                                className={{
                                    containerAbove:
                                        '!grid !grid-cols-2 md:col-span-2 !mb-4',
                                    input: ' self-center',
                                }}
                            >
                                <LabelComponentForm className=" self-center">
                                    G-score
                                </LabelComponentForm>
                            </InputComponent>

                            <h3 className="text-lg font-semibold">
                                Commentaire environnement
                            </h3>
                            <FieldArray
                                control={control}
                                name="projectImpactGeneral.environmentalComment"
                            >
                                {({ fields, append, remove }) => (
                                    <>
                                        <div className="flex flex-col items-center w-full space-y-2 mb-2">
                                            {fields.map((field, index) => (
                                                <div
                                                    key={field.id}
                                                    className="grid w-full relative"
                                                >
                                                    <InputComponent
                                                        register={register}
                                                        value={`projectImpactGeneral.environmentalComment.${index}.value`}

                                                        // className={{ input: "row-span-1 col-span-1" }}
                                                    >
                                                        <h3 className="text-left">
                                                            Bullet point{' '}
                                                            {index + 1}
                                                        </h3>
                                                    </InputComponent>

                                                    <RemoveFieldArrayComponent
                                                        remove={remove}
                                                        index={index}
                                                    />
                                                </div>
                                            ))}
                                            <PrimaryButton
                                                className="  mb-2"
                                                onClick={() =>
                                                    append({
                                                        value: '',
                                                    })
                                                }
                                            >
                                                Ajouter
                                            </PrimaryButton>
                                        </div>
                                    </>
                                )}
                            </FieldArray>

                            <h3 className="text-lg font-semibold">
                                Commentaire social
                            </h3>
                            <FieldArray
                                control={control}
                                name="projectImpactGeneral.socialComment"
                            >
                                {({ fields, append, remove }) => (
                                    <>
                                        <div className="flex flex-col items-center w-full space-y-2 mb-2">
                                            {fields.map((field, index) => (
                                                <div
                                                    key={field.id}
                                                    className="grid w-full relative"
                                                >
                                                    <InputComponent
                                                        register={register}
                                                        value={`projectImpactGeneral.socialComment.${index}.value`}

                                                        // className={{ input: "row-span-1 col-span-1" }}
                                                    >
                                                        <h3 className="text-left">
                                                            Bullet point{' '}
                                                            {index + 1}
                                                        </h3>
                                                    </InputComponent>

                                                    <RemoveFieldArrayComponent
                                                        remove={remove}
                                                        index={index}
                                                    />
                                                </div>
                                            ))}
                                            <PrimaryButton
                                                className="  mb-2"
                                                onClick={() =>
                                                    append({
                                                        value: '',
                                                    })
                                                }
                                            >
                                                Ajouter
                                            </PrimaryButton>
                                        </div>
                                    </>
                                )}
                            </FieldArray>

                            <h3 className="text-lg font-semibold">
                                Commentaire économique
                            </h3>
                            <FieldArray
                                control={control}
                                name="projectImpactGeneral.economicalComment"
                            >
                                {({ fields, append, remove }) => (
                                    <>
                                        <div className="flex flex-col items-center w-full space-y-2 mb-2">
                                            {fields.map((field, index) => (
                                                <div
                                                    key={field.id}
                                                    className="grid w-full relative"
                                                >
                                                    <InputComponent
                                                        register={register}
                                                        value={`projectImpactGeneral.economicalComment.${index}.value`}

                                                        // className={{ input: "row-span-1 col-span-1" }}
                                                    >
                                                        <h3 className="text-left">
                                                            Bullet point{' '}
                                                            {index + 1}
                                                        </h3>
                                                    </InputComponent>

                                                    <RemoveFieldArrayComponent
                                                        remove={remove}
                                                        index={index}
                                                    />
                                                </div>
                                            ))}
                                            <PrimaryButton
                                                className="  mb-2"
                                                onClick={() =>
                                                    append({
                                                        value: '',
                                                    })
                                                }
                                            >
                                                Ajouter
                                            </PrimaryButton>
                                        </div>
                                    </>
                                )}
                            </FieldArray>
                            <div className="mt-5 md:col-span-2 self-center mx-auto flex gap-3 w-full max-w-lg">
                                <PrimaryButton
                                    type="submit"
                                    className="w-full"
                                    loading={isLoadingImpactEdit}
                                >
                                    Edit
                                </PrimaryButton>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </>
    );
}

export interface FormVariable {
    variable: string;
    typeVariable: string;
    title?: string;
    comment?: string;
}

export function DisplayFormAccordingTypeVariable<T extends FieldValues>({
    register,
    watch,
    name,
    form,
}: {
    register: UseFormRegister<T>;
    watch?: UseFormWatch<T>;
    name: Path<T>;
    form: FormVariable;
}) {
    const displayForm = (form: FormVariable) => {
        switch (form.typeVariable) {
            case TypeVariable.number:
                return (
                    <InputComponent
                        required={false}
                        className={{
                            containerAbove: '!grid !grid-cols-2',
                            input: ' self-center',
                        }}
                        register={register}
                        value={name}
                        type="number"
                        commentaire={form.comment}
                    >
                        <LabelComponentForm className=" self-center">
                            {form.variable}
                        </LabelComponentForm>
                    </InputComponent>
                );
            case TypeVariable.string:
                return (
                    <InputComponent
                        required={false}
                        className={{
                            containerAbove: '!grid !grid-cols-2',
                            input: ' self-center',
                        }}
                        register={register}
                        value={name}
                        type="text"
                        commentaire={form.comment}
                    >
                        <LabelComponentForm className=" self-center">
                            {form.variable}
                        </LabelComponentForm>
                    </InputComponent>
                );
            case TypeVariable.boolean:
                return (
                    <RadioComponent
                        register={register}
                        required={false}
                        watch={watch!}
                        name={name}
                        className={{
                            container: '!grid !grid-cols-2',
                            div: ' self-center',
                        }}
                        values={[
                            {
                                label: 'Oui',
                                value: 'true',
                            },
                            {
                                label: 'Non',
                                value: 'false',
                            },
                        ]}
                    >
                        <LabelComponentForm>{form.variable}</LabelComponentForm>
                    </RadioComponent>
                );
        }
    };

    return (
        <div
            className={`grid ${
                TypeVariable.boolean === form.typeVariable ? 'grid-cols-2' : ''
            }`}
        >
            {form.title ? (
                <h3 className=" font-mainFontFamily text-lg text-secondColor">
                    {form.title}
                </h3>
            ) : null}
            {displayForm(form)}
        </div>
    );
}
