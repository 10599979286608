import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { FeeValueDto, GetByDateBetweenByFundsDto, GetFeesMetrics, GetFundsInfoForDashboardUserResponse, OverviewAdminDto } from '../../types/shareApi';
import { GetOverViewAdminInfoProps } from './shareApi';
import { baseQueryHandleError } from '../index.rtk';


const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}shareApi`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export interface GetLastDateComputedPerFonds {
    fondsId: number
    lastDateComputed: Date
    fondsName: string;
}

export interface DeleteAllShareApiBetweenDates {
    fondsIds: number[],
    dateStart: Date,
    dateEnd: Date
}

export interface GetUserValorisationRequest {
    userId: number;
}

export interface GetUserValorisationResponse {
    data: IUserValorisation[];
}

export interface IUserValorisation {
    value: number;
    date: Date;
}

export const shareApi = createApi({
    reducerPath: 'shareApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: ['ShareApi'],
    keepUnusedDataFor: 300,
    endpoints: (builder) => ({
        getOverViewAdminInfo: builder.query<OverviewAdminDto, GetOverViewAdminInfoProps>({
            query: (payload) => ({
                url: `/overviewAdmin`,
                method: 'GET',
                params: {
                    date: payload.date,
                    applyFees: payload.applyFees
                }
            }),
            providesTags: ['ShareApi']
        }),
        getDashbordUserInfo: builder.query<GetFundsInfoForDashboardUserResponse, void>({
            query: () => '/dashboardUser',

            providesTags: ['ShareApi']

        }),
        getFeesMetrics: builder.query<FeeValueDto[], GetFeesMetrics>({
            query: (payload) => ({
                url: `/getFeesMetrics`,
                method: 'GET',
                params: payload
            }),
            providesTags: ['ShareApi']

        }),
        getFeesMetricsByFeeId: builder.query<FeeValueDto[], {
            request: GetFeesMetrics,
            feeId: number
        }>({
            query: (payload) => ({
                url: `/getFeesMetrics/${payload.feeId}`,
                method: 'GET',
                params: payload.request,
            }),
            providesTags: ['ShareApi']

        }),
        getIrrFondsId: builder.query<number, number>({
            query: (payload) => ({
                url: `/getIrrFondsId/${payload}`,
                method: 'GET',
            }),


        }),

        getCashByFondsIdBetweenDates: builder.query<FeeValueDto[],
            GetByDateBetweenByFundsDto>({
                query: (payload) => ({
                    url: `/cash`,
                    method: 'GET',
                    params: {
                        dateEnd: payload.dateEnd,
                        fondsId: payload.fondsId,
                        dateStart: payload.dateStart
                    }
                }),
                providesTags: ['ShareApi']

            }),
        getLiquidityForDashboardAdmin: builder.query<FeeValueDto[], Date>({
            query: (date) => ({
                url: `/cashDashboardAdmin`,
                method: 'GET',
                params: {
                    date: date,

                }
            }),
            providesTags: ['ShareApi']

        }),
        getLatestDateComputed: builder.query<GetLastDateComputedPerFonds[], void>({
            query: () => '/latestDateComputed',
            transformResponse: (resonse: GetLastDateComputedPerFonds[]) => {
                return resonse.filter((r) => r !== null).map((item) => {
                    return {
                        ...item,
                        lastDateComputed: new Date(item.lastDateComputed)
                    }
                })
            },
            providesTags: ['ShareApi']

        }),
        deleteAllBetweenDates: builder.mutation<void, DeleteAllShareApiBetweenDates>({
            query: (payload) => ({
                url: `/deleteAllBetweenDates/${JSON.stringify(payload)}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ShareApi']

        }),
        getUserHistoricalValorisation: builder.query<GetUserValorisationResponse, GetUserValorisationRequest>({
            query: (payload) => ({
                url: `/userValorisation/${payload.userId}`,
                method: 'GET',

            }),
            providesTags: ['ShareApi']


        })

    })
})

export const { useGetOverViewAdminInfoQuery, useGetCashByFondsIdBetweenDatesQuery, useGetLatestDateComputedQuery, useDeleteAllBetweenDatesMutation,
    useGetDashbordUserInfoQuery, useGetFeesMetricsQuery, useGetIrrFondsIdQuery, useGetFeesMetricsByFeeIdQuery
    , useGetLiquidityForDashboardAdminQuery, useGetUserHistoricalValorisationQuery } = shareApi;
