import { fondList } from "../../data/fondList";
import { useState, useEffect } from "react";
import CategoriesFond from "./CategoriesFond";
import FondItem from "./FondItem";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import Page from "../../function/page_info";

function FondPresentation() {
  const pageData = useAppSelector(
    (state) => state.pageMeasurement.measurements
  );

  const [activeFond, setActiveFond] = useState<number>(2);
  const [activeCategoryFond, setActiveCategoryFond] = useState<string>("");

  const categories = fondList.reduce(
    (acc: string[], fond) =>
      acc.includes(fond.category) ? acc : acc.concat(fond.category),
    []
  );
  // console.log(activeCategoryFond);

  useEffect(() => {
    let diffNumero = 2 - activeFond;
    diffNumero = diffNumero * 70;
    // setDiffNumero(3-activeFond);
    // setDiffNumero(diffNumero*70);
    let fond_item = document.getElementsByClassName(
      "fond_item"
    ) as HTMLCollectionOf<HTMLElement>;
    if (activeCategoryFond == "") {
      for (let value of fond_item) {
        value.style.transform = `translateX(${diffNumero}%)`;
        value.style.margin = "0px -35px";
      }
    } else {
      for (let value of fond_item) {
        value.style.margin = "0px 20px";
        value.style.transform = `translateX(0px))`; // value.style.transform = `translateX(0px))`;
      }
    }
  }, [activeFond, activeCategoryFond]);

  useEffect(() => {
    Page.scrollFunction("fond_container_id", "apparitionFond", true);
  }, [pageData.scrollPage]);

  return (
    <React.Fragment>
      <CategoriesFond
        categories={categories}
        setActiveCategoryFond={setActiveCategoryFond}
        activeCategoryFond={activeCategoryFond}
      />
      <div className="fond_container" id="fond_container_id">
        {!!(activeCategoryFond == "") && (
          <React.Fragment>
            <div
              className="chevronRight chevron pointer"
              onClick={() => {
                activeFond <= 2 && setActiveFond(activeFond + 1);
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} size="2x" />
            </div>
            <div
              className="chevronLeft chevron pointer"
              onClick={() => {
                activeFond >= 2 && setActiveFond(activeFond - 1);
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="2x" />
            </div>
          </React.Fragment>
        )}
        <ul className="fond_list">
          {fondList
            .filter((fond) => fond.category.includes(activeCategoryFond))
            .sort((a, b) => a.oderHomePage - b.oderHomePage)
            .map(
              ({
                id,
                cover,
                name,
                rendement,
                liquidite,
                social,
                environnemental,
                path,
                oderHomePage,
              }) => (
                <React.Fragment key={id}>
                  <FondItem
                    cover={cover}
                    name={name}
                    rendement={rendement}
                    liquidite={liquidite}
                    social={social}
                    environnemental={environnemental}
                    activeFond={activeFond}
                    setActiveFond={setActiveFond}
                    numero={oderHomePage}
                    activeCategoryFond={activeCategoryFond}
                    path={path}
                  />
                </React.Fragment>
              )
            )}
        </ul>
      </div>
      <div className="discoverNosFond">
        <Link to="/VueEnsemble">Découvrir nos fonds</Link>
      </div>
    </React.Fragment>
  );
}

export default FondPresentation;
