import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { number } from "yup";
import { compareTwoDate, formatDate, isDateClosed } from "../../function/Utils";
import { GetSharesByDateBetweenLimitDto, ShareDto } from "../../types/Share";



interface shareState {
    sharesAll: ShareDto[];
    sharesUser: ShareDto[];
    loading: boolean;
    errors: any;
}

const initialState: shareState = {
    sharesAll: [],
    sharesUser: [],
    loading: false,
    errors: null,
};

// actions are processes that get data from backend
export const getSharesAll = createAsyncThunk<
    ShareDto[]
>("Shares/getSharesAll", async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}shares`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

interface arg {
    dates: Date[];
    userId: number;
}

export const getSharesUser = createAsyncThunk<ShareDto[], arg>("Shares/getSharesUser", async (arg, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}shares/${arg.userId}`,
            withCredentials: true,
            params: {
                dates: arg.dates,
            },
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});



// export const editShares = createAsyncThunk<
//     ShareDto[],
//     ShareDto[]
// >("Shares/editShares", async (data, thunkApi) => {
//     try {
//         const response: AxiosResponse<any, any> = await axios({
//             method: "put",
//             url: `${import.meta.env.VITE_API_URL}shares/array`,
//             data: data,
//             withCredentials: true,
//         });
//         return response.data;
//     } catch (error) {
//         return thunkApi.rejectWithValue(error);
//     }
// });

// export const updateOrCreateShares = createAsyncThunk<
//     ShareDto[],
//     ShareDto[]
// >("Shares/updateOrCreateShares", async (data, thunkApi) => {
//     try {
//         const response = await axios({
//             method: "post",
//             url: `${import.meta.env.VITE_API_URL}shares/updateOrCreate`,
//             data: data,
//             withCredentials: true,
//         });
//         return response.data;
//     } catch (error) {
//         return thunkApi.rejectWithValue(error);
//     }
// });


export const deleteShare = createAsyncThunk<number, number>(
    "Shares/deleteShare",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}shares/${id}`,
                withCredentials: true,
            });
            return id;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteShareByDate = createAsyncThunk<string, string>(
    "Shares/deleteShareByDate",
    async (date: string, thunkApi) => {
        try {

            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}shares/date/${date}`,
                withCredentials: true,
            });
            return date;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }

    }
);

interface PropsDeleteBetweenDate {
    dateStart: Date,
    dateEnd: Date
}

export const deleteShareBetweenDate = createAsyncThunk<PropsDeleteBetweenDate, PropsDeleteBetweenDate>(
    "Shares/deleteShareBetweenDate",
    async (payload: PropsDeleteBetweenDate, thunkApi) => {
        try {

            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}shares/dateBetween/date`,
                withCredentials: true,
                params: {
                    dateStart: payload.dateStart,
                    dateEnd: payload.dateEnd
                }
            });
            return payload;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }

    }
);
export const deleteAllShares = createAsyncThunk(
    "Shares/deleteAllShares",
    async (_, thunkAPI) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}shares`,
                withCredentials: true,
            });

        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


// reducers -> reduce to a specific state -> changes state

export const ShareSlice = createSlice({
    name: "Shares",
    initialState,
    reducers: {
        setSharesAll: (
            state,
            action: PayloadAction<ShareDto[]>
        ) => {

            state.sharesAll = [...state.sharesAll, ...action.payload];

        },
        setSharesUser: (
            state,
            action: PayloadAction<ShareDto[]>
        ) => {
            state.sharesUser = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSharesAll.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSharesAll.fulfilled, (state, action) => {
            state.sharesAll = action.payload;
            state.loading = false;
        });
        builder.addCase(getSharesAll.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(getSharesUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSharesUser.fulfilled, (state, action) => {
            state.sharesUser = action.payload;
            state.loading = false;
        });
        builder.addCase(getSharesUser.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });


        builder.addCase(deleteShare.fulfilled, (state, action) => {
            state.loading = false;
            state.sharesAll = state.sharesAll.filter(
                (share) => share.id !== action.payload
            );
        });
        builder.addCase(deleteShare.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteShare.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteAllShares.fulfilled, (state, action) => {
            state.loading = false;
            state.sharesAll = []
        });
        builder.addCase(deleteAllShares.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteAllShares.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteShareByDate.fulfilled, (state, action) => {
            state.loading = false;
            state.sharesAll = state.sharesAll.filter(
                (share) => !isDateClosed(new Date(share.date), new Date(action.payload))
            );
        });
        builder.addCase(deleteShareByDate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteShareByDate.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteShareBetweenDate.fulfilled, (state, action) => {
            state.loading = false;
            state.sharesAll = state.sharesAll.filter(
                (share) => compareTwoDate(new Date(share.date), new Date(action.payload.dateEnd)) && compareTwoDate(new Date(action.payload.dateStart), new Date(share.date)))

        });
        builder.addCase(deleteShareBetweenDate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteShareBetweenDate.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
    },
});

export default ShareSlice.reducer;
export const { setSharesAll, setSharesUser } = ShareSlice.actions;
