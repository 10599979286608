import {
    faDownload,
    faFilePdf,
    faSeedling,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { NumberParam, useQueryParam } from 'use-query-params';
import { formatDate, isSafari, printLargeValue } from '../../../function/Utils';
import { useGetBankFondsInfoForSubscriptionQuery } from '../../../redux/features/fondsSlice';
import { useFetchTokenQuery } from '../../../redux/features/tokenSlice';
import { useTransactionDoneByUserMutation } from '../../../redux/features/transactionSlice';
import {
    useGetSubscriptionUserBySubscriptionIdQuery,
    useGetUserWithAllInfoForCustomerQuery,
} from '../../../redux/features/userSlice';
import { BankAccountFondsForUserDto } from '../../../types/fonds';
import {
    DocumentSubscriptionDto,
    DocumentSubscriptionType,
} from '../../../types/SubscriptionUserFondsDto';
import { TransactionStatus } from '../../../types/transactions';
import { StatusUserTypeEnum } from '../../../types/user';
import {
    PrimaryButton,
    SuccessButton,
    WhiteButton,
} from '../../commun/Buttons';
import CopyButton from '../../commun/CopyButton';
import DisplayDocumentFonds from '../../commun/DisplayDocuments/DisplayDocumentFonds';
import { DropDownComponentUser } from '../../commun/DropDownComponent';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import { useNotificationContext } from '../../Context/notification-context';
import GenerateContract from './GenerateContract';
import { DisplaySubscriptionStatus } from './Investir';

const EditSubscription = ({ subscriptionId }: { subscriptionId: number }) => {
    const [step, setStep] = useQueryParam('stepInvest', NumberParam);

    const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();

    const { data: user, isLoading: isLoadingUserAll } =
        useGetUserWithAllInfoForCustomerQuery(userData?.id!, {
            skip: !userData?.id,
        });

    const { data: s, isLoading: isLoadingSubscription } =
        useGetSubscriptionUserBySubscriptionIdQuery(
            {
                subscriptionId: subscriptionId,
                userId: user?.id || 0,
            },
            {
                skip: !subscriptionId || user === undefined,
            }
        );

    const { data: bankAccounts, isLoading: loadingBankAccount } =
        useGetBankFondsInfoForSubscriptionQuery(
            {
                subscriptionId: subscriptionId,
                userId: user?.id || 0,
            },
            {
                skip: !subscriptionId || user === undefined,
            }
        );

    const [sousScriptionBool, setSouScriptionBool] = useState(true);
    const [transactionBool, setTransactionBool] = useState(true);
    const [documentBool, setDocumentBool] = useState(true);

    const documentSubscription = s?.documents?.find(
        (d) => d.type === DocumentSubscriptionType.SUBSCRIPTION
    );

    const status = user?.statusUser?.find(
        (status) =>
            status.url?.split('subscriptionId=')[1] &&
            s &&
            parseInt(status.url?.split('subscriptionId=')[1]) === s.id
    );

    const [sign, setSign] = useState(false);
    const [open, setOpen] = useState(false);
    const [openTransactionMessage, setOpenTransactionMessage] = useState(false);

    useEffect(() => {
        if (status?.type === StatusUserTypeEnum.InComing) {
            setOpen(true);
        } else if (
            status?.type === StatusUserTypeEnum.ToDo &&
            documentSubscription
        ) {
            setOpenTransactionMessage(true);
        }
    }, [status]);

    return (
        <div className="flex flex-col gap-4">
            {isLoadingSubscription || isLoadingUserAll || loadingBankAccount ? (
                <div className="w-full flex items-center justify-center">
                    <Loading />
                </div>
            ) : (
                <>
                    {s ? (
                        <>
                            <MessageEndSubscription
                                open={open}
                                setOpen={setOpen}
                                nbTransactions={bankAccounts?.length || 0}
                            />
                            <MessageTransactionNotDone
                                open={openTransactionMessage}
                                setOpen={setOpenTransactionMessage}
                                nbTransactions={bankAccounts?.length || 0}
                            />
                            <div className="grid md:grid-cols-2 mt-4">
                                <h3 className=" mt-3 font-mainFontFamily text-xl">
                                    Date de la souscription:{' '}
                                    <span className=" text-mainColor font-semibold">
                                        {s?.date
                                            ? formatDate(new Date(s?.date))
                                            : 'Non trouvée'}
                                    </span>
                                    {s?.dateDone ? (
                                        <>
                                            {' '}
                                            - Date de validation:{' '}
                                            <span className=" text-mainColor font-semibold">
                                                {formatDate(
                                                    new Date(s?.dateDone)
                                                )}
                                            </span>
                                        </>
                                    ) : null}
                                </h3>
                                <div className="justify-self-end">
                                    <DisplaySubscriptionStatus
                                        status={status}
                                        statusSub={s.status}
                                    />
                                </div>
                            </div>
                            <DropDownComponentUser
                                bool={sousScriptionBool}
                                setBool={setSouScriptionBool}
                                title={'Souscription'}
                            >
                                {documentSubscription ? (
                                    <DisplaySubscriptionDoc
                                        d={documentSubscription}
                                    />
                                ) : (
                                    <>
                                        {isSafari() ? (
                                            <div className=" my-4 flex items-center justify-center">
                                                Si le contrat n'apparait pas
                                                après avoir appuyé sur signer le
                                                document. Veuillez signer ce
                                                document dans un autre
                                                navigateur que safari.
                                            </div>
                                        ) : null}
                                        <GenerateContract
                                            s={s}
                                            sign={sign}
                                            setSign={setSign}
                                        />
                                    </>
                                )}
                            </DropDownComponentUser>
                            {!sign ? (
                                <>
                                    <DropDownComponentUser
                                        bool={transactionBool}
                                        setBool={setTransactionBool}
                                        title={'Paiement'}
                                    >
                                        <>
                                            {!documentSubscription ? (
                                                <p className="">
                                                    Vous devez d'abord signer le
                                                    document de souscription
                                                    pour accéder aux
                                                    informations de paiements.
                                                </p>
                                            ) : (
                                                <>
                                                    <p className="mb-4 mt-5 text-lg">
                                                        {s?.transactions &&
                                                        s?.transactions
                                                            ?.length > 1
                                                            ? 'Veuillez effectuer les virements suivants, depuis votre application bancaire personnelle, correspondant au RIB communiqué et en utilisant les références associés à chaque transaction comme référence/intitulé de virement.'
                                                            : 'Veuillez effectuer le virement suivant, depuis votre application bancaire personnelle, correspondant au RIB communiqué et en utilisant la référence associé à la transaction comme référence/intitulé de virement.'}
                                                    </p>

                                                    <div className="flex flex-col gap-4 mb-4">
                                                        {bankAccounts?.map(
                                                            (b) => (
                                                                <DisplayBankAccount
                                                                    b={b}
                                                                    sId={s.id}
                                                                    statusId={
                                                                        status?.id ||
                                                                        0
                                                                    }
                                                                    userId={
                                                                        user?.id ||
                                                                        0
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    </DropDownComponentUser>
                                    <DropDownComponentUser
                                        bool={documentBool}
                                        setBool={setDocumentBool}
                                        title={'Documents'}
                                    >
                                        <></>
                                    </DropDownComponentUser>
                                </>
                            ) : null}
                        </>
                    ) : (
                        <div className="">
                            <p>La souscription n'a pas été trouvée</p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default EditSubscription;

export function DisplaySubscriptionDoc({ d }: { d: DocumentSubscriptionDto }) {
    return (
        <div className="w-full md:w-11/12 mx-auto grid grid-cols-[1fr,4fr,1fr] items-center my-3">
            <div className="">
                <FontAwesomeIcon icon={faFilePdf} size={'2x'} />
            </div>
            <div className="text-md place-self-center text-start font-medium leading-6 text-gray-900">
                <span className="text-gray-500">{d.fileName}</span>
            </div>
            <div className=" place-self-end">
                <a href={d.url} download={'test'} target="_blank">
                    <div className="text-sm font-medium leading-6 cursor-pointer text-gray-900">
                        <FontAwesomeIcon
                            icon={faDownload}
                            className="text-mainColor  hover:text-secondColor w-[20px] h-[20px]"
                        />
                    </div>
                </a>
            </div>
        </div>
    );
}

export function displayTitreName(titre: 'distribution' | 'cumulative') {
    if (titre === 'distribution') {
        return 'Distribue';
    } else {
        return 'Capitalise';
    }
}

function MessageTransactionNotDone({
    open,
    setOpen,
    nbTransactions,
}: {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    nbTransactions: number;
}) {
    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            disabledOnclose={true}
            title={() => {
                return <></>;
            }}
            buttonBoolean={false}
            width="w-full md:w-2/3"
        >
            <div className="flex flex-col gap-4">
                <p className="font-mainFontFamily text-lg font-semibold">
                    Votre contrat a bien été signé ! Vous y êtes presque.
                </p>
                <p className="">
                    <span className=" text-mainColor">Dernière étape</span> :
                    effectuer{' '}
                    {nbTransactions > 0 ? 'les virements' : 'le virement'}
                </p>
                <p>
                    Vous devez effectuer{' '}
                    {nbTransactions > 0
                        ? 'les virements, depuis votre application bancaire personnelle, correspondant au RIB communiqué et en utilisant les références associés à chaque transaction comme référence/intitulé de virement.'
                        : 'le virement, depuis votre application bancaire personnelle, correspondant au RIB communiqué et en utilisant la référence associé à la transaction comme référence/intitulé de virement.'}{' '}
                </p>
                <div className="w-full justify-center items-center flex gap-3">
                    <PrimaryButton onClick={() => setOpen(false)}>
                        Continuer
                    </PrimaryButton>
                </div>
            </div>
        </PopUp>
    );
}

function MessageEndSubscription({
    open,
    setOpen,
    nbTransactions,
}: {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    nbTransactions: number;
}) {
    const navigate = useNavigate();

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            disabledOnclose={true}
            title={() => {
                return <></>;
            }}
            buttonBoolean={false}
            width="w-full md:w-2/3"
        >
            <div className="flex flex-col gap-4">
                <div className="text-2xl font-bold">
                    <FontAwesomeIcon
                        icon={faSeedling}
                        className="text-green-600"
                        size="3x"
                    />
                </div>
                <h3 className=" font-mainFontFamily text-2xl font-semibold">
                    Merci pour votre souscription
                </h3>
                <p>
                    Votre souscription est en attente de validation. La
                    souscription sera validée une fois que
                    {nbTransactions > 0
                        ? ' les virements seront reçus'
                        : ' le virement sera reçu'}
                    . Vous serez notifié par email dès que votre souscription
                    sera validée.
                </p>
                <p>
                    En attendant, vous pouvez télécharger le document de
                    souscription signé et le conserver.
                </p>
                <div className="w-full justify-center items-center flex gap-3">
                    <WhiteButton onClick={() => setOpen(false)}>
                        Fermer
                    </WhiteButton>
                    <PrimaryButton
                        onClick={() => navigate('/DashBoardClient/investir')}
                    >
                        Voir mes souscriptions
                    </PrimaryButton>
                </div>
            </div>
        </PopUp>
    );
}

function DisplayBankAccount({
    b,
    sId,
    statusId,
    userId,
}: {
    b: BankAccountFondsForUserDto;
    sId: number;
    statusId: number;
    userId: number;
}) {
    const { showError, showSuccess } = useNotificationContext();

    const { refetch: refetchUser } = useGetUserWithAllInfoForCustomerQuery(
        userId,
        {
            skip: userId === undefined || userId === 0,
        }
    );

    const { refetch: refetchBanlAccount } =
        useGetBankFondsInfoForSubscriptionQuery(
            {
                subscriptionId: sId,
                userId: userId || 0,
            },
            {
                skip: !sId || userId === undefined,
            }
        );

    const [transactionDone, { isLoading }] = useTransactionDoneByUserMutation();

    const handleTransactionDone = async () => {
        await transactionDone({
            transactionId: b.transactionId,
            subscriptionId: sId,
            statusUserId: statusId,
            userId: userId,
        })
            .unwrap()
            .then((res: any) => {
                showSuccess('Réussi', 'Transaction effectuée avec succès');
                console.log(res);
                refetchBanlAccount();

                if (res?.results?.message === 'All transactions done') {
                    refetchUser();
                }
            })
            .catch((err) => {
                showError('Erreur', err.message);
            });
    };
    return (
        <div
            key={b.id}
            className="p-3 flex flex-col gap-2 rounded-md bg-bgDashboardClient"
        >
            <div className="grid md:grid-cols-2 items-center ">
                <h3 className=" text-mainColor font-mainFontFamily text-xl">
                    {b.fondsName} - {displayTitreName(b.typeShare)}
                </h3>
                <div className=" place-self-end w-fit">
                    {b.transactionStatus === TransactionStatus.PENDING ? (
                        <WhiteButton
                            loading={isLoading}
                            onClick={() => {
                                handleTransactionDone();
                            }}
                        >
                            J'ai effectué la transaction
                        </WhiteButton>
                    ) : b.transactionStatus === TransactionStatus.ISSUED ? (
                        <SuccessButton
                            className="!w-fit"
                            disabled={true}
                            onClick={() => {}}
                        >
                            Transaction effectuée
                        </SuccessButton>
                    ) : (
                        <SuccessButton
                            className="!w-fit"
                            disabled={true}
                            onClick={() => {}}
                        >
                            Transaction reçue
                        </SuccessButton>
                    )}
                </div>
            </div>

            <div className="grid md:grid-cols-2 md:gap-x-2">
                <div className="flex flex-col">
                    <p>
                        <span className=" font-semibold">IBAN</span>
                    </p>
                    <div className="grid grid-cols-[1fr,auto] bg-white shadow-in rounded-md py-1 p-2">
                        <p className=" text-gray-500">{b.iban} </p>
                        <CopyButton text={b.iban} />
                    </div>
                </div>
                <div className="flex flex-col">
                    <p>
                        <span className=" font-semibold">BIC</span>
                    </p>
                    <div className="grid grid-cols-[1fr,auto] bg-white shadow-in rounded-md py-1 p-2">
                        <p className="  text-gray-500 ">{b.bic} </p>
                        <CopyButton text={b.bic} />
                    </div>
                </div>
            </div>
            {b.country && b.beneficiary ? (
                <div className="grid md:grid-cols-2 md:gap-x-2">
                    <div className="flex flex-col">
                        <p>
                            <span className=" font-semibold">Pays</span>
                        </p>
                        <div className="grid grid-cols-[1fr,auto] bg-white shadow-in rounded-md py-1 p-2">
                            <p className=" text-gray-500">{b.country} </p>
                            <CopyButton text={b.country} />
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <p>
                            <span className=" font-semibold">Bénéficiaire</span>
                        </p>
                        <div className="grid grid-cols-[1fr,auto] bg-white shadow-in rounded-md py-1 p-2">
                            <p className="  text-gray-500 ">{b.beneficiary} </p>
                            <CopyButton text={b.beneficiary} />
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="flex flex-col">
                <p>
                    <span className=" font-semibold">Référence</span>
                </p>
                <div className="grid grid-cols-[1fr,auto] bg-white shadow-in rounded-md p-2">
                    <p className=" text-secondColor">{b.reference} </p>
                    <CopyButton text={b.reference} />
                </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-x-2">
                <div className="flex flex-col">
                    <p>
                        <span className=" font-semibold">Montant</span>
                    </p>
                    <p className=" text-gray-500 bg-white shadow-in rounded-md py-1 p-2">
                        {printLargeValue(b.amount)} €
                    </p>
                </div>
                {b?.documentRib ? (
                    <div className="w-full flex justify-center items-center bg-white rounded-md shadow-in px-2">
                        <DisplayDocumentFonds document={b.documentRib} />
                    </div>
                ) : (
                    <p>Aucun document n'a été trouvé</p>
                )}
            </div>
        </div>
    );
}
