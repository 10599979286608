import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { WhiteButton } from "../Buttons";

interface Props {
  remove: (index: number) => void;
  index: number;
  className?: string;
}

const RemoveFieldArrayComponent: React.FC<Props> = ({
  remove,
  index,
  className,
}) => {
  return (
    <div
      className={`flex w-full sm:w-fit justify-center items-center md:block col-span-1 self-end ml-2 mb-2
    ${className}`}
    >
      <WhiteButton onClick={() => remove(index)}>
        <XIcon className="w-4 h-4" />
      </WhiteButton>
    </div>
  );
};

export default RemoveFieldArrayComponent;
