import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import {
  filterFunctions,
  formatDate,
  isEmpty,
  nextMonth,
  printLargeValue,
  setDate,
  startMonthOrLastMonth,
  stringToFloat,
  stringToInt,
  transformDate,
  typeTransactionFunc,
} from "../../../../function/Utils";
import { UploadDocumentTransactionDto } from "../../../../redux/features/transactions";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { typeFiltration } from "../../../../types/BackOffice";
import { typeOrderOrFiltration } from "../../../../types/fonds";
import { ProjectDto } from "../../../../types/project";
import {
  TransactionDto,
  TransactionStatus,
  TypeInvestisseurEnum,
  TypeInvestisseurIdEnum,
  TypeTransaction,
  TypeTransactionProject2Cash,
  UpdateTransactionDtoForm,
} from "../../../../types/transactions";
import {
  GetUserWithSharesByDateBetweenLimitDto,
  UserDto,
  UserStatusType,
} from "../../../../types/user";
import { PrimaryButton, WhiteButton } from "../../../commun/Buttons";
import CheckboxComponent from "../../../commun/formComponent/CheckboxComponent";
import InputComponent from "../../../commun/formComponent/InputComponent";
import SelectComponent, {
  IOptionValues,
} from "../../../commun/formComponent/SelectComponent";
import PopUp from "../../../commun/PopUp";
import {
  BackOfficeState,
  RootBlackOfficeState,
} from "../../../Context/AppContext";
import {
  Level,
  useNotificationContext,
} from "../../../Context/notification-context";
import FilterApparition from "../Components/FilterApparition";
import FiltrationColumnTable, {
  filtrationInterface,
} from "../Components/FiltrationColumnTable";
import { isPending } from "@reduxjs/toolkit";
import {
  setUserWithShares,
  getUsersWithSharesByDateBetweenLimit,
} from "../../../../redux/features/user";
import { ComputationAdmin } from "../../../../function/ComputationAdmin";
import RadioComponent from "../../../commun/formComponent/RadioComponent";
import UploaderMulti from "../../../commun/formComponent/UploaderMulti";
import Loading from "../../../commun/Loading";
import DisplayDocumentTransaction from "../../../commun/DisplayDocuments/DisplayDocumentTransaction";
import {
  useDeletePlatformMutation,
  useGetPlatformsQuery,
} from "../../../../redux/features/platformSlice";
import {
  GetTransactionTable,
  transactionsApi,
  uploadDocumentTransaction,
  useDeleteTransactionMutation,
  useEditTransactionMutation,
  useGetTransactionByIdQuery,
  useGetTransactionDocumentsQuery,
  useGetTransactionSponsorIdQuery,
  useGetTransactionsTableQuery,
  useGetTransactionsUserQuery,
} from "../../../../redux/features/transactionSlice";
import {
  projectApi,
  useDeleteProjetMutation,
  useGetCashIdsQuery,
  useGetProjectsSearchQuery,
} from "../../../../redux/features/projectSlice";
import {
  useGetUsersSearchQuery,
  usersApi,
} from "../../../../redux/features/userSlice";
import { watch } from "fs";
import { useGetIrrFondsIdQuery } from "../../../../redux/features/shareApiSlice";
import StatusTransaction from "../../../commun/StatusTransaction";
import { useGetTypeInvestisseursFormQuery } from "../../../../redux/features/typeInvestisseurSlice";
import useDeleteItem from "../../../commun/CustomHook/useDeleteItem";

interface Props {
  editTransactionId?: number;
  addTransaction?: true;
}

const ViewTableTransactions: React.FC<Props> = ({
  editTransactionId,
  addTransaction,
}) => {
  const backOfficeState = useContext(BackOfficeState);

  const [editToggle, setEditToggle] = useState<boolean>(false);

  const [queryParam, setQueryParam] = useQueryParam(
    "transactionId",
    NumberParam
  );

  const classNameObejct = {
    head: "text-sm text-gray-900 px-6 py-4 text-left font-semibold text-right",
    item: "text-sm font-light px-6 py-4 whitespace-nowrap text-right",
  };

  const [transactionsDataFiltered, setTransactionsDataFiltered] = useState<
    TransactionDto[]
  >([]);
  const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
  const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);
  const [filterColumn, setFilterColumn] = useState<
    filtrationInterface<TransactionDto>[]
  >([
    {
      element: "date",
      type: typeFiltration.date,
      value: "decroissant",
      orderOrFiltration: typeOrderOrFiltration.order,
    },
  ]);

  // useEffect(() => {
  //   if (!isEmpty(transactionsData)) {
  //   filtrationFunction();

  //   }
  // }, [transactionsData]);

  const [searchFrom, setSearchFrom] = useState<string>("");
  const [searchTo, setSearchTo] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("");
  const [status, setStatus] = useState<string>(TransactionStatus.VALIDATED);

  const {
    data: transactionsDataWithNbrows,
    isLoading,
    isFetching,
    isError,
  } = useGetTransactionsTableQuery(
    {
      limit: nbItemsShowed,
      offset: startIntervalNbItems,
      dateStart: new Date(2020, 0, 1),
      dateEnd: new Date(2040, 0, 1),
      fondsId: backOfficeState?.fondsId || 0,
      from: searchFrom,
      to: searchTo,
      type: searchType,
      status: status,
    },
    {
      skip:
        (backOfficeState?.fondsId || 0) == 0 ||
        backOfficeState?.fondsId === undefined ||
        nbItemsShowed > 0
          ? false
          : true,
    }
  );

  // const { data: irr } = useGetIrrFondsIdQuery(backOfficeState?.fondsId || 0, {
  //   skip: backOfficeState?.fondsId === undefined,
  // });

  // console.log("irr", irr);

  const transactionsData = transactionsDataWithNbrows?.rows;
  const nbItems = transactionsDataWithNbrows?.count;

  const filtrationFunction = () => {
    let transactionDataFiltered_init: TransactionDto[] = transactionsData ?? [];
    for (let item of filterColumn) {
      transactionDataFiltered_init = filterFunctions(
        transactionDataFiltered_init,
        item.element,
        item.type,
        item.value
      );
    }
    setTransactionsDataFiltered(transactionDataFiltered_init);
  };

  useEffect(() => {
    if (!isEmpty(transactionsData)) {
      filtrationFunction();
    }
  }, [filterColumn, transactionsData]);

  const [fillInterestDate, setFillInterestDate] = useState<boolean>(false);

  const handleFillInterestDate = () => {
    if (fillInterestDate) {
      setSearchType(TypeTransaction.cashProject);
      setFilterColumn([
        {
          element: "date",
          type: typeFiltration.date,
          value: "decroissant",
          orderOrFiltration: typeOrderOrFiltration.order,
        },
        {
          element: "type",
          type: typeFiltration.exact,
          value: TypeTransaction.cashProject,
          orderOrFiltration: typeOrderOrFiltration.filter,
        },
        {
          element: "projectToClosed",
          type: typeFiltration.boolean,
          value: false,
          orderOrFiltration: typeOrderOrFiltration.filter,
        },
      ]);
    } else {
      setSearchType("");
      setFilterColumn([
        {
          element: "date",
          type: typeFiltration.date,
          value: "decroissant",
          orderOrFiltration: typeOrderOrFiltration.order,
        },
      ]);
    }
  };

  useEffect(() => {
    handleFillInterestDate();
  }, [fillInterestDate]);

  return (
    <React.Fragment>
      <>
        {editToggle ? (
          <EditTransaction
            setEditToggle={setEditToggle}
            editToggle={editToggle}
          />
        ) : null}
        <div className="flex justify-end gap-3 items-center">
          <PrimaryButton onClick={() => setFillInterestDate(!fillInterestDate)}>
            {fillInterestDate ? "Annuler" : "Remplir les dates d'intérêts"}
          </PrimaryButton>
        </div>
        <FilterApparition
          startIntervalNbItems={startIntervalNbItems}
          setNbItemsShowed={setNbItemsShowed}
          nbItemsShowed={nbItemsShowed}
          setStartIntervalNbItems={setStartIntervalNbItems}
          array={transactionsData ?? []}
          nbItems={nbItems || 0}
          offset={true}
        />
        <div className="flex flex-col max-w-full overflow-x-auto">
          <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
            <div className="py-2 inline-block min-w-full">
              <div className="overflow-hidden">
                <table className="min-w-full relative">
                  <thead className="bg-white border-b border-t">
                    <tr>
                      <th
                        scope="col"
                        className={`text-sm text-gray-900 px-6 py-4 text-left font-semibold `}
                      >
                        #
                      </th>
                      <FiltrationColumnTable
                        element={"type"}
                        setSearch={setSearchType}
                        search={searchType}
                        type={typeFiltration.exact}
                        setFilterColumn={setFilterColumn}
                        orderOrFiltration={typeOrderOrFiltration.filter}
                        value={["all"].concat(
                          Object.values(TypeTransaction).map((v) => v)
                        )}
                        columnName={() => <p className="inline-block">Type</p>}
                        textLeft={false}
                      />
                      <FiltrationColumnTable
                        element={"from"}
                        search={searchFrom}
                        setSearch={setSearchFrom}
                        type={typeFiltration.nameWithIdTransaction}
                        setFilterColumn={setFilterColumn}
                        columnName={() => (
                          <p className="inline-block">Origine</p>
                        )}
                        orderOrFiltration={typeOrderOrFiltration.filter}
                        textLeft={false}
                      />
                      <FiltrationColumnTable
                        element={"to"}
                        search={searchTo}
                        setSearch={setSearchTo}
                        type={typeFiltration.nameWithIdTransaction}
                        setFilterColumn={setFilterColumn}
                        columnName={() => <p className="inline-block">Pour</p>}
                        orderOrFiltration={typeOrderOrFiltration.filter}
                        textLeft={false}
                      />

                      <FiltrationColumnTable
                        element={"amount"}
                        type={typeFiltration.amount}
                        setFilterColumn={setFilterColumn}
                        columnName={() => (
                          <p className="inline-block">Montant</p>
                        )}
                        orderOrFiltration={typeOrderOrFiltration.order}
                        textLeft={false}
                      />
                      <FiltrationColumnTable
                        element={"date"}
                        type={typeFiltration.date}
                        setFilterColumn={setFilterColumn}
                        columnName={() => <p className="inline-block">Date</p>}
                        orderOrFiltration={typeOrderOrFiltration.order}
                        textLeft={false}
                      />
                      <FiltrationColumnTable
                        element={"dateInterest"}
                        type={typeFiltration.date}
                        setFilterColumn={setFilterColumn}
                        columnName={() => (
                          <p className="inline-block">Date intérêt</p>
                        )}
                        orderOrFiltration={typeOrderOrFiltration.order}
                        textLeft={false}
                      />
                      <FiltrationColumnTable
                        element={"status"}
                        setSearch={setStatus}
                        search={status}
                        type={typeFiltration.exact}
                        setFilterColumn={setFilterColumn}
                        orderOrFiltration={typeOrderOrFiltration.filter}
                        value={["all"].concat(
                          Object.values(TransactionStatus).map((v) => v)
                        )}
                        columnName={() => (
                          <p className="inline-block">Statut</p>
                        )}
                        textLeft={false}
                      />
                    </tr>
                  </thead>

                  <React.Fragment>
                    <tbody className="relative">
                      {(isLoading || isFetching) && !editToggle ? (
                        <tr className="">
                          <td colSpan={8} className="pt-10 text-center mx-auto">
                            <Loading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {!!(
                            backOfficeState?.fondsId !== undefined &&
                            transactionsDataFiltered !== undefined &&
                            transactionsData
                          ) && (
                            <>
                              {transactionsData.length === 0 ? (
                                <tr className="w-full mx-auto">
                                  <td
                                    colSpan={8}
                                    className="text-center text-gray-500 text-xl  pt-10"
                                  >
                                    Aucune transaction touvée
                                  </td>
                                </tr>
                              ) : (
                                <React.Fragment>
                                  {transactionsDataFiltered
                                    ?.filter(
                                      (val) =>
                                        val.fondsId ===
                                          backOfficeState?.fondsId &&
                                        (fillInterestDate
                                          ? val.dateInterest == null
                                          : true)
                                    )
                                    // .filter(
                                    //   (val, index) =>
                                    //     index >= startIntervalNbItems &&
                                    //     index <
                                    //       startIntervalNbItems + nbItemsShowed
                                    // )
                                    // .sort(
                                    //   (a, b) =>
                                    //     new Date(b.date).getTime() -
                                    //     new Date(a.date).getTime()
                                    // )
                                    .map((transaction, key) => (
                                      <tr
                                        onClick={() => {
                                          setQueryParam(transaction.id);
                                          setEditToggle(true);
                                        }}
                                        key={key}
                                        className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                                          key % 2 === 0
                                            ? "bg-gray-100"
                                            : "bg-white"
                                        } `}
                                      >
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                          {key + 1 + startIntervalNbItems}
                                        </td>
                                        <td className={classNameObejct.item}>
                                          {transaction.type}
                                        </td>
                                        <td className={classNameObejct.item}>
                                          {typeTransactionFunc(
                                            transaction.type,
                                            true,
                                            transaction
                                          )}
                                        </td>
                                        <td className={classNameObejct.item}>
                                          {typeTransactionFunc(
                                            transaction.type,
                                            false,
                                            transaction
                                          )}
                                        </td>
                                        <td className={classNameObejct.item}>
                                          {printLargeValue(transaction.amount)}
                                        </td>
                                        <td className={classNameObejct.item}>
                                          {formatDate(
                                            new Date(transaction.date)
                                          )}
                                        </td>
                                        <td
                                          className={`${classNameObejct.item} !text-center`}
                                        >
                                          {transaction.dateInterest
                                            ? formatDate(
                                                new Date(
                                                  transaction.dateInterest!
                                                )
                                              )
                                            : "-"}
                                        </td>
                                        <td className={classNameObejct.item}>
                                          <StatusTransaction
                                            status={transaction.status}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                </React.Fragment>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </tbody>
                  </React.Fragment>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
};

export default ViewTableTransactions;

function EditTransaction({
  editToggle,
  setEditToggle,
}: {
  editToggle: boolean;
  setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const { showError, showSuccess } = useNotificationContext();

  const [transactionId, setQueryParam] = useQueryParam(
    "transactionId",
    NumberParam
  );

  const { data: platformData } = useGetPlatformsQuery();

  const {
    data: documents,
    isLoading: loadingDocument,
    refetch,
  } = useGetTransactionDocumentsQuery(transactionId ?? 0);

  useEffect(() => {
    refetch();
  }, []);

  const { data: transaction, isLoading } = useGetTransactionByIdQuery(
    transactionId ?? 0
  );

  const [loading, setLoading] = useState<boolean>(false);

  const setDefautlValues = (): void => {
    let typeProject = undefined;
    let type = transaction?.type;
    if (type === TypeTransaction.ProjectBuying) {
      typeProject = TypeTransactionProject2Cash.Capital;
      type = TypeTransaction.projectCash;
    } else if (type === TypeTransaction.projectStopCash) {
      typeProject = TypeTransactionProject2Cash.Closure;
      type = TypeTransaction.projectCash;
    } else if (type === TypeTransaction.projectCash) {
      typeProject = TypeTransactionProject2Cash.Coupon;
    }
    reset({
      ...transaction,
      subscriptionUserFondsId:
        transaction?.subscriptionUserFondsId || undefined,
      sponsored:
        transaction &&
        transaction?.type === TypeTransaction.userCash &&
        transaction?.sponsorId
          ? true
          : false,
      status: transaction?.status,
      type,
      date: transformDate(new Date(transaction?.date!).getTime())
        .toJSON()
        ?.split("T")[0],
      dateInterest: transaction?.dateInterest
        ? transformDate(new Date(transaction?.dateInterest!).getTime())
            .toJSON()
            ?.split("T")[0]
        : undefined,
      typeTransactionProject2Cash: typeProject,
    });
  };

  useEffect(() => {
    if (transaction) {
      setDefautlValues();
    }
  }, [transaction]);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    control,
    formState: { isSubmitting, submitCount },
  } = useForm<UpdateTransactionDtoForm>();

  const [
    editTransaction,
    { isLoading: isLoadingEdit, isSuccess: isSuccessEdit },
  ] = useEditTransactionMutation();

  const { data: sponsorId } = useGetTransactionSponsorIdQuery(
    watch("from") ?? 0,
    {
      skip: watch("type") !== TypeTransaction.userCash || !watch("from"),
    }
  );

  const [
    deleteTransaction,
    { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
  ] = useDeleteTransactionMutation();

  const onSubmit = async (data: UpdateTransactionDtoForm) => {
    setLoading((curr) => !curr);
    const files = data?.files;
    delete data.files;

    if (data.type === TypeTransaction.debtCash) {
      data = {
        ...data,
        interest: stringToFloat(data.interest!),
        from: 0,
      };
    } else if (data.type === TypeTransaction.cashDebt) {
      data = {
        ...data,
        interest: stringToFloat(data.interest!),
        to: 0,
      };
    } else if (
      data.type === TypeTransaction.newParadigmsCash ||
      data.type === TypeTransaction.platformCash
    ) {
      data = {
        ...data,
        from: 0,
      };
    } else if (data.type === TypeTransaction.cashNewParadigms) {
      data = {
        ...data,
        to: 0,
      };
    } else if (data.type === TypeTransaction.userCash) {
      data = {
        ...data,
        initialValueOfShare: stringToFloat(data.initialValueOfShare!),
        typeInvestisseurId: data.typeInvestisseurId!,
      };
    } else if (data.type === TypeTransaction.projectCash) {
      let type = TypeTransaction.projectCash;
      switch (data.typeTransactionProject2Cash) {
        case TypeTransactionProject2Cash.Capital:
          type = TypeTransaction.ProjectBuying;
          break;
        case TypeTransactionProject2Cash.Closure:
          type = TypeTransaction.projectStopCash;
          break;
        default:
          break;
      }
      data.type = type;
    }

    let sponsorIdToSend = null;
    if (data.sponsored && (data.sponsorId || sponsorId?.sponsorId)) {
      sponsorIdToSend = data.sponsorId || sponsorId?.sponsorId;
    }
    // if (data?.sponsorId) {
    //   sponsorIdToSend = data.sponsorId;
    // }

    await editTransaction({
      id: data?.id!,
      donationFee: data?.donationFee,
      entranceFee: data?.entranceFee,
      emptiedTransaction: data?.emptiedTransaction,
      type: data?.type,
      sponsorId: sponsorIdToSend as any,
      fondsId: stringToInt(data.fondsId),
      date: transformDate(data.date),
      status: data.status ? data.status : undefined,
      to: data.to,
      comment: data?.comment,
      interest: data?.interest ? stringToFloat(data.interest) : undefined,
      typeInvestisseurId: data?.typeInvestisseurId
        ? data.typeInvestisseurId
        : undefined,
      initialValueOfShare: data?.initialValueOfShare,
      typeShare: data?.typeShare,
      feesValue: data?.feesValue,
      actualValueOfShareBool: data?.actualValueOfShareBool,
      subscriptionUserFondsId: data?.subscriptionUserFondsId,
      from: data.from,
      dateInterest: data.dateInterest ? transformDate(data.dateInterest) : null,
      amount: stringToFloat(data.amount),
      transactionUserId: data.transactionUserId
        ? stringToInt(data.transactionUserId)
        : null,
    })
      .unwrap()
      .then(async (res) => {
        try {
          if (files) {
            for (let i = 0; i < files.length; i++) {
              let data: UploadDocumentTransactionDto = {
                transactionId: (res as TransactionDto).id,
                file: files[i],
                fileName: files[i].name,
                // type:(res.payload as TransactionDto)?.type === TypeTransaction.ProjectBuying ? TypeDocumentTransaction. ,
              };
              await uploadDocumentTransaction(data)
                .then((res) => {
                  if (res.sucess) {
                    showSuccess("Created", "File uploaded successfully");
                  } else {
                    showError(
                      "Error",
                      `Error uploading file ${data.fileName}: ${res.message}`
                    );
                  }
                })
                .catch((res) => {
                  showError("Error", `Error uploading file ${data.fileName}`);
                });
            }
          }
          showSuccess("Edited", "Transaction edited successfully");
          setLoading((curr) => !curr);
          setEditToggle((curr) => !curr);
        } catch (error) {
          showError("Error", "Error editing transaction");
          setLoading((curr) => !curr);
          return;
        }
      })
      .catch((err) => {
        showError("Error", "Error editing transaction");
      });
  };

  const { data: transactionUser } = useGetTransactionsUserQuery(
    watch("to") ?? 0,
    {
      skip:
        watch("type") !== TypeTransaction.cashUser || watch("to") === undefined
          ? true
          : false,
    }
  );

  const transactionUserSelect = (): IOptionValues[] => {
    if (transactionUser) {
      return transactionUser
        .filter(
          (t) => t.type === TypeTransaction.userCash && t.from === watch("to")
        )
        .map((t) => {
          return {
            label: `date: ${formatDate(new Date(t.date))} montant: ${t.amount}`,
            value: t.id,
          };
        });
    } else {
      return [];
    }
  };

  const [searchFrom, setSearchFrom] = useState<string>("");
  const [searchTo, setSearchTo] = useState<string>("");

  let { data: cashData } = useGetCashIdsQuery();
  let { data: projectData } = useGetProjectsSearchQuery(
    {
      fondsId: getValues("fondsId") ?? 0,
      search:
        watch("type") === TypeTransaction.cashProject ? searchTo : searchFrom,
    },
    {
      skip:
        watch("type") === TypeTransaction.cashProject ||
        watch("type") === TypeTransaction.projectCash
          ? false
          : true,
    }
  );

  let { data: usersData } = useGetUsersSearchQuery(
    watch("type") === TypeTransaction.userCash ? searchFrom : searchTo,
    {
      skip:
        watch("type") === TypeTransaction.userCash ||
        watch("type") === TypeTransaction.cashUser ||
        watch("type") === TypeTransaction.newParadigmsUser
          ? false
          : true,
    }
  );

  useEffect(() => {
    if (transaction) {
      if (transaction.type === TypeTransaction.cashUser) {
        dispatch(
          usersApi.util.updateQueryData("getUsersSearch", "", (draft) => {
            if (draft.find((u) => u.id === transaction.to)) return draft;
            return draft?.concat([
              {
                id: transaction.to,
                firstName: transaction.userNameTo!,
                lastName: "",
              },
            ]);
          })
        );
      } else if (transaction.type === TypeTransaction.userCash) {
        dispatch(
          usersApi.util.updateQueryData("getUsersSearch", "", (draft) => {
            if (draft.find((u) => u.id === transaction.from)) return draft;
            return draft?.concat([
              {
                id: transaction.from,
                firstName: transaction.userNameFrom!,
                lastName: "",
              },
            ]);
          })
        );
      } else if (
        transaction.type === TypeTransaction.projectCash ||
        transaction.type === TypeTransaction.projectStopCash ||
        transaction.type === TypeTransaction.ProjectBuying
      ) {
        dispatch(
          projectApi.util.updateQueryData(
            "getProjectsSearch",
            { fondsId: transaction.fondsId, search: "" },
            (draft) => {
              if (draft.find((u) => u.id === transaction.from)) return draft;
              return draft?.concat([
                { id: transaction.from, name: transaction.projectNameFrom! },
              ]);
            }
          )
        );
      } else if (transaction.type === TypeTransaction.cashProject) {
        dispatch(
          projectApi.util.updateQueryData(
            "getProjectsSearch",
            { fondsId: transaction.fondsId, search: "" },
            (draft) => {
              if (draft.find((u) => u.id === transaction.to)) return draft;
              return draft?.concat([
                { id: transaction.to, name: transaction.projectNameTo! },
              ]);
            }
          )
        );
      }
    }
  }, [transaction]);

  const selectFunc = useCallback(
    (type: TypeTransaction, from: boolean): IOptionValues[] => {
      let cash: IOptionValues[] =
        cashData
          ?.filter((c) => c.fondsId === getValues("fondsId"))
          .map((val) => {
            return {
              label: "Cash",
              value: val.id,
            };
          }) ?? [];

      let user: IOptionValues[] =
        usersData?.map((user, key) => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          };
        }) ?? [];

      let projects: IOptionValues[] =
        projectData?.map((project, key) => {
          return {
            label: project.name,
            value: project.id,
          };
        }) ?? [];

      let newParadimgs: IOptionValues[] = [
        { label: "New Paradimgs", value: 1 },
      ];
      let debt: IOptionValues[] = [{ value: 1, label: "Dette" }];

      let plaform: IOptionValues[] =
        platformData?.map((platform, key) => {
          return {
            label: platform.name,
            value: platform.id,
          };
        }) ?? [];

      switch (type) {
        case TypeTransaction.cashProject:
          if (from) {
            // if (getValues("from") === undefined)
            //   setValue("from", parseInt(cash[0].value as string));
            return cash;
          } else {
            // if (getValues("to") === undefined)
            //   setValue("to", parseInt(projects[0].value as string));
            return projects;
          }
        case TypeTransaction.cashUser:
          if (from) {
            // if (getValues("from") === undefined)
            //   setValue("from", parseInt(cash[0].value as string));
            return cash;
          } else {
            // if (getValues("to") === undefined)
            //   setValue("to", parseInt(user[0].value as string));

            return user;
          }
        case TypeTransaction.projectCash:
          if (from) {
            // if (getValues("from") === undefined)
            //   setValue("from", parseInt(projects[0].value as string));
            return projects;
          } else {
            // if (getValues("to") === undefined)
            //   setValue("to", parseInt(cash[0].value as string));

            return cash;
          }
        case TypeTransaction.userCash:
          if (from) {
            // if (getValues("from") === undefined)
            //   setValue("from", parseInt(user[0].value as string));
            return user;
          } else {
            // if (getValues("to") === undefined)
            //   setValue("to", parseInt(cash[0].value as string));

            return cash;
          }
        case TypeTransaction.cashNewParadigms:
          if (from) {
            // if (getValues("from") === undefined)
            //   setValue("from", parseInt(cash[0].value as string));
            return cash;
          } else {
            // if (getValues("to") === undefined)
            //   setValue("to", parseInt(newParadimgs[0].value as string));
            return newParadimgs;
          }
        case TypeTransaction.newParadigmsCash:
          if (from) {
            // if (getValues("to") === undefined)
            //   setValue("to", parseInt(newParadimgs[0].value as string));
            return newParadimgs;
          } else {
            // if (getValues("from") === undefined)
            //   setValue("from", parseInt(cash[0].value as string));
            return cash;
          }
        case TypeTransaction.newParadigmsUser:
          if (from) {
            return newParadimgs;
          } else {
            return user;
          }
        case TypeTransaction.userNewParadigms:
          if (from) {
            return user;
          } else {
            return newParadimgs;
          }
        case TypeTransaction.cashDebt:
          if (from) {
            // if (getValues("from") === undefined)
            //   setValue("from", parseInt(cash[0].value as string));
            return cash;
          } else {
            // if (getValues("to") === undefined)
            //   setValue("to", parseInt(debt[0].value as string));
            return debt;
          }
        case TypeTransaction.debtCash:
          if (from) {
            // if (getValues("to") === undefined)
            //   setValue("to", parseInt(debt[0].value as string));
            return debt;
          } else {
            // if (getValues("from") === undefined)
            //   setValue("from", parseInt(cash[0].value as string));
            return cash;
          }
        case TypeTransaction.ProjectBuying:
          if (from) {
            // if (getValues("from") === undefined)
            //   setValue("from", parseInt(projects[0].value as string));
            return projects;
          } else {
            // if (getValues("to") === undefined)
            //   setValue("to", parseInt(cash[0].value as string));
            return cash;
          }
        case TypeTransaction.projectStopCash:
          if (from) {
            // if (getValues("from") === undefined)
            //   setValue("from", parseInt(projects[0].value as string));
            return projects;
          } else {
            // if (getValues("to") === undefined)
            //   setValue("to", parseInt(cash[0].value as string));
            return cash;
          }
        case TypeTransaction.platformCash:
          if (from) {
            // if (getValues("from") === undefined)
            //   setValue("from", parseInt(plaform[0].value as string));
            return plaform;
          } else {
            // if (getValues("to") === undefined)
            //   setValue("to", parseInt(cash[0].value as string));
            return cash;
          }
      }
    },
    [watch("fondsId"), cashData, usersData, projectData, watch("type")]
  );

  const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
    transactionId: number;
  }>({
    deleteItem: () => deleteTransaction(transaction?.id!),
    message: "transaction",
    isLoading: isLoading,
  });

  const [expanded, setExpanded] = useState<boolean>(true);
  const { data: typeInvestisseurs } = useGetTypeInvestisseursFormQuery();

  return (
    <>
      <DeleteComponent title="cette transaction" />
      <PopUp
        open={editToggle}
        setOpen={setEditToggle}
        submitItemName={`Editer`}
        buttonBoolean={false}
        expand={expanded}
        setExpand={setExpanded}
        title={() => {
          return (
            <div className="relative">
              <h3 className="text-3xl font-semibold leading-6 text-gray-900">
                Editer cette transaction
              </h3>
              {transaction && transaction?.id ? (
                <div className="absolute top-[-20px] cursor-pointer left-4 transition-all hover:scale-105">
                  <FontAwesomeIcon
                    onClick={() => setOpenDelete(true)}
                    icon={faTrash}
                    className=""
                  />
                </div>
              ) : null}
            </div>
          );
        }}
      >
        {isLoading ? (
          <div className="flex justify-center items-center h-[70vh]">
            <Loading />
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="formTemplateSingUp"
            style={{ width: `${expanded ? "98%" : "80%"}` }}
          >
            <div
              className={`w-full mx-auto ${
                expanded ? "sm:flex sm:space-x-5 sm:justify-between " : ""
              }`}
            >
              <div className={`w-full ${expanded ? "sm:w-10/12 " : ""}`}>
                <SelectComponent
                  register={register}
                  value={"type"}
                  watch={watch}
                  getValues={getValues}
                  setValue={setValue}
                  useParamBoolean={true}
                  control={control}
                  container={true}
                  submitCount={submitCount}
                  optionValues={Object.values(TypeTransaction).map((val) => {
                    return {
                      value: val,
                      label: val,
                    };
                  })}
                >
                  <h3>
                    Type de transaction <span className="required">*</span>
                  </h3>
                </SelectComponent>

                {watch("type") === TypeTransaction.projectCash ? (
                  <div className="flex items-center gap-2 justify-center">
                    <RadioComponent
                      register={register}
                      watch={watch}
                      name={"typeTransactionProject2Cash"}
                      values={[
                        {
                          value: TypeTransactionProject2Cash.Coupon,
                          label: TypeTransactionProject2Cash.Coupon,
                        },
                        {
                          value: TypeTransactionProject2Cash.Capital,
                          label: TypeTransactionProject2Cash.Capital,
                        },
                        {
                          value: TypeTransactionProject2Cash.Closure,
                          label: TypeTransactionProject2Cash.Closure,
                        },
                      ]}
                    ></RadioComponent>
                  </div>
                ) : null}

                <SelectComponent
                  register={register}
                  value={"from"}
                  container={true}
                  setSearch={setSearchFrom}
                  control={control}
                  watch={watch}
                  getValues={getValues}
                  setValue={setValue}
                  useParamBoolean={true}
                  optionValues={selectFunc(watch("type"), true)}
                  submitCount={submitCount}
                >
                  <h3>
                    Origine <span className="required">*</span>
                  </h3>
                </SelectComponent>
                <SelectComponent
                  register={register}
                  value={"to"}
                  setSearch={setSearchTo}
                  container={true}
                  control={control}
                  watch={watch}
                  getValues={getValues}
                  setValue={setValue}
                  useParamBoolean={true}
                  submitCount={submitCount}
                  optionValues={selectFunc(watch("type"), false)}
                >
                  <h3>
                    Pour <span className="required">*</span>
                  </h3>
                </SelectComponent>
                {watch("type") === TypeTransaction.cashUser ? (
                  <SelectComponent
                    register={register}
                    value={"transactionUserId"}
                    container={true}
                    optionValues={transactionUserSelect()}
                    watch={watch}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    submitCount={submitCount}
                    useParamBoolean={true}
                  >
                    <h3>
                      Transaction <span className="required">*</span>
                    </h3>
                  </SelectComponent>
                ) : null}
                {watch("type") !== TypeTransaction.cashUser ? (
                  <InputComponent
                    register={register}
                    value={"amount"}
                    type={"number"}
                  >
                    <h3>
                      Montant <span className="required">*</span>
                    </h3>
                  </InputComponent>
                ) : (
                  <>
                    <CheckboxComponent
                      register={register}
                      watch={watch}
                      values={[
                        {
                          label: "Sortie totale du potefeuille",
                          name: "actualValueOfShareBool",
                        },
                      ]}
                    >
                      {/* <h3>
                Valeur actuelle de la part <span className="required">*</span>
              </h3> */}
                    </CheckboxComponent>
                    {!watch("actualValueOfShareBool") ? (
                      <InputComponent
                        register={register}
                        value={"amount"}
                        type={"number"}
                      >
                        <h3>
                          Montant <span className="required">*</span>
                        </h3>
                      </InputComponent>
                    ) : null}
                  </>
                )}
                {watch("type") === TypeTransaction.debtCash ? (
                  <InputComponent
                    register={register}
                    value={"interest"}
                    type={"number"}
                  >
                    <h3>
                      Interêts <span className="required">*</span>
                    </h3>
                  </InputComponent>
                ) : null}
                {watch("type") === TypeTransaction.userCash ? (
                  <>
                    {!watch("actualValueOfShareBool") ? (
                      <InputComponent
                        register={register}
                        value={"initialValueOfShare"}
                        type={"number"}
                      >
                        <h3>
                          Valeur initial de la part{" "}
                          <span className="required">*</span>
                        </h3>
                      </InputComponent>
                    ) : null}

                    <CheckboxComponent
                      register={register}
                      watch={watch}
                      values={[
                        {
                          label: "Valeur actuelle de la part ?",
                          name: "actualValueOfShareBool",
                        },
                      ]}
                    >
                      {/* <h3>
              Valeur actuelle de la part <span className="required">*</span>
            </h3> */}
                    </CheckboxComponent>
                  </>
                ) : null}
                {watch("type") === TypeTransaction.userCash ? (
                  <>
                    <RadioComponent
                      register={register}
                      watch={watch}
                      name={"typeShare"}
                      className={{
                        container: "grid grid-cols-2 gap-2",
                      }}
                      values={[
                        {
                          value: "cumulative",
                          label: "Capitalise",
                        },
                        {
                          value: "distribution",
                          label: "Distribue",
                        },
                      ]}
                    >
                      <h3 className=" col-span-2 text-start">
                        Type de titre <span className="required">*</span>
                      </h3>
                    </RadioComponent>
                    <SelectComponent
                      register={register}
                      value={"typeInvestisseurId"}
                      control={control}
                      container={true}
                      optionValues={
                        typeInvestisseurs?.map((val) => {
                          return {
                            value: val.id,
                            label: val.name,
                          };
                        }) || []
                      }
                    >
                      <h3>
                        Type investisseur <span className="required">*</span>
                      </h3>
                    </SelectComponent>
                    {watch("typeInvestisseurId") !==
                    TypeInvestisseurIdEnum.noFees ? (
                      <InputComponent
                        register={register}
                        value={"feesValue"}
                        type={"number"}
                      >
                        <h3>
                          Valeur des frais <span className="required">*</span>
                        </h3>
                      </InputComponent>
                    ) : null}
                    <InputComponent
                      register={register}
                      value={"entranceFee"}
                      type={"number"}
                    >
                      <h3>
                        Frais d'entrée <span className="required">*</span>
                      </h3>
                    </InputComponent>
                    <InputComponent
                      register={register}
                      value={"donationFee"}
                      type={"number"}
                    >
                      <h3>
                        Donation frais <span className="required">*</span>
                      </h3>
                    </InputComponent>
                    <CheckboxComponent
                      register={register}
                      watch={watch}
                      values={[
                        {
                          label: "Transaction parrainé?",
                          name: "sponsored",
                        },
                      ]}
                    />
                    <InputComponent
                      register={register}
                      value={"dateInterest"}
                      type={"date"}
                      required={false}
                    >
                      <h3>Date émission des titres</h3>
                    </InputComponent>
                  </>
                ) : null}
                <InputComponent
                  register={register}
                  value={"date"}
                  type={"date"}
                >
                  <h3>
                    Date <span className="required">*</span>
                  </h3>
                </InputComponent>
                {watch("type") === TypeTransaction.cashProject ? (
                  <InputComponent
                    register={register}
                    value={"dateInterest"}
                    type={"date"}
                    required={false}
                  >
                    <h3>Date début des intérêts</h3>
                  </InputComponent>
                ) : null}
                <InputComponent
                  register={register}
                  value={"comment"}
                  required={false}
                >
                  <h3>Commentaire</h3>
                </InputComponent>
                <SelectComponent
                  register={register}
                  value={"status"}
                  container={true}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  watch={watch}
                  optionValues={Object.values(TransactionStatus).map(
                    (v: string) => {
                      return {
                        value: v,
                        label: v,
                      };
                    }
                  )}
                >
                  <h3>
                    Status <span className="required">*</span>
                  </h3>
                </SelectComponent>
              </div>
              {expanded ? (
                <div className="w-full sm:w-11/12">
                  <UploaderMulti
                    register={register}
                    value={"files"}
                    size={10}
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    button={false}
                  >
                    <h3 className="text-2xl mb-5">Ajouter des documents</h3>
                  </UploaderMulti>
                  <div className="">
                    <h3 className="text-xl font-semibold mb-3">Documents</h3>
                    <div className="w-full sm:11/12 mx-auto gap-2 flex justify-center flex-col">
                      {loadingDocument ? (
                        <Loading />
                      ) : (
                        <>
                          {documents && documents?.length! > 0 ? (
                            documents.map((doc, index) => (
                              <DisplayDocumentTransaction
                                document={doc}
                                key={index}
                              />
                            ))
                          ) : (
                            <p>Aucun document n'a été trouvé</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
              <WhiteButton
                onClick={() => {
                  setEditToggle(false);
                }}
                className="w-full"
              >
                Cancel
              </WhiteButton>
              <PrimaryButton type="submit" className="w-full">
                {loading !== undefined && loading === true ? (
                  <Loading size={4} />
                ) : (
                  "Edit"
                )}
              </PrimaryButton>
            </div>
          </form>
        )}
      </PopUp>
    </>
  );
}
