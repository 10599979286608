import { ifError } from "assert";
import React, { ReactNode, useEffect, useState } from "react";
import {
  Control,
  FieldValues,
  Path,
  useController,
  useForm,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import Select from "react-select";

interface Props<T extends FieldValues, G> {
  value: Path<T>;
  optionValues: G[];
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  getValues?: UseFormGetValues<T>;
  watch?: UseFormWatch<T>;
  control: Control<T, Path<T>>;
  required?: boolean;
  optionsRender: (value: G) => string | number;
  valueRender: (value: G) => string | number;
}

const SelectMultiComponent = <T extends object, G>(
  props: Props<T, G> & { children?: ReactNode }
) => {
  const {
    field: {
      value: property,
      onChange: propertyOnChange,
      ...restPropertyField
    },
  } = useController({
    name: props.value,
    control: props.control,
  });

  const [selectAll, setSelectAll] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (selectAll !== undefined) {
      selectAllOptions();
    }
  }, [selectAll]);

  const selectAllOptions = () => {
    if (selectAll) {
      props.setValue(
        props.value,
        props.optionValues.map((o) => {
          return props.valueRender(o);
        }) as any
      );
    } else {
      props.setValue(props.value, [] as any);
    }
  };

  // useEffect(() => {
  //   if (Array.isArray(props?.getValues(props.value))) {
  //     console.log("getValues", props?.getValues(props.value));
  //     let res = props?.getValues(props.value);
  //     if (res.length > 0) {
  //       res[res.length - 1] = props?.getValues(props.value)[
  //         res.length - 1
  //       ].value;
  //     }
  //     propertyOnChange(res);
  //   }
  // }, props?.watch(props.value));

  const cursorFunction = (): JSX.Element => {
    return (
      <div className="flex w-full gap-2">
        <Select
          className={`w-full text-left outline-none border-none my-0`}
          required={props.required}
          isMulti
          options={props.optionValues?.map((value, index) => {
            return {
              value: props.valueRender(value),
              label: props.optionsRender(value),
            };
          })}
          {...props.register(props.value, { required: props.required ?? true })}
          value={
            property
              ? props.optionValues
                  ?.map((value, index) => {
                    return {
                      value: props.valueRender(value),
                      label: props.optionsRender(value),
                    };
                  })
                  .filter((val) => (property as any[]).includes(val.value))
              : property
          }
          onChange={(option) => {
            // let n = option.length
            let option_ = option.map((value, index) => {
              return value?.value ? value.value : value;
            });
            propertyOnChange(option_);
          }}
          {...restPropertyField}
        />
        <div className="checkbox_container relative flex items-center  cursor-pointer">
          <input
            onClick={() => setSelectAll(!selectAll)}
            type="checkbox"
            name="allMulti"
            className=" rounded-full"
            id="allMulti"
          />
          <label htmlFor="allMulti">Tous</label>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      {props.children}

      {cursorFunction()}
    </div>
  );
};

export default SelectMultiComponent;
