import { TypePayement } from "./fonds";

export interface TransactionDto {
    id: number;
    type: TypeTransaction;
    from: number;
    to: number;
    referenceTransaction?: string;
    amount: number;
    fondsName?: string
    date: Date;
    entranceFee: null | number;
    donationFee: null | number;
    comment: null | string;
    interest: null | number;
    initialValueOfShare: null | number;
    typeInvestisseur?: string
    typeInvestisseurId?: number
    feesValue: null | number;
    actualValueOfShareBool: null | boolean;
    fondsId: number;
    transactionUserId: null | number;
    emptiedTransaction: null | boolean;
    dateInterest: null | Date;
    projectNameFrom: null | string;
    projectNameTo: null | string;
    projectToClosed: null | boolean;
    platformFrom: null | string;
    platformTo: null | string;
    userNameFrom: null | string
    userNameTo: null | string;
    status: TransactionStatus;
    sponsorId?: number | null;
    typeShare?: "distribution" | "cumulative"
    subscriptionUserFondsId?: number | null;



}

export interface TransactionSubscriptionDto extends TransactionDto {
    fondsPerformance: number;


}

export interface TransactionSponsorDto {
    id: number;
    type: TypeTransaction;
    to: number;
    amount: number;
    date: Date;
    comment: null | string;
    dateInterest: null | Date;
    userNameSponsor: string;
    status: TransactionStatus;
    userNameSponsored: string;
    sponsorId: number;
}

export interface CreateTransactionSponsorDto {
    type: TypeTransaction;
    to: number;
    amount: number;
    date: Date;
    from: number;
    comment: null | string;
    sponsorId: number;
    status: TransactionStatus;
}

export interface UpdateTransactionSponsorDto extends CreateTransactionSponsorDto {
    id: number;
}

export interface UpdateTransactionSponsorForm extends UpdateTransactionSponsorDto {
    files?: File[]
}

export interface CreateTransactionSponsorForm extends CreateTransactionSponsorDto {
    files?: File[]
}

export interface ViewTransactionsSponsorDto {
    rows: TransactionSponsorDto[];
    count: number;
}


export enum TransactionStatus {
    PENDING = 'PENDING',
    VALIDATED = 'VALIDATED',
    REFUSED = 'REFUSED',
    CANCELLED = 'CANCELLED',
    DELETED = 'DELETED',
    ISSUED = 'ISSUED',
    DELAYED = 'DELAYED',
}

export interface ViewTransactionsDto {
    rows: TransactionDto[];
    count: number;
}


export interface TransactionDtoWithFonds extends TransactionDto {
    fondsName: string;
}

export interface TransactionUserDto {
    id: number;
    amount: number;
    date: Date;
    type: TypeTransaction;
    status: TransactionStatus;
    comment: null | string;
    fondsName: string;
    to: number;
    from: number;
    fondsId: number;
    nbShare: number;
    share: number;
    capitalGain: number | string;
}

export interface CreateTransactionDto {
    type: TypeTransaction;
    from: number;
    to: number;
    date: Date;
    amount: number;
    fondsId: number;
    comment?: string;
    interest?: number;
    initialValueOfShare?: number;
    typeInvestisseurId?: number;
    actualValueOfShareBool?: boolean;
    feesValue?: number;
    entranceFee?: number;
    donationFee?: number;
    emptiedTransaction?: boolean;
    dateInterest?: Date;
    status: TransactionStatus;
    sponsorId?: number
    typeShare?: "distribution" | "cumulative"
    subscriptionUserFondsId?: number;



}

export interface CreateTransactionDtoForm {
    date: string | Date;
    sponsored?: boolean
    sponsorId?: number
    type: TypeTransaction;
    typeShare?: "distribution" | "cumulative"
    from: number;
    to: number;
    amount: number;
    fondsId: number;
    comment?: string;
    interest?: number;
    initialValueOfShare?: number;
    typeInvestisseurId?: number;
    actualValueOfShareBool?: boolean;
    feesValue?: number;
    entranceFee?: number;
    donationFee?: number;
    transactionUserId?: number;
    emptiedTransaction?: boolean;
    typeTransactionProject2Cash?: TypeTransactionProject2Cash;
    dateInterest?: Date | string;
    files?: File[]
    status: TransactionStatus;


}

export interface UpdateTransactionProjectDto {
    transactionAdded: CreateTransactionDto[];
    transactionUpdated: UpdateTransactionDto[];
    transactionRemoved: number[];
    projectId: number;
}


export interface CreateCustomTransactionProjectInComing {
    distribution: TypePayement;
    amount: number;
    date: Date;
    projectId: number;
    cashId: number;
    duration: number;

}
export interface CreateCustomTransactionProjectInComingForm {
    distribution: TypePayement;
    amount: number;
    date: Date | string;
    projectId: number;
    cashId: number;
    duration: number;

}

export enum TypeTransactionProject2Cash {
    Coupon = "Coupon",
    Capital = "Capital",
    Closure = "Fermeture",
}

export interface UpdateTransactionDto extends Partial<TransactionDto> {
    id: number;
}

export interface UpdateTransactionDtoForm {
    id: number;
    sponsorId?: number | null
    sponsored?: boolean;
    type: TypeTransaction;
    from: number;
    to: number;
    typeShare?: "distribution" | "cumulative"
    amount: number;
    date: Date | string;
    entranceFee: null | number;
    donationFee: null | number;
    comment: null | string;
    interest: null | number;
    initialValueOfShare: null | number;
    typeInvestisseurId?: number;
    feesValue: null | number;
    actualValueOfShareBool: null | boolean;
    fondsId: number;
    transactionUserId: number | null;
    emptiedTransaction: null | boolean;
    typeTransactionProject2Cash?: TypeTransactionProject2Cash;
    dateInterest: null | Date | string;
    files?: File[]
    status: TransactionStatus;
    subscriptionUserFondsId?: number | null



}

export enum TypeInvestisseurEnum {
    normal = "normal",
    specific = "specific",
    nonFees = "pas de frais",
}

export enum TypeInvestisseurIdEnum {
    normal = 1,
    specific = 2,
    noFees = 3
}



export enum TypeTransaction {
    userCash = "user->cash",
    cashUser = "cash->user",
    cashProject = "cash->project",
    projectCash = "project->cash",
    cashNewParadigms = "cash->newParadigms",
    newParadigmsCash = "newParadigms->cash",
    newParadigmsUser = "newParadigms->user",
    userNewParadigms = "user->newParadigms",
    cashDebt = "cash->debt",
    debtCash = "debt->cash",
    ProjectBuying = "buyingProject->cash",
    platformCash = "platform->cash",
    projectStopCash = "projectStop->cash",
}

export enum TypeDocumentTransaction {
    invest = 'Invest',
    coupon = 'Coupon',
    closure = "Closure"

}

export interface DocumentTransactionDto {
    id: number;
    // type: TypeDocumentTransaction;
    key: string;
    fileName: string;
    comment?: string;
    transactionId: number;
    date: Date;
    url: string;

}

export interface CreateDocumentTransactionDto {
    // type: TypeDocumentTransaction;
    fileName: string;
    comment?: string;
    transactionId: number;
    fileType: string;
    key: string;
}

export interface UpdateDocumentTransactionDto extends Partial<CreateDocumentTransactionDto> {
    id: number;
}

export interface IRecapSponsorShip {
    userId: number;
    totalMoneyWon: number;
    moneyReceived: number;
    nbUserSponsored: number;

}


export interface GetLiquidityFuture {
    dateStart: Date;
    dateEnd: Date;
    fondsIds?: number[];
    groupby: 'day' | 'week' | 'month' | 'year';

}

export interface LiquidityFutureDto {
    date: Date;
    value: number;
    fondsId: number;
}