import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { ReactNode, useEffect, useState } from 'react';
import { UseFormSetValue, useForm } from 'react-hook-form';
import {
    formatDate,
    stringToFloat,
    stringToInt,
    transformDate,
} from '../../../function/Utils';
import {
    UploadDocumentProjectDto,
    uploadDocumentProject,
    useGetCashIdsQuery,
    useGetDocumentByTypeByProjectQuery,
    useGetProjectByIdQuery,
} from '../../../redux/features/projectSlice';
import {
    useCreateCustomTransactionProjectInComingMutation,
    useGetProjectTransactionsQuery,
    useUpdateTransactionProjectMutation,
} from '../../../redux/features/transactionSlice';
import { TypePayement } from '../../../types/fonds';
import {
    TransactionDtoForm,
    TypeDocumentProject,
} from '../../../types/project';
import {
    CreateCustomTransactionProjectInComingForm,
    CreateTransactionDto,
    TransactionDto,
    TransactionStatus,
    TypeTransaction,
    TypeTransactionProject2Cash,
} from '../../../types/transactions';
import { useNotificationContext } from '../../Context/notification-context';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import DisplayDocumentProject from '../../commun/DisplayDocuments/DisplayDocumentProject';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import FieldArray from '../../commun/formComponent/FieldArray';
import InputComponent from '../../commun/formComponent/InputComponent';
import RemoveFieldArrayComponent from '../../commun/formComponent/RemoveFieldArrayComponent';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import UploaderSingle from '../../commun/formComponent/UploaderSingle';

export function Transaction({ projectId }: { projectId: number }) {
    const {
        data: project,
        isLoading: isLoadingProject,
        isFetching: isFetchingProject,
        refetch: refetchProject,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const {
        data: transactions,
        isLoading: isLoadingTransactionsProject,
        isFetching: isFetchingTransactionsProject,
        refetch,
    } = useGetProjectTransactionsQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    return (
        <>
            {isLoadingTransactionsProject ||
            isLoadingProject ||
            isFetchingTransactionsProject ? (
                <Loading />
            ) : (
                <>
                    <TransactionSubscription
                        transactions={
                            transactions?.filter(
                                (t) =>
                                    t.type === TypeTransaction.cashProject &&
                                    t.status === TransactionStatus.VALIDATED
                            ) ?? []
                        }
                        projectId={projectId}
                    />
                    <TransactionOut
                        transactions={
                            transactions?.filter(
                                (t) =>
                                    t.type !== TypeTransaction.cashProject &&
                                    t.status === TransactionStatus.VALIDATED
                            ) ?? []
                        }
                        projectId={projectId}
                    />
                    <TransactionInComing
                        transactions={
                            transactions?.filter(
                                (t) => t.status === TransactionStatus.PENDING
                            ) ?? []
                        }
                        projectId={projectId}
                    />
                    <TransactionLate
                        transactions={
                            transactions?.filter(
                                (t) => t.status === TransactionStatus.DELAYED
                            ) ?? []
                        }
                        projectId={projectId}
                    />
                    <DocumentSchedulePayment projectId={projectId} />
                </>
            )}
        </>
    );
}

function IsTransactionUpdated(
    transactionNew: TransactionDtoForm,
    transactions: TransactionDto[]
): boolean {
    let transaction = transactions?.find((ts) => ts.id === transactionNew.id);
    let type = TypeTransaction.projectCash;
    if (transactionNew.typeTransactionProject2Cash) {
        switch (transactionNew.typeTransactionProject2Cash) {
            case TypeTransactionProject2Cash.Capital:
                type = TypeTransaction.ProjectBuying;
                break;
            case TypeTransactionProject2Cash.Closure:
                type = TypeTransaction.projectStopCash;
                break;
            default:
                break;
        }
    }

    if (!transaction) return false;
    if (
        transaction?.amount !== transactionNew.amount ||
        formatDate(new Date(transaction?.date)) !==
            formatDate(new Date(transactionNew.date)) ||
        (transactionNew?.dateInterest &&
            transaction?.dateInterest &&
            formatDate(new Date(transaction?.dateInterest)) !==
                formatDate(new Date(transactionNew.dateInterest))) ||
        (transactionNew?.dateInterest && !transaction?.dateInterest) ||
        (!transactionNew?.dateInterest && transaction?.dateInterest) ||
        transaction?.status !== transactionNew.status ||
        (transactionNew.typeTransactionProject2Cash && type != transaction.type)
    ) {
        return true;
    } else {
        return false;
    }
}

function DeleteAllTransactions({
    setValue,
    children,
}: {
    setValue: UseFormSetValue<TransactionProjectForm>;
    children?: ReactNode;
}) {
    return (
        <>
            <PrimaryButton onClick={() => setValue('transactions', [])}>
                Supprimer toutes les transactions {children}
            </PrimaryButton>
        </>
    );
}

interface TransactionProjectForm {
    transactions: TransactionDtoForm[];
}

export function TransactionSubscription({
    transactions,
    projectId,
}: {
    transactions: TransactionDto[];
    projectId: number;
}) {
    const {
        data: project,
        isLoading: isLoadingProject,
        isFetching: isFetchingProject,
        refetch: refetchProject,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const { showSuccess, showError } = useNotificationContext();

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        getValues,
        formState: { dirtyFields },
    } = useForm<TransactionProjectForm>();

    const { data: cashIds } = useGetCashIdsQuery();

    const [
        updateTransactionProject,
        { isLoading: isLoadingUpdateTransaction },
    ] = useUpdateTransactionProjectMutation();

    const setDefautlValues = (): void => {
        if (project && transactions) {
            let transactioinForSorting = [...transactions];
            reset({
                transactions: [
                    ...(transactioinForSorting
                        .sort(
                            (a, b) =>
                                new Date(a.date).getTime() -
                                new Date(b.date).getTime()
                        )
                        ?.map((pi) => {
                            return {
                                ...pi,
                                date: new Date(pi.date).toJSON()?.split('T')[0],
                                dateInterest: pi.dateInterest
                                    ? new Date(pi?.dateInterest)
                                          .toJSON()
                                          ?.split('T')[0]
                                    : undefined,
                            };
                        }) ?? []),
                ],
            });
        }
    };
    useEffect(() => {
        if (project && transactions) {
            setDefautlValues();
        }
    }, [project, transactions]);

    const onSubmit = async (data: TransactionProjectForm) => {
        if (transactions && project) {
            const transactionsForm = data.transactions;

            let transactionBDId = transactions?.map((t) => t.id);

            // transaction changed
            let transactionUpdated = transactionsForm
                ?.filter((t) => transactionBDId?.includes(t.id))
                .filter((t, index) => IsTransactionUpdated(t, transactions))
                .map((t) => {
                    return {
                        ...t,
                        id: t.id,
                        amount: t.amount,
                        date: transformDate(t.date),
                        dateInterest: t.dateInterest
                            ? transformDate(t?.dateInterest)
                            : null,
                    };
                });
            // transaction added
            let transactionAdded = transactionsForm
                ?.filter((t) => !transactionBDId?.includes(t.id))
                ?.map((t) => {
                    return {
                        to: project.id,
                        from: cashIds?.find(
                            (v) => v.fondsId === project.fondsId
                        )?.id!,
                        amount: stringToFloat(t.amount),
                        date: transformDate(t.date),
                        dateInterest: t.dateInterest
                            ? transformDate(t.date)
                            : undefined,
                        type: TypeTransaction.cashProject,
                        fondsId: stringToInt(project.fondsId),
                        status: TransactionStatus.VALIDATED,
                        comment: 'Initial amount',
                    };
                });
            // transaction deleted
            let transactionId = transactionsForm?.map((t) => t.id);
            let transactionRemoved = transactionBDId?.filter(
                (id) => !transactionId?.includes(id)
            );

            await updateTransactionProject({
                transactionAdded: transactionAdded ?? [],
                transactionRemoved: transactionRemoved ?? [],
                transactionUpdated: transactionUpdated ?? [],
                projectId: projectId,
            })
                .unwrap()
                .then((res) => {
                    showSuccess('Edited', 'Transaction edited successfully');
                })
                .catch((err) => {
                    showError('Error', 'Error editing transaction');
                });
        }
    };

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp w-full md:w-11/12 mx-auto"
            >
                <div className="w-full mx-auto">
                    <TitleComponentForm>
                        Transactions souscription
                    </TitleComponentForm>

                    <FieldArray control={control} name="transactions">
                        {({ fields, append, remove }) => (
                            <>
                                <div className="flex flex-col w-full mb-2">
                                    <div className="grid sm:grid-cols-3 w-full relative">
                                        <LabelComponentForm>
                                            Date{' '}
                                            <span className="required">*</span>
                                        </LabelComponentForm>
                                        <LabelComponentForm>
                                            Date jouissance
                                        </LabelComponentForm>
                                        <LabelComponentForm>
                                            Montant{' '}
                                            <span className="required">*</span>
                                        </LabelComponentForm>
                                    </div>
                                    {fields.map((field, index) => (
                                        <div
                                            key={field.id}
                                            className={`grid sm:grid-cols-3 w-full relative border-t-2  ${
                                                field.from === undefined
                                                    ? ' !bg-green-200'
                                                    : IsTransactionUpdated(
                                                            watch(
                                                                `transactions.${index}`
                                                            ),
                                                            transactions
                                                        )
                                                      ? ' !bg-yellow-200'
                                                      : ''
                                            } 
                          `}
                                        >
                                            <InputComponent
                                                register={register}
                                                type="date"
                                                value={`transactions.${index}.date`}
                                                cursorNotAllowed={
                                                    index === 0
                                                        ? true
                                                        : undefined
                                                }
                                                className={{
                                                    input: 'row-span-1 col-span-1 !rounded-none !h-[35px] !outline-none !border-none !bg-transparent',
                                                    containerAbove: '!m-0',
                                                }}
                                            ></InputComponent>
                                            <InputComponent
                                                register={register}
                                                type="date"
                                                required={false}
                                                value={`transactions.${index}.dateInterest`}
                                                className={{
                                                    input: 'row-span-1 col-span-1 !rounded-none !h-[35px] !outline-none !border-none !bg-transparent',
                                                    containerAbove: '!m-0',
                                                }}
                                            ></InputComponent>

                                            <InputComponent
                                                register={register}
                                                type="number"
                                                value={`transactions.${index}.amount`}
                                                className={{
                                                    input: 'row-span-1 col-span-1 !rounded-none !h-[35px] !outline-none !border-none !bg-transparent',
                                                    containerAbove: '!m-0',
                                                }}
                                            ></InputComponent>
                                            {index > 0 ? (
                                                <RemoveFieldArrayComponent
                                                    remove={remove}
                                                    index={index}
                                                    className="!top-0"
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                                <div className="w-11/12 flex  justify-center items-center">
                                    <PrimaryButton
                                        className=" mx-auto mb-2"
                                        onClick={() =>
                                            append({
                                                date: new Date(
                                                    project?.dateStart!
                                                )
                                                    .toJSON()
                                                    ?.split('T')[0],

                                                amount: 0,
                                                fondsId: project?.fondsId!,
                                                type: TypeTransaction.cashProject,
                                            } as any)
                                        }
                                    >
                                        Ajouter
                                    </PrimaryButton>
                                </div>
                                <div className="w-11/12 flex mt-4 justify-center items-center">
                                    <PrimaryButton
                                        loading={isLoadingUpdateTransaction}
                                        className=" mx-auto mb-2"
                                        type="submit"
                                    >
                                        Edit
                                    </PrimaryButton>
                                </div>
                            </>
                        )}
                    </FieldArray>
                </div>
            </form>
        </>
    );
}

function TransactionOut({
    transactions,
    projectId,
}: {
    transactions: TransactionDto[];
    projectId: number;
}) {
    const {
        data: project,
        isLoading: isLoadingProject,
        isFetching: isFetchingProject,
        refetch: refetchProject,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const { showError, showSuccess } = useNotificationContext();

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        getValues,
        formState: { dirtyFields },
    } = useForm<TransactionProjectForm>();

    const { data: cashIds } = useGetCashIdsQuery();

    const [
        updateTransactionProject,
        { isLoading: isLoadingUpdateTransaction },
    ] = useUpdateTransactionProjectMutation();

    const setDefautlValues = (): void => {
        if (project && transactions) {
            let transactioinForSorting = [...transactions];
            reset({
                transactions:
                    transactioinForSorting
                        .sort(
                            (a, b) =>
                                new Date(a.date).getTime() -
                                new Date(b.date).getTime()
                        )
                        ?.map((pi) => {
                            let typeProject = undefined;
                            let type = pi?.type;
                            if (type === TypeTransaction.ProjectBuying) {
                                typeProject =
                                    TypeTransactionProject2Cash.Capital;
                                type = TypeTransaction.projectCash;
                            } else if (
                                type === TypeTransaction.projectStopCash
                            ) {
                                typeProject =
                                    TypeTransactionProject2Cash.Closure;
                                type = TypeTransaction.projectCash;
                            } else if (type === TypeTransaction.projectCash) {
                                typeProject =
                                    TypeTransactionProject2Cash.Coupon;
                            }
                            return {
                                ...pi,
                                type,
                                date: new Date(pi.date).toJSON()?.split('T')[0],
                                typeTransactionProject2Cash: typeProject,
                            };
                        }) ?? [],
            });
        }
    };
    useEffect(() => {
        if (project && transactions) {
            setDefautlValues();
        }
    }, [project, transactions]);

    const onSubmit = async (data: TransactionProjectForm) => {
        if (transactions && project) {
            const transactionsForm = data.transactions;

            let transactionBDId = transactions?.map((t) => t.id);

            // transaction changed
            let transactionUpdated = transactionsForm
                ?.filter((t) => transactionBDId?.includes(t.id))
                .filter((t, index) => {
                    let typeTrans = t?.type;
                    let type = TypeTransaction.projectCash;
                    switch (t.typeTransactionProject2Cash) {
                        case TypeTransactionProject2Cash.Capital:
                            type = TypeTransaction.ProjectBuying;
                            break;
                        case TypeTransactionProject2Cash.Closure:
                            type = TypeTransaction.projectStopCash;
                            break;
                        default:
                            break;
                    }
                    if (IsTransactionUpdated(t, transactions)) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .map((t) => {
                    let type = TypeTransaction.projectCash;
                    switch (t.typeTransactionProject2Cash) {
                        case TypeTransactionProject2Cash.Capital:
                            type = TypeTransaction.ProjectBuying;
                            break;
                        case TypeTransactionProject2Cash.Closure:
                            type = TypeTransaction.projectStopCash;
                            break;
                        default:
                            break;
                    }
                    return {
                        ...t,
                        id: t.id,
                        amount: t.amount,
                        date: transformDate(t.date),
                        dateInterest: null,
                        type: type,
                    };
                });
            // transaction added
            let transactionAdded = transactionsForm
                ?.filter((t) => !transactionBDId?.includes(t.id))
                ?.map((t) => {
                    let dataBis: CreateTransactionDto = {
                        to: cashIds?.find((v) => v.fondsId === project.fondsId)
                            ?.id!,
                        from: project.id,
                        amount: stringToFloat(t.amount),
                        date: transformDate(t.date),
                        type: TypeTransaction.projectCash,
                        fondsId: stringToInt(project.fondsId),
                        status: t.status,
                    };
                    let type = TypeTransaction.projectCash;

                    switch (t.typeTransactionProject2Cash) {
                        case TypeTransactionProject2Cash.Capital:
                            type = TypeTransaction.ProjectBuying;
                            break;
                        case TypeTransactionProject2Cash.Closure:
                            type = TypeTransaction.projectStopCash;
                            break;
                        default:
                            break;
                    }
                    dataBis.type = type;
                    return dataBis;
                });
            // transaction deleted
            let transactionId = transactionsForm?.map((t) => t.id);
            let transactionRemoved = transactionBDId?.filter(
                (id) => !transactionId?.includes(id)
            );

            await updateTransactionProject({
                transactionAdded: transactionAdded ?? [],
                transactionRemoved: transactionRemoved ?? [],
                transactionUpdated: transactionUpdated ?? [],
                projectId: projectId,
            })
                .unwrap()
                .then((res) => {
                    showSuccess('Edited', 'Transaction edited successfully');
                })
                .catch((err) => {
                    showError('Error', 'Error editing transaction');
                });
        }
    };

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp w-full md:w-11/12 mx-auto"
            >
                <div className="w-full mx-auto">
                    <TitleComponentForm className="!py-2">
                        <div className="grid md:grid-cols-2">
                            <div className="">Transactions sortie</div>
                            <div className=" md:justify-self-end w-fit">
                                <DeleteAllTransactions setValue={setValue} />
                            </div>
                        </div>
                    </TitleComponentForm>
                    <div className="grid sm:grid-cols-4 w-full relative">
                        <LabelComponentForm>
                            Date <span className="required">*</span>
                        </LabelComponentForm>
                        <LabelComponentForm>
                            Montant <span className="required">*</span>
                        </LabelComponentForm>
                        <LabelComponentForm>
                            Type <span className="required">*</span>
                        </LabelComponentForm>
                        <LabelComponentForm>
                            Statut <span className="required">*</span>
                        </LabelComponentForm>
                    </div>
                    <FieldArray control={control} name="transactions">
                        {({ fields, append, remove }) => (
                            <>
                                <div className="flex flex-col w-full mb-2">
                                    {fields.map((field, index) => (
                                        <div
                                            key={field.id}
                                            className={`grid sm:grid-cols-4 w-full relative border-t-2  ${
                                                watch(
                                                    `transactions.${index}.typeTransactionProject2Cash`
                                                ) ===
                                                TypeTransactionProject2Cash.Capital
                                                    ? 'bg-secondBackgroundColor'
                                                    : watch(
                                                            `transactions.${index}.typeTransactionProject2Cash`
                                                        ) ===
                                                        TypeTransactionProject2Cash.Closure
                                                      ? 'bg-red-500/3'
                                                      : 'bg-primaryColor'
                                            } ${
                                                field.from === undefined
                                                    ? ' !bg-green-200'
                                                    : IsTransactionUpdated(
                                                            watch(
                                                                `transactions.${index}`
                                                            ),
                                                            transactions
                                                        )
                                                      ? ' !bg-yellow-200'
                                                      : ''
                                            }
                          `}
                                        >
                                            {/* <div className="absolute"></div> */}
                                            {/* ${
                            watch(
                              `transactions.${index}.typeTransactionProject2Cash`
                            ) === TypeTransactionProject2Cash.Capital
                              ? "bg-secondBackgroundColor"
                              : watch(
                                  `transactions.${index}.typeTransactionProject2Cash`
                                ) === TypeTransactionProject2Cash.Closure
                              ? "bg-red-500/3"
                              : "bg-primaryColor"
                          } */}

                                            <InputComponent
                                                register={register}
                                                type="date"
                                                value={`transactions.${index}.date`}
                                                className={{
                                                    input: 'row-span-1 col-span-1 !rounded-none !h-[35px] !outline-none !border-none !bg-transparent',
                                                    containerAbove: '!m-0',
                                                }}
                                            ></InputComponent>

                                            <InputComponent
                                                register={register}
                                                type="number"
                                                value={`transactions.${index}.amount`}
                                                className={{
                                                    input: 'row-span-1 col-span-1 !rounded-none !h-[35px] !outline-none !border-none !bg-transparent',
                                                    containerAbove: '!m-0',
                                                }}
                                            ></InputComponent>
                                            <div className="flex items-center gap-2 justify-center">
                                                <SelectComponent
                                                    register={register}
                                                    control={control}
                                                    setValue={setValue}
                                                    watch={watch}
                                                    className="!outline-none !border-none !bg-transparent"
                                                    value={`transactions.${index}.typeTransactionProject2Cash`}
                                                    optionValues={[
                                                        {
                                                            value: TypeTransactionProject2Cash.Coupon,
                                                            label: TypeTransactionProject2Cash.Coupon,
                                                        },
                                                        {
                                                            value: TypeTransactionProject2Cash.Capital,
                                                            label: TypeTransactionProject2Cash.Capital,
                                                        },
                                                        {
                                                            value: TypeTransactionProject2Cash.Closure,
                                                            label: TypeTransactionProject2Cash.Closure,
                                                        },
                                                    ]}
                                                ></SelectComponent>
                                            </div>
                                            <div className="flex items-center gap-2 justify-center">
                                                <SelectComponent
                                                    register={register}
                                                    control={control}
                                                    setValue={setValue}
                                                    watch={watch}
                                                    className="!outline-none !border-none !bg-transparent"
                                                    value={`transactions.${index}.status`}
                                                    optionValues={[
                                                        {
                                                            value: TransactionStatus.VALIDATED,
                                                            label: 'Valider',
                                                        },
                                                        {
                                                            value: TransactionStatus.PENDING,
                                                            label: 'A venir',
                                                        },
                                                        {
                                                            value: TransactionStatus.DELAYED,
                                                            label: 'En retard',
                                                        },
                                                    ]}
                                                ></SelectComponent>
                                            </div>

                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                                className="!top-0"
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="flex w-11/12 mx-auto items-center justify-center">
                                    <PrimaryButton
                                        className=" mx-auto mb-2"
                                        onClick={() =>
                                            append({
                                                date: new Date()
                                                    .toJSON()
                                                    ?.split('T')[0],

                                                amount: 0,
                                                fondsId: project?.fondsId!,
                                                status: TransactionStatus.VALIDATED,
                                                TypeTransactionProject2Cash:
                                                    TypeTransactionProject2Cash.Coupon,
                                            } as any)
                                        }
                                    >
                                        Ajouter
                                    </PrimaryButton>
                                </div>
                                <div className="w-11/12 mx-auto flex mt-4 justify-center items-center">
                                    <PrimaryButton
                                        loading={isLoadingUpdateTransaction}
                                        className=" mx-auto mb-2"
                                        type="submit"
                                    >
                                        Edit
                                    </PrimaryButton>
                                </div>
                            </>
                        )}
                    </FieldArray>
                </div>
            </form>
        </>
    );
}

function TransactionInComing({
    transactions,
    projectId,
}: {
    transactions: TransactionDto[];
    projectId: number;
}) {
    const {
        data: project,
        isLoading: isLoadingProject,
        isFetching: isFetchingProject,
        refetch: refetchProject,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const { showError, showSuccess } = useNotificationContext();

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        getValues,
        formState: { dirtyFields },
    } = useForm<TransactionProjectForm>();

    const { data: cashIds } = useGetCashIdsQuery();

    const [
        updateTransactionProject,
        { isLoading: isLoadingUpdateTransaction },
    ] = useUpdateTransactionProjectMutation();

    const [toggleFormPendingTransaction, setToggleFormPendingTransaction] =
        useState(false);

    const setDefautlValues = (): void => {
        if (project && transactions) {
            let transactioinForSorting = [...transactions];
            reset({
                transactions:
                    transactioinForSorting
                        .sort(
                            (a, b) =>
                                new Date(a.date).getTime() -
                                new Date(b.date).getTime()
                        )
                        ?.map((pi, index) => {
                            let typeProject = undefined;
                            let type = pi?.type;
                            if (type === TypeTransaction.ProjectBuying) {
                                typeProject =
                                    TypeTransactionProject2Cash.Capital;
                                type = TypeTransaction.projectCash;
                            } else if (
                                type === TypeTransaction.projectStopCash
                            ) {
                                typeProject =
                                    TypeTransactionProject2Cash.Closure;
                                type = TypeTransaction.projectCash;
                            } else if (type === TypeTransaction.projectCash) {
                                typeProject =
                                    TypeTransactionProject2Cash.Coupon;
                            }
                            return {
                                ...pi,
                                type,
                                date: new Date(pi.date).toJSON()?.split('T')[0],
                                typeTransactionProject2Cash: typeProject,
                            };
                        }) ?? [],
            });
        }
    };
    useEffect(() => {
        if (project && transactions) {
            setDefautlValues();
        }
    }, [project, transactions]);

    const onSubmit = async (data: TransactionProjectForm) => {
        if (transactions && project) {
            const transactionsForm = data.transactions;

            let transactionBDId = transactions?.map((t) => t.id);

            // transaction changed
            let transactionUpdated = transactionsForm
                ?.filter((t) => transactionBDId?.includes(t.id))
                .filter((t, index) => {
                    let typeTrans = t?.type;
                    let type = TypeTransaction.projectCash;
                    switch (t.typeTransactionProject2Cash) {
                        case TypeTransactionProject2Cash.Capital:
                            type = TypeTransaction.ProjectBuying;
                            break;
                        case TypeTransactionProject2Cash.Closure:
                            type = TypeTransaction.projectStopCash;
                            break;
                        default:
                            break;
                    }
                    if (IsTransactionUpdated(t, transactions)) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .map((t) => {
                    let type = TypeTransaction.projectCash;
                    switch (t.typeTransactionProject2Cash) {
                        case TypeTransactionProject2Cash.Capital:
                            type = TypeTransaction.ProjectBuying;
                            break;
                        case TypeTransactionProject2Cash.Closure:
                            type = TypeTransaction.projectStopCash;
                            break;
                        default:
                            break;
                    }
                    return {
                        ...t,
                        id: t.id,
                        amount: t.amount,
                        date: transformDate(t.date),
                        dateInterest: null,
                        type: type,
                    };
                });
            // transaction added
            let transactionAdded = transactionsForm
                ?.filter((t) => !transactionBDId?.includes(t.id))
                ?.map((t) => {
                    let dataBis: CreateTransactionDto = {
                        to: cashIds?.find((v) => v.fondsId === project.fondsId)
                            ?.id!,
                        from: project.id,
                        amount: stringToFloat(t.amount),
                        date: transformDate(t.date),
                        type: TypeTransaction.projectCash,
                        fondsId: stringToInt(project.fondsId),
                        status: t.status,
                    };
                    let type = TypeTransaction.projectCash;

                    switch (t.typeTransactionProject2Cash) {
                        case TypeTransactionProject2Cash.Capital:
                            type = TypeTransaction.ProjectBuying;
                            break;
                        case TypeTransactionProject2Cash.Closure:
                            type = TypeTransaction.projectStopCash;
                            break;
                        default:
                            break;
                    }
                    dataBis.type = type;
                    return dataBis;
                });
            // transaction deleted
            let transactionId = transactionsForm?.map((t) => t.id);
            let transactionRemoved = transactionBDId?.filter(
                (id) => !transactionId?.includes(id)
            );

            await updateTransactionProject({
                transactionAdded: transactionAdded ?? [],
                transactionRemoved: transactionRemoved ?? [],
                transactionUpdated: transactionUpdated ?? [],
                projectId: projectId,
            })
                .unwrap()
                .then((res) => {
                    showSuccess('Edited', 'Transaction edited successfully');
                })
                .catch((err) => {
                    showError('Error', 'Error editing transaction');
                });
        }
    };

    return (
        <>
            {toggleFormPendingTransaction ? (
                <CreatePenddingTransactions
                    open={toggleFormPendingTransaction}
                    setOpen={setToggleFormPendingTransaction}
                    projectId={projectId}
                />
            ) : null}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp w-full md:w-11/12 mx-auto"
            >
                <div className="w-full mx-auto">
                    <TitleComponentForm className="!py-2">
                        <div className="grid md:grid-cols-2">
                            <div className="">Transactions à venir</div>
                            <div className=" md:justify-self-end flex items-center gap-2 w-fit">
                                <WhiteButton
                                    onClick={() =>
                                        setToggleFormPendingTransaction(true)
                                    }
                                >
                                    Créer des transactions à venir
                                </WhiteButton>
                                <DeleteAllTransactions setValue={setValue} />
                            </div>
                        </div>
                    </TitleComponentForm>
                    <div className="grid sm:grid-cols-4 w-full relative">
                        <LabelComponentForm>
                            Date <span className="required">*</span>
                        </LabelComponentForm>
                        <LabelComponentForm>
                            Montant <span className="required">*</span>
                        </LabelComponentForm>
                        <LabelComponentForm>
                            Type <span className="required">*</span>
                        </LabelComponentForm>
                        <LabelComponentForm>
                            Statut <span className="required">*</span>
                        </LabelComponentForm>
                    </div>
                    <FieldArray control={control} name="transactions">
                        {({ fields, append, remove }) => (
                            <>
                                <div className="flex flex-col w-full mb-2">
                                    {fields.map((field, index) => (
                                        <div
                                            key={field.id}
                                            className={`grid sm:grid-cols-4 w-full relative border-t-2  ${
                                                watch(
                                                    `transactions.${index}.typeTransactionProject2Cash`
                                                ) ===
                                                TypeTransactionProject2Cash.Capital
                                                    ? 'bg-secondBackgroundColor'
                                                    : watch(
                                                            `transactions.${index}.typeTransactionProject2Cash`
                                                        ) ===
                                                        TypeTransactionProject2Cash.Closure
                                                      ? 'bg-red-500/3'
                                                      : 'bg-primaryColor'
                                            } ${
                                                field.from === undefined
                                                    ? ' !bg-green-200'
                                                    : IsTransactionUpdated(
                                                            watch(
                                                                `transactions.${index}`
                                                            ),
                                                            transactions
                                                        )
                                                      ? ' !bg-yellow-200'
                                                      : ''
                                            }
                          `}
                                        >
                                            {/* <div className="absolute"></div> */}
                                            {/* ${
                            watch(
                              `transactions.${index}.typeTransactionProject2Cash`
                            ) === TypeTransactionProject2Cash.Capital
                              ? "bg-secondBackgroundColor"
                              : watch(
                                  `transactions.${index}.typeTransactionProject2Cash`
                                ) === TypeTransactionProject2Cash.Closure
                              ? "bg-red-500/3"
                              : "bg-primaryColor"
                          } */}

                                            <InputComponent
                                                register={register}
                                                type="date"
                                                value={`transactions.${index}.date`}
                                                className={{
                                                    input: 'row-span-1 col-span-1 !rounded-none !h-[35px] !outline-none !border-none !bg-transparent',
                                                    containerAbove: '!m-0',
                                                }}
                                            ></InputComponent>

                                            <InputComponent
                                                register={register}
                                                type="number"
                                                value={`transactions.${index}.amount`}
                                                className={{
                                                    input: 'row-span-1 col-span-1 !rounded-none !h-[35px] !outline-none !border-none !bg-transparent',
                                                    containerAbove: '!m-0',
                                                }}
                                            ></InputComponent>
                                            <div className="flex items-center gap-2 justify-center">
                                                <SelectComponent
                                                    register={register}
                                                    control={control}
                                                    setValue={setValue}
                                                    watch={watch}
                                                    className="!outline-none !border-none !bg-transparent"
                                                    value={`transactions.${index}.typeTransactionProject2Cash`}
                                                    optionValues={[
                                                        {
                                                            value: TypeTransactionProject2Cash.Coupon,
                                                            label: TypeTransactionProject2Cash.Coupon,
                                                        },
                                                        {
                                                            value: TypeTransactionProject2Cash.Capital,
                                                            label: TypeTransactionProject2Cash.Capital,
                                                        },
                                                        {
                                                            value: TypeTransactionProject2Cash.Closure,
                                                            label: TypeTransactionProject2Cash.Closure,
                                                        },
                                                    ]}
                                                ></SelectComponent>
                                            </div>
                                            <div className="flex items-center gap-2 justify-center">
                                                <SelectComponent
                                                    register={register}
                                                    control={control}
                                                    setValue={setValue}
                                                    watch={watch}
                                                    className="!outline-none !border-none !bg-transparent"
                                                    value={`transactions.${index}.status`}
                                                    optionValues={[
                                                        {
                                                            value: TransactionStatus.VALIDATED,
                                                            label: 'Valider',
                                                        },
                                                        {
                                                            value: TransactionStatus.PENDING,
                                                            label: 'A venir',
                                                        },
                                                        {
                                                            value: TransactionStatus.DELAYED,
                                                            label: 'En retard',
                                                        },
                                                    ]}
                                                ></SelectComponent>
                                            </div>

                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                                className="!top-0"
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="flex mt-3 items-center justify-center">
                                    <PrimaryButton
                                        className=" mx-auto mb-2"
                                        onClick={() =>
                                            append({
                                                date: new Date()
                                                    .toJSON()
                                                    ?.split('T')[0],

                                                amount: 0,
                                                fondsId: project?.fondsId!,
                                                status: TransactionStatus.PENDING,
                                                TypeTransactionProject2Cash:
                                                    TypeTransactionProject2Cash.Coupon,
                                            } as any)
                                        }
                                    >
                                        Ajouter
                                    </PrimaryButton>
                                </div>
                                <div className="w-11/12 mx-auto flex mt-4 justify-center items-center">
                                    <PrimaryButton
                                        loading={isLoadingUpdateTransaction}
                                        className=" mx-auto mb-2"
                                        type="submit"
                                    >
                                        Edit
                                    </PrimaryButton>
                                </div>
                            </>
                        )}
                    </FieldArray>
                </div>
            </form>
        </>
    );
}

function CreatePenddingTransactions({
    projectId,
    open,
    setOpen,
}: {
    projectId: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        getValues,
        formState: { dirtyFields },
    } = useForm<CreateCustomTransactionProjectInComingForm>();

    const { data: cashIds } = useGetCashIdsQuery();

    const {
        data: transactions,
        isLoading: isLoadingTransactionsProject,
        isFetching: isFetchingTransactionsProject,
        refetch,
    } = useGetProjectTransactionsQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const [
        createTransactionPending,
        { isLoading: isLoadingCreatePendingTransactions },
    ] = useCreateCustomTransactionProjectInComingMutation();
    const { showError, showSuccess } = useNotificationContext();

    const {
        data: project,
        isLoading: isLoadingProject,
        isFetching: isFetchingProject,
        refetch: refetchProject,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const setDefautlValues = (): void => {
        if (project && cashIds && transactions) {
            reset({
                distribution: project.payment!,
                duration: project.duration!,
                date: new Date(Date.now()).toJSON()?.split('T')[0],
                projectId: project.id,
                amount: transactions
                    .filter(
                        (t) =>
                            TypeTransaction.cashProject === t.type &&
                            t.status === TransactionStatus.VALIDATED
                    )
                    ?.reduce((acc, t) => {
                        return acc + t.amount;
                    }, 0),
                cashId: cashIds?.find((v) => v.fondsId === project.fondsId)
                    ?.id!,
            });
        }
    };
    useEffect(() => {
        if (project && cashIds) {
            setDefautlValues();
        }
    }, [project, cashIds, transactions]);

    const onSubmit = async (
        data: CreateCustomTransactionProjectInComingForm
    ) => {
        await createTransactionPending({
            ...data,
            date: transformDate(data.date),
        })
            .unwrap()
            .then((res) => {
                showSuccess('Created', 'Transaction created successfully');
            })
            .catch((err) => {
                showError('Error', 'Error creating transaction');
            });
    };
    return (
        <>
            <PopUp
                open={open}
                setOpen={setOpen}
                title={() => {
                    return (
                        <h3 className="text-2xl font-medium leading-6 text-gray-900">
                            Create transactions à venir
                        </h3>
                    );
                }}
                buttonBoolean={false}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formTemplateSingUp"
                >
                    <InputComponent
                        register={register}
                        value="date"
                        type="date"
                        commentaire="Date de référence pour créer les transactions à venir"
                    >
                        <LabelComponentForm>
                            Date
                            <span className="required">*</span>
                        </LabelComponentForm>
                    </InputComponent>
                    <SelectComponent
                        register={register}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        value={`distribution`}
                        optionValues={Object.values(TypePayement).map((v) => {
                            return {
                                value: v,
                                label: v,
                            };
                        })}
                    >
                        <LabelComponentForm>
                            Distribution<span className="required">*</span>
                        </LabelComponentForm>
                    </SelectComponent>

                    <InputComponent
                        register={register}
                        value={'duration'}
                        type={'number'}
                        className={{
                            input: 'border-2 border-gray-300 p-2 rounded-lg',
                        }}
                    >
                        <LabelComponentForm>
                            Durée <span className="required">*</span>
                        </LabelComponentForm>
                    </InputComponent>

                    <InputComponent
                        register={register}
                        value={'amount'}
                        type={'number'}
                        className={{
                            input: 'border-2 border-gray-300 p-2 rounded-lg',
                        }}
                    >
                        <LabelComponentForm>
                            Montant <span className="required">*</span>
                        </LabelComponentForm>
                    </InputComponent>
                    <div className="flex justify-center items-center mt-3 w-full mx-auto">
                        <PrimaryButton
                            type="submit"
                            className="w-fit"
                            loading={isLoadingCreatePendingTransactions}
                        >
                            Valider
                        </PrimaryButton>
                    </div>
                </form>
            </PopUp>
        </>
    );
}

function TransactionLate({
    transactions,
    projectId,
}: {
    transactions: TransactionDto[];
    projectId: number;
}) {
    const {
        data: project,
        isLoading: isLoadingProject,
        isFetching: isFetchingProject,
        refetch: refetchProject,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const { showSuccess, showError } = useNotificationContext();

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        getValues,
        formState: { dirtyFields },
    } = useForm<TransactionProjectForm>();

    const { data: cashIds } = useGetCashIdsQuery();

    const [
        updateTransactionProject,
        { isLoading: isLoadingUpdateTransaction },
    ] = useUpdateTransactionProjectMutation();

    const setDefautlValues = (): void => {
        if (project && transactions) {
            let transactioinForSorting = [...transactions];
            reset({
                transactions:
                    transactioinForSorting
                        .sort(
                            (a, b) =>
                                new Date(a.date).getTime() -
                                new Date(b.date).getTime()
                        )
                        ?.map((pi) => {
                            let typeProject = undefined;
                            let type = pi?.type;
                            if (type === TypeTransaction.ProjectBuying) {
                                typeProject =
                                    TypeTransactionProject2Cash.Capital;
                                type = TypeTransaction.projectCash;
                            } else if (
                                type === TypeTransaction.projectStopCash
                            ) {
                                typeProject =
                                    TypeTransactionProject2Cash.Closure;
                                type = TypeTransaction.projectCash;
                            } else if (type === TypeTransaction.projectCash) {
                                typeProject =
                                    TypeTransactionProject2Cash.Coupon;
                            }
                            return {
                                ...pi,
                                type,
                                date: new Date(pi.date).toJSON()?.split('T')[0],
                                typeTransactionProject2Cash: typeProject,
                            };
                        }) ?? [],
            });
        }
    };
    useEffect(() => {
        if (project && transactions) {
            setDefautlValues();
        }
    }, [project, transactions]);

    const onSubmit = async (data: TransactionProjectForm) => {
        if (transactions && project) {
            const transactionsForm = data.transactions;

            let transactionBDId = transactions?.map((t) => t.id);

            // transaction changed
            let transactionUpdated = transactionsForm
                ?.filter((t) => transactionBDId?.includes(t.id))
                .filter((t, index) => {
                    let typeTrans = t?.type;
                    let type = TypeTransaction.projectCash;
                    switch (t.typeTransactionProject2Cash) {
                        case TypeTransactionProject2Cash.Capital:
                            type = TypeTransaction.ProjectBuying;
                            break;
                        case TypeTransactionProject2Cash.Closure:
                            type = TypeTransaction.projectStopCash;
                            break;
                        default:
                            break;
                    }
                    if (IsTransactionUpdated(t, transactions)) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .map((t) => {
                    let type = TypeTransaction.projectCash;
                    switch (t.typeTransactionProject2Cash) {
                        case TypeTransactionProject2Cash.Capital:
                            type = TypeTransaction.ProjectBuying;
                            break;
                        case TypeTransactionProject2Cash.Closure:
                            type = TypeTransaction.projectStopCash;
                            break;
                        default:
                            break;
                    }
                    return {
                        ...t,
                        id: t.id,
                        amount: t.amount,
                        date: transformDate(t.date),
                        dateInterest: null,
                        type: type,
                    };
                });
            // transaction added
            let transactionAdded = transactionsForm
                ?.filter((t) => !transactionBDId?.includes(t.id))
                ?.map((t) => {
                    let dataBis: CreateTransactionDto = {
                        to: cashIds?.find((v) => v.fondsId === project.fondsId)
                            ?.id!,
                        from: project.id,
                        amount: stringToFloat(t.amount),
                        date: transformDate(t.date),
                        type: TypeTransaction.projectCash,
                        fondsId: stringToInt(project.fondsId),
                        status: t.status,
                    };
                    let type = TypeTransaction.projectCash;

                    switch (t.typeTransactionProject2Cash) {
                        case TypeTransactionProject2Cash.Capital:
                            type = TypeTransaction.ProjectBuying;
                            break;
                        case TypeTransactionProject2Cash.Closure:
                            type = TypeTransaction.projectStopCash;
                            break;
                        default:
                            break;
                    }
                    dataBis.type = type;
                    return dataBis;
                });
            // transaction deleted
            let transactionId = transactionsForm?.map((t) => t.id);
            let transactionRemoved = transactionBDId?.filter(
                (id) => !transactionId?.includes(id)
            );

            await updateTransactionProject({
                transactionAdded: transactionAdded ?? [],
                transactionRemoved: transactionRemoved ?? [],
                transactionUpdated: transactionUpdated ?? [],
                projectId: projectId,
            })
                .unwrap()
                .then((res) => {
                    showSuccess('Edited', 'Transaction edited successfully');
                })
                .catch((err) => {
                    showError('Error', 'Error editing transaction');
                });
        }
    };

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp w-full md:w-11/12 mx-auto"
            >
                <div className="w-full mx-auto">
                    <TitleComponentForm className="!py-2">
                        <div className="grid md:grid-cols-2">
                            <div className="">Transactions en retard</div>
                            <div className=" md:justify-self-end w-fit">
                                <DeleteAllTransactions setValue={setValue} />
                            </div>
                        </div>
                    </TitleComponentForm>
                    <div className="grid sm:grid-cols-4 w-full relative">
                        <LabelComponentForm>
                            Date <span className="required">*</span>
                        </LabelComponentForm>
                        <LabelComponentForm>
                            Montant <span className="required">*</span>
                        </LabelComponentForm>
                        <LabelComponentForm>
                            Type <span className="required">*</span>
                        </LabelComponentForm>
                        <LabelComponentForm>
                            Statut <span className="required">*</span>
                        </LabelComponentForm>
                    </div>
                    <FieldArray control={control} name="transactions">
                        {({ fields, append, remove }) => (
                            <>
                                <div className="flex flex-col w-full mb-2">
                                    {fields.map((field, index) => (
                                        <div
                                            key={field.id}
                                            className={`grid sm:grid-cols-4 w-full relative border-t-2  ${
                                                watch(
                                                    `transactions.${index}.typeTransactionProject2Cash`
                                                ) ===
                                                TypeTransactionProject2Cash.Capital
                                                    ? 'bg-secondBackgroundColor'
                                                    : watch(
                                                            `transactions.${index}.typeTransactionProject2Cash`
                                                        ) ===
                                                        TypeTransactionProject2Cash.Closure
                                                      ? 'bg-red-500/3'
                                                      : 'bg-primaryColor'
                                            } ${
                                                field.from === undefined
                                                    ? ' !bg-green-200'
                                                    : IsTransactionUpdated(
                                                            watch(
                                                                `transactions.${index}`
                                                            ),
                                                            transactions
                                                        )
                                                      ? ' !bg-yellow-200'
                                                      : ''
                                            }
                          `}
                                        >
                                            {/* <div className="absolute"></div> */}
                                            {/* ${
                            watch(
                              `transactions.${index}.typeTransactionProject2Cash`
                            ) === TypeTransactionProject2Cash.Capital
                              ? "bg-secondBackgroundColor"
                              : watch(
                                  `transactions.${index}.typeTransactionProject2Cash`
                                ) === TypeTransactionProject2Cash.Closure
                              ? "bg-red-500/3"
                              : "bg-primaryColor"
                          } */}

                                            <InputComponent
                                                register={register}
                                                type="date"
                                                value={`transactions.${index}.date`}
                                                className={{
                                                    input: 'row-span-1 col-span-1 !rounded-none !h-[35px] !outline-none !border-none !bg-transparent',
                                                    containerAbove: '!m-0',
                                                }}
                                            ></InputComponent>

                                            <InputComponent
                                                register={register}
                                                type="number"
                                                value={`transactions.${index}.amount`}
                                                className={{
                                                    input: 'row-span-1 col-span-1 !rounded-none !h-[35px] !outline-none !border-none !bg-transparent',
                                                    containerAbove: '!m-0',
                                                }}
                                            ></InputComponent>
                                            <div className="flex items-center gap-2 justify-center">
                                                <SelectComponent
                                                    register={register}
                                                    control={control}
                                                    setValue={setValue}
                                                    watch={watch}
                                                    className="!outline-none !border-none !bg-transparent"
                                                    value={`transactions.${index}.typeTransactionProject2Cash`}
                                                    optionValues={[
                                                        {
                                                            value: TypeTransactionProject2Cash.Coupon,
                                                            label: TypeTransactionProject2Cash.Coupon,
                                                        },
                                                        {
                                                            value: TypeTransactionProject2Cash.Capital,
                                                            label: TypeTransactionProject2Cash.Capital,
                                                        },
                                                        {
                                                            value: TypeTransactionProject2Cash.Closure,
                                                            label: TypeTransactionProject2Cash.Closure,
                                                        },
                                                    ]}
                                                ></SelectComponent>
                                            </div>
                                            <div className="flex items-center gap-2 justify-center">
                                                <SelectComponent
                                                    register={register}
                                                    control={control}
                                                    setValue={setValue}
                                                    watch={watch}
                                                    className="!outline-none !border-none !bg-transparent"
                                                    value={`transactions.${index}.status`}
                                                    optionValues={[
                                                        {
                                                            value: TransactionStatus.VALIDATED,
                                                            label: 'Valider',
                                                        },
                                                        {
                                                            value: TransactionStatus.PENDING,
                                                            label: 'A venir',
                                                        },
                                                        {
                                                            value: TransactionStatus.DELAYED,
                                                            label: 'En retard',
                                                        },
                                                    ]}
                                                ></SelectComponent>
                                            </div>

                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                                className="!top-0"
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="flex mt-3 items-center justify-center">
                                    <PrimaryButton
                                        className=" mx-auto mb-2"
                                        onClick={() =>
                                            append({
                                                date: new Date()
                                                    .toJSON()
                                                    ?.split('T')[0],

                                                amount: 0,
                                                fondsId: project?.fondsId!,
                                                status: TransactionStatus.DELAYED,
                                                TypeTransactionProject2Cash:
                                                    TypeTransactionProject2Cash.Coupon,
                                            } as any)
                                        }
                                    >
                                        Ajouter
                                    </PrimaryButton>
                                </div>
                                <div className="w-11/12 mx-auto flex mt-4 justify-center items-center">
                                    <PrimaryButton
                                        loading={isLoadingUpdateTransaction}
                                        className=" mx-auto mb-2"
                                        type="submit"
                                    >
                                        Edit
                                    </PrimaryButton>
                                </div>
                            </>
                        )}
                    </FieldArray>
                </div>
            </form>
        </>
    );
}

interface DocumentProjectForm {
    documentSchedulePayment: File[];
}
function DocumentSchedulePayment({ projectId }: { projectId: number }) {
    const { showError, showSuccess } = useNotificationContext();

    const {
        data: documents,
        isLoading: loadingDocuments,
        refetch,
    } = useGetDocumentByTypeByProjectQuery(
        { projectId: projectId, type: TypeDocumentProject.schedule_Payment },
        {
            skip: projectId === undefined,
        }
    );

    const {
        data: project,
        isLoading: isLoadingProject,
        isFetching: isFetchingProject,
        refetch: refetchProject,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const {
        register,
        watch,
        setValue,
        reset,
        control,
        getValues,
        handleSubmit,
    } = useForm<DocumentProjectForm>({
        defaultValues: {
            documentSchedulePayment: [],
        },
    });

    const [loading, setLoading] = useState(false);

    const onSubmit = async (data: DocumentProjectForm) => {
        setLoading((curr) => !curr);

        const coverSchedule = data.documentSchedulePayment?.[0];

        if (coverSchedule) {
            let data: UploadDocumentProjectDto = {
                projectId: projectId!,
                type: TypeDocumentProject.schedule_Payment,
                file: coverSchedule,
                fileName: coverSchedule.name,
                projectName: project?.name!,
            };

            await uploadDocumentProject(data)
                .then(async (res) => {
                    if (res.sucess) {
                        showSuccess('Created', 'File uploaded');
                        await new Promise((r) => setTimeout(r, 1000));
                        await refetch();
                        reset();
                    } else {
                        showError(
                            'Error',
                            `Error uploading file ${data.fileName}: ${res.message}`
                        );
                    }
                })
                .catch((res) => {
                    showError('Error', 'Error uploading file');
                });
        }
        // wait 1s

        setLoading((curr) => !curr);
    };

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp w-full md:w-11/12 mx-auto"
            >
                <div className="w-full mx-auto">
                    {loadingDocuments ? (
                        <Loading />
                    ) : (
                        <>
                            <TitleComponentForm>
                                Document transaction à venir
                            </TitleComponentForm>
                            <UploaderSingle
                                register={register}
                                value={'documentSchedulePayment'}
                                watch={watch}
                                setValue={setValue}
                                typeFile={['png', 'jpg', 'jpeg']}
                                button={false}
                            ></UploaderSingle>
                            <div className="w-full md:w-10:12">
                                <div className="w-9/12 mx-auto gap-2 flex justify-center items-center flex-col">
                                    {loadingDocuments ? (
                                        <Loading />
                                    ) : (
                                        <>
                                            {documents !== undefined &&
                                            documents.length > 0 ? (
                                                <DisplayDocumentProject
                                                    document={documents![0]}
                                                    key={1}
                                                    refetch={refetch}
                                                />
                                            ) : (
                                                <p>
                                                    Aucun document n'a été
                                                    trouvé
                                                </p>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="w-10/12 mx-auto flex justify-center items-center my-3">
                                <PrimaryButton
                                    type="submit"
                                    loading={loading}
                                    disabled={
                                        watch('documentSchedulePayment') &&
                                        watch('documentSchedulePayment')
                                            ?.length === 0
                                            ? true
                                            : false
                                    }
                                >
                                    Ajouter le document
                                </PrimaryButton>
                            </div>
                        </>
                    )}
                </div>
            </form>
        </>
    );
}
