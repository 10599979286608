import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { Axios, AxiosResponse } from "axios";
import { CreatePlatformDto, PlatformDto, UpdatePlatformDto } from "../../types/platform";
import { useAppSelector } from "../store";


interface platfromState {
    platforms: PlatformDto[];
    loading: boolean;
    errors: any;
}

const initialState: platfromState = {
    platforms: [],
    loading: false,
    errors: null,
};

// actions are processes that get data from backend
export const getPlatforms = createAsyncThunk<
    PlatformDto[]
>("platforms/getPlatforms", async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}platforms`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

interface IAddPlatform extends CreatePlatformDto {
    id: number,
}

export const addPlatform = createAsyncThunk<
    PlatformDto,
    CreatePlatformDto
>("platforms/addPlatform", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "post",
            url: `${import.meta.env.VITE_API_URL}platforms`,
            data: data,
            withCredentials: true,
        });
        return response.data
            ;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const editPlatform = createAsyncThunk<
    PlatformDto,
    UpdatePlatformDto
>("platforms/editPlatform", async (data, thunkApi) => {
    try {
        const response: AxiosResponse<PlatformDto> = await axios({
            method: "put",
            url: `${import.meta.env.VITE_API_URL}platforms/${data.id}`,
            data: { ...data },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});


export const deletePlatform = createAsyncThunk<number, number>(
    "platforms/deletePlatform",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}platforms/${id}`,
                withCredentials: true,
            });
            return id;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);
// reducers -> reduce to a specific state -> changes state

export const PlatformSlice = createSlice({
    name: "platforms",
    initialState,
    reducers: {
        setPlatforms: (
            state,
            action: PayloadAction<PlatformDto[]>
        ) => {
            state.platforms = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPlatforms.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPlatforms.fulfilled, (state, action) => {
            state.platforms = action.payload
            state.loading = false;
        });
        builder.addCase(getPlatforms.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addPlatform.fulfilled, (state, action) => {
            state.platforms = [...state.platforms,
            action.payload,

            ];
            state.loading = false;
        });
        builder.addCase(addPlatform.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addPlatform.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editPlatform.fulfilled, (state, action) => {
            state.loading = false;
            state.platforms = state.platforms.map((platform) => {
                if (platform.id === action.payload.id) {
                    return {
                        ...platform,
                        ...action.payload,

                    };
                } else return platform;
            });
        });
        builder.addCase(editPlatform.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editPlatform.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });

        builder.addCase(deletePlatform.fulfilled, (state, action) => {
            state.loading = false;
            state.platforms = state.platforms.filter(
                (Platform) => Platform.id !== action.payload
            );
        });
        builder.addCase(deletePlatform.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deletePlatform.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
    },
});

export default PlatformSlice.reducer;
export const { setPlatforms } = PlatformSlice.actions;
