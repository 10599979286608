import React, { useEffect, useState } from "react";
import {
  UploadDocumentTransactionDto,
  uploadDocumentTransaction,
  useDeleteTransactionSponsorMutation,
  useEditTransactionSponsorMutation,
  useGetTransactionByIdQuery,
  useGetTransactionDocumentsQuery,
  useGetTransactionSponsorByIdQuery,
  useGetTransactionsSponsorQuery,
} from "../../../redux/features/transactionSlice";
import { addDays, formatDate, transformDate } from "../../../function/Utils";
import {
  useQueryParam,
  StringParam,
  DateParam,
  NumberParam,
} from "use-query-params";
import {
  CreateTransactionSponsorForm,
  TransactionDto,
  TransactionSponsorDto,
  TransactionStatus,
  UpdateTransactionSponsorForm,
} from "../../../types/transactions";
import usePaginationTable from "../../commun/CustomHook/usePaginationTable";
import ExportComponent from "../../commun/ExportComponent";
import FiltrationColumnTable from "../BackOffice/Components/FiltrationColumnTable";
import { typeFiltration } from "../../../types/BackOffice";
import { typeOrderOrFiltration } from "../../../types/fonds";
import Loading from "../../commun/Loading";
import StatusTransaction from "../../commun/StatusTransaction";
import { useForm } from "react-hook-form";
import {
  useGetAllSponsorQuery,
  useGetSponsorToBeRefundQuery,
} from "../../../redux/features/sponsorSlice";
import { useGetUsersSearchQuery } from "../../../redux/features/userSlice";
import { useNotificationContext } from "../../Context/notification-context";
import { WhiteButton, PrimaryButton } from "../../commun/Buttons";
import PopUp from "../../commun/PopUp";
import InputComponent from "../../commun/formComponent/InputComponent";
import SelectComponent from "../../commun/formComponent/SelectComponent";
import UploaderMulti from "../../commun/formComponent/UploaderMulti";
import useDeleteItem from "../../commun/CustomHook/useDeleteItem";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TransactionsSponsorTable = () => {
  const [sponsorName, setSponsorName] = useQueryParam(
    "sponsorName",
    StringParam
  );
  const [sponsoredName, setSponsoredName] = useQueryParam(
    "sponsoredName",
    StringParam
  );
  let date = new Date();
  date = transformDate(date);

  const [dateStart, setDateStart] = useQueryParam("dateStart", DateParam);
  const [dateEnd, setDateEnd] = useQueryParam("dateEnd", DateParam);
  const [status, setStatus] = useQueryParam<TransactionStatus>("status");

  const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
  const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);

  const { data: transactionsCount, isLoading: isLoadingTransactions } =
    useGetTransactionsSponsorQuery({
      limit: nbItemsShowed,
      offset: startIntervalNbItems,
      sponsorName: sponsorName || "",
      sponsoredName: sponsoredName || "",
      dateStart: dateStart || addDays(date, -4 * 365),
      dateEnd: dateEnd || date,
      status: status || TransactionStatus.VALIDATED,
    });

  const transactions = transactionsCount?.rows;
  const nbItems = transactionsCount?.count;

  const { ComponentFilter, dataFiltered, setFilterColumn } =
    usePaginationTable<TransactionSponsorDto>({
      initialOrder: "date",
      data: transactions || [],
      nbItemsShowed: nbItemsShowed,
      startIntervalNbItems: startIntervalNbItems,
      setNbItemsShowed: setNbItemsShowed,
      setStartIntervalNbItems: setStartIntervalNbItems,
    });

  const classNameObejct = {
    head: "text-sm text-gray-900 px-6 py-4 text-left font-semibold text-left",
    item: "text-sm font-light px-6 py-4 whitespace-nowrap  text-right",
  };

  const [toggleEdit, setToggleEdit] = useState(false);

  return (
    <div className="mt-4">
      {toggleEdit ? (
        <EditTransactionSponsor open={toggleEdit} setOpen={setToggleEdit} />
      ) : null}
      <ComponentFilter offset={true} nbItems={nbItems ?? 0}>
        <div className="relative md:absolute md:top-3 md:right-3">
          <ExportComponent
            headers={[
              "SponsorName",
              "SponsoredName",
              "amount",
              "date",
              "Status",
            ]}
            title="Parrainage"
            data={dataFiltered.map((t) => {
              return {
                SponsorName: t.userNameSponsor,
                SponsoredName: t.userNameSponsored,
                amount: t.amount,
                date: formatDate(new Date(t.date)),
                Status: t.status,
              };
            })}
          />
        </div>
      </ComponentFilter>
      <div className="flex flex-col max-w-full overflow-x-auto">
        <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
          <div className="py-2 inline-block min-w-full">
            {/* <p>
  Valeur total du fonds{" "}
  {printLargeValue(
    projectValorisationInfoData?.valorisationByProjects?.toFixed(
      2
    )
  )}
</p> */}
            <div className="overflow-auto">
              <table className="table-auto overflow-scroll w-full">
                <thead className="bg-white border-b border-t">
                  <tr>
                    <th scope="col" className={classNameObejct.head}>
                      #
                    </th>
                    <FiltrationColumnTable
                      element={"userNameSponsor"}
                      type={typeFiltration.name}
                      setFilterColumn={setFilterColumn}
                      setSearch={setSponsoredName}
                      columnName={() => <p className="inline-block">Sponsor</p>}
                      orderOrFiltration={typeOrderOrFiltration.filter}
                      textLeft={true}
                    />
                    <FiltrationColumnTable
                      element={"userNameSponsored"}
                      type={typeFiltration.name}
                      setFilterColumn={setFilterColumn}
                      setSearch={setSponsorName}
                      columnName={() => (
                        <p className="inline-block">Sponsored</p>
                      )}
                      orderOrFiltration={typeOrderOrFiltration.filter}
                      textLeft={true}
                    />
                    <FiltrationColumnTable
                      element={"amount"}
                      type={typeFiltration.amount}
                      setFilterColumn={setFilterColumn}
                      columnName={() => <p className="inline-block">Montant</p>}
                      orderOrFiltration={typeOrderOrFiltration.order}
                      textLeft={false}
                    />

                    <FiltrationColumnTable
                      element={"date"}
                      type={typeFiltration.date}
                      setFilterColumn={setFilterColumn}
                      columnName={() => (
                        <p className="inline-block text-center">Date</p>
                      )}
                      orderOrFiltration={typeOrderOrFiltration.order}
                      textLeft={true}
                    />

                    <FiltrationColumnTable
                      element={"status"}
                      setSearch={setStatus}
                      search={status}
                      type={typeFiltration.exact}
                      setFilterColumn={setFilterColumn}
                      orderOrFiltration={typeOrderOrFiltration.filter}
                      value={["all"].concat(
                        Object.values(TransactionStatus).map((v) => v)
                      )}
                      columnName={() => <p className="inline-block">Statut</p>}
                      textLeft={false}
                    />
                  </tr>
                </thead>
                <tbody className="bg-white ">
                  {isLoadingTransactions ? (
                    <tr className="">
                      <td colSpan={6} className="pt-10 text-center mx-auto">
                        <Loading />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {dataFiltered && (
                        <>
                          {dataFiltered.length === 0 ? (
                            <tr className="w-full mx-auto">
                              <td
                                colSpan={6}
                                className="text-center text-gray-500 text-xl  pt-10"
                              >
                                Aucune transaction touvée
                              </td>
                            </tr>
                          ) : (
                            <React.Fragment>
                              {dataFiltered.map((t, key) => (
                                <RowElement
                                  t={t}
                                  key={key}
                                  number={key}
                                  setOpen={setToggleEdit}
                                />
                              ))}
                            </React.Fragment>
                          )}
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsSponsorTable;

function RowElement({
  t,
  number,
  setOpen,
}: {
  t: TransactionSponsorDto;
  number: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const classNameObejct = {
    item: "text-sm  font-light px-6 py-4 whitespace-nowrap",
  };
  const [transactionId, setQueryParam] = useQueryParam(
    "transactionId",
    NumberParam
  );
  return (
    <tr
      className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
        number % 2 === 0 ? "bg-secondBackgroundColor" : "bg-white "
      } `}
      onClick={() => {
        setQueryParam(t.id);
        setOpen(true);
      }}
    >
      <td className={`${classNameObejct.item}`}>{number + 1}</td>
      <td className={`${classNameObejct.item}`}>{t.userNameSponsor}</td>
      <td className={`${classNameObejct.item}`}>{t.userNameSponsored}</td>
      <td className={`${classNameObejct.item} text-right`}>
        {t?.amount ? t?.amount.toFixed(2) : "-"}
      </td>

      <td className={`${classNameObejct.item} text-center `}>
        {!!t.date &&
          (t.date instanceof Date
            ? t.date.toLocaleDateString("en-GB")
            : new Date(t.date).toLocaleDateString("en-GB"))}
      </td>
      <td className={classNameObejct.item}>
        <StatusTransaction status={t.status} />
      </td>
    </tr>
  );
}

function EditTransactionSponsor({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [transactionId, setQueryParam] = useQueryParam(
    "transactionId",
    NumberParam
  );

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    control,
    formState: { isSubmitting, submitCount },
  } = useForm<UpdateTransactionSponsorForm>();

  const { showError, showSuccess } = useNotificationContext();

  const {
    data: documents,
    isLoading: loadingDocument,
    refetch,
  } = useGetTransactionDocumentsQuery(transactionId ?? 0);

  useEffect(() => {
    refetch();
  }, []);

  const { data: transaction, isLoading: isLoadingTransaction } =
    useGetTransactionSponsorByIdQuery(transactionId ?? 0);

  const { data: sponsors } = useGetSponsorToBeRefundQuery(watch("to"), {
    skip: !watch("to"),
  });

  useEffect(() => {
    if (
      watch("sponsorId") &&
      watch("sponsorId") !== transaction?.sponsorId &&
      sponsors
    ) {
      let amount =
        sponsors.find((s) => s.id === watch("sponsorId"))?.amount || 0;
      setValue("amount", amount);
    }
  }, [watch("sponsorId"), sponsors]);

  const setDefautlValues = (): void => {
    reset({
      ...transaction,
      to: transaction?.to,
      sponsorId: transaction?.sponsorId,
      amount: transaction?.amount,
      status: transaction?.status,
      date: transformDate(new Date(transaction?.date!).getTime())
        .toJSON()
        ?.split("T")[0] as any,
    });
    console.log("transaction", watch());
  };

  useEffect(() => {
    if (transaction) {
      setDefautlValues();
    }
  }, [transaction]);

  const [searchTo, setSearchTo] = useState<string>("");

  const [expanded, setExpanded] = useState<boolean>(true);

  const [editTransaction] = useEditTransactionSponsorMutation();

  const [
    deleteTransaction,
    { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
  ] = useDeleteTransactionSponsorMutation();

  const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
    transactionId: number;
  }>({
    deleteItem: () => deleteTransaction(transactionId || 0),
    toDoIfSuccess: () => setOpen(false),

    message: "transaction",
    isLoading: isLoadingDelete,
  });

  const onSubmit = async (data: UpdateTransactionSponsorForm) => {
    setLoading((curr) => !curr);
    const files = data?.files;
    delete data.files;

    await editTransaction({
      ...data,
      date: transformDate(data.date),
    })
      .unwrap()
      .then(async (res) => {
        try {
          if (files) {
            for (let i = 0; i < files.length; i++) {
              let data: UploadDocumentTransactionDto = {
                transactionId: (res as TransactionDto).id,
                file: files[i],
                fileName: files[i].name,
                // type:(res.payload as TransactionDto)?.type === TypeTransaction.ProjectBuying ? TypeDocumentTransaction. ,
              };
              await uploadDocumentTransaction(data).then((res) => {
                if (res.sucess) {
                  showSuccess("Created", "File uploaded");
                } else {
                  showError(
                    "Error",
                    `Error uploading file ${data.fileName}: ${res.message}`
                  );
                }
              });
            }
          }
        } catch (error) {
          showError("Error", "Error uploading file");
          setLoading((curr) => !curr);
          return;
        }
        showSuccess("Updated", "Transaction updated successfully");
        setLoading((curr) => !curr);
        setOpen(false);
      })
      .catch((err) => {
        showError("Error", "Error updating transaction");
        setLoading((curr) => !curr);
      });
  };

  return (
    <PopUp
      open={open}
      setOpen={setOpen}
      buttonBoolean={false}
      expand={expanded}
      setExpand={setExpanded}
      submitItemName={"Ajouter"}
      onClickSubmit={() => {
        onSubmit(watch());
      }}
      title={() => {
        return (
          <>
            <h3 className="text-2xl font-semibold leading-6 text-gray-900">
              Editer un remboursement de parainage
            </h3>
            {transactionId ? (
              <FontAwesomeIcon
                onClick={() => setOpenDelete(true)}
                icon={faTrash}
                className="absolute top-4 md:top-0 cursor-pointer left-4 transition-all hover:scale-105"
              />
            ) : null}
          </>
        );
      }}
    >
      <DeleteComponent title="cette transaction" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="formTemplateSingUp"
        style={{ width: `${expanded ? "90%" : "80%"}` }}
      >
        {isLoadingTransaction ? (
          <Loading />
        ) : (
          <>
            <div
              className={`w-full mx-auto ${
                expanded
                  ? "sm:flex sm:gap-2 sm:justify-between sm:items-center "
                  : ""
              }`}
            >
              <div className={`w-full ${expanded ? "sm:w-10/12 " : ""}`}>
                <div className="">
                  <h3 className=" font-mainFontFamily text-start">Pour</h3>
                  <div className="border-1 border-gray-400 rounded-md text-start font-semibold">
                    {transaction?.userNameSponsor}
                  </div>
                </div>
                <SelectComponent
                  register={register}
                  control={control}
                  watch={watch}
                  value={"sponsorId"}
                  optionValues={[
                    {
                      label: `${transaction?.userNameSponsored} | montant: ${
                        transaction?.amount || 0
                      } | date: ${formatDate(new Date(transaction?.date!))}`,
                      value: transaction?.sponsorId!,
                    },
                    ...(sponsors?.map((s) => {
                      return {
                        label: `${s.sponsoredName} | montant: ${
                          s?.amount || 0
                        } | date: ${formatDate(new Date(s?.date))}`,
                        value: s.id,
                      };
                    }) || []),
                  ]}
                >
                  <h3 className=" font-mainFontFamily text-start">
                    Selectionnez la parrainage à rembourser
                  </h3>
                </SelectComponent>

                <InputComponent
                  register={register}
                  value={"amount"}
                  type={"number"}
                >
                  <h3 className="font-mainFontFamily">
                    Montant <span className="required">*</span>
                  </h3>
                </InputComponent>
                <InputComponent
                  register={register}
                  value={"date"}
                  type={"date"}
                >
                  <h3 className="font-mainFontFamily">
                    Date <span className="required">*</span>
                  </h3>
                </InputComponent>
                <SelectComponent
                  register={register}
                  value={"status"}
                  container={true}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  watch={watch}
                  optionValues={Object.values(TransactionStatus).map(
                    (v: string) => {
                      return {
                        value: v,
                        label: v,
                      };
                    }
                  )}
                >
                  <h3 className="font-mainFontFamily">
                    Status <span className="required">*</span>
                  </h3>
                </SelectComponent>
              </div>
              {expanded ? (
                <div className="w-full">
                  <UploaderMulti
                    register={register}
                    value={"files"}
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    size={10}
                    button={false}
                  >
                    <h3 className="text-2xl mb-5">Ajouter des documents</h3>
                  </UploaderMulti>
                </div>
              ) : null}
            </div>
            <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
              <WhiteButton
                onClick={() => {
                  setOpen(false);
                }}
                className="w-full"
              >
                Cancel
              </WhiteButton>
              <PrimaryButton type="submit" className="w-full">
                {loading !== undefined && loading === true ? (
                  <Loading size={4} />
                ) : (
                  "Edit"
                )}
              </PrimaryButton>
            </div>
          </>
        )}
      </form>
    </PopUp>
  );
}
