export default function TitleComponentForm({
    children,
    className,
    onClick,
}: {
    className?: string;
    children: React.ReactNode;
    onClick?: () => void;
}) {
    return (
        <h2
            onClick={onClick}
            className={`uppercase text-xl relative pl-3 lg:col-span-2 bg-secondBackgroundColor rounded-md p-1 text-secondColor mt-6 mb-2 font-mainFontFamily ${className}`}
        >
            {children}
        </h2>
    );
}
