import React, { ReactNode } from "react";
import {
  FieldErrorsImpl,
  FieldValues,
  Path,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";

interface Props<T extends FieldValues> {
  values: {
    label: string;
    name: Path<T> & keyof T;
  }[];
  register: UseFormRegister<T>;
  watch: UseFormWatch<T>;
  errors?: FieldErrorsImpl<T>;
  componentEnd?: () => JSX.Element;
  className?: {
    input?: string;
    label?: string;
    container?: string;
  };
  cursorNotAllowed?: true;
}

const CheckboxComponent = <T extends object>(
  props: Props<T> & { children?: ReactNode }
) => {
  const jsxFunction = (): JSX.Element => {
    return (
      <>
        {props.values?.map((value, key) => (
          <div
            key={key}
            className="checkbox_container relative form-item form-check items"
          >
            <input
              type="checkbox"
              id={value.name}
              checked={props.watch(value.name) == true ? true : false}
              defaultChecked={props.watch(value.name) == true ? true : false}
              {...props.register(value.name)}
              className={`form-check-input !mt-[3px] ${
                props?.cursorNotAllowed && "pointer-events-none"
              } ${
                props.errors && props.errors[value.name] ? "is-invalid" : ""
              }`}
            />
            <label
              className={`pointer ${
                props?.className?.label && props?.className?.label
              }`}
              htmlFor={value.name}
            >
              {value.label}
            </label>
          </div>
        ))}
      </>
    );
  };
  return (
    <React.Fragment>
      {props.children}
      {props.cursorNotAllowed ? (
        <div className="cursor-not-allowed w-full">{jsxFunction()}</div>
      ) : (
        <>
          {props?.className?.container ? (
            <div className={`${props?.className?.container}`}>
              {jsxFunction()}
            </div>
          ) : (
            <>{jsxFunction()}</>
          )}
        </>
      )}

      {!!props.componentEnd && props.componentEnd()}
    </React.Fragment>
  );
};

export default CheckboxComponent;
