import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import React, { ReactNode, memo, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useController, useForm } from 'react-hook-form';
import logo from '../../assets/logo.png';
import { PrimaryButton } from './Buttons';
import SlideOverComponent from './SlideOverComponent';
import RadioComponent from './formComponent/RadioComponent';

// import fs from "fs";
// Function to set vfs for pdfMake
// Set the vfs using the setter function
//setVfs(pdfFonts.pdfMake.vfs);
//(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

export interface UserInfoPdf {
    name: string;
}

interface Props<T> {
    data: T[];
    headers: string[];
    userData?: UserInfoPdf;
    title?: string;
}

interface Form {
    exportformat: string;
}

const CSVPreview = memo(function CSVPreview<T>({ headers, data }: Props<T>) {
    // console.log([data.header, ...[data.subheader, ...data.values]]);
    console.log([headers, ...data?.map((item: any) => Object.values(item))]);
    return (
        <div className="bg-white px-4 py-6 text-sm m-2 overflow-scroll">
            <p className="whitespace-pre-line ">
                {/* {toCSV([headers, ...data?.map((item: any) => Object.values(item))])} */}
            </p>
        </div>
    );
});

const ExportComponent = <T extends object>(
    props: Props<T> & { children?: ReactNode }
) => {
    const onClick = () => {};
    const [choseToggle, setChoseToggle] = React.useState(false);
    const [fileName, setFilename] = useState(
        `${new Date().toISOString()?.split('T')?.[0]}${
            props?.title ? `-${props?.title}` : ''
        }`
    );

    const [downloadInfo, setDownloadInfo] = useState<any>(null);

    const { register, watch, getValues, control } = useForm<Form>({
        defaultValues: {
            exportformat: 'pdf',
        },
    });

    const {
        field: { value: exportFormat, onChange: exportFormatOnChange },
    } = useController<Form>({
        name: 'exportformat',
        control: control,
    });

    useEffect(() => {
        if (exportFormat == 'csv') {
            // const uri = csvBuildURI([props.headers, ...props.data]);
            // setDownloadInfo({ uri, fileName });
        } else if (exportFormat == 'pdf') {
            const uri = BuildPdf({
                data: props.data,
                headers: props.headers,
                userData: props.userData,
            });
            setDownloadInfo({ uri, fileName });
        } else {
            setDownloadInfo(null);
        }
    }, [exportFormat]);

    return (
        <div className="realtive">
            <SlideOverComponent
                open={choseToggle}
                setOpen={setChoseToggle}
                title="Exporter"
            >
                <div className="flex flex-col space-x-4 px-5">
                    <div>
                        <div className=" space-y-4">
                            <RadioComponent
                                watch={watch}
                                register={register}
                                name="exportformat"
                                values={[
                                    {
                                        label: 'PDF',
                                        value: 'pdf',
                                    },
                                    {
                                        label: 'CSV',
                                        value: 'csv',
                                    },
                                ]}
                            >
                                <h3 className="text-lg font-mainFontFamily leading-6 font-medium mb-3 text-gray-900">
                                    Options
                                </h3>
                            </RadioComponent>

                            <div>
                                <label
                                    htmlFor="filename"
                                    className="block text-lg font-medium text-gray-700"
                                >
                                    Nom du fichier
                                </label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                        disabled={!downloadInfo}
                                        type="text"
                                        name="filename"
                                        id="filename"
                                        className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-orange-400 focus:border-orange-400 sm:text-sm border-gray-300 disabled:opacity-50"
                                        value={fileName}
                                        onChange={(e) =>
                                            setFilename(e.target.value)
                                        }
                                    />
                                    <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                        .{exportFormat}
                                    </span>
                                </div>
                            </div>

                            <div className="flex justify-center items-center space-x-3">
                                <a
                                    href={downloadInfo?.uri}
                                    download={
                                        downloadInfo?.fileName +
                                        '.' +
                                        exportFormat
                                    }
                                    // onClick={exportReport}
                                    // type="button"
                                    className=""
                                >
                                    {exportFormat === 'csv' ? (
                                        <CSVLink
                                            filename={fileName}
                                            data={props.data}
                                        >
                                            <PrimaryButton
                                                disabled={!downloadInfo}
                                            >
                                                Télécharger
                                            </PrimaryButton>
                                        </CSVLink>
                                    ) : (
                                        <PrimaryButton disabled={!downloadInfo}>
                                            Télécharger
                                        </PrimaryButton>
                                    )}
                                    {/* <button
                    disabled={!downloadInfo}
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
                  >
                    Download Report
                  </button> */}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3">
                        <>
                            <h3 className="text-lg font-mainFontFamily leading-6 font-medium mb-3 text-gray-900">
                                Preview
                            </h3>
                            <div
                                className={` opacity-100 h-96 ${
                                    exportFormat == 'pdf' ? 'bg-gray-500' : ''
                                }  w-full flex flex-col mb-4`}
                            >
                                {exportFormat === 'csv' ? (
                                    // <CSVPreview
                                    //   headers={props.headers}
                                    //   data={props.data}
                                    // ></CSVPreview>
                                    <>
                                        {' '}
                                        <CSVLink
                                            title={fileName}
                                            data={props.data}
                                        >
                                            CSV
                                        </CSVLink>
                                    </>
                                ) : (
                                    <PdfPreview
                                        data={props.data}
                                        headers={props.headers}
                                        userData={props.userData}
                                    ></PdfPreview>
                                )}
                            </div>
                        </>
                    </div>
                </div>
            </SlideOverComponent>
            <motion.div
                className="py-1 px-2 cursor-pointer flex items-center gap-2 rounded-md bg-secondBackgroundColor shadow-low w-fit"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setChoseToggle(!choseToggle)}
            >
                <FontAwesomeIcon
                    icon={faCloudArrowDown}
                    className="mr-2 text-mainColor"
                />
                <p className="test font-titleFontFamily text-mainColor">
                    Exporter
                </p>
            </motion.div>
            {/* <AnimatePresence>
        {choseToggle ? (
          <div className="absolute flex flex-col bottom-[-50px] left-0 w-full h-full  bg-opacity-50 justify-center items-center">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.1 }}
              whileHover={{ translateX: 1.15 }}
              whileTap={{ scale: 0.95 }}
              onClick={async () => {
                await handlePdfClick();
              }}
              className="mb-1 z-10 cursor-pointer mt-2 px-2 py-1 flex gap-1 items-center rounded-md opacity-80 bg-white shadow-low"
            >
              <FontAwesomeIcon
                icon={faArrowTurnRight}
                className="text-titleColor"
              />
              PDF
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              whileHover={{ translateX: 1.15 }}
              whileTap={{ scale: 0.95 }}
              className="px-2 z-10 py-1 cursor-pointer flex gap-1 items-center rounded-md opacity-80 bg-white shadow-low"
              transition={{
                duration: 0.1,
                delay: 0.1,
              }}
            >
              <FontAwesomeIcon
                icon={faArrowTurnRight}
                className="text-titleColor"
              />
              <CSVLink data={props.data}>CSV</CSVLink>
            </motion.div>
          </div>
        ) : null}
      </AnimatePresence> */}
        </div>
    );
};

export default ExportComponent;

function PdfPreview<T>({
    data,
    headers,
    userData,
}: {
    data: T[];
    headers: string[];
    userData?: UserInfoPdf;
}) {
    const uri = BuildPdf({ data, headers, userData, preview: true });

    return uri ? (
        <div className="h-full m-2">
            <object
                data={uri}
                type="application/pdf"
                width="100%"
                height="100%"
            ></object>
            {/* <p>
          Preview not working download here <a href={uri}></a>
        </p> */}
        </div>
    ) : (
        <div className="text-white h-full m-2 flex items-center justify-center">
            Something went wrong when generating report.
        </div>
    );
}

function BuildPdf<T>({
    data,
    headers,
    userData,
    preview = false,
}: {
    data: T[];
    headers: string[];
    userData?: UserInfoPdf;
    preview?: boolean;
}) {
    const doc = new jsPDF();

    const img = new Image();
    img.src = logo;

    doc.setFontSize(20);
    doc.setFontSize(20);
    doc.setTextColor(40);
    if (userData?.name) {
        doc.text(userData?.name, 10, 35);
    }
    doc.setFontSize(15);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 10, 45);

    // @ts-ignore
    doc.autoTable({
        startY: 55,
        head: [headers],
        theme: 'striped',
        margin: { horizontal: 10, top: 35 },
        body: data?.map((item: any) => Object.values(item)),
        didDrawPage: (data: any) => {
            // Header
            // doc.setFontSize(20);
            // doc.setTextColor(40);
            // doc.text("Report", data.settings.margin.left, 22);
            doc.addImage(
                img,
                'png',
                210 - data.settings.margin.left - 18,
                8,
                24,
                24
            );

            // // Header
            // doc.setFontSize(20);
            // doc.setTextColor(40);
            // doc.text("Report", data.settings.margin.left, 22);
            // Footer
            // @ts-ignore
            const str = 'Page ' + doc.internal.getNumberOfPages(); // does not exist

            doc.setFontSize(10);

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height
                ? pageSize.height
                : pageSize.getHeight();

            doc.text(str, data.settings.margin.left, pageHeight - 10);
        },
    });

    if (!preview) {
        return doc.output('datauristring');
    } else {
        const blob = doc.output('blob');
        return URL.createObjectURL(blob);
    }
}

function PdfExport<T>({}: { data: T[]; headers?: string[] }) {}
