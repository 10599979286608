import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AuditLogDto } from "../../types/AuditLog";

interface AuditLogState {
    allAuditLog: AuditLogDto[];
    auditLogOnUser: AuditLogDto[];
    auditLogAction: AuditLogDto[];
    loading: boolean;
    errors: any;
}

const initialState: AuditLogState = {
    allAuditLog: [],
    auditLogOnUser: [],
    auditLogAction: [],
    loading: false,
    errors: null,
}

// actions are processes that get data from backend
export const getAuditLogs = createAsyncThunk<AuditLogDto[]>(
    "knowlegde/getAuditLogs",
    async (_, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}auditLog/`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getAuditLogsActionByUserId = createAsyncThunk<AuditLogDto[], number>(
    "knowlegde/getAuditLogsActionByUserId",
    async (userId, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}auditLog/action/${userId}`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getAuditLogsOnUserByUserId = createAsyncThunk<AuditLogDto[], number>(
    "knowlegde/getAuditLogsOnUserByUserId",
    async (userId, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}auditLog/onUser/${userId}`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const knowledgeSlice = createSlice({
    name: "knowledge",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAuditLogs.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAuditLogs.fulfilled, (state, action) => {
            state.loading = false;
            state.allAuditLog = action.payload;
        })
        builder.addCase(getAuditLogs.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })
        builder.addCase(getAuditLogsActionByUserId.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAuditLogsActionByUserId.fulfilled, (state, action) => {
            state.loading = false;
            state.auditLogAction = action.payload;
        })
        builder.addCase(getAuditLogsActionByUserId.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })
        builder.addCase(getAuditLogsOnUserByUserId.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAuditLogsOnUserByUserId.fulfilled, (state, action) => {
            state.loading = false;
            state.auditLogOnUser = action.payload;
        })
        builder.addCase(getAuditLogsOnUserByUserId.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })
    }
})

export default knowledgeSlice.reducer;