import {
  ArrayPath,
  Control,
  FieldArrayWithId,
  FieldValues,
  Path,
  UseFieldArrayReturn,
  useFieldArray,
} from "react-hook-form";

interface Props<T extends FieldValues> {
  control: Control<T, Path<T>>;
  name: ArrayPath<T>;
  className?: string;
  children: ({
    fields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
    replace,
  }: UseFieldArrayReturn<T>) => React.ReactNode;
}

export default function FieldArray<T extends object>({
  control,
  className,
  name,
  children,
}: Props<T>) {
  const obj = useFieldArray<T>({
    control,
    name: name,
  });

  return <div className={className}>{children(obj)}</div>;
}
