import React, { useContext, useEffect, useState } from 'react';

import FormulaireEtape0 from './FormulaireEtape0';

import { isEmpty } from '../../../../function/Utils';
import { useFetchTokenQuery } from '../../../../redux/features/tokenSlice';
import {
    CheckUserProfileForUserDto,
    DocumentUserDto,
    StateStatus,
    UserStatusType,
} from '../../../../types/user';
import StepComponent, { IStep } from '../../../commun/StepComponent';
import {
    EtapeForm,
    MenuDashBoardUserBoolState,
    StepFormInterface,
} from '../../../Context/AppContext';
import FormulaireEtapeKnowledge from './FormEtapeKnowledge';
import FormulaireEnd from './FormulaireEnd';
import FormulaireEtapeInvestment from './FormulaireEtapeInvestment';

interface Props {
    validation?: CheckUserProfileForUserDto;
    documents?: DocumentUserDto[];
}

const FormulaireInvestisseur: React.FC<Props> = ({ validation, documents }) => {
    const [step, setStep] = useState<number>(
        parseInt(sessionStorage.getItem('stepFormProfil') || '0')
    );

    const [stepInsideStep0, setStepInsideStep0] = useState<number>(
        parseInt(sessionStorage.getItem('nextFormProfil') || '0')
    );

    useEffect(() => {
        sessionStorage.setItem('stepFormProfil', step.toString());
    }, [step]);

    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

    useEffect(() => {
        if (user?.status === UserStatusType.informationNeedToBeChecked) {
            sessionStorage.setItem('stepValidationForm', '1');
            setStep(3);
        }
    }, [user]);

    const etapeFunction = () => {
        if (user) {
            if (user.status === UserStatusType.informationNeedToBeChecked) {
                sessionStorage.setItem('stepValidationForm', '1');
                return <FormulaireEnd />;
            }

            if (!validation) {
                switch (step) {
                    case 0:
                        return <FormulaireEtape0 />;
                    case 1:
                        return <FormulaireEtapeInvestment />;
                    case 2:
                        return <FormulaireEtapeKnowledge />;
                    case 3:
                        return <FormulaireEnd />;
                    default:
                        return <></>;
                }
            } else {
                if (step === 0) {
                    return <FormulaireEtape0 />;
                } else {
                    return <FormulaireEnd reValidationForm={true} />;
                }
            }
        }
    };

    useEffect(() => {
        let elementDashBoard = document.querySelector(
            '.form_investisseur_container'
        ) as HTMLElement;
        elementDashBoard.scrollTo(0, 0);
        // if (
        //   toInt(Cookies.get("etape")) < etape ||
        //   Cookies.get("etape") === undefined
        // ) {
        //   Cookies.set("etape", etape.toString(), { expires: 31 });
        // }
    }, [step]);

    const etapeObject: StepFormInterface = {
        step: step,
        setStep: setStep,
        setStepInsideStep0: setStepInsideStep0,
        stepInsideStep0: stepInsideStep0,
    };

    const steps = (): IStep[] => {
        let res = [
            {
                name: 'Identité',
                description: 'Vos informations personnelles',
                href: '#',
                error:
                    validation &&
                    `${
                        (validation?.validIdentity !== undefined &&
                            !validation?.validIdentity) ||
                        (documents &&
                            documents?.filter(
                                (d) => d.status === StateStatus.unValidated
                            ).length > 0)
                            ? 'Veuillez renvoyer le.s document.s érroné.s où modifier vos informations'
                            : ''
                    } 
              ${
                  validation?.validIdentity !== undefined &&
                  !validation?.validIdentity &&
                  validation.commentIdentity !== undefined
                      ? validation.commentIdentity
                      : ''
              }`,
            },
        ];

        if (!validation) {
            res.push(
                {
                    name: 'Vos investissements',
                    href: '#',
                    description:
                        "Questionnaire afin d'établir vos objectifs et votre expérience d'invesstissement",
                    error: undefined,
                },
                {
                    name: 'Vos connaissances',
                    href: '#',
                    description:
                        "Questionnaire afin d'établir vos connaisances financières",
                    error: undefined,
                }
            );
        }
        res.push({
            name: 'Vérification',
            href: '#',
            description: "Jusqu'à 24h",
            error: undefined,
        });

        return res;
    };

    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    return (
        <EtapeForm.Provider value={etapeObject}>
            <div
                className={`mx-0 w-[100%] md:ml-2 rounded-lg shadow-low  md:mt-[6px] h-[98vh] lg:px-2  overflow-auto form_investisseur_container`}
            >
                <div
                    className={`mt-12 md:mt-2 mx-auto ${
                        menuBoolState?.toggleMenu === false ? '!w-[96%]' : ''
                    }`}
                >
                    <StepComponent steps={steps()} current={step} />
                </div>
                {/* {!!(etape < 8) && (
          <div className="head grid_start_end">
            <p className="I_1 pointer" onClick={() => saveData()}></p>
            <p className="I_2">
              Questionnaire investisseur <br />
              <span>Etape {etape} / 7</span>
            </p>
          </div>
        )} */}

                {!isEmpty(user) && etapeFunction()}
            </div>
        </EtapeForm.Provider>
    );
};

export default FormulaireInvestisseur;
