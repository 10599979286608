// import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { fondInterface } from "../../types/data";
import CareScaleFond from "../commun/CareScaleFond";

interface Props {
  cover: string;
  name: string;
  rendement: number;
  liquidite: number;
  social: number;
  environnemental: number;
  activeFond: number;
  setActiveFond: React.Dispatch<React.SetStateAction<number>>;
  numero: number;
  activeCategoryFond: string;
  path: string | false;
}

const FondItem: React.FC<Props> = ({
  cover,
  name,
  rendement,
  liquidite,
  social,
  environnemental,
  activeFond,
  setActiveFond,
  numero,
  activeCategoryFond,
  path,
}) => {
  let classFond = "fond_sub_item";
  function classFondFunction() {
    if (numero == activeFond) {
      classFond = "fond_active fond_sub_item";
    } else if (numero > activeFond) {
      classFond = "fond_after fond_sub_item";
    } else {
      classFond = "fond_before fond_sub_item";
    }
  }

  if (activeCategoryFond == "") {
    classFondFunction();
  }

  return (
    <li className="fond_item" onClick={() => setActiveFond(numero)}>
      <div className={classFond}>
        <h2>{name}</h2>
        <img className="fond_item_cover" src={cover} alt={`${name} cover`} />
        <p>
          Rendement : <span className="title-color lighter">{rendement} %</span>
        </p>
        <p>
          Liquidité :{" "}
          <span className="title-color lighter">{liquidite} ans minimum</span>
        </p>

        <div className="careScale_container">
          <p className="colorTitle">Impact</p>
          <div className="careScaleFond">
            <div>Social :</div>
            <div className="cercle_container">
              <CareScaleFond scaleValue={social} />
            </div>
          </div>
          <div className="careScaleFond">
            <div>Environnemental :</div>
            <div className="cercle_container">
              <CareScaleFond scaleValue={environnemental} />
            </div>
          </div>
        </div>
        <div className="discover_container">
          <div className="line"></div>
          {path ? (
            <Link to={path} className="pointer">
              Découvrir
            </Link>
          ) : (
            <p className="text_center">Découvrir prochainement</p>
          )}
          <div className="line"></div>
        </div>
        <div className="line_fin"></div>
      </div>
    </li>
  );
};

export default FondItem;
