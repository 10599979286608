import { DocusealForm } from '@docuseal/react';
import jsPDF, { TextOptionsLight } from 'jspdf';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    formatDate,
    getInitialAmountTransanction,
    printLargeValue,
} from '../../../function/Utils';
import { useGetSubmissionMutation } from '../../../redux/features/docuSealSlice';
import { useFetchTokenQuery } from '../../../redux/features/tokenSlice';
import {
    SubscriptionContractForSigningDto,
    useContractSignedMutation,
    useGetSubscriptionUserBySubscriptionIdQuery,
    useGetUserWithAllInfoForCustomerQuery,
    useSendSubscriptionToBeSignedMutation,
} from '../../../redux/features/userSlice';
import { CategoryQuestion } from '../../../types/KnowledgeDto';
import { SubscriptionUserFondsDto } from '../../../types/SubscriptionUserFondsDto';
import { UserWithAllInfoDto } from '../../../types/user';
import { PrimaryButton } from '../../commun/Buttons';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import { MenuDashBoardUserBoolState } from '../../Context/AppContext';
import { useNotificationContext } from '../../Context/notification-context';
import logo from './../../../assets/logo_name.png';
import { displayTitreName } from './EditSubscription';

const GenerateContract = ({
    s,
    sign,
    setSign,
    admin = false,
}: {
    s: SubscriptionUserFondsDto;
    sign: boolean;
    setSign: React.Dispatch<React.SetStateAction<boolean>>;
    admin?: boolean;
}) => {
    const { data: userFetch, isLoading: isLoadingFetch } = useFetchTokenQuery();

    const { data: user, isLoading: isLoading } =
        useGetUserWithAllInfoForCustomerQuery(userFetch?.id || 0, {
            skip: userFetch ? false : true,
        });

    const [uri, setUri] = useState<undefined | string>();
    const [uriPrview, setUriPreview] = useState<undefined | string>();

    useEffect(() => {
        if (user && s) {
            setUri(generateInvestmentContractPdf(user, s));
            setUriPreview(generateInvestmentContractPdf(user, s, true));
        }
    }, [user, s]);

    const { showError, showSuccess } = useNotificationContext();
    const [slug, setSlug] = useState<undefined | string>();

    const [sendPdf, { isLoading: isLoadingSend }] =
        useSendSubscriptionToBeSignedMutation();

    const sendPdfToBeSigned = async () => {
        if (uri) {
            const data: SubscriptionContractForSigningDto = {
                file: uri?.split(',')[1],
                userId: userFetch?.id!,
                subscriptionUserFondsId: s.id,
                email: user?.email!,
                phone: user?.userInfo?.phone!,
                name: `Souscription n° ${getNumeroBulletinSubscriptions(s.id)}- ${
                    user?.firstName
                } ${user?.lastName.toUpperCase()}`,
            };
            // Update if contract already exists
            // Add slug to susbsription Add table contractInfo (slud, templateId, dateGenerated)
            await sendPdf(data)
                .unwrap()
                .then((data: any) => {
                    setSlug(data.slug);
                    setSign(true);
                    menuBoolState?.setToggleMenu(!menuBoolState?.toggleMenu);
                    showSuccess('Succès', 'Le document peut être signé');
                })
                .catch((e) => {
                    showError(
                        'Erreur',
                        "Une erreur s'est produite lors de l'envoi du document"
                    );
                });
        }
    };

    const [getSubmission, { isLoading: isLoadingGetSubmission }] =
        useGetSubmissionMutation();

    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    return (
        <>
            {isLoadingFetch || isLoading ? (
                <Loading />
            ) : uriPrview ? (
                <>
                    {/* h-[96vh] */}
                    <div className=" m-2 ">
                        {isLoadingFetch || isLoading || isLoadingSend ? (
                            <Loading />
                        ) : (
                            <>
                                {slug && sign ? (
                                    <SignDocument
                                        slug={slug}
                                        email={user?.email!}
                                        sId={s.id}
                                        userId={userFetch?.id!}
                                        phoneNumber={user?.userInfo?.phone!}
                                        setSign={setSign}
                                    />
                                ) : (
                                    <>
                                        {/* <embed
                      src={uriPrview}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    ></embed> */}
                                        <object
                                            data={uriPrview}
                                            type="application/pdf"
                                            width="100%"
                                            height="100%"
                                            // style={{ minHeight: "80vh" }}
                                            id="pdfObject"
                                            onLoad={() => {
                                                if (
                                                    document.getElementById(
                                                        'pdfObject'
                                                    )
                                                ) {
                                                    document.getElementById(
                                                        'pdfObject'
                                                    )!.style.minHeight = '80vh';
                                                }
                                            }}
                                        >
                                            <p>
                                                Your browser does not support
                                                PDFs.
                                                <a
                                                    href={uriPrview}
                                                    className=" text-mainColor"
                                                    download={'test'}
                                                    target="_blank"
                                                >
                                                    {' '}
                                                    Download the PDF
                                                </a>
                                                .
                                            </p>
                                        </object>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="w-full justify-center flex items-center my-5">
                        {uri && !sign && !admin ? (
                            <PrimaryButton
                                onClick={sendPdfToBeSigned}
                                loading={isLoadingSend}
                            >
                                Signer le document
                            </PrimaryButton>
                        ) : null}
                        {/* <PrimaryButton
              onClick={() => getSubmission(s.id)}
              loading={isLoadingGetSubmission}
            >
              Get submission
            </PrimaryButton> */}
                    </div>
                </>
            ) : (
                <div className="text-white h-full m-2 flex items-center justify-center">
                    Something went wrong when generating report.
                </div>
            )}
        </>
    );
};

function SignDocument({
    slug,
    email,
    sId,
    userId,
    setSign,
    phoneNumber,
}: {
    slug: string;
    phoneNumber: string;
    email: string;
    sId: number;
    userId: number;
    setSign: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [contractSingned, { isLoading: isLoadingContractSingned }] =
        useContractSignedMutation();

    const { refetch } = useGetSubscriptionUserBySubscriptionIdQuery(
        {
            subscriptionId: sId,
            userId: userId || 0,
        },
        {
            skip: !sId || userId === undefined,
        }
    );
    const text = (
        <>
            Veuillez signer la souscription en inscrivant votre{' '}
            <span className=" font-semibold">nom</span> et{' '}
            <span className=" font-semibold">prénom</span>, et confirmer la
            signature avec le code reçu par SMS. Si, le numéro ({phoneNumber})
            n'est plus valide cliquez{' '}
            <Link
                className=" text-mainColor hover:underline font-semibold"
                to={`/DashBoardClient/donnees-personnelles?pageDonneePerso=edit-user`}
            >
                ici
            </Link>{' '}
            pour le modifier.
        </>
    );

    const [open, setOpen] = useState<boolean>(true);
    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    const [openLoading, setOpenLoading] = useState<boolean>(false);

    const onComplete = async (data: any) => {
        setOpenLoading((curr) => !curr);
        contractSingned({
            jsonSigned: data,
            subscriptionUserFondsId: sId,
            userId: userId,
        })
            .unwrap()
            .then((res: any) => {
                setOpenLoading((curr) => !curr);

                setSign(false);
                window.location.reload();
                // if (res?.message && res.message === "Document not found") {
                //   // await 2s
                //   setTimeout(() => {
                //     setSign(false);
                //     refetch();
                //   }, 2000);
                // } else {

                // }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="relative">
            {isLoadingContractSingned ? (
                <LoadingToGetContract
                    isLoading={isLoadingContractSingned}
                    open={openLoading}
                    setOpen={setOpenLoading}
                />
            ) : null}
            <PopUp
                width=" max-w-[700px]"
                open={open}
                buttonBoolean={false}
                setOpen={setOpen}
            >
                <>
                    <div className=" text-justify">{text}</div>
                    <div className="justify-center mt-4 items-center flex">
                        <PrimaryButton onClick={() => setOpen(false)}>
                            D'accord
                        </PrimaryButton>
                    </div>
                </>
            </PopUp>

            <div className=" z-[19] sticky top-0 bg-thirdBackgroundColor w-full">
                <div className="z-[101] my-2 flex justify-center">
                    <PrimaryButton
                        onClick={() => {
                            setSign(false);
                            menuBoolState?.setToggleMenu(
                                !menuBoolState?.toggleMenu
                            );
                        }}
                    >
                        Annuler
                    </PrimaryButton>
                </div>
                <h3 className="px-2 font-mainFontFamily text-lg">{text}</h3>
            </div>

            <DocusealForm
                src={`https://docuseal.co/d/${slug}`}
                email={email}
                onComplete={(data) => onComplete(data)}
                expand={false}
            />
        </div>
        // </PopUp>
    );
}

export default GenerateContract;

function LoadingToGetContract({
    isLoading,
    open,
    setOpen,
}: {
    isLoading: boolean;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    return (
        <PopUp
            width=" max-w-[700px]"
            open={open}
            buttonBoolean={false}
            setOpen={setOpen}
        >
            <div className="h-full w-full flex items-center justify-center">
                <p> Veuillez patienter quelque instant</p>
                {isLoading ? <Loading /> : null}
            </div>
        </PopUp>
    );
}

// Example function to generate PDF for an investment contract
function generateInvestmentContractPdf(
    user: UserWithAllInfoDto | undefined,
    s: SubscriptionUserFondsDto,
    preview: boolean = false
) {
    const doc = new jsPDF();

    // Add your company logo
    const img = new Image();
    img.src = logo;
    // doc.addImage(imgData, 'JPEG', 10, 10, 50, 15);

    // Contract Title
    const textOptionCenter: TextOptionsLight = {
        align: 'center',
    };

    const mainColor = [25, 153, 179];
    const secondColor = [30, 197, 184];
    const textColor = [0, 0, 0];

    const margin = 5;

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const textOptionParagraph: TextOptionsLight = {
        maxWidth: pageWidth - 30,
        align: 'justify',
    };

    const userName = user?.firstName + ' ' + user?.lastName.toLocaleUpperCase();

    const isWiseedTransfer = s.transactions.some((t) => t.entranceFee === 0.01);

    let text: any;
    // Header
    // TODO: Store info on database and fectch it
    doc.setFontSize(9);
    doc.text('NEW PARADIGMS ASSET MANAGEMENT S.A.R.L', 5, 10);
    doc.text('B279614 RCS Luxembourg', 5, 13);
    doc.text('Société de gestion du groupe New Paradigms', 5, 16);
    doc.text('8 rue Mathias Hardt, L-1717 Luxembourg ville, Luxembourg', 5, 19);

    //   console.log(doc.getFont());
    // Image
    doc.addImage(img, 'png', 165, 8, 38, 20);

    doc.setTextColor(mainColor[0], mainColor[1], mainColor[2]);
    doc.setFontSize(18);
    doc.text('CONTRAT DE SOUSCRIPTION', 105, 35, textOptionCenter);

    // Investor Details
    doc.setDrawColor(mainColor[0], mainColor[1], mainColor[2]);
    const borderMargin = 10;
    doc.setLineWidth(0.3);

    // set border
    doc.rect(
        borderMargin,
        43,
        pageWidth - 2 * borderMargin,
        pageHeight - 2 * borderMargin - (43 - borderMargin)
    );
    doc.setFontSize(12);
    doc.text('PROFIL INVESTISSEUR', 105, 50, textOptionCenter);

    doc.setTextColor(textColor[0], textColor[1], textColor[2]);
    doc.setFontSize(9);

    // @ts-ignore
    doc.setFont('helvetica', 'bold');
    doc.text('INFORMATIONS SUR L’IDENTITE DE L’INVESTISSEUR', margin + 10, 58);

    const investData =
        s.investAccount?.physicalOrMoral === 'physical'
            ? [
                  ['Nom et prénom', userName],
                  [
                      'Date et lieu de naissance',
                      formatDate(
                          user?.userInfo?.birthDay
                              ? new Date(user?.userInfo?.birthDay)
                              : new Date()
                      ) +
                          ' ' +
                          user?.userInfo?.birthPlace,
                  ],
                  ['Adresse postale', user?.userInfo?.adress],
                  ['Téléphone', user?.userInfo?.phone],
                  ['Adress email', user?.email],
              ]
            : [
                  [
                      'Dénomination social',
                      s.investAccount?.moralAccount?.companyName,
                  ],
                  ['SIREN', s.investAccount?.moralAccount?.registrationNumber],
                  [
                      'Siège social',
                      s.investAccount?.moralAccount?.address +
                          ' ' +
                          s.investAccount?.moralAccount?.addressComplement ||
                          '',
                  ],
                  // [
                  //   "Objet social synthétisé",
                  //   s.investAccount?.moralAccount?.socialObject,
                  // ],
                  ['Représentant légal', userName],
                  ['Téléphone', user?.userInfo?.phone],
                  ['Adress email', user?.email],
                  [
                      'Principaux actionnaires (+25 %)',
                      s.investAccount?.moralAccount?.beneficialOwners
                          .filter((b) => b.proportionShareholder >= 25)
                          .map((b) => b.firstName + ' ' + b.lastName)
                          .join(', ') || '',
                  ],
              ];
    // @ts-ignore
    doc.autoTable({
        startY: 60,
        columnStyles: { 1: { fontStyle: 'bold' } },
        body: investData,
    });

    // get Y position of the end of the table
    // @ts-ignore
    const finalY = doc.autoTable.previous.finalY;
    doc.setFontSize(9);

    doc.setFont('helvetica', 'bold');
    doc.text("TEST D'ADEQUATION", margin + 10, finalY + 10);

    const questions = user?.knowledgeUsers
        ?.filter(
            (k) =>
                k.question.category ===
                    CategoryQuestion.experience_investissments ||
                k.question.category === CategoryQuestion.funds_functionning
        )
        .map((k, i) => {
            return [
                i + 1,
                k.question.question,
                '\u2022 ' + k.answers.map((a) => a.answer).join(', '),
            ];
        });
    // @ts-ignore
    doc.autoTable({
        startY: finalY + 12,
        columnStyles: { 1: { fontStyle: 'italic' }, 2: { fontStyle: 'bold' } },
        body: questions,
    });

    // signature en fin de contrat
    doc.setDrawColor(textColor[0], textColor[1], textColor[2]);
    doc.setLineWidth(0.2);

    // set border
    doc.rect(
        borderMargin + 40,
        pageHeight - 50,
        pageWidth - 45 - 2 * borderMargin,
        35
    );
    // @ts-ignore
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text(
        'Signature en fin de contrat',
        borderMargin + 40 + 3,
        pageHeight - 45
    );
    doc.setFont('helvetica', 'bold');
    doc.text(
        "« J'atteste sur l'honneur que les réponses données sont sincères et véritables »",
        borderMargin + 40 + 3,
        pageHeight - 40
    );
    const cityArray = user?.userInfo?.adress?.split(',');
    let city = '';
    if (cityArray && cityArray?.length > 0) {
        city = cityArray[cityArray?.length - 2];
    }

    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    if (!preview) {
        doc.text(`Fait à`, 15, pageHeight - 55);
        doc.text(`{{Fait à;default=${city};height=30}}`, 28, pageHeight - 58);
        doc.text(
            `{{type=stamp;readonly=true;width=120;height=60}}`,
            pageWidth / 2,
            pageHeight - 55 - 20
        );

        doc.text(
            `{{Signature ;type=signature;format=drawn;height=10;width=100}}`,
            borderMargin + 40 + 3,
            pageHeight - 30
        );
        if (import.meta.env.VITE_API_URL === 'https://newparadigms.fr/api/') {
            doc.text(
                `{{Numéro de téléphone;type=phone;required=true;width=0;height=0}}`,
                borderMargin + 40 + 3,
                pageHeight - 18
            );
        }
    } else {
        doc.text(
            `Fait à ${city} , le ${formatDate(new Date())}`,
            15,
            pageHeight - 55
        );
    }

    // For each transaction create a bulletin de souscription
    s?.transactions.forEach((transaction) => {
        const gap = 3;
        doc.addPage();

        const initialAmount = getInitialAmountTransanction(
            transaction.amount,
            transaction?.entranceFee || 0
        );
        const nbShare =
            initialAmount / (transaction?.initialValueOfShare || 100);
        const typeShare = transaction.typeShare === 'cumulative' ? 'C' : 'D';
        // add  a background
        doc.setFillColor(mainColor[0], mainColor[1], mainColor[2]);
        doc.rect(10, 10, pageWidth - 20, 8, 'F');
        doc.setTextColor(255, 255, 255);
        doc.setFontSize(15);
        doc.text(
            `BULLETIN DE SOUSCRIPTION - NP ${transaction.fondsName} - Obligation ${typeShare}`,
            100,
            16,
            textOptionCenter
        );

        doc.setTextColor(textColor[0], textColor[1], textColor[2]);
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(12);
        doc.text(
            `Bulletin de souscription n° ${getNumeroBulletinSubscriptions(s.id)}`,
            13,
            25
        );
        doc.setFont('Helvetica', 'normal');
        doc.setFontSize(8);
        doc.text(`Edité le ${formatDate(new Date())}`, 13, 28);

        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(12);
        doc.text('SOUSCRIPTEUR', 15, 34 + 2);
        doc.setFillColor(textColor[0], textColor[1], textColor[2]);
        doc.rect(15, 35 + 2, pageWidth - 25, 0.1, 'F');

        let yPosition = 0;
        if (s.investAccount?.physicalOrMoral === 'physical') {
            doc.rect(13, 30 + 2, 1, 18, 'F');
            doc.setFontSize(9);
            doc.setFont('Helvetica', 'bold');
            doc.text(userName, 15, 38 + 3);
            doc.setFont('Helvetica', 'normal');
            doc.setFontSize(9);

            doc.text(
                `né(e) le : ${formatDate(
                    user?.userInfo?.birthDay
                        ? new Date(user?.userInfo?.birthDay)
                        : new Date()
                )} à ${user?.userInfo?.birthPlace}, de nationalité ${
                    user?.userInfo?.nationality
                }, résidant au ${user?.userInfo?.adress}`,
                15,
                40 + 4.5
            );
            doc.text(
                `Téléphone : ${user?.userInfo?.phone}, Email : ${user?.email}`,
                15,
                44 + 4
            );
            yPosition = 55 - gap;
        } else {
            const moralAccount = s.investAccount?.moralAccount;
            doc.rect(13, 30 + 2, 1, 30, 'F');
            doc.setFontSize(9);
            doc.setFont('Helvetica', 'bold');
            doc.text(
                `Société ${moralAccount?.companyName} ${
                    moralAccount?.legalStatusOther ??
                    moralAccount?.legalStatusOther
                } (${moralAccount?.registrationNumber})`,
                15,
                38 + 3
            );
            doc.setFont('Helvetica', 'normal');
            doc.text(
                `Siège social: ${moralAccount?.address} ${
                    moralAccount?.addressComplement || ''
                }`,
                15,
                40 + 4.5
            );

            doc.setFontSize(9);
            doc.setFont('Helvetica', 'bold');
            doc.text(`Réprésentée par ${userName}`, 15, 38 + 3 + 20);
            doc.setFont('Helvetica', 'normal');
            doc.setFontSize(10);
            doc.text(
                `né(e) le : ${formatDate(
                    user?.userInfo?.birthDay
                        ? new Date(user?.userInfo?.birthDay)
                        : new Date()
                )} à ${user?.userInfo?.birthPlace}, de nationalité ${
                    user?.userInfo?.nationality
                }, résidant au ${user?.userInfo?.adress}`,
                15,
                40 + 4.5 + 20
            );
            doc.text(
                `Téléphone : ${user?.userInfo?.phone}, Email : ${user?.email}`,
                15,
                44 + 4 + 20
            );

            yPosition = 68 + 10 - gap;
        }
        // Investissement
        yPosition += 5;
        headerComponent('INVESTISSEMENT', yPosition, 20, doc);

        doc.setFont('Helvetica', 'bold');
        text = [
            {
                content: `Souscription de`,
                type: 'normal',
            },
            {
                content: ` ${nbShare} obligation(s) ${typeShare} - ${displayTitreName(
                    transaction.typeShare!
                )} de ${transaction.initialValueOfShare} unitaire `,
                type: 'bold',
            },
            {
                content: 'soit une somme totale de : ',
                type: 'normal',
            },
            {
                content: `${printLargeValue(initialAmount)} €`,
                type: 'bold',
            },
        ];
        cusomBoldText(text, 15, yPosition + 6, doc, textOptionParagraph);

        doc.setFont('Helvetica', 'normal');
        doc.text('de la société', 15, yPosition + 3 + 6.5);
        text = [
            {
                content: `NP ${transaction.fondsName}`,
                type: 'bold',
            },
            {
                content:
                    ', SCSp, B251823 RCS LUXEMBOURG, 8 rue Mathias Hardt – L-1717 Luxembourg ville',
                type: 'normal',
            },
        ];
        cusomBoldText(text, 15, yPosition + 3 + 10, doc, textOptionParagraph);
        // doc.text(
        //   `NP ${transaction.fondsName}, SCSp, B251823 RCS LUXEMBOURG, 8 rue Mathias Hardt – L-1717 Luxembourg ville`,
        //   15,
        //   yPosition + 3 + 10
        // );
        doc.text('– Luxembourg', 15, yPosition + 3 + 13.5);

        yPosition += 28 - gap;
        headerComponent('FRAIS', yPosition, 21, doc);

        text = [
            {
                content: `Des frais d'entrée de `,
                type: 'normal',
            },
            {
                content: `${(transaction.entranceFee || 0) * 100} %`,
                type: 'bold',
            },
            {
                content: ` sont appliqués au montant souscrit et à régler par le souscripteur pour valider sa souscription.`,
                type: 'normal',
            },
        ];
        yPosition = yPosition + 6;
        cusomBoldText(text, 15, yPosition, doc, textOptionParagraph);
        text = [
            {
                content: `Frais de gestion annuel: `,
                type: 'normal',
            },
            {
                content: `${(transaction.feesValue || 0) * 100} %`,
                type: 'bold',
            },
        ];
        yPosition += 3;
        cusomBoldText(text, 15, yPosition, doc, textOptionParagraph);

        text = [
            {
                content: `Commission de surperformance: `,
                type: 'normal',
            },
            {
                content: `30 % `,
                type: 'bold',
            },
            {
                content: `de la performance au-delà de l'objectif de performance de `,
                type: 'normal',
            },
            {
                content: ` ${transaction.fondsPerformance}%`,
                type: 'bold',
            },
        ];
        yPosition += 3;

        cusomBoldText(text, 15, yPosition, doc, textOptionParagraph);

        text = [
            {
                content: `Estimation des frais de fonctionnement: `,
                type: 'normal',
            },
            {
                content: `0 % - 0.20 %`,
                type: 'bold',
            },
        ];
        yPosition += 3;

        cusomBoldText(text, 15, yPosition, doc, textOptionParagraph);

        let leaveFee = 4;
        let leaveFeeDuration = 2;

        if (isWiseedTransfer) {
            leaveFee = 2;
            leaveFeeDuration = 1;
        }

        text = [
            {
                content: `Frais de sortie anticipée: `,
                type: 'normal',
            },
            {
                content: `${leaveFee} %`,
                type: 'bold',
            },
            {
                content: ` pour toute demande de sortie dans les ${leaveFeeDuration} ans qui suivent l'émission des titres.`,
                type: 'normal',
            },
        ];
        yPosition += 3;

        cusomBoldText(text, 15, yPosition, doc, textOptionParagraph);
        // doc.text("sa souscription.", 15, yPosition + 3 + 6.5);

        yPosition += 12 - gap;

        // Paiement
        headerComponent('PAIEMENT', yPosition, 30, doc);

        text = [
            {
                content: `Mon paiement est effectué par `,
                type: 'normal',
            },
            {
                content: `virement bancaire, pour un montant total de ${printLargeValue(
                    transaction.amount
                )} €`,
                type: 'bold',
            },
        ];
        cusomBoldText(text, 15, yPosition + 6, doc, textOptionParagraph);
        text = [
            {
                content: `Libellé du virement à indiquer: `,
                type: 'normal',
            },
            {
                content: `${transaction.referenceTransaction}`,
                type: 'bold',
            },
        ];
        cusomBoldText(text, 15, yPosition + 3 + 6.5, doc, textOptionParagraph);

        doc.setFontSize(9);
        text = 'Recommandations:';
        doc.text(text, 15, yPosition + 3 + 10);
        underlineText(text, 15, yPosition + 3 + 10, doc);
        doc.setFontSize(8);
        yPosition += 2;
        doc.text(
            '\u2022 Les virements sont réceptionnés par la banque de la société d’investissement et traités par la société responsable de la gestion des flux, New Paradigms Asset Management S.A.R.L.',
            16,
            yPosition + 15,
            textOptionParagraph
        );

        doc.text(
            '\u2022 Une fois le paiement reçu, vous recevrez par mail un accusé de réception. Si vous ne recevez pas cet email et que le délai vous semble long, contactez-nous sans attendre à antoine.flipo@new-paradigms-am.com ou au 07 86 98 79 26',
            16,
            yPosition + 21,
            textOptionParagraph
        );

        yPosition += 36 - gap;

        // Signature
        headerComponent('SIGNATURE', yPosition, 79, doc);
        doc.setFont('Helvetica', 'bold');

        text = `Je soussigné(e), ${user?.sex} ${userName} né(e) le : ${formatDate(
            user?.userInfo?.birthDay
                ? new Date(user?.userInfo?.birthDay)
                : new Date()
        )} à ${
            user?.userInfo?.birthPlace
        }, après avoir pris connaissance des conditions de souscription et modalités de l'opération proposée, m'engage irrévocablement à souscrire une somme totale de ${printLargeValue(
            transaction.amount
        )} € correspondant à ${nbShare} obligation(s) ${typeShare} de ${printLargeValue(
            transaction.initialValueOfShare || 100
        )} € unitaire et comprenant le paiement des frais de souscription.`;
        doc.text(text, 15, yPosition + 6, textOptionParagraph);

        doc.setFont('Helvetica', 'normal');
        doc.setFontSize(7);

        // Sample text, broken down into lines for clarity
        const lines = [
            "1. J'accepte de recevoir les convocations aux assemblées générales par courrier électronique, plutôt que par envoi postal, conformément à la pratique luxembourgeoise.",
            `2. Je déclare effectuer ma souscription au profit de NP ${transaction.fondsName?.toUpperCase()}, sans avoir été sollicité par un démarchage bancaire ou financier, conformément à la réglementation luxembourgeoise sur le démarchage financier qui vise à protéger les investisseurs contre les sollicitations non sollicitées.`,
            "3. Je certifie que les fonds utilisés pour cette souscription ne proviennent pas d'activités illicites et ne sont pas destinés au blanchiment de capitaux ou au financement du terrorisme, en accord avec les lois luxembourgeoises pertinentes en matière de lutte contre le blanchiment d'argent et le financement du terrorisme.",
            "4. Je suis conscient que l'investissement dans des sociétés non cotées ou cotées comporte des risques de perte en capital et d'illiquidité, reflétant les avertissements généraux sur les risques d'investissement présents dans la législation luxembourgeoise.",
            "5. Je reconnais qu'aucun droit de rétractation n'est applicable à l'acquisition d'instruments financiers, comme le stipulent les dispositions luxembourgeoises.",
            "6. J'ai pris connaissance de la Charte concernant la protection des données, en conformité avec le Règlement Général sur la Protection des Données (RGPD) applicable au Luxembourg depuis le 25 mai 2018, disponible dans mon espace investisseur.",
            "7. J'accepte que NEW PARADIGMS puisse collecter et conserver, pour son usage propre, toutes les informations me concernant. Je reconnais et accepte que ce document :",
            "   a) a été signé sous forme électronique, conformément à la législation luxembourgeoise sur la signature électronique, garantissant la fiabilité de l'identification et le lien avec la signature ;",
            "   b) a la même valeur probante qu'un document écrit sur support papier, en vertu de la loi luxembourgeoise.",
        ];

        // Starting coordinates
        const x = 20;
        let y =
            yPosition +
            (doc.getTextDimensions(text).w * 6) / (pageWidth - 30) +
            8;

        // Add each line of text to the document
        lines.forEach((line, index) => {
            doc.text(line, x, y, textOptionParagraph);
            if (index === 0) y += 1;
            y += (doc.getTextDimensions(line).w * 3) / (pageWidth - 50) + 2; // Increase y-coordinate for the next line
        });

        yPosition += 82 + 8 - gap + 10;

        doc.setFontSize(12);
        if (!preview) {
            doc.text(`Fait à`, 15, yPosition);
            doc.text(
                `{{Fait à;default=${city};height=30 }}`,
                28,
                yPosition - 3
            );
            doc.text(
                `{{type=stamp;readonly=true;width=120;height=60}}`,
                pageWidth / 2,
                yPosition - 17
            );
        } else {
            doc.text(
                `Fait à ${city} , le ${formatDate(new Date())}`,
                15,
                yPosition
            );
        }
        doc.setTextColor(mainColor[0], mainColor[1], mainColor[2]);
        doc.setFontSize(12);
        doc.text('Avertissement:', 15, yPosition + 10);

        doc.setFontSize(9);
        doc.setTextColor(textColor[0], textColor[1], textColor[2]);

        doc.text(
            `Le souscripteur reconnait que sa souscription sera définitive à la bonne réception par NP ${transaction.fondsName}`,
            15,
            yPosition + 15,
            {
                maxWidth: pageWidth / 2 - 30,
                align: 'justify',
            }
        );
        yPosition += 10;
        doc.text(
            '\u2022 du présent bulletin de souscription signé',
            22,
            yPosition + 17
        );
        doc.text(
            `\u2022 du règlement par Virement vers le compte de la société NP ${transaction.fondsName}, et correspondant au  montant total de la souscription comprenant les frais de souscription`,
            22,
            yPosition + 23,
            {
                maxWidth: pageWidth / 2 - 30,
                align: 'justify',
            }
        );

        // signature en fin de contrat
        doc.setDrawColor(textColor[0], textColor[1], textColor[2]);
        doc.setLineWidth(0.2);

        // set border
        doc.rect(pageWidth / 2, pageHeight - 50, pageWidth / 2 - 20 + 10, 35);
        // @ts-ignore
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.text(
            `« Bon pour souscription de ${nbShare} obligation(s) de ${transaction.initialValueOfShare} € »`,
            pageWidth / 2 + 2,
            pageHeight - 45
        );
        if (!preview) {
            doc.text(
                `{{Signature ;type=signature;format=drawn;height=10;width=100}}`,
                pageWidth / 2 + 2,
                pageHeight - 35
            );
        }
    });

    // Saving the PDF
    if (!preview) {
        return doc.output('datauristring');
    } else {
        const blob = doc.output('blob');
        return URL.createObjectURL(blob);
    }
}

function getNumeroBulletinSubscriptions(n: number): string {
    return n.toString().padStart(7, '0');
}

function underlineText(text: string, x: number, y: number, doc: jsPDF) {
    // Calculate the width of the text
    const textWidth = doc.getTextWidth(text);
    doc.text(text, x, y);
    doc.setLineWidth(0.1);
    doc.setDrawColor(0); // Set the line color, black in this case
    doc.line(x, y + 1, x + textWidth, y + 1);
}

function cusomBoldText(
    text: { content: string; type: 'normal' | 'bold' }[],
    x: number,
    y: number,
    doc: jsPDF,
    textOptionParagraph: TextOptionsLight
) {
    let xPosition = x;
    text.forEach((t) => {
        doc.setFont('helvetica', t.type);
        doc.text(t.content, xPosition, y, textOptionParagraph);
        xPosition += doc.getTextWidth(t.content);
    });
    doc.setFont('helvetica', 'normal');
}

function headerComponent(title: string, y: number, height: number, doc: jsPDF) {
    const textColor = [0, 0, 0];

    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(12);
    doc.text(title, 15, y + 2);
    doc.setFillColor(textColor[0], textColor[1], textColor[2]);
    doc.rect(15, y + 2.5, pageWidth - 25, 0.1, 'F');

    doc.rect(13, y - 2, 1, height, 'F');
    doc.setFontSize(9);
    doc.setFont('Helvetica', 'normal');
}
