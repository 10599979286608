import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import antoine from "./../../assets/Calque_antoine.png";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  addArticle,
  editArticle,
  getArticles,
} from "../../redux/features/article";
import { Article } from "../../types/Articles";
import { isEmpty } from "../../function/Utils";

const ArticlesAll = () => {
  const dispatch = useAppDispatch();
  const articlesData = [
    ...useAppSelector((state) => state.articleReducer.articles),
  ];
  const [order, setOrder] = useState(0);
  const userData = useAppSelector((state) => state.userReducer.user);

  useEffect(() => {
    let maxOrder: number = 0;

    articlesData?.map((article) => {
      if (article.order > maxOrder) {
        maxOrder = article.order;
      }
    });
    setOrder(maxOrder + 1);
  }, [articlesData]);

  const handleClick = async () => {
    const data: Article = {
      title: "Un titre",
      text: "Un text de présentation",
      show: false,
      datepublication: new Date(Date.now()),
      auteurname: `${userData?.firstName} ${userData?.lastName}`,
      auteurcover: antoine,
      auteurpost: "Co fondateur de Newparadigms",
      order: order,
    };
    setOrder(order + 1);

    await dispatch(addArticle(data));

    dispatch(getArticles());
  };

  const showArticle = (article: Article) => {
    if (!article.show) {
      return (
        <p className="pointer" style={{ backgroundColor: "red" }}>
          Offline
        </p>
      );
    } else {
      return (
        <p className="pointer" style={{ backgroundColor: "green" }}>
          Online
        </p>
      );
    }
  };

  const affichageArticles = (val: Article, key: number) => {
    if (userData?.admin) {
      return (
        <Link
          to={`/Articles/${val.id}`}
          className="article_container"
          style={{ backgroundImage: `url(${val.cover})` }}
          key={key}
        >
          <div className="show_container">{showArticle(val)}</div>
          <div className="text_container">
            <h3>{val.title}</h3>
            <p>{val.text}</p>
            <p className="italic">
              {val.datepublication instanceof Date
                ? val.datepublication.toLocaleDateString("en-GB")
                : new Date(val.datepublication).toLocaleDateString("en-GB")}
            </p>
          </div>
        </Link>
      );
    } else {
      if (val.show) {
        return (
          <Link
            to={`/Articles/${val.id}`}
            className="article_container"
            style={{ backgroundImage: `url(${val.cover})` }}
            key={key}
          >
            <div className="text_container">
              <h3>{val.title}</h3>
              <p>{val.text}</p>
              <p className="italic">
                {val.datepublication instanceof Date
                  ? val.datepublication.toLocaleDateString("en-GB")
                  : new Date(val.datepublication).toLocaleDateString("en-GB")}
              </p>
            </div>
          </Link>
        );
      } else {
        return null;
      }
    }
  };

  return (
    <React.Fragment>
      <h2 className="titre_Article_page">
        Voici une liste d'articles qui pourraient vous intéresser
      </h2>

      <div className="articles_container">
        {!isEmpty(articlesData) &&
          articlesData
            .sort((a, b) => a.order - b.order)
            .map((val, key) => affichageArticles(val, key))}
        {!!userData?.admin && (
          <div className="article_container" onClick={() => handleClick()}>
            <h2 className="pointer text_center">+ créer un nouvel article</h2>
          </div>
        )}
        {!!(
          (!isEmpty(articlesData) &&
            articlesData.filter((article) => article.show == true).length ==
              0 &&
            isEmpty(articlesData)) ||
          userData?.admin == false
        ) && <p>Aucun article n'est disponible pour le moment</p>}
      </div>
    </React.Fragment>
  );
};

export default ArticlesAll;
