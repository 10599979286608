import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { UserOrderCashOutInterface } from "../../types/user";

interface userOrderCashOutState {
    usersOrderCashOut: UserOrderCashOutInterface[];
    userOrderCashOut: UserOrderCashOutInterface[];
    loading: boolean;
    errors: any;
}

const initialState: userOrderCashOutState = {
    usersOrderCashOut: [],
    userOrderCashOut: [],
    loading: false,
    errors: null,
};

// actions are processes that get data from backend
export const getUsersOrderCashOut = createAsyncThunk<
    UserOrderCashOutInterface[]
>("userOrderCashOut/getUsersOrderCashOut", async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}userOrderCashOut`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getUserOrderCashOut = createAsyncThunk<UserOrderCashOutInterface[], number>("userOrderCashOut/getUserOrderCashOut", async (id, thunkAPI) => {
    try {
        const response: AxiosResponse<any, any> = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}userOrderCashOut/${id}`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const addUserOrderCashOut = createAsyncThunk<
    UserOrderCashOutInterface,
    UserOrderCashOutInterface
>("userOrderCashOut/addUserOrderCashOut", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "post",
            url: `${import.meta.env.VITE_API_URL}userOrderCashOut`,
            data: data,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const addUserOrderCashOutByAdmin = createAsyncThunk<
    UserOrderCashOutInterface,
    UserOrderCashOutInterface
>("userOrderCashOut/addUserOrderCashOutByAdmin", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "post",
            url: `${import.meta.env.VITE_API_URL}userOrderCashOut`,
            data: data,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const editUserOrderCashOut = createAsyncThunk<
    UserOrderCashOutInterface,
    UserOrderCashOutInterface
>("userOrderCashOut/editUserOrderCashOut", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "put",
            url: `${import.meta.env.VITE_API_URL}userOrderCashOut/byUser/${data.id}`,
            data: { ...data },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const editUserOrderCashOutByAdmin = createAsyncThunk<
    UserOrderCashOutInterface,
    UserOrderCashOutInterface
>("userOrderCashOut/editUserOrderCashOutByAdmin", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "put",
            url: `${import.meta.env.VITE_API_URL}userOrderCashOut/byAdmin${data.id}`,
            data: { ...data },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const deleteUserOrderCashOut = createAsyncThunk<number, number>(
    "userOrderCashOut/deleteUserOrderCashOut",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}userOrderCashOut/${id}`,
                withCredentials: true,
            });
            return id;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteUserOrderCashOutByAdmin = createAsyncThunk<number, number>(
    "userOrderCashOut/deleteUserOrderCashOutByAdmin",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}userOrderCashOut/${id}`,
                withCredentials: true,
            });
            return id;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);


// reducers -> reduce to a specific state -> changes state

export const userOrderCashOutSlice = createSlice({
    name: "userOrderCashOut",
    initialState,
    reducers: {
        setusersOrderCashOut: (
            state,
            action: PayloadAction<UserOrderCashOutInterface[]>
        ) => {
            state.usersOrderCashOut = action.payload;
        },
        setuserOrderCashOut: (
            state,
            action: PayloadAction<UserOrderCashOutInterface[]>
        ) => {
            state.userOrderCashOut = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUsersOrderCashOut.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUsersOrderCashOut.fulfilled, (state, action) => {
            state.usersOrderCashOut = action.payload;
            state.loading = false;
        });
        builder.addCase(getUsersOrderCashOut.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(getUserOrderCashOut.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUserOrderCashOut.fulfilled, (state, action) => {
            state.userOrderCashOut = action.payload;
            state.loading = false;
        });
        builder.addCase(getUserOrderCashOut.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addUserOrderCashOut.fulfilled, (state, action) => {
            state.userOrderCashOut = [...state.userOrderCashOut, action.payload];
            state.loading = false;
        });
        builder.addCase(addUserOrderCashOut.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addUserOrderCashOut.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addUserOrderCashOutByAdmin.fulfilled, (state, action) => {
            state.usersOrderCashOut = [...state.usersOrderCashOut, action.payload];
            state.loading = false;
        });
        builder.addCase(addUserOrderCashOutByAdmin.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addUserOrderCashOutByAdmin.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editUserOrderCashOut.fulfilled, (state, action) => {
            state.loading = false;
            state.userOrderCashOut = state.userOrderCashOut.map((order) => {
                if (order.id === action.payload.id) {
                    return {
                        ...order,
                        comment: action.payload?.comment,
                        date: action.payload?.date,
                        amount: action.payload?.amount,
                    };
                } else return order;
            });
        });
        builder.addCase(editUserOrderCashOut.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editUserOrderCashOut.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editUserOrderCashOutByAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.usersOrderCashOut = state.usersOrderCashOut.map((userOrderCashOut) => {
                if (userOrderCashOut.id === action.payload.id) {
                    return {
                        ...userOrderCashOut,
                        comment: action.payload?.comment,
                        date: action.payload?.date,
                        amount: action.payload?.amount,
                    };
                } else return userOrderCashOut;
            });
        });
        builder.addCase(editUserOrderCashOutByAdmin.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editUserOrderCashOutByAdmin.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteUserOrderCashOut.fulfilled, (state, action) => {
            state.loading = false;
            state.userOrderCashOut = state.userOrderCashOut.filter(
                (userOrderCashOut) => userOrderCashOut.id !== action.payload
            );
        });
        builder.addCase(deleteUserOrderCashOut.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteUserOrderCashOut.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteUserOrderCashOutByAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.usersOrderCashOut = state.usersOrderCashOut.filter(
                (userOrderCashOut) => userOrderCashOut.id !== action.payload
            );
        });
        builder.addCase(deleteUserOrderCashOutByAdmin.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteUserOrderCashOutByAdmin.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
    },
});

export default userOrderCashOutSlice.reducer;
export const { setuserOrderCashOut, setusersOrderCashOut } = userOrderCashOutSlice.actions;
