import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    useEditProjectStatusMutation,
    useGetProjectByIdQuery,
} from '../../../../redux/features/projectSlice';
import {
    ProjectStatus,
    UpdateProjectStatusDto,
} from '../../../../types/project';
import { useNotificationContext } from '../../../Context/notification-context';
import PopUp from '../../../commun/PopUp';
import SelectComponent from '../../../commun/formComponent/SelectComponent';
import SubmitComponent from '../../../commun/formComponent/SubmitComponent';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    projectId: number;
}

const EditProjectStatus: React.FC<Props> = ({ open, setOpen, projectId }) => {
    const { register, handleSubmit, reset, getValues, watch, control } =
        useForm<UpdateProjectStatusDto>();

    const { data: project, isLoading } = useGetProjectByIdQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );

    useEffect(() => {
        if (project) {
            reset({
                status: project.status,
                id: project.id,
            });
        }
    }, [project]);

    const [editProjectStatus, { isLoading: isLoadingEdit }] =
        useEditProjectStatusMutation();

    const { showSuccess, showError } = useNotificationContext();

    const onSubmit = async (data: UpdateProjectStatusDto) => {
        await editProjectStatus(data)
            .unwrap()
            .then(() => {
                showSuccess('Success', 'Project status updated successfully');
                setOpen(false);
            })
            .catch((err) => {
                showError('Error', 'An error occurred');
            });
    };

    return (
        <>
            <PopUp
                open={open}
                setOpen={setOpen}
                title={() => {
                    return (
                        <h3 className="text-2xl font-medium leading-6 text-gray-900">
                            Edit Project Status
                        </h3>
                    );
                }}
                buttonBoolean={false}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formTemplateSingUp"
                >
                    <SelectComponent
                        register={register}
                        value={'status'}
                        container={true}
                        optionValues={Object.values(ProjectStatus)?.map(
                            (val) => {
                                return {
                                    value: val,
                                    label: val,
                                };
                            }
                        )}
                        control={control}
                    >
                        <LabelComponentForm>
                            Situation du projet{' '}
                            <span className="required">*</span>
                        </LabelComponentForm>
                    </SelectComponent>
                    <div className=" h-64 w-full"></div>

                    <SubmitComponent
                        addItemName="Update"
                        onClickCancel={() => setOpen(false)}
                        isLoading={isLoadingEdit}
                    />
                </form>
            </PopUp>
        </>
    );
};

export default EditProjectStatus;
