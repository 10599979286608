import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import Menu from "../components/DashboardUser/Menu";
import Overview from "../components/DashboardUser/Overview/Overview";
import DonneesPerso from "../components/DashboardUser/Profile/Profil";
import Wallet from "../components/DashboardUser/Wallet/Wallet";
import Investir from "../components/DashboardUser/Investir/Investir";
import NotFound from "./NotFound";
import {
  createDateArray,
  lastDay,
  nextDay,
  startMonth,
  toInt,
} from "../function/Utils";
import Parameter from "../components/DashboardUser/Profile/Parameter";
import Documents from "../components/DashboardUser/Profile/Documents";
import {
  MenuDashBoardUserBool,
  MenuDashBoardUserBoolState,
} from "../components/Context/AppContext";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { getSharesUser } from "../redux/features/share";
import Sponsor from "../components/DashboardUser/Profile/Sponsor/Sponsor";
import { getTransactionsUser } from "../redux/features/transactions";
import IndexTransactionUser from "../components/DashboardUser/Transactions/IndexTransactionUser";
import { useFetchTokenQuery } from "../redux/features/tokenSlice";
import Seconnecter from "./Seconnecter";
import Activities from "../components/DashboardUser/Activities";
import ContactPopUp from "../components/DashboardUser/commun/ContactPopUp";
import IndexFondsUser from "../components/DashboardUser/Fonds/IndexFondsUser";
import InvestAccount from "../components/DashboardUser/Profile/InvestAccount/InvestAccount";
import IndexSimulation from "../components/DashboardUser/Simulation/IndexSimulation";

type PathParams = {
  path: string;
};

type FondsParams = {
  idFonds: string;
};

type ProjetParams = {
  idProjet: string;
};

const DashboardUser = () => {
  let { path } = useParams<PathParams>();

  const [toggleMenu, setToggleMenu] = useState(true);

  const location = useLocation();

  const currentLocation = location.pathname + location.search;

  const menuToggleObject: MenuDashBoardUserBool = {
    toggleMenu: toggleMenu,
    setToggleMenu: setToggleMenu,
  };

  const routeFunction = () => {
    switch (path) {
      case "tableau-de-bord":
        return <Activities />;
      case "nos-fonds":
        return <IndexFondsUser />;
      case "donnees-personnelles":
        return <DonneesPerso />;
      case "portefeuille":
        return <Wallet />;
      case "Paramètres":
        return <Parameter />;
      case "Parrainage":
        return <Sponsor />;
      case "Documents":
        return <Documents />;
      case "investir":
        return <Investir />;
      case "transactions":
        return <IndexTransactionUser />;
      case "overview":
        return <Overview />;
      case "invest-account":
        return <InvestAccount />;
      case "simulations":
        return <IndexSimulation />;
      default:
    }
  };
  const { data: user, isLoading } = useFetchTokenQuery();

  return (
    <>
      {user ? (
        <MenuDashBoardUserBoolState.Provider value={menuToggleObject}>
          <div
            className={`dashboard_container_page dashboard_client ${
              toggleMenu === true ? "!w-[99%]" : "!w-[100%]"
            }`}
          >
            <Menu />
            {routeFunction()}
            <ContactPopUp />
          </div>
        </MenuDashBoardUserBoolState.Provider>
      ) : (
        <Seconnecter path={`${currentLocation}`} />
      )}
    </>
  );
};

export default DashboardUser;
