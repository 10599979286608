import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useContext } from 'react';
import { EtapeForm } from '../../../../Context/AppContext';
import FormTemplate, {
    IFormTemplate,
} from '../../../../commun/formComponent/FormTemplate';

const FormInvestTemplate = <T extends object>(
    props: IFormTemplate<T> & { children?: ReactNode }
) => {
    const etapeObject = useContext(EtapeForm);

    return (
        <FormTemplate
            className="w-full md:w-11/12 md:mx-auto lg:w-9/12"
            renderEndComponent={() => (
                <>
                    {etapeObject && etapeObject.step > 0 ? (
                        <div className="transitionBasic_hover revenir_etape_precedent mx-auto mb-10">
                            <p
                                className="pointer"
                                onClick={() => {
                                    etapeObject?.setStep(etapeObject?.step - 1);
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} /> Revenir à
                                l'étape précendente
                            </p>
                        </div>
                    ) : null}
                </>
            )}
            {...props}
        ></FormTemplate>
    );
};

export default FormInvestTemplate;
