import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ShareDto } from '../../types/Share';
import { baseQueryHandleError } from '../index.rtk';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}shares`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

interface GetShareUserArg {
    dates: Date[];
    userId: number;
}

interface GetShareUserFonds {
    userId: number;
    date: Date;
}

export interface ValoByUser {
    fondsName: string;
    fondsId: number;
    values: {
        valo: number;
        valoNotBlackList: number;
        valoBlackList: number;
        date: Date;
    }[];
}

export interface IPerformanceFondsPerMonth {
    date: Date;
    fondsId: number;
    monthlyTRI: number;
    monthlyPerf: number;
    valorisation: number;
    share: number;
}

export interface IPerformanceFondsForUser {
    monthlyValues: {
        date: Date;
        fondsId: number;
        share: number;
    }[];
    yearlyValues: {
        year: number;
        perf: number;
        fondsId: number;
        startDate: Date;
        endDate: Date;
    }[];
}

export interface FirstDateInFundsInterface {
    date: Date | undefined;
    fondsId: number;
}

export interface UserSharesForWallet {
    shares: ShareDto[];
    firstDates: FirstDateInFundsInterface[];
}

export interface GetValoUserBetweenDates {
    dateStart: Date;
    dateEnd: Date;
}

export interface GetPerfIndexForUserRequest {
    userId: number;
}

export interface IUserPerfIndexFonds {
    fondsId: number;
    data: IUserPerfIndex[];
}

export interface IUserPerfIndex {
    share: number;
    irr: number;
    date: Date;
    perf: number;
}

export interface GetPerfIndexForUserResponse {
    sharesD: IUserPerfIndexFonds[];
    sharesC: IUserPerfIndexFonds[];
    lastDate: Date;
    firstDate: Date;
}

export const sharesApi = createApi({
    reducerPath: 'sharesApi',
    baseQuery: baseQueryHandleError(baseQuery),
    endpoints: (builder) => ({
        getShares: builder.query<ShareDto[], void>({
            query: () => `/`,
        }),
        getSharesUser: builder.query<UserSharesForWallet, number>({
            query: (userId) => ({
                url: `/user/${userId}`,
                method: 'GET',
            }),
        }),
        getHistoricalSharesUser: builder.query<
            GetPerfIndexForUserResponse,
            GetPerfIndexForUserRequest
        >({
            query: (request) => ({
                url: `/historyuser/${request.userId}`,
                method: 'GET',
            }),
        }),
        getSharesUserFonds: builder.query<
            UserSharesForWallet,
            GetShareUserFonds
        >({
            query: (request) => ({
                url: `/fondsUser/${request.userId}`,
                method: 'GET',
                params: request,
            }),
        }),
        getValoUser: builder.query<ValoByUser[], Date>({
            query: (date) => ({
                url: `/valoByUser/`,
                method: 'GET',
                params: {
                    date: date,
                },
            }),
        }),

        getValoByUserBetween2Dates: builder.query<
            ValoByUser[],
            GetValoUserBetweenDates
        >({
            query: (request) => ({
                url: `/valoByUser/dates`,
                method: 'GET',
                params: request,
            }),
        }),
        deleteShare: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}`,
                method: 'DELETE',
            }),
        }),
        deleteShareByDate: builder.mutation<void, string>({
            query: (date) => ({
                url: `/date/${date}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetSharesQuery,
    useGetSharesUserFondsQuery,
    useGetSharesUserQuery,
    useGetHistoricalSharesUserQuery,
    useDeleteShareMutation,
    useDeleteShareByDateMutation,
    useGetValoUserQuery,
    useGetValoByUserBetween2DatesQuery,
} = sharesApi;
