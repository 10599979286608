import React, { useContext, useEffect, useState } from "react";
import { MenuDashBoardAdminBoolState } from "../../Context/AppContext";
import WrapComponentDashboardUser from "../../DashboardUser/commun/WrapComponentDashboardUser";
import { PrimaryButton, WhiteButton } from "../../commun/Buttons";
import AddTypeInvestisseur from "./AddTypeInvestisseur";
import {
  useDeleteTypeInvestisseurMutation,
  useGetTypeInvestisseurByIdQuery,
  useGetTypeInvestisseursQuery,
  useUpdateTypeInvestisseurMutation,
} from "../../../redux/features/typeInvestisseurSlice";
import { useGetFondsQuery } from "../../../redux/features/fondsSlice";
import {
  TypeInvestisseurDto,
  UpdateTypeInvestisseurDto,
} from "../../../types/typeInvestisseurTypes";
import { useQueryParam, NumberParam } from "use-query-params";
import {
  getDateOperationFee,
  printLargeValue,
  transformDate,
} from "../../../function/Utils";
import Loading from "../../commun/Loading";
import TableComponent from "../BackOffice/Components/TableComponent";
import { useForm } from "react-hook-form";
import PopUp from "../../commun/PopUp";
import { useNotificationContext } from "../../Context/notification-context";
import FieldArray from "../../commun/formComponent/FieldArray";
import InputComponent from "../../commun/formComponent/InputComponent";
import RemoveFieldArrayComponent from "../../commun/formComponent/RemoveFieldArrayComponent";
import SelectComponent from "../../commun/formComponent/SelectComponent";
import SelectMultiComponent from "../../commun/formComponent/SelectMultiComponent";
import useDeleteItem from "../../commun/CustomHook/useDeleteItem";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FeesIndex = () => {
  const menuBoolState = useContext(MenuDashBoardAdminBoolState);

  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto">
      <WrapComponentDashboardUser
        title="Gestion des frais"
        description=""
        classNameHeader={`${!menuBoolState?.toggleMenu ? "!pl-[40px]" : ""}`}
        classNameContainer={`${!menuBoolState?.toggleMenu ? "" : ""}`}
      >
        {open ? <AddTypeInvestisseur open={open} setOpen={setOpen} /> : null}
        <div className="flex justify-center items-center mt-4 mb-3">
          <PrimaryButton onClick={() => setOpen(true)}>
            Add Type Investisseur
          </PrimaryButton>
        </div>
        <div className="overflow-auto h-[83vh] md:h-[88vh] relative px-2">
          <FeeTable />
        </div>
      </WrapComponentDashboardUser>
    </div>
  );
};

export default FeesIndex;

function FeeTable() {
  const { data: typeInvestisseur, isLoading: isLoadingTypeInvestisseur } =
    useGetTypeInvestisseursQuery();

  const { data: fonds } = useGetFondsQuery();

  const [open, setOpen] = useState<boolean>(false);

  const classNameObejct = {
    head: "text-sm text-gray-900 px-6 py-4 text-left font-semibold text-left",
    item: "text-sm font-light px-6 py-4 whitespace-nowrap  text-right",
  };
  return (
    <div className="">
      {open ? <EditTypeInvestisseur setOpen={setOpen} /> : null}
      <TableComponent
        head={["Type investisseur", ...(fonds?.map((f) => f.name) || [])]}
      >
        <tbody>
          {isLoadingTypeInvestisseur ? (
            <tr className="">
              <td
                colSpan={(fonds?.length || 0) + 1}
                className="pt-10 text-center mx-auto"
              >
                <Loading />
              </td>
            </tr>
          ) : (
            <>
              {typeInvestisseur?.map((val, key) => (
                <RowElement t={val} number={key} setOpen={setOpen} />
              ))}
            </>
          )}
        </tbody>
      </TableComponent>
    </div>
  );
}

function RowElement({
  t,
  number,
  setOpen,
}: {
  t: TypeInvestisseurDto;
  number: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const classNameObejct = {
    item: "text-sm  font-light px-6 py-4 whitespace-nowrap",
  };

  const { data: fonds } = useGetFondsQuery();

  const [typeInvestisseurId, setTypeInvestisseurId] = useQueryParam(
    "typeInvestisseurId",
    NumberParam
  );

  return (
    <tr
      onClick={() => {
        setTypeInvestisseurId(t.id);
        setOpen(true);
      }}
      className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
        number % 2 === 0 ? "bg-secondBackgroundColor" : "bg-white "
      } `}
    >
      <td className={`${classNameObejct.item}`}>{t.name}</td>
      {fonds?.map((f) => (
        <td className={`${classNameObejct.item}`}>
          {
            getDateOperationFee(
              t.operationFees?.filter((o) => o.fondsId === f.id),
              new Date()
            )?.operationFee
          }
        </td>
      ))}
    </tr>
  );
}

function EditTypeInvestisseur({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [typeInvestisseurId, setTypeInvestisseurId] = useQueryParam(
    "typeInvestisseurId",
    NumberParam
  );

  const { data: fonds } = useGetFondsQuery();

  const { data: typeInvestisseur, isLoading: isLoadingTypeInvestisseur } =
    useGetTypeInvestisseurByIdQuery(typeInvestisseurId || 0, {
      skip: !typeInvestisseurId,
    });

  const [editTypeInvestisseur, { isLoading: isLoadingEdit }] =
    useUpdateTypeInvestisseurMutation();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isValid },
  } = useForm<UpdateTypeInvestisseurDto>();

  const { showError, showSuccess } = useNotificationContext();

  const onSubmit = async (data: UpdateTypeInvestisseurDto) => {
    data = {
      ...data,
      operationFees: data?.operationFees?.map((o) => {
        return {
          ...o,
          date: transformDate(new Date(o.date)),
        };
      }),
    };
    await editTypeInvestisseur(data)
      .unwrap()
      .then((e) => {
        showSuccess("Edited", "Type investisseur edited");
        setOpen(false);
      })
      .catch((e) => {
        showError("Error", e.data?.message);
      });
  };

  const updateData = () => {
    let copy = [...(typeInvestisseur?.operationFees || [])];
    reset({
      ...typeInvestisseur,
      operationFees: copy
        ?.sort((a, b) => a.fondsId - b.fondsId)
        ?.map((o) => {
          return {
            ...o,
            date: new Date(o.date).toJSON()?.split("T")[0] as any,
          };
        }),
    });
  };

  useEffect(() => {
    updateData();
  }, [typeInvestisseur]);

  const formSelectFonds = useForm<{
    fondsIds: number[];
  }>({
    defaultValues: {
      fondsIds: undefined,
    },
  });

  const [
    deleteTypeInvestisseur,
    { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
  ] = useDeleteTypeInvestisseurMutation();

  const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
    typeTransactionId: number;
  }>({
    deleteItem: () => deleteTypeInvestisseur(typeInvestisseurId || 0),
    toDoIfSuccess: () => setOpen(false),
    message: "type investisseur",
    isLoading: isLoadingDelete,
  });

  return (
    <PopUp
      title={() => {
        return (
          <>
            <h3 className="text-2xl font-semibold leading-6 text-gray-900">
              Edit type investisseur
            </h3>
            {typeInvestisseurId && ![1, 2, 3].includes(typeInvestisseurId) ? (
              <FontAwesomeIcon
                onClick={() => setOpenDelete(true)}
                icon={faTrash}
                className="absolute top-4 md:top-0 cursor-pointer left-4 transition-all hover:scale-105"
              />
            ) : null}
          </>
        );
      }}
      open={true}
      setOpen={setOpen}
      buttonBoolean={false}
      width="md:w-2/3"
    >
      <DeleteComponent title="ce type investisseur" />
      <form onSubmit={handleSubmit(onSubmit)} className="formTemplateSingUp">
        <div className="w-11/12 md:w-[400px] mx-auto mb-3">
          <InputComponent value={"name"} register={register}>
            <p className="font-semibold text-md">Name</p>
          </InputComponent>
          <SelectMultiComponent
            register={formSelectFonds.register}
            setValue={formSelectFonds.setValue}
            control={formSelectFonds.control}
            watch={formSelectFonds.watch}
            value="fondsIds"
            optionsRender={(fond) => fond?.label}
            valueRender={(fond) => fond?.value}
            optionValues={
              fonds?.map((fond) => ({
                value: fond.id,
                label: fond.name,
              })) || []
            }
          >
            <label className=" font-semibold !text-lg">Filtrer par fonds</label>
          </SelectMultiComponent>
        </div>
        <div className="grid font-semibold text-md grid-cols-[1fr,1fr,1fr,auto] w-full">
          <p>Date</p>
          <p>Operation fee</p>
          <p>Fonds</p>
          <p></p>
        </div>
        <FieldArray control={control} className="w-full" name="operationFees">
          {({ fields, append, remove }) => (
            <>
              <div className="flex flex-col w-full mb-2">
                {fields.map((field, index) => (
                  <>
                    {field?.fondsId === undefined ||
                    formSelectFonds.watch("fondsIds") === undefined ||
                    formSelectFonds
                      .watch("fondsIds")
                      ?.includes(field.fondsId) ? (
                      <div
                        key={field.id}
                        className={`grid grid-cols-[1fr,1fr,1fr,auto] w-full gap-x-2 relative`}
                      >
                        <InputComponent
                          register={register}
                          type="date"
                          value={`operationFees.${index}.date`}

                          // className={{ input: "row-span-1 col-span-1" }}
                        ></InputComponent>

                        <InputComponent
                          register={register}
                          type="number"
                          required={false}
                          value={`operationFees.${index}.operationFee`}
                          // className={{ input: "row-span-1 col-span-1" }}
                        ></InputComponent>

                        <SelectComponent
                          register={register}
                          value={`operationFees.${index}.fondsId`}
                          container={true}
                          optionValues={
                            fonds?.map((val) => {
                              return {
                                value: val.id,
                                label: val.name,
                              };
                            }) ?? []
                          }
                          control={control}
                        ></SelectComponent>

                        <RemoveFieldArrayComponent
                          remove={remove}
                          index={index}
                        />
                      </div>
                    ) : null}
                  </>
                ))}
              </div>
              <PrimaryButton
                className=" mx-auto mb-2"
                onClick={() =>
                  append({
                    date: new Date().toJSON()?.split("T")[0],
                    operationFee: 0.002,
                    fondsId:
                      formSelectFonds.watch("fondsIds")?.length === 1
                        ? formSelectFonds.watch("fondsIds")[0]
                        : undefined,
                    // projectId: project.current?.id!,
                  } as any)
                }
              >
                Ajouter
              </PrimaryButton>
            </>
          )}
        </FieldArray>

        <div className="mt-5 w-full flex justify-center items-center gap-3 text-center">
          <WhiteButton
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </WhiteButton>
          <PrimaryButton type="submit">
            {isLoadingEdit ? <Loading size={4} /> : "Edit"}
          </PrimaryButton>
        </div>
      </form>
    </PopUp>
  );
}
