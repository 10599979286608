import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { CreateDocumentUserDto, DocumentUserDto, GetUserWithSharesByDateBetweenLimitDto, UpdateUserByAdminDto, UpdateUserByUserDto, UpdateUserKnowlegdeDto, UpdateUserWithAllInfoDto, UploadDocument, UserDto, UserWithAllInfoDto, UserWithSharesDto } from '../../types/user';
import { SponsorCodeDto } from '../../types/Sponsor';


interface userState {
    users: UserDto[];
    user: UserDto | null;
    userWithAllInfo: UserWithAllInfoDto | null;
    loading: boolean;
    userWithShares: UserWithSharesDto[];
    documentsUser: DocumentUserDto[];
    errors: any;
}

const initialState: userState = {
    users: [],
    userWithShares: [],
    documentsUser: [],
    userWithAllInfo: null,
    user: null,
    loading: false,
    errors: null,
}

// actions are processes that get data from backend
export const getUsers = createAsyncThunk<UserDto[]>(
    "users/getUsers",
    async (_, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}users`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getUser = createAsyncThunk<UserDto, number>(
    "users/getUser",
    async (id, thunkAPI) => {
        try {
            const response: AxiosResponse<any, any> = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}users/${id}`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getUserWithAllInfo = createAsyncThunk<UserWithAllInfoDto, number>(
    "users/getUserWithAllInfo",
    async (id, thunkAPI) => {
        try {
            const response: AxiosResponse<any, any> = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}users/allInfo/${id}`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)


export const getUsersWithSharesByDateBetweenLimit = createAsyncThunk<UserWithSharesDto[], GetUserWithSharesByDateBetweenLimitDto>(
    "users/getUsersWithSharesByDateBetweenLimit", async (payload, thunkAPI) => {

        const response = await axios({
            method: 'get',
            url: `${import.meta.env.VITE_API_URL}users/dateBetweenLimit`,
            withCredentials: true,
            params: {
                dateStart: payload.dateStart.toISOString(),
                dateEnd: payload.dateEnd.toISOString(),
                limit: payload.limit,
                offset: payload.offset,
                fondsId: payload.fondsId,
                noFeesShare: payload.noFeesShare
            },
        });
        return response.data;
    });

// export const addUser = createAsyncThunk<UserDto, UserDto>(
//     "users/addUser",
//     async (data, thunkApi) => {
//         try {
//             const response = await axios({
//                 method: "post",
//                 url: `${import.meta.env.VITE_API_URL}users`,
//                 data: data,
//                 withCredentials: true,
//             })
//             return response.data
//         } catch (error) {
//             return thunkApi.rejectWithValue(error);
//         }
//     }
// );


export const getUserDocuments = createAsyncThunk<DocumentUserDto[], number>(
    "users/getUserDocuments",
    async (id, thunkAPI) => {
        try {
            const response: AxiosResponse<any, any> = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}documentUser/get/${id}`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)
export interface FormCreateDocumentUserRequest extends CreateDocumentUserDto {
    file: File;
}

export const uploadDocumentUser = createAsyncThunk<DocumentUserDto, FormCreateDocumentUserRequest>(
    "users/uploadDocumentUser",
    async (data, thunkAPI) => {
        try {
            const urlSigned = await axios({
                method: "GET",
                url: `${import.meta.env.VITE_API_URL}documentUser/url/put`,
                params: {
                    fileType: encodeURIComponent(data.file.type),
                    userId: data.userId,
                    name: data.name,
                    type: data.type,
                    side: data.side,
                    fileName: data.fileName,
                },
                withCredentials: true,
            });

            const { uploadUrl, key } = urlSigned.data as unknown as {
                uploadUrl: string;
                key: string;
            };
            const res = await axios.put(uploadUrl, data.file);
            const response = await axios({
                method: "post",
                url: `${import.meta.env.VITE_API_URL}documentUser`,
                data: {
                    userId: data.userId,
                    name: data.name,
                    type: data.type,
                    side: data.side,
                    fileName: data.fileName,
                    key: key,
                },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    })


export const editUser = createAsyncThunk<UserDto, UserDto>(
    "users/editUser",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "put",
                url: `${import.meta.env.VITE_API_URL}users/${data.id}`,
                data: { ...data },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const editUserWithAllInfo = createAsyncThunk<UserWithAllInfoDto, Partial<UpdateUserWithAllInfoDto>>(
    "users/editUserWithAllInfo",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "put",
                url: `${import.meta.env.VITE_API_URL}users/allInfo/${data.id}`,
                data: { ...data },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const editUserKnowledge = createAsyncThunk<UserWithAllInfoDto, UpdateUserKnowlegdeDto>(
    "users/editUserKnowledge",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "put",
                url: `${import.meta.env.VITE_API_URL}users/knowledge/${data.userId}`,
                data: { ...data },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);




export const editUserAdmin = createAsyncThunk<UserDto, UpdateUserByAdminDto>(
    "users/editUserAdmin",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "put",
                url: `${import.meta.env.VITE_API_URL}users/admin/${data.id}`,
                data: { ...data },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteUser = createAsyncThunk<number, number>(
    "users/deleteArticle",
    async (id, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}users/${id}`,
                withCredentials: true,
            })
            return id
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const uploadCoverUser = createAsyncThunk<FormData, FormData>(
    "users/uploadCoverUser",
    async (data, thunkApi) => {
        try {
            const response: AxiosResponse<any, any> = await axios({
                method: "post",
                url: `${import.meta.env.VITE_API_URL}users/upload`,
                data: data,
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const uploadEditUser = createAsyncThunk<FormData, FormData>(
    "users/uploadEditUser",
    async (data, thunkApi) => {
        try {
            const response: AxiosResponse<any, any> = await axios({
                method: "post",
                url: `${import.meta.env.VITE_API_URL}users/upload/img`,
                data: data,
                headers: { "Content-Type": 'multipart/form-data' },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const createSponsorCode = createAsyncThunk<UserDto, number>(
    "users/createSponsorCode",
    async (userId, thunkApi) => {
        try {
            const response: AxiosResponse<any, any> = await axios({
                method: "post",
                url: `${import.meta.env.VITE_API_URL}users/sponsorCode/${userId}`,
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

// reducers -> reduce to a specific state -> changes state 

export const userSlice = createSlice({
    name: "articles",
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<UserDto[]>) => {
            state.users = action.payload
        },
        setUser: (state, action: PayloadAction<UserDto>) => {
            state.user = action.payload
        },
        setUserWithShares: (state, action: PayloadAction<UserWithSharesDto[]>) => {
            state.userWithShares = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserDocuments.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUserDocuments.fulfilled, (state, action) => {
            state.documentsUser = action.payload;
            state.loading = false;
        });
        builder.addCase(getUserDocuments.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(uploadDocumentUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(uploadDocumentUser.fulfilled, (state, action) => {
            state.documentsUser = [...state.documentsUser, action.payload];
            state.loading = false;
        });
        builder.addCase(uploadDocumentUser.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(getUsers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.users = action.payload;
            state.loading = false;
        });
        builder.addCase(getUsers.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(getUserWithAllInfo.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUserWithAllInfo.fulfilled, (state, action) => {
            state.userWithAllInfo = action.payload;
            state.loading = false;
        });
        builder.addCase(getUserWithAllInfo.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(getUsersWithSharesByDateBetweenLimit.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUsersWithSharesByDateBetweenLimit.fulfilled, (state, action) => {
            // if user not already there, add it, if shares with different date for a user not already there, add it
            action.payload.forEach((userWithShares) => {
                const user = state.userWithShares.find((user) => user.id === userWithShares.id);
                if (user) {
                    userWithShares.shares.forEach((share) => {
                        const shareAlreadyThere = user.shares.find((userShare) => userShare.id === share.id);
                        if (!shareAlreadyThere) {
                            user.shares.push(share);
                        }
                    });
                } else {
                    state.userWithShares.push(userWithShares);
                }
            });
            state.loading = false;
        });
        builder.addCase(getUsersWithSharesByDateBetweenLimit.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(getUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.user = action.payload;
            state.loading = false;
        });
        builder.addCase(getUser.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });

        builder.addCase(editUserAdmin.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editUserAdmin.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editUserAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.users = state.users.map((user) => {
                if (user.id === action.payload.id) {
                    return {
                        ...action.payload,
                    };
                } else return user;

            });
        });
        builder.addCase(editUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.users = state.users.map((user) => {
                if (user.id === action.payload.id) {
                    return {
                        ...action.payload,
                    };
                } else return user;

            });
        });
        builder.addCase(editUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editUser.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editUserWithAllInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.userWithAllInfo = action.payload;

        });
        builder.addCase(editUserWithAllInfo.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editUserWithAllInfo.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editUserKnowledge.fulfilled, (state, action) => {
            state.loading = false;
            state.userWithAllInfo = action.payload;

        });
        builder.addCase(editUserKnowledge.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editUserKnowledge.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.loading = false;
            state.users = state.users.filter((user) => user.id !== action.payload);
        });
        builder.addCase(deleteUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(uploadEditUser.fulfilled, (state, action) => {
            state.loading = false;

        });
        builder.addCase(uploadEditUser.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;

        });
        builder.addCase(createSponsorCode.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
        });
        builder.addCase(createSponsorCode.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createSponsorCode.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
    }

});

export default userSlice.reducer;
export const { setUsers, setUser, setUserWithShares } = userSlice.actions;


