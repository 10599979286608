import React from 'react';
import Avertissementcomponent from '../components/commun/Avertissementcomponent';
import ContactSection from '../components/commun/ContactSection';
import FirstFondPage from '../components/commun/FirstFondPage';
import Footer from '../components/commun/Footer';
import Navigation from '../components/commun/Navigation';
import ChartRendement from '../components/ImmoDyn/ChartRendement';
import ConnectezVous from '../components/ImmoDyn/ConnectezVous';
import ImmoDynPresentation from '../components/ImmoDyn/ImmoDynPresentation';
import { useFetchTokenQuery } from '../redux/features/tokenSlice';
import { pageHeader } from '../types/commun';
import coverBackground from './../assets/immo_home_recadre.jpg';

const FondImmobilier = () => {
    const fondImmobilierList: pageHeader = {
        coverBackground: coverBackground,
        coverImg: './img/immo_home_recadre.jpg',
        classN: 'FondImmobilier_Page',
        h1_: 'Immobilier',
    };

    const { data: user } = useFetchTokenQuery();

    return (
        <React.Fragment>
            <Navigation
                bol_nosfonds={true}
                bol_transparent={true}
                id_color="p0ID"
            />
            <FirstFondPage pageHeader={fondImmobilierList} />
            <ImmoDynPresentation />
            {user ? (
                <React.Fragment>
                    <ChartRendement />
                    {/* <ChartProjets /> */}
                </React.Fragment>
            ) : (
                <ConnectezVous path={'/Immobilier'} />
            )}

            <Avertissementcomponent />
            <ContactSection />
            <Footer />
        </React.Fragment>
    );
};

export default FondImmobilier;
