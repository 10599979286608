
import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryHandleError } from '../index.rtk';




export interface IContactMessage {
    firstName: string
    lastName: string
    email: string
    message: string
    subject: string
    userId?: number

}

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}contact`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export const contactApi = createApi({
    reducerPath: 'contactApi',
    baseQuery: baseQueryHandleError(baseQuery),
    endpoints: (builder) => ({
        sendMessage: builder.mutation<void, IContactMessage>({
            query: (body) => ({
                url: '/message',
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const { useSendMessageMutation, } = contactApi;