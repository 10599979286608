import { PlatformDto } from "./platform";
import { TransactionStatus } from "./transactions";



export interface FondsDto {
    id: number;
    name: string;
    order: null | number;
    platforms: null | PlatformDto[];
    cover?: null | string;
    description: null | string;
    performance: number;
    performances: FondsObjectivePerformanceDto[]
    dateCreation: null | Date;
    show: boolean;
    overviewInfo?: FondsForUserOverviewAttributeDto;
    userId?: number
}

export interface FondsForUserOverviewAttributeDto {
    text?: string;
    subTitle?: string;
    monthProjectId?: number;

}


export interface FondsUserDto {
    id: number;
    name: string;
    userId: number;
}


export enum TypeDocumentFonds {
    cover = 'Cover',
    rib = 'RIB',
    other = 'autre',
    DICI = 'DICI',
    SumUpInfo = "Information synthétique"
}

export enum CategoryDocumentFonds {
    private = 'private',
    invest = 'invest', // documents to show during the investment process of a user
    public = 'public',
    other = 'other',
}

export interface GetDocumentFondsByType {
    fondsId: number;
    type: TypeDocumentFonds;
}

export interface DocumentFondsDto {
    id: number;
    type: TypeDocumentFonds;
    key: string;
    fileName: string;
    comment?: string;
    fondsId: number;
    category?: CategoryDocumentFonds

    date: Date;
    url?: string;
}


export interface UploadDocumentFondsDto {
    fondsId: number;
    file: File;
    type: TypeDocumentFonds;
    fileName: string;
    category?: CategoryDocumentFonds

    comment?: string;
    fondsName: string;
}


export interface CreateDocumentFondsDto {
    type: TypeDocumentFonds;
    fileName: string;
    comment?: string;
    fondsId: number;
    category?: CategoryDocumentFonds

    key: string;
}

export interface UpdateDocumentFondsDto extends Partial<CreateDocumentFondsDto> {
    id: number;
}

export interface CreateFondsDto {
    name: string;
    order?: null | number;
    dateCreation?: Date | null;
    show: boolean;
    cover?: null | string;
    description?: null | string;
    overviewInfo?: FondsForUserOverviewAttributeDto;
    performances: CreateFondsObjectivePerformanceDto[];

}

export interface CreateFondsForm {
    name: string;
    order?: null | number;
    dateCreation: Date;
    show: boolean;
    cover?: null | string;
    description?: null | string;
    performance: number;
    overviewInfo?: FondsForUserOverviewAttributeDto;
}


export interface UpdateFondsDto extends Partial<CreateFondsDto> {
    id: number;
    userId?: number
    performances: CreateOrUpdateFondsObjectivePerformanceDto[]
}

// ----------------- Fonds objective performance --------------------------

export interface FondsObjectivePerformanceDto {
    id: number;
    date: Date;
    value: number;
    fondsId: number;

}

export interface CreateFondsObjectivePerformanceDto {
    date: Date;
    value: number;
}

export interface UpdateFondsObjectivePerformanceDto extends CreateFondsObjectivePerformanceDto {
    id: number;
    fondsId: number;
}

export interface CreateOrUpdateFondsObjectivePerformanceDto extends CreateFondsObjectivePerformanceDto {
    id?: number;
    fondsId: number;
}


export enum TypePayement {
    monthly = "mensuel",
    trimester = "trimestriel",
    semester = "semestriel",
    annual = "annuel",
    infine = "in fine",
}


export interface MonitorProjectInterest {
    id?: number;
    ProjetId: number;
    FondId: number;
    date: Date | string;
    interest: number;

}



export interface CategoryProjet {
    type: string;
    content: string;
    ProjetId: number;
}



export enum typeOrderOrFiltration {
    filter = "filter",
    order = "order",
}


export interface BankAccountFondsDto {
    id: number;
    iban: string;
    bic: string;
    country: string;
    beneficiary: string;
    fondsId: number;
    fondsName: string;
}

export interface BankAccountFondsForUserDto extends BankAccountFondsDto {
    reference: string;
    documentRib: DocumentFondsDto;
    transactionStatus: TransactionStatus;
    transactionId: number;
    amount: number;
    typeShare: "distribution" | "cumulative"

}



export interface BankAccountFondsForFondsDto {

    fondsId: number;
    fondsName: string;
    bankAccounts: {
        id: number;
        iban: string;
        bic: string;
        country: string;
        beneficiary: string;

    }[]
}

export interface CreateBankAccountFondsDto {
    iban: string;
    bic: string;
    country: string;
    beneficiary: string;
    fondsId: number;

}

export interface FormBankAccountFondsDto extends CreateBankAccountFondsDto {
    documentRib: FileList
}

export interface UpdateBankAccountFondsDto extends CreateBankAccountFondsDto {
    id: number;
}

export interface FormUpdateBankAccountFondsDto extends UpdateBankAccountFondsDto {
    documentRib: FileList
}

export interface GetBankInfoRequest {
    subscriptionId: number;
    userId: number
}


