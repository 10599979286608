

export enum TypeDocumentPlatform {
    cover = 'Cover',
    logo = 'LOGO',
    other = 'autre',
}

export enum CategoryDocumentPlatform {
    private = 'private',
    public = 'public',
    other = 'other',
}

export interface CreateDocumentPlatformDto {
    fileName: string;
    type: TypeDocumentPlatform;
    category: CategoryDocumentPlatform;
    platformId: number;
    comment?: string;
}

export interface UpdateDocumentPlatformDto extends CreateDocumentPlatformDto {
    id: number;
}

export interface DocumentPlatformDto {
    id: number;
    key: string;
    fileName: string;
    type: TypeDocumentPlatform;
    category: CategoryDocumentPlatform;
    comment?: string;
    platformId: number;
    url: string;
}

export interface FormCreateDocumentPlatform extends CreateDocumentPlatformDto {
    file: File[];
}

export interface FormCreateDocumentPlatformRequest extends CreateDocumentPlatformDto {
    file: File;
    platformName: string;
}