import { StringParam } from 'use-query-params';
import { CategoryQuestion } from '../../../../types/KnowledgeDto';
import { UserWithAllInfoDto } from '../../../../types/user';
import { IMenu } from '../../../DashboardAdmin/UserManage/UserManage';
import { PrimaryButton } from '../../../commun/Buttons';
import { useQueryParamCustom } from '../../../commun/CustomHook/useQueryParamCustom';
import { TabsMenu } from '../../../commun/HorizontalMenu';
import DisplayCategoryQuestion, {
    displayTitle,
} from '../FormInvest/CommunComponent/DisplayCategoryQuestion';

const FormQuestion = ({ user }: { user: UserWithAllInfoDto }) => {
    const [pageQuery, setPageQuery] = useQueryParamCustom(
        'pageQuestion',
        StringParam,
        CategoryQuestion.target_investissments
    );

    const items: IMenu[] = [
        {
            queryPage: CategoryQuestion.target_investissments,
            label: displayTitle(CategoryQuestion.target_investissments),
        },
        {
            queryPage: CategoryQuestion.experience_investissments,
            label: displayTitle(CategoryQuestion.experience_investissments),
        },
        {
            queryPage: CategoryQuestion.funds_functionning,
            label: displayTitle(CategoryQuestion.funds_functionning),
        },
        {
            queryPage: CategoryQuestion.assets,
            label: displayTitle(CategoryQuestion.assets),
        },
    ];

    return (
        <div>
            <TabsMenu items={items} queryPage="pageQuestion" />
            <DisplayCategoryQuestion
                className="w-full px-2 md:w-11/12 md:mx-auto lg:w-9/12"
                category={
                    (pageQuery as CategoryQuestion) ||
                    CategoryQuestion.target_investissments
                }
                renderButtons={({ isValid, isLoadingEditUser }) => (
                    <PrimaryButton
                        type="submit"
                        disabled={!isValid}
                        loading={isLoadingEditUser}
                    >
                        Modifier
                    </PrimaryButton>
                )}
            />
        </div>
    );
};

export default FormQuestion;
