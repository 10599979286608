import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { useEffect, useState } from 'react';
import {
    Control,
    Path,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
    useForm,
} from 'react-hook-form';
import {
    UploadDocumentProjectDto,
    uploadDocumentProject,
    useGetProjectByIdQuery,
    useGetProjectDocumentsWithUrlQuery,
} from '../../../redux/features/projectSlice';
import {
    DocumentProjectDto,
    TypeDocumentProject,
} from '../../../types/project';
import { useNotificationContext } from '../../Context/notification-context';
import { PrimaryButton } from '../../commun/Buttons';
import DisplayDocumentProject from '../../commun/DisplayDocuments/DisplayDocumentProject';
import Loading from '../../commun/Loading';
import UploaderMulti from '../../commun/formComponent/UploaderMulti';
import UploaderSingle from '../../commun/formComponent/UploaderSingle';

function DisplayEditDocument({
    title,
    subTitle,
    documents,
    variableName,
    register,
    watch,
    setValue,
    control,
    loadingDocuments,
}: {
    title: string;
    subTitle: string;
    documents?: DocumentProjectDto[];
    loadingDocuments: boolean;
    variableName: Path<DocumentProjectForm>;
    register: UseFormRegister<DocumentProjectForm>;
    watch: UseFormWatch<DocumentProjectForm>;
    control: Control<DocumentProjectForm, Path<DocumentProjectForm>>;
    setValue: UseFormSetValue<DocumentProjectForm>;
}) {
    return (
        <>
            <TitleComponentForm className="w-full ">{title}</TitleComponentForm>
            <UploaderMulti
                register={register}
                value={variableName}
                watch={watch}
                size={10}
                setValue={setValue}
                control={control}
                button={false}
            ></UploaderMulti>
            <div className="w-full md:w-10:12">
                {/* <h3 className="text-xl font-semibold mb-3 items-center text-center">
            {subTitle}
          </h3> */}
                <div className="w-full sm:w-10/12  mx-auto gap-2 flex justify-center items-center flex-col">
                    {loadingDocuments ? (
                        <Loading />
                    ) : (
                        <>
                            {documents && documents?.length! > 0 ? (
                                documents.map((doc, index) => (
                                    <DisplayDocumentProject
                                        document={doc}
                                        key={index}
                                    />
                                ))
                            ) : (
                                <p>Aucun document n'a été trouvé</p>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

interface DocumentProjectForm {
    documentCover: File[];
    documentsShareIssue: File[];
    documentsProject: File[];
    documentSponsorProject: File[];
    documentOther: File[];
}

export function Documents({ projectId }: { projectId: number }) {
    const { showError, showSuccess } = useNotificationContext();

    const {
        data: documents,
        isLoading: loadingDocuments,
        refetch: refetchDocuments,
    } = useGetProjectDocumentsWithUrlQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const {
        data: project,
        isLoading: isLoadingProject,
        isFetching: isFetchingProject,
        refetch: refetchProject,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    useEffect(() => {
        refetchDocuments();
    }, []);

    const documentCover = documents?.find(
        (document) => document.type === TypeDocumentProject.cover
    );
    const documentsShareIssue = documents?.filter(
        (document) => document.type === TypeDocumentProject.shareIssue
    );
    const documentsProject = documents?.filter(
        (document) => document.type === TypeDocumentProject.project
    );
    const documentSponsorProject = documents?.filter(
        (document) => document.type === TypeDocumentProject.sponsorProject
    );
    const documentOther = documents?.filter(
        (document) => document.type === TypeDocumentProject.other
    );

    const {
        register,
        watch,
        setValue,
        reset,
        control,
        getValues,
        handleSubmit,
    } = useForm<DocumentProjectForm>({
        defaultValues: {
            documentCover: [],
            documentsShareIssue: [],
            documentsProject: [],
            documentSponsorProject: [],
            documentOther: [],
        },
    });

    const [loading, setLoading] = useState(false);

    const onSubmit = async (data: DocumentProjectForm) => {
        setLoading((curr) => !curr);
        const coverImg = data.documentCover?.[0];
        const fileShareIssue = data.documentsShareIssue;
        const fileProject = data.documentsProject;
        const fileSponsorProject = data.documentSponsorProject;
        const fileOther = data.documentOther;

        if (coverImg) {
            let data: UploadDocumentProjectDto = {
                projectId: projectId!,
                type: TypeDocumentProject.cover,
                file: coverImg,
                fileName: coverImg.name,
                projectName: project?.name!,
            };

            await uploadDocumentProject(data)
                .then((res) => {
                    if (res.sucess) {
                        showSuccess('Created', 'File uploaded');
                    } else {
                        showError(
                            'Error',
                            `Error uploading file ${data.fileName}: ${res.message}`
                        );
                    }
                })
                .catch((res) => {
                    showError('Error', 'Error uploading file');
                });
        }

        if (fileShareIssue.length > 0) {
            fileShareIssue.forEach(async (file) => {
                let data: UploadDocumentProjectDto = {
                    projectId: projectId!,
                    type: TypeDocumentProject.shareIssue,
                    file: file,
                    fileName: file.name,
                    projectName: project?.name!,
                };

                await uploadDocumentProject(data)
                    .then((res) => {
                        if (res.sucess) {
                            showSuccess('Created', 'File uploaded');
                        } else {
                            showError(
                                'Error',
                                `Error uploading file ${data.fileName}: ${res.message}`
                            );
                        }
                    })
                    .catch((res) => {
                        showError('Error', 'Error uploading file');
                    });
            });
        }

        if (fileProject.length > 0) {
            fileProject.forEach(async (file) => {
                let data: UploadDocumentProjectDto = {
                    projectId: projectId!,
                    type: TypeDocumentProject.project,
                    file: file,
                    fileName: file.name,
                    projectName: project?.name!,
                };

                await uploadDocumentProject(data)
                    .then((res) => {
                        if (res.sucess) {
                            showSuccess('Created', 'File uploaded');
                        } else {
                            showError(
                                'Error',
                                `Error uploading file ${data.fileName}: ${res.message}`
                            );
                        }
                    })
                    .catch((res) => {
                        showError('Error', 'Error uploading file');
                    });
            });
        }

        if (fileSponsorProject.length > 0) {
            fileSponsorProject.forEach(async (file) => {
                let data: UploadDocumentProjectDto = {
                    projectId: projectId!,
                    type: TypeDocumentProject.sponsorProject,
                    file: file,
                    fileName: file.name,
                    projectName: project?.name!,
                };

                await uploadDocumentProject(data)
                    .then((res) => {
                        if (res.sucess) {
                            showSuccess('Created', 'File uploaded');
                        } else {
                            showError(
                                'Error',
                                `Error uploading file ${data.fileName}: ${res.message}`
                            );
                        }
                    })
                    .catch((res) => {
                        showError('Error', 'Error uploading file');
                    });
            });
        }

        if (fileOther.length > 0) {
            fileOther.forEach(async (file) => {
                let data: UploadDocumentProjectDto = {
                    projectId: projectId!,
                    type: TypeDocumentProject.other,
                    file: file,
                    fileName: file.name,
                    projectName: project?.name!,
                };

                await uploadDocumentProject(data)
                    .then((res) => {
                        if (res.sucess) {
                            showSuccess('Created', 'File uploaded');
                        } else {
                            showError(
                                'Error',
                                `Error uploading file ${data.fileName}: ${res.message}`
                            );
                        }
                    })
                    .catch((res) => {
                        showError('Error', 'Error uploading file');
                    });
            });
        }
        await new Promise((r) => setTimeout(r, 1000));
        await refetchDocuments();
        reset();
        setLoading((curr) => !curr);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="">
            {loadingDocuments ? (
                <Loading />
            ) : (
                <>
                    <TitleComponentForm>Image du projet</TitleComponentForm>
                    <UploaderSingle
                        register={register}
                        size={10}
                        value={'documentCover'}
                        watch={watch}
                        setValue={setValue}
                        typeFile={['png', 'jpg', 'jpeg']}
                        button={false}
                    ></UploaderSingle>
                    <div className="w-full md:w-10:12">
                        <div className="w-9/12 mx-auto gap-2 flex justify-center items-center flex-col">
                            {loadingDocuments ? (
                                <Loading />
                            ) : (
                                <>
                                    {documentCover !== undefined ? (
                                        <DisplayDocumentProject
                                            document={documentCover!}
                                            key={1}
                                        />
                                    ) : (
                                        <p>Aucune photo n'a été trouvé</p>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    <DisplayEditDocument
                        title="Documents d'émission de titre"
                        subTitle="Documents d'émission de titre"
                        documents={documentsShareIssue}
                        register={register}
                        variableName={'documentsShareIssue'}
                        watch={watch}
                        setValue={setValue}
                        control={control}
                        loadingDocuments={loadingDocuments}
                    />
                    <DisplayEditDocument
                        title="Documents sur le projet"
                        subTitle="Documents sur le projet"
                        documents={documentsProject}
                        register={register}
                        variableName={'documentsProject'}
                        watch={watch}
                        setValue={setValue}
                        control={control}
                        loadingDocuments={loadingDocuments}
                    />
                    <DisplayEditDocument
                        title="Documents sur le porteur du projet"
                        subTitle="Documents sur le porteur du projet"
                        documents={documentSponsorProject}
                        register={register}
                        variableName={'documentSponsorProject'}
                        watch={watch}
                        setValue={setValue}
                        control={control}
                        loadingDocuments={loadingDocuments}
                    />
                    <DisplayEditDocument
                        title="Documents autre"
                        subTitle="Documents autre"
                        documents={documentOther}
                        register={register}
                        variableName={'documentOther'}
                        watch={watch}
                        setValue={setValue}
                        control={control}
                        loadingDocuments={loadingDocuments}
                    />
                    <div className="w-10/12 mx-auto flex justify-center items-center my-3">
                        <PrimaryButton type="submit" loading={loading}>
                            Ajouter les documents
                        </PrimaryButton>
                    </div>
                </>
            )}
        </form>
    );
}
