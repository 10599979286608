import { ReactNode, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { numericValue } from '../../../../../function/Utils';
import { useGetQuestionsQuery } from '../../../../../redux/features/knowledgeSlice';
import { useFetchTokenQuery } from '../../../../../redux/features/tokenSlice';
import {
    useEditUserKnowledgeMutation,
    useGetUserWithAllInfoForCustomerQuery,
} from '../../../../../redux/features/userSlice';
import {
    CategoryQuestion,
    KnowledgeQuestionDto,
    TypeQuestion,
} from '../../../../../types/KnowledgeDto';
import { UpdateUserKnowlegdeDto } from '../../../../../types/user';
import FieldArray from '../../../../commun/formComponent/FieldArray';
import FormTemplate from '../../../../commun/formComponent/FormTemplate';
import Loading from '../../../../commun/Loading';
import { useNotificationContext } from '../../../../Context/notification-context';
import { DisplayQuestion } from './DisplayQuestion';

export interface FormQuestionItemInterface {
    questionId: number;
    question: KnowledgeQuestionDto;
    answerIds: number[];
    answer?: string | number;
    answerId?: number;
}

export interface FormQuestionInterface {
    value: FormQuestionItemInterface[];
}

export const displayTitle = (cat: CategoryQuestion): string => {
    switch (cat) {
        case CategoryQuestion.financial_products:
            return 'Produits financiers';
        case CategoryQuestion.funds_functionning:
            return 'Fonctionnement des fonds';
        case CategoryQuestion.impact_knowledge:
            return "Connaissance de l'impact";
        case CategoryQuestion.target_investissments:
            return "Vos objectifs d'investissement";
        case CategoryQuestion.experience_investissments:
            return 'Votre expérience financière';
        case CategoryQuestion.assets:
            return 'Simulateur de patrimoine';
        default:
            return '';
    }
};

function DisplayCategoryQuestion({
    category,
    renderButtons,
    beforeSubmitLogic,
    afterSubmitLogic,
    showGoogdAnswer,
    renderDescription,
    className,
}: {
    category: CategoryQuestion;
    className?: string;
    renderDescription?: ReactNode;
    renderButtons: (props: {
        isValid: boolean;
        isLoadingEditUser: boolean;
    }) => ReactNode;
    beforeSubmitLogic?: (props: { isDirty: boolean }) => boolean;
    afterSubmitLogic?: (props: { isDirty: boolean }) => void;
    showGoogdAnswer?: (props: {
        questions: KnowledgeQuestionDto[];
    }) => ReactNode;
}) {
    const formState = useForm<FormQuestionInterface>({});

    const {
        register,
        handleSubmit,
        watch,
        reset,
        getValues,
        setValue,
        control,
        formState: { isValid, isDirty },
    } = formState;

    const { showError, showSuccess } = useNotificationContext();

    const [editUserKnowledge, { isLoading: isLoadingEditUser }] =
        useEditUserKnowledgeMutation();

    const { data: questions, isLoading: loadingQuestion } =
        useGetQuestionsQuery();
    const { data: userData } = useFetchTokenQuery();
    const { data: user, isLoading: loading } =
        useGetUserWithAllInfoForCustomerQuery(userData?.id ?? 0, {
            skip: !userData,
        });

    const onSubmit = async (dataForm: FormQuestionInterface) => {
        if (beforeSubmitLogic) {
            const shouldContinue = beforeSubmitLogic({ isDirty: isDirty });

            if (!shouldContinue) {
                return;
            }
        }

        const data: UpdateUserKnowlegdeDto = {
            userId: user?.id!,
            knowledges: dataForm.value.map((data) => {
                if (
                    [TypeQuestion.text, TypeQuestion.number].includes(
                        data.question.type
                    )
                ) {
                    return {
                        questionId: data.questionId,
                        answerIds: undefined,
                        answer:
                            data.question.type === TypeQuestion.text
                                ? data.answer?.toString()
                                : numericValue(
                                      data.answer?.toString() || ''
                                  ).toString(),
                        type: data.question.type,
                        answerId: data?.answerId ?? undefined,
                    };
                } else {
                    if (
                        typeof data.answerIds === 'string' ||
                        typeof data.answerIds === 'number'
                    ) {
                        // if ([TypeQuestion.radio, TypeQuestion.select].includes(data.question.type) ){
                        return {
                            questionId: data.questionId,
                            answer: undefined,
                            type: data.question.type,
                            answerIds: [parseInt(data.answerIds as any)],
                            answerId: undefined,
                        };
                    } else {
                        return {
                            questionId: data.questionId,
                            answer: undefined,
                            type: data.question.type,
                            answerIds: data.answerIds,
                            answerId: undefined,
                        };
                    }
                }
            }),
        };

        await editUserKnowledge(data)
            .unwrap()
            .then(async (res) => {
                showSuccess('Sauvegardé', 'Données sauvegardées avec succès');
                if (afterSubmitLogic) {
                    afterSubmitLogic({ isDirty: isDirty });
                }
            })
            .catch((err) => {
                showError('Erreur', 'Erreur lors de la sauvegarde des données');
            });
    };

    const questionsCategory = useMemo(
        () => questions?.filter((q) => q.category === category) || [],
        [questions, category]
    );

    useEffect(() => {
        if (questionsCategory && questionsCategory?.length > 0 && user) {
            const data: FormQuestionItemInterface[] = questionsCategory.map(
                (question) => {
                    const answer = user?.knowledgeUsers?.find(
                        (k) => k.question.id === question.id
                    )?.answers;

                    return {
                        questionId: question.id,
                        question: question,
                        answerIds: answer?.map((a) => a.id) ?? [],
                        answer: answer?.[0]?.answer ?? undefined,
                        answerId: answer?.[0]?.id,
                    };
                }
            );
            reset({ value: data });
        }
    }, [questionsCategory, user, category]);

    return (
        <div className="form_investisseur_container">
            {showGoogdAnswer
                ? showGoogdAnswer({
                      questions: questionsCategory,
                  })
                : null}

            <FormTemplate
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                isValid={isValid}
                loading={loading}
                useSubmitBtn={false}
                className={className}
            >
                {loadingQuestion ? (
                    <Loading />
                ) : (
                    <>
                        <div className="!text-center font-semibold text-mainColor font-mainFontFamily !text-4xl mb-10 w-full">
                            {displayTitle(category)}
                        </div>
                        {renderDescription}
                        <FieldArray
                            control={control}
                            name="value"
                            className="flex flex-col gap-3 mx-auto"
                        >
                            {({ fields, append, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <div
                                            className="mx-auto w-full"
                                            key={field.id}
                                        >
                                            <DisplayQuestion
                                                control={control}
                                                register={register}
                                                setValue={setValue}
                                                formState={formState}
                                                watch={watch}
                                                key={index}
                                                question={
                                                    getValues(
                                                        `value.${index}.question`
                                                    ) as unknown as KnowledgeQuestionDto
                                                }
                                                index={index}
                                            >
                                                <h3 className=" !font-mainFontFamily !mb-4">
                                                    {
                                                        (
                                                            getValues(
                                                                `value.${index}.question`
                                                            ) as unknown as KnowledgeQuestionDto
                                                        ).question
                                                    }
                                                </h3>
                                                {(
                                                    getValues(
                                                        `value.${index}.question`
                                                    ) as unknown as KnowledgeQuestionDto
                                                ).comment ? (
                                                    <p className="mb-3">
                                                        {
                                                            (
                                                                getValues(
                                                                    `value.${index}.question`
                                                                ) as unknown as KnowledgeQuestionDto
                                                            ).comment
                                                        }
                                                    </p>
                                                ) : null}
                                            </DisplayQuestion>
                                        </div>
                                    ))}
                                </>
                            )}
                        </FieldArray>
                        <div className="w-full my-6 mx-auto flex gap-3 items-center justify-center">
                            {renderButtons({ isValid, isLoadingEditUser })}
                        </div>
                    </>
                )}
            </FormTemplate>
        </div>
    );
}

export default DisplayCategoryQuestion;
