import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { BackButton } from '../../commun/Buttons';

interface Props {
    title: string;
    description?: string;
    children?: React.ReactNode;
    classNameContainer?: string;
    classNameHeader?: string;
    subTitle?: React.ReactNode;
    backButton?: boolean;
    onClickReturn?: () => void;
    textBackButton?: string;
}

const WrapComponentDashboardUser: React.FC<Props> = ({
    title,
    description,
    children,
    classNameContainer,
    classNameHeader,
    subTitle,
    backButton,
    onClickReturn,
    textBackButton,
}) => {
    // get the route

    const location = useLocation();
    const navigate = useNavigate();

    const onClickDefault = () => navigate(-1);

    return (
        <div
            className={`bg-white h-full lg:shadow-low sm:rounded-lg relative overflow-hidden ${classNameContainer}`}
        >
            <div
                className={`px-4 py-5 grid md:grid-cols-2 ${
                    location.pathname.includes('DashBoardAdmin')
                        ? 'bg-secondBackgroundColor'
                        : 'bg-secondBackgroundColor'
                }  ${classNameHeader}`}
            >
                <div className="relative grid md:grid-cols-[auto,1fr] ml-2 md:gap-x-3 ">
                    {backButton ? (
                        <div className="relative -top-3 -right-3 md:top-0 md:right-0 ">
                            <BackButton
                                onClick={onClickReturn || onClickDefault}
                                textBackButton={textBackButton}
                            />
                        </div>
                    ) : null}
                    <div className={`${backButton ? '' : 'md:col-span-2'}`}>
                        <h3
                            className={`text-2xl font-mainFontFamily leading-6 font-semibold text-mainColor `}
                        >
                            {title}
                        </h3>
                        {description ? (
                            <p
                                className={`mt-1 max-w-2xl text-sm text-gray-500 `}
                            >
                                {description}
                            </p>
                        ) : null}
                    </div>
                </div>
                {subTitle ? (
                    <div className="self-center">{subTitle}</div>
                ) : null}
            </div>
            <div className="border-t border-gray-200 h-full">{children}</div>
        </div>
    );
};

export default WrapComponentDashboardUser;
