import {
  useGetMetricKnowledgeUserQuery,
  useGetUserWithAllInfoQuery,
} from "../../../redux/features/userSlice";
import { KnowledgeUserDto, UserDto } from "../../../types/user";
import { MetricPercentDisplay } from "../../DashboardUser/Profile/FormInvest/FormulaireEnd";
import { SuccessIcon, ErrorIcon } from "../../commun/IconsFeedBack";
import Loading from "../../commun/Loading";

export function Questionnaires({ userId }: { userId: number }) {
  const { data: metricKnowledgeUser, isLoading: isLoadingMetricKnowledgeUser } =
    useGetMetricKnowledgeUserQuery(userId);

  const { data: user, isLoading: isLoadingUser } =
    useGetUserWithAllInfoQuery(userId);

  return (
    <div className="w-full md:10/12  mx-auto p-3  flex flex-col gap-3 justify-center items-center h-full">
      {isLoadingMetricKnowledgeUser || isLoadingUser ? (
        <Loading />
      ) : (
        <>
          {metricKnowledgeUser &&
          metricKnowledgeUser?.resultsPercent.length > 0 ? (
            <>
              <div className="w-full max-w-[500px] mx-auto">
                <div className="grid grid-cols-fr1auto space-x-2 mb-3">
                  <p className=" text-start">Horizon de temps </p>
                  <div className="max-w-[50px]">
                    {metricKnowledgeUser.investmentHorizon ? (
                      <SuccessIcon />
                    ) : (
                      <ErrorIcon />
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-fr1auto space-x-2">
                  <p className=" text-start">Investissement non garantis </p>
                  <div className="max-w-[50px]">
                    {metricKnowledgeUser &&
                    metricKnowledgeUser.balanceInvestment ? (
                      <SuccessIcon />
                    ) : (
                      <ErrorIcon />
                    )}
                  </div>
                </div>
              </div>

              <h4 className="mt-5 text-center font-mainFontFamily text-xl">
                Voici les resultats sur les connaissances
              </h4>

              <dl className="mt-5 w-full md:max-w-md mx-auto grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center">
                {metricKnowledgeUser?.resultsPercent?.map((metric, index) => (
                  <div key={index} className="flex flex-col bg-gray-600/5 p-8">
                    <MetricPercentDisplay data={metric} />
                  </div>
                ))}
              </dl>
              {user ? (
                <DisplayQuestions
                  user={user}
                  questions={user?.knowledgeUsers || []}
                />
              ) : null}
            </>
          ) : (
            <div className="">
              <p>Le formulaire d'onboarding n'a pas était fait ou fini.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function DisplayQuestions({
  questions,
  user,
}: {
  questions: KnowledgeUserDto[];
  user: UserDto;
}) {
  return (
    <div className="mt-3">
      <h4 className="text-center mb-5 font-mainFontFamily text-2xl">
        Voici les réponses de{" "}
        <span className=" text-titleColor">
          {user.firstName} {user.lastName}
        </span>
      </h4>
      <div className="w-full flex flex-col gap-5">
        {questions.map((q, key) => (
          <div key={key} className="flex flex-col gap-1">
            <h3 className="text-start font-semibold font-mainFontFamily">
              {q.question.question}
            </h3>
            <ul className="ml-5">
              {q.answers.map((answer, key) => (
                <li className="py-1 list-disc text-mainColor ">
                  <span className=" text-gray-600">{answer.answer}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
