import { EtapeForm } from '@components/Context/AppContext';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useFetchTokenQuery } from '../../../../redux/features/tokenSlice';
import {
    useGetMetricKnowledgeUserQuery,
    useGetUserWithAllInfoForCustomerQuery,
    useOnBoardingDoneMutation,
} from '../../../../redux/features/userSlice';
import { CategoryQuestion } from '../../../../types/KnowledgeDto';
import { MetricKnowledgeUserCategory } from '../../../../types/user';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import Loading from '../../../commun/Loading';
import { useNotificationContext } from '../../../Context/notification-context';

const FormulaireEnd = ({
    reValidationForm,
}: {
    reValidationForm?: boolean;
}) => {
    const [stepValidation, setStepValidation] = useState<number>(
        parseInt(sessionStorage.getItem('stepValidationForm') || '0')
    );

    useEffect(() => {
        sessionStorage.setItem('stepValidationForm', stepValidation.toString());
        const elementDashBoard = document.querySelector(
            '.form_investisseur_container'
        ) as HTMLElement;
        elementDashBoard.scrollTo(0, 0);
    }, [stepValidation]);

    return (
        <>
            {stepValidation === 0 ? (
                <EndForm
                    setStepValidation={setStepValidation}
                    reValidationForm={reValidationForm}
                />
            ) : stepValidation === 1 ? (
                <EndMessage />
            ) : null}
        </>
    );
};

function EndForm({
    setStepValidation,
    reValidationForm,
}: {
    setStepValidation: React.Dispatch<React.SetStateAction<number>>;
    reValidationForm?: boolean;
}) {
    const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();
    const { data: user, isLoading: loading } =
        useGetUserWithAllInfoForCustomerQuery(userData?.id ?? 0, {
            skip: !userData,
        });

    const [onBoardingDone, { isLoading: isLoadingOnBoardingDone }] =
        useOnBoardingDoneMutation();

    const {
        data: metricKnowledgeUser,
        isLoading: isLoadingMetricKnowledgeUser,
    } = useGetMetricKnowledgeUserQuery(userData?.id ?? 0, {
        skip: !userData,
    });

    const [toggleHorizon, setToggleHorizon] = useState<boolean>(true);
    const [toggleBalance, setToggleBalance] = useState<boolean>(true);
    const { showError, showSuccess } = useNotificationContext();

    const handleFinish = async () => {
        if (user) {
            await onBoardingDone(user.id)
                .unwrap()
                .then((res) => {
                    showSuccess(
                        'Sauvegardé',
                        'Données sauvegardées avec succès'
                    );
                    setStepValidation(1);
                })
                .catch((err) => {
                    showError(
                        'Erreur',
                        'Erreur lors de la sauvegarde des données'
                    );
                });
        }
    };

    const etapeObject = useContext(EtapeForm);

    return (
        <div className="w-full md:9/12 lg:w-2/3 mx-auto p-3  flex flex-col gap-3 justify-center items-center h-[74vh]">
            {isLoadingMetricKnowledgeUser || loadingUser || loading ? (
                <Loading />
            ) : (
                <>
                    <h3 className="font-semibold text-center text-mainColor font-mainFontFamily text-4xl mb-10 w-full">
                        {reValidationForm
                            ? 'Validez vos modifications'
                            : 'Validez votre inscription'}
                    </h3>
                    <p className="text-center text-lg">
                        Merci d'avoir rempli le formulaire. Cliquez sur terminer
                        pour finaliser votre inscription, sinon cliquez sur
                        précédent si vous souhaitez modifier vos inforamtions
                        avant de finaliser.
                    </p>

                    <div className="mt-4 flex justify-center gap-3">
                        <WhiteButton
                            onClick={() => {
                                etapeObject?.setStep(etapeObject?.step - 1);
                            }}
                        >
                            Précédent
                        </WhiteButton>
                        <PrimaryButton
                            onClick={() => {
                                handleFinish();
                            }}
                            loading={isLoadingOnBoardingDone}
                        >
                            Terminer
                        </PrimaryButton>
                    </div>
                </>
            )}
        </div>
    );
}

export function MetricPercentDisplay({
    data,
}: {
    data: MetricKnowledgeUserCategory;
}) {
    const convertCategoryToName = (cat: CategoryQuestion) => {
        switch (cat) {
            case CategoryQuestion.target_investissments:
                return "Objectifs d'investissement";
            case CategoryQuestion.experience_investissments:
                return 'Expérience en investissement';
            case CategoryQuestion.financial_products:
                return 'Produits financiers';
            case CategoryQuestion.funds_functionning:
                return "Fonctionnement d'un fonds";
            case CategoryQuestion.impact_knowledge:
                return "Connaissance de l'impact";
            default:
                return '';
        }
    };

    return (
        <>
            <dt className="text-sm font-semibold leading-6 text-gray-600">
                {convertCategoryToName(data.category)}
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                {((data.totalGoodAnswers * 100) / data.totalAnswers).toFixed(2)}{' '}
                %
            </dd>
        </>
    );
}

function EndMessage() {
    const navigate = useNavigate();

    return (
        <div className="w-full md:9/12 lg:w-2/3 mx-auto p-3  flex flex-col gap-3 justify-center items-center h-[60vh]">
            <div className="text-2xl font-bold">
                <FontAwesomeIcon
                    icon={faSeedling}
                    className="text-green-600"
                    size="3x"
                />
            </div>
            <h3 className=" font-mainFontFamily text-2xl md:text-3xl">
                Merci pour votre inscription
            </h3>
            <p>
                Votre demande est en cours de traitement. Vous recevrez un email
                de confirmation une fois votre compte validé. Le processus peut
                prendre jusqu'à 24h.
            </p>
            <PrimaryButton
                className="mt-5"
                onClick={() => navigate('/DashBoardClient/tableau-de-bord')}
            >
                Tableau de bord
            </PrimaryButton>
        </div>
    );
}

export default FormulaireEnd;
