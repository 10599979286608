import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryParam, NumberParam } from "use-query-params";
import {
  transformDate,
  stringToInt,
  stringToFloat,
  formatDate,
} from "../../../function/Utils";
import { useGetCashIdsQuery } from "../../../redux/features/projectSlice";
import {
  useGetTransactionDocumentsQuery,
  useGetTransactionByIdQuery,
  useEditTransactionMutation,
  useGetTransactionSponsorIdQuery,
  useDeleteTransactionMutation,
  UploadDocumentTransactionDto,
  uploadDocumentTransaction,
  useGetTransactionsUserQuery,
} from "../../../redux/features/transactionSlice";
import {
  useGetUsersSearchQuery,
  usersApi,
} from "../../../redux/features/userSlice";
import { useAppDispatch } from "../../../redux/store";
import {
  TypeTransaction,
  TypeTransactionProject2Cash,
  UpdateTransactionDtoForm,
  TransactionDto,
  TransactionStatus,
} from "../../../types/transactions";
import {
  useNotificationContext,
  Level,
} from "../../Context/notification-context";
import { WhiteButton, PrimaryButton } from "../../commun/Buttons";
import DisplayDocumentTransaction from "../../commun/DisplayDocuments/DisplayDocumentTransaction";
import Loading from "../../commun/Loading";
import PopUp from "../../commun/PopUp";
import InputComponent from "../../commun/formComponent/InputComponent";
import SelectComponent, {
  IOptionValues,
} from "../../commun/formComponent/SelectComponent";
import UploaderMulti from "../../commun/formComponent/UploaderMulti";
import useDeleteItem from "../../commun/CustomHook/useDeleteItem";

export default function ManageTransactionOutShareD({
  editToggle,
  setEditToggle,
}: {
  editToggle: boolean;
  setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const dispatch = useAppDispatch();

  const { showError, showSuccess } = useNotificationContext();

  const [transactionId, setQueryParam] = useQueryParam(
    "transactionId",
    NumberParam
  );

  const {
    data: documents,
    isLoading: loadingDocument,
    refetch,
  } = useGetTransactionDocumentsQuery(transactionId ?? 0);

  useEffect(() => {
    refetch();
  }, []);

  const { data: transaction, isLoading } = useGetTransactionByIdQuery(
    transactionId ?? 0
  );

  const [loading, setLoading] = useState<boolean>(false);

  const setDefautlValues = (): void => {
    let typeProject = undefined;
    let type = transaction?.type;
    if (type === TypeTransaction.ProjectBuying) {
      typeProject = TypeTransactionProject2Cash.Capital;
      type = TypeTransaction.projectCash;
    } else if (type === TypeTransaction.projectStopCash) {
      typeProject = TypeTransactionProject2Cash.Closure;
      type = TypeTransaction.projectCash;
    } else if (type === TypeTransaction.projectCash) {
      typeProject = TypeTransactionProject2Cash.Coupon;
    }
    reset({
      ...transaction,
      subscriptionUserFondsId:
        transaction?.subscriptionUserFondsId || undefined,
      sponsored:
        transaction &&
        transaction?.type === TypeTransaction.userCash &&
        transaction?.sponsorId
          ? true
          : false,
      status: transaction?.status,
      type,
      date: transformDate(new Date(transaction?.date!).getTime())
        .toJSON()
        ?.split("T")[0],
      dateInterest: transaction?.dateInterest
        ? transformDate(new Date(transaction?.dateInterest!).getTime())
            .toJSON()
            ?.split("T")[0]
        : undefined,
      typeTransactionProject2Cash: typeProject,
    });
  };

  useEffect(() => {
    if (transaction) {
      setDefautlValues();
    }
  }, [transaction]);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    control,
    formState: { isSubmitting, submitCount },
  } = useForm<UpdateTransactionDtoForm>();

  const [
    editTransaction,
    { isLoading: isLoadingEdit, isSuccess: isSuccessEdit },
  ] = useEditTransactionMutation();

  const { data: sponsorId } = useGetTransactionSponsorIdQuery(
    watch("from") ?? 0,
    {
      skip: watch("type") !== TypeTransaction.userCash || !watch("from"),
    }
  );

  const [
    deleteTransaction,
    { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
  ] = useDeleteTransactionMutation();

  const onSubmit = async (data: UpdateTransactionDtoForm) => {
    setLoading((curr) => !curr);
    const files = data?.files;
    delete data.files;

    let sponsorIdToSend = null;

    // if (data?.sponsorId) {
    //   sponsorIdToSend = data.sponsorId;
    // }

    await editTransaction({
      id: data?.id!,
      donationFee: data?.donationFee,
      entranceFee: data?.entranceFee,
      emptiedTransaction: data?.emptiedTransaction,
      type: data?.type,
      sponsorId: sponsorIdToSend as any,
      fondsId: stringToInt(data.fondsId),
      date: transformDate(data.date),
      status: data.status ? data.status : undefined,
      to: data.to,
      comment: data?.comment,
      interest: data?.interest ? stringToFloat(data.interest) : undefined,
      typeInvestisseurId: data?.typeInvestisseurId
        ? data.typeInvestisseurId
        : undefined,
      initialValueOfShare: data?.initialValueOfShare,
      typeShare: data?.typeShare,
      feesValue: data?.feesValue,
      actualValueOfShareBool: data?.actualValueOfShareBool,
      subscriptionUserFondsId: data?.subscriptionUserFondsId,
      from: data.from,
      dateInterest: data.dateInterest ? transformDate(data.dateInterest) : null,
      amount: stringToFloat(data.amount),
      transactionUserId: data.transactionUserId
        ? stringToInt(data.transactionUserId)
        : null,
    })
      .unwrap()
      .then(async (res) => {
        try {
          if (files) {
            for (let i = 0; i < files.length; i++) {
              let data: UploadDocumentTransactionDto = {
                transactionId: (res as TransactionDto).id,
                file: files[i],
                fileName: files[i].name,
                // type:(res.payload as TransactionDto)?.type === TypeTransaction.ProjectBuying ? TypeDocumentTransaction. ,
              };
              await uploadDocumentTransaction(data)
                .then((res) => {
                  if (res.sucess) {
                    showSuccess("Created", "File uploaded");
                  } else {
                    showError(
                      "Error",
                      `Error uploading file ${data.fileName}: ${res.message}`
                    );
                  }
                })
                .catch((res) => {
                  showError("Error", "Error uploading file");
                });
            }
          }
          showSuccess("Edited", "Transaction edited successfully");
          setEditToggle((curr) => !curr);
        } catch (error) {
          showError("Error", "Error editing transaction");
          return;
        }
      })
      .catch((err) => {
        showError("Error", "Error editing transaction");
      });
    setLoading((curr) => !curr);
  };

  const { data: transactionUser } = useGetTransactionsUserQuery(
    watch("to") ?? 0,
    {
      skip:
        watch("type") !== TypeTransaction.cashUser || watch("to") === undefined
          ? true
          : false,
    }
  );

  const transactionUserSelect = (): IOptionValues[] => {
    if (transactionUser) {
      return transactionUser
        .filter(
          (t) => t.type === TypeTransaction.userCash && t.from === watch("to")
        )
        .map((t) => {
          return {
            label: `date: ${formatDate(new Date(t.date))} montant: ${t.amount}`,
            value: t.id,
          };
        });
    } else {
      return [];
    }
  };

  const [searchFrom, setSearchFrom] = useState<string>("");
  const [searchTo, setSearchTo] = useState<string>("");

  let { data: cashData } = useGetCashIdsQuery();

  let { data: usersData } = useGetUsersSearchQuery(
    watch("type") === TypeTransaction.userCash ? searchFrom : searchTo,
    {
      skip:
        watch("type") === TypeTransaction.userCash ||
        watch("type") === TypeTransaction.cashUser ||
        watch("type") === TypeTransaction.newParadigmsUser
          ? false
          : true,
    }
  );

  useEffect(() => {
    if (transaction) {
      dispatch(
        usersApi.util.updateQueryData("getUsersSearch", "", (draft) => {
          if (draft.find((u) => u.id === transaction.to)) return draft;
          return draft?.concat([
            {
              id: transaction.to,
              firstName: transaction.userNameTo!,
              lastName: "",
            },
          ]);
        })
      );
    }
  }, [transaction]);

  const selectFunc = useCallback(
    (type: TypeTransaction, from: boolean): IOptionValues[] => {
      let cash: IOptionValues[] =
        cashData
          ?.filter((c) => c.fondsId === getValues("fondsId"))
          .map((val) => {
            return {
              label: "Cash",
              value: val.id,
            };
          }) ?? [];

      let user: IOptionValues[] =
        usersData?.map((user, key) => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          };
        }) ?? [];

      if (from) {
        // if (getValues("from") === undefined)
        //   setValue("from", parseInt(cash[0].value as string));
        return cash;
      } else {
        // if (getValues("to") === undefined)
        //   setValue("to", parseInt(user[0].value as string));

        return user;
      }
    },
    [watch("fondsId"), cashData, usersData, watch("type")]
  );

  const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
    transactionId: number;
  }>({
    deleteItem: () => deleteTransaction(transaction?.id!),
    toDoIfSuccess: () => setEditToggle(false),
    message: "transaction",

    isLoading: isLoadingDelete,
  });

  const [expanded, setExpanded] = useState<boolean>(true);

  return (
    <PopUp
      open={editToggle}
      setOpen={setEditToggle}
      submitItemName={`Editer`}
      buttonBoolean={false}
      expand={expanded}
      setExpand={setExpanded}
      title={() => {
        return (
          <div className="relative">
            <h3 className="text-3xl font-semibold leading-6 text-gray-900">
              Editer cette transaction
            </h3>
            {transaction && transaction?.id ? (
              <div className="absolute top-[-20px] cursor-pointer left-4 transition-all hover:scale-105">
                <FontAwesomeIcon
                  onClick={() => setOpenDelete(true)}
                  icon={faTrash}
                  className=""
                />
              </div>
            ) : null}
          </div>
        );
      }}
    >
      <DeleteComponent title="cette transaction" />

      {isLoading ? (
        <div className="flex justify-center items-center h-[70vh]">
          <Loading />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="formTemplateSingUp"
          style={{ width: `${expanded ? "98%" : "80%"}` }}
        >
          <div className="w-full col-span-2 flex justify-center items-center">
            <PrimaryButton
              loading={isLoading}
              onClick={() => {
                setValue("status", TransactionStatus.VALIDATED);
                handleSubmit(onSubmit)();
              }}
            >
              Valider la transaction
            </PrimaryButton>
          </div>
          <div
            className={`w-full mx-auto ${
              expanded ? "sm:flex sm:space-x-5 sm:justify-between " : ""
            }`}
          >
            <div className={`w-full ${expanded ? "sm:w-10/12 " : ""}`}>
              <SelectComponent
                register={register}
                value={"from"}
                container={true}
                setSearch={setSearchFrom}
                control={control}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                useParamBoolean={true}
                optionValues={selectFunc(watch("type"), true)}
                submitCount={submitCount}
              >
                <h3>
                  Origine <span className="required">*</span>
                </h3>
              </SelectComponent>
              <SelectComponent
                register={register}
                value={"to"}
                setSearch={setSearchTo}
                container={true}
                control={control}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                useParamBoolean={true}
                submitCount={submitCount}
                optionValues={selectFunc(watch("type"), false)}
              >
                <h3>
                  Pour <span className="required">*</span>
                </h3>
              </SelectComponent>

              <SelectComponent
                register={register}
                value={"transactionUserId"}
                container={true}
                optionValues={transactionUserSelect()}
                watch={watch}
                control={control}
                getValues={getValues}
                setValue={setValue}
                submitCount={submitCount}
                useParamBoolean={true}
              >
                <h3>
                  Transaction <span className="required">*</span>
                </h3>
              </SelectComponent>

              <InputComponent
                register={register}
                value={"amount"}
                type={"number"}
              >
                <h3>
                  Montant <span className="required">*</span>
                </h3>
              </InputComponent>

              <InputComponent register={register} value={"date"} type={"date"}>
                <h3>
                  Date <span className="required">*</span>
                </h3>
              </InputComponent>

              <InputComponent
                register={register}
                value={"comment"}
                required={false}
              >
                <h3>Commentaire</h3>
              </InputComponent>
              <SelectComponent
                register={register}
                value={"status"}
                container={true}
                control={control}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
                optionValues={Object.values(TransactionStatus).map(
                  (v: string) => {
                    return {
                      value: v,
                      label: v,
                    };
                  }
                )}
              >
                <h3>
                  Status <span className="required">*</span>
                </h3>
              </SelectComponent>
            </div>
            {expanded ? (
              <div className="w-full sm:w-11/12">
                <UploaderMulti
                  register={register}
                  value={"files"}
                  size={10}
                  watch={watch}
                  setValue={setValue}
                  control={control}
                  button={false}
                >
                  <h3 className="text-2xl mb-5">Ajouter des documents</h3>
                </UploaderMulti>
                <div className="">
                  <h3 className="text-xl font-semibold mb-3">Documents</h3>
                  <div className="w-full sm:11/12 mx-auto gap-2 flex justify-center flex-col">
                    {loadingDocument ? (
                      <Loading />
                    ) : (
                      <>
                        {documents && documents?.length! > 0 ? (
                          documents.map((doc, index) => (
                            <DisplayDocumentTransaction
                              document={doc}
                              key={index}
                            />
                          ))
                        ) : (
                          <p>Aucun document n'a été trouvé</p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
            <WhiteButton
              onClick={() => {
                setEditToggle(false);
              }}
              className="w-full"
            >
              Cancel
            </WhiteButton>
            <PrimaryButton type="submit" className="w-full">
              {loading !== undefined && loading === true ? (
                <Loading size={4} />
              ) : (
                "Edit"
              )}
            </PrimaryButton>
          </div>
        </form>
      )}
    </PopUp>
  );
}
