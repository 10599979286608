import { TransactionDto, TransactionSubscriptionDto } from "./transactions";
import { InvestAccountDto, StateStatus } from "./user";

export enum SubscriptionUserFondsStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REFUSED = 'REFUSED',
}

export interface SubscriptionUserFondsDto {
    id: number;
    investAccountId: number;
    userId: number;
    status: SubscriptionUserFondsStatus;
    dateDone?: Date;
    date: Date;
    transactions: TransactionSubscriptionDto[];
    investAccount?: InvestAccountDto
    documents?: DocumentSubscriptionDto[]
}

export interface InvestAccountWithSubscriptionDto {
    id: number;
    physicalOrMoral: "physical" | "moral";
    userId: number;
    status: StateStatus;

    subscriptions: SubscriptionUserFondsDto[];
}

export interface CreateSubscriptionUserFondsDto {
    investAccountId: number;
    userId: number;
    status: SubscriptionUserFondsStatus;
    dateDone?: Date;
    date: Date;

}

export enum DocumentSubscriptionType {
    SUBSCRIPTION = 'SUBSCRIPTION',
    OTHER = 'OTHER',
}

export interface DocumentSubscriptionDto {
    id: number;
    key: string;
    fileName: string;
    state: StateStatus
    type: DocumentSubscriptionType
    subscriptionUserFondsId: number;
    validation?: DocumentSubscriptionValidationDto;
    url?: string;
}

export interface CreateDocumentSubscriptionDto {
    key: string;
    fileName: string;
    subscriptionUserFondsId: number;
    type: DocumentSubscriptionType

}

export interface FormUploadSubscriptionDto extends CreateDocumentSubscriptionDto {
    file: File;
    userId: number

}

export interface DocumentSubscriptionValidationDto {
    id: number;
    status: StateStatus;
    documentId: number;
    performValidationUserId: number;
    comment?: string
}

export interface CreateDocumentSubscriptionValidationDto {
    status: StateStatus;
    documentId: number;
    performValidationUserId: number;
    comment?: string
}