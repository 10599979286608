import React, { useContext, useEffect, useState } from "react";
import {
  filterFunctions,
  formatDate,
  isEmpty,
  printLargeValue,
} from "../../../function/Utils";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { UserDto, UserRequestDto, UserStatusType } from "../../../types/user";
import UserManage from "./UserManage";
import { getAuditLogs } from "../../../redux/features/auditLog";
import { DisplayTypeTransaction } from "../../../function/functionUtilis";
import { typeFiltration } from "../../../types/BackOffice";
import { typeOrderOrFiltration } from "../../../types/fonds";
import {
  TransactionDtoWithFonds,
  TypeTransaction,
} from "../../../types/transactions";
import ExportComponent from "../../commun/ExportComponent";
import FilterApparition from "../BackOffice/Components/FilterApparition";
import FiltrationColumnTable, {
  filtrationInterface,
} from "../BackOffice/Components/FiltrationColumnTable";
import { editUser, editUserAdmin } from "../../../redux/features/user";
import { PrimaryButton } from "../../commun/Buttons";
import { useNavigate } from "react-router";
import Loading from "../../commun/Loading";
import {
  useGetUsersRequestQuery,
  useGetUsersSearchQuery,
} from "../../../redux/features/userSlice";
import SearchComponent from "../../commun/SearchComponent";
import { MenuDashBoardAdminBoolState } from "../../Context/AppContext";
import WrapComponentDashboardUser from "../../DashboardUser/commun/WrapComponentDashboardUser";
import {
  BooleanParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from "use-query-params";

const UsersManage = () => {
  const dispatch = useAppDispatch();
  const menuBoolState = useContext(MenuDashBoardAdminBoolState);

  return (
    <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto">
      <WrapComponentDashboardUser
        title="Gestion des utilisateurs"
        description=""
        classNameHeader={`${!menuBoolState?.toggleMenu ? "!pl-[40px]" : ""}`}
        classNameContainer={`${!menuBoolState?.toggleMenu ? "" : ""}`}
      >
        <div className="overflow-auto h-[83vh] md:h-[88vh] relative px-2">
          <UsersTable />
        </div>
      </WrapComponentDashboardUser>
    </div>
  );
};

export default UsersManage;

enum Filter {
  NoFilter = "Pas de filtre",
  checkUserOnboarded = "Validation user en attente",
  compteInvestToValidate = "Validation compte investissement",
  documentToValidate = "Document à valider",
  subscriptionsToValidate = "Subscriptions à valider",
}

function UsersTable() {
  const classNameObejct = {
    head: "text-sm text-gray-900 px-6 py-4 text-left font-semibold text-center",
    item: "text-sm font-light px-6 py-4 whitespace-nowrap  text-right",
  };

  const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
  const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);
  const [filterColumn, setFilterColumn] = useState<
    filtrationInterface<UserRequestDto>[]
  >([
    {
      element: "createdAt",
      type: typeFiltration.date,
      value: "croissant",
      orderOrFiltration: typeOrderOrFiltration.order,
    },
  ]);

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState<string | UserStatusType>("");

  const [filterQueryParam, setFilterQueryParam] = useQueryParam(
    "filterUsers",
    StringParam
  );

  const [statusQueryParam, setStatusQueryParam] = useQueryParam(
    "status",
    StringParam
  );

  useEffect(() => {
    if (statusQueryParam && status === "") {
      if (statusQueryParam === UserStatusType.informationNeedToBeChecked) {
        setCheckUserOnBoarded(true);
      } else {
        setStatus(statusQueryParam);
      }
    }
  }, [statusQueryParam]);

  useEffect(() => {
    if (status !== "") {
      setStatusQueryParam(status);
    }
  }, [status]);

  const {
    data: usersDataWithNbItems,
    isLoading,
    isFetching,
  } = useGetUsersRequestQuery({
    search: search,
    limit: nbItemsShowed,
    offset: startIntervalNbItems,
    status: status,
    compteInvestToValidate:
      filterQueryParam === Filter.compteInvestToValidate ? true : false,
    documentToValidate:
      filterQueryParam === Filter.documentToValidate ? true : false,
    subscriptionToValidate:
      filterQueryParam === Filter.subscriptionsToValidate ? true : false,
  });

  const usersData = usersDataWithNbItems?.rows;
  const nbItems = usersDataWithNbItems?.count;

  const [usersDataFiltered, setUsersDataFiltered] = useState<UserRequestDto[]>(
    usersData ?? []
  );

  const filtrationFunction = () => {
    let transactionDataFiltered_init: UserRequestDto[] = usersData ?? [];
    for (let item of filterColumn) {
      transactionDataFiltered_init = filterFunctions(
        transactionDataFiltered_init,
        item.element,
        item.type,
        item.value
      );
    }
    setUsersDataFiltered(transactionDataFiltered_init);
  };

  useEffect(() => {
    if (usersData) {
      filtrationFunction();
    }
  }, [filterColumn, usersData]);

  const [checkUserOnboarded, setCheckUserOnBoarded] = useState<boolean>(false);

  const handleFillInterestDate = () => {
    if (checkUserOnboarded) {
      setStatus(UserStatusType.informationNeedToBeChecked);
      setFilterColumn([
        {
          element: "createdAt",
          type: typeFiltration.date,
          value: "decroissant",
          orderOrFiltration: typeOrderOrFiltration.order,
        },
        {
          element: "status",
          type: typeFiltration.exact,
          value: UserStatusType.informationNeedToBeChecked,
          orderOrFiltration: typeOrderOrFiltration.filter,
        },
      ]);
    } else {
      setStatus("");
      setStatusQueryParam("");
      setFilterColumn([
        {
          element: "createdAt",
          type: typeFiltration.date,
          value: "decroissant",
          orderOrFiltration: typeOrderOrFiltration.order,
        },
      ]);
    }
  };

  useEffect(() => {
    handleFillInterestDate();
  }, [checkUserOnboarded]);

  const [rangeChosen, setRangeChosen] = useState<number>(1);

  const handleFilter = (value: Filter) => {
    if (value === Filter.checkUserOnboarded) {
      setCheckUserOnBoarded(true);
    } else {
      setCheckUserOnBoarded(false);
    }
    setFilterQueryParam(value);
  };

  const [initValue, setInitValue] = useState<boolean>(false);

  const [selectValue, setSelectValue] = useState<Filter>(Filter.NoFilter);

  // useEffect(() => {
  //   handleFilter(selectValue);
  // }, [selectValue]);

  useEffect(() => {
    if (!initValue && filterQueryParam !== undefined) {
      setSelectValue(filterQueryParam as Filter);
      setInitValue(true);
    }
  }, [filterQueryParam]);

  useEffect(() => {
    handleFilter(selectValue);
  }, [selectValue]);

  return (
    <>
      <div className="w-full mt-10 flex justify-center items-center mb-5">
        <SearchComponent setSearch={setSearch} search={search} />
      </div>

      <>
        <div className="flex justify-end gap-3 my-2 items-center">
          <select
            className=" py-1 px-1 border-2 border-gray-300 rounded-md"
            value={selectValue}
            onChange={(e) => {
              setSelectValue(e.target.value as Filter);
            }}
          >
            {Object.values(Filter).map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
          {/* <PrimaryButton
            onClick={() => setCheckUserOnBoarded(!checkUserOnboarded)}
          >
            {checkUserOnboarded ? "Annuler" : "Validation user en attente"}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => {
              let bool = !compteInvestToValidate;
              setCompteInvestToValidate(bool);
              setCompteInvestToValidateStatusQueryParam(bool);
            }}
          >
            {compteInvestToValidate
              ? "Annuler"
              : "Validation compte investissement"}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => {
              let bool = !documentToValidate;
              setDocumentToValidate(bool);
              setDocumentToValidateQueryParam(bool);
            }}
          >
            {documentToValidate ? "Annuler" : "Document à valider"}
          </PrimaryButton> */}
        </div>
        <FilterApparition
          startIntervalNbItems={startIntervalNbItems}
          setNbItemsShowed={setNbItemsShowed}
          nbItemsShowed={nbItemsShowed}
          setRangeChosenOffset={setRangeChosen}
          rangeChosenOffset={rangeChosen}
          setStartIntervalNbItems={setStartIntervalNbItems}
          array={usersDataFiltered ?? []}
          offset={true}
          nbItems={nbItems ?? 0}
        >
          <div className="relative md:absolute md:top-3 md:right-3">
            <ExportComponent
              // userData={{
              //   name: `${userAdmin?.lastName.toLocaleUpperCase()} ${
              //     userAdmin?.firstName
              //   }`,
              // }}
              headers={["Nom", "Prénom", "Email", "Date", "admin"]}
              title="Liste des utilisateurs"
              data={usersDataFiltered.map((val) => {
                return {
                  Nom: val.lastName,
                  Prénom: val.firstName,
                  Email: val.email,
                  Date: formatDate(new Date(val.createdAt)),
                  admin: val.admin,
                };
              })}
            />
          </div>
        </FilterApparition>
        <div className="flex flex-col max-w-full overflow-x-auto">
          <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
            <div className="py-2 inline-block min-w-full">
              {/* <p>
    Valeur total du fonds{" "}
    {printLargeValue(
      projectValorisationInfoData?.valorisationByProjects?.toFixed(
        2
      )
    )}
  </p> */}
              <div className="overflow-auto">
                <table className="table-auto overflow-scroll w-full">
                  <thead className="bg-white border-b border-t">
                    <tr>
                      <th scope="col" className={classNameObejct.head}>
                        #
                      </th>
                      <th scope="col" className={classNameObejct.head}>
                        Nom
                      </th>
                      <th scope="col" className={classNameObejct.head}>
                        Prénom
                      </th>
                      <FiltrationColumnTable
                        element={"status"}
                        setSearch={setStatus}
                        search={status}
                        type={typeFiltration.exact}
                        setFilterColumn={setFilterColumn}
                        orderOrFiltration={typeOrderOrFiltration.filter}
                        value={[
                          "all",
                          UserStatusType.informationNeedToBeChecked,
                          UserStatusType.profileValidated,
                          UserStatusType.profilUnValitaded,
                        ]}
                        columnName={() => (
                          <p className="inline-block">Statut</p>
                        )}
                        textLeft={false}
                      />

                      <th scope="col" className={classNameObejct.head}>
                        Email
                      </th>

                      <FiltrationColumnTable
                        element={"createdAt"}
                        type={typeFiltration.date}
                        setFilterColumn={setFilterColumn}
                        columnName={() => (
                          <p className="inline-block text-center">Date</p>
                        )}
                        orderOrFiltration={typeOrderOrFiltration.order}
                        textLeft={true}
                      />
                    </tr>
                  </thead>
                  <tbody className="bg-white ">
                    {isLoading || isFetching ? (
                      <tr className="">
                        <td colSpan={7} className="pt-10 text-center mx-auto">
                          <Loading />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {usersDataFiltered && (
                          <>
                            {usersDataFiltered.length === 0 ? (
                              <tr className="w-full mx-auto">
                                <td
                                  colSpan={7}
                                  className="text-center text-gray-500 text-xl  pt-10"
                                >
                                  Aucun utilisateur touvée
                                </td>
                              </tr>
                            ) : (
                              <React.Fragment>
                                {[
                                  ...usersDataFiltered.filter(
                                    (val, index) => val.admin === true
                                  ),
                                  ...usersDataFiltered.filter(
                                    (val, index) => val.admin !== true
                                  ),
                                ].map((user, key) => (
                                  <RowElement
                                    user={user}
                                    key={key}
                                    number={key}
                                    startIntervalNbItems={startIntervalNbItems}
                                  />
                                ))}
                              </React.Fragment>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

// function getQueryParams() {
//   const [statusQueryParam, setStatusQueryParam] = useQueryParam(
//     "status",
//     StringParam
//   );
//   const [
//     compteInvestToValidateQueryParam,
//     setCompteInvestToValidateStatusQueryParam,
//   ] = useQueryParam("compteInvestToValidate", BooleanParam);
//   const [documentToValidateQueryParam, setDocumentToValidateQueryParam] =
//     useQueryParam("documentToValidate", BooleanParam);

//   return `status=${statusQueryParam}&compteInvestToValidate=${compteInvestToValidateQueryParam}&documentToValidateQueryParam=${documentToValidateQueryParam}`;
// }

function RowElement({
  user,
  number,
  startIntervalNbItems,
}: {
  user: UserRequestDto;
  number: number;
  startIntervalNbItems: number;
}) {
  const [editContent, setEditContent] = useState<boolean>(user.admin);
  const [editToggle, setEditToggle] = useState(false);

  const dispatch = useAppDispatch();

  const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await dispatch(editUserAdmin({ ...user, admin: editContent }));
    setEditToggle(!editToggle);
  };
  const classNameObejct = {
    item: "text-sm  font-light px-6 py-4 whitespace-nowrap",
  };

  const navigate = useNavigate();

  const [statusQueryParam, setStatusQueryParam] = useQueryParam(
    "status",
    StringParam
  );

  const [filterQueryParam, setFilterQueryParam] = useQueryParam(
    "filterUsers",
    StringParam
  );

  return (
    <tr
      onClick={() => {
        let string = [];
        if (statusQueryParam !== undefined) {
          string.push(`status=${statusQueryParam}&`);
        }
        string.push(`filterUsers=${filterQueryParam}`);

        let res = string.join("");
        let pageUser = "general";
        if (filterQueryParam === Filter.subscriptionsToValidate) {
          pageUser = "subscriptions";
        }

        navigate(
          `/DashBoardAdmin/Utilisateurs/user/${user.id}/?pageUser=${pageUser}&${res}`
        );
      }}
      className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
        number % 2 === 0 && !user.admin
          ? "bg-secondBackgroundColor"
          : user.admin
          ? "bg-titleColor"
          : "bg-white "
      } `}
    >
      <td className={`${classNameObejct.item}`}>
        {number + 1 + startIntervalNbItems}
      </td>
      <td className={`${classNameObejct.item} text-center`}>{user.lastName}</td>
      <td className={`${classNameObejct.item} text-center`}>
        {user.firstName}
      </td>
      <td className={`${classNameObejct.item} text-center`}>{user.status}</td>
      <td className={`${classNameObejct.item} text-left`}>{user.email}</td>
      <td className={`${classNameObejct.item} text-center`}>
        {!!user.createdAt &&
          (user.createdAt instanceof Date
            ? user.createdAt.toLocaleDateString("en-GB")
            : new Date(user.createdAt).toLocaleDateString("en-GB"))}
      </td>
      {/* <td className={`${classNameObejct.item} text-center`}>
        {editToggle ? (
          <form
            onSubmit={(e) => handleForm(e)}
            className="relative flex flex-col gap-3"
          >
            <div className="">
              <div className="absolute  right-[10%]"></div>
              <select
                value={editContent ? "true" : "false"}
                className="pointer"
                onChange={(e) =>
                  setEditContent(e.target.value === "true" ? true : false)
                }
              >
                <option value={"true"}>true</option>
                <option value={"false"}>false</option>
              </select>
            </div>

            
            <div className="">
              <PrimaryButton type="submit" className="w-fit">
                Modifier
              </PrimaryButton>
            </div>
          </form>
        ) : (
          <div className="relative">
            <div className="absolute  right-0"></div>
            <p onClick={() => setEditToggle(!editToggle)}>
              {user.admin ? "true" : "false"}
            </p>
          </div>
        )}
      </td> */}
    </tr>
  );
}
