import { Chart } from "chart.js";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ComputationUserDashboard } from "../../../function/ComputationUserDashboard";
import {
  compareTwoDate,
  createDateArray,
  debounce,
  isEmpty,
  lastDay,
  lastMonth,
  monthNumberToString,
  setDate,
  startMonth,
} from "../../../function/Utils";
import { useAppSelector } from "../../../redux/store";
import { ShareDto } from "../../../types/Share";
import { MenuDashBoardUserBoolState } from "../../Context/AppContext";
import { useGetFondsQuery } from "../../../redux/features/fondsSlice";
import { useFetchTokenQuery } from "../../../redux/features/tokenSlice";
import { useGetSharesUserQuery } from "../../../redux/features/shareSlice";
import { useGetUserHistoricalValorisationQuery } from "../../../redux/features/shareApiSlice";
import { formatInTimeZone } from "date-fns-tz";

interface Props {
  computationObject: ComputationUserDashboard;
}

const ChartValo: React.FC<Props> = ({ computationObject }) => {
  const pageState = useAppSelector(
    (state) => state.pageMeasurement.measurements
  );

  const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

  const { data: valo, isLoading } = useGetUserHistoricalValorisationQuery(
    { userId: user?.id ?? 0 },
    {
      skip: !user,
    }
  );

  const menuBoolState = useContext(MenuDashBoardUserBoolState);

  const { data: fondsData } = useGetFondsQuery();

  const [datasets, setDatasets] = useState<any>();
  const [labels, setLabels] = useState<any>();

  const updateGraph = useCallback(
    debounce(() => {
      if (fondsData && !isEmpty(valo) && computationObject) {
        setLabels(
          valo?.data.map((v) =>
            formatInTimeZone(new Date(v.date), "Europe/Paris", "MM/yyyy")
          )
        );

        let datasets_ = [
          {
            label: "Votre valorisation",
            data: valo?.data.map((v) => v.value),
            backgroundColor: "rgba(20, 93, 136, 0.2)",
            borderColor: "rgba(20, 93, 136,1)",
          },
        ];

        setDatasets(datasets_);
      }
    }, 100),
    [valo, fondsData, computationObject]
  );

  useEffect(() => {
    if (!isEmpty(valo)) {
      updateGraph();
    }
  }, [valo, fondsData, menuBoolState?.toggleMenu, computationObject]);

  useEffect(() => {
    if (datasets && labels) {
      let hook = document.getElementById("myChart3")! as HTMLCanvasElement;
      let ctx = hook.getContext("2d")!;
      const myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          responsive: true,

          plugins: {
            legend: {
              position: "top",
              labels: {
                font: {
                  size: 20,
                  family: "Spectral",
                },
              },
            },
          },
        },
      });

      return () => {
        myChart.destroy();
      };
    }
  }, [pageState.widthScreen, labels, datasets, menuBoolState?.toggleMenu]);

  return (
    <React.Fragment>
      <canvas
        id="myChart3"
        // style={{ minWidth: "400px", minHeight: "200px" }}
      ></canvas>
    </React.Fragment>
  );
};

export default ChartValo;
