import React, { useEffect, useState } from "react";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import { isEmpty } from "../../../function/Utils";
import { indexBackOfficeInterface } from "../../../pages/DashboardAdmin";
import { tableBackOfficeInterface } from "../../../types/BackOffice";
import {
  RootBlackOfficeState,
  rootStateBlackOfficeInterface,
} from "../../Context/AppContext";

import { useGetFondsQuery } from "../../../redux/features/fondsSlice";
import BackOffice from "./BackOffice";

interface Props {
  idObject: indexBackOfficeInterface;
}
const IndexBackOffice: React.FC<Props> = ({ idObject }) => {
  const [selectTable, setSelectTable] = useState<tableBackOfficeInterface>(
    tableBackOfficeInterface.overview
  );

  let [tableQuery, setTableQuery] = useQueryParam("table", StringParam);

  useEffect(() => {
    if (tableQuery) {
      setSelectTable(tableQuery as tableBackOfficeInterface);
    } else {
      setTableQuery(selectTable as tableBackOfficeInterface);
    }
  }, []);

  const routeFunction = () => {
    if (idObject.idBackOffice) {
      switch (idObject.idBackOffice) {
        case "addProjects":
          return <BackOffice addBool={true} />;
        case "addTransactions":
          return <BackOffice addBool={true} />;

        default:
          break;
      }
    } else if (idObject.idTable) {
      return <BackOffice />;
    } else if (idObject.idProjectBO) {
      return <BackOffice id={parseInt(idObject.idProjectBO)} />;
    } else if (idObject.idTransaction) {
      return <BackOffice id={parseInt(idObject.idTransaction)} />;
    } else {
      return <BackOffice />;
    }
  };
  const [fondsId, setFondsId] = useState<number | undefined>();
  const [applyFeesBool, setApplyFeesBool] = useState<boolean>(true);
  const [dailyShare, setdailyShare] = useState<boolean>(false);
  const { data: fondsData } = useGetFondsQuery();
  let [fondsQuery, setFondsQuery] = useQueryParam("fonds", NumberParam);

  useEffect(() => {
    if (
      !isEmpty(fondsData) &&
      fondsData !== undefined &&
      fondsId === undefined
    ) {
      if (fondsQuery) {
        setFondsId(fondsQuery);
      } else {
        setFondsId(fondsData[0].id!);
        setFondsQuery(fondsData[0].id!);
      }
    }
  }, [fondsData]);

  // useEffect(() => {
  //   if (fondsQuery) setFondsId(fondsQuery);
  // }, [fondsQuery]);

  const fondsObjectState: rootStateBlackOfficeInterface = {
    fondsIdBackOffice: fondsId,
    setFondsIdBackOffice: setFondsId,
    applyFeesBool: applyFeesBool,
    setApplyFeesBool: setApplyFeesBool,
    selectTable: selectTable,
    setSelectTable: setSelectTable,
    dailyShare: dailyShare,
    setdailyShare: setdailyShare,
  };

  return (
    <RootBlackOfficeState.Provider value={fondsObjectState}>
      <React.Fragment>{routeFunction()}</React.Fragment>
    </RootBlackOfficeState.Provider>
  );
};

export default IndexBackOffice;
