import { MutationActionCreatorResult } from '@reduxjs/toolkit/query';
import { hasProperty } from '../../../../function/Utils';
import {
    FormCreateDocumentBeneficialOwnerRequest,
    FormCreateDocumentInvestAccountRequest,
    FormCreateDocumentMoralAccountRequest,
    uploadDocumentBeneficialOwner,
    uploadDocumentInvestAccount,
    uploadDocumentMoralAccount,
} from '../../../../redux/features/userSlice';
import {
    CreateInvestAccountAndUpdateUserInfoDto,
    DocumentMoralAccountType,
    DocumentUserSide,
    FormCreateInvestAccountAndUpdateUserInfoDto,
    FormCreateInvestAccountDto,
    FormUpdateInvestAccountDto,
    FormUpdateInvestAccountInInvestirDto,
    FormUpdateUserInvestAccountDto,
    InvestAccountDto,
    UpdateInvestAccountDto,
} from '../../../../types/user';

export async function onSumbitAddInvestAccount({
    data,
    showError,
    showSuccess,
    userId,
    addInvestAccount,
    refetchUser,
    onSuccess,
}: {
    data:
        | FormCreateInvestAccountAndUpdateUserInfoDto
        | FormCreateInvestAccountDto;
    showError: (
        title: string,
        message: string,
        errorMessage?: string | undefined
    ) => void;
    showSuccess: (title: string, message: string) => void;
    userId: number;
    addInvestAccount: (
        arg: CreateInvestAccountAndUpdateUserInfoDto
    ) => MutationActionCreatorResult<any>;
    refetchUser?: () => any;
    onSuccess?: (props: { investAccount: InvestAccountDto }) => void;
}) {
    let userInfoInvestAccount = undefined;
    let success = true;

    data.iban = data.iban.replace(/\s+/g, '');

    if (hasProperty(data, 'userInfoInvestAccount')) {
        const userInfo =
            data.userInfoInvestAccount as FormUpdateUserInvestAccountDto;
        userInfoInvestAccount = {
            adress: userInfo.adress,
            phone: userInfo.phone,
            professionalSituation: userInfo.professionalSituation,
            politicalyExposed:
                userInfo.politicalyExposed == 'true' ? true : false,
            politicalyExposedDetails: userInfo.politicalyExposedDetails,
            usCitizen: userInfo.usCitizen == 'true' ? true : false,
        };
    }

    if (data.physicalOrMoral === 'physical') delete data.moralAccount;

    await addInvestAccount({
        userId: userId,
        physicalOrMoral: data.physicalOrMoral,
        iban: data.iban,
        bic: data.bic,
        moralAccount: data.moralAccount,
        userInfoInvestAccount: userInfoInvestAccount,
    })
        .unwrap()
        .then(async (res_: any) => {
            const res = res_ as InvestAccountDto;
            // Add all documents
            let file = data.documentRib?.[0];

            const dataUpload: FormCreateDocumentInvestAccountRequest = {
                file: file,
                investAccountId: res.id,
                iban: data.iban,
                bic: data.bic,
                fileName: file.name,
                userId: userId,
            };
            await uploadDocumentInvestAccount(dataUpload)
                .then((res) => {
                    if (res.sucess) {
                    } else {
                        success = false;
                        showError(
                            'Erreur',
                            `Erreur lors de l'envoie de ${dataUpload.fileName}`
                        );
                    }
                })
                .catch((res) => {
                    success = false;
                    showError(
                        'Erreur',
                        `Erreur lors de l'envoie de ${dataUpload.fileName}`
                    );
                });

            if (data.physicalOrMoral === 'moral') {
                file = data.documentKbis?.[0];

                let dataUpload: FormCreateDocumentMoralAccountRequest = {
                    file: file,
                    userId: userId,
                    fileName: file.name,
                    type: DocumentMoralAccountType.KBIS,
                    moralAccountId: res.moralAccount?.id!,
                };
                await uploadDocumentMoralAccount(dataUpload)
                    .then((res) => {
                        if (res.sucess) {
                        } else {
                            success = false;
                            showError(
                                'Erreur',
                                `Erreur lors de l'envoie de ${dataUpload.fileName}`
                            );
                        }
                    })
                    .catch((res) => {
                        success = false;
                        showError(
                            'Erreur',
                            `Erreur lors de l'envoie de ${dataUpload.fileName}`
                        );
                    });

                file = data.documentStatus?.[0];
                dataUpload = {
                    file: file,
                    userId: userId,
                    fileName: file.name,
                    type: DocumentMoralAccountType.STATUS,
                    moralAccountId: res.moralAccount?.id!,
                };
                await uploadDocumentMoralAccount(dataUpload)
                    .then((res) => {
                        if (res.sucess) {
                        } else {
                            success = false;
                            showError(
                                'Erreur',
                                `Erreur lors de l'envoie de ${dataUpload.fileName}`
                            );
                        }
                    })
                    .catch((res) => {
                        success = false;
                        showError(
                            'Erreur',
                            `Erreur lors de l'envoie de ${dataUpload.fileName}`
                        );
                    });

                file = data.documentDeclarationBeneficial_Owner?.[0];
                dataUpload = {
                    file: file,
                    userId: userId,
                    fileName: file.name,
                    type: DocumentMoralAccountType.DECLARATION_BENEFICIAL_OWNER,
                    moralAccountId: res.moralAccount?.id!,
                };
                await uploadDocumentMoralAccount(dataUpload)
                    .then((res) => {
                        if (res.sucess) {
                        } else {
                            success = false;
                            showError(
                                'Erreur',
                                `Erreur lors de l'envoie de ${dataUpload.fileName}`
                            );
                        }
                    })
                    .catch((res) => {
                        success = false;
                        showError(
                            'Erreur',
                            `Erreur lors de l'envoie de ${dataUpload.fileName}`
                        );
                    });

                if (
                    data.moralAccount?.beneficialOwners &&
                    data.moralAccount?.beneficialOwners.length > 0
                ) {
                    for (const beneficial of data.moralAccount
                        ?.beneficialOwners) {
                        file = beneficial.file?.[0];

                        const dataUpload: FormCreateDocumentBeneficialOwnerRequest =
                            {
                                file: file,
                                userId: userId,
                                fileName: file.name,
                                side: DocumentUserSide.BOTH,
                                name: beneficial.name,
                                beneficialOwnerId:
                                    res.moralAccount?.beneficialOwners.find(
                                        (b) =>
                                            b.firstName ===
                                                beneficial.firstName &&
                                            b.lastName ===
                                                beneficial.lastName &&
                                            b.nationality ===
                                                beneficial.nationality
                                    )?.id!,
                            };

                        await uploadDocumentBeneficialOwner(dataUpload)
                            .then((res) => {
                                if (res.sucess) {
                                } else {
                                    success = false;
                                    showError(
                                        'Erreur',
                                        `Erreur lors de l'envoie de ${dataUpload.fileName}`
                                    );
                                }
                            })
                            .catch((res) => {
                                success = false;
                                showError(
                                    'Erreur',
                                    `Erreur lors de l'envoie de ${dataUpload.fileName}`
                                );
                            });
                    }
                }
            }
            showSuccess('Créé', 'Compte investisseur créé avec succès');

            if (refetchUser) await refetchUser();
            if (success && onSuccess) {
                onSuccess({ investAccount: res });
            }
        })
        .catch((err) => {
            console.log(err);
            showError(
                'Erreur',
                `Erreur lors de la création du compte investisseur`,
                err.data?.message
            );
        });
}

export async function onSumbitUpdateInvestAccount({
    data,
    showError,
    showSuccess,
    userId,
    editInvestAccount,
    refetchDocumentInvestAccount,
    refetchInvestAccount,
    isDirty,
    investAccount,
    onSuccess,
}: {
    data: FormUpdateInvestAccountInInvestirDto | FormUpdateInvestAccountDto;
    showError: (
        title: string,
        message: string,
        errorMessage?: string | undefined
    ) => void;
    showSuccess: (title: string, message: string) => void;
    userId: number;
    editInvestAccount: (
        arg: UpdateInvestAccountDto
    ) => MutationActionCreatorResult<any>;
    refetchDocumentInvestAccount: () => any;
    refetchInvestAccount: () => any;
    isDirty: boolean;
    investAccount?: InvestAccountDto;
    onSuccess?: () => void;
}) {
    if (!isDirty) {
        showSuccess('Modifié', 'Compte investisseur modifié avec succès');
        if (onSuccess) {
            onSuccess();
        }
        return;
    }
    let documentEdited = false;
    let userInfoInvestAccount = undefined;

    let docRibEdited = false;
    data.iban = data.iban.replace(/\s+/g, '');

    let success = true;

    if (hasProperty(data, 'userInfoInvestAccount')) {
        const userInfo =
            data.userInfoInvestAccount as FormUpdateUserInvestAccountDto;
        userInfoInvestAccount = {
            adress: userInfo.adress,
            phone: userInfo.phone,
            professionalSituation: userInfo.professionalSituation,
            politicalyExposed:
                userInfo.politicalyExposed == 'true' ? true : false,
            politicalyExposedDetails: userInfo.politicalyExposedDetails,
            usCitizen: userInfo.usCitizen == 'true' ? true : false,
        };
    }

    await editInvestAccount({
        userId: userId,
        id: data.id,
        iban: data.iban || '',
        bic: data.bic || '',
        moralAccount: data.moralAccount,
        userInfoInvestAccount: userInfoInvestAccount,
    })
        .unwrap()
        .then(async (res_: any) => {
            const res = res_ as InvestAccountDto;
            // Add all documents
            if (data.documentRib.length > 0) {
                const file = data.documentRib?.[0];

                const dataUpload: FormCreateDocumentInvestAccountRequest = {
                    file: file,
                    investAccountId: data.id,
                    iban: data.iban,
                    bic: data.bic,
                    fileName: file.name,
                    userId: userId,
                };
                await uploadDocumentInvestAccount(dataUpload)
                    .then((res) => {
                        if (res.sucess) {
                            documentEdited = true;
                            docRibEdited = true;
                        } else {
                            showError(
                                'Erreur',
                                `Erreur lors de l'envoie de ${dataUpload.fileName}`
                            );
                            success = false;
                        }
                    })
                    .catch((res) => {
                        success = false;
                        showError(
                            'Erreur',
                            `Erreur lors de l'envoie de ${dataUpload.fileName}`
                        );
                    });
            }

            if (investAccount && investAccount.physicalOrMoral === 'moral') {
                if (data.documentKbis.length > 0) {
                    const file = data.documentKbis?.[0];

                    const dataUpload: FormCreateDocumentMoralAccountRequest = {
                        file: file,
                        userId: userId,
                        fileName: file.name,
                        type: DocumentMoralAccountType.KBIS,
                        moralAccountId: investAccount.moralAccount?.id!,
                    };
                    await uploadDocumentMoralAccount(dataUpload)
                        .then((res) => {
                            if (res.sucess) {
                                documentEdited = true;
                            } else {
                                success = false;
                                showError(
                                    'Erreur',
                                    `Erreur lors de l'envoie de ${dataUpload.fileName}`
                                );
                            }
                        })
                        .catch((res) => {
                            success = false;
                            showError(
                                'Erreur',
                                `Erreur lors de l'envoie de ${dataUpload.fileName}`
                            );
                        });
                }

                if (data.documentStatus.length > 0) {
                    const file = data.documentStatus?.[0];
                    const dataUpload = {
                        file: file,
                        userId: userId,
                        fileName: file.name,
                        type: DocumentMoralAccountType.STATUS,
                        moralAccountId: investAccount.moralAccount?.id!,
                    };
                    await uploadDocumentMoralAccount(dataUpload)
                        .then((res) => {
                            if (res.sucess) {
                                documentEdited = true;
                            } else {
                                success = false;
                                showError(
                                    'Erreur',
                                    `Erreur lors de l'envoie de ${dataUpload.fileName}`
                                );
                            }
                        })
                        .catch((res) => {
                            success = false;
                            showError(
                                'Erreur',
                                `Erreur lors de l'envoie de ${dataUpload.fileName}`
                            );
                        });
                }

                if (data.documentDeclarationBeneficial_Owner.length > 0) {
                    const file = data.documentDeclarationBeneficial_Owner?.[0];
                    const dataUpload = {
                        file: file,
                        userId: userId,
                        fileName: file.name,
                        type: DocumentMoralAccountType.DECLARATION_BENEFICIAL_OWNER,
                        moralAccountId: investAccount.moralAccount?.id!,
                    };
                    await uploadDocumentMoralAccount(dataUpload)
                        .then((res) => {
                            if (res.sucess) {
                                documentEdited = true;
                            } else {
                                success = false;
                                showError(
                                    'Erreur',
                                    `Erreur lors de l'envoie de ${dataUpload.fileName}`
                                );
                            }
                        })
                        .catch((res) => {
                            success = false;
                            showError(
                                'Erreur',
                                `Erreur lors de l'envoie de ${dataUpload.fileName}`
                            );
                        });
                }

                if (
                    data.moralAccount?.beneficialOwners &&
                    data.moralAccount?.beneficialOwners.length > 0
                ) {
                    for (const beneficial of data.moralAccount
                        ?.beneficialOwners) {
                        if (beneficial.file?.length === 0) continue;
                        const file = beneficial.file?.[0];

                        let beneficialOwnerId = 0;
                        if (beneficial.id) {
                            beneficialOwnerId = beneficial.id;
                        } else {
                            beneficialOwnerId =
                                res.moralAccount?.beneficialOwners.find(
                                    (b) =>
                                        b.firstName === beneficial.firstName &&
                                        b.lastName === beneficial.lastName &&
                                        b.nationality ===
                                            beneficial.nationality &&
                                        beneficial.id === undefined
                                )?.id!;
                        }

                        const dataUpload: FormCreateDocumentBeneficialOwnerRequest =
                            {
                                file: file,
                                userId: userId,
                                side: DocumentUserSide.BOTH,
                                fileName: file.name,
                                name: beneficial.name,
                                beneficialOwnerId: beneficialOwnerId,
                            };

                        await uploadDocumentBeneficialOwner(dataUpload)
                            .then((res) => {
                                if (res.sucess) {
                                    documentEdited = true;
                                } else {
                                    success = false;
                                    showError(
                                        'Erreur',
                                        `Erreur lors de l'envoie de ${dataUpload.fileName}`
                                    );
                                }
                            })
                            .catch((res) => {
                                success = false;
                                showError(
                                    'Erreur',
                                    `Erreur lors de l'envoie de ${dataUpload.fileName}`
                                );
                            });
                    }
                }
            }
            if (isDirty) {
                showSuccess(
                    'Modifié',
                    'Compte investisseur modifié avec succès'
                );
            }
            if (documentEdited === true) {
                await refetchDocumentInvestAccount();
            } else if (docRibEdited) {
                await refetchInvestAccount();
            }
            if (success && onSuccess) {
                onSuccess();
            }
        })
        .catch((err) => {
            showError(
                'Erreur',
                `Erreur lors de l'édition du compte investisseur`,
                err.data?.message
            );
        });
}
