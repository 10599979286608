import React, { useEffect, useState } from "react";
import { isEmpty } from "../../../function/Utils";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { UserStatusType } from "../../../types/user";
import FormulaireInvestisseur from "./FormInvest/FormulaireInvestisseur";
import MessageFinFormulaire from "./MessageFinFormulaire";
import DonneesPerso from "./DonneesPerso/DonneesPerso";
import { getUserWithAllInfo } from "../../../redux/features/user";
import { useGetUserWithAllInfoQuery } from "../../../redux/features/userSlice";
import { useFetchTokenQuery } from "../../../redux/features/tokenSlice";
import ValidationUser from "./ValidationUser";
import InformationNeedTobeChecked from "./InformationNeedTobeChecked";

const Profil = () => {
  const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

  const dispatch = useAppDispatch();

  const activeCatFunction = () => {
    if (!isEmpty(user)) {
      switch (user!.status) {
        case UserStatusType.newUser:
          return <FormulaireInvestisseur />;
        case UserStatusType.informationNeedToBeChecked:
          return <FormulaireInvestisseur />;
        case UserStatusType.profileValidated:
          // return <ValidationUser />;
          // return <FormulaireInvestisseur />;

          return <DonneesPerso />;
        // return <ValidationUser />;

        case UserStatusType.profilUnValitaded:
          return <ValidationUser />;
      }
    }
  };

  return <React.Fragment>{activeCatFunction()}</React.Fragment>;
};

export default Profil;
