import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { CreateTransactionDto, DocumentTransactionDto, TransactionDto, TransactionDtoWithFonds, TypeDocumentTransaction, UpdateTransactionDto } from "../../types/transactions";


interface transactionState {
    transactions: TransactionDto[];
    transactionsUser: TransactionDtoWithFonds[];
    transactionDocument: DocumentTransactionDto[];
    loading: boolean;
    loadingDocument: boolean;
    errors: any;
}

const initialState: transactionState = {
    transactions: [],
    transactionDocument: [],
    transactionsUser: [],
    loading: false,
    errors: null,
    loadingDocument: false,
};

// actions are processes that get data from backend
export const getTransactions = createAsyncThunk<
    TransactionDto[]
>("transactions/getTransactions", async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}transactions`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

interface Arg {
    userId: number;
    date: Date;
}

export const getTransactionsUser = createAsyncThunk<
    TransactionDtoWithFonds[], Arg
>("transactions/getTransactionsUser", async (arg, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}transactions/user/${arg.userId}`,
            withCredentials: true,
            params: {
                date: arg.date
            }
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});




export const addTransaction = createAsyncThunk<
    TransactionDto,
    CreateTransactionDto
>("transactions/addTransaction", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "post",
            url: `${import.meta.env.VITE_API_URL}transactions`,
            data: data,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const editTransaction = createAsyncThunk<
    TransactionDto,
    UpdateTransactionDto
>("transactions/editTransaction", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "put",
            url: `${import.meta.env.VITE_API_URL}transactions/${data.id}`,
            data: { ...data },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});


export const deleteTransaction = createAsyncThunk<number, number>(
    "transactions/deleteTransaction",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}transactions/${id}`,
                withCredentials: true,
            });
            return id;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

// get doucments
export const getTransactionDocuments = createAsyncThunk<DocumentTransactionDto[], number>(
    "transactions/getTransactionDocuments",
    async (id, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}transactions/documents/${id}`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export interface UploadDocumentTransactionDto {
    transactionId: number;
    file: File;
    // type: TypeDocumentTransaction;
    fileName: string;
    comment?: string;
}

export const uploadDocumentTransaction = createAsyncThunk<DocumentTransactionDto, UploadDocumentTransactionDto>(
    "transactions/uploadDocumentTransaction",
    async (data, thunkAPI) => {
        try {
            const urlSigned = await axios({
                method: "GET",
                url: `${import.meta.env.VITE_API_URL}transactions/documents/url/put`,
                params: {
                    fileType: encodeURIComponent(data.file.type),
                    transactionId: data.transactionId
                },
                withCredentials: true,
            });

            const { uploadUrl, key } = urlSigned.data as unknown as {
                uploadUrl: string;
                key: string;
            };
            const res = await axios.put(uploadUrl, data.file);
            const response = await axios({
                method: "post",
                url: `${import.meta.env.VITE_API_URL}transactions/documents/post`,
                data: {
                    transactionId: data.transactionId,
                    fileName: data.fileName,
                    // type: data.type,
                    comment: data.comment,
                    key: key,
                },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const deleteDocumentTransaction = createAsyncThunk<number, number>(
    "transactions/deleteDocumentTransaction",
    async (id, thunkAPI) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}transactions/documents/${id}`,
                withCredentials: true,
            })
            return id;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)


// reducers -> reduce to a specific state -> changes state

export const TransactionSlice = createSlice({
    name: "transactions",
    initialState,
    reducers: {
        setTransactions: (
            state,
            action: PayloadAction<TransactionDto[]>
        ) => {
            state.transactions = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTransactionDocuments.pending, (state) => {
            state.loadingDocument = true;
        });
        builder.addCase(getTransactionDocuments.fulfilled, (state, action) => {
            state.transactionDocument = action.payload;
            state.loadingDocument = false;
        });
        builder.addCase(getTransactionDocuments.rejected, (state, action) => {
            state.loadingDocument = false;
            state.errors = action.error;
        });
        builder.addCase(getTransactions.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTransactions.fulfilled, (state, action) => {
            state.transactions = action.payload;
            state.loading = false;
        });
        builder.addCase(getTransactions.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(getTransactionsUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTransactionsUser.fulfilled, (state, action) => {
            state.transactionsUser = action.payload;
            state.loading = false;
        });
        builder.addCase(getTransactionsUser.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addTransaction.fulfilled, (state, action) => {
            state.transactions = [...state.transactions, action.payload];
            state.loading = false;
        });
        builder.addCase(addTransaction.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addTransaction.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editTransaction.fulfilled, (state, action) => {
            state.loading = false;
            state.transactions = state.transactions.map((transactions) => {
                if (transactions.id === action.payload.id) {
                    return {
                        ...transactions,
                        ...action.payload,
                    };
                } else return transactions;
            });
        });
        builder.addCase(editTransaction.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editTransaction.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteTransaction.fulfilled, (state, action) => {
            state.loading = false;
            state.transactions = state.transactions.filter(
                (transaction) => transaction.id !== action.payload
            );
        });
        builder.addCase(deleteTransaction.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteTransaction.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteDocumentTransaction.fulfilled, (state, action) => {
            state.loadingDocument = false;
            state.transactionDocument = state.transactionDocument.filter(
                (doc) => doc.id !== action.payload
            );
        });
        builder.addCase(deleteDocumentTransaction.pending, (state) => {
            state.loadingDocument = true;
        });
        builder.addCase(deleteDocumentTransaction.rejected, (state, action) => {
            state.loadingDocument = false;
            state.errors = action.error;
        });
    },
});

export default TransactionSlice.reducer;
export const { setTransactions } = TransactionSlice.actions;
