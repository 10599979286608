import React, { useEffect } from "react";
import Avertissementcomponent from "../components/commun/Avertissementcomponent";
import ContactSection from "../components/commun/ContactSection";
import FirstFondPage from "../components/commun/FirstFondPage";
import Footer from "../components/commun/Footer";
import Navigation from "../components/commun/Navigation";
import VueEnsPresentationFonds from "../components/VueEnsemble/VueEnsPresentationFonds";
import VueEnsTextePresentation from "../components/VueEnsemble/VueEnsTextePresentation";
import { pageHeader } from "../types/commun";
import coverBackground from "./../assets/presentation_home.jpg";

function VueEnsemble() {
  const Vue_ensembleList: pageHeader = {
    coverBackground: coverBackground,
    coverImg: "./img/presentation_home.jpg",
    classN: "VueEnsemble_Page",
    h1_: "Nos Fonds",
  };

  return (
    <React.Fragment>
      <Navigation bol_nosfonds={true} bol_transparent={true} id_color="p0ID" />
      <FirstFondPage pageHeader={Vue_ensembleList} />
      <VueEnsTextePresentation />
      <VueEnsPresentationFonds />
      <Avertissementcomponent />
      <ContactSection />
      <Footer />
    </React.Fragment>
  );
}

export default VueEnsemble;
