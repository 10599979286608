import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ArticleElementTitre from "./ArticleElementTitre";
import ArticleElementParagraph from "./ArticleElementParagraph";
import pictureSVG from "../../assets/picture.svg";
import pdfSVG from "../../assets/download-solid.svg";
import CategorieElement from "./CategorieElement";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  Article,
  CategoryArticleInterface,
  ParagraphArticleInterface,
  paragraphArticleType,
  UploadFiles,
} from "../../types/Articles";
import {
  addParagraphArticle,
  getParagraphArticles,
  uploadAddParagraphArticle,
} from "../../redux/features/paragraphArticle";
import { addCategoryArticle } from "../../redux/features/categoryArticle";
import { editArticle, getArticles } from "../../redux/features/article";
import { isEmpty } from "../../function/Utils";

interface Props {
  id: number;
}

const ArticleElement: React.FC<Props> = ({ id }) => {
  const articlesData = [
    ...useAppSelector((state) => state.articleReducer.articles),
  ];
  const categorieData = useAppSelector(
    (state) => state.categoryArticleReducer.categories
  );
  const paragraphsData = [
    ...useAppSelector((state) => state.paragraphArticleReducer.paragraphs),
  ];
  const userData = useAppSelector((state) => state.userReducer.user);
  const pageData = useAppSelector(
    (state) => state.pageMeasurement.measurements
  );

  const [categories, setCategories] = useState<
    CategoryArticleInterface[] | null
  >(null);
  const [article, setArticle] = useState<Article | null>(null);

  const [order, setOrder] = useState<number>(0);
  const [content, setContent] = useState<string>("");
  const [type, setType] = useState<paragraphArticleType>(
    paragraphArticleType.p
  );
  const [file, setFile] = useState<File | null>(null);
  const [filePdf, setFilePdf] = useState<File | null>(null);
  const [ajoutCategorieType, setAjoutCategorieType] = useState<string>("");
  const [ajoutCategorieContent, setAjoutCategorieContent] =
    useState<string>("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userData?.admin) choixType(paragraphArticleType.p);
  }, [id, userData]);

  const choixType = (id: paragraphArticleType) => {
    setType(id);
    let p = document.getElementById(id) as HTMLElement;
    let ps = document
      .querySelector(".ajout_paragraph")
      ?.querySelector(".type")
      ?.querySelectorAll("p");
    if (ps) {
      for (let val of ps) {
        // val.style.fontWeight = "300";
        val.style.backgroundColor = "rgb(241, 240, 239)";
        val.style.color = "rgb(20, 93, 136)";
      }
    }
    // p.style.fontWeight = "600";
    p.style.backgroundColor = "green";
    p.style.color = "white";
  };

  useEffect(() => {
    setOrder(0);
  }, [id]);

  const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (type === "img" && file) {
      const data = new FormData();

      data.append("type", type);
      data.append("order", (order + 1).toString());
      data.append("ArticleId", id.toString());
      data.append("file", file);

      let ObjectUploadFile: UploadFiles = {
        data: data,
        type: "img",
      };

      await dispatch(uploadAddParagraphArticle(ObjectUploadFile));
      dispatch(getParagraphArticles());
    } else if (type === "pdf" && content && filePdf) {
      const data = new FormData();

      data.append("type", type);
      data.append("order", (order + 1).toString());
      data.append("ArticleId", id.toString());
      data.append("text", content);
      data.append("file", filePdf);
      let ObjectUploadFile: UploadFiles = {
        data: data,
        type: "pdf",
      };

      await dispatch(uploadAddParagraphArticle(ObjectUploadFile));
      dispatch(getParagraphArticles());
    } else if (content) {
      let data: ParagraphArticleInterface = {
        order: order + 1,
        type: type,
        text: content,
        ArticleId: id,
      };

      await dispatch(addParagraphArticle(data));
      dispatch(getParagraphArticles());
    }

    setContent("");
  };

  const handleFormCategorie = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (ajoutCategorieContent && ajoutCategorieType) {
      let data: CategoryArticleInterface = {
        content: ajoutCategorieContent,
        type: ajoutCategorieType,
        ArticleId: id,
      };
      setAjoutCategorieContent("");
      setAjoutCategorieType("");
      dispatch(addCategoryArticle(data));
    }
  };

  useEffect(() => {
    let article_ = articlesData?.filter((article) => article.id == id);
    setArticle(article_[0]);
  }, [articlesData, id]);

  useEffect(() => {
    let categorie_ = categorieData?.filter(
      (categorie) => categorie.ArticleId == id
    );
    setCategories(categorie_);
  }, [categorieData, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const categoriesFunction = () => {
    return (
      <React.Fragment>
        <div className="list_of_element_container">
          {categories?.map(
            (categorie, key) => (
              <React.Fragment key={key}>
                <CategorieElement categorie={categorie} />
              </React.Fragment>
            )
            // categorieFunction(categorie, key)
          )}
        </div>
        {!!userData?.admin && (
          <form onSubmit={(e) => handleFormCategorie(e)}>
            <input
              type="text"
              name="type"
              placeholder="type"
              value={ajoutCategorieType}
              className="input"
              onChange={(e) => setAjoutCategorieType(e.target.value)}
            />
            <input
              type="text"
              name="content"
              className="input"
              placeholder="content"
              value={ajoutCategorieContent}
              onChange={(e) => setAjoutCategorieContent(e.target.value)}
            />
            <input type="submit" value="Ajouter" className="pointer submit" />
          </form>
        )}
      </React.Fragment>
    );
  };

  const showArticle = () => {
    if (!article?.show) {
      return (
        <p
          className="pointer"
          style={{ backgroundColor: "red" }}
          onClick={() => {
            changeShowArticle(true);
          }}
        >
          Offline
        </p>
      );
    } else {
      return (
        <p
          className="pointer"
          style={{ backgroundColor: "green" }}
          onClick={() => {
            changeShowArticle(false);
          }}
        >
          Online
        </p>
      );
    }
  };

  const changeShowArticle = async (bool: boolean) => {
    const data = {
      ...articlesData.filter((article) => article.id === id)[0],
      show: bool,
      id: id,
    };

    await dispatch(editArticle(data));
    dispatch(getArticles());
  };

  const affichageArticles = (id: number, titre: string, show: boolean) => {
    if (userData?.admin) {
      return (
        <p>
          <Link to={`/Articles/${id}`}>{titre}</Link>
        </p>
      );
    } else {
      if (show) {
        return (
          <p>
            <Link to={`/Articles/${id}`}>{titre}</Link>
          </p>
        );
      } else {
        return null;
      }
    }
  };

  useEffect(() => {
    let elements = document.querySelectorAll(".element_modifiable");
    for (let element of elements) {
      if (userData?.admin) {
        element.classList.add("pointer");
      } else {
        element.classList.remove("pointer");
      }
    }
  }, [userData, paragraphsData, articlesData]);

  return (
    <div className="articleElement_container">
      {!!userData?.admin && (
        <div className="show_container">
          {!isEmpty(article) && showArticle()}
        </div>
      )}

      <div className="categories_container">
        {/* <h2>Liste des catégories</h2> */}
        {categoriesFunction()}
      </div>

      {article && <ArticleElementTitre article={article} />}
      {pageData.widthScreen > 800 && (
        <div className="list_articles">
          <Link to="/articles">Retour à la liste des articles</Link>
          <h3>Voici d'autres articles disponibles</h3>
          {!isEmpty(articlesData) &&
            articlesData
              .filter((val) => val.id != id)
              .sort((a, b) => a.order - b.order)
              .map(({ title, id, show }, key) => (
                <React.Fragment key={key}>
                  {id && affichageArticles(id, title, show)}
                </React.Fragment>
              ))}
        </div>
      )}
      <div className="article_text_containter">
        {/* Il faut map sur tous les paragraphs  */}
        {!isEmpty(paragraphsData) &&
          paragraphsData
            .filter((paragraph) => paragraph.ArticleId == id)
            .sort((a, b) => a.order - b.order)
            .map((paragraph, key) => (
              <ArticleElementParagraph
                paragraph={paragraph}
                order={order}
                setOrder={setOrder}
                id={id}
                key={key}
              />
            ))}
        {!!userData?.admin && (
          <form className="ajout_paragraph" onSubmit={(e) => handleForm(e)}>
            <div className="type">
              <p
                id="p"
                className="pointer"
                onClick={() => choixType(paragraphArticleType.p)}
              >
                Paragraphe
              </p>
              <p
                id="h3"
                className="pointer"
                onClick={() => choixType(paragraphArticleType.h3)}
              >
                Titre
              </p>
              <p
                id="list"
                className="pointer"
                onClick={() => choixType(paragraphArticleType.list)}
              >
                List
              </p>
              <p
                id="img"
                className="pointer img"
                onClick={() => choixType(paragraphArticleType.img)}
              >
                <img src={pictureSVG} alt="img" />
                <input
                  type="file"
                  id="file-upload"
                  name="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => {
                    if (e.target.files) {
                      choixType(paragraphArticleType.img);
                      setFile(e.target.files[0]);
                    }
                  }}
                />
              </p>
              <p
                id="pdf"
                className="pointer pdf"
                onClick={() => choixType(paragraphArticleType.pdf)}
              >
                <img src={pdfSVG} alt="pdf" />
                <input
                  type="file"
                  id="file-upload-pdf"
                  name="file"
                  accept=".pdf"
                  onChange={(e) => {
                    if (e.target.files) {
                      choixType(paragraphArticleType.pdf);
                      setFilePdf(e.target.files[0]);
                    }
                  }}
                />
              </p>
            </div>
            <textarea
              placeholder="Un nouveau paragraph, titre photo ?"
              value={content}
              onChange={(e) => {
                setContent(e.target.value);
              }}
              rows={6}
            ></textarea>
            <br />
            <input type="submit" value="Ajouter" className="pointer" />
          </form>
        )}
      </div>

      {pageData.widthScreen < 800 && (
        <div className="list_articles">
          <Link to="/articles">Retour à la liste des articles</Link>

          <h3>Voici d'autres articles disponibles</h3>
          {!isEmpty(articlesData) &&
            articlesData
              .filter((val) => val.id != id)
              .sort((a, b) => a.order - b.order)
              .map(({ title, id, show }, key) => (
                <React.Fragment key={key}>
                  {id && affichageArticles(id, title, show)}
                </React.Fragment>
              ))}
        </div>
      )}
    </div>
  );
};

export default ArticleElement;
