import React from "react";
import { ApexOptions } from "apexcharts";
import ApexCharts from "react-apexcharts";
import {
  addDays,
  formatDateMonthYear,
  lastMonth,
  printLargeValue,
  transformDate,
} from "../../../function/Utils";
import HistoricalPerf from "./HistoricalPerf";
import Loading from "../../commun/Loading";
import { ISeries } from "../../commun/Charts/AreaChart";
import { useGetPerformancePerFondsQuery } from "../../../redux/features/fondsSlice";

export const downloadIcon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
</svg>`;

const PerformanceFonds = ({ fondsId }: { fondsId: number }) => {
  const { data: performanceFonds, isLoading: isLoadingPerf } =
    useGetPerformancePerFondsQuery(fondsId);

  const series: ISeries[] = [
    {
      name: "Part €",
      data: [
        {
          x: lastMonth(
            new Date(performanceFonds?.monthlyValues[0]?.date!)
          ).getTime(),
          y: 100,
        },
        ...(performanceFonds?.monthlyValues?.map((p) => {
          return {
            x: new Date(p.date).getTime(),
            y: p.share,
          };
        }) || []),
      ],
    },
    // {
    //   name: "Performance mensuelle (annualisée) %",
    //   data:
    //     performanceFonds?.map((p) => {
    //       return {
    //         x: new Date(p.date).getTime(),
    //         y: p.monthlyPerf * 12 * 100,
    //       };
    //     }) || [],
    // },
    // {
    //   name: "Valorisation du fonds €",
    //   data:
    //     performanceFonds?.map((p) => {
    //       return {
    //         x: new Date(p.date).getTime(),
    //         y: p.valorisation,
    //       };
    //     }) || [],
    // },
  ];

  const toolbar = {
    show: true,
    offsetX: 0,
    offsetY: 0,
    tools: {
      download: downloadIcon,
      selection: true,
      zoom: true,
      zoomin: true,
      zoomout: true,
      pan: true,
      reset: true,
      customIcons: [],
    },
    // export: {
    //   // csv: {
    //   //   filename: undefined,
    //   //   columnDelimiter: ",",
    //   //   headerCategory: "category",
    //   //   headerValue: "value",
    //   //   dateFormatter(timestamp) {
    //   //     return new Date(timestamp).toDateString();
    //   //   },
    //   // },
    //   // svg: {
    //   //   filename: undefined,
    //   // },
    //   // png: {
    //   //   filename: undefined,
    //   // },
    // },
    // autoSelected: "zoom",
  };

  let yAxes: any[] = [];
  series?.forEach((item, k) => {
    yAxes.push({
      title: {
        text: item.name,
        style: {
          fontSize: "20px",
        },
      },
      opposite: k % 2 === 1,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        // color: "#008FFB",
      },
      decimalsInFloat: 2,
      labels: {
        style: {
          fontSize: "16px",
        },
        formatter: (value: number) => {
          return printLargeValue(value.toFixed(2));
        },
      },
    });
  });

  const options: ApexOptions = {
    chart: {
      id: "chart1",
      type: "line",
      // height: 350,
      animations: {
        enabled: false,
      },
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "pan",
        show: false,
        tools: {
          download: downloadIcon,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 3, // Size of the dots
      strokeWidth: 2, // Width of the connecting lines
      strokeOpacity: 0.9,
      strokeColors: "#fff",
      colors: undefined, // Color of the dots
      hover: {
        size: 7,
      },
    },

    yaxis: yAxes,
    // yaxis: {
    //   tickAmount: 6,
    //   decimalsInFloat: 4,
    // },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          fontSize: "16px",
        },
      },
      // categories: series.data.map((d) => d.x),

      // min: new Date("01 Mar 2012").getTime(),
      // tickAmount: 6,
    },
    // tooltip: {
    //     x: {
    //         format: "dd MMM yyyy HH:mm",
    //     },
    //     y: yAxesTooltip,
    // },
    stroke: {
      width: 2,
      curve: "straight",
      lineCap: "butt",
    },
  };

  // let min = Math.max(0, series[0]?.data?.length - 1 - 12);
  let min = 0;

  const optionsLine: ApexOptions = {
    chart: {
      id: "chart2",
      height: 130,
      type: "area",
      brush: {
        target: "chart1",
        enabled: true,
      },
      selection: {
        enabled: true,
        xaxis: {
          min: new Date(series[0].data[min]?.x).getTime(),
          max: new Date(series[0].data[series[0].data.length - 1]?.x).getTime(),
        },
      },
    },
    colors: ["#008FFB"],
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1,
      },
    },
    xaxis: {
      type: "datetime",

      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: 2,
      decimalsInFloat: 2,
      labels: {
        style: {
          fontSize: "30px",
        },
      },
    },
  };

  return (
    <div>
      {isLoadingPerf ? (
        <div className=" w-full flex items-center justify-center h-[60vh]">
          <Loading />
        </div>
      ) : (
        <>
          {series?.length > 0 && options ? (
            <div className="w-full md:w-10/12 mx-auto px-2">
              <ApexCharts
                type="line"
                series={series as any}
                options={options}
                height="300"
              />
              {/* <ApexCharts
                type="area"
                series={series as any}
                options={optionsLine}
                height="130"
              /> */}
            </div>
          ) : null}
          <HistoricalPerf fondsId={fondsId} />
        </>
      )}
    </div>
  );
};

export default PerformanceFonds;
