import { CategoryQuestion } from '../../../../types/KnowledgeDto';
import DisplayCategoriesQuestion, {
    CategoryQuestionConfig,
} from './CommunComponent/DisplayCategoriesQuestion';

const FormulaireEtapeInvestment = () => {
    const categories: CategoryQuestionConfig[] = [
        {
            category: CategoryQuestion.target_investissments,
            showGoogdAnswer: false,
        },
        {
            category: CategoryQuestion.experience_investissments,
            showGoogdAnswer: false,
        },
    ];

    return (
        <DisplayCategoriesQuestion
            categories={categories}
            stepQuestionName="stepQuestionFormProfil"
        />
    );
};

export default FormulaireEtapeInvestment;
