import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosPromise, AxiosResponse } from "axios";
import { ParagraphArticleInterface, ParagraphDelete, UploadFiles } from "../../types/Articles";

interface acticleState {
  paragraphs: ParagraphArticleInterface[];
  loading: boolean;
  errors: any;
}

const initialState: acticleState = {
  paragraphs: [],
  loading: false,
  errors: null,
};

// actions are processes that get data from backend
export const getParagraphArticles = createAsyncThunk<
  ParagraphArticleInterface[]
>("paragraphArticles/getParagraphArticles", async (_, thunkAPI) => {
  try {
    const response = await axios({
      method: "get",
      url: `${import.meta.env.VITE_API_URL}paragraphs`,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const addParagraphArticle = createAsyncThunk<
  ParagraphArticleInterface,
  ParagraphArticleInterface
>("paragraphArticles/addParagraphArticle", async (data, thunkApi) => {
  try {
    const response: AxiosResponse<any, any> = await axios({
      method: "post",
      url: `${import.meta.env.VITE_API_URL}paragraphs`,
      data: data,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const editParagraphArticle = createAsyncThunk<
  ParagraphArticleInterface,
  ParagraphArticleInterface
>("paragraphArticles/editArticle", async (data, thunkApi) => {
  try {
    const response = await axios({
      method: "put",
      url: `${import.meta.env.VITE_API_URL}paragraphs/${data.id}`,
      data: { ...data },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});



export const deleteParagraphArticle = createAsyncThunk<ParagraphDelete, ParagraphDelete>(
  "paragraphArticles/deleteParagraphArticle",
  async (data: ParagraphDelete, thunkApi) => {
    try {
      const response = await axios({
        method: "delete",
        url: `${import.meta.env.VITE_API_URL}paragraphs/${data.id}`,
        data: data.body,
        withCredentials: true,
      });
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);



export const uploadEditParagraphArticle = createAsyncThunk<
  UploadFiles,
  UploadFiles
>("paragraphArticles/uploadEditParagraphArticle", async (objectUploadFiles, thunkApi) => {
  try {
    const response = await axios({
      method: "post",
      url: `${import.meta.env.VITE_API_URL}paragraphs/upload/${objectUploadFiles.type}/edit`,
      data: objectUploadFiles.data,
      headers: { "Content-Type": 'multipart/form-data' },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const uploadAddParagraphArticle = createAsyncThunk<UploadFiles, UploadFiles>(
  "paragraphArticles/uploadAddParagraphArticle",
  async (objectUploadFiles, thunkApi) => {
    try {
      const response: AxiosResponse<any, any> = await axios({
        method: "post",
        url: `${import.meta.env.VITE_API_URL}paragraphs/upload/${objectUploadFiles.type}/add`,
        data: objectUploadFiles.data,
        headers: { "Content-Type": 'multipart/form-data' },
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// reducers -> reduce to a specific state -> changes state

export const paragraphArticleSlice = createSlice({
  name: "paragraphArticles",
  initialState,
  reducers: {
    setParagraphArticles: (
      state,
      action: PayloadAction<ParagraphArticleInterface[]>
    ) => {
      state.paragraphs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getParagraphArticles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getParagraphArticles.fulfilled, (state, action) => {
      state.paragraphs = action.payload;
      state.loading = false;
    });
    builder.addCase(getParagraphArticles.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
    });
    builder.addCase(addParagraphArticle.fulfilled, (state, action) => {
      state.paragraphs = [...state.paragraphs, action.payload];
      state.loading = false;
    });
    builder.addCase(addParagraphArticle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addParagraphArticle.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
    });
    builder.addCase(editParagraphArticle.fulfilled, (state, action) => {
      state.loading = false;
      state.paragraphs = state.paragraphs.map((paragraph) => {
        if (paragraph.id === action.payload.id) {
          return {
            ...paragraph,
            text: action.payload?.text,
            type: action.payload?.type,
            path: action.payload?.path,
          };
        } else return paragraph;
      });
    });
    builder.addCase(editParagraphArticle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editParagraphArticle.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
    });
    builder.addCase(deleteParagraphArticle.fulfilled, (state, action) => {
      state.loading = false;
      state.paragraphs = state.paragraphs.filter(
        (paragraph) => paragraph.id !== action.payload.id
      );
    });
    builder.addCase(deleteParagraphArticle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteParagraphArticle.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
    });
    builder.addCase(uploadEditParagraphArticle.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
    });
    builder.addCase(uploadEditParagraphArticle.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(uploadAddParagraphArticle.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
    });
    builder.addCase(uploadAddParagraphArticle.fulfilled, (state, action) => {
      state.loading = false;
    });
  },
});

export default paragraphArticleSlice.reducer;
export const { setParagraphArticles } = paragraphArticleSlice.actions;
