import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NumberParam, useQueryParam } from 'use-query-params';
import {
    useAddParentCompanyMutation,
    useDeleteParentCompanyMutation,
    useGetParentCompanyByFilterQuery,
    useGetParentCompanyByIdQuery,
    useGetShareholdersQuery,
    useUpdateParentCompanyMutation,
} from '../../../../redux/features/projectSlice';
import {
    CreateParentCompanyDto,
    UpdateParentCompanyDto,
} from '../../../../types/parentCompanyType';
import { ShareholderDto } from '../../../../types/shareholderType';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import useDebouncedEffect from '../../../commun/CustomHook/useDebouncedEffect';
import useDeleteItem from '../../../commun/CustomHook/useDeleteItem';
import InputComponent from '../../../commun/formComponent/InputComponent';
import SelectComponent from '../../../commun/formComponent/SelectComponent';
import Loading from '../../../commun/Loading';
import PopUp from '../../../commun/PopUp';
import { useNotificationContext } from '../../../Context/notification-context';
import TableComponent from '../../BackOffice/Components/TableComponent';
import { DisplayProjectsAssociated } from './Shareholder';

interface FilterParentCompany {
    parentCompanyName: string;
    kBis: string;
}

const ParentCompany = () => {
    const formState = useForm<FilterParentCompany>({
        defaultValues: {
            parentCompanyName: '',
            kBis: '',
        },
    });

    const { watch, register, control } = formState;

    const [parentCompanyNameDebounce, setParentCompanyNameDebounce] =
        useState('');
    const [kBisDebounce, setKbisDebounce] = useState('');

    useDebouncedEffect(
        () => {
            setParentCompanyNameDebounce(watch('parentCompanyName'));
            setKbisDebounce(watch('kBis'));
        },
        [watch('parentCompanyName'), watch('kBis')],
        500
    );

    const { data: parentCompanies } = useGetParentCompanyByFilterQuery({
        name: parentCompanyNameDebounce,
        kBis: kBisDebounce,
    });

    const [parentCompanyId, setParentCompanyId] = useQueryParam(
        'parentCompanyId',
        NumberParam
    );

    const classNameObejct = {
        item: 'text-sm px-1 font-light py-2 whitespace-nowrap',
    };

    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openShow, setOpenShow] = useState(false);

    return (
        <div className="w-full">
            {openAdd ? (
                <AddParentCompany open={openAdd} setOpen={setOpenAdd} />
            ) : null}
            {openEdit ? (
                <EditParentCompany open={openEdit} setOpen={setOpenEdit} />
            ) : null}
            {openShow ? (
                <ShowInfoParentCompany open={openShow} setOpen={setOpenShow} />
            ) : null}
            <div className="formTemplateSingUp !grid md:grid-cols-2 gap-x-3 w-full">
                <InputComponent value={'parentCompanyName'} register={register}>
                    <LabelComponentForm>Société Mère</LabelComponentForm>
                </InputComponent>
                <InputComponent value={'kBis'} register={register}>
                    <LabelComponentForm>Kbis</LabelComponentForm>
                </InputComponent>
            </div>
            <div className="relative w-full">
                <div className="absolute top-[19px] right-4 z-10">
                    <PrimaryButton onClick={() => setOpenAdd(true)}>
                        Add
                    </PrimaryButton>
                </div>
            </div>
            <TableComponent height="h-[40vh]" head={['Nom', 'Kbis', 'Action']}>
                <tbody className="w-full">
                    {parentCompanies?.length === 0 && (
                        <tr className="">
                            <td
                                className={`${classNameObejct.item} text-center`}
                                colSpan={2}
                            >
                                Aucun résultat
                            </td>
                        </tr>
                    )}
                    {parentCompanies?.map((parentCompany, key) => (
                        <tr
                            key={parentCompany.id}
                            onClick={() => {
                                setParentCompanyId(parentCompany.id);
                            }}
                            className={`border-b cursor-pointer hover:bg-slate-500  ${
                                key % 2 === 1
                                    ? 'bg-secondBackgroundColor'
                                    : 'bg-white '
                            } `}
                        >
                            <td className={`${classNameObejct.item}`}>
                                {parentCompany.name}
                            </td>
                            <td className={`${classNameObejct.item}`}>
                                {parentCompany.kBis}
                            </td>
                            <td
                                className={`${classNameObejct.item} flex  gap-2`}
                            >
                                <WhiteButton onClick={() => setOpenShow(true)}>
                                    Show
                                </WhiteButton>
                                <WhiteButton onClick={() => setOpenEdit(true)}>
                                    Edit
                                </WhiteButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </TableComponent>
        </div>
    );
};

export default ParentCompany;

function ShowInfoParentCompany({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [parentCompanyId, setParentCompanyId] = useQueryParam(
        'parentCompanyId',
        NumberParam
    );

    const { data: parentCompany, isLoading: isLoadingParentCompany } =
        useGetParentCompanyByIdQuery(parentCompanyId || 0, {
            skip: parentCompanyId === undefined,
        });

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            width="w-full md:w-10/12"
            title={() => {
                return (
                    <div className="text-center text-mainColor font-bold text-lg">
                        Information sur la société mère{' '}
                        <span className=" text-mainColor">
                            {parentCompany?.name}
                        </span>
                    </div>
                );
            }}
        >
            <div className="">
                {isLoadingParentCompany ? (
                    <Loading />
                ) : (
                    <div className="w-full flex flex-col gap-3">
                        <DisplayProjectsAssociated
                            projects={parentCompany?.projects || []}
                        />
                        <DisplayShareholderAssociated
                            shareholders={parentCompany?.shareholders || []}
                        />
                        <div className="my-4 flex justify-center items-center">
                            <PrimaryButton onClick={() => setOpen(false)}>
                                Fermer
                            </PrimaryButton>
                        </div>
                    </div>
                )}
            </div>
        </PopUp>
    );
}

function DisplayShareholderAssociated({
    removeShareholder,
    shareholders,
}: {
    shareholders: ShareholderDto[];
    removeShareholder?: (id: number) => Promise<void>;
}) {
    const head = ['Nom'];

    if (removeShareholder && !head.includes('Action')) {
        head.push('Action');
    }
    const classNameObejct = {
        item: 'text-sm  font-light px-1 py-2 whitespace-nowrap',
    };

    return (
        <>
            <h3 className="text-lg font-mainFontFamily">
                Actionnaires associés
            </h3>
            <TableComponent height="h-[40vh]" head={['Nom']}>
                <tbody>
                    {shareholders?.length === 0 && (
                        <tr>
                            <td
                                className="text-center"
                                colSpan={removeShareholder ? 2 : 1}
                            >
                                Aucun résultat
                            </td>
                        </tr>
                    )}
                    {shareholders?.map((shareholder, key) => (
                        <tr
                            className={`border-b w-full cursor-pointer hover:bg-slate-500  ${
                                key % 2 === 1
                                    ? 'bg-secondBackgroundColor'
                                    : 'bg-white '
                            } `}
                            key={shareholder.id}
                        >
                            <td className={`${classNameObejct.item}`}>
                                {shareholder.fullName}
                            </td>
                            {removeShareholder && (
                                <td>
                                    <WhiteButton
                                        onClick={() =>
                                            removeShareholder(shareholder.id)
                                        }
                                    >
                                        Remove
                                    </WhiteButton>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </TableComponent>
        </>
    );
}

export function AddParentCompany({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const formState = useForm<CreateParentCompanyDto>();

    const { register, handleSubmit, watch, control, setValue } = formState;

    const { showError, showSuccess } = useNotificationContext();

    const [addShareholder, { isLoading: isLoadindAdd }] =
        useAddParentCompanyMutation();

    const onSubmit = async (data: CreateParentCompanyDto) => {
        await addShareholder(data)
            .unwrap()
            .then(() => {
                showSuccess('Created', 'Shareholder added');
                setOpen(false);
            })
            .catch(() => {
                showError('Error', 'Error adding shareholder');
            });
    };

    const { data: shareholders } = useGetShareholdersQuery();

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            title={() => {
                return (
                    <div className="text-center text-mainColor font-bold text-lg">
                        Add Société mère
                    </div>
                );
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp"
            >
                <InputComponent value={'name'} register={register}>
                    <LabelComponentForm>Name</LabelComponentForm>
                </InputComponent>
                <InputComponent value={'kBis'} register={register}>
                    <LabelComponentForm>Kbis</LabelComponentForm>
                </InputComponent>

                <div className="w-full mt-4 flex justify-center items-center gap-2 ">
                    <WhiteButton onClick={() => setOpen(false)}>
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit" loading={isLoadindAdd}>
                        Add
                    </PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
}

function EditParentCompany({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [parentCompanyId, setParentCompanyId] = useQueryParam(
        'parentCompanyId',
        NumberParam
    );

    const { data: parentCompany, isLoading: isLoadingShareholder } =
        useGetParentCompanyByIdQuery(parentCompanyId || 0, {
            skip: parentCompanyId === undefined,
        });

    const { data: shareholders } = useGetShareholdersQuery();

    const formState = useForm<UpdateParentCompanyDto>();

    const { register, handleSubmit, watch, control, reset, setValue } =
        formState;

    useEffect(() => {
        if (parentCompany) {
            reset(parentCompany);
        }
    }, [parentCompany]);

    const { showError, showSuccess } = useNotificationContext();

    const [editParentCompany, { isLoading: isLoadindEdit }] =
        useUpdateParentCompanyMutation();

    const onSubmit = async (data: UpdateParentCompanyDto) => {
        await editParentCompany(data)
            .unwrap()
            .then(() => {
                showSuccess('Edited', 'Shareholder added');
                setParentCompanyId(undefined);
                setOpen(false);
            })
            .catch(() => {
                showError('Error', 'Error adding shareholder');
            });
    };

    const [
        deleteParentCompany,
        { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
    ] = useDeleteParentCompanyMutation();

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        parentCompanyId: number;
    }>({
        deleteItem: () => deleteParentCompany(parentCompany?.id!),
        toDoIfSuccess: () => setOpen(false),
        message: 'Société mère',
        isLoading: isLoadingDelete,
    });

    const addShareholder = async (id: number) => {
        const request: UpdateParentCompanyDto = {
            id: parentCompany?.id!,
            addShareholderId: id,
            name: parentCompany?.name!,
            kBis: parentCompany?.kBis!,
        };

        await editParentCompany(request)
            .unwrap()
            .then(() => {
                setValue('addShareholderId', undefined);
            })
            .catch(() => {
                showError('Error', 'Error adding shareholder');
            });
    };

    const removeShareholder = async (id: number) => {
        const request: UpdateParentCompanyDto = {
            id: parentCompany?.id!,
            removeShareholderId: id,
            name: parentCompany?.name!,
            kBis: parentCompany?.kBis!,
        };

        await editParentCompany(request)
            .unwrap()
            .then(() => {
                setValue('removeShareholderId', undefined);
            })
            .catch(() => {
                showError('Error', 'Error adding shareholder');
            });
    };

    const shareholderOptions = useMemo(() => {
        const shareholdersFiltered = shareholders?.filter((s) => {
            return (
                parentCompany?.shareholders?.findIndex((p) => p.id === s.id) ===
                -1
            );
        });

        return (
            shareholdersFiltered?.map((p) => ({
                value: p.id,
                label: `${p.fullName}`,
            })) || []
        );
    }, [shareholders, parentCompany]);

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            title={() => {
                return (
                    <>
                        <div className="text-center text-mainColor font-bold text-lg">
                            Edit Société mère
                        </div>
                        {parentCompany && parentCompany?.id ? (
                            <FontAwesomeIcon
                                onClick={() => setOpenDelete(true)}
                                icon={faTrash}
                                className="absolute top-8 md:top-5 cursor-pointer right-5 transition-all hover:scale-105"
                            />
                        ) : null}
                    </>
                );
            }}
        >
            <DeleteComponent title="cette société mère" />

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp"
            >
                {isLoadingShareholder ? (
                    <Loading />
                ) : (
                    <>
                        <InputComponent value={'name'} register={register}>
                            <LabelComponentForm>Name</LabelComponentForm>
                        </InputComponent>
                        <InputComponent value={'kBis'} register={register}>
                            <LabelComponentForm>Kbis</LabelComponentForm>
                        </InputComponent>
                        <div className="grid w-full mb-4 md:gap-x-4 place-items-end md:grid-cols-[1fr,auto]">
                            <SelectComponent
                                register={register}
                                value={'addShareholderId'}
                                control={control}
                                container={true}
                                required={false}
                                watch={watch}
                                optionValues={shareholderOptions || []}
                            >
                                <LabelComponentForm>
                                    Add Actionnaire
                                </LabelComponentForm>
                            </SelectComponent>
                            <div className=" md:pb-3">
                                <PrimaryButton
                                    disabled={!watch('addShareholderId')}
                                    onClick={() =>
                                        addShareholder(
                                            watch('addShareholderId') || 0
                                        )
                                    }
                                >
                                    Add
                                </PrimaryButton>
                            </div>
                        </div>
                        <DisplayShareholderAssociated
                            shareholders={parentCompany?.shareholders || []}
                            removeShareholder={removeShareholder}
                        />
                        <div className="w-full mt-4 flex justify-center items-center gap-2 ">
                            <WhiteButton onClick={() => setOpen(false)}>
                                Cancel
                            </WhiteButton>
                            <PrimaryButton
                                type="submit"
                                loading={isLoadindEdit}
                            >
                                Edit
                            </PrimaryButton>
                        </div>
                    </>
                )}
            </form>
        </PopUp>
    );
}
