import { FormCreateDocumentUserAddress } from '../components/DashboardUser/Profile/FormInvest/DocumentAdress';
import { FormCreateSidedDocumentUser } from '../components/DashboardUser/Profile/FormInvest/DocumentsIdentity1';
import {
    CategoryQuestion,
    KnowledgeAnswerDto,
    KnowledgeQuestionDto,
    TypeQuestion,
} from './KnowledgeDto';
import { ShareDto } from './Share';
import { SponsorDto } from './Sponsor';

export interface UserSignInDto {
    email: string;
    password: string;
}

export interface UserCreationDto extends UserSignInDto {
    firstName: string;
    lastName: string;
    acceptTerms: boolean;
    email: string;
    password: string;
    phone: string;
    acceptRisque: boolean;
    sponsorCode?: string;
}

export interface UserRequestDto {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    admin: boolean;
    createdAt: Date;
    status: UserStatusType;
    investAccountNeedToValidate: boolean;
}

export interface ViewAllUsersDto {
    rows: UserRequestDto[];
    count: number;
}

export interface UserSearchDto {
    id: number;
    firstName: string;
    lastName: string;
}

export interface GetUserWithSharesByDateBetweenLimitDto {
    dateStart: Date;
    dateEnd: Date;
    limit: number;
    offset: number;
    fondsId: number;
    noFeesShare: boolean;
    search: string;
}

export interface GetUserWithSharesByDateLimitRequest {
    date: Date;
    limit: number;
    offset: number;
    fondsId: number;
    noFeesShare: boolean;
    search?: string;
}

export interface UpdateUserIdentityDto {
    sex: 'Monsieur' | 'Madame';
    firstName: string;
    lastName: string;
    birthDay: Date | string;
    birthPlace: string;
    nationality: string;
    adress: string;
    phone: any;
    professionalSituation: string;
    polticalyExposed: string | boolean;
    politicalyExposedDetails: string;
    usCitizen: string | boolean;
}

export interface UpdateUserInvestAccountDto {
    adress: string;
    phone: string;
    professionalSituation: string;
    politicalyExposed: string | boolean;
    politicalyExposedDetails: string;
    usCitizen: string | boolean;
}

export interface FormUpdateUserInvestAccountDto
    extends UpdateUserInvestAccountDto {
    documentIdentity1: FormCreateSidedDocumentUser;
    documentAdress: FormCreateDocumentUserAddress;
}

export interface CreateInvestAccountAndUpdateUserInfoDto {
    userId: number;
    physicalOrMoral: 'physical' | 'moral';
    iban: string;
    bic: string;
    moralAccount?: CreateMoraleAccountDto;
    userInfoInvestAccount?: UpdateUserInvestAccountDto;
}

export interface FormCreateInvestAccountAndUpdateUserInfoDto
    extends FormCreateInvestAccountDto {
    userInfoInvestAccount: FormUpdateUserInvestAccountDto;
}

export interface FormCreateInvestAccountDto
    extends CreateInvestAccountAndUpdateUserInfoDto {
    documentRib: FileList;
    documentStatus: FileList;
    documentKbis: FileList;
    documentDeclarationBeneficial_Owner: FileList;
}

export interface FormUpdateInvestAccountDto {
    id: number;
    moralAccount?: UpdateMoraleAccountDto;
    documentRib: FileList;
    documentStatus: FileList;
    documentKbis: FileList;
    documentDeclarationBeneficial_Owner: FileList;
    userId: number;
    iban: string;
    bic: string;
    userInfoInvestAccount?: FormUpdateUserInvestAccountDto;
}

export interface FormUpdateInvestAccountInInvestirDto
    extends FormUpdateInvestAccountDto {
    userInfoInvestAccount: FormUpdateUserInvestAccountDto;
    documentIdentity1: FormCreateSidedDocumentUser;
    documentAdress: FormCreateDocumentUserAddress;
}

export interface FormUpdateInvestAccountInFormInvestisorDto {
    id: number;
    moralAccount?: UpdateMoraleAccountDto;
    documentRib?: FileList;
    documentStatus: FileList;
    documentKbis: FileList;
    documentDeclarationBeneficial_Owner: FileList;
    userId: number;
    iban?: string;
    bic?: string;
}

export interface DocumentValidationForUserDto {
    id: number;
    comment?: string;
    documentId: number;
    date: Date;
    status: StateStatus;
}

export interface DocumentValidationForAdminDto
    extends DocumentValidationForUserDto {
    performValidationUserId: number;
    performValidationName: string;
}

export interface CreateOrUpdateValidationInvestAccountDto {
    status: StateStatus;
    investAccountId: number;
    investAccount: CreateOrUpdateDocumentValidationDto;
    moralAccount: CreateOrUpdateDocumentValidationDto[];
    beneficialOwner: CreateOrUpdateDocumentValidationDto[];
}

export interface CreateOrUpdateDocumentValidationDto {
    id?: number;
    comment?: string;
    documentId: number;
    status: StateStatus;
    performValidationUserId: number;
}

export interface DocumentInvestAccountValidationForUserDto
    extends DocumentValidationForUserDto {}

export interface DocumentMoralValidationForUserDto
    extends DocumentValidationForUserDto {}

export interface DocumentInvestAccountValidationForAdminDto
    extends DocumentValidationForAdminDto {}

export interface DocumentMoralValidationForAdminDto
    extends DocumentValidationForAdminDto {}

export interface DocumentBeneficialOwnerValidationForUserDto
    extends DocumentValidationForUserDto {}

export interface DocumentBeneficialOwnerValidationForAdminDto
    extends DocumentValidationForAdminDto {}

export interface UpdateInvestAccountDto {
    id: number;
    userId: number;
    iban: string;
    bic: string;
    moralAccount?: UpdateMoraleAccountDto;
    userInfoInvestAccount?: UpdateUserInvestAccountDto;
}

export interface DocumentBeneficialOwnerDto {
    id: number;
    status: StateStatus;
    key: string;
    date: Date;

    fileName: string;
    side: DocumentUserSide;
    name: DocumentUserName;
    url: string;
    beneficialOwnerId: number;
    firstName: string;
    lastName: string;
    validation?: DocumentBeneficialOwnerValidationForUserDto;
}

export interface CreateDocumentBeneficialOwnerDto {
    fileName: string;
    name: DocumentUserName;
    beneficialOwnerId: number;
    side: DocumentUserSide;
}

export interface BeneficialOwnerDto {
    id: number;
    firstName: string;
    lastName: string;
    proportionShareholder: number;
    nationality: string;
    moralAccountId: number;
}

export interface CreateBeneficialOwnerDto {
    firstName: string;
    lastName: string;
    moralAccountId: number;
    proportionShareholder: number;
    nationality: string;
}

export interface FormCreateBeneficialOwnerDto extends CreateBeneficialOwnerDto {
    file: FileList;
    name: DocumentUserName;
}

export interface FormCreateOrUpdateBeneficialOwnerDto
    extends CreateOrUpdateBeneficialOwnerDto {
    file: FileList;
    name: DocumentUserName;
}

export interface CreateOrUpdateBeneficialOwnerDto {
    id?: number;
    firstName: string;
    lastName: string;
    proportionShareholder: number;
    nationality: string;
    moralAccountId: number;
}

export interface CreateMoraleAccountDto {
    companyName: string;
    registrationNumber: string;
    legalStatus: string;
    legalStatusOther?: string;
    investorCategorization: 'pro' | 'noPro';
    address: string;
    socialObject: string;

    addressComplement?: string;
    investAccountId: number;
    beneficialOwners: FormCreateBeneficialOwnerDto[];
}

export interface UpdateMoraleAccountDto {
    id: number;
    companyName: string;
    socialObject: string;

    registrationNumber: string;
    legalStatus: string;
    legalStatusOther?: string;
    investorCategorization: 'pro' | 'noPro';
    address: string;
    addressComplement?: string;
    investAccountId: number;
    beneficialOwners: FormCreateOrUpdateBeneficialOwnerDto[];
}

export interface CreateDocumentInvestAccountDto {
    fileName: string;
    iban: string;
    bic: string;
    investAccountId: number;
}

export interface DocumentInvestAccountDto {
    id: number;
    status: StateStatus;
    date: Date;

    key: string;
    fileName: string;
    url: string;
    iban: string;
    bic: string;
    validation?: DocumentInvestAccountValidationForUserDto;
    investAccountId: number;
}

export interface AllDocumentInvestAccount {
    investAccount: DocumentInvestAccountDto[];
    moralAccount: DocumentMoralAccountDto[];
    beneficialOwner: DocumentBeneficialOwnerDto[];
}

export interface AllLatestDocumentInvestAccount {
    investAccount?: DocumentInvestAccountDto;
    moralAccount: DocumentMoralAccountDto[];
    beneficialOwner: DocumentBeneficialOwnerDto[];
}

export interface CreateDocumentMoralAccountDto {
    type: DocumentMoralAccountType;
    fileName: string;
    moralAccountId: number;
}

export interface InvestAccountDto {
    id: number;
    physicalOrMoral: 'physical' | 'moral';
    iban?: string;
    bic?: string;
    status: StateStatus;
    userId: number;
    moralAccount?: MoraleAccountDto;
}

export enum DocumentMoralAccountType {
    STATUS = 'STATUS',
    KBIS = 'KBIS',
    // declaration of beneficial owner
    DECLARATION_BENEFICIAL_OWNER = 'DECLARATION_BENEFICIAL_OWNER',
}

export interface MoraleAccountDto {
    id: number;
    companyName: string;
    registrationNumber: string;
    legalStatus: string;
    legalStatusOther?: string;
    investorCategorization: 'pro' | 'noPro';
    address: string;
    addressComplement?: string;
    investAccountId: number;
    beneficialOwners: BeneficialOwnerDto[];
    documents?: DocumentMoralAccountDto[];
}

export interface DocumentMoralAccountDto {
    id: number;
    type: DocumentMoralAccountType;
    status: StateStatus;
    key: string;
    date: Date;

    fileName: string;
    url: string;
    moralAccountId: number;
    validation?: DocumentMoralValidationForUserDto;
}

export interface UserWithSharesDto {
    id: number;
    firstName: string;
    lastName: string;
    shares: ShareDto[];
}

export interface IRRTransaction {
    irr: number;
    date: Date;
    transactionId: number;
}

export interface ViewUserWithSharesDto {
    rows: UserWithSharesDto[];
    count: number;
    firstDate?: Date;
}

export interface UpdateUserPasswordDto {
    id: number;
    password: string;
}

export interface CheckUserPasswordDto {
    email: string;
    oldPassword: string;
}

export interface UpdateEmailUserDto {
    id: number;
    email: string;
}

export interface UpdateUserByUserDto extends Partial<UserCreationDto> {
    id: number;
    dateSubscription: Date | null;
    status?: UserStatusType;
}

export interface UpdateUserWithAllInfoDto extends Partial<UserCreationDto> {
    id: number;
    dateSubscription?: Date;
    userInfo?: CreateUserInfoDto;
    // knowledges?: CreateOrUpdateKnowledgeUserDto[];
}

export interface UpdateUserKnowlegdeDto {
    userId: number;
    knowledges?: CreateOrUpdateKnowledgeUserDto[];
}

export interface UpdateUserByAdminDto {
    id: number;
    admin: boolean;
}

export interface UserDto {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    admin: boolean;
    confirmed: boolean;
    sex?: 'Monsieur' | 'Madame';
    status: string;
    acceptTerms: null | boolean;
    acceptRisque: boolean;
    dateSubscription: null | Date;
    dateValidated?: Date;
    sponsor?: SponsorDto[];
    sponsored?: SponsorDto;
    sponsorCode?: string;
    createdAt: Date;
}

export interface UserWithStatusDto extends UserDto {
    statusUser?: StatusUserDto[];
}

export interface UserWithAllInfoDto extends UserWithStatusDto {
    userInfo?: UserInfoDto;
    statusUser?: StatusUserDto[];
    knowledgeUsers: KnowledgeUserDto[];
    validationProfile?: CheckUserProfileForUserDto;
    investAccounts?: InvestAccountDto[];
}
export interface CheckUserProfileForUserDto {
    id: number;
    date: Date;
    status: boolean;
    userId: number;
    comment?: string;
    commentIdentity?: string;
    commentQuestion?: string;
    validIdentity: boolean;
    validQuestion: boolean;
}

export interface UserWithAllInfoForAdminDto extends UserWithAllInfoDto {
    checkUserProfile?: CheckUserProfileDto[];
}

export interface DocumentUserDto {
    id: number;
    date: Date;
    type: DocumentUserType;
    status: StateStatus;
    side: DocumentUserSide;
    userId: number;
    name: DocumentUserName;
    key: string;
    fileName: string;
    url: string;
    validation?: DocumentUserValidationDto;
}

export interface CreateDocumentUserDto {
    type: DocumentUserType;
    name: DocumentUserName;
    side: DocumentUserSide;
    userId: number;
    fileType: string;
    fileName: string;
    // file: File[];
}

export interface UploadDocument extends FormData {
    file: File;
    uploadUrl: string;
}

export interface UpdateDocumentUserDto extends CreateDocumentUserDto {
    id: number;
    path?: string;
    status?: StateStatus;
}

export enum DocumentUserName {
    IDENTITY_CARD = 'IDENTITY_CARD',
    DRIVING_LICENSE = 'DRIVING_LICENSE',
    PASSPORT = 'PASSPORT',
    RESIDENT_PERMIT = 'RESIDENT_PERMIT',
    BILL = 'BILL',
    BANK_STATEMENT = 'BANK_STATEMENT',
    HOME_INSURANCE = 'HOME_INSURANCE',
    RENT_RECEIPT = 'RENT_RECEIPT',
    THIRD_PARTY_ATTESTATION = 'THIRD_PARTY_ATTESTATION',
    HOST_BY_THIRD_PARTY = 'HOST_BY_THIRD_PARTY',
    PROOF_OF_INCOME = 'PROOF_OF_INCOME',
    OTHER = 'OTHER',
}

export enum DocumentUserType {
    IDENTITY_1 = 'IDENTITY_1',
    IDENTITY_2 = 'IDENTITY_2',
    ADDRESS = 'ADDRESS',
    ADDRESS_THIRD_PARTY = 'ADDRESS_THIRD_PARTY',
    ADDRESS_IDENTTITY = 'ADDRESS_IDENTTITY',
    ADDRESS_THIRD_PARTY_ATTESTATION = 'ADDRESS_THIRD_PARTY_ATTESTATION',
    RIB = 'RIB',
    OTHER = 'OTHER',
}

export enum DocumentUserSide {
    FRONT = 'FRONT',
    BACK = 'BACK',
    BOTH = 'BOTH',
}
export interface CreateUserInfoDto {
    adress: null | string;
    phone: null | string;
    birthDay: null | Date;
    birthPlace: null | string;
    nationality: null | string;
    professionalSituation: null | string;
    politicalyExposed: null | boolean;
    politicalyExposedDetails: null | string;
}

export interface KnowledgeUserDto {
    id: number;
    userId: number;
    question: KnowledgeQuestionDto;
    answers: KnowledgeAnswerDto[];
}

export interface MetricKnowledgeUserCategory {
    category: CategoryQuestion;
    totalGoodAnswers: number;
    totalAnswers: number;
}

export interface MetricKnowledgeUser {
    userId: number;
    resultsPercent: MetricKnowledgeUserCategory[];
    investmentHorizon: boolean;
    balanceInvestment: boolean;
}

export interface CreateOrUpdateKnowledgeUserDto {
    questionId: number;
    answerIds?: number[];
    answer?: string;
    answerId?: number;
    type: TypeQuestion;
}

export interface UserInfoDto {
    id: number;

    adress: null | string;
    phone: null | string;
    birthDay: null | Date;
    birthPlace: null | string;
    professionalSituation: null | string;
    politicalyExposed: null | boolean;
    politicalyExposedDetails: null | string;
    nationality: null | string;
    userId: number;
}

export interface UserOrderCashOutInterface {
    id?: number;
    date: Date | string;
    comment: string;
    amount: number;
    UserId: number;
    userId: number;
}

export interface UserCover extends FormData {
    id?: number;
    file?: File;
}

export interface UserFilesInterface {
    fileIdentite_1: File[] | null;
    fileIdentite_2: File[] | null;
    fileVersoIdentite_1?: File[] | null;
    fileVersoIdentite_2?: File[] | null;
    fileJustificatifDomicile: File[] | null;
    fileIdentitePersonneTiers?: File[] | null;
    fileAttestationHerbetgement?: File[] | null;
}

// Status

export enum UserStatusType {
    newUser = 'Doit remplir ses données personnelles',
    informationNeedToBeChecked = 'Formulaire complété en attente de vérification',
    profileValidated = 'Profil validé',
    profilUnValitaded = 'Profil non validé',
}

export interface StatusUserDto {
    id: number;
    userId: number;
    type: StatusUserTypeEnum;
    name: StatusUserTypeEnum;
    content: string;
    date: Date;
    url?: string;
    dateEnd?: Date;
}

export interface CreateStatusUserDto {
    userId: number;
    type: StatusUserTypeEnum;
    content: string;
    date: Date;
    dateEnd?: Date;
    url?: string;
}

export interface UpdateStatusUserDto extends CreateStatusUserDto {
    id: number;
}

export enum StatusUserTypeEnum {
    InComing = 'InComing',
    Done = 'Done',
    ToDo = 'ToDo',
}

export enum StateStatus {
    unchecked = 'unchecked',
    onGoing = 'onGoing',
    unValidated = 'unValidated',
    validated = 'validated',
}

export enum docStatus {
    allChecked3months = 'All checked + 3 mois',
    allCheckedNot3months = 'All checked - 3 mois',
    notAllChecked = 'Not all checked',
}

export interface ReasonDeleteAccountInterface {
    id?: number;
    reason: string;
    howLongBeenUser: number;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface DocumentUserValidationDto {
    id: number;
    firstname: boolean;
    firstnameComment?: string;
    lastname: boolean;
    lastnameComment?: string;
    birthDay?: boolean;
    birthDayComment?: string;
    birthPlace?: boolean;
    birthPlaceComment?: string;
    comment?: string;
    documentId: number;
    dateValidity: boolean;
    dateValidityComment?: string;
    dateValidityEnd?: Date;
    userId: number;
    type: TypeValidationDocumentUser;
    address?: boolean;
    addressComment?: string;
    acceptedDocument: boolean;
}

export enum TypeValidationDocumentUser {
    IDENTITY = 'IDENTITY',
    RESIDENCY = 'RESIDENCY',
    RESIDENCY_HOST_THIRD_PARTY = 'RESIDENCY_HOST_THIRD_PARTY',
    IDENTITY_HOSY_THIRD_PARTY = 'IDENTITY_HOSY_THIRD_PARTY',
    ATTESTATION_HOST_THIRD_PARTY = 'ATTESTATION_HOST_THIRD_PARTY',
}

export interface DocumentUserValidationDtoForm {
    id?: number;
    firstname: boolean | string;
    firstnameComment?: string;
    lastname: boolean | string;
    lastnameComment?: string;
    birthDay?: boolean | string;
    birthDayComment?: string;
    birthPlace?: boolean | string;
    birthPlaceComment?: string;
    comment?: string;
    documentId: number;
    dateValidity: boolean | string;
    dateValidityComment?: string;
    dateValidityEnd?: Date | string;
    userId: number;
    type: TypeValidationDocumentUser;
    address?: boolean | string;
    addressComment?: string;
    acceptedDocument: boolean | string;
}

export interface CreateDocumentUserValidationDto {
    firstname: boolean;
    firstnameComment?: string;
    lastname: boolean;
    lastnameComment?: string;
    birthDay?: boolean;
    birthDayComment?: string;
    birthPlace?: boolean;
    birthPlaceComment?: string;
    comment?: string;
    documentId: number;
    dateValidity: boolean;
    dateValidityComment?: string;
    dateValidityEnd?: Date;
    userId: number;
    type: TypeValidationDocumentUser;
    address?: boolean;
    addressComment?: string;
    acceptedDocument: boolean;
}

export interface CreateOrUpdateDocumentUserValidationDto
    extends CreateDocumentUserValidationDto {
    id?: number;
}

export interface UpdateDocumentUserValidationDto
    extends CreateDocumentUserValidationDto {
    id: number;
}

export interface ValidationOrNotProfileInvestisor {
    dateValidated?: Date;
    validated: boolean;
    comment: string;
    documentProblem: boolean;
    userId: number;
}

export interface CheckUserProfileDto {
    id: number;
    date: Date;
    status: boolean;
    userCustomerId: number;
    userCheckName: string;
    comment?: string;
    commentIdentity?: string;
    commentQuestion?: string;
    validIdentity: boolean;
    validQuestion: boolean;
}

export interface CreateCheckUserProfileDto {
    date: Date;
    status: boolean;
    userCustomerId: number;
    userAdminId: number;
    comment?: string;
    commentIdentity?: string;
    commentQuestion?: string;
    validIdentity: boolean;
    validQuestion: boolean;
}

export interface CreateValidationUserDto {
    checkUser: CreateCheckUserProfileDto;
    validationDocumentUser: CreateOrUpdateDocumentUserValidationDto[];
}
