import React from "react";
import { useAppSelector } from "../../redux/store";
import { useFetchTokenQuery } from "../../redux/features/tokenSlice";

const ImmoDynPresentation: React.FC = () => {
  const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

  return (
    <div className="presentationFondsImmoDyn">
      <div className="Intro_container container_flex">
        <div className="sub_1 texte">
          <div className="intro_titre caract">
            <h2 className="caract_h2">Introduction</h2>
          </div>
          <div className="positionnement">
            <h2>Positionnement</h2>
            <p>
              Le fonds se positionne en tant qu'alternative au traditionnel
              investissement en action et obligation cotée, en proposant un
              portefeuille diversifié sur l'économie réelle et sécurisé par des
              actifs immobiliers, très apprécié pour son ratio rendement/risque.
            </p>
          </div>

          <div className="composition">
            <h2>Composition du fond</h2>
            <p>
              Le fonds investit en obligations de 2nd rang afin de co-financer
              les besoins en fonds propres de PME Francaises dans le secteur de
              l'immobilier (promoteurs et marchants de biens) développant des
              projets faisant preuve d'une resilience financière et d'une
              attention particulière aux enjeux sociaux et environnementaux.
            </p>
          </div>
        </div>
        {!!user && (
          <React.Fragment>
            <div className="sub_2 texte">
              <div className="intro_titre caract">
                <h2 className="caract_h2">Caractéristiques</h2>
              </div>
              <div className="rendement">
                <h2>Rendement</h2>
                <ul>
                  <li>
                    Un rendement plus élevé que les produits d'investissement
                    traditionnels, tels que les obligations (rendemnet cible :
                    9% net de frais) ;
                  </li>
                  <li>
                    Un accès à des deals institutionnels privés bénéficiant d'un
                    ratio risque/rendement avantageux, conséquence d'un
                    durcicement des régulations bancaires post 2008 qui à
                    fortement diminué le nombre de prestataires de financement à
                    destination des PME. ;
                  </li>
                  <li>
                    Un rendement optimisé grâce à un endettement contrôlé et
                    limité à 20% du fonds.
                  </li>
                </ul>
              </div>

              <div className="risque">
                <h2>Risques</h2>
                <ul>
                  <li>
                    Un accès à un portefeuille d'obligation sur des projets
                    immobiliers diversifiés dans toute la France( &gt; 30
                    investissements) ;
                  </li>
                  <li>
                    Un investissement bien moins volatile que les actions &lt;
                    3% ;
                  </li>
                  <li>
                    Un investissement adossé à des actifs tangibles (immobilier)
                    et bénéficiant de structure de prêt et de clauses
                    (cautions...).
                  </li>
                </ul>
              </div>

              <div className="impact">
                <h2>Impact</h2>
                <ul>
                  <li>
                    Un investissement sur des projets en France et ayant des
                    impacts directs sociaux et environnementaux (création
                    d'emplois, économie d'énergie).
                  </li>
                </ul>
              </div>
            </div>
            <div className="sub_4 tableau1">
              <div className="tableau">
                <table>
                  <tbody>
                    <tr>
                      <th className="ligne" colSpan={2}>
                        Details du fonds
                      </th>
                    </tr>
                    <tr>
                      <td>Type de fonds</td>
                      <td>Open-ended</td>
                    </tr>
                    <tr>
                      <td>Durée minimale d'investissement</td>
                      <td>2 ans (2% de pénalité)</td>
                    </tr>
                    <tr>
                      <td>Rachat de parts</td>
                      <td>Mensuel, avec un préavis de 180 jours*</td>
                    </tr>
                    <tr>
                      <td className="ligne">Valorisation</td>
                      <td className="ligne">Mensuel </td>
                    </tr>
                    <tr>
                      <td>Rendement cible net de frais</td>
                      <td>9%**</td>
                    </tr>
                    <tr>
                      <td>Diversification</td>
                      <td>&gt; 30 positions</td>
                    </tr>
                    <tr>
                      <td className="ligne">Risque</td>
                      <td className="ligne">4/7</td>
                    </tr>
                    <tr>
                      <td>Investissement minimum initial</td>
                      <td>
                        10 000€ accredité
                        <br /> 100 000€ non-accrédité
                      </td>
                    </tr>
                    <tr>
                      <td className="ligne">
                        Investissement minimum ultérieur
                      </td>
                      <td className="ligne">10 000€</td>
                    </tr>
                    <tr>
                      <td>Frais de gestion</td>
                      <td>0,8 - 1,2%</td>
                    </tr>
                    <tr>
                      <td className="ligne">Frais d'entrée</td>
                      <td className="ligne">1 - 2%</td>
                    </tr>
                    <tr>
                      <td>Zone géographique</td>
                      <td>France</td>
                    </tr>
                    <tr>
                      <td>Secteur</td>
                      <td>Immobilier</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="tableau tableau2">
                <table className="table_2">
                  <tbody>
                    <tr>
                      <th className="ligne" colSpan={2}>
                        DOCUMENTS D'INFORMATION
                      </th>
                    </tr>
                    <tr>
                      <td className="download" colSpan={2}>
                        <p className="pointer">
                          <i className="fa fa-download"></i>DICI (pdf) (à venir)
                        </p>
                      </td>
                    </tr>
                    {/* <!-- <a href="" download="test-dl"><i className="fa fa-download"></i>DICI (pdf)</a> --> */}
                  </tbody>
                </table>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ImmoDynPresentation;
