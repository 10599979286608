import React, { useCallback, useContext, useEffect, useState } from "react";
import { debounce, printLargeValue, setDate } from "../../../../function/Utils";
import { useAppSelector } from "../../../../redux/store";
import { FondsDto } from "../../../../types/fonds";
import { BackOfficeState } from "../../../Context/AppContext";
import { InfoByFundsDto } from "../../../../types/shareApi";

interface Props {
  fonds: InfoByFundsDto;
  number: number;
}

const FondsElement: React.FC<Props> = ({ fonds, number }) => {
  const backOfficeState = useContext(BackOfficeState);

  const classNameObejct = {
    item: "text-sm font-light px-6 py-4 whitespace-nowrap",
  };

  return (
    <React.Fragment>
      {!!backOfficeState && fonds && (
        <tr
          className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
            number % 2 === 1 ? "bg-secondBackgroundColor" : "bg-white "
          } `}
        >
          <td className={`${classNameObejct.item}`}>{fonds.fondsName}</td>
          <td className={`${classNameObejct.item}`}>
            {printLargeValue(fonds.valorisationByUsers?.toFixed(2))}
          </td>
          <td className={`${classNameObejct.item}`}>
            {setDate(
              backOfficeState.year,
              backOfficeState.month,
              backOfficeState.day
            ).toLocaleDateString()}
          </td>
          <td className={`${classNameObejct.item}`}>
            {printLargeValue(fonds.nbUsers)}
          </td>
          <td className={`${classNameObejct.item}`}>
            {printLargeValue(fonds.averageValorisationByUser?.toFixed(2))}
          </td>
          <td className={`${classNameObejct.item}`}>
            {fonds.tri?.toFixed(2)} %{" "}
            <span className="text-xs text-gray-400 italic">
              ({fonds.triNotBlackList?.toFixed(2)} % hors blacklist)
            </span>
          </td>
          <td className={`${classNameObejct.item}`}>
            {fonds.triCurrent?.toFixed(2)} %{" "}
            <span className="text-xs text-gray-400 italic">
              ({fonds.triNotBlackListCurrent?.toFixed(2)} % hors blacklist)
            </span>
          </td>

          <td className={`${classNameObejct.item}`}>
            {fonds.triLast30Days?.toFixed(2)} %{" "}
            <span className="text-xs text-gray-400 italic">
              ({fonds.triNotBlackListLast30Days?.toFixed(2)} % hors blacklist)
            </span>
          </td>
          <td className={`${classNameObejct.item}`}>
            {fonds.triSinceBeginningOfYear?.toFixed(2)} %{" "}
            <span className="text-xs text-gray-400 italic">
              ({fonds.triNotBlackListSinceBeginningOfYear?.toFixed(2)} % hors
              blacklist)
            </span>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default FondsElement;
