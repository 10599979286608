import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';
import { CashBaseInfoDto, CreateProjectDto, DocumentProjectDto, ProjectDto, ProjectWithValorisationDto, TypeDocumentProject, UpdateProjectClosureDto, UpdateProjectDto } from '../../types/project';
import { GetByDateBetweenByFundsDto, ProjectValorisationForAdminDto } from '../../types/shareApi';
import { setProjectWithValorisation } from './shareApi';
import { TransactionDto } from '../../types/transactions';



interface ProjetState {
    projets: ProjectDto[];
    cashIds: CashBaseInfoDto[];
    projectValorisationForAdminDto: ProjectValorisationForAdminDto | null;
    documentProject: DocumentProjectDto[];
    project: {
        project: ProjectDto | null,
        transactions: TransactionDto[]
    }
    loading: boolean;
    loadingProjectGetById: boolean;
    loadingDocuments: boolean;
    errors: any;
}

const initialState: ProjetState = {
    projets: [],
    cashIds: [],
    projectValorisationForAdminDto: null,
    documentProject: [],
    project: {
        project: null,
        transactions: []
    },
    loading: false,
    loadingProjectGetById: false,
    loadingDocuments: false,
    errors: null,
}

// actions are processes that get data from backend
export const getProjets = createAsyncThunk<ProjectDto[]>(
    "Projet/getProjet",
    async (_, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}projects`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getProjectById = createAsyncThunk<{
    project: ProjectDto,
    transactions: TransactionDto[]
}, number>(
    "Projet/getProjectById",
    async (projectId, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}projects/getById/${projectId}`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getCashIds = createAsyncThunk<CashBaseInfoDto[]>(
    "Projet/getCashIds",
    async (_, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}projects/cashIds`,
                withCredentials: true,
            })
            return response.data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)


export const getProjectsWithProjectValorisationByFonds = createAsyncThunk<ProjectValorisationForAdminDto, GetByDateBetweenByFundsDto>(
    "Projet/getProjectsWithProjectValorisationByFonds",
    async (payload, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}projects/projectWithValorisations`,
                withCredentials: true,
                params: {
                    dateEnd: payload.dateEnd,
                    dateStart: payload.dateStart,
                    fondsId: payload.fondsId
                }

            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

// get doucments
export const getProjectDocumentsWithUrl = createAsyncThunk<DocumentProjectDto[], number>(
    "Projet/getProjectDocumentsWithUrl",
    async (id, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}projects/documents/url/${id}`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const getProjectDocuments = createAsyncThunk<DocumentProjectDto[], number>(
    "Projet/getProjectDocuments",
    async (id, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}projects/documents/url/${id}`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)



// export const uploadDocumentProject = createAsyncThunk<DocumentProjectDto, UploadDocumentProjectDto>(
//     "Projet/uploadDocumentProject",
//     async (data, thunkAPI) => {
//         try {
//             const urlSigned = await axios({
//                 method: "GET",
//                 url: `${import.meta.env.VITE_API_URL}projects/documents/url/put`,
//                 params: {
//                     fileType: encodeURIComponent(data.file.type),
//                     projectName: data.projectName,
//                     projectId: data.projectId
//                 },
//                 withCredentials: true,
//             });

//             const { uploadUrl, key } = urlSigned.data as unknown as {
//                 uploadUrl: string;
//                 key: string;
//             };
//             const res = await axios.put(uploadUrl, data.file);
//             const response = await axios({
//                 method: "post",
//                 url: `${import.meta.env.VITE_API_URL}projects/documents/post`,
//                 data: {
//                     projectId: data.projectId,
//                     fileName: data.fileName,
//                     type: data.type,
//                     comment: data.comment,
//                     key: key,
//                 },
//                 withCredentials: true,
//             })
//             return response.data
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error);
//         }
//     }
// )

export const deleteDocumentProject = createAsyncThunk<number, number>(
    "Projet/deleteDocumentProject",
    async (id, thunkAPI) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}projects/documents/${id}`,
                withCredentials: true,
            })
            return id;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const addProjet = createAsyncThunk<ProjectWithValorisationDto, CreateProjectDto>(
    "Projet/addProjet",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "post",
                url: `${import.meta.env.VITE_API_URL}projects`,
                data: data,
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const editProjet = createAsyncThunk<ProjectWithValorisationDto, UpdateProjectDto>(
    "Projet/editProjet",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "put",
                url: `${import.meta.env.VITE_API_URL}projects/${data.id}`,
                data: { ...data },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const editClosureProject = createAsyncThunk<ProjectWithValorisationDto, UpdateProjectClosureDto>(
    "Projet/editClosureProjet",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "put",
                url: `${import.meta.env.VITE_API_URL}projects/closure/${data.id}`,
                data: { ...data },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteProjet = createAsyncThunk<number, number>(
    "Projet/deleteProjet",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}projects/${id}`,
                withCredentials: true,
            })
            return id
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const uploadCoverProjet = createAsyncThunk<FormData, FormData>(
    "Projet/uploadCoverProjet",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "post",
                url: `${import.meta.env.VITE_API_URL}projects/upload`,
                data: data,
                headers: { "Content-Type": 'multipart/form-data' },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);


// reducers -> reduce to a specific state -> changes state 

export const projetSlice = createSlice({
    name: "Projet",
    initialState,
    reducers: {
        setProjet: (state, action: PayloadAction<ProjectDto[]>) => {
            state.projets = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProjets.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProjets.fulfilled, (state, action) => {
            state.projets = action.payload;
            state.loading = false;
        });
        builder.addCase(getProjets.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(getProjectById.pending, (state) => {
            state.loadingProjectGetById = true;
        });
        builder.addCase(getProjectById.fulfilled, (state, action) => {
            state.project = action.payload;
            state.loadingProjectGetById = false;
        });
        builder.addCase(getProjectById.rejected, (state, action) => {
            state.loadingProjectGetById = false;
            state.errors = action.error;
        });
        builder.addCase(getProjectDocuments.pending, (state) => {
            state.loadingDocuments = true;
        });
        builder.addCase(getProjectDocuments.fulfilled, (state, action) => {
            state.documentProject = action.payload;
            state.loadingDocuments = false;
        });
        builder.addCase(getProjectDocuments.rejected, (state, action) => {
            state.loadingDocuments = false;
            state.errors = action.error;
        });
        builder.addCase(getProjectDocumentsWithUrl.pending, (state) => {
            state.loadingDocuments = true;
        });
        builder.addCase(getProjectDocumentsWithUrl.fulfilled, (state, action) => {
            state.documentProject = action.payload;
            state.loadingDocuments = false;
        });
        builder.addCase(getProjectDocumentsWithUrl.rejected, (state, action) => {
            state.loadingDocuments = false;
            state.errors = action.error;
        });
        builder.addCase(getCashIds.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCashIds.fulfilled, (state, action) => {
            state.cashIds = action.payload;
            state.loading = false;
        });
        builder.addCase(getCashIds.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(getProjectsWithProjectValorisationByFonds.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProjectsWithProjectValorisationByFonds.fulfilled, (state, action) => {
            state.projectValorisationForAdminDto = action.payload;
            state.loading = false;
        });
        builder.addCase(getProjectsWithProjectValorisationByFonds.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });

        builder.addCase(addProjet.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addProjet.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editProjet.fulfilled, (state, action) => {
            state.loading = false;
            state.projets = state.projets.map((projet) => {
                if (projet.id === action.payload.id) {
                    return {
                        ...projet,
                        ...action.payload

                    };
                } else return projet

            });
            state.projectValorisationForAdminDto = {
                valorisationByProjects: state.projectValorisationForAdminDto?.valorisationByProjects ?? [],
                projectWithValorisations: state.projectValorisationForAdminDto?.projectWithValorisations.map((projet) => {
                    if (projet.id === action.payload.id) {
                        return {
                            ...projet,
                            ...action.payload

                        };
                    } else return projet

                }
                )
                    ?? []
            }



        });
        builder.addCase(editProjet.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editProjet.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editClosureProject.fulfilled, (state, action) => {
            state.loading = false;
            state.projets = state.projets.map((projet) => {
                if (projet.id === action.payload.id) {
                    return {
                        ...projet,
                        ...action.payload

                    };
                } else return projet

            });
            state.projectValorisationForAdminDto = {
                valorisationByProjects: state.projectValorisationForAdminDto?.valorisationByProjects ?? [],
                projectWithValorisations: state.projectValorisationForAdminDto?.projectWithValorisations.map((projet) => {
                    if (projet.id === action.payload.id) {
                        return {
                            ...projet,
                            ...action.payload

                        };
                    } else return projet

                }
                )
                    ?? []
            }



        });
        builder.addCase(editClosureProject.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editClosureProject.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteProjet.fulfilled, (state, action) => {
            state.loading = false;
            state.projets = state.projets.filter((projet => projet.id !== action.payload));
            state.projectValorisationForAdminDto = {
                valorisationByProjects: state.projectValorisationForAdminDto?.valorisationByProjects ?? [],
                projectWithValorisations: state.projectValorisationForAdminDto?.projectWithValorisations.filter((projet => projet.id !== action.payload))
                    ?? []
            }
        });
        builder.addCase(deleteProjet.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteProjet.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteDocumentProject.fulfilled, (state, action) => {
            state.loadingDocuments = false;
            state.documentProject = state.documentProject.filter((document => document.id !== action.payload));
        });
        builder.addCase(deleteDocumentProject.pending, (state) => {
            state.loadingDocuments = true;
        });
        builder.addCase(deleteDocumentProject.rejected, (state, action) => {
            state.loadingDocuments = false;
            state.errors = action.error;
        });
    }

});

export default projetSlice.reducer;
export const { setProjet } = projetSlice.actions;


