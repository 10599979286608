import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { UserSignInDto } from '../../types/user';
import { PrimaryButton, WhiteButton } from '../commun/Buttons';
import { UidContext } from '../Context/AppContext';
import { useNotificationContext } from '../Context/notification-context';

interface Props {
    path: string;
}

const Login: React.FC<Props> = ({ path }) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [viewPassword, setViewPassword] = useState<boolean>(false);
    const [sendValidationEmailToggle, setSendValidationEmailToggle] =
        useState<boolean>(false);

    const { showError, showSuccess, showInfo } = useNotificationContext();

    const navigate = useNavigate();

    const uidValue = useContext(UidContext);

    const login = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data: UserSignInDto = {
            email: email.toLowerCase().trim(),
            password: password,
        };
        await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}users/auth/login`,
            withCredentials: true,
            data: data,
        })
            .then((value) => {
                console.log(value.data);
                setMessage(value.data.message);
                uidValue?.setUid(null);
                if (value.data.message === 'Vous êtes connecté') {
                    // window.scrollTo(0, 0);
                    window.location.href = path;
                    // navigate(path);
                }
            })
            .catch((err) => {
                if (err.response.data.message === 'Please confirm your email') {
                    setSendValidationEmailToggle(true);
                    showInfo(
                        'Erreur',
                        "Votre email n'est pas validé, veuillez valider votre email !"
                    );
                } else {
                    console.log(err);
                    //setMessage(err.response.data);
                }
            });
    };

    return (
        <React.Fragment>
            {/* <Navigation bol_nosfonds={false} bol_transparent={false} /> */}
            {uidValue?.uid ? (
                <h1 className="titre_login">Déjà connecté</h1>
            ) : (
                <form onSubmit={(e) => login(e)}>
                    <div
                        className={`${
                            message === 'Vous êtes connecté'
                                ? 'text-green-600'
                                : 'text-red-600'
                        }`}
                    >
                        {message}
                    </div>
                    <div className="form-item">
                        <label>Email:</label>
                        <input
                            type="text"
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                            name="email"
                            className="form-control"
                        />
                    </div>

                    <div className="form-item">
                        <label>Password:</label>
                        <div className="relative w-full">
                            <input
                                type={viewPassword ? 'text' : 'password'}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                }}
                                name="password"
                                className="form-control"
                            />
                            <FontAwesomeIcon
                                icon={viewPassword ? faEye : faEyeSlash}
                                className="absolute -right-8 top-0 bottom-0 my-auto cursor-pointer"
                                onClick={() => setViewPassword(!viewPassword)}
                            />
                        </div>
                    </div>

                    <Link
                        to="/ask-reset-password"
                        className="italic underline  text-sm cursor-pointer"
                    >
                        Mot de passe oublié ?
                    </Link>
                    <div className="form-item">
                        <PrimaryButton type="submit" className="mx-auto my-5">
                            Se connecter
                        </PrimaryButton>
                    </div>
                    {sendValidationEmailToggle ? (
                        <SendValidationEmail email={email} />
                    ) : null}
                </form>
            )}
        </React.Fragment>
    );
};

export default Login;

function SendValidationEmail({ email }: { email: string }) {
    const { showError, showSuccess } = useNotificationContext();

    const sendValidationEmail = async () => {
        await axios({
            method: 'get',
            url: `${import.meta.env.VITE_API_URL}email/send-validation-email/${email}`,
            withCredentials: true,
        })
            .then((value) => {
                showSuccess(
                    'Validation',
                    `Un email de validation vous a été envoyé à l'adresse ${email} !`
                );
            })
            .catch((err) => {
                showError(
                    'Erreur',
                    'Une erreur est survenue, veuillez réessayer !'
                );
            });
    };

    return (
        <div className="mb-4">
            <WhiteButton onClick={() => sendValidationEmail()}>
                Renvoyer un email de validation
            </WhiteButton>
        </div>
    );
}
