import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import {
    faCircle,
    faDownload,
    faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import {
    formatDate,
    getInterest,
    lastDay,
    printLargeValue,
} from '../../../function/Utils';
import {
    displayReportRiskNotation,
    getColorReportRiskNotation,
} from '../../../function/projectHelpers';
import {
    useGetProjectByIdForUserQuery,
    useGetProjectDocumentsWithUrlQuery,
    useGetProportionInPorjectByUserIdInFondsIdQuery,
} from '../../../redux/features/projectSlice';
import { useFetchTokenQuery } from '../../../redux/features/tokenSlice';
import { useAppSelector } from '../../../redux/store';
import {
    DocumentProjectDto,
    ProjectForUserDto,
    TypeDocumentProject,
} from '../../../types/project';
import { MenuDashBoardUserBoolState } from '../../Context/AppContext';
import { IMenu } from '../../DashboardAdmin/UserManage/UserManage';
import DisplayTextEditor from '../../commun/DisplayTextEditor';
import { TabsMenu } from '../../commun/HorizontalMenu';
import Loading from '../../commun/Loading';
import ProjectMap from '../../commun/Map/ProjectMap';
import HoverEffect from '../../commun/animation/HoverEffect';
import { LazyImage } from '../../commun/animation/LazyImage';
import { ReportingUser } from './RenderReporting';

// TODO: Say what proportion of the wallet of the user is invested for this project
// If not blacklist use proportion of this project in no black list time sum(shareNotBlacklist*nbShareNotBlacklist)/sumTotalUSerInproject
// If blackList need to store somewhere the proportion of the user in the project (maybe by
// using the date when the project has been put into blackList is it possible to know)

const ProjectUser = ({ projectId }: { projectId: number }) => {
    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    const [queryProjectPage, setQueryProjectPage] = useQueryParam(
        'pageProjectUser',
        StringParam
    );

    const { data, isLoading: isLoadingProject } = useGetProjectByIdForUserQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );

    const navigate = useNavigate();

    const menuList: IMenu[] = [
        {
            queryPage: 'general-info',
            label: 'Introduction',
        },
        {
            queryPage: 'Impact',
            label: 'Impact',
        },
        {
            queryPage: 'Reporting',
            label: 'Communication',
        },
        {
            queryPage: 'Documents',
            label: 'Documents',
        },
    ];

    const selectPage = () => {
        switch (queryProjectPage) {
            case 'general-Info':
                return <GeneralInfo projectId={projectId} />;
            case 'Impact':
                return <Impact projectId={projectId} />;
            case 'Reporting':
                return <ReportingUser projectId={projectId} />;
            case 'Documents':
                return <Documents projectId={projectId} />;
            default:
                return <GeneralInfo projectId={projectId} />;
                break;
        }
    };

    return (
        <div className="relative h-full w-full">
            <div className="md:h-fit ">
                <TabsMenu items={menuList} queryPage="pageProjectUser" />
            </div>
            <div className="p-3 overflow-auto overflow_projectManage h-[73vh] md:h-[75vh] xxxl:h-[80vh]">
                {isLoadingProject ? <Loading /> : <>{selectPage()}</>}
            </div>
        </div>
    );
};

export default ProjectUser;

function Titre({
    children,
    className,
}: {
    className?: string;
    children: React.ReactNode;
}) {
    return (
        <h2
            className={` w-full md:w-11/12 bg-bgDashboardClient mx-auto pl-4 p-2 uppercase rounded-md mt-6 mb-2 font-mainFontFamily text-2xl text-mainColor ${className}`}
        >
            {children}
        </h2>
    );
}

function GeneralInfo({ projectId }: { projectId: number }) {
    const { data: project, isLoading } = useGetProjectByIdForUserQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );

    const measurement = useAppSelector((state) => state.pageMeasurement);

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <h3 className=" mt-5  text-center text-3xl md:text-4xl font-mainFontFamily mb-2 text-mainColor">
                        {project?.realName}
                    </h3>
                    <LazyImage
                        src={project?.cover || ''}
                        className={{
                            img: 'my-2 h-[300px] bg-cover bg-center rounded-sm',
                            div: 'flex justify-center items-center',
                        }}
                    />
                    <div className="w-full px-4 mt-4 mb-6 md:w-11/12 mx-auto">
                        <h3 className=" font-mainFontFamily text-xl md:text-2xl text-center">
                            Informations synthètiques
                        </h3>
                        <div className="w-full text-justify px-4 my-4 mx-auto">
                            {project?.description}
                        </div>
                        <DisplayInfoOnProject project={project!} />
                    </div>

                    <ProjectMap
                        width={
                            measurement?.measurements.widthScreen > 640
                                ? '50%'
                                : '80%'
                        }
                        height={'300px'}
                        project={project}
                        className=" my-4"
                    />
                </>
            )}
            <Titre>Description détailée</Titre>
            <div className="w-full px-2 my-4 md:w-11/12 mx-auto">
                <DisplayTextEditor content={project?.content ?? undefined} />
            </div>
        </div>
    );
}

function DisplayInfoOnProject({ project }: { project: ProjectForUserDto }) {
    const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();

    const { data: proportionProject } =
        useGetProportionInPorjectByUserIdInFondsIdQuery(
            {
                fondsId: project.fondsId,
                userId: userData?.id || 0,
            },
            {
                skip: userData?.id === undefined,
            }
        );

    const data = useMemo(() => {
        if (!project || !proportionProject) return [];
        return [
            {
                label: 'Type de financement',
                value:
                    project.projectInfo?.fundingType ||
                    project.projectInfo?.fundingTypeOther,
            },
            {
                label: 'Secteur',
                value: project?.projectMarketTypology?.map((v, index) => (
                    <div key={index} className="  flex gap-1 items-center">
                        <FontAwesomeIcon
                            icon={faCircle}
                            className="text-mainColor h-2 w-2"
                        />{' '}
                        <p>{v}</p>
                    </div>
                )),
            },
            { label: 'Plateforme', value: project.platformName },
            {
                label: 'Taux',
                value: `${getInterest(
                    project.interests,
                    lastDay(new Date(Date.now()))
                )} %`,
            },
            { label: 'Durée', value: project.duration + ' mois' },
            {
                label: 'Exposition',
                value: `${
                    proportionProject
                        ?.find((prop) => prop.projectId === project.id)
                        ?.proportion?.toFixed(2) || 0
                }
      %`,
            },
            {
                label: 'Valorisation',
                value: printLargeValue(project.valorisation.toFixed(2)) + ' €',
            },
            { label: 'Échéance', value: formatDate(new Date(project.dateEnd)) },
            {
                label: 'Santé',
                value: displayReportRiskNotation(project.notationRisque),
                color: getColorReportRiskNotation(project.notationRisque),
            },
            {
                label: 'Projet immobilisé',
                value: project.dateBlackList
                    ? `Depuis ${formatDate(new Date(project.dateBlackList))} `
                    : 'Non',
            },
        ];
    }, [project, proportionProject]);
    return (
        // <div className="overflow-x-auto">
        //   <table className="min-w-full bg-white border border-gray-300">
        //     <thead>
        //       <tr>
        //         <th className="py-2 px-4 border-b">Label</th>
        //         <th className="py-2 px-4 border-b">Value</th>
        //       </tr>
        //     </thead>
        //     <tbody>
        //       {data.map((item, index) => (
        //         <tr key={index} className="text-left">
        //           <td className="py-2 px-4 border-b font-bold">{item.label}</td>
        //           <td className="py-2 px-4 border-b">{item.value}</td>
        //         </tr>
        //       ))}
        //     </tbody>
        //   </table>
        // </div>
        <div className="grid grid-cols-1 md:grid-cols-2">
            {data.map((item, index) => (
                <div
                    key={item.label}
                    className={`grid md:mt-3 grid-cols-1 md:grid-cols-2 border-b-2 md:w-10/12 py-2 space-y-2 md:space-y-0 `}
                >
                    <h3
                        className={`text-lg font-mainFontFamily font-semibold text-center md:text-start`}
                    >
                        {item.label}
                    </h3>
                    <p
                        className={`flex items-center justify-center md:block md:text-start`}
                        style={item.color ? { color: item.color } : {}}
                    >
                        {item.value}
                    </p>
                </div>
            ))}
        </div>
    );
}

function Impact({ projectId }: { projectId: number }) {
    return <div>Disponible prochainement</div>;
}

function Documents({ projectId }: { projectId: number }) {
    const { data: project, isLoading: isLoadingProject } =
        useGetProjectByIdForUserQuery(projectId ?? 0, {
            skip: projectId === undefined,
        });

    const {
        data: documents,
        isLoading: loadingDocuments,
        refetch: refetchDocuments,
    } = useGetProjectDocumentsWithUrlQuery(projectId ?? 0, {
        skip:
            projectId === undefined ||
            project === undefined ||
            project.confidential,
    });

    const documentsShareIssue = documents?.filter(
        (document) => document.type === TypeDocumentProject.shareIssue
    );
    const documentsProject = documents?.filter(
        (document) => document.type === TypeDocumentProject.project
    );
    const documentSponsorProject = documents?.filter(
        (document) => document.type === TypeDocumentProject.sponsorProject
    );
    const documentOther = documents?.filter(
        (document) => document.type === TypeDocumentProject.other
    );
    return (
        <div className="w-full md:w-10:12">
            {/* <h3 className="text-xl font-semibold mb-3 items-center text-center">
      {subTitle}
    </h3> */}
            <div className="w-full sm:w-10/12  mx-auto gap-2 flex justify-center items-center flex-col">
                {loadingDocuments ? (
                    <Loading />
                ) : (
                    <>
                        {project?.confidential ? (
                            <div className="w-full md:w-11/12 mx-auto">
                                <p className=" text-lg text-justify">
                                    Ce projet est confidentiel, vous ne pouvez
                                    pas accéder aux documents.
                                </p>
                            </div>
                        ) : null}
                        <div className="w-full mx-auto">
                            <TitleComponentForm className="w-full ">
                                Documents d'émission de titre
                            </TitleComponentForm>
                            {documentsShareIssue &&
                            documentsShareIssue?.length! > 0 ? (
                                documentsShareIssue.map((doc, index) => (
                                    <DisplayDocumentProjectForUser
                                        document={doc}
                                        key={index}
                                    />
                                ))
                            ) : (
                                <p className=" text-center">
                                    Aucun document n'a été trouvé.
                                </p>
                            )}
                        </div>
                        <div className="w-full mx-auto">
                            <TitleComponentForm className="w-full ">
                                Documents sur le projet
                            </TitleComponentForm>
                            {documentsProject &&
                            documentsProject?.length! > 0 ? (
                                documentsProject.map((doc, index) => (
                                    <DisplayDocumentProjectForUser
                                        document={doc}
                                        key={index}
                                    />
                                ))
                            ) : (
                                <p className=" text-center">
                                    Aucun document n'a été trouvé.
                                </p>
                            )}
                        </div>

                        <div className="w-full mx-auto">
                            <TitleComponentForm className="w-full ">
                                Documents sur le porteur du projet
                            </TitleComponentForm>
                            {documentSponsorProject &&
                            documentSponsorProject?.length! > 0 ? (
                                documentSponsorProject.map((doc, index) => (
                                    <DisplayDocumentProjectForUser
                                        document={doc}
                                        key={index}
                                    />
                                ))
                            ) : (
                                <p className=" text-center">
                                    Aucun document n'a été trouvé.
                                </p>
                            )}
                        </div>

                        <div className="w-full mx-auto">
                            <TitleComponentForm className="w-full ">
                                Documents autre
                            </TitleComponentForm>
                            {documentOther && documentOther?.length! > 0 ? (
                                documentOther.map((doc, index) => (
                                    <DisplayDocumentProjectForUser
                                        document={doc}
                                        key={index}
                                    />
                                ))
                            ) : (
                                <p className=" text-center">
                                    Aucun document n'a été trouvé.
                                </p>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export function DisplayDocumentProjectForUser({
    document,
}: {
    document: DocumentProjectDto;
}) {
    const type = document?.key.split('.')[1];
    return (
        <div className="">
            <div
                className={`w-11/12 mx-auto items-center gap-3 ${
                    type !== 'pdf'
                        ? 'grid grid-cols-2'
                        : 'flex space-y-2 flex-wrap sm:flex-nowrap justify-between'
                }`}
            >
                <div
                    className={`${
                        type !== 'pdf'
                            ? 'w-full cursor-pointer col-span-2 mx-auto flex justify-center items-center'
                            : 'w-10'
                    } justify-self-start`}
                >
                    {type !== 'pdf' ? (
                        <img src={document?.url} className="max-w-xs" />
                    ) : (
                        <FontAwesomeIcon icon={faFilePdf} size={'2x'} />
                    )}
                </div>

                <div className="text-sm h-fit text-start w-fit md:w-full font-medium leading-6 text-gray-900">
                    {decodeURIComponent(document?.fileName)}
                </div>

                <div className="h-fit justify-self-end text-end w-fit">
                    <div className="text-sm flex gap-4 items-center font-medium leading-6 cursor-pointer text-gray-900">
                        <HoverEffect>
                            <a href={document.url} download={'test'}>
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    className="text-mainColor hover:text-secondColor w-[20px] h-[20px]"
                                />
                            </a>
                        </HoverEffect>
                    </div>
                </div>
            </div>
        </div>
    );
}
