
import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { CreateTypeInvestisseurDto, TypeInvestisseurDto, UpdateTypeInvestisseurDto } from "../../types/typeInvestisseurTypes";
import { baseQueryHandleError } from '../index.rtk';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}typeInvestisseur`,
    credentials: 'include', // Use 'include' to include cookies in the request
});


export const typeInvestisseurApi = createApi({
    reducerPath: 'typeInvestisseurApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: ['TypeInvestisseur'],
    endpoints: (builder) => ({
        getTypeInvestisseurs: builder.query<TypeInvestisseurDto[], void>({
            query: () => `/`,
            providesTags: ['TypeInvestisseur'],
        }),
        getTypeInvestisseursForm: builder.query<TypeInvestisseurDto[], void>({
            query: () => `/`,
            providesTags: ['TypeInvestisseur'],
        }),
        getTypeInvestisseurById: builder.query<TypeInvestisseurDto, number>({
            query: (id) => `/${id}`,
            providesTags: ['TypeInvestisseur'],
        }),
        getTypeInvestisseursByDate: builder.query<TypeInvestisseurDto[], Date>({
            query: (date) => ({
                url: `/date`,
                method: 'GET',
                params: {
                    date: date
                },

            }),
            providesTags: ['TypeInvestisseur'],
        }),
        addTypeInvestisseur: builder.mutation<TypeInvestisseurDto, CreateTypeInvestisseurDto>({
            query: (body) => ({
                url: `/`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['TypeInvestisseur'],
        }),
        updateTypeInvestisseur: builder.mutation<TypeInvestisseurDto, UpdateTypeInvestisseurDto>({
            query: (body) => ({
                url: `/`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['TypeInvestisseur'],
        }),
        deleteTypeInvestisseur: builder.mutation<number, number>({
            query: (id) => ({
                url: `/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['TypeInvestisseur'],
        }),
    }),
});

export const { useAddTypeInvestisseurMutation, useDeleteTypeInvestisseurMutation, useGetTypeInvestisseursByDateQuery, useGetTypeInvestisseursQuery,
    useUpdateTypeInvestisseurMutation, useGetTypeInvestisseurByIdQuery, useGetTypeInvestisseursFormQuery } = typeInvestisseurApi;