import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import {
    debounce,
    formatDate,
    isEmpty,
    lastDay,
    stringToFloat,
    stringToInt,
    transformDate,
} from '../../../function/Utils';
import { UploadDocumentTransactionDto } from '../../../redux/features/transactions';
import { tableBackOfficeInterface } from '../../../types/BackOffice';
import { CreateBlackListDtoForm } from '../../../types/blackList';
import { FormCreatePlatform, PlatformDto } from '../../../types/platform';
import {
    CreateTransactionDtoForm,
    TransactionDto,
    TransactionStatus,
    TypeInvestisseurIdEnum,
    TypeTransaction,
    TypeTransactionProject2Cash,
} from '../../../types/transactions';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import CheckboxComponent from '../../commun/formComponent/CheckboxComponent';
import InputComponent from '../../commun/formComponent/InputComponent';
import SelectComponent, {
    IOptionValues,
} from '../../commun/formComponent/SelectComponent';
import SelectMultiComponent from '../../commun/formComponent/SelectMultiComponent';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import {
    BackOfficeState,
    BackOfficeStateInterface,
    loadingBackOfficeInterface,
    LoadingBackOfficeState,
    MenuDashBoardAdminBoolState,
    RootBlackOfficeState,
} from '../../Context/AppContext';
import { useNotificationContext } from '../../Context/notification-context';
import ApplyFees from './Components/ApplyFees';
import DateManagement from './Components/DateManagement';
import OverviewBackOffice from './Overview/OverviewBackOffice';
import ViewTableBlackList from './ViewTable/ViewTableBlackList';
import ViewTablePlatform from './ViewTable/ViewTablePlatform';
import ViewTableProjects from './ViewTable/ViewTableProjects';
import ViewTableTransactions from './ViewTable/ViewTableTransactions';
import ViewTableUsers from './ViewTable/ViewTableUsers';

import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { useAddBlackListMutation } from '../../../redux/features/blackListSlice';
import { useGetFondsQuery } from '../../../redux/features/fondsSlice';
import {
    uploadDocumentPlatform,
    useAddPlatformMutation,
    useGetPlatformsQuery,
} from '../../../redux/features/platformSlice';
import {
    useGetCashIdsQuery,
    useGetProjectsSearchQuery,
} from '../../../redux/features/projectSlice';
import {
    uploadDocumentTransaction,
    useAddTransactionMutation,
    useGetTransactionSponsorIdQuery,
    useGetTransactionsUserQuery,
} from '../../../redux/features/transactionSlice';
import { useGetTypeInvestisseursFormQuery } from '../../../redux/features/typeInvestisseurSlice';
import { useGetUsersSearchQuery } from '../../../redux/features/userSlice';
import {
    CategoryDocumentPlatform,
    FormCreateDocumentPlatformRequest,
    TypeDocumentPlatform,
} from '../../../types/documentPlatform';
import FieldArray from '../../commun/formComponent/FieldArray';
import RadioComponent from '../../commun/formComponent/RadioComponent';
import RemoveFieldArrayComponent from '../../commun/formComponent/RemoveFieldArrayComponent';
import TextAreaComponent from '../../commun/formComponent/TextAreaComponent';
import TextEditorComponent from '../../commun/formComponent/TextEditorComponent';
import UploaderMulti from '../../commun/formComponent/UploaderMulti';
import UploaderSingle from '../../commun/formComponent/UploaderSingle';
import WrapComponentDashboardUser from '../../DashboardUser/commun/WrapComponentDashboardUser';

interface Props {
    id?: number;
    addBool?: true;
}

const BackOffice: React.FC<Props> = ({ id, addBool }) => {
    const { data: fondsData } = useGetFondsQuery();

    const tableArray: tableBackOfficeInterface[] = [
        tableBackOfficeInterface.overview,
        tableBackOfficeInterface.project,
        tableBackOfficeInterface.users,
        tableBackOfficeInterface.transactions,
        tableBackOfficeInterface.blackLists,
        tableBackOfficeInterface.platform,
    ];

    const rootState = useContext(RootBlackOfficeState);

    let date = lastDay(new Date(Date.now()));
    // let date = setDate(2021, 11, 31);

    const [year, setYear] = useState<number>(date.getFullYear());
    const [month, setMonth] = useState<number>(date.getMonth());
    const [day, setDay] = useState<number>(date.getDate());

    const [loading, setLoading] = useState<boolean>(true);

    const loadingObject: loadingBackOfficeInterface = {
        loading: loading,
        setLoading: setLoading,
    };

    const [overviewState, setOverviewState] = useState<boolean>(false);

    const [addPlatformToggle, setAddPlatformToggle] = useState<boolean>(false);
    const [addBlacklistToggle, setAddBlacklistToggle] =
        useState<boolean>(false);
    const [addTransactionToggle, setAddTransactionToggle] =
        useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (
            rootState?.selectTable !== tableBackOfficeInterface.overview &&
            !overviewState
        ) {
            setOverviewState(true);
        } else if (
            rootState?.selectTable === tableBackOfficeInterface.overview &&
            overviewState
        ) {
            setOverviewState(false);
        }
    }, [rootState?.selectTable]);

    let [tableQuery, setTableQuery] = useQueryParam('table', StringParam);
    let [fondsQuery, setFondsQuery] = useQueryParam('fonds', NumberParam);

    const viewTableFunc = () => {
        switch (tableQuery) {
            case tableBackOfficeInterface.project:
                return (
                    <ViewTableProjects
                        editProjectId={id}
                        addProject={addBool}
                    />
                );
            case tableBackOfficeInterface.transactions:
                return (
                    <ViewTableTransactions
                        editTransactionId={id}
                        addTransaction={addBool}
                    />
                );
            case tableBackOfficeInterface.users:
                return <ViewTableUsers />;
            case tableBackOfficeInterface.blackLists:
                return <ViewTableBlackList />;
            case tableBackOfficeInterface.platform:
                return <ViewTablePlatform />;
            case tableBackOfficeInterface.overview:
                return <OverviewBackOffice />;
            default:
                return <OverviewBackOffice />;
        }
    };

    const backOfficeState: BackOfficeStateInterface = {
        year: year,
        month: month,
        day: day,
        setDay: setDay,
        setMonth: setMonth,
        setYear: setYear,
        fondsId: rootState?.fondsIdBackOffice,
        setFondsId: rootState?.setFondsIdBackOffice,
    };

    const classList = {
        link: 'rounded text-mainColor cursor-pointer italic px-3 py-1 text-md transition-all hover:scale-[1.01]',
    };

    const menuBoolState = useContext(MenuDashBoardAdminBoolState);

    return (
        <BackOfficeState.Provider value={backOfficeState}>
            <LoadingBackOfficeState.Provider value={loadingObject}>
                <React.Fragment>
                    {loading ? (
                        <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto">
                            <WrapComponentDashboardUser
                                title="Back office"
                                description=""
                                classNameHeader={`${
                                    !menuBoolState?.toggleMenu
                                        ? '!pl-[40px]'
                                        : ''
                                }`}
                                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
                            >
                                <div className="overflow-auto h-[83vh] md:h-[87vh] px-2">
                                    <div className="flex gap-3 md:absolute top-2 right-3">
                                        <ApplyFees />
                                        <DailyShare />
                                    </div>

                                    <div className="flex mt-2 gap-5 mx-auto w-11/12 md:w-full md:mx-0 md:justify-center flex-wrap">
                                        {/* <StoreFondsHistoricalPerformance /> */}
                                        <p
                                            onClick={() => {
                                                navigate(
                                                    '/DashBoardAdmin/ProjectsManage?fonds=1&projectManage=ADD&returnBackoffice=1'
                                                );
                                            }}
                                            className={`${classList.link}`}
                                        >
                                            Ajouter des projets
                                        </p>
                                        <p
                                            onClick={() =>
                                                setAddTransactionToggle(true)
                                            }
                                            className={`${classList.link}`}
                                        >
                                            Ajouter des transactions
                                        </p>
                                        <p
                                            className={`${classList.link}`}
                                            onClick={() =>
                                                setAddBlacklistToggle(true)
                                            }
                                        >
                                            Ajouter des projets à la liste noire
                                        </p>
                                        <p
                                            className={`${classList.link}`}
                                            onClick={() =>
                                                setAddPlatformToggle(true)
                                            }
                                        >
                                            Ajouter des plateformes
                                        </p>
                                    </div>
                                    <div className="grid xl:grid-cols-2 my-3">
                                        <div className="flex justify-center items-center flex-col w-full mx-auto my-3">
                                            <div className="px-5 py-1 flex gap-1 flex-wrap justify-center">
                                                {fondsData?.map((val, key) => (
                                                    <p
                                                        key={key}
                                                        onClick={() => {
                                                            rootState?.setFondsIdBackOffice(
                                                                fondsData?.filter(
                                                                    (fonds) =>
                                                                        fonds.name ===
                                                                        val.name
                                                                )[0].id!
                                                            );
                                                            setFondsQuery(
                                                                fondsData?.filter(
                                                                    (fonds) =>
                                                                        fonds.name ===
                                                                        val.name
                                                                )[0].id!
                                                            );
                                                        }}
                                                        className={`${
                                                            rootState?.fondsIdBackOffice ===
                                                            val.id
                                                                ? 'rounded-md shadow-basic text-titleColor'
                                                                : null
                                                        } cursor-pointer transition-all hover:scale-[1.01] py-1 px-3 font-titleFontFamily`}
                                                    >
                                                        {val.name}
                                                    </p>
                                                ))}
                                            </div>

                                            <div className="mt-5 flex gap-5 items-center">
                                                <div className="p-1 flex gap-1 flex-wrap justify-center">
                                                    {tableArray?.map(
                                                        (val, key) => (
                                                            <p
                                                                key={key}
                                                                onClick={() => {
                                                                    rootState?.setSelectTable(
                                                                        val
                                                                    );
                                                                    setTableQuery(
                                                                        val
                                                                    );
                                                                }}
                                                                className={`${
                                                                    tableQuery ===
                                                                    val
                                                                        ? 'rounded-md bg-secondBackgroundColor text-mainColor shadow-in'
                                                                        : null
                                                                } cursor-pointer transition-all hover:scale-[1.01] py-1 px-3 font-titleFontFamily`}
                                                            >
                                                                {val}
                                                            </p>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <DateManagement />
                                        </div>
                                    </div>

                                    {addPlatformToggle ? (
                                        <AddPlatform
                                            addPlatformToggle={
                                                addPlatformToggle
                                            }
                                            setAddPlatformToggle={
                                                setAddPlatformToggle
                                            }
                                        />
                                    ) : null}

                                    {addBlacklistToggle ? (
                                        <AddBlacklist
                                            addBlacklistToggle={
                                                addBlacklistToggle
                                            }
                                            setAddBlacklistToggle={
                                                setAddBlacklistToggle
                                            }
                                        />
                                    ) : null}
                                    {/* {addProjectToggle ? (
                    <AddProject
                      addProjectToggle={addProjectToggle}
                      setAddProjectToggle={setAddProjectToggle}
                    />
                  ) : null} */}
                                    {addTransactionToggle ? (
                                        <AddTransaction
                                            addTransactionToggle={
                                                addTransactionToggle
                                            }
                                            setAddTransactionToggle={
                                                setAddTransactionToggle
                                            }
                                        />
                                    ) : null}

                                    <div className="">{viewTableFunc()}</div>
                                </div>
                            </WrapComponentDashboardUser>
                        </div>
                    ) : (
                        <Loading />
                    )}
                </React.Fragment>
            </LoadingBackOfficeState.Provider>
        </BackOfficeState.Provider>
    );
};

export default BackOffice;

function DailyShare() {
    const rootState = useContext(RootBlackOfficeState);

    return (
        <React.Fragment>
            {rootState ? (
                <div
                    className={`rounded-xl top-0 max-w-fit right-2 mx-auto cursor-pointer ${
                        rootState.dailyShare ? 'bg-green-500' : 'bg-red-500'
                    }`}
                    onClick={() => {
                        rootState.setdailyShare(!rootState.dailyShare);
                    }}
                >
                    {/* <p className="p-3 font-titleFontFamily">
            {rootState.dailyShare ? (
              <React.Fragment>Daily share</React.Fragment>
            ) : (
              <React.Fragment>Non daily share</React.Fragment>
            )}
          </p> */}
                </div>
            ) : null}
        </React.Fragment>
    );
}

function AddTransaction({
    addTransactionToggle,
    setAddTransactionToggle,
}: {
    addTransactionToggle: boolean;
    setAddTransactionToggle: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
    const rootState = useContext(RootBlackOfficeState);
    const { showError, showSuccess } = useNotificationContext();

    const { data: fondsData } = useGetFondsQuery();

    const { data: platformData } = useGetPlatformsQuery();

    const [loading, setLoading] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        control,
        formState: { isSubmitting, submitCount },
    } = useForm<CreateTransactionDtoForm>({
        defaultValues: {
            fondsId: rootState?.fondsIdBackOffice,
            status: TransactionStatus.VALIDATED,
            type: TypeTransaction.cashProject,
            amount: 0,
            date: new Date(Date.now()).toJSON().split('T')[0],
            initialValueOfShare: 100,
            typeInvestisseurId: TypeInvestisseurIdEnum.normal,
            actualValueOfShareBool: false,
            feesValue: 0.005,
            donationFee: 0,
            entranceFee: 0,
        },
    });

    const { data: sponsorId } = useGetTransactionSponsorIdQuery(
        watch('from') ?? 0,
        {
            skip: watch('type') !== TypeTransaction.userCash || !watch('from'),
        }
    );

    const [addTransaction, {}] = useAddTransactionMutation();

    const onSubmit = async (data: CreateTransactionDtoForm) => {
        setLoading((curr) => !curr);
        const files = data?.files;
        delete data.files;
        if (data.type === TypeTransaction.debtCash) {
            data = {
                ...data,
                interest: stringToFloat(data.interest!),
                from: 0,
            };
        } else if (data.type === TypeTransaction.cashDebt) {
            data = {
                ...data,
                interest: stringToFloat(data.interest!),
                to: 0,
            };
        } else if (
            data.type === TypeTransaction.newParadigmsCash ||
            data.type === TypeTransaction.platformCash
        ) {
            data = {
                ...data,
                from: 0,
            };
        } else if (data.type === TypeTransaction.cashNewParadigms) {
            data = {
                ...data,
                to: 0,
            };
        } else if (data.type === TypeTransaction.userCash) {
            data = {
                ...data,
                initialValueOfShare: stringToFloat(data.initialValueOfShare!),
                typeInvestisseurId: data.typeInvestisseurId!,
                typeShare: data.typeShare || 'cumulative',
            };
        } else if (data.type === TypeTransaction.projectCash) {
            let type = TypeTransaction.projectCash;
            switch (data.typeTransactionProject2Cash) {
                case TypeTransactionProject2Cash.Capital:
                    type = TypeTransaction.ProjectBuying;
                    break;
                case TypeTransactionProject2Cash.Closure:
                    type = TypeTransaction.projectStopCash;
                    break;
                default:
                    break;
            }
            data.type = type;
        }
        let sponsorIdToSend = undefined;
        if (data.sponsored && sponsorId?.sponsorId) {
            sponsorIdToSend = sponsorId?.sponsorId;
        }

        await addTransaction({
            ...data,
            sponsorId: sponsorIdToSend,
            fondsId: stringToInt(data.fondsId),
            date: transformDate(data.date),
            to: data.to,
            from: data.from,
            amount: stringToFloat(data.amount),
            status: TransactionStatus.VALIDATED,
            dateInterest: data.dateInterest
                ? transformDate(data.dateInterest)
                : undefined,
        })
            .unwrap()
            .then(async (res) => {
                try {
                    if (files) {
                        for (let i = 0; i < files.length; i++) {
                            let data: UploadDocumentTransactionDto = {
                                transactionId: (res as TransactionDto).id,
                                file: files[i],
                                fileName: files[i].name,
                                // type:(res.payload as TransactionDto)?.type === TypeTransaction.ProjectBuying ? TypeDocumentTransaction. ,
                            };
                            await uploadDocumentTransaction(data)
                                .then((res) => {
                                    if (res.sucess) {
                                        showSuccess(
                                            'Created',
                                            'File uploaded successfully'
                                        );
                                    } else {
                                        showError(
                                            'Error',
                                            `Error uploading file ${data.fileName}: ${res.message}`
                                        );
                                    }
                                })
                                .catch((res) => {
                                    showError(
                                        'Error',
                                        `Error uploading file ${data.fileName}: ${res.message}`
                                    );
                                });
                        }
                    }
                } catch (error) {
                    showError('Error', 'Error uploading files');

                    setLoading((curr) => !curr);
                    return;
                }
                showSuccess('Created', 'Transaction creating successfully');
                setLoading((curr) => !curr);
            })
            .catch((err) => {
                showError('Error', 'Error creating transaction');
            });
    };

    const { data: transactionUser } = useGetTransactionsUserQuery(
        watch('to') ?? 0,
        {
            skip:
                watch('type') !== TypeTransaction.cashUser ||
                watch('to') === undefined
                    ? true
                    : false,
        }
    );

    const transactionUserSelect = (): IOptionValues[] => {
        if (transactionUser) {
            return transactionUser
                .filter((t) => t.fondsId === watch('fondsId'))
                .map((t) => {
                    return {
                        label: `date: ${formatDate(new Date(t.date))} montant: ${t.amount}`,
                        value: t.id,
                    };
                });
        } else {
            return [];
        }
    };

    useEffect(() => {
        setSearchFrom('');
        setSearchTo('');
        reset({
            ...watch(),

            from: stringToInt(selectFunc(watch('type'), true)[0]?.value),
            to: stringToInt(selectFunc(watch('type'), false)[0]?.value),
        });
        if (watch('type') === TypeTransaction.cashProject) {
            setValue(
                'typeTransactionProject2Cash',
                TypeTransactionProject2Cash.Coupon
            );
        }
        if (watch('type') === TypeTransaction.userCash) {
            setValue('typeShare', 'cumulative');
            setValue(
                'dateInterest',
                new Date(watch('date'))?.toJSON().split('T')[0]
            );
        }
    }, [watch('type'), watch('fondsId')]);

    const [searchFrom, setSearchFrom] = useState<string>('');
    const [searchTo, setSearchTo] = useState<string>('');

    let { data: cashData } = useGetCashIdsQuery();
    let { data: projectData } = useGetProjectsSearchQuery(
        {
            fondsId: getValues('fondsId') ?? 0,
            search:
                watch('type') === TypeTransaction.cashProject
                    ? searchTo
                    : searchFrom,
        },
        {
            skip:
                watch('type') === TypeTransaction.cashProject ||
                watch('type') === TypeTransaction.projectCash
                    ? false
                    : true,
        }
    );

    let { data: usersData } = useGetUsersSearchQuery(
        watch('type') === TypeTransaction.userCash ? searchFrom : searchTo,
        {
            skip:
                watch('type') === TypeTransaction.userCash ||
                watch('type') === TypeTransaction.cashUser ||
                watch('type') === TypeTransaction.newParadigmsUser
                    ? false
                    : true,
        }
    );

    const selectFunc = useCallback(
        (type: TypeTransaction, from: boolean): IOptionValues[] => {
            let cash: IOptionValues[] =
                cashData
                    ?.filter((c) => c.fondsId === getValues('fondsId'))
                    .map((val) => {
                        return {
                            label: 'Cash',
                            value: val.id,
                        };
                    }) ?? [];

            let user: IOptionValues[] =
                usersData?.map((user, key) => {
                    return {
                        label: `${user.firstName} ${user.lastName}`,
                        value: user.id,
                    };
                }) ?? [];

            let projects: IOptionValues[] =
                projectData?.map((project, key) => {
                    return {
                        label: project.name,
                        value: project.id,
                    };
                }) ?? [];

            let newParadimgs: IOptionValues[] = [
                { label: 'New Paradimgs', value: 1 },
            ];
            let debt: IOptionValues[] = [{ value: 1, label: 'Dette' }];

            let plaform: IOptionValues[] =
                platformData?.map((platform, key) => {
                    return {
                        label: platform.name,
                        value: platform.id,
                    };
                }) ?? [];

            switch (type) {
                case TypeTransaction.cashProject:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(cash[0].value as string));
                        return cash;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(projects[0].value as string));
                        return projects;
                    }
                case TypeTransaction.cashUser:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(cash[0].value as string));
                        return cash;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(user[0].value as string));

                        return user;
                    }
                case TypeTransaction.projectCash:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(projects[0].value as string));
                        return projects;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(cash[0].value as string));

                        return cash;
                    }
                case TypeTransaction.userCash:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(user[0].value as string));
                        return user;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(cash[0].value as string));

                        return cash;
                    }
                case TypeTransaction.cashNewParadigms:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(cash[0].value as string));
                        return cash;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(newParadimgs[0].value as string));
                        return newParadimgs;
                    }

                case TypeTransaction.newParadigmsCash:
                    if (from) {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(newParadimgs[0].value as string));
                        return newParadimgs;
                    } else {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(cash[0].value as string));
                        return cash;
                    }
                case TypeTransaction.newParadigmsUser:
                    if (from) {
                        return newParadimgs;
                    } else {
                        return user;
                    }
                case TypeTransaction.userNewParadigms:
                    if (from) {
                        return user;
                    } else {
                        return newParadimgs;
                    }
                case TypeTransaction.cashDebt:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(cash[0].value as string));
                        return cash;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(debt[0].value as string));
                        return debt;
                    }
                case TypeTransaction.debtCash:
                    if (from) {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(debt[0].value as string));
                        return debt;
                    } else {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(cash[0].value as string));
                        return cash;
                    }
                case TypeTransaction.ProjectBuying:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(projects[0].value as string));
                        return projects;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(cash[0].value as string));
                        return cash;
                    }
                case TypeTransaction.projectStopCash:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(projects[0].value as string));
                        return projects;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(cash[0].value as string));
                        return cash;
                    }
                case TypeTransaction.platformCash:
                    if (from) {
                        // if (getValues("from") === undefined)
                        //   setValue("from", parseInt(plaform[0].value as string));
                        return plaform;
                    } else {
                        // if (getValues("to") === undefined)
                        //   setValue("to", parseInt(cash[0].value as string));
                        return cash;
                    }
            }
        },
        [watch('fondsId'), cashData, usersData, projectData, watch('type')]
    );

    const { data: typeInvestisseurs } = useGetTypeInvestisseursFormQuery();

    const [expanded, setExpanded] = useState<boolean>(true);

    return (
        <PopUp
            open={addTransactionToggle}
            setOpen={setAddTransactionToggle}
            buttonBoolean={false}
            expand={expanded}
            setExpand={setExpanded}
            submitItemName={'Ajouter'}
            onClickSubmit={() => {
                handleSubmit(onSubmit);
            }}
            title={() => {
                return (
                    <>
                        <h3 className="text-2xl font-semibold leading-6 text-gray-900">
                            Ajouter une transaction
                        </h3>
                    </>
                );
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp"
                style={{ width: `${expanded ? '90%' : '80%'}` }}
            >
                <div
                    className={`w-full mx-auto ${
                        expanded
                            ? 'sm:flex sm:gap-2 sm:justify-between sm:items-center '
                            : ''
                    }`}
                >
                    <div className={`w-full ${expanded ? 'sm:w-10/12 ' : ''}`}>
                        <SelectComponent
                            register={register}
                            value={'fondsId'}
                            container={true}
                            optionValues={
                                fondsData?.map((val) => {
                                    return {
                                        value: val.id,
                                        label: val.name,
                                    };
                                }) ?? []
                            }
                            control={control}
                        >
                            <h3>
                                Fonds <span className="required">*</span>
                            </h3>
                        </SelectComponent>
                        <SelectComponent
                            register={register}
                            value={'type'}
                            container={true}
                            watch={watch}
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                            useParamBoolean={true}
                            submitCount={submitCount}
                            optionValues={[
                                TypeTransaction.userCash,
                                TypeTransaction.cashUser,
                                TypeTransaction.cashProject,
                                TypeTransaction.projectCash,
                                TypeTransaction.cashNewParadigms,
                                TypeTransaction.newParadigmsCash,
                                TypeTransaction.newParadigmsUser,
                                TypeTransaction.userNewParadigms,
                                TypeTransaction.cashDebt,
                                TypeTransaction.debtCash,
                                TypeTransaction.ProjectBuying,
                                TypeTransaction.platformCash,
                            ].map((val) => {
                                return {
                                    value: val,
                                    label: val,
                                };
                            })}
                        >
                            <h3>
                                Type de transaction{' '}
                                <span className="required">*</span>
                            </h3>
                        </SelectComponent>

                        {watch('type') === TypeTransaction.projectCash ? (
                            <div className="flex items-center gap-2 justify-center">
                                <RadioComponent
                                    register={register}
                                    watch={watch}
                                    name={'typeTransactionProject2Cash'}
                                    values={[
                                        {
                                            value: TypeTransactionProject2Cash.Coupon,
                                            label: TypeTransactionProject2Cash.Coupon,
                                        },
                                        {
                                            value: TypeTransactionProject2Cash.Capital,
                                            label: TypeTransactionProject2Cash.Capital,
                                        },
                                        // {
                                        //   value: TypeTransactionProject2Cash.Closure,
                                        //   label: TypeTransactionProject2Cash.Closure,
                                        // },
                                    ]}
                                ></RadioComponent>
                            </div>
                        ) : null}

                        <SelectComponent
                            register={register}
                            value={'from'}
                            setSearch={setSearchFrom}
                            container={true}
                            optionValues={selectFunc(watch('type'), true)}
                            control={control}
                            watch={watch}
                            getValues={getValues}
                            setValue={setValue}
                            useParamBoolean={true}
                            submitCount={submitCount}
                        >
                            <h3>
                                Origine <span className="required">*</span>
                            </h3>
                        </SelectComponent>

                        <SelectComponent
                            register={register}
                            value={'to'}
                            container={true}
                            setSearch={setSearchTo}
                            optionValues={selectFunc(watch('type'), false)}
                            watch={watch}
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                            submitCount={submitCount}
                            useParamBoolean={true}
                        >
                            <h3>
                                Pour <span className="required">*</span>
                            </h3>
                        </SelectComponent>

                        {watch('type') === TypeTransaction.cashUser ? (
                            <SelectComponent
                                register={register}
                                value={'transactionUserId'}
                                container={true}
                                optionValues={transactionUserSelect()}
                                watch={watch}
                                control={control}
                                getValues={getValues}
                                setValue={setValue}
                                submitCount={submitCount}
                                useParamBoolean={true}
                            >
                                <h3>
                                    Transaction{' '}
                                    <span className="required">*</span>
                                </h3>
                            </SelectComponent>
                        ) : null}
                        {watch('type') !== TypeTransaction.cashUser ? (
                            <>
                                <InputComponent
                                    register={register}
                                    value={'amount'}
                                    type={'number'}
                                >
                                    <h3>
                                        Montant{' '}
                                        <span className="required">*</span>
                                    </h3>
                                </InputComponent>
                            </>
                        ) : (
                            <>
                                <CheckboxComponent
                                    register={register}
                                    watch={watch}
                                    values={[
                                        {
                                            label: 'Sortie totale du potefeuille',
                                            name: 'emptiedTransaction',
                                        },
                                    ]}
                                >
                                    {/* <h3>
                Valeur actuelle de la part <span className="required">*</span>
              </h3> */}
                                </CheckboxComponent>
                                {!watch('emptiedTransaction') ? (
                                    <InputComponent
                                        register={register}
                                        value={'amount'}
                                        type={'number'}
                                    >
                                        <h3>
                                            Montant{' '}
                                            <span className="required">*</span>
                                        </h3>
                                    </InputComponent>
                                ) : null}
                            </>
                        )}

                        {watch('type') === TypeTransaction.debtCash ? (
                            <InputComponent
                                register={register}
                                value={'interest'}
                                type={'number'}
                            >
                                <h3>
                                    Interêts <span className="required">*</span>
                                </h3>
                            </InputComponent>
                        ) : null}
                        {watch('type') === TypeTransaction.userCash ? (
                            <>
                                {!watch('actualValueOfShareBool') ? (
                                    <InputComponent
                                        register={register}
                                        value={'initialValueOfShare'}
                                        type={'number'}
                                    >
                                        <h3>
                                            Valeur initial de la part{' '}
                                            <span className="required">*</span>
                                        </h3>
                                    </InputComponent>
                                ) : null}

                                <CheckboxComponent
                                    register={register}
                                    watch={watch}
                                    values={[
                                        {
                                            label: 'Valeur actuelle de la part ?',
                                            name: 'actualValueOfShareBool',
                                        },
                                    ]}
                                >
                                    {/* <h3>
                Valeur actuelle de la part <span className="required">*</span>
              </h3> */}
                                </CheckboxComponent>
                            </>
                        ) : null}
                        {watch('type') === TypeTransaction.userCash ? (
                            <>
                                <RadioComponent
                                    register={register}
                                    watch={watch}
                                    name={'typeShare'}
                                    className={{
                                        container: 'grid grid-cols-2 gap-2',
                                    }}
                                    values={[
                                        {
                                            value: 'cumulative',
                                            label: 'Capitalise',
                                        },
                                        {
                                            value: 'distribution',
                                            label: 'Distribue',
                                        },
                                    ]}
                                >
                                    <h3 className=" col-span-2 text-start">
                                        Type de titre{' '}
                                        <span className="required">*</span>
                                    </h3>
                                </RadioComponent>

                                <SelectComponent
                                    register={register}
                                    value={'typeInvestisseurId'}
                                    control={control}
                                    container={true}
                                    optionValues={
                                        typeInvestisseurs?.map((val) => {
                                            return {
                                                value: val.id,
                                                label: val.name,
                                            };
                                        }) || []
                                    }
                                >
                                    <h3>
                                        Type investisseur{' '}
                                        <span className="required">*</span>
                                    </h3>
                                </SelectComponent>
                                {watch('typeInvestisseurId') !==
                                TypeInvestisseurIdEnum.noFees ? (
                                    <InputComponent
                                        register={register}
                                        value={'feesValue'}
                                        type={'number'}
                                    >
                                        <h3>
                                            Valeur des frais{' '}
                                            <span className="required">*</span>
                                        </h3>
                                    </InputComponent>
                                ) : null}
                                <InputComponent
                                    register={register}
                                    value={'entranceFee'}
                                    type={'number'}
                                >
                                    <h3>
                                        Frais d'entrée{' '}
                                        <span className="required">*</span>
                                    </h3>
                                </InputComponent>
                                <InputComponent
                                    register={register}
                                    value={'donationFee'}
                                    type={'number'}
                                >
                                    <h3>
                                        Donation frais{' '}
                                        <span className="required">*</span>
                                    </h3>
                                </InputComponent>
                                {sponsorId ? (
                                    <CheckboxComponent
                                        register={register}
                                        watch={watch}
                                        values={[
                                            {
                                                label: 'Transaction parrainé?',
                                                name: 'sponsored',
                                            },
                                        ]}
                                    />
                                ) : null}
                                <InputComponent
                                    register={register}
                                    value={'dateInterest'}
                                    type={'date'}
                                    required={false}
                                >
                                    <h3>Date émission des titres</h3>
                                </InputComponent>
                            </>
                        ) : null}
                        <InputComponent
                            register={register}
                            value={'date'}
                            type={'date'}
                        >
                            <h3>
                                Date <span className="required">*</span>
                            </h3>
                        </InputComponent>
                        {watch('type') === TypeTransaction.cashProject ? (
                            <InputComponent
                                register={register}
                                value={'dateInterest'}
                                type={'date'}
                                required={false}
                            >
                                <h3>Date début des intérêts</h3>
                            </InputComponent>
                        ) : null}
                        <InputComponent
                            register={register}
                            value={'comment'}
                            required={false}
                        >
                            <h3>Commentaire</h3>
                        </InputComponent>
                    </div>
                    {expanded ? (
                        <div className="w-full">
                            <UploaderMulti
                                register={register}
                                value={'files'}
                                watch={watch}
                                setValue={setValue}
                                control={control}
                                size={10}
                                button={false}
                            >
                                <h3 className="text-2xl mb-5">
                                    Ajouter des documents
                                </h3>
                            </UploaderMulti>
                            <SelectComponent
                                register={register}
                                value={'status'}
                                container={true}
                                control={control}
                                getValues={getValues}
                                setValue={setValue}
                                watch={watch}
                                optionValues={Object.values(
                                    TransactionStatus
                                ).map((v: string) => {
                                    return {
                                        value: v,
                                        label: v,
                                    };
                                })}
                            >
                                <h3>
                                    Status <span className="required">*</span>
                                </h3>
                            </SelectComponent>
                        </div>
                    ) : null}
                </div>
                <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
                    <WhiteButton
                        onClick={() => {
                            setAddTransactionToggle(false);
                        }}
                        className="w-full"
                    >
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit" className="w-full">
                        {loading !== undefined && loading === true ? (
                            <Loading size={4} />
                        ) : (
                            'Add'
                        )}
                    </PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
}

function AddPlatform({
    addPlatformToggle,
    setAddPlatformToggle,
}: {
    addPlatformToggle: boolean;
    setAddPlatformToggle: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
    const { register, handleSubmit, watch, control, setValue, getValues } =
        useForm<FormCreatePlatform>({
            defaultValues: {
                documents: [],
            },
        });

    const { data: fondsData } = useGetFondsQuery();

    const [
        addPlatform,
        { isLoading: isLoadingEdit, isSuccess: isSuccessEdit },
    ] = useAddPlatformMutation();

    const { refetch } = useGetPlatformsQuery();

    const { showError, showSuccess } = useNotificationContext();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmit = async (data: FormCreatePlatform) => {
        await addPlatform(data)
            .unwrap()
            .then(async (res: PlatformDto) => {
                if (data?.documents?.length > 0) {
                    for (let i = 0; i < data?.documents?.length; i++) {
                        const document = data.documents[i];

                        let dataFile: FormCreateDocumentPlatformRequest = {
                            file: document.file[0] as File,
                            type: document.type,
                            platformId: res.id,
                            category: CategoryDocumentPlatform.public,
                            platformName: data.name,
                            fileName: document.file[0].name,
                            comment: document?.comment,
                        };

                        await uploadDocumentPlatform(dataFile)
                            .then((res) => {
                                if (res.sucess) {
                                    showSuccess(
                                        'Envoyé',
                                        'Le document a été envoyé avec le succès'
                                    );
                                } else {
                                    showError(
                                        'Erreur',
                                        `Erreur lors de l'envoie de ${dataFile.fileName}`
                                    );
                                }
                            })
                            .catch((res) => {
                                showError(
                                    'Erreur',
                                    `Erreur lors de l'envoie de ${dataFile.fileName}`
                                );
                            });
                    }
                }

                showSuccess('Created', 'Platform added successfully');
            })
            .catch((err) => {
                showError('Error', 'Error adding this platform');
            });

        setIsLoading((curr) => !curr);
    };

    const {
        field: { onChange: onChangeComment, value: comment },
    } = useController<FormCreatePlatform>({
        name: 'description',
        control: control,
    });

    return (
        <PopUp
            open={addPlatformToggle}
            setOpen={setAddPlatformToggle}
            submitItemName={'Ajouter'}
            buttonBoolean={false}
            width="w-full md:w-10/12"
            onClickSubmit={() => {
                handleSubmit(onSubmit);
            }}
            title={() => {
                return (
                    <>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Ajouter une plateforme
                        </h3>
                    </>
                );
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp text-start"
            >
                <InputComponent register={register} value={'name'}>
                    <LabelComponentForm>
                        Nom de la plateforme{' '}
                        <span className="text-red-500">*</span>
                    </LabelComponentForm>
                </InputComponent>

                <SelectMultiComponent
                    register={register}
                    setValue={setValue}
                    value={'fondsIds'}
                    optionValues={
                        fondsData
                            ? fondsData?.map((fond) => {
                                  return { value: fond.id, label: fond.name };
                              })
                            : []
                    }
                    control={control}
                    optionsRender={(option) => option.label}
                    valueRender={(option) => option.value}
                >
                    <LabelComponentForm>
                        Fonds <span className="text-red-500">*</span>
                    </LabelComponentForm>
                </SelectMultiComponent>

                <TextEditorComponent
                    content={comment as any as string}
                    setContent={onChangeComment}
                    className=" w-full"
                >
                    <LabelComponentForm>Description</LabelComponentForm>
                </TextEditorComponent>

                <TitleComponentForm className="!w-full mt-3">
                    Document
                </TitleComponentForm>

                <FieldArray
                    control={control}
                    className="w-full"
                    name="documents"
                >
                    {({ fields, append, remove }) => (
                        <>
                            <div className="flex flex-col w-full mb-2">
                                {fields.map((field, index) => (
                                    <div key={field.id} className={``}>
                                        <SelectComponent
                                            register={register}
                                            control={control}
                                            value={`documents.${index}.type`}
                                            getValues={getValues}
                                            setValue={setValue}
                                            optionValues={Object.values(
                                                TypeDocumentPlatform
                                            ).map((v) => {
                                                return {
                                                    label: v,
                                                    value: v,
                                                };
                                            })}
                                        >
                                            <h3 className=" font-semibold mt-3">
                                                Choisisser le type du document
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </SelectComponent>

                                        <UploaderSingle
                                            register={register}
                                            value={`documents.${index}.file`}
                                            watch={watch}
                                            setValue={setValue}
                                            loading={isLoading}
                                            button={false}
                                        ></UploaderSingle>
                                        <div className=" w-full flex justify-center items-center">
                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className=" w-full flex justify-center items-center">
                                <PrimaryButton
                                    className=" mx-auto mb-2"
                                    onClick={() =>
                                        append({
                                            // projectId: project.current?.id!,
                                        } as any)
                                    }
                                >
                                    Ajouter un document
                                </PrimaryButton>
                            </div>
                        </>
                    )}
                </FieldArray>

                <div className="mt-5 w-full sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 text-center">
                    <WhiteButton
                        onClick={() => {
                            setAddPlatformToggle(false);
                        }}
                    >
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit">Add</PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
}

function AddBlacklist({
    addBlacklistToggle,
    setAddBlacklistToggle,
}: {
    addBlacklistToggle: boolean;
    setAddBlacklistToggle: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        control,
        getValues,
    } = useForm<CreateBlackListDtoForm>();

    const { showError, showSuccess } = useNotificationContext();

    const { data: fondsData } = useGetFondsQuery();

    const rootState = useContext(RootBlackOfficeState);
    const [search, setSearch] = useState('');

    let { data: projectData } = useGetProjectsSearchQuery(
        {
            fondsId: rootState?.fondsIdBackOffice ?? 0,
            search: search,
        },
        {
            skip: !rootState?.fondsIdBackOffice,
        }
    );

    const [
        addBlackList,
        { isLoading: isLoadingEdit, isSuccess: isSuccessEdit },
    ] = useAddBlackListMutation();

    const onSubmit = async (data: CreateBlackListDtoForm) => {
        await addBlackList({
            ...data,
            comment: data.comment ?? undefined,
            fondsId: data.fondsId,
            dates: data.dates.map((dateb) => {
                return {
                    date: transformDate(dateb.date),
                    deletedAt: dateb.deletedAt
                        ? transformDate(dateb.deletedAt)
                        : undefined,
                };
            }),
            projectId: data.projectId,
            interests: data.interests.map((pi, index) => {
                if (index === 0) {
                    return {
                        value: stringToFloat(pi.value),
                        fondsId: stringToFloat(data.fondsId),
                        // projectId: stringToInt(data.id),
                        date: transformDate(data.dates?.[0].date),
                    };
                }
                return {
                    value: stringToFloat(pi.value),
                    fondsId: stringToInt(data.fondsId),
                    // projectId: stringToInt(data.id),
                    date: transformDate(pi.date),
                };
            }),
        })
            .unwrap()
            .then((res) => {
                showSuccess(
                    'Created',
                    'Project added to the blacklist successfully'
                );
            })
            .catch((err) => {
                showError('Error', 'Error adding this project in blacklist');
            });
    };

    const setDefautlValues = (): void => {
        reset({
            fondsId: rootState?.fondsIdBackOffice,
            dates: [
                {
                    date: new Date(Date.now()).toJSON().split('T')[0] as any,
                    deletedAt: undefined,
                },
            ],
            interests: [
                {
                    date: new Date(Date.now()).toJSON().split('T')[0],
                    value: undefined,
                    fondsId: rootState?.fondsIdBackOffice,
                },
            ],
        });
        // setValue("ProjetId", array[0]);
    };

    useEffect(() => {
        if (!isEmpty(fondsData) && rootState?.fondsIdBackOffice) {
            setDefautlValues();
        }
    }, [fondsData, rootState?.fondsIdBackOffice]);

    const updateDateEnd = useCallback(
        debounce(() => {
            if (getValues(`dates.0.date`) != null) {
                setValue(
                    `interests`,
                    getValues(`interests`).map((val, index) => {
                        if (index === 0) {
                            return {
                                ...val,
                                date: transformDate(
                                    new Date(getValues(`dates.0.date`))
                                )
                                    .toJSON()
                                    .split('T')[0],
                            };
                        } else {
                            return val;
                        }
                    })
                );
            }
        }),
        []
    );

    useEffect(() => {
        updateDateEnd();
    }, [watch(`dates.0.date`)]);

    return (
        <PopUp
            open={addBlacklistToggle}
            setOpen={setAddBlacklistToggle}
            submitItemName={'Ajouter'}
            buttonBoolean={false}
            onClickSubmit={() => {
                onSubmit(watch());
            }}
            title={() => {
                return (
                    <>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Ajouter un project à la black list
                        </h3>
                    </>
                );
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp"
            >
                <SelectComponent
                    register={register}
                    value={'fondsId'}
                    container={true}
                    optionValues={
                        fondsData?.map((val) => {
                            return {
                                value: val.id,
                                label: val.name,
                            };
                        }) ?? []
                    }
                    control={control}
                >
                    <h3>
                        Fonds <span className="required">*</span>
                    </h3>
                </SelectComponent>

                <SelectComponent
                    register={register}
                    control={control}
                    setSearch={setSearch}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                    value={'projectId'}
                    container={true}
                    optionValues={
                        projectData?.map((project, key) => {
                            return {
                                label: project.name,
                                value: project.id,
                            };
                        }) ?? []
                    }
                >
                    <h3>
                        Projet <span className="required">*</span>
                    </h3>
                </SelectComponent>
                <FieldArray control={control} className="w-full" name="dates">
                    {({ fields, append, remove }) => (
                        <>
                            <div className="flex flex-col w-full space-y-2 mb-2">
                                {fields.map((field, index) => (
                                    <div
                                        key={field.id}
                                        className={`grid ${
                                            index > 0
                                                ? 'grid-cols-[1fr,1fr,auto]'
                                                : 'grid-cols-2'
                                        }  w-full gap-x-2 relative`}
                                    >
                                        <InputComponent
                                            register={register}
                                            type="date"
                                            value={`dates.${index}.date`}
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                Date{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </InputComponent>

                                        <InputComponent
                                            register={register}
                                            type="date"
                                            required={false}
                                            value={`dates.${index}.deletedAt`}
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                DeletedAt
                                            </h3>
                                        </InputComponent>
                                        {index > 0 ? (
                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                            <PrimaryButton
                                className=" mx-auto mb-2"
                                onClick={() =>
                                    append({
                                        date: new Date()
                                            .toJSON()
                                            ?.split('T')[0],
                                        deletedAt: undefined,
                                        // projectId: project.current?.id!,
                                    } as any)
                                }
                            >
                                Ajouter
                            </PrimaryButton>
                        </>
                    )}
                </FieldArray>

                <h3 className="text-center my-2 mx-auto">
                    List des intérêt en fonction de la date
                </h3>
                <FieldArray control={control} name="interests">
                    {({ fields, append, remove }) => (
                        <>
                            <div className="flex flex-col w-full space-y-2 mb-2">
                                {fields.map((field, index) => (
                                    <div
                                        key={field.id}
                                        className={`grid ${
                                            index > 0
                                                ? 'grid-cols-[1fr,1fr,auto]'
                                                : 'grid-cols-2'
                                        }  w-full gap-x-2 relative`}
                                    >
                                        <InputComponent
                                            register={register}
                                            type="date"
                                            value={`interests.${index}.date`}
                                            cursorNotAllowed={
                                                index === 0 ? true : undefined
                                            }
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                Date{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </InputComponent>

                                        <InputComponent
                                            register={register}
                                            type="number"
                                            value={`interests.${index}.value`}
                                            // className={{ input: "row-span-1 col-span-1" }}
                                        >
                                            <h3 className="text-left">
                                                Intérêt{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </InputComponent>
                                        {index > 0 ? (
                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                            />
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                            <PrimaryButton
                                className=" mx-auto mb-2"
                                onClick={() =>
                                    append({
                                        date: new Date()
                                            .toJSON()
                                            ?.split('T')[0],
                                        value: 0,
                                        fondsId: watch('fondsId')!,
                                        // projectId: project.current?.id!,
                                    })
                                }
                            >
                                Ajouter
                            </PrimaryButton>
                        </>
                    )}
                </FieldArray>

                <TextAreaComponent
                    register={register}
                    value={'comment'}
                    required={false}
                >
                    <h3>Commentaire</h3>
                </TextAreaComponent>
                <div className="mt-5 w-full sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 text-center">
                    <WhiteButton
                        onClick={() => {
                            setAddBlacklistToggle(false);
                        }}
                    >
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit">Add</PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
}
