import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryHandleError } from '../index.rtk';


const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}docuSealApi`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export const docuSealApi = createApi({
    reducerPath: 'docuSealApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: ['DocuSeal'],
    endpoints: (builder) => ({
        getSubmission: builder.mutation<any, number>({
            query: (id) => `/submission/${id}`,
        }),
        getTemplate: builder.mutation<any, number>({
            query: (id) => `/template/${id}`,
        }),
        generateTemplateId: builder.mutation<any, any>({
            query: (payload) => ({
                url: `/templates/pdf`,
                method: 'POST',
                body: payload,
            }),
        }),
        sendDocForSignature: builder.mutation<any, any>({
            query: (payload) => ({
                url: `/submissions`,
                method: 'POST',
                body: payload,
            }),
        }),
    }),
});

export const { useGetSubmissionMutation, useGenerateTemplateIdMutation, useSendDocForSignatureMutation,
    useGetTemplateMutation } = docuSealApi;
