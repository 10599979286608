import React, { useEffect } from "react";
import ArticlesAll from "../components/Articles/ArticlesAll";
import FirstFondPage from "../components/commun/FirstFondPage";
import Footer from "../components/commun/Footer";
import Navigation from "../components/commun/Navigation";
import { pageHeader } from "../types/commun";
import coverBackground from "./../assets/articles.jpg";

const Articles = () => {
  const NewsList: pageHeader = {
    coverBackground: coverBackground,
    coverImg: "./img/articles.jpg",
    classN: "Articles",
    h1_: "Articles",
  };

  return (
    <React.Fragment>
      <Navigation bol_nosfonds={false} bol_transparent={true} />
      <FirstFondPage pageHeader={NewsList} />
      <ArticlesAll />
      <Footer />
    </React.Fragment>
  );
};

export default Articles;
