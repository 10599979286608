import { DisplayDocumentsFormInvestisor } from '@components/commun/DisplayDocuments/DisplayDocumentsFormInvestisor';
import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { situationProfessionelleListe } from '../../../data/FormulaireInvestisseur';
import { useFetchTokenQuery } from '../../../redux/features/tokenSlice';
import { useGetUserDocumentsQuery } from '../../../redux/features/userSlice';
import {
    CreateInvestAccountAndUpdateUserInfoDto,
    DocumentUserName,
    DocumentUserType,
    FormUpdateUserInvestAccountDto,
    StateStatus,
} from '../../../types/user';
import { PrimaryButton } from '../../commun/Buttons';
import useSidedDocument from '../../commun/CustomHook/useSidedDocument';
import DisplayDocumentValidation from '../../commun/DisplayDocuments/DisplayDocumentValidation';
import AdressCompletion from '../../commun/formComponent/AdressCompletion';
import HeadlessRadioComponent from '../../commun/formComponent/HeadlessRadioComponent';
import InputComponent from '../../commun/formComponent/InputComponent';
import RadioComponent from '../../commun/formComponent/RadioComponent';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import UploaderSingle from '../../commun/formComponent/UploaderSingle';
import { EtapeForm } from '../../Context/AppContext';
import { TitleDocumentUser } from './FormInvest/DocumentsIdentity1';

export interface FormUpdateUserInfo {
    userInfoInvestAccount: FormUpdateUserInvestAccountDto;
    userId: number;
}

interface Props {
    formState: UseFormReturn<
        FormUpdateUserInfo | CreateInvestAccountAndUpdateUserInfoDto
    >;
    children?: React.ReactNode;
    className?: string;
}
const UpdateUserInfo: React.FC<Props> = ({
    formState,
    children,
    className,
}) => {
    const [updateId, setUpdateId] = useState(true);

    const { data: userData } = useFetchTokenQuery();

    const {
        register,
        setValue,
        watch,
        control,
        formState: { dirtyFields },
    } = formState;

    const { refetch } = useGetUserDocumentsQuery(userData?.id ?? 0, {
        skip: !userData,
    });

    useEffect(() => {
        refetch();
    }, [refetch]);

    return (
        <div className={`${className ? className : null}`}>
            {children}
            <TitleComponentForm
                className="grid !w-full items-center mt-3 grid-cols-[1fr,auto] cursor-pointer"
                onClick={() => setUpdateId(!updateId)}
            >
                <div className="">
                    Mettez à jour vos documents d'identités si nécessaire
                </div>
                <div className="  justify-self-end pr-2">
                    <FontAwesomeIcon
                        icon={faArrowRight}
                        className={` transition-all ${
                            updateId ? 'transform rotate-90' : ''
                        }`}
                    />
                </div>
            </TitleComponentForm>

            <UpdateDocumentIdentity1 formState={formState} show={updateId} />
            {/* <UpdateDocumentIdentity2
        {...{ register, setValue, control, watch, dirtyFields }}
        show={updateId}
      /> */}

            <AdressCompletion
                control={control}
                register={register}
                value="userInfoInvestAccount.adress"
            >
                <LabelComponentForm>
                    Adresse actuelle <span className="required">*</span>
                </LabelComponentForm>
            </AdressCompletion>
            {dirtyFields.userInfoInvestAccount?.adress ? (
                <>
                    <UpdateDocumentAddress formState={formState} />
                </>
            ) : null}
            <LabelComponentForm>
                Numéro de téléphone <span className="required">*</span>
            </LabelComponentForm>
            <PhoneInput
                placeholder="Entrer votre numéro de téléphone"
                className="w-full border outline-none border-gray-300 rounded-md p-2"
                required={true}
                // value={value}
                // onChange={setValue}

                onChange={(e: any) =>
                    setValue('userInfoInvestAccount.phone', e)
                }
                value={watch('userInfoInvestAccount.phone')}
            />
            <SelectComponent
                register={register}
                value={'userInfoInvestAccount.professionalSituation'}
                control={control}
                optionValues={situationProfessionelleListe.map((v) => {
                    return {
                        value: v,
                        label: v,
                    };
                })}
            >
                <LabelComponentForm>
                    Votre situation professionnelle{' '}
                    <span className="required">*</span>
                </LabelComponentForm>
            </SelectComponent>

            <RadioComponent
                register={register}
                watch={watch}
                name="userInfoInvestAccount.usCitizen"
                values={[
                    {
                        value: 'true',
                        label: 'Oui',
                    },
                    {
                        value: 'false',
                        label: 'Non',
                    },
                ]}
            >
                <h3>
                    Avez-vous le statut de contribuable "US Person" ?{' '}
                    <span className="required">*</span>
                </h3>
            </RadioComponent>
            {watch('userInfoInvestAccount.usCitizen') == 'true' ? (
                <p>
                    Vous ne pouvez malheureusement pas investir dans nos fonds.
                </p>
            ) : null}

            <RadioComponent
                register={register}
                watch={watch}
                name="userInfoInvestAccount.politicalyExposed"
                values={[
                    {
                        value: 'true',
                        label: 'Oui',
                    },
                    {
                        value: 'false',
                        label: 'Non',
                    },
                ]}
            >
                <>
                    <LabelComponentForm>
                        Êtes-vous une personne politiquement exposée ?{' '}
                        <span className="required">*</span>
                    </LabelComponentForm>
                    <p className=" italic text-gray-500">
                        Une personne exerçant ou ayant exercé une haute fonction
                        publique, ou étroitement associée à une telle personne.
                    </p>
                </>
            </RadioComponent>
            <InputComponent
                register={register}
                required={
                    watch('userInfoInvestAccount.politicalyExposed') == 'true'
                        ? true
                        : false
                }
                value="userInfoInvestAccount.politicalyExposedDetails"
            >
                <LabelComponentForm
                    className={` !text-base !font-mainFontFamily !font-normal ${
                        watch('userInfoInvestAccount.politicalyExposed') ===
                        'true'
                            ? ''
                            : 'text-gray-400'
                    }`}
                >
                    Précisez{' '}
                    {watch('userInfoInvestAccount.politicalyExposed') ===
                    'true' ? (
                        <span className="required">*</span>
                    ) : (
                        ''
                    )}
                </LabelComponentForm>
            </InputComponent>
        </div>
    );
};

export default UpdateUserInfo;

function UpdateDocumentAddress({
    formState,
}: {
    formState: UseFormReturn<
        FormUpdateUserInfo | CreateInvestAccountAndUpdateUserInfoDto
    >;
}) {
    const { data: userData } = useFetchTokenQuery();
    const {
        data: documents,
        isLoading: loadingUser,
        refetch,
    } = useGetUserDocumentsQuery(userData?.id ?? 0, {
        skip: !userData,
    });

    const {
        register,
        setValue,
        watch,
        control,
        formState: { dirtyFields },
    } = formState;

    const etape = useContext(EtapeForm);

    const [isLoadingUpload, setIsLoadingUpload] = React.useState(false);

    const document_address = documents?.filter((doc) =>
        doc.type.includes(DocumentUserType.ADDRESS)
    );

    const doc_adress = documents?.find(
        (doc) => doc.type === DocumentUserType.ADDRESS
    );

    const document_adress_Identity = documents?.find(
        (doc) => doc.type === DocumentUserType.ADDRESS_IDENTTITY
    );

    const document_adress_ThirdParty = documents?.find(
        (doc) => doc.type === DocumentUserType.ADDRESS_THIRD_PARTY
    );

    const document_adress_ThirdParty_Attestation = documents?.find(
        (doc) => doc.type === DocumentUserType.ADDRESS_THIRD_PARTY_ATTESTATION
    );

    const {
        getFiles,
        isValidForm,
        SidedFileDocument,
        DisplaySidedDocumentValidation,
    } = useSidedDocument({
        formState,
        fileNameVariable: `userInfoInvestAccount.documentAdress.sidedFiles`,
        documents: documents || [],
        isLoadingUpload: isLoadingUpload,
        type: DocumentUserType.ADDRESS_IDENTTITY,
        nameVariable: `userInfoInvestAccount.documentAdress.sidedFiles.name`,
    });

    useEffect(() => {
        if (document_address !== undefined) {
            if (document_address?.length === 1) {
                setValue(
                    'userInfoInvestAccount.documentAdress.name',
                    document_address[0].name
                );
            } else if (document_address?.length > 1) {
                setValue(
                    'userInfoInvestAccount.documentAdress.name',
                    DocumentUserName.HOST_BY_THIRD_PARTY
                );
                if (document_adress_ThirdParty !== undefined) {
                    setValue(
                        `userInfoInvestAccount.documentAdress.values.${0}.name`,
                        document_adress_ThirdParty?.name!
                    );
                }
                if (document_adress_ThirdParty_Attestation !== undefined) {
                    setValue(
                        `userInfoInvestAccount.documentAdress.values.${1}.name`,
                        document_adress_ThirdParty_Attestation?.name!
                    );
                }
            }
        }
    }, [documents]);

    const [openAdress, setOpenAdress] = React.useState(false);
    const [openAdressThirdParty, setOpenAdressThirdParty] =
        React.useState(false);
    const [openAttestation, setOpenAttestation] = React.useState(false);

    return (
        <>
            {openAdress && doc_adress ? (
                <DisplayDocumentValidation
                    document={doc_adress}
                    setOpen={setOpenAdress}
                    open={openAdress}
                    index={0}
                />
            ) : null}

            {openAdressThirdParty && document_adress_ThirdParty ? (
                <DisplayDocumentValidation
                    document={document_adress_ThirdParty}
                    setOpen={setOpenAdressThirdParty}
                    open={openAdressThirdParty}
                    index={0}
                />
            ) : null}

            <DisplaySidedDocumentValidation />

            {openAttestation && document_adress_ThirdParty_Attestation ? (
                <DisplayDocumentValidation
                    document={document_adress_ThirdParty_Attestation}
                    setOpen={setOpenAttestation}
                    open={openAttestation}
                    index={0}
                />
            ) : null}

            <div className="flex flex-col gap-3 justify-center items-center w-full h-full">
                <div
                    className={`mx-auto w-11/12 md:w-2/3 flex justify-start items-start flex-col ${
                        !watch('userInfoInvestAccount.documentAdress.name')
                            ? ''
                            : ''
                    }`}
                >
                    <h3 className="text-2xl mb-5 font-mainFontFamily ">
                        Vous avez modifié votre adresse, vous devez donc nous
                        fournir un justificatif de domicile pour cette nouvelle
                        adresse.
                    </h3>
                    <HeadlessRadioComponent
                        register={register}
                        name={'userInfoInvestAccount.documentAdress.name'}
                        watch={watch}
                        className="mx-auto"
                        control={control}
                        values={[
                            {
                                label: 'Facture (éléctricté, eau, gaz, internet, téléphone fixe)',
                                value: DocumentUserName.BILL,
                            },
                            {
                                label: 'Relevé bancaire',
                                value: DocumentUserName.BANK_STATEMENT,
                            },
                            {
                                label: 'Assurance habitation',
                                value: DocumentUserName.HOME_INSURANCE,
                            },
                            {
                                label: 'Quittance de loyer',
                                value: DocumentUserName.RENT_RECEIPT,
                            },
                            {
                                label: 'Hébergé par un tiers',
                                value: DocumentUserName.HOST_BY_THIRD_PARTY,
                                title: 'Vous êtes hébergé par un tiers',
                            },
                        ]}
                    >
                        <h3 className="">
                            Choisissez un justificatif de domicile à votre nom{' '}
                            <span className="required">*</span>
                        </h3>
                    </HeadlessRadioComponent>
                </div>
            </div>
            {watch('userInfoInvestAccount.documentAdress.name') ===
            undefined ? null : watch(
                  'userInfoInvestAccount.documentAdress.name'
              ) !== DocumentUserName.HOST_BY_THIRD_PARTY ? (
                <>
                    <UploaderSingle
                        register={register}
                        value={`userInfoInvestAccount.documentAdress.values.${0}.file`}
                        watch={watch}
                        setValue={setValue}
                        documentAlreadyUploaded={!!doc_adress}
                        loading={isLoadingUpload}
                        button={false}
                        componentEnd={() => {
                            return (
                                <p className="italic commentaireInput text-justify text-[0.8rem]">
                                    <span className="font-bold">
                                        Documents acceptés:
                                    </span>{' '}
                                    Documents datant de moins de 3 mois.
                                </p>
                            );
                        }}
                    >
                        <>
                            <h3 className="text-2xl mb-5">
                                {TitleDocumentUser(
                                    watch(
                                        'userInfoInvestAccount.documentAdress.name'
                                    )
                                )}{' '}
                                de {userData?.firstName}
                                <span className="uppercase">
                                    {' '}
                                    {userData?.lastName}
                                </span>
                                <span className="required">*</span>
                            </h3>
                            {doc_adress &&
                            doc_adress?.status === StateStatus.unValidated ? (
                                <div className="flex flex-col mt-2 gap-2">
                                    <p className="text-center text-red-600">
                                        Veuillez renvoyer ce document et/ou
                                        modifier vos informations personnelles
                                        pour correspondre à ce document si
                                        nécessaire.
                                    </p>
                                    <PrimaryButton
                                        className="w-fit mx-auto mb-5"
                                        onClick={() => setOpenAdress(true)}
                                    >
                                        Visualiser à nouveau les éléments à
                                        modifier
                                    </PrimaryButton>
                                </div>
                            ) : null}
                        </>
                    </UploaderSingle>
                    {document_address && doc_adress !== undefined ? (
                        <>
                            <DisplayDocumentsFormInvestisor
                                document={doc_adress!}
                            />
                        </>
                    ) : null}
                </>
            ) : (
                <>
                    <HeadlessRadioComponent
                        register={register}
                        name={`userInfoInvestAccount.documentAdress.sidedFiles.name`}
                        watch={watch}
                        className="mx-auto mt-5"
                        control={control}
                        values={[
                            {
                                label: "Carte Nationale d'Identité",
                                value: DocumentUserName.IDENTITY_CARD,
                            },
                            {
                                label: 'Passeport',
                                value: DocumentUserName.PASSPORT,
                            },
                            {
                                label: 'Carte de séjour',
                                value: DocumentUserName.RESIDENT_PERMIT,
                            },
                        ]}
                    >
                        <h3 className="">
                            Choisissez une pièce d'itentité de votre tiers
                            <span className="required">*</span>
                        </h3>
                    </HeadlessRadioComponent>
                    {watch(
                        `userInfoInvestAccount.documentAdress.sidedFiles.name`
                    ) ? (
                        <>
                            <SidedFileDocument>
                                {TitleDocumentUser(
                                    watch(
                                        `userInfoInvestAccount.documentAdress.values.0.name`
                                    )
                                )}{' '}
                                de votre tiers
                            </SidedFileDocument>
                        </>
                    ) : null}
                    <HeadlessRadioComponent
                        register={register}
                        name={`userInfoInvestAccount.documentAdress.values.${0}.name`}
                        watch={watch}
                        className="mx-auto mt-5"
                        control={control}
                        values={[
                            {
                                label: 'Facture (éléctricté, eau, gaz, internet, téléphone fixe)',
                                value: DocumentUserName.BILL,
                            },
                            {
                                label: 'Relevé bancaire',
                                value: DocumentUserName.BANK_STATEMENT,
                            },
                            {
                                label: 'Assurance habitation',
                                value: DocumentUserName.HOME_INSURANCE,
                            },
                            {
                                label: 'Quittance de loyer',
                                value: DocumentUserName.RENT_RECEIPT,
                            },
                        ]}
                    >
                        <h3 className="">
                            Choisissez un justificatif de domicile au nom de
                            votre tiers
                            <span className="required">*</span>
                        </h3>
                    </HeadlessRadioComponent>
                    {watch(
                        `userInfoInvestAccount.documentAdress.values.${0}.name`
                    ) ? (
                        <>
                            <UploaderSingle
                                register={register}
                                value={`userInfoInvestAccount.documentAdress.values.${0}.file`}
                                watch={watch}
                                documentAlreadyUploaded={
                                    !!document_adress_ThirdParty
                                }
                                setValue={setValue}
                                loading={isLoadingUpload}
                                button={false}
                                componentEnd={() => {
                                    return (
                                        <p className="italic commentaireInput text-justify text-[0.8rem]">
                                            <span className="font-bold">
                                                Documents acceptés:
                                            </span>{' '}
                                            Documents datant de moins de 3 mois.
                                        </p>
                                    );
                                }}
                            >
                                <>
                                    <h3 className="text-2xl mb-5">
                                        {TitleDocumentUser(
                                            watch(
                                                `userInfoInvestAccount.documentAdress.values.${0}.name`
                                            )
                                        )}{' '}
                                        de votre tiers
                                    </h3>
                                    {document_adress_ThirdParty &&
                                    document_adress_ThirdParty?.status ===
                                        StateStatus.unValidated ? (
                                        <div className="flex flex-col mt-2 gap-2">
                                            <p className="text-center text-red-600">
                                                Veuillez renvoyer ce document
                                                et/ou modifier vos informations
                                                personnelles pour correspondre à
                                                ce document si nécessaire.
                                            </p>
                                            <PrimaryButton
                                                className="w-fit mx-auto mb-5"
                                                onClick={() =>
                                                    setOpenAdressThirdParty(
                                                        true
                                                    )
                                                }
                                            >
                                                Visualiser à nouveau les
                                                éléments à modifier
                                            </PrimaryButton>
                                        </div>
                                    ) : null}
                                </>
                            </UploaderSingle>
                            {document_address &&
                            document_adress_ThirdParty !== undefined ? (
                                <>
                                    <DisplayDocumentsFormInvestisor
                                        document={document_adress_ThirdParty!}
                                    />
                                </>
                            ) : null}
                        </>
                    ) : null}

                    <UploaderSingle
                        register={register}
                        value={`userInfoInvestAccount.documentAdress.values.${1}.file`}
                        watch={watch}
                        setValue={setValue}
                        loading={isLoadingUpload}
                        documentAlreadyUploaded={
                            !!document_adress_ThirdParty_Attestation
                        }
                        button={false}
                        componentEnd={() => {
                            return (
                                <p className="italic commentaireInput text-justify text-[0.8rem]">
                                    <span className="font-bold">
                                        Documents acceptés:
                                    </span>{' '}
                                    Documents datant de moins de 3 mois.
                                </p>
                            );
                        }}
                    >
                        <>
                            <h3 className="text-2xl mb-5">
                                Attestation signée par votre tiers attestant que
                                vous vivez à son domicile.
                            </h3>
                            {document_adress_ThirdParty_Attestation &&
                            document_adress_ThirdParty_Attestation?.status ===
                                StateStatus.unValidated ? (
                                <div className="flex flex-col mt-2 gap-2">
                                    <p className="text-center text-red-600">
                                        Veuillez renvoyer ce document et/ou
                                        modifier vos informations personnelles
                                        pour correspondre à ce document si
                                        nécessaire.
                                    </p>
                                    <PrimaryButton
                                        className="w-fit mx-auto mb-5"
                                        onClick={() => setOpenAttestation(true)}
                                    >
                                        Visualiser à nouveau les éléments à
                                        modifier
                                    </PrimaryButton>
                                </div>
                            ) : null}
                        </>
                    </UploaderSingle>
                    {document_address &&
                    document_adress_ThirdParty_Attestation !== undefined ? (
                        <>
                            <DisplayDocumentsFormInvestisor
                                document={
                                    document_adress_ThirdParty_Attestation!
                                }
                            />
                        </>
                    ) : null}
                </>
            )}
        </>
    );
}

function UpdateDocumentIdentity1({
    formState,

    show,
}: {
    formState: UseFormReturn<
        FormUpdateUserInfo | CreateInvestAccountAndUpdateUserInfoDto
    >;

    show: boolean;
}) {
    const { data: userData } = useFetchTokenQuery();
    const {
        data: documents,
        isLoading: loadingUser,
        refetch,
    } = useGetUserDocumentsQuery(userData?.id ?? 0, {
        skip: !userData,
    });

    const { register, setValue, watch, control } = formState;

    const {
        getFiles,
        isValidForm,
        SidedFileDocument,
        DisplaySidedDocumentValidation,
    } = useSidedDocument({
        formState,
        fileNameVariable: 'userInfoInvestAccount.documentIdentity1.sidedFiles',
        documents: documents || [],
        isLoadingUpload: false,
        type: DocumentUserType.IDENTITY_1,
        nameVariable: 'userInfoInvestAccount.documentIdentity1.name',
    });

    const DocumentNameAlreadyUsed = documents?.find(
        (doc) => doc.type === DocumentUserType.IDENTITY_2
    )?.name;

    return (
        <>
            {show ? (
                <>
                    <DisplaySidedDocumentValidation />

                    <div className="flex flex-col gap-3 justify-center items-center w-full h-full">
                        <div
                            className={`mx-auto w-11/12 md:w-2/3 flex justify-start items-start flex-col 
              
              `}
                            // ${
                            //   !watch("name") ? "mt-[-30%]" : ""
                            // }
                        >
                            <HeadlessRadioComponent
                                register={register}
                                name={
                                    'userInfoInvestAccount.documentIdentity1.name'
                                }
                                watch={watch}
                                className="mx-auto"
                                control={control}
                                values={[
                                    {
                                        label: "Carte Nationale d'Identité",
                                        value: DocumentUserName.IDENTITY_CARD,
                                        used:
                                            DocumentNameAlreadyUsed ===
                                            DocumentUserName.IDENTITY_CARD,
                                    },
                                    {
                                        label: 'Passeport',
                                        value: DocumentUserName.PASSPORT,
                                        used:
                                            DocumentNameAlreadyUsed ===
                                            DocumentUserName.PASSPORT,
                                    },
                                    {
                                        label: 'Carte de séjour',
                                        value: DocumentUserName.RESIDENT_PERMIT,
                                        used:
                                            DocumentNameAlreadyUsed ===
                                            DocumentUserName.RESIDENT_PERMIT,
                                    },
                                ]}
                            >
                                <h3 className="">
                                    Choisisser une pièce d'identité{' '}
                                </h3>
                            </HeadlessRadioComponent>
                        </div>
                    </div>
                    {watch('userInfoInvestAccount.documentIdentity1.name') ? (
                        <>
                            <SidedFileDocument>
                                {TitleDocumentUser(
                                    watch(
                                        'userInfoInvestAccount.documentIdentity1.name'
                                    )
                                )}{' '}
                                de {userData?.firstName}
                                <span className="uppercase">
                                    {' '}
                                    {userData?.lastName}
                                </span>
                            </SidedFileDocument>
                        </>
                    ) : null}
                </>
            ) : null}
        </>
    );
}
