import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import ChangeEmail from "./Components/ChangeEmail";
import ChangePassword from "./Components/ChangePassword";
import DeleteAccount from "./Components/DeleteAccount";
import { MenuDashBoardUserBoolState } from "../../Context/AppContext";
import WrapComponentDashboardUser from "../commun/WrapComponentDashboardUser";

const Parameter = () => {
  const [password, setPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<boolean>(false);
  const [deleteAccount, setDeleteAccount] = useState<boolean>(false);

  const styleObject = {
    icon: "absolute -right-8 top-1 scale-75 transition-all duration-300",
    h2: "text-base md:text-2xl pointer relative w-max md:mx-auto",
  };
  const menuBoolState = useContext(MenuDashBoardUserBoolState);

  return (
    <div
      className={`mx-auto  md:mt-0 pt-[7px] pb-[10px] lg:px-2 w-[100%] h-[100vh] overflow-auto `}
    >
      <WrapComponentDashboardUser
        title="Paramètres"
        description=""
        classNameHeader={`${!menuBoolState?.toggleMenu ? "!pl-[40px]" : ""}`}
        classNameContainer={` ${!menuBoolState?.toggleMenu ? "" : ""}`}
      >
        <div className="flex overflow-auto justify-center flex-col gap-2 items-center h-[80vh]">
          {" "}
          <h2
            className={styleObject.h2}
            onClick={() =>
              setPassword((currentValue) => {
                return !currentValue;
              })
            }
          >
            Modifier son mot de passe{" "}
            <FontAwesomeIcon
              icon={faArrowRight}
              className={`${styleObject.icon} ${password ? "rotate-90" : ""} `}
            />
          </h2>
          <div className="md:w-1/2 mx-auto">
            {!!password && <ChangePassword />}
          </div>
          <h2
            className={styleObject.h2}
            onClick={() =>
              setEmail((currentValue) => {
                return !currentValue;
              })
            }
          >
            Modifier son email{" "}
            <FontAwesomeIcon
              icon={faArrowRight}
              className={`${styleObject.icon} ${email ? "rotate-90" : ""} `}
            />
          </h2>
          <div className="md:w-1/2 mx-auto">{!!email && <ChangeEmail />}</div>
        </div>
      </WrapComponentDashboardUser>
    </div>
  );
};

export default Parameter;
