import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { BlackListDto, CreateBlackListDto, UpdateBlackListDto } from "../../types/blackList";


interface blackListState {
    blackLists: BlackListDto[];
    loading: boolean;
    errors: any;
}

const initialState: blackListState = {
    blackLists: [],
    loading: false,
    errors: null,
};

// actions are processes that get data from backend
export const getBlackLists = createAsyncThunk<
    BlackListDto[]
>("blackLists/getBlackLists", async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}blackLists`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});



export const addBlackList = createAsyncThunk<
    BlackListDto,
    CreateBlackListDto
>("blackLists/addBlackList", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "post",
            url: `${import.meta.env.VITE_API_URL}blackLists`,
            data: data,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const editBlackList = createAsyncThunk<
    BlackListDto,
    UpdateBlackListDto
>("blackLists/editBlackList", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "put",
            url: `${import.meta.env.VITE_API_URL}blackLists/${data.id}`,
            data: { ...data },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});


export const deleteBlackList = createAsyncThunk<number, number>(
    "blackLists/deleteBlackList",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}blackLists/${id}`,
                withCredentials: true,
            });
            return id;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);


// reducers -> reduce to a specific state -> changes state

export const blackListSlice = createSlice({
    name: "blackLists",
    initialState,
    reducers: {
        setBlackLists: (
            state,
            action: PayloadAction<BlackListDto[]>
        ) => {
            state.blackLists = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBlackLists.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getBlackLists.fulfilled, (state, action) => {
            state.blackLists = action.payload;
            state.loading = false;
        });
        builder.addCase(getBlackLists.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addBlackList.fulfilled, (state, action) => {
            state.blackLists = [...state.blackLists, action.payload];
            state.loading = false;
        });
        builder.addCase(addBlackList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addBlackList.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editBlackList.fulfilled, (state, action) => {
            state.loading = false;
            state.blackLists = state.blackLists.map((blackLists) => {
                if (blackLists.id === action.payload.id) {
                    return {
                        ...blackLists,
                        ...action.payload,
                    };
                } else return blackLists;
            });
        });
        builder.addCase(editBlackList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editBlackList.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteBlackList.fulfilled, (state, action) => {
            state.loading = false;
            state.blackLists = state.blackLists.filter(
                (blackList) => blackList.id !== action.payload
            );
        });
        builder.addCase(deleteBlackList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteBlackList.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
    },
});

export default blackListSlice.reducer;
export const { setBlackLists } = blackListSlice.actions;
