import { createSlice } from '@reduxjs/toolkit'
import Page from '../../function/page_info';
import { pageMeasurement } from '../../types/pagesMeasurements'

interface pageState {
    measurements: pageMeasurement
}

const initialState: pageState = {
    measurements: {
        widthScreen: Page.getXmax(),
        heightScreen: Page.getYmax(),
        scrollPage: Page.getScrollPage(),
    }
}

const pageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {
        getMeasures: (state) => {
            state.measurements = {
                widthScreen: Page.getXmax(),
                heightScreen: Page.getYmax(),
                scrollPage: Page.getScrollPage(),
            }
        }
    }
});

export const { getMeasures } = pageSlice.actions

export default pageSlice.reducer