import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import {
    addDays,
    formatDate,
    getTrimesterYearDateFormat,
} from '../../../function/Utils';
import {
    useGetProjectByIdForUserQuery,
    useGetReportingByProjectQuery,
} from '../../../redux/features/projectSlice';
import { ReportingDto } from '../../../types/project';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';

import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import {
    displayReportRiskNotation,
    getColorReportRiskNotation,
} from '../../../function/projectHelpers';
import { useGetPlatformQuery } from '../../../redux/features/platformSlice';
import { TypeDocumentPlatform } from '../../../types/documentPlatform';
import { LazyImage } from '../../commun/animation/LazyImage';
import DisplayTextEditor from '../../commun/DisplayTextEditor';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import TableComponent from '../../DashboardAdmin/BackOffice/Components/TableComponent';
import {
    OperationFollowInfo,
    OurRoleInfo,
    RiskNotationInfo,
} from './InfoProjectsComponents';
import { DisplayDocumentProjectForUser } from './ProjectUser';

export function ReportingUser({ projectId }: { projectId: number }) {
    const {
        data: reportings,
        isLoading: isLoadingReporting,
        refetch,
    } = useGetReportingByProjectQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const { data: project, isLoading } = useGetProjectByIdForUserQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );

    const data = useMemo(() => {
        if (!project) return [];
        return [
            {
                label: 'Début du projet',
                timeframe: getTrimesterYearDateFormat(
                    new Date(project.dateStart)
                ),
                date: formatDate(new Date(project.dateStart)),
            },
            {
                label: 'Échéance',
                timeframe: getTrimesterYearDateFormat(
                    new Date(project.dateEnd)
                ),
                date: formatDate(new Date(project.dateEnd)),
            },
            {
                label: 'Droit de prorogation',
                timeframe: getTrimesterYearDateFormat(
                    addDays(
                        new Date(project.dateEnd),
                        (project.delayAccepted || 6) * 30
                    )
                ),
                date: formatDate(
                    addDays(
                        new Date(project.dateEnd),
                        (project.delayAccepted || 6) * 30
                    )
                ),
            },
        ];
    }, [project]);

    return (
        <div>
            <>
                {isLoadingReporting ? (
                    <Loading />
                ) : (
                    <>
                        <div className=" text-center">
                            <h3 className=" text-3xl md:text-4xl font-mainFontFamily text-mainColor mb-5">
                                Rapport financier
                            </h3>
                            <p>
                                Vous trouverez ci-dessous les données vous
                                permettant de suivre l'évolution du projet et de
                                son équilibre financier.
                            </p>
                            {/* <p className="mt-3">
                Nous sommes investis avec vous sur chaque opération
              </p> */}
                            <div className="bg-white  rounded-lg p-2 my-3">
                                <h2 className="text-xl font-bold my-4 font-mainFontFamily">
                                    Chronologie du projet
                                </h2>
                                <ul className="space-y-2 mb-7">
                                    {data.map((item, index) => (
                                        <li
                                            key={index}
                                            className="flex flex-col md:flex-row justify-between items-center border-b pb-4 mb-4"
                                        >
                                            <div className="w-full md:w-1/3 font-semibold">
                                                {item.label}
                                            </div>
                                            <div className="w-full md:w-1/3 text-gray-600">
                                                {item.timeframe}
                                            </div>
                                            <div className="w-full md:w-1/3 text-gray-500">
                                                {item.date}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <h3 className=" mt-6 font-mainFontFamily text-xl text-center mb-4 font-semibold">
                            Liste des rapports disponibles
                        </h3>
                        <TableComponent
                            head={['Date', 'Suivi', 'Documents', '']}
                        >
                            <tbody>
                                <>
                                    {isLoadingReporting ? (
                                        <>
                                            <tr className="">
                                                <td
                                                    colSpan={4}
                                                    className="pt-10 text-center mx-auto"
                                                >
                                                    <Loading />
                                                </td>
                                            </tr>
                                        </>
                                    ) : (
                                        <>
                                            {reportings ? (
                                                <>
                                                    {reportings?.length > 0 ? (
                                                        <>
                                                            {reportings?.map(
                                                                (r, key) => (
                                                                    <tr
                                                                        className={`border-b hover:bg-slate-500 hover:opacity-80 ${
                                                                            key %
                                                                                2 ===
                                                                            1
                                                                                ? 'bg-secondBackgroundColor'
                                                                                : 'bg-white '
                                                                        } `}
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        <RenderReporting
                                                                            reporting={
                                                                                r
                                                                            }
                                                                            refetch={
                                                                                refetch
                                                                            }
                                                                            projectId={
                                                                                projectId
                                                                            }
                                                                        />
                                                                    </tr>
                                                                )
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <tr className="w-full mx-auto">
                                                                <td
                                                                    colSpan={4}
                                                                    className="text-center text-gray-500 text-xl  pt-10"
                                                                >
                                                                    Aucun
                                                                    rapport
                                                                    trouvé
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                </>
                            </tbody>
                        </TableComponent>

                        <div className="w-full mt-6 mb-4">
                            <RiskNotationInfo />
                            <p className=" mt-10 mb-4 text-center  text-2xl font-semibold tracking-tight text-gray-900 md:text-3xl ">
                                Le suivi de l'opération
                            </p>
                            <ul className=" mt-4 mb-6 max-w-lg mx-auto">
                                <li className="flex flex-col md:flex-row justify-between items-center border-y py-4">
                                    <div className="w-full font-semibold">
                                        Projet proposé par
                                    </div>
                                    <div className="w-full text-gray-600">
                                        {project?.platformName}
                                    </div>
                                </li>
                            </ul>
                            <RenderPlatformInfo
                                platformId={project?.platformId || 0}
                            />
                            <OperationFollowInfo project={project} />
                            <OurRoleInfo />
                        </div>
                    </>
                )}
            </>
        </div>
    );
}

function RenderPlatformInfo({ platformId }: { platformId: number }) {
    const { data: platform } = useGetPlatformQuery(platformId);

    const logo = useMemo(() => {
        return (
            platform?.documents?.find(
                (doc) => doc.type === TypeDocumentPlatform.logo
            )?.url || undefined
        );
    }, [platform]);

    return (
        <div className="grid md:grid-cols-[auto,1fr] w-full md:w-11/12 mx-auto">
            {logo ? (
                <LazyImage
                    src={logo || ''}
                    className={{
                        img: 'my-2 w-[150px] bg-cover bg-center rounded-sm',
                        div: 'flex justify-center items-center w-[150px] mx-auto ',
                    }}
                />
            ) : null}

            <div className="w-full px-2 my-4 mx-auto">
                <DisplayTextEditor
                    content={platform?.description ?? undefined}
                />
            </div>
        </div>
    );
}

function RenderReporting({
    reporting,
    refetch,
    projectId,
}: {
    reporting: ReportingDto;
    refetch: any;
    projectId: number;
}) {
    const [editToggle, setEditToggle] = useState(false);
    const [detailsToggle, setDetailsToggle] = useState(false);

    const classNameObejct = {
        item: 'text-sm font-light px-1 py-2 whitespace-nowrap',
    };

    return (
        <>
            {detailsToggle ? (
                <ReportingCards
                    reporting={reporting}
                    open={detailsToggle}
                    setOpen={setDetailsToggle}
                />
            ) : null}
            <td className={`${classNameObejct.item}`}>
                {formatDate(reporting.date)}
            </td>
            <td
                className={`${classNameObejct.item}`}
                style={{
                    color: getColorReportRiskNotation(reporting?.notation),
                }}
            >
                {displayReportRiskNotation(reporting?.notation)}
            </td>
            {/* <td className={`${classNameObejct.item}`}>
        {displayHideLongText("Pas de commentaire", reporting?.comment)}
      </td> */}
            <td className="">
                {reporting?.documents?.length > 0 ? (
                    <FontAwesomeIcon icon={faFileLines} className="h-[25px]" />
                ) : null}
            </td>
            <td className="text-center">
                <WhiteButton onClick={() => setDetailsToggle(true)}>
                    Details
                </WhiteButton>
                {/* <PrimaryButton onClick={() => setEditToggle(true)}>Edit</PrimaryButton> */}
            </td>
        </>
    );
}

function ReportingCards({
    reporting,
    open,
    setOpen,
}: {
    reporting: ReportingDto;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const classNameDiv =
        'grid grid-cols-1 space-y-2 md:space-y-0 md:grid-cols-2 border-b-2 py-2';
    const classNameH3 =
        'text-md font-mainFontFamily font-semibold text-center md:text-start';
    const classNameP = 'flex justify-center items-center';
    const classNameComment = 'px-2 md:px-0 text-start text-gray-400 italic';

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title={() => {
                return (
                    <h3 className="text-2xl font-mainFontFamily font-medium leading-6 text-gray-900">
                        Détails du reporting {formatDate(reporting.date)}
                    </h3>
                );
            }}
            buttonBoolean={false}
            width="w-full md:w-11/12"
        >
            <div className="mt-5 w-[95%] flex gap-2 flex-col  md:px-5 mx-auto">
                <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Notation</h3>
                    <p className={`${classNameP}`}>
                        {reporting.notation || 'Pas de notation'}
                    </p>
                </div>
                <div className={`flex flex-col`}>
                    <h3 className={`${classNameH3} mb-4`}>Commentaire</h3>
                    <p className={` text-left`}>
                        <DisplayTextEditor content={reporting.comment} />
                    </p>
                </div>
                <TitleComponentForm>Documents</TitleComponentForm>
                <div className="flex flex-col gap-2">
                    {reporting.documents && reporting.documents?.length! > 0 ? (
                        reporting.documents.map((doc, index) => (
                            <DisplayDocumentProjectForUser
                                document={doc}
                                key={index}
                            />
                        ))
                    ) : (
                        <p>Aucun document n'a été trouvé</p>
                    )}
                </div>
                <div className="flex justify-center items-center">
                    <PrimaryButton onClick={() => setOpen(false)}>
                        Fermer
                    </PrimaryButton>
                </div>
            </div>
        </PopUp>
    );
}
