import React from "react";
import { TransactionStatus } from "../../types/transactions";
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/outline";

interface Props {
  status: TransactionStatus;
}
const StatusTransaction: React.FC<Props> = ({ status }) => {
  return (
    <>
      {status === TransactionStatus.PENDING ? (
        <div className="flex items-center justify-center">
          {/* <ExclamationIcon
            className="h-6 w-6 text-blue-400"
            aria-hidden="true"
          /> */}
          <p className="ml-2 text-yellow-500">En attente</p>
        </div>
      ) : status === TransactionStatus.VALIDATED ? (
        <div className="flex items-center justify-center">
          <CheckCircleIcon
            className="h-6 w-6 text-green-400"
            aria-hidden="true"
          />
          <p className="ml-2 text-green-500">Validé</p>
        </div>
      ) : status === TransactionStatus.REFUSED ? (
        <div className="flex items-center justify-center">
          <ExclamationIcon
            className="h-6 w-6 text-red-400"
            aria-hidden="true"
          />
          <p className="ml-2 text-red-500">Refusé</p>
        </div>
      ) : status === TransactionStatus.DELAYED ? (
        <div className="flex items-center justify-center">
          <ExclamationIcon
            className="h-6 w-6 text-orange-400"
            aria-hidden="true"
          />
          <p className="ml-2 text-yellow-500">En retard</p>
        </div>
      ) : status === TransactionStatus.ISSUED ? (
        <div className="flex items-center justify-center">
          <ExclamationIcon
            className="h-6 w-6 text-orange-400"
            aria-hidden="true"
          />
          <p className="ml-2 text-yellow-500">Effectuée</p>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <ExclamationIcon
            className="h-6 w-6 text-orange-400"
            aria-hidden="true"
          />
          <p className="ml-2 text-yellow-500">{status}</p>
        </div>
      )}
    </>
  );
};

export default StatusTransaction;
