import React, { useRef, useState } from "react";
import update from "../../assets/edit.svg";
import deleteSVG from "../../assets/delete.svg";
import pictureSVG from "./../../assets/picture.svg";
import { useNavigate } from "react-router";
import ArticleAuteur from "./ArticleAuteur";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  deleteArticle,
  editArticle,
  getArticles,
  uploadCoverArticle,
} from "../../redux/features/article";
import {
  Article,
  ArticleCoverInterface,
  ParagraphDelete,
} from "../../types/Articles";
import { deleteParagraphArticle } from "../../redux/features/paragraphArticle";
import { refreshImage } from "../../function/Utils";

interface Props {
  article: Article;
}

const ArticleElementTitre: React.FC<Props> = ({ article }) => {
  const userData = useAppSelector((state) => state.userReducer.user);
  const paragraphsData = [
    ...useAppSelector((state) => state.paragraphArticleReducer.paragraphs),
  ];

  const [editToggleTitre, setEditToggleTitre] = useState<boolean>(false);
  const [editTitre, setEditTitre] = useState<string>(article.title);
  const [editToggleText, setEditToggleText] = useState<boolean>(false);
  const [editText, setEditText] = useState<string>(article.text);
  const [file, setFile] = useState<File | null>(null);

  const articleCoverRef = useRef<HTMLImageElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleFormTitre = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = { ...article, id: article.id, title: editTitre };

    await dispatch(editArticle(data));
    setEditToggleTitre(!editToggleTitre);
    dispatch(getArticles());
  };

  const handleFormText = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = { ...article, id: article.id, text: editText };

    await dispatch(editArticle(data));
    setEditToggleText(!editToggleText);
    dispatch(getArticles());
  };

  const handlePicture = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (file && article.id) {
      const data = new FormData();

      data.append("id", article.id.toString());
      data.append("file", file);

      await dispatch(uploadCoverArticle(data));
      if (article.cover) {
        refreshImage(articleCoverRef, article.cover);
      }

      dispatch(getArticles());
    }
  };
  const handleDelete = async () => {
    let bol = window.confirm("Voulez vous vraiment supprimer cet article ?");
    if (bol && article.id) {
      paragraphsData
        .filter((val) => val.ArticleId === article.id)
        .map(
          (paragraph) =>
            paragraph.id &&
            dispatch(
              deleteParagraphArticle({
                id: paragraph.id,
                body: {
                  path: paragraph?.path,
                },
              })
            )
        );
      dispatch(deleteArticle(article.id));

      await navigate("/articles");
    }
  };

  return (
    <div className="head_container">
      {!!userData?.admin && (
        <div className="edit-delete flex">
          <div
            className="hidden container md:flex justify-center items-center pointer"
            onClick={() => setEditToggleTitre(!editToggleTitre)}
          >
            <img src={update} alt="edit" className="pointer" />
            <p>Edit title</p>
          </div>
          <div
            className="container flex justify-center items-center pointer"
            onClick={() => handleDelete()}
          >
            <img src={deleteSVG} alt="delete" className="pointer" />
            <p className="">Supprimer cet article</p>
          </div>
        </div>
      )}
      {editToggleTitre ? (
        <form onSubmit={(e) => handleFormTitre(e)}>
          <div className="flex container md:hidden justify-end pointer">
            <img
              src={update}
              alt="edit"
              className="pointer h-5 m-3"
              onClick={() => setEditToggleTitre(!editToggleTitre)}
            />
          </div>
          <textarea
            defaultValue={article.title}
            onChange={(e) => setEditTitre(e.target.value)}
          ></textarea>
          <input
            className="inputHeader pointer"
            type="submit"
            value="Valider la modification"
          />
        </form>
      ) : (
        <React.Fragment>
          <div
            className="flex container md:hidden justify-end pointer"
            onClick={() => setEditToggleTitre(!editToggleTitre)}
          >
            <img src={update} alt="edit" className="pointer h-5 m-3" />
          </div>
          <h1
            className="element_modifiable"
            onClick={() =>
              userData?.admin && setEditToggleTitre(!editToggleTitre)
            }
          >
            {article.title}
          </h1>
        </React.Fragment>
      )}
      <div className="sousTitre_container">
        {!!userData?.admin && (
          <div className="edit-delete">
            <img
              onClick={() => setEditToggleText(!editToggleText)}
              src={update}
              alt="edit"
              className="pointer"
            />
            {/* <img
              src={deleteSVG}
              alt="delete"
              onClick={() => handleDelete()}
              className="pointer"
            /> */}
          </div>
        )}
        {editToggleText ? (
          <form onSubmit={(e) => handleFormText(e)}>
            <textarea
              defaultValue={article.text}
              onChange={(e) => setEditText(e.target.value)}
            ></textarea>
            <input
              type="submit"
              value="Valider la modification"
              className="inputHeader pointer"
            />
          </form>
        ) : (
          <h4
            className="element_modifiable"
            onClick={() =>
              userData?.admin && setEditToggleText(!editToggleText)
            }
          >
            {article.text}
          </h4>
        )}
      </div>
      <React.Fragment>
        {userData?.admin ? (
          <form
            className="formImageCover img"
            onSubmit={(e) => handlePicture(e)}
          >
            <img className="svg pointer" src={pictureSVG} alt="img" />
            <input
              className="fileInput pointer"
              type="file"
              id="file-upload"
              name="file"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => {
                if (e.target.files) setFile(e.target.files[0]);
              }}
            />

            <img ref={articleCoverRef} src={article.cover} alt="img" />
            <br />
            <input
              className="pointer envoyerPhoto"
              type="submit"
              value="Envoyer"
            />
          </form>
        ) : (
          <div className="formImageCover img">
            <img src={article.cover} alt="img" />
          </div>
        )}
      </React.Fragment>

      {/* <div className="auteur_container">
        <img src={article.auteurcover} alt="auteur" />
        <div className="text">
          <h3>{article.auteurname}</h3>
          <p>{article.auteurpost}</p>
          <p className="date_publication">
            Publié le {article.datepublication}
          </p>
        </div> */}
      {/* </div> */}
      <ArticleAuteur article={article} />
    </div>
  );
};

export default ArticleElementTitre;
