import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { FormCreateDocumentPlatformRequest } from '../../types/documentPlatform';
import {
    CreatePlatformDto,
    PlatformDto,
    UpdatePlatformDto,
} from '../../types/platform';
import { baseQueryHandleError } from '../index.rtk';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}platforms`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export const platformsApi = createApi({
    reducerPath: 'platformsApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: ['Platform'],
    endpoints: (builder) => ({
        getPlatforms: builder.query<PlatformDto[], void>({
            query: () => ``,
            providesTags: ['Platform'],
        }),
        getPlatform: builder.query<PlatformDto, number>({
            query: (id) => `${id}`,
            providesTags: ['Platform'],
        }),
        addPlatform: builder.mutation<PlatformDto, CreatePlatformDto>({
            query: (payload) => ({
                url: ``,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Platform'],
        }),
        deletePlatform: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Platform'],
        }),
        editPlatform: builder.mutation<PlatformDto, UpdatePlatformDto>({
            query: (payload) => ({
                url: `/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['Platform'],
        }),
        // documents
        deleteDocumentPlatform: builder.mutation<void, number>({
            query: (id) => ({
                url: `/documents/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetPlatformsQuery,
    useAddPlatformMutation,
    useDeletePlatformMutation,
    useEditPlatformMutation,
    useDeleteDocumentPlatformMutation,
    useGetPlatformQuery,
} = platformsApi;

export async function uploadDocumentPlatform(
    request: FormCreateDocumentPlatformRequest
) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}platforms/documents/url/put`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    platformId: request.platformId,
                    platformName: request.platformName,
                    type: request.type,
                    category: request.category,
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}platforms/documents/post`,
            data: {
                platformId: request.platformId,
                type: request.type,
                comment: request?.comment,
                category: request.category,
                fileName: encodeURIComponent(request.fileName),
                key: key,
            },
            withCredentials: true,
        });
        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}
