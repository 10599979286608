import React, { ReactNode, useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  Path,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useForm,
} from "react-hook-form";
import PreviewFile from "./PreviewFile";
import picture from "../../../assets/picture.svg";
import { PrimaryButton } from "../Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import PreviewFiles from "./PreviewFiles";
import FieldArray from "./FieldArray";
import Loading from "../Loading";

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>;
  watch: UseFormWatch<T>;
  errors?: FieldErrorsImpl<T>;
  componentEnd?: () => JSX.Element;
  className?: {
    input?: string;
  };
  control: Control<T, Path<T>>;
  value: Path<T>;
  required?: false;
  cursorNotAllowed?: true;
  type?: string;
  setValue: UseFormSetValue<T>;
  getValues?: UseFormGetValues<T>;
  button?: boolean;
  loading?: boolean;
  size?: number;
}

const UploaderMulti = <T extends object>(
  props: Props<T> & { children?: ReactNode }
) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined
  );

  let size = props.size ?? 6;

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    const files = event.dataTransfer.files;
    if (props.setValue && files && files.length > 0) {
      const fileArray = Array.from(files);
      // Process each file
      fileArray.forEach((file) => {
        const fileType = file.type.split("/")[1];
        if (["png", "jpg", "jpeg", "pdf"].includes(fileType)) {
          // Append file to the existing files array
          props.setValue(props.value, [
            ...(props.watch(props.value) || []),
            file,
          ] as any);
          setErrorMessage(undefined);
        } else {
          setErrorMessage(`Le type ${fileType} n'est pas valide`);
        }
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files;
    if (props.setValue && files && files.length > 0) {
      const fileArray = Array.from(files);
      // Process each file
      fileArray.forEach((file) => {
        const fileType = file.type.split("/")[1];
        if (["png", "jpg", "jpeg", "pdf"].includes(fileType)) {
          // Append file to the existing files array
          props.setValue(props.value, [
            ...(props.watch(props.value) || []),
            file,
          ] as any);
          setErrorMessage(undefined);
        } else {
          setErrorMessage(`Le type ${fileType} n'est pas valide`);
        }
      });
    }
  };

  const classNameObject = {
    h3: "text-base mt-2",
    p: "italic text-xs",
    input:
      "w-[300px] h-[100px] absolute left-[10%] sm:left-[20%] md:left[30%] bg-secondBackgroundColor cursor-pointer  top-2 opacity-0",
    img: "w-8 h-8 my-2",
    previewImage: "w-20 my-2",
  };

  return (
    <motion.div
      className={`mx-auto w-full  md:w-11/12 md:max-w-xl  p-8 `}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      {props.children}

      <div
        className={`relative  p-4 border-dashed border-2 rounded-lg border-mainColor flex flex-col items-center justify-center gap-3 w-full ${
          isDragOver ? "bg-gray-300" : ""
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {/* <img
          className={classNameObject.img}
          src={picture}
          alt="icon to upload file"
        /> */}

        <input
          type="file"
          accept=".jpg, .jpeg, .png, .pdf"
          onChange={(e) => handleChange(e)}
          // required
          className={classNameObject.input}
          multiple // Enable multiple file selection
        />
        <div className="flex gap-3 items-center">
          <FontAwesomeIcon
            icon={faUpload}
            className="text-mainColor hover:text-secondColor"
          />
          Glisser-déposer ou
          <PrimaryButton className="w-20 h-8 my-2 inline-block">
            <label htmlFor="file-upload">Ajouter</label>
          </PrimaryButton>
        </div>
        <div className="">
          <span className="font-bold">Fichiers supportés:</span> JPG, JPEG, PNG,
          PDF
        </div>
        <div className="">
          <span className="font-bold">Taille maximum:</span> {size} MB
        </div>
      </div>
      {errorMessage ? (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="text-red-500 text-xs italic my-2"
        >
          {errorMessage}
        </motion.div>
      ) : null}
      {props.componentEnd && props.componentEnd()}
      <FieldArray control={props.control} name={props.value as any}>
        {({ fields, append, remove }) => (
          <>
            {fields.map((field, index) => (
              <PreviewFiles
                key={field.id}
                index={index}
                file={(props.watch(props.value) as File[])?.[index] as File}
                remove={remove}
                size={size}
              />
            ))}
          </>
        )}
      </FieldArray>
      {props.button === undefined || props.button === true ? (
        <div className="flex items-center justify-center mt-10">
          <PrimaryButton
            type="submit"
            disabled={
              !props.watch || !(props.watch(props.value) as File[])?.length
            }
            className={`${
              !props.watch || !(props.watch(props.value) as File[])?.length
                ? "opacity-50"
                : ""
            }`}
          >
            {props.loading !== undefined && props.loading === true ? (
              <Loading size={4} />
            ) : (
              "Continuer"
            )}
          </PrimaryButton>
        </div>
      ) : null}
    </motion.div>
  );
};

export default UploaderMulti;
