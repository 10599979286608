// Artcile

export interface Article {
    id?: number;
    title: string;
    cover?: string;
    text: string;
    show: boolean;
    order: number;
    datepublication: Date;
    auteurname: string;
    auteurcover: string;
    auteurpost: string;

}

export interface UploadFiles {
    data: FormData;
    type: string;
}

export interface ArticleCoverInterface extends FormData {
    file: File;
    id: number;
}

// Paragraph

export interface ParagraphArticleInterface {
    id?: number;
    text?: string;
    path?: string;
    order: number;
    type: paragraphArticleType;
    ArticleId?: number;
}

export enum paragraphArticleTypeImg {
    img = "img",
    pdf = "pdf",
}


export enum paragraphArticleType {
    p = "p",
    h3 = "h3",
    img = "img",
    pdf = "pdf",
    list = "list",
}

export interface ParagraphDelete {
    id: number;
    body: {
        path: string | undefined;
    }
}

// Category 

export interface CategoryArticleInterface {
    id?: number;
    type: string;
    content: string;
    ArticleId?: number;
}

export enum CategoryElementInterface {
    name = "name",
    post = "post",
    date = "date",
}