export interface ISocketMessageFromClient {
    api: EnumSocketApi,
    type: EnumSocketTypeFromClient,
    action: EnumSocketAction,
    message: string,
    backOfficeInfo?: IBackOfficeInfo,

}

export interface IBackOfficeInfo {
    fondsIds: number[],
    applyFeesBool: boolean,
    date: Date,
    dailyShareBool: boolean,
    dateEnd?: Date,
}

export enum EnumSocketApi {
    share = 'share',
}

export enum EnumSocketTypeFromClient {
    start = "start",
}

export enum EnumSocketAction {
    computeAllShares = "computeAllShares",
    computeSharesMonth = "computeSharesMonth",
    computeProjectValorisations = "computeProjectValorisations",
    computeProjectValorisationsMonth = "computeProjectValorisationsMonth",
    computeShareOfTheday = "computeShareOfTheday",
    computeShareBetweenDate = "computeShareBetweenDate",
    computeProjectValorisationsBetweenDate = "computeProjectValorisationsBetweenDate",
    computeEverythingBetween2Dates = "computeEverythingBetween2Dates",
    computeEverything = "computeEverything",

}

export interface ISocketMessageFromServer<T = any> {
    type: EnumSocketTypeFromServer,
    message: string,
    progress?: string,
    date?: string,
    fondsId?: number,
    data?: T[],
}

export enum EnumSocketTypeFromServer {
    start = "start",
    progress = "progress",
    completed = "completed",
    error = "error"
}