import React from 'react';

import { useEffect } from 'react';
import { pageHeader } from '../../types/commun';
import { useAppSelector } from '../../redux/store';
import Page from '../../function/page_info';

// import { Vue_ensembleList } from '../data/vueEnsembleList';

interface Props {
    pageHeader: pageHeader;
}

const FirstFondPage: React.FC<Props> = ({ pageHeader }) => {
    const pageData = useAppSelector(
        (state) => state.pageMeasurement.measurements
    );

    function resizeImg() {
        if (Page.getXmax() > 600) {
            let img = document.getElementsByClassName(
                'firstImagePage'
            ) as HTMLCollectionOf<HTMLElement>;
            for (let value of img) {
                value.style.height = `${Page.getYmax()}px`;
            }
        } else {
            let mobile = document.getElementsByClassName(
                'mobile_background'
            ) as HTMLCollectionOf<HTMLElement>;
            for (let value of mobile) {
                value.style.height = `auto`;
            }
        }
    }

    function apparitionTitre() {
        let div_ = document.getElementsByClassName(
            'firstImagePage'
        ) as HTMLCollectionOf<HTMLElement>;
        for (let value of div_) {
            let h = value.children as HTMLCollectionOf<HTMLElement>;
            for (let value of h) {
                if (pageData.scrollPage > Page.getYmax() / 3) {
                    value.style.display = 'none';
                } else {
                    value.style.display = 'block';
                }
            }
        }
    }

    useEffect(() => {
        resizeImg();
    }, [pageData.widthScreen, pageData.heightScreen]);
    useEffect(() => {
        if (pageData.widthScreen > 600) {
            apparitionTitre();
        }
    }, [pageData.scrollPage]);

    return (
        <React.Fragment>
            {pageData.widthScreen > 600 ? (
                <div
                    key={pageHeader.classN}
                    className={`firstImagePage ${pageHeader.classN}`}
                    style={{
                        backgroundImage: `url(${pageHeader.coverBackground})`,
                    }}
                >
                    <h1>{pageHeader.h1_}</h1>
                    {pageHeader.h2_ && <h2>{pageHeader.h2_}</h2>}
                </div>
            ) : (
                <div key={pageHeader.classN} className="mobile_background">
                    <img src={pageHeader.coverImg} alt="un océan" />
                    <h1 className="text-3xl">{pageHeader.h1_}</h1>
                    {pageHeader.h2_ && (
                        <h2 className="text-xl">{pageHeader.h2_}</h2>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default FirstFondPage;
// "./img/image_home2.jpg"
