export default function LabelComponentForm({
    children,
    className,
}: {
    className?: string;
    children: React.ReactNode;
}) {
    return (
        <h3 className={`text-lg mt-2 mb-1 font-semibold ${className}`}>
            {children}
        </h3>
    );
}
