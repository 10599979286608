import React, { useEffect, useRef, useState } from "react";

export const LazyImageBackground = ({
  src,
  className,
}: {
  src: string;
  className?: string;
}) => {
  const [loaded, setLoaded] = useState(false);

  const backgroundRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (src != "") {
      const image = new Image();
      image.src = src;
      image.onload = () => {
        setLoaded(true);
      };
    }
  }, [src]);

  return (
    <>
      {src != "" ? (
        <div
          className={`bg-center bg-no-repeat bg-cover flex justify-center items-center ${
            loaded ? "" : "blur-sm"
          } ${className}`}
          style={{ backgroundImage: `url('${src}')` }}
        >
          {src == "" ? "Auncune image n'a été trouvée" : null}
        </div>
      ) : null}
    </>
  );
};

// export const LazyImageBackground = ({
//   src,
//   className,
// }: {
//   src: string;
//   className?: string;
// }) => {
//   const [loaded, setLoaded] = useState(false);
//   const [error, setError] = useState(false);

//   useEffect(() => {
//     if (src !== "") {
//       const image = new Image();
//       image.src = src;
//       image.onload = () => {
//         setLoaded(true);
//         setError(false);
//       };
//       image.onerror = () => {
//         setError(true);
//       };
//     }
//   }, [src]);

//   return (
//     <div
//       className={`bg-center bg-no-repeat bg-cover flex justify-center items-center ${
//         loaded ? "" : "blur-sm"
//       } ${className}`}
//       style={loaded ? { backgroundImage: `url(${src})` } : {}}
//     >
//       {!src
//         ? "Aucune image n'a été trouvée"
//         : error
//         ? "Failed to load image"
//         : null}
//     </div>
//   );
// };

export const LazyImage = ({
  src,
  className,
}: {
  src: string;
  className?: {
    div?: string;
    img?: string;
  };
}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (src != "") {
      const image = new Image();
      image.src = src;
      image.onload = () => setLoaded(true);
    }
  }, [src]);

  return (
    <>
      {src != "" ? (
        <div className={`${loaded ? "bg-none" : "blur-sm"} ${className?.div}`}>
          <img
            className={` ${className?.img} ${loaded ? "bg-none" : "blur-sm"}`}
            src={src}
            loading="lazy"
            alt="lazyImage"
          />
        </div>
      ) : (
        <div
          className={`flex justify-center h-full min-h-[100px] px-2 items-center italic bg-gray-200 ${className?.div}`}
        >
          Aucune image n'a été trouvé
        </div>
      )}
    </>
  );
};
