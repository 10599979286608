import React, { useContext, useState } from "react";
import { useLocation, useParams } from "react-router";
import ArticlesManage from "../components/DashboardAdmin/ArticlesManage";
import Fonds from "../components/DashboardAdmin/Fonds/IndexFondsAdmin";
import Projet_i from "../components/DashboardAdmin/Fonds/Projet_i";
import Menu from "../components/DashboardAdmin/Menu";
import UtilisateursManage from "../components/DashboardAdmin/UserManage/UsersManage";
import { toInt } from "../function/Utils";
import NotFound from "./NotFound";
import { tableBackOfficeInterface } from "../types/BackOffice";
import LoginDashBoardAdmin from "../components/DashboardAdmin/Security/LoginDashBoardAdmin";
import Security from "../components/DashboardAdmin/Security/Security";
import {
  AllowState,
  MenuDashBoardAdminBool,
  MenuDashBoardAdminBoolState,
} from "../components/Context/AppContext";
import IndexBackOffice from "../components/DashboardAdmin/BackOffice/IndexBackOffice";
import UserManage from "../components/DashboardAdmin/UserManage/UserManage";
import ProjectsManage from "../components/DashboardAdmin/ProjectManage/ProjectsManage";
import IndexFondsAdmin from "../components/DashboardAdmin/Fonds/IndexFondsAdmin";
import IndexImpactData from "../components/DashboardAdmin/ImpactData/IndexImpactData";
import MetricsFeeValue from "../components/DashboardAdmin/MetricsPage/MetricsFeeValue";
import ProspectUser from "../components/DashboardAdmin/ProspectUser";
import SponsorIndex from "../components/DashboardAdmin/Sponsor/SponsorIndex";
import FeesIndex from "../components/DashboardAdmin/Fees/FeesIndex";
import ShareDIndex from "../components/DashboardAdmin/ShareD/ShareDIndex";

type PathParams = {
  path: string;
};

type FondsParams = {
  idFonds: string;
};

type ProjetParams = {
  idProjet: string;
};

type ProjectBOParams = {
  idProjectBO: string;
};

type backOfficeParams = {
  idBackOffice: string;
};

type UserParams = {
  idUser: string;
};

type TableParams = {
  idTable: tableBackOfficeInterface;
};

type transactionParams = {
  idTransaction: string;
};

type blackListParams = {
  idBlackList: string;
};

export interface indexBackOfficeInterface {
  idBackOffice?: string;
  idUser?: string;
  idTable?: tableBackOfficeInterface;
  idProjectBO?: string;
  idTransaction?: string;
  idBlackList?: string;
}

const DashboardAdmin = () => {
  let { path } = useParams<PathParams>();
  let { idFonds } = useParams<FondsParams>();
  let { idProjet } = useParams<ProjetParams>();
  let { idBackOffice } = useParams<backOfficeParams>();
  let { idUser } = useParams<UserParams>();
  let { idTable } = useParams<TableParams>();
  let { idProjectBO } = useParams<ProjectBOParams>();
  let { idTransaction } = useParams<transactionParams>();
  let { idBlackList } = useParams<blackListParams>();

  const [toggleMenu, setToggleMenu] = useState(true);

  const menuToggleObject: MenuDashBoardAdminBool = {
    toggleMenu: toggleMenu,
    setToggleMenu: setToggleMenu,
  };

  const location = useLocation();

  const routeFunction = () => {
    if (path) {
      switch (path) {
        case "Utilisateurs":
          if (idUser) {
            return <UserManage userId={toInt(idUser)} />;
          } else {
            return <UtilisateursManage />;
          }
        case "prospects":
          return <ProspectUser />;
        case "titre-d-manager":
          return <ShareDIndex />;
        case "sponsor":
          return <SponsorIndex />;
        case "fees":
          return <FeesIndex />;
        case "impacts-management":
          return <IndexImpactData />;
        case "metrics":
          return <MetricsFeeValue />;
        case "Articles":
          return <ArticlesManage />;
        case "ProjectsManage":
          return <ProjectsManage />;
        case "BackOffice":
          let idObject: indexBackOfficeInterface = {};
          if (idBackOffice) {
            idObject = { idBackOffice: idBackOffice };
          } else if (idUser) {
            idObject = { idUser: idUser };
          } else if (idTable) {
            idObject = { idTable: idTable };
          } else if (idProjectBO) {
            idObject = { idProjectBO: idProjectBO };
          } else if (idTransaction) {
            idObject = { idTransaction: idTransaction };
          } else if (idBlackList) {
            idObject = { idBlackList: idBlackList };
          }
          return <IndexBackOffice idObject={idObject} />;
        case "Fonds":
          return <IndexFondsAdmin />;
        case "Securite":
          return <Security />;
        default:
      }
    } else if (idProjet) {
      return <Projet_i idProjet_fonds={idProjet} />;
    } else {
      return <NotFound />;
    }
  };

  const allowState = useContext(AllowState);

  return (
    <div
      className={`dashboard_container_page dashboard_admin ${
        toggleMenu === true ? "!w-[99%]" : "!w-[100%]"
      }`}
    >
      {allowState && allowState.allow ? (
        <MenuDashBoardAdminBoolState.Provider value={menuToggleObject}>
          <React.Fragment>
            <Menu />
            {routeFunction()}
          </React.Fragment>
        </MenuDashBoardAdminBoolState.Provider>
      ) : (
        <React.Fragment>
          <LoginDashBoardAdmin />
        </React.Fragment>
      )}
    </div>
  );
};

export default DashboardAdmin;
