import {
  faFileLines,
  faPieChart,
  faHouse,
  faUser,
  faGear,
  faGift,
  faMoneyBillTransfer,
  faMagnifyingGlass,
  faLandmark,
  faArrowTrendUp,
  faWallet,
  faChartLine
} from "@fortawesome/free-solid-svg-icons";
import { menuDashboard } from "../types/dashboard";

export const menuListClient: menuDashboard[] = [
  {
    icon: faHouse,
    name: "Tableau de bord",
    path: "tableau-de-bord",
  },
  {
    icon: faLandmark,
    name: "Nos fonds",
    path: "nos-fonds",
  },
  {
    icon: faPieChart,
    name: "Portefeuille",
    path: "portefeuille",
  },
  {
    icon: faMoneyBillTransfer,
    name: "Transactions",
    path: "transactions",
  },
  {
    icon: faArrowTrendUp,
    name: "Investir",
    path: "investir",
  },
  {
    name: "Vue d'ensemble",
    path: "overview",
    icon: faMagnifyingGlass,
  }, {
    name: "Compte d'investissement",
    path: "invest-account",
    icon: faWallet
  }, {
    name: "Simulations",
    path: "simulations",
    icon: faChartLine
  }
  // {
  //   icon: faFileLines,
  //   name: "item3",
  //   path: "item3",
  // },
  // {
  //   icon: faFileLines,
  //   name: "item4",
  //   path: "item4",
  // }
];

export const menuBottomListClient: menuDashboard[] = [
  {
    icon: faUser,
    name: "Données personnelles",
    path: "donnees-personnelles",
  },
  {
    icon: faGear,
    name: "Paramètres",
    path: "Paramètres",
  },
  {
    icon: faFileLines,
    name: "Documents",
    path: "Documents",
  },
  {
    icon: faGift,
    name: "Parrainage",
    path: "Parrainage",
  }
];
