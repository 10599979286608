import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { monthList } from "../../../../data/Date";
import {
  monthNumberToNbDay,
  monthNumberToString,
} from "../../../../function/Utils";
import { BackOfficeState } from "../../../Context/AppContext";
import { PrimaryButton } from "../../../commun/Buttons";

const DateManagement = () => {
  const backOfficeState = useContext(BackOfficeState);

  const yearOptions = (n: number): number[] => {
    let year: number[] = [];
    for (let i = 0; i < n; i++) {
      year.push(i + 2021);
    }
    return year;
  };

  const dayOptions = (): number[] => {
    let day: number[] = [];
    for (
      let i = 1;
      i <=
      monthNumberToNbDay(
        new Date(
          2022,
          backOfficeState?.month! === 11 ? 0 : backOfficeState?.month! + 1
        )
      );
      i++
    ) {
      day.push(i);
    }
    return day;
  };

  return (
    <React.Fragment>
      <div className="flex gap-10 flex-col mb-5 flex-wrap mt-5 items-center justify-center lg:justify-around">
        <div className="flex gap-5 min-w-min items-center flex-wrap">
          <PrimaryButton
            // className="px-3 shadow-low py-1 rounded-2xl bg-secondBackgroundColor text-titleColor font-titleFontFamily hover:scale-105 transition-all cursor-pointer"
            onClick={() => {
              backOfficeState?.setYear(new Date(Date.now()).getFullYear());
              backOfficeState?.setMonth(new Date(Date.now()).getMonth());
              backOfficeState?.setDay(new Date(Date.now()).getDate());
            }}
          >
            Aujourd'hui
          </PrimaryButton>
          <select
            className="border rounded-md p-1 cursor-pointer outline-none"
            value={backOfficeState?.year}
            onChange={(e) => backOfficeState?.setYear(parseInt(e.target.value))}
          >
            {yearOptions(10).map((year, key) => (
              <option key={key} value={year}>
                {year}
              </option>
            ))}
          </select>
          <select
            className="border rounded-md p-1 cursor-pointer outline-none"
            value={backOfficeState?.month}
            onChange={(e) =>
              backOfficeState?.setMonth(parseInt(e.target.value))
            }
          >
            {monthList.map((month, key) => (
              <option key={key} value={key}>
                {month}
              </option>
            ))}
          </select>
          <select
            className="border rounded-md p-1 cursor-pointer outline-none"
            value={backOfficeState?.day}
            onChange={(e) => backOfficeState?.setDay(parseInt(e.target.value))}
          >
            {dayOptions().map((day, key) => (
              <option key={key} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>
        <div className="flex md:gap-5 min-w-min items-center">
          <FontAwesomeIcon
            className=" text-secondColor cursor-pointer hover:scale-105 transition-all"
            icon={faChevronLeft}
            onClick={() => {
              if (backOfficeState?.month === 0) {
                backOfficeState?.setYear(backOfficeState?.year - 1);
              }
              backOfficeState?.setMonth(
                backOfficeState.month === 0 ? 11 : backOfficeState.month - 1
              );
            }}
          />
          <p
            className="cursor-pointer w-[95px] text-center text-gray-400 hover:scale-105 transition-all"
            onClick={() => {
              if (backOfficeState?.month === 0) {
                backOfficeState?.setYear(backOfficeState?.year - 1);
              }
              backOfficeState?.setMonth(
                backOfficeState.month === 0 ? 11 : backOfficeState.month - 1
              );
            }}
          >
            {monthNumberToString(
              backOfficeState?.month === 0 ? 11 : backOfficeState?.month! - 1
            )}
          </p>
          <p className="rounded-lg shadow-basic text-titleColor  px-3 -mt-1 w-[95px] text-center">
            {monthNumberToString(backOfficeState?.month!)}
          </p>
          <p
            className="cursor-pointer w-[95px] text-center text-gray-400 hover:scale-105 transition-all"
            onClick={() => {
              if (backOfficeState?.month === 11) {
                backOfficeState?.setYear(backOfficeState?.year + 1);
              }
              backOfficeState?.setMonth(
                backOfficeState.month === 11 ? 0 : backOfficeState.month + 1
              );
            }}
          >
            {monthNumberToString(
              backOfficeState?.month === 11 ? 0 : backOfficeState?.month! + 1
            )}
          </p>
          <FontAwesomeIcon
            className="text-secondColor cursor-pointer hover:scale-105 transition-all"
            icon={faChevronRight}
            onClick={() => {
              if (backOfficeState?.month === 11) {
                backOfficeState?.setYear(backOfficeState?.year + 1);
              }
              backOfficeState?.setMonth(
                backOfficeState.month === 11 ? 0 : backOfficeState.month + 1
              );
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DateManagement;
