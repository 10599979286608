class Page {

  // constructor(public xMax: number, public yMax: number) {
  //   this.xMax = 0;
  //   this.yMax = 0;
  // }
  static getXmax() {
    return window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
  }

  static getYmax() {
    return window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
  }
  static getScrollPage() {
    return (
      (document.documentElement && document.documentElement.scrollTop) ||
      window.pageYOffset ||
      document.body.scrollTop
    );
  }
  static getOffset(element: HTMLElement, adjust = 0) {
    return element.offsetTop - (1 / 2) * this.getYmax() - adjust;
  }
  static scrollFunction(element: string, animation_Name: string, bol_reset: boolean, adjust: number = 0) {
    let e: HTMLElement | null = document.getElementById(element);

    if (e) {
      if (this.getScrollPage() > Page.getOffset(e, adjust)) {
        e.style.animationName = animation_Name;
      }
      if (bol_reset === true && this.getScrollPage() < 300) {
        e.style.animationName = "";
      }
    }

  }

}


export default Page;
