import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import countryList from 'react-select-country-list';
import { situationProfessionelleListe } from '../../../../data/FormulaireInvestisseur';
import { useFetchTokenQuery } from '../../../../redux/features/tokenSlice';
import {
    useEditUserWithAllInfoMutation,
    useGetUserWithAllInfoForCustomerQuery,
} from '../../../../redux/features/userSlice';
import { UpdateUserIdentityDto } from '../../../../types/user';
import AdressCompletion from '../../../commun/formComponent/AdressCompletion';
import InputComponent from '../../../commun/formComponent/InputComponent';
import RadioComponent from '../../../commun/formComponent/RadioComponent';
import SelectComponent from '../../../commun/formComponent/SelectComponent';
import { EtapeForm } from '../../../Context/AppContext';
import { useNotificationContext } from '../../../Context/notification-context';
import FormInvestTemplate from './CommunComponent/FormInvestTemplate';

interface Props {}
const FormulaireEtapeIdentity: React.FC<Props> = () => {
    const etapeObject = useContext(EtapeForm);

    const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();
    const { data: user, isLoading: loading } =
        useGetUserWithAllInfoForCustomerQuery(userData?.id ?? 0, {
            skip: !userData,
        });

    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        getValues,
        control,
        formState: { isValid, errors, isDirty },
    } = useForm<UpdateUserIdentityDto>({
        defaultValues: user
            ? {
                  firstName: user.firstName,
                  lastName: user.lastName,
                  birthDay:
                      user?.userInfo?.birthDay != null
                          ? new Date(user?.userInfo?.birthDay)
                                .toJSON()
                                .split('T')[0]
                          : undefined,
                  birthPlace: user?.userInfo?.birthPlace ?? '',
                  nationality: user?.userInfo?.nationality ?? '',
                  adress: user?.userInfo?.adress ?? '',
                  phone: user?.userInfo?.phone ?? '',
                  sex: user?.sex ?? 'Monsieur',
              }
            : {},
        mode: 'onSubmit',
    });

    const [editUser, { isLoading }] = useEditUserWithAllInfoMutation();

    useEffect(() => {
        if (user) {
            reset({
                firstName: user.firstName,
                lastName: user.lastName,
                birthDay:
                    user?.userInfo?.birthDay != null
                        ? new Date(user?.userInfo?.birthDay)
                              .toJSON()
                              .split('T')[0]
                        : undefined,
                birthPlace: user?.userInfo?.birthPlace ?? '',
                nationality: user?.userInfo?.nationality ?? '',
                adress: user?.userInfo?.adress ?? '',
                phone: user?.userInfo?.phone ?? '',
                sex: user?.sex ?? undefined,
                professionalSituation:
                    user?.userInfo?.professionalSituation ?? '',
                polticalyExposed:
                    user?.userInfo?.politicalyExposed == undefined ||
                    user?.userInfo?.politicalyExposed == null
                        ? undefined
                        : user?.userInfo?.politicalyExposed
                          ? 'true'
                          : 'false',
                politicalyExposedDetails:
                    user?.userInfo?.politicalyExposedDetails ?? '',
                usCitizen: 'false',
            });
        }
    }, [user]);

    const [isValidForm, setIsValidForm] = useState<boolean>(false);

    useEffect(() => {
        if (
            isValid &&
            getValues('birthPlace') &&
            getValues('adress') &&
            getValues('phone') &&
            getValues('usCitizen') === 'false' &&
            ((getValues('polticalyExposed') === 'true' &&
                getValues('politicalyExposedDetails')) ||
                getValues('polticalyExposed') === 'false')
        ) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    }, [watch()]);

    const { showError, showSuccess } = useNotificationContext();

    const onSubmit = async (data: UpdateUserIdentityDto) => {
        // if (isValidForm === false) return;
        if (!isDirty) {
            etapeObject?.setStepInsideStep0(etapeObject.stepInsideStep0 + 1);
            return;
        }
        let dataBis = {
            firstName: data.firstName,
            lastName: data.lastName,
            sex: data.sex,
            id: user?.id,
            userInfo: {
                birthPlace: data.birthPlace,
                birthDay: new Date(data.birthDay),
                adress: data.adress,
                nationality: data.nationality,
                phone: data.phone.toString(),
                politicalyExposedDetails: data.politicalyExposedDetails,
                politicalyExposed:
                    data.polticalyExposed == 'true' ? true : false,
                professionalSituation: data.professionalSituation,
            },
        };

        await editUser(dataBis)
            .unwrap()
            .then((res) => {
                showSuccess('Sauvegardé', 'Données sauvegardées avec succès');
                etapeObject?.setStepInsideStep0(
                    etapeObject.stepInsideStep0 + 1
                );
            })
            .catch((err) => {
                showError('Erreur', 'Erreur lors de la sauvegarde des données');
            });
    };

    const options = useMemo(() => countryList().getData(), []);

    return (
        <FormInvestTemplate
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            isValid={isValidForm}
            loading={isLoading}
        >
            <>
                <h2 className="!text-3xl font-mainFontFamily my-2">
                    Informations personnelles
                </h2>
                <p className="">
                    Ces données doivent correspondre à celles indiquées sur
                    votre pièce d’identité.
                </p>
                <RadioComponent
                    register={register}
                    watch={watch}
                    name="sex"
                    errors={errors}
                    className={{
                        container: 'grid grid-cols-1 md:grid-cols-2',
                    }}
                    values={[
                        {
                            value: 'Monsieur',
                            label: 'Monsieur',
                        },
                        {
                            value: 'Madame',
                            label: 'Madame',
                        },
                    ]}
                >
                    <h3 className="">
                        Civilité <span className="required">*</span>
                    </h3>
                </RadioComponent>

                <div className="grid md:gap-2 ld:grid-cols-2 w-full">
                    <InputComponent
                        register={register}
                        value="firstName"
                        type="text"
                    >
                        <h3>
                            Prénom <span className="required">*</span>
                        </h3>
                    </InputComponent>
                    <InputComponent
                        register={register}
                        value="lastName"
                        type="text"
                    >
                        <h3>
                            Nom <span className="required">*</span>
                        </h3>
                    </InputComponent>
                </div>
                <InputComponent
                    register={register}
                    type="date"
                    value="birthDay"
                >
                    <h3>
                        Date de naissance <span className="required">*</span>
                    </h3>
                </InputComponent>

                <AdressCompletion
                    control={control}
                    register={register}
                    value="birthPlace"
                    palceholder="Ville, Pays"
                >
                    <h3>
                        Lieu de naissance <span className="required">*</span>
                    </h3>
                </AdressCompletion>
                <SelectComponent
                    optionValues={options}
                    register={register}
                    control={control}
                    value="nationality"
                >
                    <h3>
                        Nationalité <span className="required">*</span>
                    </h3>
                </SelectComponent>

                <AdressCompletion
                    control={control}
                    register={register}
                    value="adress"
                >
                    <h3>
                        Adresse actuelle <span className="required">*</span>
                    </h3>
                </AdressCompletion>
                <h3>
                    Numéro de téléphone <span className="required">*</span>
                </h3>
                <PhoneInput
                    placeholder="Entrer votre numéro de téléphone"
                    className="w-full border outline-none border-gray-300 rounded-md p-2"
                    required={true}
                    defaultCountry={watch('nationality') as any}
                    // value={value}
                    // onChange={setValue}

                    onChange={(e) => setValue('phone', e)}
                    value={watch('phone')}
                />
                <SelectComponent
                    register={register}
                    value={'professionalSituation'}
                    control={control}
                    optionValues={situationProfessionelleListe.map((v) => {
                        return {
                            value: v,
                            label: v,
                        };
                    })}
                >
                    <h3>
                        Votre situation professionnelle{' '}
                        <span className="required">*</span>
                    </h3>
                </SelectComponent>
                <RadioComponent
                    register={register}
                    watch={watch}
                    name="usCitizen"
                    values={[
                        {
                            value: 'true',
                            label: 'Oui',
                        },
                        {
                            value: 'false',
                            label: 'Non',
                        },
                    ]}
                >
                    <h3>
                        Avez-vous le statut de contribuable "US Person" ?{' '}
                        <span className="required">*</span>
                    </h3>
                    <p className=" italic text-gray-500">
                        Une US Person est une personne qualifiée de contribuable
                        américain au regard de la législation fiscale aux
                        Etats-Unis.
                    </p>
                </RadioComponent>
                {watch('usCitizen') == 'true' ? (
                    <p>
                        Vous ne pouvez malheureusement pas investir dans nos
                        fonds.
                    </p>
                ) : null}

                <RadioComponent
                    register={register}
                    watch={watch}
                    name="polticalyExposed"
                    values={[
                        {
                            value: 'true',
                            label: 'Oui',
                        },
                        {
                            value: 'false',
                            label: 'Non',
                        },
                    ]}
                >
                    <>
                        <h3>
                            Êtes-vous une personne politiquement exposée ?{' '}
                            <span className="required">*</span>
                        </h3>
                        <p className=" italic text-gray-500">
                            Une personne exerçant ou ayant exercé une haute
                            fonction publique, ou étroitement associée à une
                            telle personne.
                        </p>
                    </>
                </RadioComponent>
                <InputComponent
                    register={register}
                    required={
                        watch('polticalyExposed') == 'true' ? true : false
                    }
                    value="politicalyExposedDetails"
                >
                    <h3
                        className={` !text-base !font-mainFontFamily !font-normal ${
                            watch('polticalyExposed') === 'true'
                                ? ''
                                : 'text-gray-400'
                        }`}
                    >
                        Précisez{' '}
                        {watch('polticalyExposed') === 'true' ? (
                            <span className="required">*</span>
                        ) : (
                            ''
                        )}
                    </h3>
                </InputComponent>
            </>
        </FormInvestTemplate>
    );
};

export default FormulaireEtapeIdentity;
