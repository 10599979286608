import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { KnowledgeQuestionDto } from "../../types/KnowledgeDto";

interface KnowledgeState {
    questions: KnowledgeQuestionDto[];
    loading: boolean;
    errors: any;
}

const initialState: KnowledgeState = {
    questions: [],
    loading: false,
    errors: null,
}

// actions are processes that get data from backend
export const getQuestions = createAsyncThunk<KnowledgeQuestionDto[]>(
    "knowlegde/getQuestions",
    async (_, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}knowledge/questions`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const knowledgeSlice = createSlice({
    name: "knowledge",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getQuestions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getQuestions.fulfilled, (state, action) => {
            state.loading = false;
            state.questions = action.payload;
        })
        builder.addCase(getQuestions.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload;
        })
    }
})

export default knowledgeSlice.reducer;