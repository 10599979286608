import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/query";

export function baseQueryHandleError(baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>) {
    return async (arg: string | FetchArgs, api: any, extraOptions: any) => {
        let result = await baseQuery(arg, api, extraOptions);
        if ((result?.error?.data as any)?.error === "TokenExpiredError") {
            const currentLocation = window.location
            const currentPath = currentLocation.pathname + currentLocation.search +
                (currentLocation.search === "" ? "?sessionExpired=1" : "&sessionExpired=1");


            window.location.href = currentPath

        }
        return result;
    };
}