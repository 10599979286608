import React, { useContext, useEffect, useState } from "react";
import { MenuDashBoardAdminBoolState } from "../Context/AppContext";
import WrapComponentDashboardUser from "../DashboardUser/commun/WrapComponentDashboardUser";
import { ProspectUserDto } from "../../types/prospectUser";
import { typeFiltration } from "../../types/BackOffice";
import { typeOrderOrFiltration } from "../../types/fonds";
import FiltrationColumnTable, {
  filtrationInterface,
} from "./BackOffice/Components/FiltrationColumnTable";
import {
  useDeleteProspectUserMutation,
  useGetProspectUsersQuery,
} from "../../redux/features/prospectUserSlice";
import { filterFunctions, formatDate, isEmpty } from "../../function/Utils";
import FilterApparition from "./BackOffice/Components/FilterApparition";
import ExportComponent from "../commun/ExportComponent";
import Loading from "../commun/Loading";
import { NumberParam, useQueryParam } from "use-query-params";
import useDeleteItem from "../commun/CustomHook/useDeleteItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import PopUp from "../commun/PopUp";

const ProspectUser = () => {
  const menuBoolState = useContext(MenuDashBoardAdminBoolState);

  return (
    <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto">
      <WrapComponentDashboardUser
        title="Gestion des prospects"
        description=""
        classNameHeader={`${!menuBoolState?.toggleMenu ? "!pl-[40px]" : ""}`}
        classNameContainer={`${!menuBoolState?.toggleMenu ? "" : ""}`}
      >
        <div className="overflow-auto h-[83vh] md:h-[88vh] relative px-2">
          <ProspectTable />
        </div>
      </WrapComponentDashboardUser>
    </div>
  );
};

export default ProspectUser;

function ProspectTable() {
  const classNameObejct = {
    head: "text-sm text-gray-900 px-6 py-4 text-left font-semibold",
    item: "text-sm font-light px-6 py-4 whitespace-nowrap ",
  };

  const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
  const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);
  const [filterColumn, setFilterColumn] = useState<
    filtrationInterface<ProspectUserDto>[]
  >([
    {
      element: "createdAt",
      type: typeFiltration.date,
      value: "croissant",
      orderOrFiltration: typeOrderOrFiltration.order,
    },
  ]);

  const [search, setSearch] = useState("");

  const { data: prospectsWithNb, isLoading: isLoadingProspect } =
    useGetProspectUsersQuery();

  const prospects = prospectsWithNb?.rows;
  const nbItems = prospectsWithNb?.count;

  const [prospectFiltered, setProspectFiltered] = useState<ProspectUserDto[]>(
    []
  );

  const filtrationFunction = () => {
    let transactionDataFiltered_init: ProspectUserDto[] = prospects ?? [];
    for (let item of filterColumn) {
      transactionDataFiltered_init = filterFunctions(
        transactionDataFiltered_init,
        item.element,
        item.type,
        item.value
      );
    }
    setProspectFiltered(transactionDataFiltered_init);
  };

  useEffect(() => {
    if (prospects) {
      filtrationFunction();
    }
  }, [filterColumn, prospects]);

  const [editToggle, setToggleEdit] = useState<boolean>(false);

  return (
    <>
      <div className="mt-5 relative ">
        {editToggle ? (
          <EditProspectUser open={editToggle} setOpen={setToggleEdit} />
        ) : null}
        <FilterApparition
          startIntervalNbItems={startIntervalNbItems}
          setNbItemsShowed={setNbItemsShowed}
          nbItemsShowed={nbItemsShowed}
          setStartIntervalNbItems={setStartIntervalNbItems}
          array={prospectFiltered!}
        >
          <div className="relative md:absolute md:top-[-8px] md:right-3">
            <ExportComponent
              headers={["Nom", "Prénom", "Email", "Date"]}
              title="Liste des prospects"
              data={prospectFiltered.map((val) => ({
                Nom: val.lastName,
                Prénom: val.firstName,
                Email: val.email,
                Date: formatDate(new Date(val.createdAt)),
              }))}
            />
          </div>
        </FilterApparition>
        <div className="flex mt-3 flex-col max-w-full overflow-x-auto">
          <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
            <div className="py-2 inline-block min-w-full">
              <div className="overflow-hidden">
                {/* <p>
  Valeur total du fonds{" "}
  {printLargeValue(
    projectValorisationInfoData?.valorisationByProjects?.toFixed(
      2
    )
  )}
</p> */}
                <table className="min-w-full">
                  <thead className="bg-white border-b border-t">
                    <tr>
                      <th scope="col" className={classNameObejct.head}>
                        #
                      </th>
                      <th scope="col" className={classNameObejct.head}>
                        Nom
                      </th>
                      <th scope="col" className={classNameObejct.head}>
                        Prénom
                      </th>
                      <th scope="col" className={classNameObejct.head}>
                        Email
                      </th>

                      <FiltrationColumnTable
                        element={"createdAt"}
                        type={typeFiltration.date}
                        setFilterColumn={setFilterColumn}
                        columnName={() => (
                          <p className="inline-block text-center">Date</p>
                        )}
                        orderOrFiltration={typeOrderOrFiltration.order}
                        textLeft={true}
                      />
                    </tr>
                  </thead>
                  <tbody className="bg-white ">
                    {isLoadingProspect ? (
                      <tr className="">
                        <td colSpan={5} className="pt-10 text-center mx-auto">
                          <Loading />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {prospectFiltered && (
                          <>
                            {prospectFiltered.length === 0 ? (
                              <tr className="w-full mx-auto">
                                <td
                                  colSpan={5}
                                  className="text-center text-gray-500 text-xl  pt-10"
                                >
                                  Aucun prospect touvée
                                </td>
                              </tr>
                            ) : (
                              <React.Fragment>
                                {prospectFiltered?.map((p, key) => (
                                  <RowElement
                                    setOpen={setToggleEdit}
                                    p={p}
                                    key={key}
                                    number={key}
                                  />
                                ))}
                              </React.Fragment>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function RowElement({
  p,
  number,
  setOpen,
}: {
  p: ProspectUserDto;
  number: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const classNameObejct = {
    item: "text-sm  font-light px-6 py-4 whitespace-nowrap",
  };

  const [prospectUserId, setQueryParam] = useQueryParam(
    "prospectUserId",
    NumberParam
  );

  return (
    <tr
      onClick={() => {
        setQueryParam(p.id);
        setOpen(true);
      }}
      className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
        number % 2 === 0 ? "bg-secondBackgroundColor" : "bg-white "
      } `}
    >
      <td className={`${classNameObejct.item}`}>{number + 1}</td>
      <td className={`${classNameObejct.item}`}>{p.lastName}</td>
      <td className={`${classNameObejct.item}`}>{p.firstName}</td>
      <td className={`${classNameObejct.item} `}>{p.email}</td>
      <td className={`${classNameObejct.item} text-center `}>
        {!!p.createdAt &&
          (p.createdAt instanceof Date
            ? p.createdAt.toLocaleDateString("en-GB")
            : new Date(p.createdAt).toLocaleDateString("en-GB"))}
      </td>
    </tr>
  );
}

function EditProspectUser({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [prospectUserId, setQueryParam] = useQueryParam(
    "prospectUserId",
    NumberParam
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [
    deleteProspectUser,
    { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
  ] = useDeleteProspectUserMutation();

  const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
    prospectUserId: number;
  }>({
    deleteItem: () => deleteProspectUser(prospectUserId || 0),
    toDoIfSuccess: () => setOpen(false),

    message: "prospect",
    isLoading: isLoadingDelete,
  });

  useEffect(() => {
    if (prospectUserId) {
      setOpenDelete(true);
    }
  }, [prospectUserId]);

  return (
    // <PopUp
    //   open={open}
    //   setOpen={setOpen}
    //   buttonBoolean={false}
    //   submitItemName={"Ajouter"}
    //   title={() => {
    //     return (
    //       <>
    //         <h3 className="text-2xl font-semibold leading-6 text-gray-900">
    //           Editer ce prospect
    //         </h3>
    //         {prospectUserId ? (
    //           <FontAwesomeIcon
    //             onClick={() => setOpenDelete(true)}
    //             icon={faTrash}
    //             className="absolute top-4 md:top-0 cursor-pointer left-4 transition-all hover:scale-105"
    //           />
    //         ) : null}
    //       </>
    //     );
    //   }}
    // >
    <DeleteComponent title="ce prospect" />
    //   <form
    //     // onSubmit={handleSubmit(onSubmit)}
    //     className="formTemplateSingUp"
    //     style={{ width: `80%` }}
    //   >
    //     {/* {isLoadingTransaction ? (
    //       <Loading />
    //     ) : ( */}
    //     <>
    //       <div className={`w-full mx-auto`}></div>
    //     </>
    //     {/* )} */}
    //   </form>
    // </PopUp>
  );
}
