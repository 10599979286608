import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NumberParam, useQueryParam } from 'use-query-params';
import { formatDate } from '../../../../function/Utils';
import {
    useAddShareholderMutation,
    useDeleteShareholderMutation,
    useGetParentCompaniesQuery,
    useGetShareholderByIdQuery,
    useGetShareholderByNameQuery,
    useUpdateShareholderMutation,
} from '../../../../redux/features/projectSlice';
import { ParentCompanyDto } from '../../../../types/parentCompanyType';
import {
    CreateShareholderDto,
    ProjectChildDto,
    UpdateShareholderDto,
} from '../../../../types/shareholderType';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import useDebouncedEffect from '../../../commun/CustomHook/useDebouncedEffect';
import useDeleteItem from '../../../commun/CustomHook/useDeleteItem';
import InputComponent from '../../../commun/formComponent/InputComponent';
import SelectComponent from '../../../commun/formComponent/SelectComponent';
import Loading from '../../../commun/Loading';
import PopUp from '../../../commun/PopUp';
import { useNotificationContext } from '../../../Context/notification-context';
import TableComponent from '../../BackOffice/Components/TableComponent';

interface FilterShareholder {
    shareholderName: string;
}

const Shareholder = () => {
    const formState = useForm<FilterShareholder>({
        defaultValues: {
            shareholderName: '',
        },
    });

    const { watch, register, control } = formState;

    const [shareholderNameDebounce, setShareholderNameDebounce] = useState('');

    useDebouncedEffect(
        () => {
            setShareholderNameDebounce(watch('shareholderName'));
        },
        [watch('shareholderName')],
        500
    );

    const { data: shareholders } = useGetShareholderByNameQuery(
        shareholderNameDebounce
    );

    const [shareholderId, setShareholderId] = useQueryParam(
        'shareholderId',
        NumberParam
    );

    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openShow, setOpenShow] = useState(false);

    const classNameObejct = {
        item: 'text-sm  font-light px-1 py-2 whitespace-nowrap',
    };

    return (
        <div className="w-full">
            {openAdd ? (
                <AddShareholder open={openAdd} setOpen={setOpenAdd} />
            ) : null}
            {openEdit ? (
                <EditShareholder open={openEdit} setOpen={setOpenEdit} />
            ) : null}
            {openShow ? (
                <ShowInfoShareholder open={openShow} setOpen={setOpenShow} />
            ) : null}
            <div className="formTemplateSingUp">
                <InputComponent value={'shareholderName'} register={register}>
                    <LabelComponentForm>Actionnaire</LabelComponentForm>
                </InputComponent>
            </div>
            <div className="relative w-full">
                <div className="absolute top-[19px] right-4 z-10">
                    <PrimaryButton onClick={() => setOpenAdd(true)}>
                        Add
                    </PrimaryButton>
                </div>
            </div>
            <TableComponent height="h-[40vh]" head={['Nom', 'Action']}>
                <tbody>
                    {shareholders?.length === 0 && (
                        <tr>
                            <td
                                className={`${classNameObejct.item} text-center`}
                            >
                                Aucun résultat
                            </td>
                        </tr>
                    )}
                    {shareholders?.map((shareholder, key) => (
                        <tr
                            key={shareholder.id}
                            onClick={() => {
                                setShareholderId(shareholder.id);
                            }}
                            className={`border-b w-full cursor-pointer hover:bg-slate-500 ${
                                key % 2 === 1
                                    ? 'bg-secondBackgroundColor'
                                    : 'bg-white '
                            } `}
                        >
                            <td className={`${classNameObejct.item}`}>
                                {shareholder.fullName}
                            </td>
                            <td
                                className={`${classNameObejct.item} flex  gap-2`}
                            >
                                <WhiteButton onClick={() => setOpenShow(true)}>
                                    Show
                                </WhiteButton>
                                <WhiteButton onClick={() => setOpenEdit(true)}>
                                    Edit
                                </WhiteButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </TableComponent>
        </div>
    );
};

function ShowInfoShareholder({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [shareholderId, setShareholderId] = useQueryParam(
        'shareholderId',
        NumberParam
    );

    const { data: shareholder, isLoading: isLoadingShareholder } =
        useGetShareholderByIdQuery(shareholderId || 0, {
            skip: shareholderId === undefined,
        });

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            width="w-full md:w-10/12"
            title={() => {
                return (
                    <div className="text-center text-mainColor font-bold text-2xl">
                        Information sur l'actionnaire{' '}
                        <span className=" text-mainColor">
                            {shareholder?.fullName}
                        </span>
                    </div>
                );
            }}
        >
            <div className="">
                {isLoadingShareholder ? (
                    <Loading />
                ) : (
                    <div className="w-full flex flex-col gap-3">
                        <DisplayProjectsAssociated
                            projects={shareholder?.projects || []}
                        />
                        <DisplayParentCompaniesAssociated
                            parentCompanies={shareholder?.parentCompanies || []}
                        />
                        <div className="my-4 flex justify-center items-center">
                            <PrimaryButton onClick={() => setOpen(false)}>
                                Fermer
                            </PrimaryButton>
                        </div>
                    </div>
                )}
            </div>
        </PopUp>
    );
}

function DisplayParentCompaniesAssociated({
    parentCompanies,
    removeParentCompany,
}: {
    parentCompanies: ParentCompanyDto[];
    removeParentCompany?: (id: number) => Promise<void>;
}) {
    const head = ['Nom', 'Kbis'];

    useEffect(() => {
        if (removeParentCompany !== undefined && !head.includes('Action')) {
            head.push('Action');
        }
    }, [removeParentCompany]);

    const classNameObejct = {
        item: 'text-sm text-start  font-light px-1 py-3 whitespace-nowrap',
    };

    return (
        <>
            <h3 className="text-lg w-full text-center font-mainFontFamily">
                Société mère associés
            </h3>
            <TableComponent height="h-[30vh]" head={head}>
                <tbody>
                    {parentCompanies?.length === 0 && (
                        <tr>
                            <td
                                className="text-center"
                                colSpan={removeParentCompany ? 3 : 2}
                            >
                                Aucun résultat
                            </td>
                        </tr>
                    )}
                    {parentCompanies?.map((parentCompany, key) => (
                        <tr
                            className={`border-b w-full cursor-pointer hover:bg-slate-500 ${
                                key % 2 === 1
                                    ? 'bg-secondBackgroundColor'
                                    : 'bg-white '
                            } `}
                            key={parentCompany.id}
                        >
                            <td className={`${classNameObejct.item}`}>
                                {parentCompany.name}
                            </td>
                            <td className={`${classNameObejct.item}`}>
                                {parentCompany.kBis}
                            </td>
                            {removeParentCompany ? (
                                <td>
                                    <WhiteButton
                                        onClick={() =>
                                            removeParentCompany(
                                                parentCompany.id
                                            )
                                        }
                                    >
                                        Remove
                                    </WhiteButton>
                                </td>
                            ) : null}
                        </tr>
                    ))}
                </tbody>
            </TableComponent>
        </>
    );
}

export function DisplayProjectsAssociated({
    projects,
}: {
    projects: ProjectChildDto[];
}) {
    const classNameObejct = {
        item: 'text-sm text-start  font-light px-1 py-3 whitespace-nowrap',
    };
    return (
        <>
            <h3 className="text-lg font-mainFontFamily">Projets associés</h3>
            <TableComponent
                height="h-[30vh]"
                head={['Nom', 'Valorisation', 'Date de fin théorique']}
            >
                <tbody>
                    {projects?.length === 0 && (
                        <tr>
                            <td className="text-center" colSpan={3}>
                                Aucun résultat
                            </td>
                        </tr>
                    )}
                    {projects?.map((project, key) => (
                        <tr
                            className={`border-b w-full cursor-pointer hover:bg-slate-500 ${
                                key % 2 === 1
                                    ? 'bg-secondBackgroundColor'
                                    : 'bg-white '
                            } `}
                            key={project.id}
                        >
                            <td className={`${classNameObejct.item}`}>
                                {project.name}
                            </td>
                            <td className={`${classNameObejct.item}`}>
                                {project?.valorisation?.toFixed(2) || 0} €
                            </td>
                            <td className={`${classNameObejct.item}`}>
                                {formatDate(new Date(project.dateEnd))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </TableComponent>
        </>
    );
}

export default Shareholder;

export function AddShareholder({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const formState = useForm<CreateShareholderDto>();

    const { register, handleSubmit, watch, control, setValue } = formState;

    const { showError, showSuccess } = useNotificationContext();

    const [addShareholder, { isLoading: isLoadindAdd }] =
        useAddShareholderMutation();

    const onSubmit = async (data: CreateShareholderDto) => {
        await addShareholder(data)
            .unwrap()
            .then(() => {
                showSuccess('Created', 'Shareholder added');
                setOpen(false);
            })
            .catch(() => {
                showError('Error', 'Error adding shareholder');
            });
    };

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            title={() => {
                return (
                    <div className="text-center text-mainColor font-bold text-2xl">
                        Add Actionnaire
                    </div>
                );
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp"
            >
                <InputComponent value={'fullName'} register={register}>
                    <LabelComponentForm>Full Name</LabelComponentForm>
                </InputComponent>

                <div className="w-full mt-4 flex justify-center items-center gap-2 ">
                    <WhiteButton onClick={() => setOpen(false)}>
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit" loading={isLoadindAdd}>
                        Add
                    </PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
}

function EditShareholder({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [shareholderId, setShareholderId] = useQueryParam(
        'shareholderId',
        NumberParam
    );

    const { data: shareholder, isLoading: isLoadingShareholder } =
        useGetShareholderByIdQuery(shareholderId || 0, {
            skip: shareholderId === undefined,
        });

    const formState = useForm<UpdateShareholderDto>();

    const { register, handleSubmit, watch, control, reset, setValue } =
        formState;

    useEffect(() => {
        if (shareholder) {
            reset(shareholder);
        }
    }, [shareholder]);

    const { showError, showSuccess } = useNotificationContext();

    const [editShareholder, { isLoading: isLoadindEdit }] =
        useUpdateShareholderMutation();

    const onSubmit = async (data: UpdateShareholderDto) => {
        await editShareholder(data)
            .unwrap()
            .then(() => {
                showSuccess('Edited', 'Shareholder added');
                setShareholderId(undefined);
                setOpen(false);
            })
            .catch(() => {
                showError('Error', 'Error adding shareholder');
            });
    };

    const addParentCompany = async (id: number) => {
        const request: UpdateShareholderDto = {
            id: shareholder?.id!,
            addParentCompanyId: id,
            fullName: shareholder?.fullName!,
        };
        await editShareholder(request)
            .unwrap()
            .then(() => {
                setValue('addParentCompanyId', undefined);
            })
            .catch(() => {
                showError('Error', 'Error adding parent company');
            });
    };

    const removeParentCompany = async (id: number) => {
        const request: UpdateShareholderDto = {
            id: shareholder?.id!,
            removeParentCompanyId: id,
            fullName: shareholder?.fullName!,
        };
        await editShareholder(request)
            .unwrap()
            .then(() => {
                setValue('removeParentCompanyId', undefined);
            })
            .catch(() => {
                showError('Error', 'Error removing parent company');
            });
    };

    const [
        deleteShareholder,
        { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
    ] = useDeleteShareholderMutation();

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        shareholderId: number;
    }>({
        deleteItem: () => deleteShareholder(shareholder?.id!),
        toDoIfSuccess: () => setOpen(false),
        message: 'Shareholder',
        isLoading: isLoadingDelete,
    });

    const { data: parentCompanies } = useGetParentCompaniesQuery();

    const parentCompaniesOptions = useMemo(() => {
        const parentCompaniesFiltered = parentCompanies?.filter(
            (p) =>
                shareholder?.parentCompanies?.findIndex(
                    (pc) => pc.id === p.id
                ) === -1
        );
        return (
            parentCompaniesFiltered?.map((p) => ({
                value: p.id,
                label: `${p.name} - ${p.kBis}`,
            })) || []
        );
    }, [parentCompanies, shareholder]);

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            title={() => {
                return (
                    <>
                        <div className="text-center text-mainColor font-bold text-2xl">
                            Edit Actionnaire
                        </div>
                        {shareholder && shareholder?.id ? (
                            <FontAwesomeIcon
                                onClick={() => setOpenDelete(true)}
                                icon={faTrash}
                                className="absolute top-[-10px] cursor-pointer right-5 transition-all hover:scale-105"
                            />
                        ) : null}
                    </>
                );
            }}
        >
            <DeleteComponent title="cette actionnaire" />
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp"
            >
                {isLoadingShareholder ? (
                    <Loading />
                ) : (
                    <>
                        <InputComponent value={'fullName'} register={register}>
                            <LabelComponentForm>Full Name</LabelComponentForm>
                        </InputComponent>
                        <div className="grid w-full mb-4 md:gap-x-4 place-items-end md:grid-cols-[1fr,auto]">
                            <SelectComponent
                                register={register}
                                value={'addParentCompanyId'}
                                control={control}
                                container={true}
                                watch={watch}
                                required={false}
                                optionValues={parentCompaniesOptions || []}
                            >
                                <LabelComponentForm>
                                    Add Société mère
                                </LabelComponentForm>
                            </SelectComponent>
                            <div className=" md:pb-3">
                                <PrimaryButton
                                    disabled={!watch('addParentCompanyId')}
                                    onClick={() =>
                                        addParentCompany(
                                            watch('addParentCompanyId') || 0
                                        )
                                    }
                                >
                                    Add
                                </PrimaryButton>
                            </div>
                        </div>

                        <DisplayParentCompaniesAssociated
                            parentCompanies={shareholder?.parentCompanies || []}
                            removeParentCompany={removeParentCompany}
                        />
                        <div className="w-full mt-4 flex justify-center items-center gap-2 ">
                            <WhiteButton onClick={() => setOpen(false)}>
                                Cancel
                            </WhiteButton>
                            <PrimaryButton
                                type="submit"
                                loading={isLoadindEdit}
                            >
                                Edit
                            </PrimaryButton>
                        </div>
                    </>
                )}
            </form>
        </PopUp>
    );
}
