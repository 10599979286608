import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { debounce } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import {
    BooleanParam,
    NumberParam,
    StringParam,
    useQueryParam,
} from 'use-query-params';
import { fundingTypeData } from '../../../data/ProjectForm';
import {
    isEmpty,
    stringToFloat,
    stringToInt,
    transformDate,
} from '../../../function/Utils';
import { useGetFondsQuery } from '../../../redux/features/fondsSlice';
import { useGetPlatformsQuery } from '../../../redux/features/platformSlice';
import {
    useAddProjectMutation,
    useGetCashIdsQuery,
    useGetParentCompaniesQuery,
    useGetProjectAllInfoForFormDataQuery,
    useGetProjectSectorsQuery,
    useGetShareholdersQuery,
} from '../../../redux/features/projectSlice';
import { useAddTransactionMutation } from '../../../redux/features/transactionSlice';
import { TypePayement } from '../../../types/fonds';
import { ParentCompanyDto } from '../../../types/parentCompanyType';
import {
    CreateProjectDtoForm,
    ProjectDto,
    ProjectStatus,
} from '../../../types/project';
import { ShareholderDto } from '../../../types/shareholderType';
import {
    CreateTransactionDto,
    TransactionStatus,
    TypeTransaction,
} from '../../../types/transactions';
import { useNotificationContext } from '../../Context/notification-context';
import CheckboxArrayComponent from '../../DashboardUser/Profile/FormInvest/FormComponentForArray/CheckBoxArrayComponent';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import Loading from '../../commun/Loading';
import CheckboxComponent from '../../commun/formComponent/CheckboxComponent';
import FieldArray from '../../commun/formComponent/FieldArray';
import InputComponent from '../../commun/formComponent/InputComponent';
import PickCoordonates from '../../commun/formComponent/PickCoordonates';
import RadioComponent from '../../commun/formComponent/RadioComponent';
import RemoveFieldArrayComponent from '../../commun/formComponent/RemoveFieldArrayComponent';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import SelectMultiComponent from '../../commun/formComponent/SelectMultiComponent';
import TextAreaComponent from '../../commun/formComponent/TextAreaComponent';
import TextEditorComponent from '../../commun/formComponent/TextEditorComponent';
import AddProjectSubSector from './AddProjectSubSector';
import { AddParentCompany } from './InvestTools/ParentCompany';
import { AddShareholder } from './InvestTools/Shareholder';

export function AddProject() {
    const { showError, showSuccess } = useNotificationContext();

    const [fondsQuery, setFondsQuery] = useQueryParam('fonds', NumberParam);

    const [fondsIdInit, setFondsIdInit] = useState<number>(0);

    const [projectIdQueryParams, setProjectIdQueryParams] = useQueryParam(
        'projectId',
        NumberParam
    );

    const [queryParams, setQueryParams] = useQueryParam(
        'projectManage',
        StringParam
    );

    const [pageQuery, setPageQuery] = useQueryParam(
        'pageEditProject',
        StringParam
    );

    const [returnQuery, setReturnQuery] = useQueryParam(
        'returnBackoffice',
        BooleanParam
    );

    useEffect(() => {
        if (fondsQuery) {
            setFondsIdInit(fondsQuery);
        }
    }, [fondsQuery]);

    const navigate = useNavigate();
    const { data: fondsData } = useGetFondsQuery();
    const { data: platformData } = useGetPlatformsQuery();

    const [loading, setLoading] = useState<boolean>(false);

    const { data: formData, isLoading: isLoadingFormData } =
        useGetProjectAllInfoForFormDataQuery();

    const { seniorityLevel, projectMarketTypology } = formData ?? {};

    const { data: projectSectors } = useGetProjectSectorsQuery();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        reset,
        control,
    } = useForm<CreateProjectDtoForm>({
        defaultValues: {
            duration: 24,
            dateEnd: new Date(Date.now() + 1000 * 3600 * 24 * 365 * 2)
                .toJSON()
                .split('T')[0],
            dateStart: new Date(Date.now()).toJSON().split('T')[0],
            interests: [],
            fondsId: fondsIdInit,
        },
    });

    const {
        field: { onChange: onChangeComment, value: comment },
    } = useController<CreateProjectDtoForm>({
        name: 'content',
        control: control,
    });

    const marketTypologies = useMemo(() => {
        return projectMarketTypology?.filter((p) =>
            p.fonds?.map((f) => f.id)?.includes(watch('fondsId'))
        ) &&
            projectMarketTypology?.filter((p) =>
                p.fonds?.map((f) => f.id)?.includes(watch('fondsId'))
            )?.length > 0
            ? projectMarketTypology
                  ?.filter((p) =>
                      p.fonds?.map((f) => f.id)?.includes(watch('fondsId'))
                  )
                  .concat(
                      projectMarketTypology.filter((p) => p.value === 'Autre')
                  )
            : [];
    }, [watch('fondsId')]);

    const setDefautlValues = useCallback(
        (fondsId: number): void => {
            reset({
                ...watch(),
                fondsId: fondsId,
                duration: 24,
                delayAccepted: 6,
                compoundInterest: 365,
                businessContributorIncome: 0,
                confidential: 'false',
                compoundInterestBool: false,
                payment: TypePayement.annual,
                status: ProjectStatus.subscriptionOnGoing,
                dateEnd: new Date(Date.now() + 1000 * 3600 * 24 * 365 * 2)
                    .toJSON()
                    .split('T')[0],
                dateStart: new Date(Date.now()).toJSON().split('T')[0],
                platformId: platformData ? platformData[0].id : 0,
                projectInfo: {
                    monthProject: 'false',
                    projectMarketTypology:
                        marketTypologies && marketTypologies.length > 0
                            ? [marketTypologies?.[0].id]
                            : [],
                    seniorityLevel: [6],
                    obligationType: 'Obligation simple',
                },
                sectorId:
                    fondsId === 1
                        ? projectSectors?.find((s) => s.value === 'Immobilier')
                              ?.id
                        : undefined,
                interests: [
                    {
                        date: new Date(Date.now()).toJSON().split('T')[0],

                        fondsId: fondsId,
                    },
                ],
            });
        },
        [watch('fondsId')]
    );

    const [addProject, {}] = useAddProjectMutation();

    const [addTransaction, {}] = useAddTransactionMutation();

    const { data: parentCompanies } = useGetParentCompaniesQuery();

    const { data: shareholders } = useGetShareholdersQuery();

    let { data: cashIds } = useGetCashIdsQuery();

    useEffect(() => {
        if (
            !isEmpty(fondsData) &&
            fondsIdInit &&
            platformData &&
            projectSectors
        ) {
            setDefautlValues(watch('fondsId') || fondsIdInit);
        }
    }, [fondsData, fondsIdInit, platformData, formData, watch('fondsId')]);

    const onSubmit = async (data: CreateProjectDtoForm) => {
        setLoading((curr) => !curr);
        // const files = data?.file;
        // const coverImg = data?.cover?.[0];
        const amount = data?.amount;
        delete data.amount;
        // delete data.cover;
        // delete data.file;

        await addProject({
            ...data,
            notationPlatform: data.notationPlatform,
            dateEnd: transformDate(data.dateEnd),
            address: data.address,
            sectorId: stringToInt(data.sectorId),
            subSectorId: data?.subSectorId
                ? stringToInt(data?.subSectorId)
                : undefined,
            duration: stringToFloat(data.duration!),
            delayAccepted: stringToFloat(data.delayAccepted!),
            dateStart: transformDate(data.dateStart),
            fondsId: stringToFloat(data.fondsId),
            platformId: data.platformId,
            businessContributorIncome: stringToFloat(
                data.businessContributorIncome!
            ),
            confidential: data.confidential === 'true' ? true : false,
            projectInfo: {
                ...data.projectInfo,
                monthProject:
                    data?.projectInfo?.monthProject === 'true' ? true : false,
                capital:
                    data?.projectInfo?.capital !== undefined ||
                    data?.projectInfo?.capital !== ''
                        ? parseInt(
                              data?.projectInfo?.capital! as unknown as string
                          )
                        : undefined,
            },
            interests: data.interests.map((pi, index) => {
                if (index === 0) {
                    return {
                        value: stringToFloat(pi.value),
                        fondsId: stringToFloat(data.fondsId),
                        // projectId: stringToInt(data.id),
                        date: transformDate(data.dateStart),
                    };
                }
                return {
                    value: stringToFloat(pi.value),
                    fondsId: stringToInt(data.fondsId),
                    // projectId: stringToInt(data.id),
                    date: transformDate(pi.date),
                };
            }),
        })
            .unwrap()
            .then(async (res) => {
                setProjectIdQueryParams((res as ProjectDto).id!);
                try {
                    if (amount) {
                        let dataBis: CreateTransactionDto = {
                            to: (res as ProjectDto).id,
                            from: cashIds?.find(
                                (v) => v.fondsId === (res as ProjectDto).fondsId
                            )?.id!,
                            amount: amount,
                            date: transformDate(data.dateStart),
                            type: TypeTransaction.cashProject,
                            fondsId: (res as ProjectDto).fondsId,
                            comment: 'Initial amount',
                            status: TransactionStatus.VALIDATED,
                        };
                        await addTransaction(dataBis)
                            .unwrap()
                            .then(() => {
                                showSuccess(
                                    'Created',
                                    'Transaction subscription created'
                                );
                            })
                            .catch((res) => {
                                showError(
                                    'Error',
                                    'Error creating transaction subscription'
                                );
                            });
                    }
                } catch (error) {
                    showError(
                        'Error',
                        'Error creating transaction subscription'
                    );
                    setLoading((curr) => !curr);
                    return;
                }
                showSuccess('Created', 'Project created successfully');

                setLoading((curr) => !curr);
                reset({
                    ...watch(),
                    // file: undefined,
                    // cover: undefined,
                    amount: 0,
                    description: '',
                    name: '',
                    url: '',
                });
                setQueryParams('EDIT');
                setPageQuery('BasicInfo');
            })
            .catch((err) => {
                showError('Error', 'Error creating project');
                setLoading((curr) => !curr);
            });
    };

    const updateDateEnd = useCallback(
        debounce(() => {
            if (
                getValues('dateStart') != null &&
                getValues('duration') != undefined
            ) {
                setValue(
                    'dateEnd',
                    transformDate(
                        new Date(getValues('dateStart')).getTime() +
                            getValues('duration')! * 1000 * 3600 * 24 * 30.43
                    )
                        .toJSON()
                        .split('T')[0]
                );
                setValue(
                    `interests`,
                    getValues(`interests`).map((val, index) => {
                        if (index === 0) {
                            return {
                                ...val,
                                date: transformDate(
                                    new Date(getValues('dateStart'))
                                )
                                    .toJSON()
                                    .split('T')[0],
                            };
                        } else {
                            return val;
                        }
                    })
                );
            }
        }, 500),
        []
    );

    useEffect(() => {
        updateDateEnd();
    }, [watch('duration'), watch('dateStart')]);

    useEffect(() => {
        setValue('subSectorId', undefined);
    }, [watch('sectorId')]);

    const [openAddShareholder, setOpenAddShareholder] =
        useState<boolean>(false);
    const [openAddParentCompany, setOpenAddParentCompany] =
        useState<boolean>(false);
    const [openAddProjectSubSector, setOpenAddProjectSubSector] =
        useState<boolean>(false);

    return (
        <div className="overflow-auto overflow_projectManage h-[82vh] relative">
            {openAddShareholder ? (
                <AddShareholder
                    setOpen={setOpenAddShareholder}
                    open={openAddShareholder}
                />
            ) : null}
            {openAddParentCompany ? (
                <AddParentCompany
                    setOpen={setOpenAddParentCompany}
                    open={openAddParentCompany}
                />
            ) : null}
            {openAddProjectSubSector ? (
                <AddProjectSubSector
                    setOpen={setOpenAddProjectSubSector}
                    open={openAddProjectSubSector}
                />
            ) : null}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={`formTemplateSingUp`}
                style={{ width: `90%` }}
            >
                <div
                    className={`w-full mx-auto grid grid-cols-1 lg:grid-cols-2
                lg:gap-x-6
            `}
                >
                    <div className=""></div>
                    <div className="">
                        <RadioComponent
                            register={register}
                            watch={watch}
                            required={false}
                            name={'projectInfo.monthProject'}
                            className={{
                                container: 'flex gap-2',
                            }}
                            values={[
                                { value: 'true', label: 'Oui' },
                                { value: 'false', label: 'Non' },
                            ]}
                        >
                            <LabelComponentForm>
                                Investissement du mois
                            </LabelComponentForm>
                        </RadioComponent>
                    </div>
                    <TitleComponentForm>DATA PLATEFORME</TitleComponentForm>

                    <SelectComponent
                        register={register}
                        value={'fondsId'}
                        control={control}
                        // watch={watch}
                        container={true}
                        optionValues={
                            fondsData?.map((val) => {
                                return { value: val.id, label: val.name };
                            }) ?? []
                        }
                    >
                        <LabelComponentForm>
                            Fonds <span className="required">*</span>
                        </LabelComponentForm>
                    </SelectComponent>
                    <SelectComponent
                        register={register}
                        value={'platformId'}
                        control={control}
                        container={true}
                        optionValues={
                            platformData?.map((val) => {
                                return { value: val.id, label: val.name };
                            }) ?? []
                        }
                    >
                        <LabelComponentForm>
                            Plateforme <span className="required">*</span>
                        </LabelComponentForm>
                    </SelectComponent>
                    <InputComponent
                        register={register}
                        value={'businessContributorIncome'}
                        type={'number'}
                    >
                        <LabelComponentForm>
                            Revenue d'apporteur d'affaires (%)
                            <span className="required">*</span>
                        </LabelComponentForm>
                    </InputComponent>
                    <div className="">
                        <RadioComponent
                            register={register}
                            watch={watch}
                            name={'confidential'}
                            className={{
                                container: 'flex gap-2',
                            }}
                            values={[
                                { value: 'true', label: 'Oui' },
                                { value: 'false', label: 'Non' },
                            ]}
                        >
                            <LabelComponentForm>
                                Confidentiel <span className="required">*</span>
                            </LabelComponentForm>
                        </RadioComponent>
                    </div>
                    <SelectMultiComponent
                        register={register}
                        control={control}
                        setValue={setValue}
                        required={false}
                        optionValues={shareholders || []}
                        optionsRender={(val: ShareholderDto) => val.fullName}
                        valueRender={(val: ShareholderDto) => val.id}
                        value={'shareholderIds'}
                    >
                        <div className="grid md:grid-cols-2 mb-2">
                            <LabelComponentForm>
                                Actionnaires
                            </LabelComponentForm>
                            <PrimaryButton
                                onClick={() => setOpenAddShareholder(true)}
                                className=" mr-2 place-self-end"
                            >
                                Add new
                            </PrimaryButton>
                        </div>
                    </SelectMultiComponent>

                    <SelectMultiComponent
                        register={register}
                        control={control}
                        required={false}
                        setValue={setValue}
                        optionValues={parentCompanies || []}
                        optionsRender={(val: ParentCompanyDto) =>
                            `${val.name} -${val.kBis}`
                        }
                        valueRender={(val: ParentCompanyDto) => val.id}
                        value={'parentCompanyIds'}
                    >
                        <div className="grid md:grid-cols-2 mb-2">
                            <LabelComponentForm>
                                Sociétés mère
                            </LabelComponentForm>
                            <PrimaryButton
                                onClick={() => setOpenAddParentCompany(true)}
                                className=" mr-2 place-self-end"
                            >
                                Add new
                            </PrimaryButton>
                        </div>
                    </SelectMultiComponent>

                    <TitleComponentForm>
                        DATA ACTEUR ET PORTEUR DU PROJET
                    </TitleComponentForm>
                    <InputComponent register={register} value={'name'}>
                        <LabelComponentForm>
                            Nom<span className="required">*</span>
                        </LabelComponentForm>
                    </InputComponent>
                    <TextAreaComponent
                        register={register}
                        value={'description'}
                        required={false}
                    >
                        <LabelComponentForm>
                            Courte description du projet
                        </LabelComponentForm>
                    </TextAreaComponent>

                    <PickCoordonates
                        height="200px"
                        width="100%"
                        control={control}
                        initialCenter={
                            watch('latitude') && watch('longitude')
                                ? {
                                      latitude: watch('latitude')!,
                                      longitude: watch('longitude')!,
                                  }
                                : undefined
                        }
                    />
                    <div className="">
                        <InputComponent register={register} value={'url'}>
                            <LabelComponentForm>
                                Lien du projet{' '}
                                <span className="required">*</span>
                            </LabelComponentForm>
                        </InputComponent>

                        {/* <InputComponent register={register} value={"notationPlatform"}>
              <LabelComponentForm>
                Risque notation plateforme <span className="required">*</span>
              </LabelComponentForm>
            </InputComponent> */}
                    </div>

                    <TextEditorComponent
                        content={comment as any as string}
                        setContent={onChangeComment}
                        className=" md:col-span-2"
                    >
                        <LabelComponentForm>
                            Longue description
                        </LabelComponentForm>
                    </TextEditorComponent>

                    <TitleComponentForm className="">
                        DATA RISQUE
                    </TitleComponentForm>

                    <TitleComponentForm>
                        DATA TYPOLOGIE DE PROJET
                    </TitleComponentForm>
                    {fondsData &&
                    fondsData.find((f) => f.name === 'Immobilier')?.id ===
                        watch('fondsId') ? (
                        <>
                            <SelectComponent
                                register={register}
                                control={control}
                                container={true}
                                setValue={setValue}
                                getValues={getValues}
                                value={'projectInfo.projectSponsorTypology'}
                                optionValues={[
                                    {
                                        label: 'Promoteur',
                                        value: 'Promoteur',
                                    },
                                    {
                                        label: 'Marchand de biens',
                                        value: 'Marchand de biens',
                                    },
                                    {
                                        label: 'Foncière',
                                        value: 'Foncière',
                                    },
                                    {
                                        label: 'Autre',
                                        value: 'Autre',
                                    },
                                ]}
                            >
                                <LabelComponentForm>
                                    Typologie du porteur de projet{' '}
                                    <span className="required">*</span>
                                </LabelComponentForm>
                            </SelectComponent>
                            {watch('projectInfo.projectSponsorTypology') ===
                            'Autre' ? (
                                <InputComponent
                                    register={register}
                                    value={
                                        'projectInfo.projectSponsorTypologyOther'
                                    }
                                >
                                    <LabelComponentForm>
                                        Typologie du porteur de projet autre{' '}
                                        <span className="required">*</span>
                                    </LabelComponentForm>
                                </InputComponent>
                            ) : null}
                        </>
                    ) : null}

                    <SelectComponent
                        register={register}
                        control={control}
                        container={true}
                        setValue={setValue}
                        getValues={getValues}
                        value={'projectInfo.fundingType'}
                        optionValues={
                            watch('fondsId') && fondsData ? fundingTypeData : []
                        }
                    >
                        <LabelComponentForm>
                            Flechage du financement{' '}
                            <span className="required">*</span>
                        </LabelComponentForm>
                    </SelectComponent>
                    {watch('projectInfo.fundingType') === 'Autre' ? (
                        <InputComponent
                            register={register}
                            value={'projectInfo.fundingTypeOther'}
                        >
                            <LabelComponentForm>
                                Flechage du financement autre{' '}
                                <span className="required">*</span>
                            </LabelComponentForm>
                        </InputComponent>
                    ) : null}

                    {marketTypologies && marketTypologies?.length > 0 ? (
                        <div className=" md:col-span-2">
                            <LabelComponentForm className=" text-center">
                                Typologie de marché du projet
                            </LabelComponentForm>

                            <CheckboxArrayComponent
                                register={register}
                                name={'projectInfo.projectMarketTypology'}
                                className={{
                                    container:
                                        'grid md:grid-cols-2 lg:grid-cols-3 lg:mt-3 items-center justify-center',
                                }}
                                values={
                                    marketTypologies
                                        ? marketTypologies?.map((v) => {
                                              return {
                                                  label: v.value,
                                                  value: v.id,
                                              };
                                          })
                                        : []
                                }
                                watch={watch}
                                control={control}
                            ></CheckboxArrayComponent>
                        </div>
                    ) : null}

                    {watch('projectInfo.projectMarketTypology')
                        ?.map(
                            (p) =>
                                projectMarketTypology?.find((v) => v.id === p)
                                    ?.value
                        )
                        ?.includes('Autre') ? (
                        <InputComponent
                            register={register}
                            value={`projectInfo.projectMarketTypologyOther`}
                        >
                            <LabelComponentForm>
                                Typologie de marché du projet autre{' '}
                                <span className="required">*</span>
                            </LabelComponentForm>
                        </InputComponent>
                    ) : null}

                    <SelectComponent
                        register={register}
                        control={control}
                        container={true}
                        setValue={setValue}
                        getValues={getValues}
                        value={'sectorId'}
                        optionValues={
                            projectSectors?.map((s) => {
                                return { value: s.id, label: s.value };
                            }) || []
                        }
                    >
                        <LabelComponentForm>
                            Secteur <span className="required">*</span>
                        </LabelComponentForm>
                    </SelectComponent>
                    <SelectComponent
                        register={register}
                        control={control}
                        container={true}
                        required={false}
                        setValue={setValue}
                        getValues={getValues}
                        value={'subSectorId'}
                        optionValues={
                            projectSectors
                                ?.find((s) => s.id === watch('sectorId'))
                                ?.subSectors?.map((s) => {
                                    return { value: s.id, label: s.value };
                                }) || []
                        }
                    >
                        <div className="w-full grid md:grid-cols-2 mb-2">
                            <LabelComponentForm>
                                Sous secteur
                            </LabelComponentForm>
                            <PrimaryButton
                                onClick={() => setOpenAddProjectSubSector(true)}
                                className=" mr-2 place-self-end"
                            >
                                Add new
                            </PrimaryButton>
                        </div>
                    </SelectComponent>

                    <div>
                        <RadioComponent
                            register={register}
                            watch={watch}
                            name={'projectInfo.obligationType'}
                            className={{
                                container: 'flex gap-2',
                            }}
                            values={[
                                {
                                    value: 'Obligation simple',
                                    label: 'Obligation simple',
                                },
                                {
                                    value: 'Obligation convertible en action',
                                    label: 'Obligation convertible en action',
                                },
                            ]}
                        >
                            <LabelComponentForm>
                                Type d'obligation{' '}
                                <span className="required">*</span>
                            </LabelComponentForm>
                        </RadioComponent>
                    </div>

                    <InputComponent
                        register={register}
                        value={'amount'}
                        type="number"
                        required={false}
                    >
                        <LabelComponentForm>Montant investi</LabelComponentForm>
                    </InputComponent>

                    <div className="max-w-md mx-auto my-4">
                        <h3 className="text-center text-lg font-semibold my-2 mx-auto">
                            List des intérêt en fonction de la date
                        </h3>
                        <FieldArray control={control} name="interests">
                            {({ fields, append, remove }) => (
                                <div className="flex flex-col w-full justify-center">
                                    <div className="flex flex-col w-full space-y-2 mb-2">
                                        {fields.map((field, index) => (
                                            <div
                                                key={field.id}
                                                className="grid grid-cols-2 w-full space-x-2 relative"
                                            >
                                                <InputComponent
                                                    register={register}
                                                    type="date"
                                                    value={`interests.${index}.date`}
                                                    cursorNotAllowed={
                                                        index === 0
                                                            ? true
                                                            : undefined
                                                    }
                                                    // className={{ input: "row-span-1 col-span-1" }}
                                                >
                                                    <h3 className="text-left">
                                                        Date{' '}
                                                        <span className="required">
                                                            *
                                                        </span>
                                                    </h3>
                                                </InputComponent>

                                                <InputComponent
                                                    register={register}
                                                    type="number"
                                                    value={`interests.${index}.value`}
                                                    // className={{ input: "row-span-1 col-span-1" }}
                                                >
                                                    <h3 className="text-left">
                                                        Intérêt{' '}
                                                        <span className="required">
                                                            *
                                                        </span>
                                                    </h3>
                                                </InputComponent>
                                                {index > 0 ? (
                                                    <RemoveFieldArrayComponent
                                                        remove={remove}
                                                        index={index}
                                                    />
                                                ) : null}
                                            </div>
                                        ))}
                                    </div>
                                    <PrimaryButton
                                        className=" mx-auto mb-2"
                                        onClick={() =>
                                            append({
                                                date: new Date()
                                                    .toJSON()
                                                    ?.split('T')[0],
                                                value: 0,
                                                fondsId: watch('fondsId')!,
                                                // projectId: project.current?.id!,
                                            })
                                        }
                                    >
                                        Ajouter
                                    </PrimaryButton>
                                </div>
                            )}
                        </FieldArray>
                    </div>

                    <div className=" grid md:grid-cols-2 gap-x-2 place-content-center">
                        <CheckboxComponent
                            register={register}
                            watch={watch}
                            values={[
                                {
                                    name: 'compoundInterestBool',
                                    label: 'Intérêts capitalisés',
                                },
                            ]}
                        >
                            <LabelComponentForm>
                                Intérêts capitalisés ?
                            </LabelComponentForm>
                        </CheckboxComponent>
                        {watch('compoundInterestBool') ? (
                            <div className=" md:col-span-2">
                                <InputComponent
                                    register={register}
                                    value={'compoundInterest'}
                                    type={'number'}
                                >
                                    <LabelComponentForm>
                                        Intérêts composés (sur la base de chaque
                                        multiple de x jours) insérer x{' '}
                                        <span className="required">*</span>
                                    </LabelComponentForm>
                                </InputComponent>
                            </div>
                        ) : null}
                    </div>

                    <SelectComponent
                        register={register}
                        value={'payment'}
                        // control={control}
                        control={control}
                        container={true}
                        optionValues={[
                            TypePayement.infine,
                            TypePayement.annual,
                            TypePayement.monthly,
                            TypePayement.semester,
                            TypePayement.trimester,
                        ].map((val) => {
                            return { value: val, label: val };
                        })}
                    >
                        <LabelComponentForm>
                            Distribution <span className="required">*</span>
                        </LabelComponentForm>
                    </SelectComponent>

                    <InputComponent
                        register={register}
                        value={'projectInfo.capital'}
                        type={'number'}
                        required={false}
                    >
                        <LabelComponentForm>
                            Amortisation (en mois, rachat de parts à partir de
                            la prochaine occurance de distribution strictement
                            dépassant la durée d'armotisation){' '}
                        </LabelComponentForm>
                    </InputComponent>

                    <InputComponent
                        register={register}
                        value={'dateStart'}
                        type={'date'}
                    >
                        <LabelComponentForm>
                            Date de souscription{' '}
                            <span className="required">*</span>
                        </LabelComponentForm>
                    </InputComponent>

                    <InputComponent
                        register={register}
                        value={'duration'}
                        type={'number'}
                    >
                        <LabelComponentForm>
                            Durée de l'investissement (mois)
                            <span className="required">*</span>
                        </LabelComponentForm>
                    </InputComponent>

                    <InputComponent
                        register={register}
                        value={'delayAccepted'}
                        type={'number'}
                    >
                        <LabelComponentForm>
                            Retard contractuel (mois)
                            <span className="required">*</span>
                        </LabelComponentForm>
                    </InputComponent>
                    <div className=" md:col-span-2">
                        <LabelComponentForm className=" text-center">
                            Niveau de séniorité{' '}
                            <span className="required">*</span>
                        </LabelComponentForm>
                        <CheckboxArrayComponent
                            register={register}
                            name={'projectInfo.seniorityLevel'}
                            className={{
                                container: 'grid md:grid-cols-2 lg:grid-cols-3',
                            }}
                            values={
                                seniorityLevel
                                    ? seniorityLevel.map((v) => {
                                          return {
                                              label: v.value,
                                              value: v.id,
                                          };
                                      })
                                    : []
                            }
                            watch={watch}
                            control={control}
                        ></CheckboxArrayComponent>
                    </div>
                </div>

                <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
                    <WhiteButton
                        onClick={() => {
                            navigate(-1);
                        }}
                        className="w-full"
                    >
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit" className="w-full">
                        {loading !== undefined && loading === true ? (
                            <Loading size={4} />
                        ) : (
                            'Add'
                        )}
                    </PrimaryButton>
                </div>
            </form>
        </div>
    );
}
