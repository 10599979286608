import { Chart } from "chart.js";
import React, { useContext, useEffect } from "react";
import { setDate } from "../../../../function/Utils";
import { useAppSelector } from "../../../../redux/store";
import {
  BackOfficeState,
  RootBlackOfficeState,
} from "../../../Context/AppContext";
import { useGetFondsQuery } from "../../../../redux/features/fondsSlice";
import { useGetOverViewAdminInfoQuery } from "../../../../redux/features/shareApiSlice";
import { fondsColor } from "../../../../data/FondsData";

const ChartFunds = () => {
  const pageState = useAppSelector(
    (state) => state.pageMeasurement.measurements
  );

  const { data: fondsData } = useGetFondsQuery();

  const backOfficeState = useContext(BackOfficeState);

  const rootState = useContext(RootBlackOfficeState);

  const { data: ovierViewAdminInfo, isLoading } = useGetOverViewAdminInfoQuery(
    {
      date: setDate(
        backOfficeState?.year!,
        backOfficeState?.month!,
        backOfficeState?.day ?? 0
      ),
      applyFees: rootState?.applyFeesBool ?? true,
    },
    {
      skip:
        !backOfficeState?.year ||
        backOfficeState?.month === undefined ||
        !backOfficeState?.day ||
        rootState?.applyFeesBool === undefined,
    }
  );

  useEffect(() => {
    if (ovierViewAdminInfo && backOfficeState) {
      let hook = document.getElementById("myChart")! as HTMLCanvasElement;
      let ctx = hook.getContext("2d")!;
      const myChart = new Chart(ctx, {
        type: "pie",
        data: {
          labels: fondsData?.map((fonds) => {
            return fonds.name;
          }),
          datasets: [
            {
              label: "Dataset 1",
              data: ovierViewAdminInfo.fondsInfo?.map((val) => {
                return val.valorisationByUsers;
              }),
              backgroundColor: fondsColor,
            },
          ],
        },
        options: {
          responsive: true,

          plugins: {
            legend: {
              position: "top",
              labels: {
                font: {
                  size: 16,
                },
              },
            },

            title: {
              display: false,
              text: "Répartition des fonds",
              color: "rgb(40,40,40)",
              position: "top",
            },
          },
        },
      });
      // ctx.shadowColor = "black";

      return () => {
        myChart.destroy();
      };
    }
  }, [pageState.widthScreen, backOfficeState, ovierViewAdminInfo]);

  return (
    <React.Fragment>
      <canvas id="myChart" className=""></canvas>
    </React.Fragment>
  );
};

export default ChartFunds;
