import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { CategoryArticleInterface } from "../../types/Articles";
import { ReasonDeleteAccountInterface } from "../../types/user";

interface reasonDeleteAccountState {
    reasons: ReasonDeleteAccountInterface[];
    loading: boolean;
    errors: any;
}

const initialState: reasonDeleteAccountState = {
    reasons: [],
    loading: false,
    errors: null,
};

// actions are processes that get data from backend
export const getReasonDeleteAccounts = createAsyncThunk<
    ReasonDeleteAccountInterface[]
>("reasonDeleteAccount/getReasonDeleteAccounts", async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}reasonDeleteAccount`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const addReasonDeleteAccount = createAsyncThunk<
    ReasonDeleteAccountInterface,
    ReasonDeleteAccountInterface
>("reasonDeleteAccount/addReasonDeleteAccount", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "post",
            url: `${import.meta.env.VITE_API_URL}reasonDeleteAccount`,
            data: data,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const editReasonDeleteAccount = createAsyncThunk<
    ReasonDeleteAccountInterface,
    ReasonDeleteAccountInterface
>("reasonDeleteAccount/editReasonDeleteAccount", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "put",
            url: `${import.meta.env.VITE_API_URL}reasonDeleteAccount/${data.id}`,
            data: { ...data },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const deleteReasonDeleteAccount = createAsyncThunk<number, number>(
    "reasonDeleteAccount/deleteReasonDeleteAccount",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}reasonDeleteAccount/${id}`,
                withCredentials: true,
            });
            return id;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);


// reducers -> reduce to a specific state -> changes state

export const ReasonDeleteAccountSlice = createSlice({
    name: "reasonDeleteAccount",
    initialState,
    reducers: {
        setReasonDeleteAccount: (
            state,
            action: PayloadAction<ReasonDeleteAccountInterface[]>
        ) => {
            state.reasons = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getReasonDeleteAccounts.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getReasonDeleteAccounts.fulfilled, (state, action) => {
            state.reasons = action.payload;
            state.loading = false;
        });
        builder.addCase(getReasonDeleteAccounts.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addReasonDeleteAccount.fulfilled, (state, action) => {
            state.reasons = [...state.reasons, action.payload];
            state.loading = false;
        });
        builder.addCase(addReasonDeleteAccount.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addReasonDeleteAccount.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editReasonDeleteAccount.fulfilled, (state, action) => {
            state.loading = false;
            state.reasons = state.reasons.map((data) => {
                if (data.id === action.payload.id) {
                    return {
                        ...data,
                        reason: action.payload?.reason,
                        howLongBeenUser: action.payload?.howLongBeenUser,
                    };
                } else return data;
            });
        });
        builder.addCase(editReasonDeleteAccount.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editReasonDeleteAccount.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteReasonDeleteAccount.fulfilled, (state, action) => {
            state.loading = false;
            state.reasons = state.reasons.filter(
                (categorie) => categorie.id !== action.payload
            );
        });
        builder.addCase(deleteReasonDeleteAccount.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteReasonDeleteAccount.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
    },
});

export default ReasonDeleteAccountSlice.reducer;
export const { setReasonDeleteAccount } = ReasonDeleteAccountSlice.actions;
