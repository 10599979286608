import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { PrimaryButton } from '../components/commun/Buttons';
import PasswordInput from '../components/commun/formComponent/PasswordInput';
import Loading from '../components/commun/Loading';
import { useNotificationContext } from '../components/Context/notification-context';
import { debounce } from '../function/Utils';
import {
    FormObjectResetPassword,
    ResetPasswordInterface,
} from '../types/Settings';
import logo from './../assets/logo.png';

const ResetPassword = () => {
    const { token } = useParams();
    const { showError, showSuccess } = useNotificationContext();

    const [resetPasswordToggle, setResetPasswordToggle] = React.useState(false);
    const [loadings, setLoading] = useState<boolean>(true);
    const [userId, setUserId] = useState<number>(0);

    const resetPassword = useCallback(
        debounce(async () => {
            await axios({
                method: 'get',
                url: `${import.meta.env.VITE_API_URL}email/resetPassword/${token}`,
                withCredentials: true,
            })
                .then((res) => {
                    // console.log(res);
                    setUserId(res.data.id);
                    setResetPasswordToggle(true);
                    setLoading(false);
                    showSuccess(
                        'Validation',
                        'Vous pouvez créer un nouveau mot de passe'
                    );
                })
                .catch((err) => {
                    console.log('err', err);
                    if (
                        err?.response?.data?.message?.name ===
                        'TokenExpiredError'
                    ) {
                        showError(
                            'Erreur',
                            'Le lien de réinitialisation a expiré'
                        );

                        // navigate("/");
                    } else {
                        showError(
                            'Erreur',
                            "Une erreur s'est produite, veuillez réessayer, si le problème persiste, contactez le support"
                        );
                    }
                    setLoading(false);
                });
        }),
        []
    );

    useEffect(() => {
        resetPassword();
    }, []);
    return (
        <>
            {loadings ? (
                <Loading />
            ) : (
                <>
                    {' '}
                    {resetPasswordToggle ? (
                        <ResetPasswordComponent userId={userId} />
                    ) : (
                        <ReSendEmail />
                    )}
                </>
            )}
        </>
    );
};

export default ResetPassword;

function ReSendEmail() {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col items-center justify-start h-screen">
            <Link to="/" className="my-8 h-14 w-14 md:mt-20">
                <img src={logo} alt="logo Newparadigms" />
            </Link>

            <div className="connexion_container">
                <div className="connexion">
                    <h3 className="my-3 text-justify">
                        Votre lien de réinitialisation a expiré, ou un problème
                        est survenu, veuillez renvoyer un email pour
                        réinitialiser votre mot de passe. Si le problème
                        persiste, veuillez contactez le support.
                    </h3>
                    <div className="flex flex-col items-center justify-center w-full mb-3">
                        <PrimaryButton
                            onClick={() => navigate('/ask-reset-password')}
                        >
                            Renvoyer un email
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ResetPasswordComponent({ userId }: { userId: number }) {
    const [, setMessage] = useState<string>('');

    const { showError, showSuccess } = useNotificationContext();
    const { token } = useParams();

    const validationSchema: Yup.SchemaOf<ResetPasswordInterface> =
        Yup.object().shape({
            newPassword: Yup.string()
                .required('le mot de passe est demandé')
                .min(8, 'le mot de passe doit au moins contenir 8 caractères')
                .max(40, 'le mot de passe ne doit pas excéder 40 caractères')
                .matches(
                    /^(?=.*[^A-Za-z0-9])[^&\\;<>]+$/,
                    'Il faut au moins un charactère spécial mais non spécifique ne pas utiliser (&;<>)'
                ),
            confirmNewPassword: Yup.string()
                .required('Répéter le mot de passe est demandé')
                .oneOf(
                    [Yup.ref('newPassword'), null],
                    'Les mots de passe ne correspondent pas'
                ),
        });

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ResetPasswordInterface>({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data: ResetPasswordInterface) => {
        await axios({
            method: 'put',
            url: `${import.meta.env.VITE_API_URL}users/auth/reset-password/${userId}`,
            withCredentials: true,
            data: { password: data.newPassword, token: token },
        })
            .then(() => {
                setMessage('Mot de passe modifié !');
                showSuccess(
                    'Modifié',
                    'Votre mot de passe a bien été modifié !'
                );
                navigate('/SeConnecter');
            })
            .catch(() => {
                showError(
                    'Erreur',
                    'Un problème est survenue lors de la modification du mot de passe !'
                );
            });
    };

    const formObject: FormObjectResetPassword[] = [
        {
            label: 'Nouveau mot de passe',
            name: 'newPassword',
        },
        {
            label: 'Confirmez votre nouveau mot de passe',
            name: 'confirmNewPassword',
        },
    ];

    return (
        <React.Fragment>
            {/* <Navigation bol_nosfonds={false} bol_transparent={false} /> */}
            <div className="flex flex-col items-center justify-start h-screen">
                <Link to="/" className="my-8 h-14 w-14 md:mt-20">
                    <img src={logo} alt="logo Newparadigms" />
                </Link>

                <div className="connexion_container">
                    <div className="connexion">
                        <h3 className="my-3 text-justify">
                            Créer un nouveau mot de passe pour votre compte.
                        </h3>
                        <form
                            className="formTemplateSingUp"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            {formObject.map(({ name, label }, key) => (
                                <PasswordInput
                                    register={register}
                                    errors={errors}
                                    label={label}
                                    name={name}
                                    key={key}
                                    componentError={() => {
                                        return (
                                            <div className="invalid-feedback">
                                                {errors[name]?.message}
                                            </div>
                                        );
                                    }}
                                />
                            ))}
                            <div className="flex justify-center items-center w-full mt-3">
                                <PrimaryButton type="submit">
                                    Modifier le mot de passe
                                </PrimaryButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
