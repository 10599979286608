import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { CreateSecurityDto, SecurityDto, UpdateSecurityDto } from "../../types/Security";

interface securityInterfaceState {
    securities: SecurityDto[];
    loading: boolean;
    errors: any;
}

const initialState: securityInterfaceState = {
    securities: [],
    loading: false,
    errors: null,
};

// actions are processes that get data from backend
export const getSecurities = createAsyncThunk<
    SecurityDto[]
>("security/getSecurities", async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}securiiesy`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const addSecurity = createAsyncThunk<
    SecurityDto,
    CreateSecurityDto
>("security/addSecurity", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "post",
            url: `${import.meta.env.VITE_API_URL}securiiesy`,
            data: data,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const editSecurity = createAsyncThunk<
    SecurityDto,
    UpdateSecurityDto
>("security/editSecurity", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "put",
            url: `${import.meta.env.VITE_API_URL}securities/${data.id}`,
            data: { ...data },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});


export const deleteSecurity = createAsyncThunk<number, number>(
    "security/deleteSecurity",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}securities/${id}`,
                withCredentials: true,
            });
            return id;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);


// reducers -> reduce to a specific state -> changes state

export const securitySlice = createSlice({
    name: "security",
    initialState,
    reducers: {
        setSecurities: (
            state,
            action: PayloadAction<SecurityDto[]>
        ) => {
            state.securities = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSecurities.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSecurities.fulfilled, (state, action) => {
            state.securities = action.payload;
            state.loading = false;
        });
        builder.addCase(getSecurities.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addSecurity.fulfilled, (state, action) => {
            state.securities = [...state.securities, action.payload];
            state.loading = false;
        });
        builder.addCase(addSecurity.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addSecurity.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editSecurity.fulfilled, (state, action) => {
            state.loading = false;
            state.securities = state.securities.map((security) => {
                if (security.id === action.payload.id) {
                    return {
                        ...security,
                        ...action.payload,
                    };
                } else return security;
            });
        });
        builder.addCase(editSecurity.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editSecurity.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteSecurity.fulfilled, (state, action) => {
            state.loading = false;
            state.securities = state.securities.filter(
                (security) => security.id !== action.payload
            );
        });
        builder.addCase(deleteSecurity.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteSecurity.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
    },
});

export default securitySlice.reducer;
export const { setSecurities } = securitySlice.actions;
