import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UserDto } from "../../types/user";
import { baseQueryHandleError } from '../index.rtk';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}`,
    credentials: 'include'
})



export const tokenApi = createApi({
    reducerPath: "tokenApi",
    baseQuery: baseQueryHandleError(baseQuery),
    endpoints: (builder) => ({
        fetchToken: builder.query<UserDto, void>({
            query: () => 'jwtid'
        }),
        fetchAllowUpdate: builder.query<boolean, void>({
            query: () => 'checkUpdateAllowed'
        })
    }),
})

export const { useFetchTokenQuery, useFetchAllowUpdateQuery } = tokenApi;