import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { isEmpty } from '../function/Utils';
import Articles from '../pages/Articles';
import AskResetPassword from '../pages/AskResetPassword';
import Contact from '../pages/Contact';
import DashboardAdmin from '../pages/DashboardAdmin';
import DashboardUser from '../pages/DashboardUser';
import FondImmobilier from '../pages/Fond_immobilier';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Reglementations from '../pages/Reglementations';
import ResetPassword from '../pages/ResetPassword';
import Seconnecter from '../pages/Seconnecter';
import ValidationEmail from '../pages/ValidationEmail';
import VueEnsemble from '../pages/VueEnsemble';
import {
    useFetchAllowUpdateQuery,
    useFetchTokenQuery,
} from '../redux/features/tokenSlice';
import Article from './Articles/ArticleComponent';
import { AllowState } from './Context/AppContext';
import Profile from './DashboardUser/Profile/DonneesPerso/DonneesPerso';

// import { getAllUsers } from "../actions/user.action";

const RoutesComponent = () => {
    const { data: allow } = useFetchAllowUpdateQuery();

    const { data: userData } = useFetchTokenQuery();

    return (
        <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
                <AllowState.Provider
                    value={{
                        allow: allow ?? false,
                    }}
                >
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/Home" element={<Home />} />
                        <Route
                            path="/confirmation/:token"
                            element={<ValidationEmail />}
                        />
                        <Route
                            path="/resetPassword/:token"
                            element={<ResetPassword />}
                        />
                        <Route
                            path="ask-reset-password"
                            element={<AskResetPassword />}
                        />
                        <Route path="VueEnsemble" element={<VueEnsemble />} />
                        <Route path="Immobilier" element={<FondImmobilier />} />
                        <Route path="Contact" element={<Contact />} />
                        <Route path="SeConnecter" element={<Seconnecter />} />
                        <Route path="Articles" element={<Articles />} />
                        <Route path="/Articles/:id" element={<Article />} />
                        <Route path="/Article" element={<Articles />} />
                        <Route path="*" element={<NotFound />} />

                        {/* Pages relatives aux réglementations */}

                        <Route
                            path="/Reglementations/:path"
                            element={<Reglementations />}
                        />

                        {/* Pages accessibles pour les Admins */}

                        {!!(!isEmpty(userData) && userData?.admin) && (
                            <React.Fragment>
                                <Route
                                    path="/DashBoardAdmin/:path"
                                    element={<DashboardAdmin />}
                                />

                                <Route
                                    path="/DashBoardAdmin/Fonds/Projets/:idProjet"
                                    element={<DashboardAdmin />}
                                />
                                <Route
                                    path="/DashBoardAdmin/:path/:idBackOffice"
                                    element={<DashboardAdmin />}
                                />
                                <Route
                                    path="/DashBoardAdmin/:path/table/:idTable"
                                    element={<DashboardAdmin />}
                                />
                                <Route
                                    path="/DashBoardAdmin/:path/user/:idUser"
                                    element={<DashboardAdmin />}
                                />
                                <Route
                                    path="/DashBoardAdmin/:path/projects/:idProjectBO"
                                    element={<DashboardAdmin />}
                                />
                                <Route
                                    path="/DashBoardAdmin/:path/transactions/:idTransaction"
                                    element={<DashboardAdmin />}
                                />
                                <Route
                                    path="/DashBoardAdmin/:path/blackLists/:idBlackList"
                                    element={<DashboardAdmin />}
                                />
                            </React.Fragment>
                        )}

                        {/* Pages accessibles pour ceux qui ont un compte   */}
                        {/* !!(import.meta.env.VITE_API_URL === "http://localhost:5002/api/") */}
                        {/* {!!(!isEmpty(userData) && userData?.admin) &&
              !isEmpty(userData) && ( */}
                        <React.Fragment>
                            <Route path="/Profil" element={<Profile />} />
                            <Route
                                path="/DashboardClient/:path"
                                element={<DashboardUser />}
                            />
                            <Route
                                path="/DashboardClient/investir/Fonds/:idFonds"
                                element={<DashboardUser />}
                            />
                            <Route
                                path="/DashboardClient/investir/Fonds/Projets/:idProjet"
                                element={<DashboardUser />}
                            />
                        </React.Fragment>
                        {/* )} */}
                    </Routes>
                </AllowState.Provider>
            </QueryParamProvider>
        </BrowserRouter>
    );
};

export default RoutesComponent;
