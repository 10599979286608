// export type CategoryQuestion = "knowledge" | "risk" | "profile" | "other";
// convert in enum
export enum CategoryQuestion {
    target_investissments = 'target_investissments',
    experience_investissments = 'experience_investissments',
    financial_products = 'financial_products',
    funds_functionning = 'funds_functionning',
    impact_knowledge = 'impact_knowledge',
    assets = 'assets',
}

// export type TypeQuestion = "radio" | "checkbox" | "select" | "text" | "number"
// convert in enum
export enum TypeQuestion {
    radio = 'radio',
    checkbox = 'checkbox',
    select = 'select',
    selectMulti = 'selectMulti',
    text = 'text',
    number = 'number',
}

export interface KnowledgeQuestionDto {
    id: number;
    question: string;
    type: TypeQuestion;
    category: CategoryQuestion;
    answers: KnowledgeAnswerDto[];
    order: number;
    min?: number;
    max?: number;
    comment?: string | null;
    commentAfterTrue?: string | null;
    commentAfterFalse?: string | null;
}

export interface CreateKnowledgeQuestionDto {
    question: string;
    type: TypeQuestion;
    category: CategoryQuestion;
    order: number;
    min?: number;
    max?: number;
    commentAfterTrue?: string;
    commentAfterFalse?: string;
    comment?: string;
    answers: CreateKnowledgeAnswerDto[];
}

export interface KnowledgeAnswerDto {
    id: number;
    answer: string;
    title?: string;
    comment?: string | null;
    goodAnswer?: boolean;
    answerJson: KnowledgeAnswerJsonDto;
}

export interface KnowledgeAnswerJsonDto {
    answer: string;
    title?: string;
    subTitle?: string;
    comment?: string | null;
    goodAnswer?: boolean;
    order?: number;
}

export interface CreateKnowledgeAnswerDto {
    answer: string;
    title?: string;
    comment?: string;
    goodAnswer?: boolean;
    answerJson?: KnowledgeAnswerJsonDto;
}
