import React from "react";
import {
  transformDate,
  addDays,
  formatDateMonthYear,
} from "../../../function/Utils";
import { WrappingTable } from "../../DashboardAdmin/BackOffice/Components/TableComponent";
import { useGetDashbordUserInfoQuery } from "../../../redux/features/shareApiSlice";
import { useGetPerformancePerFondsQuery } from "../../../redux/features/fondsSlice";

const HistoricalPerf = ({ fondsId }: { fondsId: number }) => {
  const { data: performanceFonds, isLoading: isLoadingPerf } =
    useGetPerformancePerFondsQuery(fondsId);

  const { data: dashBordUserAdmin, isLoading } = useGetDashbordUserInfoQuery();

  const fonds_i = dashBordUserAdmin?.fondsInfo?.find(
    (f) => f.fondsId === fondsId
  );

  const columns = [
    ...(performanceFonds?.yearlyValues?.map((p) => {
      return {
        topHeader: p.year,
        botHeader: `${formatDateMonthYear(p.startDate)} - ${formatDateMonthYear(
          p.endDate
        )}`,
        perf: `${p.perf.toFixed(2)} %`,
      };
    }) || []),
    {
      topHeader: "Performance d'un nouvel investisseur",
      botHeader: `durant les 30 derniers jours`,
      perf: `${(fonds_i && fonds_i?.triNotBlackListLast30Days > 0
        ? fonds_i?.triNotBlackListLast30Days -
          (fonds_i?.triNotBlackListLast30Days - fonds_i?.fondsPerformance) * 0.3
        : 0
      )?.toFixed(2)} %`,
    },
  ];

  const classNameObejct = {
    head: "text-sm font-light px-1 py-4 whitespace-nowrap text-center  ",
    item: "text-sm font-light px-1 py-2 whitespace-nowrap text-center",
  };

  return (
    <div className="w-full md:w-10/12 mx-auto px-2 mt-4 md:border-2 md:border-gray-300 md:rounded-md p-1">
      <p className=" text-justify">
        La performance du fonds est calculée en incluant les coupons nets
        réinvestis et nets de frais de gestion (directs et indirects) et hors
        frais d'entrée et de sortie. Les performances passées ne préjugent pas
        des performaces futures.
      </p>
      <div className="w-full my-3 md:w-10/12 mx-auto px-2">
        <WrappingTable>
          <thead className="bg-white border-b border-t">
            <tr>
              {columns?.map((val, key) => (
                <th
                  key={key}
                  scope="col"
                  className={`${classNameObejct.head} `}
                >
                  <span className=" font-semibold">{val.topHeader}</span> <br />
                  ({val.botHeader})
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {performanceFonds ? (
              <>
                <tr
                  className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 bg-secondBackgroundColor `}
                >
                  {columns?.map((val, key) => (
                    <td
                      className={`${classNameObejct.item} pl-1 ${
                        key === columns.length - 1 ? "font-semibold" : ""
                      }`}
                    >
                      {val.perf}
                    </td>
                  ))}
                </tr>
              </>
            ) : null}
          </tbody>
        </WrappingTable>
      </div>
      <p className="text-sm italic">
        Société créée le 24/02/2021. Performance calculée en EUR.
      </p>
    </div>
  );
};

export default HistoricalPerf;
