import {
  faChartLine,
  faFileLines,
  faGift,
  faMoneyBill,
  faNewspaper,
  faPieChart,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { menuDashboard } from "../types/dashboard";

export const menuListAdmin: menuDashboard[] = [
  {
    icon: faUser,
    name: "Utilisateurs",
    path: "Utilisateurs",
  },
  {
    icon: faUser,
    name: "Prospects",
    path: "prospects",
  },
  {
    icon: faNewspaper,
    name: "Articles",
    path: "Articles",
  },
  {
    icon: faPieChart,
    name: "BackOffice",
    path: "BackOffice",
  },
  {
    icon: faFileLines,
    name: "Fonds",
    path: "Fonds",
  },
  {
    icon: faFileLines,
    name: "Projects management",
    path: "ProjectsManage",
  },
  {
    icon: faFileLines,
    name: "Impacts management",
    path: "impacts-management",
  },
  {
    icon: faChartLine,
    name: "Metrics",
    path: "metrics",
  }, {
    icon: faGift,
    name: "Parrainage",
    path: "sponsor",
  }, {
    icon: faMoneyBill,
    name: "Frais",
    path: "fees"
  }, {
    icon: faMoneyBill,
    name: "Titre D manager",
    path: "titre-d-manager"
  }
];
