import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { faFileLines, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { formatDate, transformDate } from '../../../../function/Utils';
import {
    UploadDocumentProjectDto,
    uploadDocumentProject,
    useAddReportingMutation,
    useDeleteReportingMutation,
    useGetProjectByIdQuery,
    useGetReportingByProjectQuery,
    useUpdateReportingMutation,
} from '../../../../redux/features/projectSlice';
import {
    CreateReportingDtoForm,
    ReportingDto,
    TypeDocumentProject,
    UpdateReportingDtoForm,
} from '../../../../types/project';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import useDeleteItem from '../../../commun/CustomHook/useDeleteItem';
import DisplayDocumentProject from '../../../commun/DisplayDocuments/DisplayDocumentProject';
import DisplayTextEditor from '../../../commun/DisplayTextEditor';
import InputComponent from '../../../commun/formComponent/InputComponent';
import TextEditorComponent from '../../../commun/formComponent/TextEditorComponent';
import UploaderMulti from '../../../commun/formComponent/UploaderMulti';
import Loading from '../../../commun/Loading';
import PopUp from '../../../commun/PopUp';
import { useNotificationContext } from '../../../Context/notification-context';

function Reporting({ projectId }: { projectId: number }) {
    const {
        data: reportings,
        isLoading: isLoadingReporting,
        refetch,
    } = useGetReportingByProjectQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const [toggleAddReport, setToggleAddReport] = useState(false);

    return (
        <>
            {isLoadingReporting ? (
                <Loading />
            ) : (
                <>
                    {toggleAddReport ? (
                        <AddReporting
                            open={toggleAddReport}
                            setOpen={setToggleAddReport}
                            projectId={projectId}
                            refetch={refetch}
                        />
                    ) : null}
                    <div className="w-11-12 mt-2 mx-auto flex justify-center items-center">
                        <PrimaryButton onClick={() => setToggleAddReport(true)}>
                            Ajouter un reporting
                        </PrimaryButton>
                    </div>
                    <div className="w-full mt-3 grid md:grid-cols-4 items-center cursor-pointer">
                        <LabelComponentForm>Date</LabelComponentForm>
                        <LabelComponentForm>Notation</LabelComponentForm>
                        {/* <LabelComponentForm>Commentaire</LabelComponentForm> */}
                        <LabelComponentForm>Documents</LabelComponentForm>
                        <LabelComponentForm>Actions</LabelComponentForm>
                    </div>
                    {reportings?.map((r, index) => (
                        <div key={index} className="">
                            <RenderReporting
                                reporting={r}
                                refetch={refetch}
                                projectId={projectId}
                            />
                        </div>
                    ))}
                </>
            )}
        </>
    );
}

function RenderReporting({
    reporting,
    refetch,
    projectId,
}: {
    reporting: ReportingDto;
    refetch: any;
    projectId: number;
}) {
    const [editToggle, setEditToggle] = useState(false);
    const [detailsToggle, setDetailsToggle] = useState(false);

    return (
        <div className="w-full grid md:grid-cols-4 py-1 items-center">
            {editToggle ? (
                <EditReporting
                    reporting={reporting}
                    refetch={refetch}
                    open={editToggle}
                    setOpen={setEditToggle}
                    projectId={projectId}
                />
            ) : null}
            {detailsToggle ? (
                <ReportingCards
                    reporting={reporting}
                    open={detailsToggle}
                    setOpen={setDetailsToggle}
                />
            ) : null}
            <div className="">{formatDate(reporting.date)}</div>
            <div className="">{reporting?.notation}</div>
            {/* <div className="">
          {displayHideLongText("Pas de commentaire", reporting?.comment)}
        </div> */}
            <div className="flex gap-1 flex-wrap">
                {reporting?.documents?.length > 0 ? (
                    <FontAwesomeIcon icon={faFileLines} className="h-[25px]" />
                ) : null}
            </div>
            <div className="flex gap-2 items-center">
                <WhiteButton onClick={() => setDetailsToggle(true)}>
                    Details
                </WhiteButton>
                <PrimaryButton onClick={() => setEditToggle(true)}>
                    Edit
                </PrimaryButton>
            </div>
        </div>
    );
}

interface ReportingAddForm {
    createReporting: CreateReportingDtoForm;
    documents: File[];
}

function AddReporting({
    projectId,
    open,
    setOpen,
    refetch,
}: {
    projectId: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: any;
}) {
    const {
        register,
        handleSubmit,
        reset,
        getValues,
        watch,
        setValue,
        control,
    } = useForm<ReportingAddForm>({
        defaultValues: {
            documents: [],
            createReporting: {
                date: new Date(Date.now()).toJSON().split('T')[0],
            },
        },
    });

    const { data: project, isLoading } = useGetProjectByIdQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );

    const [addReporting, { isLoading: isLoadingAdd }] =
        useAddReportingMutation();

    const [loading, setLoading] = useState(false);

    const { showError, showSuccess } = useNotificationContext();

    const onSubmit = async (data: ReportingAddForm) => {
        setLoading((curr) => !curr);
        const files = data?.documents;

        await addReporting({
            ...data.createReporting,
            date: transformDate(data.createReporting.date),
            notation: data.createReporting.notation ?? undefined,
            projectId: projectId,
            fondsId: project?.fondsId!,
        })
            .unwrap()
            .then(async (res) => {
                showSuccess('Created', 'Reporting added successfully');
                if (files?.length > 0) {
                    files.forEach(async (file) => {
                        let datadoc: UploadDocumentProjectDto = {
                            projectId: projectId!,
                            type: TypeDocumentProject.reporting,
                            file: file,
                            fileName: file.name,
                            projectName: project?.name!,
                            reportingId: res.id!,
                        };

                        await uploadDocumentProject(datadoc)
                            .then((res) => {
                                if (res.sucess) {
                                    showSuccess('Created', 'File uploaded');
                                } else {
                                    showError(
                                        'Error',
                                        `Error uploading file ${datadoc.fileName}: ${res.message}`
                                    );
                                }
                            })
                            .catch((res) => {
                                showError('Error', 'Error uploading file');
                            });
                    });
                }
                // wait 1s
                await new Promise((resolve) => setTimeout(resolve, 1000));
                await refetch();
                setOpen(false);
            })
            .catch((err) => {
                showError('Error', 'Error adding this reporting');
            });
        setLoading((curr) => !curr);
    };

    const {
        field: { onChange: onChangeComment, value: comment },
    } = useController<ReportingAddForm>({
        name: 'createReporting.comment',
        control: control,
    });

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title={() => {
                return (
                    <h3 className="text-2xl font-medium leading-6 text-gray-900">
                        Ajouter un reporting
                    </h3>
                );
            }}
            buttonBoolean={false}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp"
            >
                <InputComponent
                    type="date"
                    value={'createReporting.date'}
                    register={register}
                >
                    <LabelComponentForm>
                        Date <span className="required">*</span>
                    </LabelComponentForm>
                </InputComponent>
                <InputComponent
                    type="text"
                    required={false}
                    value={'createReporting.notation'}
                    register={register}
                >
                    <LabelComponentForm>Notation (de 1 à 5)</LabelComponentForm>
                </InputComponent>
                <TextEditorComponent
                    content={comment as any as string}
                    setContent={onChangeComment}
                    className=" w-full text-left"
                >
                    <LabelComponentForm>Commentaire</LabelComponentForm>
                </TextEditorComponent>
                <UploaderMulti
                    register={register}
                    value={`documents`}
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    button={false}
                ></UploaderMulti>
                <div className="w-11/12 mx-auto flex justify-center items-center gap-3">
                    <WhiteButton onClick={() => setOpen(false)}>
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit" loading={loading}>
                        Ajouter
                    </PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
}

interface ReportingEditForm {
    editReporting: UpdateReportingDtoForm;
    documents: File[];
}

function EditReporting({
    reporting,
    open,
    setOpen,
    refetch,
    projectId,
}: {
    reporting: ReportingDto;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: any;
    projectId: number;
}) {
    const {
        register,
        handleSubmit,
        control,
        reset,
        getValues,
        watch,
        setValue,
    } = useForm<ReportingEditForm>({
        defaultValues: {
            documents: [],
            editReporting: {
                id: reporting.id!,
                date: reporting.date.toJSON().split('T')[0],
                comment: reporting?.comment,
                projectId: reporting.projectId,
                notation: reporting?.notation,
            },
        },
    });

    const { data: project, isLoading } = useGetProjectByIdQuery(
        projectId ?? 0,
        {
            skip: projectId === undefined,
        }
    );

    const [editReporting, { isLoading: isLoadingEdit }] =
        useUpdateReportingMutation();

    const [deleteReporting, { isLoading: isLoadingDelete }] =
        useDeleteReportingMutation();

    const [loading, setLoading] = useState(false);

    const { showError, showSuccess } = useNotificationContext();

    const onSubmit = async (data: ReportingEditForm) => {
        setLoading((curr) => !curr);
        const files = data?.documents;

        await editReporting({
            ...data.editReporting,
            date: transformDate(data.editReporting.date),
            projectId: projectId,
            notation: data.editReporting.notation ?? undefined,
            fondsId: project?.fondsId!,
        })
            .unwrap()
            .then(async (res) => {
                showSuccess('Edited', 'Reporting edited successfully');
                if (files?.length > 0) {
                    files.forEach(async (file) => {
                        let datadoc: UploadDocumentProjectDto = {
                            projectId: projectId!,
                            type: TypeDocumentProject.reporting,
                            file: file,
                            fileName: file.name,
                            projectName: project?.name!,
                            reportingId: data.editReporting.id!,
                        };

                        await uploadDocumentProject(datadoc)
                            .then((res) => {
                                if (res.sucess) {
                                    showSuccess('Created', 'File uploaded');
                                } else {
                                    showError(
                                        'Error',
                                        `Error uploading file ${datadoc.fileName}: ${res.message}`
                                    );
                                }
                            })
                            .catch((res) => {
                                showError('Error', 'Error uploading file');
                            });
                    });
                }
                // wait 1s
                await new Promise((resolve) => setTimeout(resolve, 1000));
                await refetch();
                setOpen(false);
            })
            .catch((err) => {
                showError('Error', 'Error editing this reporting');
            });
        setLoading((curr) => !curr);
    };

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        reportingId: number;
    }>({
        deleteItem: () => deleteReporting(reporting.id!),
        toDoIfSuccess: () => {
            refetch();
            setOpen(false);
        },
        message: 'report',
        isLoading: isLoading,
    });

    const {
        field: { onChange: onChangeComment, value: comment },
    } = useController<ReportingEditForm>({
        name: 'editReporting.comment',
        control: control,
    });

    return (
        <>
            <DeleteComponent title="ce document" />

            <PopUp
                open={open}
                setOpen={setOpen}
                width="w-full md:w-11/12"
                title={() => {
                    return (
                        <div className="relative">
                            <h3 className="text-2lg font-medium leading-6 text-gray-900">
                                Modifier ce reporting
                            </h3>

                            <FontAwesomeIcon
                                onClick={() => setOpenDelete(true)}
                                icon={faTrash}
                                className="absolute top-8 md:top-0 cursor-pointer right-4 transition-all hover:scale-105"
                            />
                        </div>
                    );
                }}
                buttonBoolean={false}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="formTemplateSingUp"
                >
                    <InputComponent
                        type="date"
                        value={'editReporting.date'}
                        register={register}
                    >
                        <LabelComponentForm>
                            Date <span className="required">*</span>
                        </LabelComponentForm>
                    </InputComponent>
                    <InputComponent
                        type="text"
                        required={false}
                        value={'editReporting.notation'}
                        register={register}
                    >
                        <LabelComponentForm>Notation</LabelComponentForm>
                    </InputComponent>
                    <TextEditorComponent
                        content={comment as any as string}
                        setContent={onChangeComment}
                        className=" w-full text-left"
                    >
                        <LabelComponentForm>Commentaire</LabelComponentForm>
                    </TextEditorComponent>
                    {/* <TextAreaComponent value={"editReporting.comment"} register={register}>
            <LabelComponentForm>Commentaire</LabelComponentForm>
          </TextAreaComponent> */}
                    <UploaderMulti
                        register={register}
                        value={`documents`}
                        watch={watch}
                        setValue={setValue}
                        control={control}
                        button={false}
                    ></UploaderMulti>
                    <div className="w-full md:w-10:12">
                        <div className="w-9/12 mx-auto gap-2 flex justify-center items-center flex-col">
                            <>
                                {reporting.documents !== undefined &&
                                reporting.documents.length > 0 ? (
                                    reporting.documents.map((doc, index) => (
                                        <DisplayDocumentProject
                                            document={doc}
                                            key={index}
                                            refetch={refetch}
                                        />
                                    ))
                                ) : (
                                    <p>Aucun document n'a été trouvé</p>
                                )}
                            </>
                        </div>
                    </div>
                    <div className="w-11/12 mx-auto flex justify-center items-center gap-3">
                        <WhiteButton onClick={() => setOpen(false)}>
                            Cancel
                        </WhiteButton>
                        <PrimaryButton type="submit" loading={loading}>
                            Edit
                        </PrimaryButton>
                    </div>
                </form>
            </PopUp>
        </>
    );
}

function ReportingCards({
    reporting,
    open,
    setOpen,
}: {
    reporting: ReportingDto;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const classNameDiv =
        'grid grid-cols-1 space-y-2 md:space-y-0 md:grid-cols-2 border-b-2 py-2';
    const classNameH3 =
        'text-md font-mainFontFamily font-semibold text-center md:text-start';
    const classNameP = 'flex justify-center items-center';
    const classNameComment = 'px-2 md:px-0 text-start text-gray-400 italic';

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title={() => {
                return (
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Détails du reporting {formatDate(reporting.date)}
                    </h3>
                );
            }}
            buttonBoolean={false}
            width="w-full md:w-11/12"
        >
            <div className="mt-5 w-[95%] flex gap-2 flex-col  md:px-5 mx-auto">
                <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Notation</h3>
                    <p className={`${classNameP}`}>
                        {reporting.notation || 'Pas de notation'}
                    </p>
                </div>
                <div className={`flex flex-col`}>
                    <h3 className={`${classNameH3} mb-4`}>Commentaire</h3>
                    <p className={`${classNameP} text-left`}>
                        <DisplayTextEditor content={reporting.comment} />
                    </p>
                </div>
                <TitleComponentForm>Documents</TitleComponentForm>
                <div className="flex flex-col gap-2">
                    {reporting.documents && reporting.documents?.length! > 0 ? (
                        reporting.documents.map((doc, index) => (
                            <DisplayDocumentProject
                                document={doc}
                                key={index}
                            />
                        ))
                    ) : (
                        <p>Aucun document n'a été trouvé</p>
                    )}
                </div>
            </div>
        </PopUp>
    );
}

export default Reporting;
