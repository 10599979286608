import React, { useContext, useState } from "react";
import { useFetchTokenQuery } from "../../../redux/features/tokenSlice";
import {
  useGetUserDocumentsQuery,
  useGetUserWithAllInfoForCustomerQuery,
} from "../../../redux/features/userSlice";
import Loading from "../../commun/Loading";
import {
  CheckUserProfileDto,
  CheckUserProfileForUserDto,
  DocumentUserDto,
  DocumentUserName,
  DocumentUserSide,
  DocumentUserType,
  StateStatus,
} from "../../../types/user";
import { formatDate } from "../../../function/Utils";
import { WhiteButton, PrimaryButton } from "../../commun/Buttons";
import DisplayDocumentValidation from "../../commun/DisplayDocuments/DisplayDocumentValidation";
import { SuccessIcon, ErrorIcon } from "../../commun/IconsFeedBack";
import PopUp from "../../commun/PopUp";
import FormulaireInvestisseur from "./FormInvest/FormulaireInvestisseur";
import { MenuDashBoardUserBoolState } from "../../Context/AppContext";
import WrapComponentDashboardUser from "../commun/WrapComponentDashboardUser";

const ValidationUser = () => {
  const { data: userFetch, isLoading: loadingUser } = useFetchTokenQuery();
  const { data: user, isLoading: isLoading } =
    useGetUserWithAllInfoForCustomerQuery(userFetch?.id || 0, {
      skip: userFetch ? false : true,
    });

  const classNameObejct = {
    head: "text-sm text-gray-900 px-6 py-4 text-center font-semibold",
  };

  const classNameDiv =
    "grid grid-cols-1 space-y-2 md:space-y-0 md:grid-cols-2 border-b-2 py-2";
  const classNameH3 =
    "text-md font-mainFontFamily font-semibold text-center md:text-start";
  const classNameP = "flex justify-center items-center";
  const classNameComment = "px-2 md:px-0 text-start text-gray-400 italic";

  const [formulaire, setFormulaire] = useState<boolean>(false);

  const [openDocument, setOpenDocument] = useState(false);
  const [indexDocumentOpen, setIndexDocumentOpen] = useState<number>(0);

  const handleFormAccess = () => {
    sessionStorage.setItem("stepFormProfil", "0");
    sessionStorage.setItem("stepQuestionFormProfil", "0");
    sessionStorage.setItem("stepKnowledgeQuestionFormProfil", "0");
    sessionStorage.setItem("nextFormProfil", "0");
    sessionStorage.setItem("stepValidationForm", "0");

    setFormulaire(true);
  };

  const {
    data: documents,
    isLoading: loadingDocuments,
    refetch,
  } = useGetUserDocumentsQuery(user?.id || 0, {
    skip: user === undefined,
  });

  const menuBoolState = useContext(MenuDashBoardUserBoolState);

  return (
    <>
      {isLoading || loadingUser || loadingDocuments ? (
        <div className="item_container rounded-lg lg:shadow-low md:p-2 w-full">
          <div className="w-full">
            <Loading />
          </div>
        </div>
      ) : formulaire ? (
        <FormulaireInvestisseur
          validation={user?.validationProfile}
          documents={documents}
        />
      ) : (
        <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto">
          <WrapComponentDashboardUser
            title="Vérification de votre profil"
            description="Votre profil n'a pas été validé, veuillez envoyer à nouveau les
        éléments non validés"
            classNameHeader={`${
              !menuBoolState?.toggleMenu ? "!pl-[40px]" : ""
            }`}
            classNameContainer={`${!menuBoolState?.toggleMenu ? "" : ""}`}
          >
            <div className="relative overflow-auto h-[75vh] md:h-[83vh] ">
              {user?.validationProfile == undefined ? (
                <div>
                  <p>Aucune validation trouvée</p>
                </div>
              ) : (
                <div className="mt-5 w-[95%] flex gap-2 flex-col  md:px-5 max-w-[600px] mx-auto">
                  <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Date de la validation</h3>
                    <p className={`${classNameP}`}>
                      {user?.validationProfile.date ? (
                        <>
                          {formatDate(new Date(user?.validationProfile.date))}
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Résultat</h3>
                    <p className={`${classNameP}`}>
                      {user?.validationProfile.status === true ? (
                        <span className=" text-green-600">Accepté</span>
                      ) : (
                        <span className=" text-red-600">Refusé</span>
                      )}
                    </p>
                  </div>
                  <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Commentaire général</h3>
                    <p className={`${classNameComment}`}>
                      {user?.validationProfile.comment ?? ""}
                    </p>
                  </div>
                  {/* <div className="w-full flex justify-center items-center mt-3 md:col-span-2">
                    <PrimaryButton>Détails</PrimaryButton>
                  </div> */}
                  <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Identité</h3>
                    <p className={`${classNameP}`}>
                      {user?.validationProfile.validIdentity ? (
                        <SuccessIcon />
                      ) : (
                        <ErrorIcon />
                      )}
                    </p>
                  </div>
                  {user?.validationProfile.commentIdentity !== "" ? (
                    <div className={`${classNameDiv}`}>
                      <h3 className={`${classNameH3}`}>Commentaire identité</h3>
                      <p className={`${classNameComment}`}>
                        {user?.validationProfile.commentIdentity ?? ""}
                      </p>
                    </div>
                  ) : null}
                  {documents?.map((document, key) => (
                    <div
                      className={`${classNameDiv} cursor-pointer`}
                      key={key}
                      onClick={() => {
                        setOpenDocument(true);
                        setIndexDocumentOpen(key);
                      }}
                    >
                      {openDocument && indexDocumentOpen === key ? (
                        <DisplayDocumentValidation
                          document={document}
                          setOpen={setOpenDocument}
                          open={openDocument}
                          index={key}
                        />
                      ) : null}
                      <div className="flex flex-col gap-1">
                        <h3 className={`${classNameH3}`}>
                          {documentNameForUser(document)}
                        </h3>
                        <p className="italic text-sm text-start text-gray-400">
                          Document {key + 1}
                        </p>
                      </div>
                      <div className={`${classNameP} relative`}>
                        {document?.status === StateStatus.validated ? (
                          <SuccessIcon />
                        ) : (
                          <ErrorIcon />
                        )}
                        <WhiteButton className="absolute right-0">
                          Détails
                        </WhiteButton>
                      </div>
                    </div>
                  ))}
                  <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Questions</h3>
                    <p className={`${classNameP}`}>
                      {user?.validationProfile.validQuestion ? (
                        <SuccessIcon />
                      ) : (
                        <ErrorIcon />
                      )}
                    </p>
                  </div>
                  {user?.validationProfile.commentQuestion !== "" ? (
                    <div className={`${classNameDiv}`}>
                      <h3 className={`${classNameH3}`}>
                        Commentaire questions
                      </h3>
                      <p className={`${classNameComment}`}>
                        {user?.validationProfile.commentQuestion ?? ""}
                      </p>
                    </div>
                  ) : null}
                  {/* <div className={`${classNameDiv}`}>
                    <h3 className={`${classNameH3}`}>Validé</h3>
                    <p className={`${classNameP}`}>
                      {user?.validationProfile.status ? (
                        <SuccessIcon />
                      ) : (
                        <ErrorIcon />
                      )}
                    </p>
                  </div> */}
                  <div className="w-full flex justify-center items-center mt-3 md:col-span-2">
                    <PrimaryButton onClick={() => handleFormAccess()}>
                      Accéder au formulaire
                    </PrimaryButton>
                  </div>
                </div>
              )}
            </div>
          </WrapComponentDashboardUser>
        </div>
      )}
    </>
  );
};

export default ValidationUser;

export function documentNameForUser(document: DocumentUserDto) {
  if (document.type === DocumentUserType.ADDRESS_IDENTTITY) {
    return "Pièce d'identité tiers personne";
  } else if (
    document.type === DocumentUserType.ADDRESS_THIRD_PARTY_ATTESTATION
  ) {
    return "Attestation signé par le tiers";
  } else if (document.name === DocumentUserName.BILL) {
    return "Facture";
  } else if (document.type === DocumentUserType.IDENTITY_1) {
    if (document.side === DocumentUserSide.BOTH) {
      return "Votre pièce d'identité";
    } else if (document.side === DocumentUserSide.FRONT) {
      return "Recto de votre pièce d'identité";
    } else {
      return "Verso de votre pièce d'identité";
    }
  }
  return document.name;
}
