import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { CategoryArticleInterface } from "../../types/Articles";

interface acticleState {
  categories: CategoryArticleInterface[];
  loading: boolean;
  errors: any;
}

const initialState: acticleState = {
  categories: [],
  loading: false,
  errors: null,
};

// actions are processes that get data from backend
export const getCategoryArticles = createAsyncThunk<
  CategoryArticleInterface[]
>("categoryArticles/getCategoryArticles", async (_, thunkAPI) => {
  try {
    const response = await axios({
      method: "get",
      url: `${import.meta.env.VITE_API_URL}article/categories`,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const addCategoryArticle = createAsyncThunk<
  CategoryArticleInterface,
  CategoryArticleInterface
>("categoryArticles/addCategoryArticle", async (data, thunkApi) => {
  try {
    const response = await axios({
      method: "post",
      url: `${import.meta.env.VITE_API_URL}article/categories`,
      data: data,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const editCategoryArticle = createAsyncThunk<
  CategoryArticleInterface,
  CategoryArticleInterface
>("categoryArticles/editCategoryArticle", async (data, thunkApi) => {
  try {
    const response = await axios({
      method: "put",
      url: `${import.meta.env.VITE_API_URL}article/categories/${data.id}`,
      data: { ...data },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const deleteCategoryArticle = createAsyncThunk<number, number>(
  "categoryArticles/deleteCategoryArticle",
  async (id: number, thunkApi) => {
    try {
      const response = await axios({
        method: "delete",
        url: `${import.meta.env.VITE_API_URL}article/categories/${id}`,
        withCredentials: true,
      });
      return id;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);


// reducers -> reduce to a specific state -> changes state

export const CategoryArticleSlice = createSlice({
  name: "categoryArticles",
  initialState,
  reducers: {
    setCategoryArticles: (
      state,
      action: PayloadAction<CategoryArticleInterface[]>
    ) => {
      state.categories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoryArticles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategoryArticles.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.loading = false;
    });
    builder.addCase(getCategoryArticles.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
    });
    builder.addCase(addCategoryArticle.fulfilled, (state, action) => {
      state.categories = [...state.categories, action.payload];
      state.loading = false;
    });
    builder.addCase(addCategoryArticle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCategoryArticle.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
    });
    builder.addCase(editCategoryArticle.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = state.categories.map((categorie) => {
        if (categorie.id === action.payload.id) {
          return {
            ...categorie,
            content: action.payload?.content,
            type: action.payload?.type,
          };
        } else return categorie;
      });
    });
    builder.addCase(editCategoryArticle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editCategoryArticle.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
    });
    builder.addCase(deleteCategoryArticle.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = state.categories.filter(
        (categorie) => categorie.id !== action.payload
      );
    });
    builder.addCase(deleteCategoryArticle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCategoryArticle.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
    });
  },
});

export default CategoryArticleSlice.reducer;
export const { setCategoryArticles } = CategoryArticleSlice.actions;
