import { faDownload, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
    useForm,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { NumberParam, useQueryParam } from 'use-query-params';
import { formatDate, removeDays } from '../../../function/Utils';
import { useFetchTokenQuery } from '../../../redux/features/tokenSlice';
import {
    useCheckUserProfileMutation,
    useGetUserDocumentsQuery,
} from '../../../redux/features/userSlice';
import {
    CreateCheckUserProfileDto,
    CreateOrUpdateDocumentUserValidationDto,
    DocumentUserDto,
    DocumentUserSide,
    DocumentUserType,
    DocumentUserValidationDtoForm,
    StateStatus,
    TypeValidationDocumentUser,
    UserWithAllInfoForAdminDto,
} from '../../../types/user';
import { useNotificationContext } from '../../Context/notification-context';
import {
    PrimaryButton,
    SecondaryButton,
    WhiteButton,
} from '../../commun/Buttons';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import StatusDocument from '../../commun/StatusDocument';
import StepComponent from '../../commun/StepComponent';
import InputComponent from '../../commun/formComponent/InputComponent';
import RadioComponent from '../../commun/formComponent/RadioComponent';
import TextAreaComponent from '../../commun/formComponent/TextAreaComponent';
import { Questionnaires } from './Questionnaires';
import { convertTypeDocumentInTypeValidation } from './UserManage';

interface IdentityValidationForm {
    comment?: string;
    validate: boolean | string;
}

interface QuestionValidation {
    comment?: string;
    validate: boolean | string;
}

interface ValidationProfilForm {
    documentValidation: DocumentUserValidationDtoForm[];
    identityValidation: IdentityValidationForm;
    questionValidation: QuestionValidation;
    validate: boolean | string;
    comment?: string;
    date: Date | string;
}

export function ValidationProfilForm({
    open,
    setOpen,
    user,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    user: UserWithAllInfoForAdminDto;
}) {
    const {
        data: documents,
        isLoading: loadingDocuments,
        refetch,
    } = useGetUserDocumentsQuery(user?.id || 0, {
        skip: user === undefined,
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        getValues,
        control,
        formState: { isValid },
    } = useForm<ValidationProfilForm>({
        defaultValues: {
            date: new Date(Date.now()).toJSON().split('T')[0],
            identityValidation: {
                validate: 'true',
            },
            questionValidation: {
                validate: 'true',
            },

            documentValidation: documents?.map((d) => {
                return d.validation
                    ? {
                          ...d.validation,

                          dateValidityEnd: d.validation.dateValidityEnd
                              ? new Date(d.validation.dateValidityEnd)
                                    .toJSON()
                                    .split('T')[0]
                              : undefined,
                          firstname: d.validation.firstname ? 'true' : 'false',
                          lastname: d.validation.lastname ? 'true' : 'false',
                          birthPlace:
                              d.validation.birthPlace !== undefined
                                  ? d.validation.birthPlace
                                      ? 'true'
                                      : 'false'
                                  : undefined,
                          birthDay:
                              d.validation.birthDay !== undefined
                                  ? d.validation.birthDay
                                      ? 'true'
                                      : 'false'
                                  : undefined,
                          dateValidity: d.validation.dateValidity
                              ? 'true'
                              : 'false',
                          address:
                              d.validation.address !== undefined
                                  ? d.validation.address
                                      ? 'true'
                                      : 'false'
                                  : undefined,
                          acceptedDocument: d.validation.acceptedDocument
                              ? 'true'
                              : 'false',
                      }
                    : {
                          dateValidityEnd: 'true',
                          firstname: 'true',
                          lastname: 'true',
                          birthPlace: 'true',
                          birthDay: 'true',
                          address: 'true',
                      };
            }),
        },
    });

    useEffect(() => {
        reset({
            date: new Date(Date.now()).toJSON().split('T')[0],
            identityValidation: {
                validate: 'true',
            },
            questionValidation: {
                validate: 'true',
            },

            documentValidation: documents?.map((d) => {
                return d.validation
                    ? {
                          ...d.validation,

                          dateValidityEnd: d.validation.dateValidityEnd
                              ? new Date(d.validation.dateValidityEnd)
                                    .toJSON()
                                    .split('T')[0]
                              : undefined,
                          firstname: d.validation.firstname ? 'true' : 'false',
                          lastname: d.validation.lastname ? 'true' : 'false',
                          birthPlace:
                              d.validation.birthPlace !== undefined
                                  ? d.validation.birthPlace
                                      ? 'true'
                                      : 'false'
                                  : undefined,
                          birthDay:
                              d.validation.birthDay !== undefined
                                  ? d.validation.birthDay
                                      ? 'true'
                                      : 'false'
                                  : undefined,
                          dateValidity: d.validation.dateValidity
                              ? 'true'
                              : 'false',
                          address:
                              d.validation.address !== undefined
                                  ? d.validation.address
                                      ? 'true'
                                      : 'false'
                                  : undefined,
                          acceptedDocument: d.validation.acceptedDocument
                              ? 'true'
                              : 'false',
                      }
                    : {
                          dateValidityEnd: 'true',
                          firstname: 'true',
                          lastname: 'true',
                          birthPlace: 'true',
                          birthDay: 'true',
                          address: 'true',
                      };
            }),
        });
    }, [documents]);

    const [step, setStep] = useState<number>(0);

    const [queryParam, setQueryParam] = useQueryParam(
        'validationForm',
        NumberParam
    );

    const choseForm = () => {
        switch (step) {
            case 0:
                return (
                    <IdentityForm
                        user={user}
                        register={register}
                        watch={watch}
                    />
                );
            case 1:
                return (
                    <DocumentForm
                        user={user}
                        register={register}
                        watch={watch}
                        getValues={getValues}
                        setValue={setValue}
                    />
                );
            case 2:
                return (
                    <QuestionForm
                        user={user}
                        register={register}
                        watch={watch}
                    />
                );
            case 3:
                return (
                    <ValidationEndForm
                        user={user}
                        register={register}
                        watch={watch}
                        getValues={getValues}
                        setValue={setValue}
                    />
                );
        }
    };
    const [documentsVerified, setDocumentsVerified] = useState<boolean>(false);

    useEffect(() => {
        let documentsVerified = true;
        if (getValues('documentValidation')) {
            for (const validation of getValues('documentValidation')) {
                if (
                    validation.firstname === null ||
                    validation.firstname === undefined
                ) {
                    documentsVerified = false;
                }
            }
            setDocumentsVerified(documentsVerified);
        }
    }, [watch()]);

    const [addCheckUser, { isLoading }] = useCheckUserProfileMutation();

    const { showError, showSuccess } = useNotificationContext();

    const { data: userAdmin, isLoading: loadingUser } = useFetchTokenQuery();

    const onSubmit = async (data: ValidationProfilForm) => {
        const validations: CreateOrUpdateDocumentUserValidationDto[] = [];

        // store in dataBase who check whom when and what is the results

        // store validation of documents

        for (const validation of data.documentValidation) {
            const dataBis: CreateOrUpdateDocumentUserValidationDto = {
                ...validation,
                documentId: validation.documentId,
                userId: user.id,
                firstname: validation.firstname == 'true' ? true : false,
                lastname: validation.lastname == 'true' ? true : false,
                acceptedDocument:
                    validation.acceptedDocument == 'true' ? true : false,
                birthPlace:
                    validation.birthPlace !== undefined
                        ? validation.birthPlace == 'true'
                            ? true
                            : false
                        : undefined,
                birthDay:
                    validation.birthDay !== undefined
                        ? validation.birthDay == 'true'
                            ? true
                            : false
                        : undefined,
                dateValidity: validation.dateValidity == 'true' ? true : false,

                dateValidityEnd: validation.dateValidityEnd
                    ? new Date(validation.dateValidityEnd)
                    : undefined,
                address:
                    validation.address !== undefined
                        ? validation.address == 'true'
                            ? true
                            : false
                        : undefined,
                type: validation.type,
            };
            validations.push(dataBis);
        }

        const dataToStore: CreateCheckUserProfileDto = {
            date: new Date(data.date),
            status: data.validate === 'true' ? true : false,
            userAdminId: userAdmin?.id!,
            userCustomerId: user.id,
            comment: data?.comment,
            commentIdentity: data.identityValidation?.comment,
            commentQuestion: data.questionValidation?.comment,
            validIdentity:
                data.identityValidation?.validate === 'true' ? true : false,
            validQuestion:
                data.questionValidation?.validate === 'true' ? true : false,
        };

        await addCheckUser({
            checkUser: dataToStore,
            validationDocumentUser: validations,
        })
            .unwrap()
            .then(() => {
                showSuccess('Edited', 'Validation stored successfully');

                setOpen(false);
            })
            .catch(() => {
                showError('Error', 'Error storing validation');
            });
    };

    return (
        <PopUp
            open={open}
            width="w-full md:w-9/12"
            setOpen={setOpen}
            buttonBoolean={false}
            title={() => {
                return (
                    <div className="relative">
                        <h3 className="text-3xl font-semibold leading-6 text-gray-900">
                            Valider le profil de {user.firstName}{' '}
                            {user.lastName}
                        </h3>
                    </div>
                );
            }}
        >
            <StepComponent
                steps={[
                    {
                        name: 'Identité',
                        href: '#',
                    },
                    {
                        name: 'Documents',
                        href: '#',
                    },
                    {
                        name: 'Questions',
                        href: '#',
                    },
                    {
                        name: 'Validation',
                        href: '#',
                    },
                ]}
                current={step}
                onClick={(id) => setStep(id)}
            />
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp gap-1"
            >
                {choseForm()}
                <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
                    {step !== 3 ? (
                        <PrimaryButton
                            type="button"
                            className="w-fit mx-auto"
                            disabled={step === 1 && !documentsVerified}
                            onClick={() => setStep(step + 1)}
                        >
                            Suivant
                        </PrimaryButton>
                    ) : null}
                </div>
                <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
                    <WhiteButton
                        onClick={() => {
                            setOpen(false);
                        }}
                        className="w-full"
                    >
                        Cancel
                    </WhiteButton>
                    {step === 3 ? (
                        <PrimaryButton
                            disabled={!isValid || !documentsVerified}
                            type="submit"
                            className="w-full"
                        >
                            {isLoading ? <Loading size={4} /> : 'Valider'}
                        </PrimaryButton>
                    ) : null}
                </div>
            </form>
        </PopUp>
    );
}

export function IdentityForm({
    user,
    register,
    watch,
}: {
    user: UserWithAllInfoForAdminDto;
    watch: UseFormWatch<ValidationProfilForm>;
    register: UseFormRegister<ValidationProfilForm>;
}) {
    return (
        <>
            <div className="relative mx-auto overflow-auto w-full">
                <div className="overflow-auto">
                    <div className="mt-6 relative">
                        {/* <div className="absolute right-5 top-2">
                {user.confirmed ? (
                  <SuccessButton>Compte Confirmer</SuccessButton>
                ) : (
                  <ErrorButton>Compte Non confirmé</ErrorButton>
                )}
              </div> */}

                        <dl className="grid grid-cols-1 sm:grid-cols-2">
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0 ">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Full name
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                    {user.firstName} {user.lastName}
                                </dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Sex
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                    {user.sex}
                                </dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Email address
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                    {user.email}
                                </dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Numero téléphone
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                    {user.userInfo?.phone}
                                </dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Nationality
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                    {user.userInfo?.nationality}
                                </dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Adress
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                    {user.userInfo?.adress}
                                </dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Date of birth
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                    {formatDate(
                                        new Date(user.userInfo?.birthDay!)
                                    )}
                                </dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Birth Place
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                    {user.userInfo?.birthPlace}
                                </dd>
                            </div>
                        </dl>
                    </div>
                    <TextAreaComponent
                        value={'identityValidation.comment'}
                        required={false}
                        register={register}
                        rows={4}
                    >
                        <div className="text-start text-sm text-gray-400 italic">
                            Commentaire
                        </div>
                    </TextAreaComponent>
                    <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                        <RadioComponent
                            name={'identityValidation.validate'}
                            watch={watch}
                            className={{
                                div: '',
                                input: '!w-[15px]',
                                label: 'text-sm',
                            }}
                            register={register}
                            values={[
                                {
                                    label: 'Valider',
                                    value: 'true',
                                },
                                {
                                    label: 'Refuser',
                                    value: 'false',
                                },
                            ]}
                        ></RadioComponent>
                    </div>
                </div>
            </div>
        </>
    );
}

interface DownloadTracking {
    document: DocumentUserDto;
    index: number;
}

export function DocumentForm({
    user,
    register,
    watch,
    getValues,
    setValue,
}: {
    user: UserWithAllInfoForAdminDto;
    watch: UseFormWatch<ValidationProfilForm>;
    register: UseFormRegister<ValidationProfilForm>;
    setValue: UseFormSetValue<ValidationProfilForm>;

    getValues: UseFormGetValues<ValidationProfilForm>;
}) {
    const {
        data: documents,
        isLoading: loadingDocuments,
        refetch,
    } = useGetUserDocumentsQuery(user?.id || 0, {
        skip: user === undefined,
    });

    const [downloadTrack, setDownloadTrack] = useState<DownloadTracking[]>([]);

    useEffect(() => {
        setDownloadTrack(
            documents?.map((d, index) => {
                return {
                    document: d,
                    index: index,
                };
            }) ?? []
        );
    }, [user]);

    const handleClick = (doc: DocumentUserDto) => {
        const link = document.createElement('a');
        link.href = doc.url;
        link.download = doc.name;

        link.click();
        setDownloadTrack((curr) => {
            return curr.filter((d) => d.document.id !== doc.id);
        });
    };

    return (
        <>
            <div className="relative mx-auto">
                <h3 className="t font-mainFontFamily font-semibold mb-3">
                    <span className=" uppercase ">{user?.lastName} </span>
                    {user?.firstName}
                </h3>
                <div className="flex flex-wrap w-full px-2 mb-4 gap-2">
                    {downloadTrack.length > 0
                        ? downloadTrack.map((d, key) => (
                              <div
                                  key={key}
                                  // href={d.document.url}
                                  // download={d.document.name}
                                  onClick={() => handleClick(d.document)}
                              >
                                  <div className="text-sm font-medium leading-6 cursor-pointer text-gray-900">
                                      <FontAwesomeIcon
                                          icon={faDownload}
                                          className="text-mainColor hover:text-secondColor w-[20px] h-[20px]"
                                      />{' '}
                                      {d.document.type}
                                  </div>
                              </div>
                          ))
                        : null}
                </div>
                {documents ? (
                    <div className="max-w-xs md:max-w-none w-full flex flex-col gap-3">
                        {documents?.map((d, key) => (
                            <DisplayDocumentValidationForm
                                document={d}
                                user={user}
                                key={key}
                                index={key}
                                register={register}
                                watch={watch}
                                getValues={getValues}
                                setValue={setValue}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
        </>
    );
}

export function displayDocumentSide(side: DocumentUserSide) {
    if (side === DocumentUserSide.BOTH) return '';
    return side === DocumentUserSide.FRONT ? 'Recto' : 'Verso';
}

export function DisplayDocumentValidationForm({
    document,
    user,
    register,
    watch,
    index,
    getValues,
    setValue,
}: {
    document: DocumentUserDto;
    user: UserWithAllInfoForAdminDto;
    watch: UseFormWatch<ValidationProfilForm>;
    register: UseFormRegister<ValidationProfilForm>;
    index: number;
    getValues: UseFormGetValues<ValidationProfilForm>;
    setValue: UseFormSetValue<ValidationProfilForm>;
}) {
    const type = document?.key.split('.')[1];

    const [popUp, setPopUp] = useState(false);

    const [isValid, setIsValid] = useState(false);

    const typeValidation = convertTypeDocumentInTypeValidation(document.type);
    useEffect(() => {
        setValue(`documentValidation.${index}.documentId`, document.id);

        setValue(`documentValidation.${index}.type`, typeValidation);
    }, [document]);

    useEffect(() => {
        if (
            getValues(`documentValidation.${index}.acceptedDocument`) != null &&
            (([
                DocumentUserType.ADDRESS,
                DocumentUserType.ADDRESS_THIRD_PARTY,
                DocumentUserType.ADDRESS_THIRD_PARTY_ATTESTATION,
            ].includes(document.type) &&
                getValues(`documentValidation.${index}.firstname`) != null &&
                getValues(`documentValidation.${index}.lastname`) != null &&
                getValues(`documentValidation.${index}.address`) != null &&
                getValues(`documentValidation.${index}.dateValidity`) !=
                    null) ||
                ([
                    DocumentUserType.IDENTITY_1,
                    DocumentUserType.IDENTITY_2,
                ].includes(document.type) &&
                    getValues(`documentValidation.${index}.firstname`) !=
                        null &&
                    getValues(`documentValidation.${index}.lastname`) != null &&
                    getValues(`documentValidation.${index}.birthDay`) != null &&
                    getValues(`documentValidation.${index}.birthPlace`) !=
                        null &&
                    getValues(`documentValidation.${index}.dateValidity`) !=
                        null &&
                    ((getValues(`documentValidation.${index}.dateValidity`) !=
                        null &&
                        getValues(`documentValidation.${index}.dateValidityEnd`)
                            ?.toString()
                            .split('-').length === 3) ||
                        getValues(`documentValidation.${index}.dateValidity`) ==
                            'false')) ||
                ([DocumentUserType.ADDRESS_IDENTTITY].includes(document.type) &&
                    getValues(`documentValidation.${index}.firstname`) !=
                        null &&
                    getValues(`documentValidation.${index}.lastname`) != null &&
                    ((getValues(`documentValidation.${index}.dateValidity`) !=
                        null &&
                        getValues(`documentValidation.${index}.dateValidityEnd`)
                            ?.toString()
                            .split('-').length === 3) ||
                        getValues(`documentValidation.${index}.dateValidity`) ==
                            'false')))
        ) {
            setIsValid(true);
        }
    }, [watch()]);
    return (
        <>
            {popUp ? (
                <PopUp
                    open={popUp}
                    setOpen={setPopUp}
                    buttonBoolean={false}
                    width="w-full md:w-9/12"
                    title={() => {
                        return (
                            <>
                                <h3 className="text-2xl font-semibold leading-6 text-gray-900">
                                    {document.type} - {document.name} -{' '}
                                    {displayDocumentSide(document.side)}
                                </h3>
                                <p>{decodeURIComponent(document.fileName)}</p>
                            </>
                        );
                    }}
                >
                    <div className="w-full flex mx-auto flex-col justify-center gap-3">
                        <div className="grid gap-x-2 md:grid-cols-2">
                            <div className="">
                                <a href={document.url} download={document.name}>
                                    <div className="text-sm font-medium leading-6 cursor-pointer text-gray-900">
                                        <FontAwesomeIcon
                                            icon={faDownload}
                                            className="text-mainColor hover:text-secondColor w-[20px] h-[20px]"
                                        />
                                    </div>
                                </a>
                                {type !== 'pdf' ? (
                                    <img src={document?.url} className="" />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faFilePdf}
                                        size={'2x'}
                                    />
                                )}
                            </div>
                            <div className="flex flex-col gap-2 mt-2">
                                <div className="grid gap-2 grid-cols-2 md:grid-cols-3 items-center">
                                    <h4 className="text-start font-semibold">
                                        Prénom
                                    </h4>
                                    <p className="text-sm text-start">
                                        {user.firstName}
                                    </p>
                                    <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                        <RadioComponent
                                            name={`documentValidation.${index}.firstname`}
                                            watch={watch}
                                            className={{
                                                div: '',
                                                input: '!w-[15px]',
                                                label: 'text-sm',
                                            }}
                                            register={register}
                                            values={[
                                                {
                                                    label: 'Valider',
                                                    value: 'true',
                                                },
                                                {
                                                    label: 'Refuser',
                                                    value: 'false',
                                                },
                                            ]}
                                        ></RadioComponent>
                                    </div>
                                    <div className="col-span-2 md:col-span-3">
                                        <TextAreaComponent
                                            required={false}
                                            rows={2}
                                            value={`documentValidation.${index}.firstnameComment`}
                                            className={{
                                                input: '!outline-1 border-2',
                                            }}
                                            register={register}
                                        >
                                            <div className="text-start text-sm text-gray-400 italic">
                                                Commentaire
                                            </div>
                                        </TextAreaComponent>
                                    </div>
                                </div>
                                <div className="grid gap-2 grid-cols-2 md:grid-cols-3 items-center">
                                    <h4 className="text-start font-semibold">
                                        Nom
                                    </h4>
                                    <p className="text-sm text-start">
                                        {user.lastName}
                                    </p>
                                    <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                        <RadioComponent
                                            name={`documentValidation.${index}.lastname`}
                                            watch={watch}
                                            className={{
                                                div: '',
                                                input: '!w-[15px]',
                                                label: 'text-sm',
                                            }}
                                            register={register}
                                            values={[
                                                {
                                                    label: 'Valider',
                                                    value: 'true',
                                                },
                                                {
                                                    label: 'Refuser',
                                                    value: 'false',
                                                },
                                            ]}
                                        ></RadioComponent>
                                    </div>
                                    <div className=" col-span-2 md:col-span-3">
                                        <TextAreaComponent
                                            required={false}
                                            rows={2}
                                            value={`documentValidation.${index}.lastnameComment`}
                                            className={{
                                                input: '!outline-1 border-2',
                                            }}
                                            register={register}
                                        >
                                            <div className="text-start text-sm text-gray-400 italic">
                                                Commentaire
                                            </div>
                                        </TextAreaComponent>
                                    </div>
                                </div>
                                {[TypeValidationDocumentUser.IDENTITY].includes(
                                    typeValidation
                                ) ? (
                                    <>
                                        <div className="grid gap-2 grid-cols-2 md:grid-cols-3 items-center">
                                            <h4 className="text-start font-semibold">
                                                Date de naissance
                                            </h4>
                                            <p className="text-sm text-start">
                                                {user.userInfo?.birthDay
                                                    ? formatDate(
                                                          new Date(
                                                              user.userInfo?.birthDay
                                                          )
                                                      )
                                                    : 'Date de naissance non trouvée'}
                                            </p>
                                            <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                                <RadioComponent
                                                    name={`documentValidation.${index}.birthDay`}
                                                    watch={watch}
                                                    className={{
                                                        div: '',
                                                        input: '!w-[15px]',
                                                        label: 'text-sm',
                                                    }}
                                                    register={register}
                                                    values={[
                                                        {
                                                            label: 'Valider',
                                                            value: 'true',
                                                        },
                                                        {
                                                            label: 'Refuser',
                                                            value: 'false',
                                                        },
                                                    ]}
                                                ></RadioComponent>
                                            </div>
                                            <div className=" col-span-2 md:col-span-3">
                                                <TextAreaComponent
                                                    required={false}
                                                    rows={2}
                                                    value={`documentValidation.${index}.birthDayComment`}
                                                    className={{
                                                        input: '!outline-1 border-2',
                                                    }}
                                                    register={register}
                                                >
                                                    <div className="text-start text-sm text-gray-400 italic">
                                                        Commentaire
                                                    </div>
                                                </TextAreaComponent>
                                            </div>
                                        </div>
                                        <div className="grid gap-2 grid-cols-2 md:grid-cols-3 items-center justify-start">
                                            <h4 className="text-start font-semibold">
                                                Lieu de naissance
                                            </h4>
                                            <p className="text-sm text-start">
                                                {user.userInfo?.birthPlace}
                                            </p>
                                            <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                                <RadioComponent
                                                    name={`documentValidation.${index}.birthPlace`}
                                                    watch={watch}
                                                    className={{
                                                        div: '',
                                                        input: '!w-[15px]',
                                                        label: 'text-sm',
                                                    }}
                                                    register={register}
                                                    values={[
                                                        {
                                                            label: 'Valider',
                                                            value: 'true',
                                                        },
                                                        {
                                                            label: 'Refuser',
                                                            value: 'false',
                                                        },
                                                    ]}
                                                ></RadioComponent>
                                            </div>
                                            <div className=" col-span-2 md:col-span-3">
                                                <TextAreaComponent
                                                    required={false}
                                                    rows={2}
                                                    value={`documentValidation.${index}.birthPlaceComment`}
                                                    className={{
                                                        input: '!outline-1 border-2',
                                                    }}
                                                    register={register}
                                                >
                                                    <div className="text-start text-sm text-gray-400 italic">
                                                        Commentaire
                                                    </div>
                                                </TextAreaComponent>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {[
                                    TypeValidationDocumentUser.IDENTITY,
                                    TypeValidationDocumentUser.IDENTITY_HOSY_THIRD_PARTY,
                                ].includes(typeValidation) ? (
                                    <div className="md:grid flex flex-col w-full gap-2 md:grid-cols-2 md:items-center md:justify-start">
                                        <h4 className="text-start font-semibold">
                                            Date validité
                                        </h4>

                                        <InputComponent
                                            value={`documentValidation.${index}.dateValidityEnd`}
                                            register={register}
                                            required={
                                                watch(
                                                    `documentValidation.${index}.dateValidity`
                                                ) === 'false'
                                                    ? false
                                                    : true
                                            }
                                            type="date"
                                            className={{
                                                input: 'border-2 rounded-sm',
                                            }}
                                        >
                                            <div className="text-start">
                                                Fin
                                            </div>
                                        </InputComponent>
                                        <div className="flex gap-2 col-span-3">
                                            <RadioComponent
                                                name={`documentValidation.${index}.dateValidity`}
                                                watch={watch}
                                                className={{
                                                    div: '',
                                                    input: '!w-[15px]',
                                                    label: 'text-sm',
                                                }}
                                                register={register}
                                                values={[
                                                    {
                                                        label: 'Valider',
                                                        value: 'true',
                                                    },
                                                    {
                                                        label: 'Refuser',
                                                        value: 'false',
                                                    },
                                                ]}
                                            ></RadioComponent>
                                        </div>
                                        <div className=" col-span-2 md:col-span-3">
                                            <TextAreaComponent
                                                required={false}
                                                rows={2}
                                                value={`documentValidation.${index}.dateValidityComment`}
                                                className={{
                                                    input: '!outline-1 border-2',
                                                }}
                                                register={register}
                                            >
                                                <div className="text-start text-sm text-gray-400 italic">
                                                    Commentaire
                                                </div>
                                            </TextAreaComponent>
                                        </div>
                                    </div>
                                ) : null}
                                {[
                                    TypeValidationDocumentUser.RESIDENCY,
                                    TypeValidationDocumentUser.RESIDENCY_HOST_THIRD_PARTY,
                                    TypeValidationDocumentUser.ATTESTATION_HOST_THIRD_PARTY,
                                ].includes(typeValidation) ? (
                                    <>
                                        <div className="grid gap-2 grid-cols-2 md:grid-cols-3 items-center">
                                            <h4 className="text-start font-semibold">
                                                Adresse
                                            </h4>
                                            <p className="text-sm text-start">
                                                {user.userInfo?.adress}
                                            </p>
                                            <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                                <RadioComponent
                                                    name={`documentValidation.${index}.address`}
                                                    watch={watch}
                                                    className={{
                                                        div: '',
                                                        input: '!w-[15px]',
                                                        label: 'text-sm',
                                                    }}
                                                    register={register}
                                                    values={[
                                                        {
                                                            label: 'Valider',
                                                            value: 'true',
                                                        },
                                                        {
                                                            label: 'Refuser',
                                                            value: 'false',
                                                        },
                                                    ]}
                                                ></RadioComponent>
                                            </div>
                                            <div className=" col-span-2 md:col-span-3">
                                                <TextAreaComponent
                                                    required={false}
                                                    rows={2}
                                                    value={`documentValidation.${index}.addressComment`}
                                                    className={{
                                                        input: '!outline-1 border-2',
                                                    }}
                                                    register={register}
                                                >
                                                    <div className="text-start text-sm text-gray-400 italic">
                                                        Commentaire
                                                    </div>
                                                </TextAreaComponent>
                                            </div>
                                        </div>
                                        <div className="grid gap-2 grid-cols-2 md:grid-cols-3 items-center">
                                            <h4 className="text-start font-semibold">
                                                Date Validity
                                            </h4>
                                            <p className="text-sm text-start">
                                                Document après{' '}
                                                <span className=" font-semibold">
                                                    {user.dateSubscription
                                                        ? formatDate(
                                                              removeDays(
                                                                  new Date(
                                                                      user.dateSubscription
                                                                  ),
                                                                  90
                                                              )
                                                          )
                                                        : null}{' '}
                                                </span>
                                                autorisé
                                            </p>
                                            <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                                <RadioComponent
                                                    name={`documentValidation.${index}.dateValidity`}
                                                    watch={watch}
                                                    className={{
                                                        div: '',
                                                        input: '!w-[15px]',
                                                        label: 'text-sm',
                                                    }}
                                                    register={register}
                                                    values={[
                                                        {
                                                            label: 'Valider',
                                                            value: 'true',
                                                        },
                                                        {
                                                            label: 'Refuser',
                                                            value: 'false',
                                                        },
                                                    ]}
                                                ></RadioComponent>
                                            </div>
                                            <div className=" col-span-2 md:col-span-3">
                                                <TextAreaComponent
                                                    required={false}
                                                    rows={2}
                                                    value={`documentValidation.${index}.dateValidityComment`}
                                                    className={{
                                                        input: '!outline-1 border-2',
                                                    }}
                                                    register={register}
                                                >
                                                    <div className="text-start text-sm text-gray-400 italic">
                                                        Commentaire
                                                    </div>
                                                </TextAreaComponent>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                        <div className="">
                            <div className="grid md:grid-cols-2 w-full my-2 md:w-2/3 mx-auto justify-center items-center">
                                <h4 className="text-start font-semibold">
                                    Type du document accepté
                                </h4>
                                <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                                    <RadioComponent
                                        name={`documentValidation.${index}.acceptedDocument`}
                                        watch={watch}
                                        className={{
                                            div: '',
                                            input: '!w-[15px]',
                                            label: 'text-sm',
                                        }}
                                        register={register}
                                        values={[
                                            {
                                                label: 'Oui',
                                                value: 'true',
                                            },
                                            {
                                                label: 'Non',
                                                value: 'false',
                                            },
                                        ]}
                                    ></RadioComponent>
                                </div>
                            </div>
                            <TextAreaComponent
                                register={register}
                                value={`documentValidation.${index}.comment`}
                                rows={3}
                                required={false}
                                className={{
                                    input: '!outline-1 border-2',
                                }}
                            >
                                <div className="text-start text-gray-400 italic">
                                    Commentaire général
                                </div>
                            </TextAreaComponent>
                        </div>
                        <div className="mt-5 self-center mx:auto flex gap-3 text-center w-full max-w-lg">
                            <WhiteButton
                                onClick={() => {
                                    setPopUp(false);
                                }}
                                className="w-full"
                            >
                                Cancel
                            </WhiteButton>
                            <PrimaryButton
                                disabled={!isValid}
                                onClick={() => {
                                    setPopUp(false);
                                }}
                                type="button"
                                className="w-full"
                            >
                                Terminé
                            </PrimaryButton>
                        </div>
                    </div>
                </PopUp>
            ) : (
                <div
                    className={`w-full items-center gap-3 ${
                        type !== 'pdf'
                            ? 'grid grid-cols-2'
                            : 'flex space-y-2 flex-wrap sm:flex-nowrap justify-between'
                    }`}
                >
                    <div
                        className={`${
                            type !== 'pdf'
                                ? 'w-full cursor-pointer col-span-2 mx-auto flex justify-center items-center'
                                : 'w-10'
                        } justify-self-start`}
                    >
                        {type !== 'pdf' ? (
                            <img
                                src={document?.url}
                                className="max-w-xs"
                                onClick={() => setPopUp(true)}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faFilePdf} size={'2x'} />
                        )}
                    </div>

                    <div className="text-sm text-start w-full font-medium leading-6 text-gray-900">
                        <span className=" font-semibold">
                            {document.type} - {document.name} -{' '}
                            {displayDocumentSide(document.side)}
                        </span>{' '}
                        <span className="text-gray-500">
                            {decodeURIComponent(document.fileName)}
                        </span>
                    </div>
                    <div className="w-10  flex gap-5 items-center">
                        <a href={document.url} download={'test'}>
                            <div className="text-sm font-medium leading-6 cursor-pointer text-gray-900">
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    className="text-mainColor hover:text-secondColor w-[20px] h-[20px]"
                                />
                            </div>
                        </a>
                        <SecondaryButton onClick={() => setPopUp(true)}>
                            Vérifier le document
                        </SecondaryButton>
                        <StatusDocument
                            status={
                                isValid
                                    ? StateStatus.onGoing
                                    : StateStatus.unchecked
                            }
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export function QuestionForm({
    user,
    register,
    watch,
}: {
    user: UserWithAllInfoForAdminDto;
    watch: UseFormWatch<ValidationProfilForm>;
    register: UseFormRegister<ValidationProfilForm>;
}) {
    return (
        <>
            <Questionnaires userId={user.id!} />
            <TextAreaComponent
                value={'questionValidation.comment'}
                required={false}
                register={register}
                rows={4}
            >
                <div className="text-start text-sm text-gray-400 italic">
                    Commentaire
                </div>
            </TextAreaComponent>
            <div className="flex mx-auto col-span-1 md:col-span-1 gap-2">
                <RadioComponent
                    name={'questionValidation.validate'}
                    watch={watch}
                    className={{
                        div: '',
                        input: '!w-[20px]',
                        label: 'text-sm',
                    }}
                    register={register}
                    values={[
                        {
                            label: 'Valider',
                            value: 'true',
                        },
                        {
                            label: 'Refuser',
                            value: 'false',
                        },
                    ]}
                ></RadioComponent>
            </div>
        </>
    );
}

export function ValidationEndForm({
    user,
    register,
    watch,
    getValues,
    setValue,
}: {
    user: UserWithAllInfoForAdminDto;
    watch: UseFormWatch<ValidationProfilForm>;
    register: UseFormRegister<ValidationProfilForm>;
    setValue: UseFormSetValue<ValidationProfilForm>;

    getValues: UseFormGetValues<ValidationProfilForm>;
}) {
    return (
        <div
            className="mx-auto flex flex-col gap-2
     w-full max-w-sm"
        >
            <InputComponent register={register} type="date" value="date">
                <div className="text-start">
                    Date <span className="text-red-500">*</span>
                </div>
            </InputComponent>
            <RadioComponent
                name={'validate'}
                watch={watch}
                className={{
                    div: '',
                    input: '!w-[20px]',
                    label: 'text-sm',
                }}
                register={register}
                values={[
                    {
                        label: 'Valider',
                        value: 'true',
                    },
                    {
                        label: 'Refuser',
                        value: 'false',
                    },
                ]}
            >
                <div className="text-start">
                    KYC validé/refusé <span className="text-red-500">*</span>
                </div>
            </RadioComponent>
            <TextAreaComponent
                required={false}
                register={register}
                value={'comment'}
                rows={3}
            >
                <div className="text-start text-gray-400 italic">
                    Commentaire général
                </div>
            </TextAreaComponent>
        </div>
    );
}
