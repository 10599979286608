import AddInvestAccountInvestir from '@components/DashboardUser/Investir/AddInvestAccountInvestir';
import EditInvestAccountInvestir from '@components/DashboardUser/Investir/EditInvestAccountInvestir';
import DisplayInvestAccount from '@components/DashboardUser/Profile/InvestAccount/DisplayInvestAccount';
import { PrimaryButton } from '@components/commun/Buttons';
import AddDashComponent from '@components/commun/Layout/AddDashComponent';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useFetchTokenQuery } from '../../../redux/features/tokenSlice';
import { useGetUserWithAllInfoForCustomerQuery } from '../../../redux/features/userSlice';
import { UserStatusType } from '../../../types/user';
import CheckboxComponent from '../../commun/formComponent/CheckboxComponent';
import { DisplayMoralAccountCard } from '../Profile/DisplayMoralAccountCard';

interface Form {
    accept: boolean;
}

function EtapeInvestAccount({
    toogleInvestInfo,
    setToogleInvestInfo,
}: {
    toogleInvestInfo: boolean;
    setToogleInvestInfo: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { data: userFetch, isLoading: isLoadingFetch } = useFetchTokenQuery();

    const { data: user, isLoading: isLoading } =
        useGetUserWithAllInfoForCustomerQuery(userFetch?.id || 0, {
            skip: userFetch ? false : true,
        });

    const [viewCard, setViewCard] = useState(false);
    const [investAccountIdQuery, setInvestAccountIdQuery] = useQueryParam(
        'investAccountId',
        NumberParam
    );

    const { register, watch } = useForm<Form>({
        defaultValues: {
            accept: false,
        },
    });

    const AcceptComponent = () => {
        return (
            <div className="mt-[30px] w-full md:mx-auto md:w-11/12 lg:w-9/12">
                <CheckboxComponent
                    register={register}
                    watch={watch}
                    values={[
                        {
                            label: 'Je certifie que les informations ci-dessus sont exactes et complètes.',
                            name: 'accept',
                        },
                    ]}
                />
            </div>
        );
    };

    return (
        <div className="relative mx-auto overflow-auto w-full">
            <>
                <TitreInvestirComponent>
                    Choisissez un compte d'investissement ou créez-en un
                </TitreInvestirComponent>
                {viewCard && investAccountIdQuery && user ? (
                    <DisplayMoralAccountCard
                        userId={user?.id}
                        open={viewCard}
                        setOpen={setViewCard}
                    />
                ) : null}
                <div className="mt-2">
                    {user?.investAccounts?.length === 0 ? (
                        <div className="flex flex-col items-center justify-center gap-2 my-2">
                            <p className="text-center">
                                Vous n'avez pas encore de compte
                                d'investissement
                            </p>
                            {user?.status === UserStatusType.newUser ? (
                                <p className=" text-orange-500 text-justify my-2 w-full md:w-10/12 mx-auto">
                                    Vous devez d'abord remplir vos données
                                    personnelles afin de valider votre profil.
                                    Vous pourrez ensuite créer un nombre
                                    illimité de comptes d'investissement.{' '}
                                </p>
                            ) : null}
                        </div>
                    ) : null}
                    {/* <div className="w-full mt-2 mb-4 flex justify-center items-center">
            <Link to="/DashBoardClient/invest-account?pageInvestAccount=add-invest-account">
              <PrimaryButton disabled={user?.status === UserStatusType.newUser}>
                Créer un compte
              </PrimaryButton>
            </Link>
          </div> */}
                    <div className="mx-auto grid md:grid-cols-2 lg:grid-cols-3 gap-2">
                        {user?.investAccounts?.map((i, index) => (
                            <div
                                key={index}
                                className={`w-full relative flex flex-col justify-start hover:bg-bgDashboardClient cursor-pointer bg-secondBackgroundColor rounded-lg py-2 px-4
              ${
                  i.id === investAccountIdQuery ? '!bg-gray-300 opacity-75' : ''
              }`}
                                onClick={() => setInvestAccountIdQuery(i.id)}
                            >
                                <DisplayInvestAccount
                                    setViewCard={setViewCard}
                                    viewCard={viewCard}
                                    i={i}
                                    renderActionButton={false}
                                    customButton={
                                        <PrimaryButton>
                                            Sélectionner
                                        </PrimaryButton>
                                    }
                                />
                            </div>
                        ))}
                        <AddDashComponent
                            onClick={() => setInvestAccountIdQuery(0)}
                            className={`${investAccountIdQuery === 0 ? '!bg-gray-300 opacity-75' : ''}`}
                        >
                            <p>Ajouter un compte</p>
                        </AddDashComponent>
                    </div>
                </div>
                {investAccountIdQuery !== undefined && user ? (
                    investAccountIdQuery !== 0 ? (
                        <EditInvestAccountInvestir
                            toogleInvestInfo={toogleInvestInfo}
                            setToogleInvestInfo={setToogleInvestInfo}
                            isUserAccepted={watch('accept')}
                            user={user}
                        >
                            <AcceptComponent />
                        </EditInvestAccountInvestir>
                    ) : (
                        <AddInvestAccountInvestir
                            setToogleInvestInfo={setToogleInvestInfo}
                            isUserAccepted={watch('accept')}
                            user={user}
                        >
                            <AcceptComponent />
                        </AddInvestAccountInvestir>
                    )
                ) : null}
            </>
        </div>
    );
}

export function TitreInvestirComponent({
    children,
    className,
    onClick,
}: {
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
}) {
    return (
        <h2
            onClick={onClick}
            className={` font-mainFontFamily text-start bg-gray-100 text-secondColor rounded-md p-1  text-xl mt-4 ${className}`}
        >
            {children}
        </h2>
    );
}

export default EtapeInvestAccount;
