import { convertToHTML } from "draft-convert";
import {
  CompositeDecorator,
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { PrimaryButton } from "../Buttons";
import { stateToHTML } from "draft-js-export-html";
import draftToHtml from "draftjs-to-html";
import { debounce } from "lodash";
import { createMarkup } from "../../../function/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface Props {
  className?: string;
  content: string;
  setContent: any;
  children?: React.ReactNode;
}

const TextEditorComponent: React.FC<Props> = ({
  className,
  setContent,
  content,
  children,
}) => {
  const [editorState, setEditorState] = useState(() =>
    (content === undefined || content === "") && content?.includes("blocks")
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(content)))
      : EditorState.createEmpty()
  );

  const [initiate, setInitiate] = useState(false);

  useEffect(() => {
    if (!initiate) {
      let value = convertToRaw(editorState.getCurrentContent()).blocks;
      if (
        !initiate &&
        content &&
        content.includes("blocks") &&
        value?.length === 1 &&
        value[0].text === ""
      ) {
        setEditorState(
          EditorState.createWithContent(convertFromRaw(JSON.parse(content)))
        );
        setInitiate(true);
      }
    }
  }, [content]);

  const [htmlContent, setHtmlContent] = useState<any>(null);

  const editorStateRef = useRef(editorState);

  const [previewToggle, setPreviewToggle] = useState(true);

  useEffect(() => {
    editorStateRef.current = editorState; // Update the ref when the editorState changes
  }, [editorState]);

  // Create a debounced function that updates the content and HTML content
  const debouncedUpdateContent = useRef(
    debounce(() => {
      const currentContentState = editorStateRef.current.getCurrentContent();
      const contentRaw = JSON.stringify(convertToRaw(currentContentState));
      // if (convertToRaw(currentContentState))
      const html = draftToHtml(convertToRaw(currentContentState));
      setHtmlContent(html);
      setContent(contentRaw);
    }, 500)
  ).current;

  useEffect(() => {
    debouncedUpdateContent();
    return () => {
      debouncedUpdateContent.cancel(); // Assuming lodash's debounce is used which provides a .cancel method
    };
    // No need to return a cleanup function for the debounce as useRef is handling the persistence
  }, [editorState]); // Dependency array includes editorState to run the effect on change

  return (
    <div className={` my-2 ${className}`}>
      {children}
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        onEditorStateChange={setEditorState}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
      />
      {/* <div className="w-full flex justify-center items-center">
        <PrimaryButton onClick={handleSaving}>Save</PrimaryButton>
      </div> */}

      <div className="mt-4">
        <div
          className="grid grid-cols-2 cursor-pointer"
          onClick={() => setPreviewToggle(!previewToggle)}
        >
          <h3 className=" font-mainFontFamily text-mainColor text-2xl">
            Preview
          </h3>
          <div className="md:pr-4 justify-self-end">
            <FontAwesomeIcon
              icon={faArrowRight}
              className={`text-mainColor text-2xl ${
                previewToggle ? " rotate-90" : ""
              }`}
            />
          </div>
        </div>
        {htmlContent && previewToggle ? (
          <div
            className="p-2 text-editor border-2 rounded-md text-start"
            dangerouslySetInnerHTML={createMarkup(htmlContent)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TextEditorComponent;

// import {
//     Editor,
//     EditorState,
//     ContentState,
//     convertFromHTML,
//     CompositeDecorator,
//     convertToRaw,
//     getDefaultKeyBinding,
//   } from 'draft-js';

//   const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
//       const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');

//       componentWillMount() {
//         const { value } = this.props
//         const blocksFromHTML = convertFromHTML(value));
//         const state = ContentState.createFromBlockArray(
//           blocksFromHTML.contentBlocks,
//           blocksFromHTML.entityMap,
//         );
//         this.state = {
//           editorState: EditorState.createWithContent(
//             state,
//             compositeDecorator,
//           ),
//         };
//   }
