import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { number } from "yup";
import { formatDate, isDateClosed } from "../../function/Utils";
import { CreateDebtDto, DebtDto } from "../../types/debt";



interface debtState {
    debt: DebtDto[];
    loading: boolean;
    errors: any;
}

const initialState: debtState = {
    debt: [],
    loading: false,
    errors: null,
};

// actions are processes that get data from backend
export const getDebts = createAsyncThunk<
    DebtDto[]
>("Debts/getDebts", async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}debt`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});


export const addDebt = createAsyncThunk<
    DebtDto,
    CreateDebtDto
>("Debts/addDebt", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "post",
            url: `${import.meta.env.VITE_API_URL}debt`,
            data: data,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const addDebts = createAsyncThunk<
    DebtDto[],
    DebtDto[]
>("Debts/addDebts", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "post",
            url: `${import.meta.env.VITE_API_URL}debt/array`,
            data: data,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const editDebt = createAsyncThunk<
    DebtDto,
    DebtDto
>("Debts/editDebt", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "put",
            url: `${import.meta.env.VITE_API_URL}debt/${data.id}`,
            data: { ...data },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});



export const deleteDebt = createAsyncThunk<number, number>(
    "Debts/deleteDebt",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}debt/${id}`,
                withCredentials: true,
            });
            return id;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteDebtByDate = createAsyncThunk<string, string>(
    "Debts/deleteDebtByDate",
    async (date: string, thunkApi) => {
        try {

            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}debt/date/${date}`,
                withCredentials: true,
            });
            return date;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }

    }
);

export const deleteAllDebts = createAsyncThunk(
    "Debts/deleteAllDebts",
    async (_, thunkAPI) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}debt`,
                withCredentials: true,
            });

        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


// reducers -> reduce to a specific state -> changes state

export const DebtSlice = createSlice({
    name: "Debts",
    initialState,
    reducers: {
        setDebts: (
            state,
            action: PayloadAction<DebtDto[]>
        ) => {
            state.debt = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDebts.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDebts.fulfilled, (state, action) => {
            state.debt = action.payload;
            state.loading = false;
        });
        builder.addCase(getDebts.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addDebts.fulfilled, (state, action) => {
            state.debt = [...state.debt, ...action.payload];
            state.loading = false;
        });
        builder.addCase(addDebts.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addDebts.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addDebt.fulfilled, (state, action) => {
            state.debt = [...state.debt, action.payload];
            state.loading = false;
        });
        builder.addCase(addDebt.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addDebt.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editDebt.fulfilled, (state, action) => {
            state.loading = false;
            state.debt = state.debt.map((debt) => {
                if (debt.id === action.payload.id) {
                    return {
                        ...debt,
                        interest: action.payload?.interest,
                        valorisation: action.payload?.valorisation,
                        date: action.payload?.date,
                        comment: action.payload?.comment,
                        typeDebt: action.payload?.typeDebt,

                    };
                } else return debt;
            });
        });
        builder.addCase(editDebt.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editDebt.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });

        builder.addCase(deleteDebt.fulfilled, (state, action) => {
            state.loading = false;
            state.debt = state.debt.filter(
                (debt) => debt.id !== action.payload
            );
        });
        builder.addCase(deleteDebt.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteDebt.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteAllDebts.fulfilled, (state, action) => {
            state.loading = false;
            state.debt = []
        });
        builder.addCase(deleteAllDebts.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteAllDebts.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteDebtByDate.fulfilled, (state, action) => {
            state.loading = false;
            state.debt = state.debt.filter(
                (debt) => !isDateClosed(new Date(debt.date), new Date(action.payload))
            );
        });
        builder.addCase(deleteDebtByDate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteDebtByDate.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
    },
});

export default DebtSlice.reducer;
export const { setDebts } = DebtSlice.actions;
