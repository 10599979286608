import React, { useContext } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import cookie from 'js-cookie';
import { useEffect, useState } from 'react';
import { isEmpty } from '../../function/Utils';
import { logout } from '../../function/logout';
import Page from '../../function/page_info';
import { useFetchTokenQuery } from '../../redux/features/tokenSlice';
import { useAppSelector } from '../../redux/store';
import { AllowState } from '../Context/AppContext';
import logo from './../../assets/logo.png';
import Loading from './Loading';

// import { icon } from '@fortawesome/fontawesome-svg-core';

// import { useState } from 'react'

interface navigationProps {
    bol_nosfonds: boolean;
    bol_transparent: boolean;
    id_color?: string;
}

interface navigationInterface {
    navName: string;
    path?: string;
    sousMenuList?:
        | {
              sousMenuName: string;
              path: string;
          }[]
        | false;
    numero?: number;
}

const Navigation: React.FC<navigationProps> = ({
    bol_nosfonds,
    bol_transparent,
    id_color,
}): JSX.Element => {
    useEffect(() => {
        if (
            window.location.pathname != '/' &&
            cookie.get('accepterCookies') === undefined
        ) {
            cookie.set('accepterCookies', 'true', { expires: 100 });
        }
    }, []);

    const allowState = useContext(AllowState);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [allowState]);
    // const [toggleIcon, setToggleIcon] = useState(faBars);
    const [toggleBol, setToggleBol] = useState(false);
    // const dispatch = useDispatch();
    const [scrollPageAvant, setScrollPageAvant] = useState(0);
    const pageData = useAppSelector(
        (state) => state.pageMeasurement.measurements
    );
    const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();

    const navigate = useNavigate();
    const [navigationList, setNavigationList] = useState<navigationInterface[]>(
        [
            {
                navName: 'Accueil',
                path: '/',
            },
            {
                navName: 'Nos fonds',
                sousMenuList: [
                    {
                        sousMenuName: "Vue d'ensemble",
                        path: '/VueEnsemble',
                    },
                    {
                        sousMenuName: 'Immobilier',
                        path: '/Immobilier',
                    },
                ],
                numero: 0,
            },
            {
                navName: 'Articles',
                path: '/Articles',
            },
            {
                navName: 'Contact',
                path: '/Contact',
            },
            // {
            //   navName: "Plus",
            //   sousMenuList: [
            //     {
            //       sousMenuName: "FAQ",
            //       path: "/VueEnsemble",
            //     },
            //     {
            //       sousMenuName: "Nous contacter",
            //       path: "/Contact",
            //     },
            //   ],
            //   numero: 1,
            // },
        ]
    );

    const [sousMenuBoll, setSousMenuBoll] = useState([false]);

    function handleClickToggle(classN: string): void {
        const menu_mobile = document.getElementsByClassName(
            classN
        ) as HTMLCollectionOf<HTMLElement>;
        const icon = document.querySelector('.toggleclass');
        icon?.classList.toggle('activeIcon');
        if (menu_mobile) {
            for (const values of menu_mobile) {
                if (toggleBol == true) {
                    values.style.display = 'none';
                    values.style.animationName = 'disparitionMenuMobile';
                    setToggleBol(false);
                } else {
                    values.style.display = 'flex';
                    values.style.animationName = 'apparitionMenuMobile';
                    setToggleBol(true);
                }
            }
        }
    }

    function resetmenu() {
        const menu_mobile = document.getElementById('nav_items_containerID');
        const sousMenu = document.getElementsByClassName(
            'sous_menu'
        ) as HTMLCollectionOf<HTMLElement>;

        if (Page.getXmax() > 800) {
            // values.style.animationName="";
            const icon = document.querySelector('.toggleclass');
            if (menu_mobile) menu_mobile.style.display = 'flex';
            for (const values of sousMenu) {
                values.style.display = 'none';
                values.style.animationName = '';
            }
            setSousMenuBoll([false]);
            setToggleBol(false);
            icon?.classList.remove('activeIcon');
        } else {
            if (toggleBol == false) {
                if (menu_mobile) menu_mobile.style.display = 'none';
            } else {
                if (menu_mobile) menu_mobile.style.display = 'flex';
            }
        }
    }
    function sousMenuClick(id: string, numero: number) {
        if (pageData.widthScreen < 800) {
            const sousMenu = document.getElementById(id);
            const transitionVariable = sousMenuBoll;
            const numeroBis = numero + 1;
            document
                .querySelector(
                    `.sous_menu_container:nth-of-type(${numeroBis}) div:first-child .iconSousMenu`
                )
                ?.classList.toggle('activeIcon');
            // let li =sousMenu.querySelectorAll('li');
            if (sousMenuBoll[numero] == false) {
                if (sousMenu) {
                    sousMenu.style.animationName = 'apparitionMenusousClick';
                    sousMenu.style.display = 'flex';
                    sousMenu.style.height = '60px';
                }

                transitionVariable[numero] = true;
                setSousMenuBoll(transitionVariable);
            } else {
                if (sousMenu) {
                    sousMenu.style.animationName = '';
                    sousMenu.style.height = '0px';
                    sousMenu.style.display = 'none';
                }

                transitionVariable[numero] = false;
                setSousMenuBoll(transitionVariable);
            }
        }
    }
    function sousMenuHover(bol: boolean, id: string) {
        if (pageData.widthScreen > 799) {
            const sousMenu = document.getElementById(id);
            if (bol == true) {
                if (sousMenu) {
                    sousMenu.style.display = 'flex';
                    sousMenu.style.animationName = 'appatitionSousMenu';
                }
            } else {
                if (sousMenu) {
                    sousMenu.style.display = 'none';
                    sousMenu.style.animationName = '';
                }
            }
        }
    }

    function ColorActive() {
        const li = document.getElementsByClassName('sous_menu_container');
        for (const value of li) {
            const p = value.querySelector('p');
            if (p) {
                if (pageData.widthScreen > 800 && pageData.scrollPage > 1) {
                    p.style.color = 'white';
                }
                if (pageData.widthScreen <= 800) {
                    p.style.color = 'rgb(30,30,30)';
                }
                if (
                    pageData.widthScreen > 800 &&
                    pageData.scrollPage <= 1 &&
                    bol_transparent === true
                ) {
                    p.style.color = 'rgb(30,30,30)';
                }
                if (
                    pageData.widthScreen > 800 &&
                    pageData.scrollPage <= 1 &&
                    bol_transparent === false
                ) {
                    p.style.color = 'white';
                }
            }
            if (bol_nosfonds == true && id_color) {
                const p = document.getElementById(id_color);
                if (p) p.style.color = '#ae952e';
            }
        }
    }

    function transparantMenu() {
        const menu = document.getElementsByClassName(
            'navigation'
        ) as HTMLCollectionOf<HTMLElement>;
        const menuContainer = document.getElementsByClassName(
            'nav_items_container'
        ) as HTMLCollectionOf<HTMLElement>;

        for (const value_2 of menuContainer) {
            // let value_a=value_2.querySelectorAll('a');
            const value_a = value_2.children as HTMLCollectionOf<HTMLElement>;
            if (bol_transparent == true) {
                for (const value of menu) {
                    if (Page.getScrollPage() <= 1) {
                        value.style.backgroundColor = 'transparent';
                        value.style.border = 'none';
                        if (pageData.widthScreen > 800) {
                            for (const value_i of value_a) {
                                value_i.style.color = 'rgb(30,30,30)';
                            }
                        }
                    } else {
                        value.style.backgroundColor = 'rgba(33, 102, 142,0.99)';
                        value.style.borderBottom = 'solid 3px #ae952e';
                        if (pageData.widthScreen > 800) {
                            for (const value_i of value_a) {
                                value_i.style.color = 'white';
                            }
                        }
                    }
                    if (pageData.widthScreen <= 800) {
                        for (const value_i of value_a) {
                            value_i.style.color = 'rgb(30,30,30)';
                        }
                    }
                }
            } else {
                if (pageData.widthScreen > 800) {
                    for (const value_i of value_a) {
                        value_i.style.color = 'white';
                    }
                }
            }
        }
    }
    function disparitionMenu() {
        const menu_mobile = document.getElementsByClassName(
            'navigation'
        ) as HTMLCollectionOf<HTMLElement>;
        for (const value of menu_mobile) {
            if (
                Page.getScrollPage() > scrollPageAvant &&
                Page.getScrollPage() > Page.getYmax()
            ) {
                value.style.display = 'none';
            } else {
                value.style.display = 'flex';
            }
        }
        setScrollPageAvant(Page.getScrollPage());
    }

    useEffect(() => {
        resetmenu();
        ColorActive();
        transparantMenu();
    }, [pageData.widthScreen]);
    useEffect(() => {
        disparitionMenu();
        transparantMenu();
        ColorActive();
    }, [pageData.scrollPage]);

    return (
        <div className="navigation">
            <NavLink to="/" id="logo">
                <img className="logo" src={logo} alt="logo Newparadigms" />
            </NavLink>

            <p
                className="toggleclass pointer"
                onClick={() => handleClickToggle('nav_items_container')}
            >
                <span></span>
                <span></span>
                <span></span>
            </p>
            {/* {!isEmpty(userData) && (
        <div className="logout_container">
          <FontAwesomeIcon
            className="pointer fa"
            icon={faRightFromBracket}
            onClick={() => logout()}
          />
        </div>
      )} */}

            <ul className="nav_items_container" id="nav_items_containerID">
                {!isEmpty(navigationList) &&
                    navigationList.map((value, key) =>
                        value.path ? (
                            value.path === '/' ? (
                                <Link
                                    key={key}
                                    to={value.path}
                                    className={
                                        window.location.pathname === '/'
                                            ? 'Nav_item active'
                                            : 'Nav_item'
                                    }
                                >
                                    <li>{value.navName}</li>
                                </Link>
                            ) : (
                                <NavLink
                                    key={key}
                                    to={value.path}
                                    className="Nav_item"
                                >
                                    <li>{value.navName}</li>
                                </NavLink>
                            )
                        ) : (
                            !!(value.numero != undefined) && (
                                <li
                                    key={key}
                                    className="sous_menu_container"
                                    onMouseEnter={() =>
                                        sousMenuHover(true, value.navName)
                                    }
                                    onMouseLeave={() =>
                                        sousMenuHover(false, value.navName)
                                    }
                                >
                                    <div
                                        className="pointer"
                                        onClick={() => {
                                            value.numero != undefined &&
                                                sousMenuClick(
                                                    value.navName,
                                                    value.numero
                                                );
                                        }}
                                    >
                                        <p id={`p${value.numero.toString()}ID`}>
                                            {value.navName}
                                        </p>
                                        {!!(pageData.widthScreen < 800) && (
                                            <div className="iconSousMenu pointer">
                                                <span></span>
                                                <span></span>
                                            </div>
                                        )}
                                    </div>

                                    <ul
                                        className="sous_menu"
                                        id={value.navName}
                                    >
                                        {value.sousMenuList &&
                                            value.sousMenuList.map(
                                                (value_sous) => (
                                                    <NavLink
                                                        key={
                                                            value_sous.sousMenuName
                                                        }
                                                        to={value_sous.path}
                                                        className="Nav_item nav_item_sous"
                                                    >
                                                        <li
                                                            id={`p${value.numero?.toString()}ID`}
                                                        >
                                                            {
                                                                value_sous.sousMenuName
                                                            }
                                                        </li>
                                                    </NavLink>
                                                )
                                            )}
                                    </ul>
                                </li>
                            )
                        )
                    )}
                {loadingUser ? (
                    <a className="!w-[50px] !rounded-md !bg-secondBackgroundColor">
                        <li>
                            <Loading size={4} />
                        </li>
                    </a>
                ) : (
                    <>
                        {!!(userData && userData.admin) && (
                            <NavLink
                                to={'/DashBoardAdmin/BackOffice'}
                                className="Nav_item text-texteColor"
                            >
                                <li>Admin</li>
                            </NavLink>
                        )}

                        {!isEmpty(userData) ? (
                            <React.Fragment>
                                {!!(
                                    userData
                                    // import.meta.env.VITE_API_URL === "http://localhost:5002/api/"
                                ) && (
                                    <NavLink
                                        to={'/DashBoardClient/tableau-de-bord'}
                                        className="Nav_item"
                                    >
                                        <li>Profil</li>
                                    </NavLink>
                                )}

                                <a>
                                    <li onClick={() => logout()}>
                                        Se déconnecter
                                    </li>
                                </a>
                            </React.Fragment>
                        ) : (
                            <NavLink
                                to={'/SeConnecter'}
                                className="Nav_item !rounded-md"
                            >
                                <li>Se connecter</li>
                            </NavLink>
                        )}
                    </>
                )}
            </ul>
        </div>
    );
};

export default Navigation;

// onMouseEnter={ () => sousMenuHover(true)} onMouseLeave={ () => sousMenuHover(false)}
