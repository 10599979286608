import React, { useContext } from "react";
import { RootBlackOfficeState } from "../../../Context/AppContext";

const ApplyFees = () => {
  const rootState = useContext(RootBlackOfficeState);

  return (
    <React.Fragment>
      {rootState ? (
        <div
          className={`rounded-xl top-0 max-w-fit right-2 mx-auto cursor-pointer ${
            rootState.applyFeesBool ? "bg-green-500" : "bg-red-500"
          }`}
          onClick={() => {
            rootState.setApplyFeesBool(!rootState.applyFeesBool);
          }}
        >
          <p className="p-3 font-titleFontFamily">
            {rootState.applyFeesBool ? (
              <React.Fragment>Frais appliqués</React.Fragment>
            ) : (
              <React.Fragment>Frais non appliqués</React.Fragment>
            )}
          </p>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ApplyFees;
