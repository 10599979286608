import {
    useGetAllLatestDocumentsInvestAccountByUserIdQuery,
    useGetInvestAccountByIdQuery,
    useGetUserWithAllInfoForCustomerQuery,
    useUpdateInvestAccountMutation,
} from '@api/features/userSlice';
import { PrimaryButton, WhiteButton } from '@components/commun/Buttons';
import DisplayDocumentBeneficialOwner from '@components/commun/DisplayDocuments/DisplayDocumentBeneficialOwner';
import DisplayDocumentInvestAccount from '@components/commun/DisplayDocuments/DisplayDocumentInvestAccount';
import DisplayDocumentMoralAccount from '@components/commun/DisplayDocuments/DisplayDocumentMoralAccount';
import AdressCompletion from '@components/commun/formComponent/AdressCompletion';
import FieldArray from '@components/commun/formComponent/FieldArray';
import FormTemplate from '@components/commun/formComponent/FormTemplate';
import HeadlessRadioComponent from '@components/commun/formComponent/HeadlessRadioComponent';
import InputComponent from '@components/commun/formComponent/InputComponent';
import { onSumbitUpdateInvestAccount } from '@components/commun/formComponent/InvestAccount/OnSubmitHelper';
import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import RadioComponent from '@components/commun/formComponent/RadioComponent';
import SelectComponent from '@components/commun/formComponent/SelectComponent';
import UploaderSingle from '@components/commun/formComponent/UploaderSingle';
import Loading from '@components/commun/Loading';
import { useNotificationContext } from '@components/Context/notification-context';
import { TitleDocumentUser } from '@components/DashboardUser/Profile/FormInvest/DocumentsIdentity1';
import { statusCompany } from '@data/investAccountFormData';
import { XIcon } from '@heroicons/react/outline';
import {
    DocumentMoralAccountType,
    DocumentUserName,
    FormUpdateInvestAccountDto,
    UpdateUserInvestAccountDto,
    UserWithAllInfoDto,
} from '@interfaces/user';
import { useEffect, useMemo, useState } from 'react';
import {
    UseFormHandleSubmit,
    UseFormReturn,
    UseFormWatch,
    useForm,
} from 'react-hook-form';
import countryList from 'react-select-country-list';

interface Props {
    user: UserWithAllInfoDto;
    useSubmitBtn: boolean;
    investAccountId?: number;
    beforeSubmitLogic?: (props: {
        isDirty: boolean;
        data: FormUpdateInvestAccountDto;
        watch: UseFormWatch<FormUpdateInvestAccountDto>;
    }) => Promise<boolean>;
    onSubmitSuccess?: () => void;
    children?: (props: {
        disabled: boolean;
        loading: boolean;
        handleSubmit: UseFormHandleSubmit<
            FormUpdateInvestAccountDto,
            undefined
        >;
        onSubmit: (data: FormUpdateInvestAccountDto) => Promise<void>;
        user: UserWithAllInfoDto;
        watch: UseFormWatch<FormUpdateInvestAccountDto>;
        formState: UseFormReturn<FormUpdateInvestAccountDto>;
    }) => React.ReactNode;
    userInfoInvestAccount?: UpdateUserInvestAccountDto;
}

const UpdateInvestAccountComponent: React.FC<Props> = ({
    user,
    onSubmitSuccess,
    children,
    beforeSubmitLogic,
    useSubmitBtn,
    investAccountId,
    userInfoInvestAccount,
}) => {
    const userId = user.id;

    const {
        data: investAccount,
        isLoading: isLoadingInvestAccount,
        refetch: refetchInvestAccount,
    } = useGetInvestAccountByIdQuery(
        {
            userId: userId,
            investAccountId: investAccountId || 0,
        },
        {
            skip: userId === undefined || investAccountId === undefined,
        }
    );

    const {
        data: documents,
        isLoading: isLoadingDocuments,
        refetch,
    } = useGetAllLatestDocumentsInvestAccountByUserIdQuery(
        {
            userId: userId,
            investAccountId: investAccountId || 0,
        },
        {
            skip: userId === undefined || investAccountId === undefined,
        }
    );

    const { refetch: refectchAllInvestAccount } =
        useGetUserWithAllInfoForCustomerQuery(user?.id || 0);

    useEffect(() => {
        if (userId && investAccountId) {
            refetchInvestAccount();
            refetch();
        }
    }, [userId, investAccountId, refetchInvestAccount, refetch]);

    const formState = useForm<FormUpdateInvestAccountDto>({});

    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        watch,
        formState: { isValid, dirtyFields, isDirty },
    } = formState;

    const [loading, setLoading] = useState(false);

    const { showError, showSuccess } = useNotificationContext();

    useEffect(() => {
        if (investAccount) {
            reset({
                ...watch(),
                userId: userId,
                id: investAccount.id,
                iban: investAccount?.iban,
                bic: investAccount?.bic,
                moralAccount:
                    investAccount.physicalOrMoral === 'physical'
                        ? undefined
                        : {
                              ...investAccount.moralAccount,
                              beneficialOwners:
                                  investAccount.moralAccount?.beneficialOwners?.map(
                                      (b) => {
                                          return {
                                              id: b.id,
                                              firstName: b.firstName,
                                              lastName: b.lastName,
                                              nationality: b.nationality,
                                              moralAccountId: b.moralAccountId,
                                              proportionShareholder:
                                                  b.proportionShareholder,
                                              name: documents?.beneficialOwner?.find(
                                                  (d) =>
                                                      d.beneficialOwnerId ===
                                                      b.id
                                              )?.name,
                                          };
                                      }
                                  ),
                          },
                userInfoInvestAccount: {
                    ...watch('userInfoInvestAccount'),
                    ...userInfoInvestAccount,
                },
            });
        }
    }, [investAccount, documents, reset, userId]);

    const [editInvestAccount] = useUpdateInvestAccountMutation();

    const optionsCountries = useMemo(() => countryList().getData(), []);

    const onSubmit = async (data: FormUpdateInvestAccountDto) => {
        if (!isDirty) {
            if (onSubmitSuccess) {
                onSubmitSuccess();
            }
            return;
        }
        setLoading((curr) => !curr);

        if (beforeSubmitLogic) {
            const shouldContinue = await beforeSubmitLogic({
                isDirty,
                watch,
                data,
            });

            if (!shouldContinue) {
                return;
            }
        }

        // update doc addres
        await onSumbitUpdateInvestAccount({
            data,
            showError,
            showSuccess,
            userId: userId,
            editInvestAccount,
            refetchDocumentInvestAccount: refetch,
            refetchInvestAccount,
            isDirty,
            investAccount,
            onSuccess: () => {
                refectchAllInvestAccount();
                if (onSubmitSuccess) {
                    onSubmitSuccess();
                }
            },
        });

        setLoading((curr) => !curr);
    };

    const documentKbisDb = documents?.moralAccount?.find(
        (m) => m.type === DocumentMoralAccountType.KBIS
    );
    const documentStatusDb = documents?.moralAccount?.find(
        (m) => m.type === DocumentMoralAccountType.STATUS
    );
    const documentDeclarationBeneficial_OwnerDb = documents?.moralAccount?.find(
        (m) => m.type === DocumentMoralAccountType.DECLARATION_BENEFICIAL_OWNER
    );

    const watchedValues = {
        iban: watch('iban'),
        bic: watch('bic'),
        documentRib: watch('documentRib'),
        moralAccount: watch('moralAccount'),
        documentStatus: watch('documentStatus'),
        documentKbis: watch('documentKbis'),
        documentDeclarationBeneficial_Owner: watch(
            'documentDeclarationBeneficial_Owner'
        ),
        beneficialOwner: watch('moralAccount.beneficialOwners'),
    };

    const isValidForm = useMemo(() => {
        if (
            (dirtyFields.iban || dirtyFields.bic) &&
            !(watchedValues.documentRib?.length > 0)
        ) {
            return false;
        }
        if (investAccount?.physicalOrMoral === 'moral') {
            if (
                (dirtyFields.moralAccount?.companyName ||
                    dirtyFields.moralAccount?.address ||
                    dirtyFields.moralAccount?.addressComplement ||
                    dirtyFields.moralAccount?.investorCategorization ||
                    dirtyFields.moralAccount?.legalStatus ||
                    dirtyFields.moralAccount?.registrationNumber) &&
                !(watchedValues.documentKbis?.length > 0)
            ) {
                return false;
            }
            // if (!dirtyFields.documentStatus) {
            //   return false;
            // }

            if (watchedValues.beneficialOwner?.length > 0) {
                for (
                    let i = 0;
                    i < watchedValues.beneficialOwner?.length;
                    i++
                ) {
                    const dirty =
                        dirtyFields.moralAccount?.beneficialOwners?.[i];
                    if (
                        (dirty?.lastName ||
                            dirty?.firstName ||
                            dirty?.nationality ||
                            dirty?.proportionShareholder) &&
                        (!(
                            watch(`moralAccount.beneficialOwners.${i}.file`)
                                ?.length > 0
                        ) ||
                            !(
                                watchedValues
                                    .documentDeclarationBeneficial_Owner
                                    ?.length > 0
                            ))
                    ) {
                        return false;
                    }
                }
            }
            if (
                watchedValues.beneficialOwner?.length !==
                    investAccount.moralAccount?.beneficialOwners?.length &&
                !(watch('documentDeclarationBeneficial_Owner')?.length > 0)
            ) {
                return false;
            }
        }

        return true;
    }, [
        dirtyFields.bic,
        dirtyFields.iban,
        dirtyFields.moralAccount?.address,
        dirtyFields.moralAccount?.addressComplement,
        dirtyFields.moralAccount?.beneficialOwners,
        dirtyFields.moralAccount?.companyName,
        dirtyFields.moralAccount?.investorCategorization,
        dirtyFields.moralAccount?.legalStatus,
        dirtyFields.moralAccount?.registrationNumber,
        investAccount?.moralAccount?.beneficialOwners?.length,
        investAccount?.physicalOrMoral,
        watch,
        watchedValues.documentDeclarationBeneficial_Owner,
        watchedValues.documentKbis,
        watchedValues.documentRib,
        watchedValues.beneficialOwner,
    ]);

    const isBeneficialOwnerDirty = () => {
        if (watch('moralAccount.beneficialOwners')?.length > 0) {
            for (
                let i = 0;
                i < watch('moralAccount.beneficialOwners')?.length;
                i++
            ) {
                const dirty = dirtyFields.moralAccount?.beneficialOwners?.[i];
                if (
                    (dirty?.lastName ||
                        dirty?.firstName ||
                        dirty?.nationality ||
                        dirty?.proportionShareholder) &&
                    (!(
                        watch(`moralAccount.beneficialOwners.${i}.file`)
                            ?.length > 0
                    ) ||
                        !(
                            watch('documentDeclarationBeneficial_Owner')
                                ?.length > 0
                        ))
                ) {
                    return true;
                }
            }
        }
        if (
            watch('moralAccount.beneficialOwners')?.length !==
                investAccount?.moralAccount?.beneficialOwners?.length &&
            !(watch('documentDeclarationBeneficial_Owner')?.length > 0)
        ) {
            return true;
        }
        return false;
    };

    const [isSizeFileGood, setIsSizeFileGood] = useState(true);

    const disabled = useMemo(() => {
        console.log('isValid', isValid);
        console.log('isValidForm', isValidForm);
        console.log('isSizeFileGood', isSizeFileGood);
        return !isValid || !isValidForm || !isSizeFileGood;
    }, [isValid, isValidForm, isSizeFileGood]);

    return (
        <FormTemplate
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            isValid={!disabled}
            loading={loading}
            useSubmitBtn={useSubmitBtn}
            className="form_investisseur_container"
        >
            {/* <ValidationCode>
        <p className="text-lg text-titleColor">
          Afin de valider la modification vous devez rentrer ci-dessous le code
          envoyé par mail
        </p>
      </ValidationCode> */}
            <div className="w-full md:w-11/12 md:mx-auto lg:w-9/12">
                {isLoadingInvestAccount || isLoadingDocuments ? (
                    <Loading />
                ) : (
                    <div
                        className={`w-full mx-auto flex flex-col
       
    `}
                    >
                        <div className="grid md:grid-cols-2 mt-4 gap-x-2">
                            <InputComponent value={'iban'} register={register}>
                                <label htmlFor="iban">
                                    IBAN<span className=" text-red-500">*</span>
                                </label>
                            </InputComponent>
                            <InputComponent value={'bic'} register={register}>
                                <label htmlFor="iban">
                                    BIC<span className=" text-red-500">*</span>
                                </label>
                            </InputComponent>
                        </div>

                        <>
                            <UploaderSingle
                                setSizeGood={setIsSizeFileGood}
                                register={register}
                                value={'documentRib'}
                                watch={watch}
                                setValue={setValue}
                                button={false}
                                documentAlreadyUploaded={
                                    !!documents?.investAccount
                                }
                                componentEnd={() => {
                                    return (
                                        <p className="italic commentaireInput text-justify text-[0.8rem]">
                                            <span className="font-bold">
                                                Documents acceptés:
                                            </span>{' '}
                                            Documents recto et verso.
                                        </p>
                                    );
                                }}
                            >
                                <>
                                    <h3 className="text-2xl mb-5">
                                        Ajoutez un RIB{' '}
                                        <span className=" text-red-500">*</span>
                                    </h3>
                                    {investAccount?.iban !== undefined &&
                                    (dirtyFields.iban || dirtyFields.bic) &&
                                    !(watch('documentRib')?.length > 0) ? (
                                        <p className=" text-red-700">
                                            Vous avez modifié votre IBAN, vous
                                            devez ajouter un RIB.
                                        </p>
                                    ) : null}
                                </>
                            </UploaderSingle>
                            {documents?.investAccount && user ? (
                                <DisplayDocumentInvestAccount
                                    document={documents?.investAccount}
                                    userId={user.id}
                                    refetch={refetch}
                                />
                            ) : null}
                        </>
                        {investAccount &&
                        investAccount.physicalOrMoral === 'moral' ? (
                            <>
                                <InputComponent
                                    value={'moralAccount.companyName'}
                                    register={register}
                                >
                                    <LabelComponentForm>
                                        Raison sociale
                                        <span className=" text-red-500">*</span>
                                    </LabelComponentForm>
                                </InputComponent>
                                <div className="grid md:gap-x-2 md:grid-cols-2">
                                    <InputComponent
                                        value={
                                            'moralAccount.registrationNumber'
                                        }
                                        register={register}
                                    >
                                        <LabelComponentForm>
                                            N° d'immatriculation
                                            <span className=" text-red-500">
                                                *
                                            </span>
                                        </LabelComponentForm>
                                    </InputComponent>
                                    <div className="">
                                        <SelectComponent
                                            control={control}
                                            setValue={setValue}
                                            watch={watch}
                                            value={'moralAccount.legalStatus'}
                                            register={register}
                                            optionValues={statusCompany.map(
                                                (s) => {
                                                    return {
                                                        value: s,
                                                        label: s,
                                                    };
                                                }
                                            )}
                                        >
                                            <LabelComponentForm>
                                                Statut juridique
                                                <span className=" text-red-500">
                                                    *
                                                </span>
                                            </LabelComponentForm>
                                        </SelectComponent>
                                    </div>
                                </div>
                                {watch('moralAccount.legalStatus') ===
                                'Autre (à préciser)' ? (
                                    <InputComponent
                                        register={register}
                                        value={'moralAccount.legalStatusOther'}
                                    >
                                        <LabelComponentForm>
                                            Précisez le statut juridique
                                            <span className=" text-red-500">
                                                *
                                            </span>
                                        </LabelComponentForm>
                                    </InputComponent>
                                ) : null}
                                <div className="grid grid-cols-2 md:grid-cols-[auto,auto] items-center mt-4">
                                    <RadioComponent
                                        register={register}
                                        watch={watch}
                                        name={
                                            'moralAccount.investorCategorization'
                                        }
                                        className={{
                                            container:
                                                'grid justify-center col-span-2 md:col-span-1 center md:grid-cols-2 items-center w-full',
                                            div: '',
                                        }}
                                        values={[
                                            {
                                                value: 'pro',
                                                label: 'Investisseur non-professionnel',
                                            },
                                            {
                                                value: 'noPro',
                                                label: 'Investisseur professionnel',
                                            },
                                        ]}
                                    >
                                        <LabelComponentForm className="col-span-2 md:col-span-1">
                                            Catégorisation de l'investisseur{' '}
                                            <span className="required">*</span>
                                        </LabelComponentForm>
                                    </RadioComponent>
                                </div>
                                <AdressCompletion
                                    control={control}
                                    register={register}
                                    value="moralAccount.address"
                                >
                                    <LabelComponentForm>
                                        Adresse{' '}
                                        <span className="required">*</span>
                                    </LabelComponentForm>
                                </AdressCompletion>
                                <InputComponent
                                    value={'moralAccount.addressComplement'}
                                    register={register}
                                    required={false}
                                >
                                    <LabelComponentForm>
                                        Complément d'adresse
                                    </LabelComponentForm>
                                </InputComponent>
                                <h2 className="mt-4 font-mainFontFamily text-2xl text-mainColor font-semibold">
                                    Informations sur les bénéficiaires effectifs
                                </h2>
                                <FieldArray
                                    control={control}
                                    name="moralAccount.beneficialOwners"
                                >
                                    {({ fields, append, remove }) => (
                                        <div className="w-full flex flex-col gap-2">
                                            {fields.map((field, index) => (
                                                <div
                                                    key={field.id}
                                                    className="w-full flex-col "
                                                >
                                                    <h3 className=" text-secondColor font-mainFontFamily font-semibold text-xl text-start">
                                                        Bénéficiaire effectif{' '}
                                                        {index + 1}
                                                    </h3>
                                                    <div className="grid gap-2 grid-cols-2">
                                                        <InputComponent
                                                            register={register}
                                                            value={`moralAccount.beneficialOwners.${index}.lastName`}
                                                        >
                                                            <LabelComponentForm>
                                                                Nom{' '}
                                                                <span className=" text-red-500">
                                                                    *
                                                                </span>
                                                            </LabelComponentForm>
                                                        </InputComponent>
                                                        <InputComponent
                                                            register={register}
                                                            value={`moralAccount.beneficialOwners.${index}.firstName`}
                                                        >
                                                            <LabelComponentForm>
                                                                Prénom{' '}
                                                                <span className=" text-red-500">
                                                                    *
                                                                </span>
                                                            </LabelComponentForm>
                                                        </InputComponent>
                                                        <div>
                                                            <SelectComponent
                                                                control={
                                                                    control
                                                                }
                                                                optionValues={
                                                                    optionsCountries
                                                                }
                                                                register={
                                                                    register
                                                                }
                                                                value={`moralAccount.beneficialOwners.${index}.nationality`}
                                                            >
                                                                <LabelComponentForm>
                                                                    Nationalité{' '}
                                                                    <span className=" text-red-500">
                                                                        *
                                                                    </span>
                                                                </LabelComponentForm>
                                                            </SelectComponent>
                                                        </div>
                                                        <InputComponent
                                                            register={register}
                                                            value={`moralAccount.beneficialOwners.${index}.proportionShareholder`}
                                                            type="number"
                                                        >
                                                            <LabelComponentForm>
                                                                Pourcentage
                                                                détenu{' '}
                                                                <span className=" text-red-500">
                                                                    *
                                                                </span>
                                                            </LabelComponentForm>
                                                        </InputComponent>
                                                    </div>
                                                    <div className="">
                                                        <HeadlessRadioComponent
                                                            register={register}
                                                            name={`moralAccount.beneficialOwners.${index}.name`}
                                                            watch={watch}
                                                            className="mx-auto mt-2 mb-4 max-w-sm"
                                                            control={control}
                                                            values={[
                                                                {
                                                                    label: "Carte Nationale d'Identité",
                                                                    value: DocumentUserName.IDENTITY_CARD,
                                                                },
                                                                {
                                                                    label: 'Passeport',
                                                                    value: DocumentUserName.PASSPORT,
                                                                },
                                                            ]}
                                                        >
                                                            <h3 className="">
                                                                Choisisser une
                                                                pièce d'identité{' '}
                                                                <span className="required">
                                                                    *
                                                                </span>
                                                            </h3>
                                                        </HeadlessRadioComponent>
                                                        {watch(
                                                            `moralAccount.beneficialOwners.${index}.name`
                                                        ) ? (
                                                            <>
                                                                <UploaderSingle
                                                                    documentAlreadyUploaded={
                                                                        !!documents?.beneficialOwner?.find(
                                                                            (
                                                                                b
                                                                            ) =>
                                                                                b.beneficialOwnerId ===
                                                                                watch(
                                                                                    `moralAccount.beneficialOwners.${index}.id`
                                                                                )
                                                                        )
                                                                    }
                                                                    setSizeGood={
                                                                        setIsSizeFileGood
                                                                    }
                                                                    register={
                                                                        register
                                                                    }
                                                                    value={`moralAccount.beneficialOwners.${index}.file`}
                                                                    watch={
                                                                        watch
                                                                    }
                                                                    setValue={
                                                                        setValue
                                                                    }
                                                                    button={
                                                                        false
                                                                    }
                                                                    componentEnd={() => {
                                                                        return (
                                                                            <p className="italic commentaireInput text-justify text-[0.8rem]">
                                                                                <span className="font-bold">
                                                                                    Documents
                                                                                    acceptés:
                                                                                </span>{' '}
                                                                                Documents
                                                                                recto
                                                                                et
                                                                                verso.
                                                                            </p>
                                                                        );
                                                                    }}
                                                                >
                                                                    <h3 className="text-2xl mb-5">
                                                                        {TitleDocumentUser(
                                                                            watch(
                                                                                `moralAccount.beneficialOwners.${index}.name`
                                                                            )
                                                                        )}{' '}
                                                                        pour{' '}
                                                                        {watch(
                                                                            `moralAccount.beneficialOwners.${index}.lastName`
                                                                        )}{' '}
                                                                        {watch(
                                                                            `moralAccount.beneficialOwners.${index}.firstName`
                                                                        )}
                                                                        <span className="required">
                                                                            *
                                                                        </span>
                                                                    </h3>
                                                                    {((investAccount?.moralAccount &&
                                                                        watch(
                                                                            `moralAccount.beneficialOwners.${index}.id`
                                                                        ) &&
                                                                        watch(
                                                                            `moralAccount.beneficialOwners.${index}.lastName`
                                                                        ) !==
                                                                            investAccount?.moralAccount?.beneficialOwners?.find(
                                                                                (
                                                                                    b
                                                                                ) =>
                                                                                    b.id ===
                                                                                    watch(
                                                                                        `moralAccount.beneficialOwners.${index}.id`
                                                                                    )
                                                                            )
                                                                                ?.lastName) ||
                                                                        watch(
                                                                            `moralAccount.beneficialOwners.${index}.firstName`
                                                                        ) !==
                                                                            investAccount?.moralAccount?.beneficialOwners?.find(
                                                                                (
                                                                                    b
                                                                                ) =>
                                                                                    b.id ===
                                                                                    watch(
                                                                                        `moralAccount.beneficialOwners.${index}.id`
                                                                                    )
                                                                            )
                                                                                ?.firstName ||
                                                                        watch(
                                                                            `moralAccount.beneficialOwners.${index}.nationality`
                                                                        ) !==
                                                                            investAccount?.moralAccount?.beneficialOwners?.find(
                                                                                (
                                                                                    b
                                                                                ) =>
                                                                                    b.id ===
                                                                                    watch(
                                                                                        `moralAccount.beneficialOwners.${index}.id`
                                                                                    )
                                                                            )
                                                                                ?.nationality) &&
                                                                    !(
                                                                        watch(
                                                                            `moralAccount.beneficialOwners.${index}.file`
                                                                        )
                                                                            ?.length >
                                                                        0
                                                                    ) ? (
                                                                        <p className=" text-red-700">
                                                                            Vous
                                                                            avez
                                                                            modifié
                                                                            des
                                                                            informations
                                                                            sur
                                                                            le
                                                                            bénéficiaire
                                                                            effectif,
                                                                            vous
                                                                            devez
                                                                            ajouter
                                                                            une
                                                                            carte
                                                                            d'identité
                                                                        </p>
                                                                    ) : null}
                                                                </UploaderSingle>
                                                                {documents?.beneficialOwner?.find(
                                                                    (b) =>
                                                                        b.beneficialOwnerId ===
                                                                        watch(
                                                                            `moralAccount.beneficialOwners.${index}.id`
                                                                        )
                                                                ) !==
                                                                undefined ? (
                                                                    <DisplayDocumentBeneficialOwner
                                                                        document={
                                                                            documents?.beneficialOwner?.find(
                                                                                (
                                                                                    b
                                                                                ) =>
                                                                                    b.beneficialOwnerId ===
                                                                                    watch(
                                                                                        `moralAccount.beneficialOwners.${index}.id`
                                                                                    )
                                                                            )!
                                                                        }
                                                                        userId={
                                                                            user.id
                                                                        }
                                                                        refetch={
                                                                            refetch
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                    </div>

                                                    <div className="w-full flex justify-center items-center gap-2">
                                                        <WhiteButton
                                                            onClick={() =>
                                                                remove(index)
                                                            }
                                                        >
                                                            <XIcon className="w-4 h-4" />
                                                            <p className="ml-3">
                                                                Supprimer ce
                                                                bénéficiaire
                                                            </p>
                                                        </WhiteButton>
                                                    </div>
                                                </div>
                                            ))}
                                            <PrimaryButton
                                                className=" mx-auto mt-3 mb-2"
                                                onClick={() =>
                                                    append({
                                                        // projectId: project.current?.id!,
                                                    } as any)
                                                }
                                            >
                                                Ajouter un bénéficiaire
                                            </PrimaryButton>
                                        </div>
                                    )}
                                </FieldArray>
                                <div className="w-full  mt-4 flex flex-col">
                                    <h2 className=" font-mainFontFamily text-2xl text-mainColor font-semibold">
                                        Documents
                                    </h2>
                                    <UploaderSingle
                                        setSizeGood={setIsSizeFileGood}
                                        register={register}
                                        value={'documentStatus'}
                                        watch={watch}
                                        documentAlreadyUploaded={
                                            !!documentStatusDb
                                        }
                                        setValue={setValue}
                                        button={false}
                                        componentEnd={() => {
                                            return (
                                                <p className="italic commentaireInput text-justify text-[0.8rem]">
                                                    <span className="font-bold">
                                                        Documents acceptés:
                                                    </span>{' '}
                                                    Documents recto et verso.
                                                </p>
                                            );
                                        }}
                                    >
                                        <h3 className="text-2xl mb-5">
                                            Statuts{' '}
                                            <span className=" text-red-500">
                                                *
                                            </span>
                                        </h3>
                                    </UploaderSingle>
                                    {documentStatusDb ? (
                                        <DisplayDocumentMoralAccount
                                            document={documentStatusDb}
                                            userId={user.id}
                                            refetch={refetch}
                                        />
                                    ) : null}

                                    <UploaderSingle
                                        setSizeGood={setIsSizeFileGood}
                                        register={register}
                                        value={'documentKbis'}
                                        documentAlreadyUploaded={
                                            !!documentKbisDb
                                        }
                                        watch={watch}
                                        setValue={setValue}
                                        button={false}
                                        componentEnd={() => {
                                            return (
                                                <p className="italic commentaireInput text-justify text-[0.8rem]">
                                                    <span className="font-bold">
                                                        Documents acceptés:
                                                    </span>{' '}
                                                    Documents recto et verso.
                                                </p>
                                            );
                                        }}
                                    >
                                        <h3 className="text-2xl mb-5">
                                            Kbis{' '}
                                            <span className=" text-red-500">
                                                *
                                            </span>
                                        </h3>
                                        {(dirtyFields.moralAccount
                                            ?.companyName ||
                                            dirtyFields.moralAccount?.address ||
                                            dirtyFields.moralAccount
                                                ?.addressComplement ||
                                            dirtyFields.moralAccount
                                                ?.investorCategorization ||
                                            dirtyFields.moralAccount
                                                ?.legalStatus ||
                                            dirtyFields.moralAccount
                                                ?.registrationNumber) &&
                                        !(watch('documentKbis')?.length > 0) ? (
                                            <p className=" text-red-700">
                                                Vous avez modifié des
                                                informations sur votre
                                                entreprise, vous devez ajouter
                                                un Kbis.
                                            </p>
                                        ) : null}
                                    </UploaderSingle>
                                    {documentKbisDb ? (
                                        <DisplayDocumentMoralAccount
                                            document={documentKbisDb}
                                            userId={user.id}
                                            refetch={refetch}
                                        />
                                    ) : null}

                                    <UploaderSingle
                                        setSizeGood={setIsSizeFileGood}
                                        register={register}
                                        value={
                                            'documentDeclarationBeneficial_Owner'
                                        }
                                        documentAlreadyUploaded={
                                            !!documentDeclarationBeneficial_OwnerDb
                                        }
                                        watch={watch}
                                        setValue={setValue}
                                        button={false}
                                        componentEnd={() => {
                                            return (
                                                <p className="italic commentaireInput text-justify text-[0.8rem]">
                                                    <span className="font-bold">
                                                        Documents acceptés:
                                                    </span>{' '}
                                                    Documents recto et verso.
                                                </p>
                                            );
                                        }}
                                    >
                                        <h3 className="text-2xl mb-5">
                                            Déclaration de bénéficiaire
                                            effectifs{' '}
                                            <span className=" text-red-500">
                                                *
                                            </span>
                                        </h3>
                                        {isBeneficialOwnerDirty() ? (
                                            <p className=" text-red-700">
                                                Vous avez modifié des
                                                informations sur les
                                                bénéficiaires effectifs, vous
                                                devez ajouter la déclaration de
                                                bénéficiaire effectifs.
                                            </p>
                                        ) : null}
                                    </UploaderSingle>
                                    {documentDeclarationBeneficial_OwnerDb ? (
                                        <DisplayDocumentMoralAccount
                                            document={
                                                documentDeclarationBeneficial_OwnerDb
                                            }
                                            userId={user.id}
                                            refetch={refetch}
                                        />
                                    ) : null}
                                </div>
                            </>
                        ) : null}
                    </div>
                )}
            </div>
            {children
                ? children({
                      disabled,
                      watch,
                      formState,
                      loading,
                      user,
                      handleSubmit,
                      onSubmit,
                  })
                : null}
        </FormTemplate>
    );
};

export default UpdateInvestAccountComponent;
