import { ExclamationIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { NumberParam, useQueryParam } from 'use-query-params';
import { InvestAccountDto, StateStatus } from '../../../../types/user';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import { ErrorIcon, SuccessIcon } from '../../../commun/IconsFeedBack';

export default function DisplayInvestAccount({
    i,
    viewCard,
    setViewCard,
    renderActionButton,
    customButton,
}: {
    i: InvestAccountDto;
    viewCard: boolean;
    setViewCard: React.Dispatch<React.SetStateAction<boolean>>;
    renderActionButton?: boolean;
    customButton?: React.ReactNode;
}) {
    const [investAccountIdQuery, setInvestAccountIdQuery] = useQueryParam(
        'investAccountId',
        NumberParam
    );

    const [hover, setHover] = useState(false);

    const navigate = useNavigate();

    return (
        <>
            <h2 className=" font-mainFontFamily text-xl text-start text-mainColor font-semibold">
                {i.physicalOrMoral === 'physical'
                    ? 'Compte physique'
                    : 'Compte morale'}
            </h2>
            {i.physicalOrMoral === 'moral' ? (
                <p className=" font-medium font-mainFontFamily">
                    {i.moralAccount?.companyName}
                </p>
            ) : null}
            <motion.div
                className="absolute right-2 top-2 cursor-pointer"
                onHoverStart={() => setHover(true)}
                onHoverEnd={() => setHover(false)}
            >
                {/* When hover on the icon display a text */}
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{
                            opacity: 1,
                            x: 0,
                        }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.2 }}
                        className="rounded-[50px] py-1 px-3 absolute z-[10]"
                    >
                        <div
                            className={`flex gap-3 items-center left-[-100px] absolute px-3  w-fit ${
                                i.status === StateStatus.validated
                                    ? ' top-[-40px]'
                                    : i.status === StateStatus.unchecked
                                      ? 'top-[-65px]'
                                      : ' top-[-70px]'
                            }`}
                        >
                            {hover ? (
                                <>
                                    {!i.iban ? (
                                        <p className="text-base w-[200px] bg-bgDashboardClient p-2 rounded-md text-gray-600 text-center">
                                            Ce compte n'a pas encore d'iban,
                                            veuillez le modifier
                                        </p>
                                    ) : i.status === StateStatus.validated ? (
                                        <p className="text-base w-[200px] bg-bgDashboardClient p-2 rounded-md text-gray-600 text-center">
                                            Compte validé
                                        </p>
                                    ) : i.status === StateStatus.unchecked &&
                                      i.iban !== undefined ? (
                                        <p className="text-base w-[200px] bg-bgDashboardClient p-2 rounded-md text-gray-600 text-center">
                                            Ce compte est en cours de validation
                                        </p>
                                    ) : (
                                        <p className="text-base w-[200px] bg-bgDashboardClient p-2 rounded-md text-gray-600 text-center">
                                            Ce compte n'a pas été validé,
                                            veuillez le modifier
                                        </p>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </motion.div>
                </AnimatePresence>

                {i.status === StateStatus.validated ? (
                    <SuccessIcon />
                ) : i.status === StateStatus.unchecked ? (
                    <ExclamationIcon
                        className="h-6 w-6 text-orange-400"
                        aria-hidden="true"
                    />
                ) : (
                    <ErrorIcon />
                )}
            </motion.div>
            <p>
                <span className=" font-semibold uppercase">Iban:</span>{' '}
                {i?.iban}
            </p>
            {i.status === StateStatus.unValidated ? (
                <p className="mt-2 text-orange-500">
                    Ce compte n'a pas été validé, veuillez le modifier
                </p>
            ) : null}
            {renderActionButton === undefined || renderActionButton === true ? (
                <>
                    {i.physicalOrMoral === 'moral' ? (
                        <div className=" w-full justify-center gap-3 items-end h-full flex mt-2 mb-0">
                            <WhiteButton
                                onClick={() => {
                                    setInvestAccountIdQuery(i.id);
                                    setViewCard(true);
                                }}
                            >
                                Détails
                            </WhiteButton>
                            <PrimaryButton
                                onClick={() => {
                                    navigate(
                                        `/DashBoardClient/invest-account?pageInvestAccount=edit-invest-account&investAccountId=${i.id}`
                                    );
                                }}
                            >
                                Modifier
                            </PrimaryButton>
                        </div>
                    ) : (
                        <div className=" w-full h-full justify-center gap-3 items-end flex mt-2 mb-0">
                            <PrimaryButton
                                onClick={() => {
                                    navigate(
                                        `/DashBoardClient/invest-account?pageInvestAccount=edit-invest-account&investAccountId=${i.id}`
                                    );
                                }}
                            >
                                Modifier
                            </PrimaryButton>
                        </div>
                    )}
                </>
            ) : customButton ? (
                <div className=" w-full h-full justify-center gap-3 items-end flex mt-2 mb-0">
                    {customButton}
                </div>
            ) : null}
        </>
    );
}
