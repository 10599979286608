import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import {
    FormUploadSubscriptionDto,
    SubscriptionUserFondsDto,
} from '../../types/SubscriptionUserFondsDto';
import {
    AllDocumentInvestAccount,
    AllLatestDocumentInvestAccount,
    CreateDocumentBeneficialOwnerDto,
    CreateDocumentInvestAccountDto,
    CreateDocumentMoralAccountDto,
    CreateDocumentUserDto,
    CreateDocumentUserValidationDto,
    CreateInvestAccountAndUpdateUserInfoDto,
    CreateOrUpdateValidationInvestAccountDto,
    CreateStatusUserDto,
    CreateValidationUserDto,
    DocumentBeneficialOwnerDto,
    DocumentInvestAccountDto,
    DocumentMoralAccountDto,
    DocumentUserDto,
    GetUserWithSharesByDateLimitRequest,
    InvestAccountDto,
    MetricKnowledgeUser,
    UpdateDocumentUserValidationDto,
    UpdateInvestAccountDto,
    UpdateStatusUserDto,
    UpdateUserByAdminDto,
    UpdateUserInvestAccountDto,
    UpdateUserKnowlegdeDto,
    UpdateUserWithAllInfoDto,
    UserDto,
    UserSearchDto,
    UserStatusType,
    UserWithAllInfoDto,
    UserWithAllInfoForAdminDto,
    ViewAllUsersDto,
    ViewUserWithSharesDto,
} from '../../types/user';
import { baseQueryHandleError } from '../index.rtk';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export interface FormCreateDocumentUserRequest extends CreateDocumentUserDto {
    file: File;
}

export interface FormCreateDocumentInvestAccountRequest
    extends CreateDocumentInvestAccountDto {
    file: File;
    userId: number;
}

export interface FormCreateDocumentMoralAccountRequest
    extends CreateDocumentMoralAccountDto {
    file: File;
    userId: number;
}

export interface FormCreateDocumentBeneficialOwnerRequest
    extends CreateDocumentBeneficialOwnerDto {
    file: File;
    userId: number;
}

export interface GetUsersRequest {
    search: string;
    status: string | UserStatusType;
    limit: number;
    offset: number;
    compteInvestToValidate?: boolean;
    documentToValidate?: boolean;
    subscriptionToValidate?: boolean;
}

export interface DeleteValidationDocument {
    userId: number;

    validationId: number;
}

export interface SubscriptionContractForSigningDto {
    userId: number;
    subscriptionUserFondsId: number;
    file: string;
    name: string;
    email: string;
    phone: string;
}

export interface isWiseedClientRequest {
    userId: number;
}

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: [
        'User',
        'Document',
        'InvestAccount',
        'ValidationInvestAccount',
        'Subscription',
        'UserKnowledge',
    ],
    endpoints: (builder) => ({
        getUsers: builder.query<UserDto[], void>({
            query: () => `users`,
        }),
        getUsersSearch: builder.query<UserSearchDto[], string>({
            query: (search) => ({
                url: `users/search`,
                method: 'GET',
                params: {
                    search: search,
                },
            }),
        }),
        getUsersRequest: builder.query<ViewAllUsersDto, GetUsersRequest>({
            query: (payload) => ({
                url: `users/request`,
                method: 'GET',
                params: payload,
            }),
        }),
        getUserById: builder.query<UserDto, number>({
            query: (id) => `users/getById/${id}`,
        }),
        getUserWithAllInfo: builder.query<UserWithAllInfoForAdminDto, number>({
            query: (id) => `users/allInfo/${id}`,
            providesTags: ['User'],
            transformResponse: (response: UserWithAllInfoForAdminDto) => {
                return {
                    ...response,
                    investAccounts: response?.investAccounts?.map(
                        (investAccount) => {
                            return {
                                ...investAccount,
                                iban: investAccount.iban
                                    ? investAccount.iban.replace(
                                          /(.{4})/g,
                                          '$1 '
                                      )
                                    : undefined,
                            };
                        }
                    ),
                };
            },
        }),
        getUserWithAllInfoForCustomer: builder.query<
            UserWithAllInfoDto,
            number
        >({
            query: (id) => `users/allInfo/customer/${id}`,
            providesTags: ['User', 'UserKnowledge'],
            transformResponse: (response: UserWithAllInfoDto) => {
                return {
                    ...response,
                    investAccounts: response?.investAccounts?.map(
                        (investAccount) => {
                            return {
                                ...investAccount,
                                iban: investAccount.iban
                                    ? investAccount.iban.replace(
                                          /(.{4})/g,
                                          '$1 '
                                      )
                                    : undefined,
                            };
                        }
                    ),
                };
            },
        }),

        getUsersWithSharesByDateLimit: builder.query<
            ViewUserWithSharesDto,
            GetUserWithSharesByDateLimitRequest
        >({
            query: (payload) => ({
                url: `users/dateLimit`,
                method: 'GET',
                params: payload,
            }),
        }),
        getUserDocuments: builder.query<DocumentUserDto[], number>({
            query: (id) => `documentUser/get/${id}`,
            providesTags: ['Document'],
        }),
        getAllUserDocuments: builder.query<DocumentUserDto[], number>({
            query: (id) => `documentUser/getAll/${id}`,
            providesTags: ['Document'],
        }),
        deleteDocumentUser: builder.mutation<void, number>({
            query: (id) => ({
                url: `documentUser/delete/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Document'],
        }),
        createValidationDocument: builder.mutation<
            DocumentUserDto,
            CreateDocumentUserValidationDto
        >({
            query: (payload) => ({
                url: `documentUser/validation`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Document'],
        }),
        getMetricKnowledgeUser: builder.query<MetricKnowledgeUser, number>({
            query: (id) => ({
                url: `users/metricKnowledge/${id}`,
                method: 'GET',
            }),
        }),
        editValidationDocument: builder.mutation<
            DocumentUserDto,
            UpdateDocumentUserValidationDto
        >({
            query: (payload) => ({
                url: `documentUser/validation/${payload.documentId}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['Document'],
        }),
        deleteValidationDocument: builder.mutation<
            void,
            DeleteValidationDocument
        >({
            query: (request) => ({
                url: `checkUserProfile/${request.validationId}`,
                method: 'DELETE',
            }),
            async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUserWithAllInfo',
                            payload.userId,
                            (draft) => {
                                return {
                                    ...draft,
                                    checkUserProfile:
                                        draft.checkUserProfile &&
                                        draft.checkUserProfile.filter(
                                            (v) => v.id !== payload.validationId
                                        ),
                                };
                            }
                        )
                    );
                } catch (err) {
                    console.log(err);
                }
            },
        }),

        editUser: builder.mutation<UserDto, Partial<UserDto>>({
            query: (payload) => ({
                url: `users/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedPost } = await queryFulfilled;
                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUserWithAllInfoForCustomer',
                            payload.id!,
                            (draft) => {
                                return {
                                    ...draft,
                                    ...updatedPost,
                                };
                            }
                        )
                    );
                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUserWithAllInfo',
                            payload.id!,
                            (draft) => {
                                return {
                                    ...draft,
                                    ...updatedPost,
                                };
                            }
                        )
                    );
                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUserById',
                            payload.id!,
                            (draft) => {
                                return updatedPost;
                            }
                        )
                    );
                } catch (err) {
                    console.log(err);
                }
            },
            // invalidatesTags: ['User']
        }),
        editUserWithAllInfo: builder.mutation<
            UserWithAllInfoDto,
            Partial<UpdateUserWithAllInfoDto>
        >({
            query: (payload) => ({
                url: `users/allInfo/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedPost } = await queryFulfilled;
                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUserWithAllInfoForCustomer',
                            payload.id!,
                            (draft) => {
                                return updatedPost;
                            }
                        )
                    );
                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUserWithAllInfo',
                            payload.id!,
                            (draft) => {
                                return {
                                    ...draft,
                                    ...updatedPost,
                                };
                            }
                        )
                    );
                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUserById',
                            payload.id!,
                            (draft) => {
                                return updatedPost;
                            }
                        )
                    );
                } catch (err) {
                    console.log(err);
                }
            },
            // invalidatesTags: ['User']
        }),
        editBasicInfoUser: builder.mutation<
            void,
            {
                request: UpdateUserInvestAccountDto;
                userId: number;
            }
        >({
            query: (request) => ({
                url: `users/basicInfo/${request.userId}`,
                method: 'PUT',
                body: request,
            }),
            invalidatesTags: ['User'],
        }),
        editUserKnowledge: builder.mutation<
            UserWithAllInfoDto,
            UpdateUserKnowlegdeDto
        >({
            query: (payload) => ({
                url: `users/knowledge/${payload.userId}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['UserKnowledge'],
        }),
        editUserAdmin: builder.mutation<UserDto, UpdateUserByAdminDto>({
            query: (payload) => ({
                url: `users/admin/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedPost } = await queryFulfilled;
                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUsers',
                            undefined,
                            (draft) => {
                                return draft.map((platform) => {
                                    if (platform.id === updatedPost.id) {
                                        return {
                                            ...platform,
                                            ...updatedPost,
                                        };
                                    } else return platform;
                                });
                            }
                        )
                    );
                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUserWithAllInfo',
                            payload.id,
                            (draft) => {
                                return {
                                    ...draft,
                                    admin: updatedPost.admin,
                                };
                            }
                        )
                    );

                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUserById',
                            payload.id,
                            (draft) => {
                                return updatedPost;
                            }
                        )
                    );
                } catch (err) {
                    console.log(err);
                }
            },
        }),

        deleteUser: builder.mutation<void, number>({
            query: (payload) => ({
                url: `users/${payload}`,
                method: 'DELETE',
            }),
        }),
        createSponsorCode: builder.mutation<UserDto, number>({
            query: (userId) => ({
                url: `users/sponsorCode/${userId}`,
                method: 'POST',
            }),
            async onQueryStarted(userId, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedPost } = await queryFulfilled;
                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUserWithAllInfoForCustomer',
                            userId,
                            (draft) => {
                                return {
                                    ...draft,
                                    sponsorCode: updatedPost.sponsorCode,
                                };
                            }
                        )
                    );
                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUserWithAllInfo',
                            userId,
                            (draft) => {
                                return {
                                    ...draft,
                                    sponsorCode: updatedPost.sponsorCode,
                                };
                            }
                        )
                    );
                    dispatch(
                        usersApi.util.updateQueryData(
                            'getUserById',
                            userId,
                            (draft) => {
                                return updatedPost;
                            }
                        )
                    );
                } catch (err) {
                    console.log(err);
                }
            },
        }),
        // Check user profile

        onBoardingDone: builder.mutation<void, number>({
            query: (userId) => ({
                url: `users/onBoardingDone/${userId}`,
                method: 'PUT',
            }),
            invalidatesTags: ['User'],
        }),
        checkUserProfile: builder.mutation<void, CreateValidationUserDto>({
            query: (request) => ({
                url: `checkUserProfile`,
                method: 'POST',
                body: request,
            }),
            invalidatesTags: ['User', 'Document'],
        }),
        // Invest account
        // create
        createInvestAccount: builder.mutation<
            InvestAccountDto,
            CreateInvestAccountAndUpdateUserInfoDto
        >({
            query: (request) => ({
                url: `users/investAccount/${request.userId}`,
                method: 'POST',
                body: request,
            }),
            invalidatesTags: ['InvestAccount', 'User'],
        }),
        // update
        updateInvestAccount: builder.mutation<
            InvestAccountDto,
            UpdateInvestAccountDto
        >({
            query: (request) => ({
                url: `users/investAccount/${request.userId}`,
                method: 'PUT',
                body: request,
            }),
            invalidatesTags: ['InvestAccount', 'User'],
        }),
        getInvestAccountById: builder.query<
            InvestAccountDto,
            {
                userId: number;
                investAccountId: number;
            }
        >({
            query: (request) => ({
                url: `users/investAccount/${request.userId}`,
                method: 'GET',
                params: {
                    investAccountId: request.investAccountId,
                },
            }),
            providesTags: ['InvestAccount', 'User'],
            transformResponse: (response: InvestAccountDto) => {
                return {
                    ...response,
                    iban: response.iban
                        ? response.iban.replace(/(.{4})/g, '$1 ')
                        : undefined,
                };
            },
        }),
        getAllSubscriptionsUser: builder.query<
            SubscriptionUserFondsDto[],
            number
        >({
            query: (userId) => ({
                url: `subscriptionUserFonds/all/${userId}`,
                method: 'GET',
            }),
            providesTags: ['InvestAccount', 'Subscription'],
        }),
        // get subscription by id
        getSubscriptionUserBySubscriptionId: builder.query<
            SubscriptionUserFondsDto,
            {
                userId: number;
                subscriptionId: number;
            }
        >({
            query: (request) => ({
                url: `subscriptionUserFonds/byId/${request.userId}`,
                method: 'GET',
                params: {
                    subscriptionUserFondsId: request.subscriptionId,
                },
            }),
            providesTags: ['InvestAccount', 'Subscription'],
        }),
        // delete
        deleteSubscriptionUser: builder.mutation<
            void,
            {
                userId: number;
                subscriptionId: number;
            }
        >({
            query: (request) => ({
                url: `subscriptionUserFonds/delete/${request.userId}`,
                method: 'DELETE',
                params: {
                    subscriptionUserFondsId: request.subscriptionId,
                },
            }),
            invalidatesTags: ['InvestAccount', 'Subscription'],
        }),
        sendSubscriptionToBeSigned: builder.mutation<
            void,
            SubscriptionContractForSigningDto
        >({
            query: (request) => ({
                url: `subscriptionUserFonds/sendToSign/${request.userId}`,
                method: 'PUT',
                body: {
                    subscriptionUserFondsId: request.subscriptionUserFondsId,
                    file: request.file,
                    name: request.name,
                    email: request.email,
                    phone: request.phone,
                },
            }),
        }),
        // contract signed
        contractSigned: builder.mutation<
            void,
            {
                userId: number;
                subscriptionUserFondsId: number;
                jsonSigned: string;
            }
        >({
            query: (request) => ({
                url: `subscriptionUserFonds/contractSigned/${request.userId}`,
                method: 'PUT',
                body: {
                    subscriptionUserFondsId: request.subscriptionUserFondsId,
                    jsonSigned: request.jsonSigned,
                },
            }),
            invalidatesTags: ['InvestAccount', 'Subscription'],
        }),
        isWiseedClient: builder.query<boolean, isWiseedClientRequest>({
            query: (request) => ({
                url: `subscriptionUserFonds/isWiseedClient/${request.userId}`,
                method: 'GET',
            }),
            providesTags: ['Subscription'],
        }),

        // // delete
        // deleteInvestAccount: builder.mutation<void, number>({
        //     query: (id) => ({
        //         url: `users/investAccount/${id}`,
        //         method: "DELETE",

        //     }),
        //     invalidatesTags: ['InvestAccount', 'User']

        // }),
        // get document invest account
        getDocumentsInvestAccount: builder.query<
            DocumentInvestAccountDto[],
            {
                userId: number;
                investAccountId: number;
            }
        >({
            query: (request) => ({
                url: `documentInvestAccount/investAccount/${request.userId}`,
                method: 'GET',
                params: {
                    investAccountId: request.investAccountId,
                },
            }),
            providesTags: ['InvestAccount'],
        }),
        getAllLatestDocumentsInvestAccountByUserId: builder.query<
            AllLatestDocumentInvestAccount,
            {
                userId: number;
                investAccountId: number;
            }
        >({
            query: (request) => ({
                url: `documentInvestAccount/allLatest/${request.userId}`,
                method: 'GET',
                params: {
                    investAccountId: request.investAccountId,
                },
            }),
            providesTags: ['InvestAccount', 'ValidationInvestAccount'],
        }),
        getAllDocumentsInvestAccountByUserId: builder.query<
            AllDocumentInvestAccount,
            {
                userId: number;
                investAccountId: number;
            }
        >({
            query: (request) => ({
                url: `documentInvestAccount/all/${request.userId}`,
                method: 'GET',
                params: {
                    investAccountId: request.investAccountId,
                },
            }),
            providesTags: ['InvestAccount', 'ValidationInvestAccount'],
        }),
        // get document moral account
        getDocumentsMoralAccount: builder.query<
            DocumentMoralAccountDto[],
            {
                userId: number;
                moralAccountId: number;
            }
        >({
            query: (request) => ({
                url: `documentInvestAccount/moralAccount/${request.userId}`,
                method: 'GET',
                params: {
                    moralAccountId: request.moralAccountId,
                },
            }),
        }),
        // get document beneficial owner
        getDocumentsBeneficialOwner: builder.query<
            DocumentBeneficialOwnerDto[],
            {
                userId: number;
                moralAccountId: number;
            }
        >({
            query: (request) => ({
                url: `documentInvestAccount/beneficialOwner/${request.userId}`,
                method: 'GET',
                params: {
                    moralAccountId: request.moralAccountId,
                },
            }),
        }),
        // delete document invest account
        deleteDocumentInvestAccount: builder.mutation<
            void,
            {
                userId: number;
                documentId: number;
            }
        >({
            query: (request) => ({
                url: `documentInvestAccount/investAccount/${request.userId}`,
                method: 'DELETE',
                params: {
                    documentId: request.documentId,
                },
            }),
        }),
        // delete document moral account
        deleteDocumentMoralAccount: builder.mutation<
            void,
            {
                userId: number;
                documentId: number;
            }
        >({
            query: (request) => ({
                url: `documentInvestAccount/moralAccount/${request.userId}`,
                method: 'DELETE',
                params: {
                    documentId: request.documentId,
                },
            }),
        }),
        // delete document beneficial owner
        deleteDocumentBeneficialOwner: builder.mutation<
            void,
            {
                userId: number;
                documentId: number;
            }
        >({
            query: (request) => ({
                url: `documentInvestAccount/beneficialOwner/${request.userId}`,
                method: 'DELETE',
                params: {
                    documentId: request.documentId,
                },
            }),
        }),
        // Validation
        createOrUpdateValidationsInvestAccount: builder.mutation<
            void,
            CreateOrUpdateValidationInvestAccountDto
        >({
            query: (request) => ({
                url: `documentInvestAccount/validation`,
                method: 'POST',
                body: request,
            }),
            invalidatesTags: [
                'ValidationInvestAccount',
                'User',
                'InvestAccount',
            ],
        }),
        deleteDocumentMoralValidation: builder.mutation<void, number>({
            query: (id) => ({
                url: `documentInvestAccount/validation/moralAccount/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ValidationInvestAccount'],
        }),
        deleteDocumentInvestAccountValidation: builder.mutation<void, number>({
            query: (id) => ({
                url: `documentInvestAccount/validation/investAccount/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ValidationInvestAccount'],
        }),
        deleteDocumentBeneficialOwnerValidation: builder.mutation<void, number>(
            {
                query: (id) => ({
                    url: `documentInvestAccount/validation/beneficialOwner/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['ValidationInvestAccount'],
            }
        ),
        // Status User
        createStatusUser: builder.mutation<void, CreateStatusUserDto>({
            query: (request) => ({
                url: `statusUser/${request.userId}`,
                method: 'POST',
            }),
            invalidatesTags: ['User'],
        }),
        editStatusUser: builder.mutation<void, UpdateStatusUserDto>({
            query: (request) => ({
                url: `statusUser/${request.userId}`,
                method: 'PUT',
                body: request,
            }),
            invalidatesTags: ['User'],
        }),
    }),
});

export const {
    useGetMetricKnowledgeUserQuery,
    useOnBoardingDoneMutation,
    useGetUsersSearchQuery,
    useDeleteValidationDocumentMutation,
    useGetUsersQuery,
    useGetUserByIdQuery,
    useGetUserWithAllInfoQuery,
    useGetUserDocumentsQuery,
    useEditUserMutation,
    useEditUserWithAllInfoMutation,
    useEditUserKnowledgeMutation,
    useEditUserAdminMutation,
    useDeleteUserMutation,
    useCreateSponsorCodeMutation,
    useCreateValidationDocumentMutation,
    useEditValidationDocumentMutation,
    useGetUsersRequestQuery,
    useCheckUserProfileMutation,
    useGetUserWithAllInfoForCustomerQuery,
    useCreateInvestAccountMutation,
    useUpdateInvestAccountMutation,
    useGetDocumentsBeneficialOwnerQuery,
    useGetDocumentsInvestAccountQuery,
    useGetDocumentsMoralAccountQuery,
    useGetAllLatestDocumentsInvestAccountByUserIdQuery,
    useDeleteDocumentBeneficialOwnerMutation,
    useDeleteDocumentInvestAccountMutation,
    useDeleteDocumentMoralAccountMutation,
    useGetInvestAccountByIdQuery,
    useCreateOrUpdateValidationsInvestAccountMutation,
    useDeleteDocumentBeneficialOwnerValidationMutation,
    useDeleteDocumentInvestAccountValidationMutation,
    useDeleteDocumentMoralValidationMutation,
    useGetAllSubscriptionsUserQuery,
    useGetSubscriptionUserBySubscriptionIdQuery,
    useCreateStatusUserMutation,
    useEditStatusUserMutation,
    useEditBasicInfoUserMutation,
    useDeleteSubscriptionUserMutation,
    useSendSubscriptionToBeSignedMutation,
    useDeleteDocumentUserMutation,
    useContractSignedMutation,
    useGetAllDocumentsInvestAccountByUserIdQuery,
    useGetAllUserDocumentsQuery,
    useGetUsersWithSharesByDateLimitQuery,
    useIsWiseedClientQuery,
} = usersApi;

export async function uploadDocumentUser(
    request: FormCreateDocumentUserRequest
) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}documentUser/url/put/${request.userId}`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    userId: request.userId,
                    name: request.name,
                    type: request.type,
                    side: request.side,
                    fileName: encodeURIComponent(request.fileName),
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}documentUser/document/${request.userId}`,
            data: {
                userId: request.userId,
                name: request.name,
                type: request.type,
                side: request.side,
                fileName: encodeURIComponent(request.fileName),
                key: key,
            },
            withCredentials: true,
        });
        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}

// upload document invest account
export async function uploadDocumentInvestAccount(
    request: FormCreateDocumentInvestAccountRequest
) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}documentInvestAccount/url/put/investAccount/${request.userId}`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    investAccountId: request.investAccountId,
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}documentInvestAccount/investAccount/${request.userId}`,
            data: {
                investAccountId: request.investAccountId,
                fileName: encodeURIComponent(request.fileName),
                iban: request.iban,
                bic: request.bic,
                key: key,
            },
            withCredentials: true,
        });
        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}

// upload document moral account
export async function uploadDocumentMoralAccount(
    request: FormCreateDocumentMoralAccountRequest
) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}documentInvestAccount/url/put/moralAccount/${request.userId}`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    moralAccountId: request.moralAccountId,
                    type: request.type,
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}documentInvestAccount/moralAccount/${request.userId}`,
            data: {
                moralAccountId: request.moralAccountId,
                fileName: encodeURIComponent(request.fileName),
                key: key,
                type: request.type,
            },
            withCredentials: true,
        });
        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}

// upload document beneficial owner
export async function uploadDocumentBeneficialOwner(
    request: FormCreateDocumentBeneficialOwnerRequest
) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}documentInvestAccount/url/put/beneficialOwner/${request.userId}`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    beneficialOwnerId: request.beneficialOwnerId,
                    name: request.name,
                    side: request.side,
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}documentInvestAccount/beneficialOwner/${request.userId}`,
            data: {
                beneficialOwnerId: request.beneficialOwnerId,
                fileName: encodeURIComponent(request.fileName),
                key: key,
                name: request.name,
                side: request.side,
            },
            withCredentials: true,
        });
        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}

// upload subscriptionContract
export async function uploadSubscriptionContract(
    request: FormUploadSubscriptionDto
) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}documentUser/subscription/url/put/${request.userId}`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    subscriptionUserFondsId: request.subscriptionUserFondsId,
                    type: request.type,
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}documentUser/subscription/${request.userId}`,
            data: {
                subscriptionUserFondsId: request.subscriptionUserFondsId,
                fileName: encodeURIComponent(request.fileName),
                key: key,
                type: request.type,
            },
            withCredentials: true,
        });
        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}
