import { useGetFondsQuery } from '@api/features/fondsSlice';
import { useGetPlatformsQuery } from '@api/features/platformSlice';
import {
    useEditProjectBasicInfoMutation,
    useGetParentCompaniesQuery,
    useGetProjectAllInfoForFormDataQuery,
    useGetProjectByIdQuery,
    useGetProjectImpactsQuery,
    useGetProjectSectorsQuery,
    useGetShareholdersQuery,
} from '@api/features/projectSlice';
import { PrimaryButton } from '@components/commun/Buttons';
import CheckboxComponent from '@components/commun/formComponent/CheckboxComponent';
import InputComponent from '@components/commun/formComponent/InputComponent';
import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import PickCoordonates from '@components/commun/formComponent/PickCoordonates';
import RadioComponent from '@components/commun/formComponent/RadioComponent';
import SelectComponent from '@components/commun/formComponent/SelectComponent';
import SelectMultiComponent from '@components/commun/formComponent/SelectMultiComponent';
import TextAreaComponent from '@components/commun/formComponent/TextAreaComponent';
import TextEditorComponent from '@components/commun/formComponent/TextEditorComponent';
import Loading from '@components/commun/Loading';
import { useNotificationContext } from '@components/Context/notification-context';
import { AddParentCompany } from '@components/DashboardAdmin/ProjectManage/InvestTools/ParentCompany';
import { AddShareholder } from '@components/DashboardAdmin/ProjectManage/InvestTools/Shareholder';
import CheckboxArrayComponent from '@components/DashboardUser/Profile/FormInvest/FormComponentForArray/CheckBoxArrayComponent';
import { fundingTypeData } from '@data/ProjectForm';
import { TypePayement } from '@interfaces/fonds';
import { ParentCompanyDto } from '@interfaces/parentCompanyType';
import { UpdateProjectBasicInfoFormDtoForm } from '@interfaces/project';
import { ShareholderDto } from '@interfaces/shareholderType';
import { debounce } from '@material-ui/core';

import { stringToFloat, stringToInt, transformDate } from '@utils/Utils';
import { useCallback, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';

export function BasicInfo({ projectId }: { projectId: number }) {
    const { showSuccess, showError } = useNotificationContext();

    const {
        data: project,
        isLoading: isLoadingProject,
        isFetching: isFetchingProject,
        refetch: refetchProject,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const { data: fondsData } = useGetFondsQuery();
    const { data: platformData } = useGetPlatformsQuery();

    const [loading, setLoading] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        reset,
        control,
    } = useForm<UpdateProjectBasicInfoFormDtoForm>();

    const {
        field: { onChange: onChangeComment, value: comment },
    } = useController<UpdateProjectBasicInfoFormDtoForm>({
        name: 'content',
        control: control,
    });

    const [editProject, { isLoading: isLoadingEditProject }] =
        useEditProjectBasicInfoMutation();

    const { data: projectImpact, isLoading: isLoadingImpact } =
        useGetProjectImpactsQuery(projectId, {
            skip: projectId === undefined,
        });

    const setDefautlValues = (): void => {
        if (project && projectSectors && projectImpact) {
            reset({
                name: project?.realName,
                shareholderIds: project?.shareholders?.map((s) => s.id) || [],
                parentCompanyIds:
                    project?.parentCompanies?.map((p) => p.id) || [],
                content: project?.content,
                platformId: project?.platformId ?? 0,
                delayAccepted: project?.delayAccepted ?? 0,
                businessContributorIncome:
                    project?.businessContributorIncome ?? 0,
                duration: project?.duration ?? undefined,
                payment: project?.payment ?? null,
                confidential: project?.confidential ? 'true' : 'false',
                dateStart: new Date(project?.dateStart!)
                    .toJSON()
                    ?.split('T')[0],
                dateEnd: new Date(project?.dateEnd!).toJSON()?.split('T')[0],
                compoundInterest: project?.compoundInterest ?? null,
                compoundInterestBool:
                    project?.compoundInterestBool ?? undefined,
                fondsId: project?.fondsId,
                id: project?.id,
                description: project?.description ?? undefined,
                show: project?.show,
                longitude: project?.longitude,
                latitude: project?.latitude,
                url: project?.url ?? undefined,
                notationPlatform: project?.notationPlatform ?? undefined,
                status: project?.status,
                address: project?.address ?? undefined,
                sectorId: projectImpact?.projectImpactGeneral?.sectorId
                    ? projectImpact?.projectImpactGeneral?.sectorId
                    : project.fondsId === 1
                      ? projectSectors?.find((s) => s.value === 'Contruction')
                            ?.id
                      : undefined,
                projectInfo: {
                    ...project?.projectInfo,
                    monthProject: project?.projectInfo?.monthProject
                        ? 'true'
                        : 'false',
                    capital: project?.projectInfo?.capital ?? undefined,
                    projectMarketTypology: project?.projectInfo
                        ?.projectMarketTypology
                        ? project?.projectInfo?.projectMarketTypology.map(
                              (v) => v.id
                          )
                        : [],
                    projectSponsorTypology:
                        project?.projectInfo?.projectSponsorTypology,
                    projectSponsorTypologyOther:
                        project?.projectInfo?.projectSponsorTypologyOther,
                    fundingType: project?.projectInfo?.fundingType,
                    fundingTypeOther: project?.projectInfo?.fundingTypeOther,
                    seniorityLevel: project?.projectInfo?.seniorityLevel
                        ? project?.projectInfo?.seniorityLevel.map((v) => v.id)
                        : [],
                },
                interests:
                    project?.interests.map((pi) => {
                        return {
                            ...pi,
                            date: new Date(pi.date).toJSON()?.split('T')[0],
                        };
                    }) ?? [],
            });
        }
    };

    const { data: formData, isLoading: isLoadingFormData } =
        useGetProjectAllInfoForFormDataQuery();

    const { seniorityLevel, projectMarketTypology } = formData ?? {};

    const { data: projectSectors } = useGetProjectSectorsQuery();

    const { data: parentCompanies } = useGetParentCompaniesQuery();

    const { data: shareholders } = useGetShareholdersQuery();

    useEffect(() => {
        if (project) {
            setDefautlValues();
        }
    }, [project, projectSectors, projectImpact]);

    const onSubmit = async (data: UpdateProjectBasicInfoFormDtoForm) => {
        setLoading((curr) => !curr);
        delete data.amount;

        await editProject({
            ...data,
            dateEnd: transformDate(data.dateEnd),
            id: projectId,
            delayAccepted: stringToFloat(data.delayAccepted!),
            dateStart: transformDate(data.dateStart),
            fondsId: stringToFloat(data.fondsId),
            businessContributorIncome: stringToFloat(
                data.businessContributorIncome!
            ),
            confidential: data.confidential === 'true' ? true : false,
            address: data.address,
            projectInfo: {
                ...data.projectInfo,
                monthProject:
                    data?.projectInfo?.monthProject === 'true' ? true : false,
                capital:
                    data?.projectInfo?.capital !== undefined ||
                    data?.projectInfo?.capital !== ''
                        ? parseInt(
                              data?.projectInfo?.capital! as unknown as string
                          )
                        : undefined,
            },
            duration: stringToFloat(data.duration!),
            platformId: data.platformId ? stringToInt(data.platformId) : 0,
            interests: data.interests.map((pi) => {
                return {
                    id: pi.id ?? undefined,
                    value: stringToFloat(pi.value),
                    fondsId: stringToInt(data.fondsId),
                    projectId: stringToInt(data.id),
                    date: transformDate(pi.date),
                };
            }),
        })
            .unwrap()
            .then(async (res) => {
                showSuccess('Edited', 'Project edited successfully');

                setLoading((curr) => !curr);
            })
            .catch((err) => {
                showError('Error', 'Error editing project');
                setLoading((curr) => !curr);
            });
    };

    const updateDateEnd = useCallback(
        debounce(() => {
            if (getValues('dateStart') != null) {
                setValue(
                    `interests`,
                    getValues(`interests`).map((val, index) => {
                        if (index === 0) {
                            return {
                                ...val,
                                date: transformDate(
                                    new Date(getValues('dateStart'))
                                )
                                    .toJSON()
                                    .split('T')[0],
                            };
                        } else {
                            return val;
                        }
                    })
                );
            }
        }, 500),
        []
    );

    const marketTypologies = projectMarketTypology
        ?.filter((p) => p.fonds?.map((f) => f.id)?.includes(watch('fondsId')))
        .concat(projectMarketTypology.filter((p) => p.value === 'Autre'));

    useEffect(() => {
        updateDateEnd();
    }, [watch('dateStart')]);

    const [openAddShareholder, setOpenAddShareholder] =
        useState<boolean>(false);
    const [openAddParentCompany, setOpenAddParentCompany] =
        useState<boolean>(false);

    return (
        <>
            {isLoadingProject || isLoadingFormData || isFetchingProject ? (
                <Loading />
            ) : (
                <>
                    {openAddShareholder ? (
                        <AddShareholder
                            setOpen={setOpenAddShareholder}
                            open={openAddShareholder}
                        />
                    ) : null}
                    {openAddParentCompany ? (
                        <AddParentCompany
                            setOpen={setOpenAddParentCompany}
                            open={openAddParentCompany}
                        />
                    ) : null}

                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={`formTemplateSingUp`}
                        style={{ width: `92%` }}
                    >
                        <div
                            className={`w-full mx-auto grid grid-cols-1 lg:grid-cols-2
                lg:gap-x-6
            `}
                        >
                            <div className=""></div>
                            <div className="">
                                <RadioComponent
                                    register={register}
                                    watch={watch}
                                    required={false}
                                    name={'projectInfo.monthProject'}
                                    className={{
                                        container: 'flex gap-2',
                                    }}
                                    values={[
                                        { value: 'true', label: 'Oui' },
                                        { value: 'false', label: 'Non' },
                                    ]}
                                >
                                    <LabelComponentForm>
                                        Investissement du mois
                                    </LabelComponentForm>
                                </RadioComponent>
                            </div>
                            <TitleComponentForm>
                                DATA PLATEFORME
                            </TitleComponentForm>

                            <SelectComponent
                                register={register}
                                value={'fondsId'}
                                control={control}
                                // watch={watch}
                                container={true}
                                optionValues={
                                    fondsData?.map((val) => {
                                        return {
                                            value: val.id,
                                            label: val.name,
                                        };
                                    }) ?? []
                                }
                            >
                                <LabelComponentForm>
                                    Fonds <span className="required">*</span>
                                </LabelComponentForm>
                            </SelectComponent>
                            <SelectComponent
                                register={register}
                                value={'platformId'}
                                control={control}
                                container={true}
                                optionValues={
                                    platformData?.map((val) => {
                                        return {
                                            value: val.id,
                                            label: val.name,
                                        };
                                    }) ?? []
                                }
                            >
                                <LabelComponentForm>
                                    Plateforme{' '}
                                    <span className="required">*</span>
                                </LabelComponentForm>
                            </SelectComponent>
                            <InputComponent
                                register={register}
                                value={'businessContributorIncome'}
                                type={'number'}
                            >
                                <LabelComponentForm>
                                    Revenue d'apporteur d'affaires (%)
                                    <span className="required">*</span>
                                </LabelComponentForm>
                            </InputComponent>
                            <div className="">
                                <RadioComponent
                                    register={register}
                                    watch={watch}
                                    name={'confidential'}
                                    className={{
                                        container: 'flex gap-2',
                                    }}
                                    values={[
                                        { value: 'true', label: 'Oui' },
                                        { value: 'false', label: 'Non' },
                                    ]}
                                >
                                    <LabelComponentForm>
                                        Confidentiel{' '}
                                        <span className="required">*</span>
                                    </LabelComponentForm>
                                </RadioComponent>
                            </div>

                            <SelectMultiComponent
                                register={register}
                                control={control}
                                setValue={setValue}
                                required={false}
                                optionValues={shareholders || []}
                                optionsRender={(val: ShareholderDto) =>
                                    val.fullName
                                }
                                valueRender={(val: ShareholderDto) => val.id}
                                value={'shareholderIds'}
                            >
                                <div className="grid md:grid-cols-2 mb-2">
                                    <LabelComponentForm>
                                        Actionnaires
                                    </LabelComponentForm>
                                    <PrimaryButton
                                        onClick={() =>
                                            setOpenAddShareholder(true)
                                        }
                                        className=" mr-2 place-self-end"
                                    >
                                        Add new
                                    </PrimaryButton>
                                </div>
                            </SelectMultiComponent>

                            <SelectMultiComponent
                                register={register}
                                required={false}
                                control={control}
                                setValue={setValue}
                                optionValues={parentCompanies || []}
                                optionsRender={(val: ParentCompanyDto) =>
                                    `${val.name} -${val.kBis}`
                                }
                                valueRender={(val: ParentCompanyDto) => val.id}
                                value={'parentCompanyIds'}
                            >
                                <div className="grid md:grid-cols-2 mb-2">
                                    <LabelComponentForm>
                                        Sociétés mère
                                    </LabelComponentForm>
                                    <PrimaryButton
                                        onClick={() =>
                                            setOpenAddParentCompany(true)
                                        }
                                        className=" mr-2 place-self-end"
                                    >
                                        Add new
                                    </PrimaryButton>
                                </div>
                            </SelectMultiComponent>

                            <TitleComponentForm>
                                DATA ACTEUR ET PORTEUR DU PROJET
                            </TitleComponentForm>
                            <InputComponent register={register} value={'name'}>
                                <LabelComponentForm>
                                    Nom<span className="required">*</span>
                                </LabelComponentForm>
                            </InputComponent>
                            <TextAreaComponent
                                register={register}
                                value={'description'}
                                required={false}
                            >
                                <LabelComponentForm>
                                    Description du projet
                                </LabelComponentForm>
                            </TextAreaComponent>

                            <PickCoordonates
                                height="200px"
                                width="100%"
                                control={control}
                                initialCenter={
                                    watch('latitude') && watch('longitude')
                                        ? {
                                              latitude: watch('latitude')!,
                                              longitude: watch('longitude')!,
                                          }
                                        : undefined
                                }
                            />
                            <div className="">
                                <InputComponent
                                    register={register}
                                    value={'url'}
                                >
                                    <LabelComponentForm>
                                        Lien du projet{' '}
                                        <span className="required">*</span>
                                    </LabelComponentForm>
                                </InputComponent>

                                {/* <InputComponent register={register} value={"notationPlatform"}>
                <LabelComponentForm>
                  Risque notation plateforme <span className="required">*</span>
                </LabelComponentForm>
              </InputComponent> */}
                            </div>

                            <TextEditorComponent
                                content={comment as any as string}
                                setContent={onChangeComment}
                                className=" md:col-span-2"
                            >
                                <LabelComponentForm>
                                    Longue description
                                </LabelComponentForm>
                            </TextEditorComponent>

                            <TitleComponentForm className="">
                                DATA RISQUE
                            </TitleComponentForm>

                            <TitleComponentForm>
                                DATA TYPOLOGIE DE PROJET
                            </TitleComponentForm>
                            {fondsData &&
                            fondsData.find((f) => f.name === 'Immobilier')
                                ?.id === watch('fondsId') ? (
                                <>
                                    <SelectComponent
                                        register={register}
                                        control={control}
                                        container={true}
                                        setValue={setValue}
                                        getValues={getValues}
                                        value={
                                            'projectInfo.projectSponsorTypology'
                                        }
                                        optionValues={[
                                            {
                                                label: 'Promoteur',
                                                value: 'Promoteur',
                                            },
                                            {
                                                label: 'Marchand de biens',
                                                value: 'Marchand de biens',
                                            },
                                            {
                                                label: 'Foncière',
                                                value: 'Foncière',
                                            },
                                            {
                                                label: 'Autre',
                                                value: 'Autre',
                                            },
                                        ]}
                                    >
                                        <LabelComponentForm>
                                            Typologie du porteur de projet{' '}
                                            <span className="required">*</span>
                                        </LabelComponentForm>
                                    </SelectComponent>
                                    {watch(
                                        'projectInfo.projectSponsorTypology'
                                    ) === 'Autre' ? (
                                        <InputComponent
                                            register={register}
                                            value={
                                                'projectInfo.projectSponsorTypologyOther'
                                            }
                                        >
                                            <LabelComponentForm>
                                                Typologie du porteur de projet
                                                autre{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </LabelComponentForm>
                                        </InputComponent>
                                    ) : null}
                                </>
                            ) : null}

                            <SelectComponent
                                register={register}
                                control={control}
                                container={true}
                                setValue={setValue}
                                getValues={getValues}
                                value={'projectInfo.fundingType'}
                                optionValues={
                                    watch('fondsId') && fondsData
                                        ? fundingTypeData
                                        : []
                                }
                            >
                                <LabelComponentForm>
                                    Flechage du financement{' '}
                                    <span className="required">*</span>
                                </LabelComponentForm>
                            </SelectComponent>
                            {watch('projectInfo.fundingType') === 'Autre' ? (
                                <InputComponent
                                    register={register}
                                    value={'projectInfo.fundingTypeOther'}
                                >
                                    <LabelComponentForm>
                                        Flechage du financement autre{' '}
                                        <span className="required">*</span>
                                    </LabelComponentForm>
                                </InputComponent>
                            ) : null}

                            {marketTypologies &&
                            marketTypologies?.length > 0 ? (
                                <div className=" md:col-span-2">
                                    <LabelComponentForm className=" text-center">
                                        Typologie de marché du projet
                                    </LabelComponentForm>

                                    <CheckboxArrayComponent
                                        register={register}
                                        name={
                                            'projectInfo.projectMarketTypology'
                                        }
                                        className={{
                                            container:
                                                'grid md:grid-cols-2 lg:grid-cols-3 lg:mt-3 items-center justify-center',
                                        }}
                                        values={
                                            marketTypologies
                                                ? marketTypologies.map((v) => {
                                                      return {
                                                          label: v.value,
                                                          value: v.id,
                                                      };
                                                  })
                                                : []
                                        }
                                        watch={watch}
                                        control={control}
                                    ></CheckboxArrayComponent>
                                </div>
                            ) : null}
                            {watch('projectInfo.projectMarketTypology')
                                ?.map(
                                    (p) =>
                                        projectMarketTypology?.find(
                                            (v) => v.id === p
                                        )?.value
                                )
                                ?.includes('Autre') ? (
                                <InputComponent
                                    register={register}
                                    value={`projectInfo.projectMarketTypologyOther`}
                                >
                                    <LabelComponentForm>
                                        Typologie de marché du projet autre{' '}
                                        <span className="required">*</span>
                                    </LabelComponentForm>
                                </InputComponent>
                            ) : null}

                            <SelectComponent
                                register={register}
                                control={control}
                                container={true}
                                setValue={setValue}
                                getValues={getValues}
                                value={'sectorId'}
                                optionValues={
                                    projectSectors?.map((s) => {
                                        return { value: s.id, label: s.value };
                                    }) || []
                                }
                            >
                                <LabelComponentForm>
                                    Secteur <span className="required">*</span>
                                </LabelComponentForm>
                            </SelectComponent>

                            <div>
                                <RadioComponent
                                    register={register}
                                    watch={watch}
                                    name={'projectInfo.obligationType'}
                                    className={{
                                        container: 'flex gap-2',
                                    }}
                                    values={[
                                        {
                                            value: 'Obligation simple',
                                            label: 'Obligation simple',
                                        },
                                        {
                                            value: 'Obligation convertible en action',
                                            label: 'Obligation convertible en action',
                                        },
                                    ]}
                                >
                                    <LabelComponentForm>
                                        Type d'obligation{' '}
                                        <span className="required">*</span>
                                    </LabelComponentForm>
                                </RadioComponent>
                            </div>

                            {/* <div className="lg:col-span-2 max-w-md mx-auto my-4">
              <h3 className="text-center text-lg font-semibold my-2 mx-auto">
                List des intérêt en fonction de la date
              </h3>
              <FieldArray control={control} name="interests">
                {({ fields, append, remove }) => (
                  <div className="flex flex-col w-full justify-center">
                    <div className="flex flex-col w-full space-y-2 mb-2">
                      {fields.map((field, index) => (
                        <div
                          key={field.id}
                          className="grid grid-cols-2 w-full space-x-2 relative"
                        >
                          <InputComponent
                            register={register}
                            type="date"
                            value={`interests.${index}.date`}
                            cursorNotAllowed={index === 0 ? true : undefined}
                            // className={{ input: "row-span-1 col-span-1" }}
                          >
                            <h3 className="text-left">
                              Date <span className="required">*</span>
                            </h3>
                          </InputComponent>

                          <InputComponent
                            register={register}
                            type="number"
                            value={`interests.${index}.value`}
                            // className={{ input: "row-span-1 col-span-1" }}
                          >
                            <h3 className="text-left">
                              Intérêt <span className="required">*</span>
                            </h3>
                          </InputComponent>
                          {index > 0 ? (
                            <RemoveFieldArrayComponent
                              remove={remove}
                              index={index}
                            />
                          ) : null}
                        </div>
                      ))}
                    </div>
                    <PrimaryButton
                      className=" mx-auto mb-2"
                      onClick={() =>
                        append({
                          date: new Date().toJSON()?.split("T")[0],
                          value: 0,
                          fondsId: watch("fondsId")!,
                          // projectId: project.current?.id!,
                        })
                      }
                    >
                      Ajouter
                    </PrimaryButton>
                  </div>
                )}
              </FieldArray>
            </div> */}

                            <div className=" grid md:grid-cols-2 gap-x-2 place-content-center">
                                <CheckboxComponent
                                    register={register}
                                    watch={watch}
                                    values={[
                                        {
                                            name: 'compoundInterestBool',
                                            label: 'Intérêts capitalisés',
                                        },
                                    ]}
                                >
                                    <LabelComponentForm>
                                        Intérêts capitalisés ?
                                    </LabelComponentForm>
                                </CheckboxComponent>
                                {watch('compoundInterestBool') ? (
                                    <div className=" md:col-span-2">
                                        <InputComponent
                                            register={register}
                                            value={'compoundInterest'}
                                            type={'number'}
                                        >
                                            <LabelComponentForm>
                                                Intérêts composés (sur la base
                                                de chaque multiple de x jours)
                                                insérer x{' '}
                                                <span className="required">
                                                    *
                                                </span>
                                            </LabelComponentForm>
                                        </InputComponent>
                                    </div>
                                ) : null}
                            </div>

                            <SelectComponent
                                register={register}
                                value={'payment'}
                                // control={control}
                                control={control}
                                container={true}
                                optionValues={[
                                    TypePayement.infine,
                                    TypePayement.annual,
                                    TypePayement.monthly,
                                    TypePayement.semester,
                                    TypePayement.trimester,
                                ].map((val) => {
                                    return { value: val, label: val };
                                })}
                            >
                                <LabelComponentForm>
                                    Distribution{' '}
                                    <span className="required">*</span>
                                </LabelComponentForm>
                            </SelectComponent>

                            <InputComponent
                                register={register}
                                value={'projectInfo.capital'}
                                type={'number'}
                                required={false}
                            >
                                <LabelComponentForm>
                                    Amortisation
                                </LabelComponentForm>
                            </InputComponent>

                            <InputComponent
                                register={register}
                                value={'dateStart'}
                                type={'date'}
                            >
                                <LabelComponentForm>
                                    Date de souscription{' '}
                                    <span className="required">*</span>
                                </LabelComponentForm>
                            </InputComponent>

                            <InputComponent
                                register={register}
                                value={'duration'}
                                type={'number'}
                            >
                                <LabelComponentForm>
                                    Durée de l'investissement (mois)
                                    <span className="required">*</span>
                                </LabelComponentForm>
                            </InputComponent>

                            <InputComponent
                                register={register}
                                value={'delayAccepted'}
                                type={'number'}
                            >
                                <LabelComponentForm>
                                    Retard contractuel (mois)
                                    <span className="required">*</span>
                                </LabelComponentForm>
                            </InputComponent>

                            <div className=" md:col-span-2">
                                <LabelComponentForm className=" text-center">
                                    Niveau de séniorité{' '}
                                    <span className="required">*</span>
                                </LabelComponentForm>
                                <CheckboxArrayComponent
                                    register={register}
                                    name={'projectInfo.seniorityLevel'}
                                    className={{
                                        container:
                                            'grid md:grid-cols-2 lg:grid-cols-3',
                                    }}
                                    values={
                                        seniorityLevel
                                            ? seniorityLevel.map((v) => {
                                                  return {
                                                      label: v.value,
                                                      value: v.id,
                                                  };
                                              })
                                            : []
                                    }
                                    watch={watch}
                                    control={control}
                                ></CheckboxArrayComponent>
                            </div>
                        </div>

                        <div className="mt-5 self-center mx-auto flex gap-3 text-center w-full max-w-lg">
                            <PrimaryButton
                                type="submit"
                                className="w-full"
                                loading={isLoadingEditProject}
                            >
                                Edit
                            </PrimaryButton>
                        </div>
                    </form>
                </>
            )}
        </>
    );
}
