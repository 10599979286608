import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { KnowledgeQuestionDto } from "../../types/KnowledgeDto";
import { baseQueryHandleError } from '../index.rtk';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}knowledge`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export const knowledgeApi = createApi({
    reducerPath: 'knowledgeApi',
    baseQuery: baseQueryHandleError(baseQuery),
    endpoints: (builder) => ({
        getQuestions: builder.query<KnowledgeQuestionDto[], void>({
            query: () => `/questions`,
        }),
        // addQuestion: builder.mutation<KnowledgeQuestionDto, CreateKnowledgeQuestionDto>({
        //     query: (payload) => ({
        //         url: `/questions`,
        //         method: 'POST',
        //         body: payload,
        //     }),
        // }),
        // deleteQuestion: builder.mutation<KnowledgeQuestionDto, number>({
        //     query: (id) => ({
        //         url: `/questions/${id}`,
        //         method: 'DELETE',
        //     }),
        // }),
        // editQuestion: builder.mutation<KnowledgeQuestionDto, UpdateKnowledgeQuestionDto>({
        //     query: (payload) => ({
        //         url: `/questions/${payload.id}`,
        //         method: 'PUT',
        //         body: payload,
        //     }),
        // }),
    }),
});

export const { useGetQuestionsQuery } = knowledgeApi;
