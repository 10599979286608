import React, { useEffect, useState } from "react";
import Login from "./Login";
import Registration from "./Registration";
import { MessageCompteCreated } from "../../pages/Seconnecter";

interface Props {
  path: string;
}

const LoginRegister: React.FC<Props> = ({ path }) => {
  const [register, setRegister] = useState<boolean>(false);
  const [openMessageCreationCompte, setOpenMessageCreattionCompte] =
    useState(false);

  useEffect(() => {
    let test = document.querySelector(".choice_container") as HTMLElement;
    let p = test.querySelectorAll("p");
    if (register) {
      p[0].style.backgroundColor = "white";
      p[0].style.zIndex = "0";

      p[1].style.backgroundColor = "rgb(241, 240, 239)";
      p[1].style.zIndex = "10";
    } else {
      p[1].style.backgroundColor = "white";
      p[1].style.zIndex = "0";
      p[0].style.zIndex = "10";
      p[0].style.backgroundColor = "rgb(241, 240, 239)";
    }
  }, [register]);

  return (
    <React.Fragment>
      {openMessageCreationCompte ? (
        <MessageCompteCreated
          open={openMessageCreationCompte}
          setOpen={setOpenMessageCreattionCompte}
        />
      ) : null}
      <div className="connexion_container">
        <div className="choice_container">
          <p
            className="pointer"
            onClick={() => setRegister(false)}
            // onMouseOver={() => setRegister(false)}
          >
            Se connecter
          </p>
          <p
            className="pointer"
            onClick={() => setRegister(true)}
            // onMouseOver={() => setRegister(true)}
          >
            S'inscrire
          </p>
        </div>
        <div className="connexion relative">
          {register ? (
            <Registration
              setRegisterState={setRegister}
              setOpenMessageCreattionCompte={setOpenMessageCreattionCompte}
            />
          ) : (
            <Login path={path} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginRegister;
