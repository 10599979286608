import React, { useContext, useMemo, useState } from "react";
import {
  formatDate,
  printLargeValue,
  setDate,
} from "../../../../function/Utils";
import { useAppSelector } from "../../../../redux/store";
import {
  BackOfficeState,
  RootBlackOfficeState,
} from "../../../Context/AppContext";
import { useGetOverViewAdminInfoQuery } from "../../../../redux/features/shareApiSlice";

interface moneyAvailableInterface {
  fondsName: string;
  amount: number;
  diffBl: number;
  diffNotBl: number;
}

const Benefices = () => {
  const backOfficeState = useContext(BackOfficeState);

  // const [toggleSeeDetails, setToggleSeeDetails] = useState<boolean>(false);

  const rootState = useContext(RootBlackOfficeState);

  const { data: ovierViewAdminInfo, isLoading } = useGetOverViewAdminInfoQuery(
    {
      date: setDate(
        backOfficeState?.year!,
        backOfficeState?.month!,
        backOfficeState?.day ?? 0
      ),
      applyFees: rootState?.applyFeesBool ?? true,
    },
    {
      skip:
        !backOfficeState?.year ||
        backOfficeState?.month === undefined ||
        !backOfficeState?.day ||
        rootState?.applyFeesBool === undefined,
    }
  );

  return (
    <React.Fragment>
      {!!(
        ovierViewAdminInfo &&
        backOfficeState !== undefined &&
        backOfficeState?.year !== undefined &&
        backOfficeState?.month !== undefined
      ) && (
        <React.Fragment>
          <div className="w-max relative text-center min-w-[200px] min-h-[100px] max-h-fit py-4 px-3 rounded-lg mx-auto bg-secondBackgroundColor flex flex-col justify-center items-center">
            {/* <div
              className="absolute cursor-pointer top-0 right-0 bg-white rounded-md"
              onClick={() => setToggleSeeDetails(!toggleSeeDetails)}
            >
              <p className="px-2 py-1">See details</p>
            </div> */}
            <p className="font-titleFontFamily text-titleColor font-semibold text-xl">
              {printLargeValue(
                ovierViewAdminInfo.beneficeSinceStartOfYear.toFixed(2)
              )}{" "}
              €
            </p>

            <p>
              Bénéfice depuis {formatDate(setDate(backOfficeState.year, 0, 1))}
            </p>
          </div>
          <React.Fragment>
            {ovierViewAdminInfo.fondsInfo?.map((val, key) => (
              <div
                key={key}
                className="w-max  text-center min-w-[200px] min-h-[100px] max-h-fit py-4 px-3 rounded-lg mx-auto bg-secondBackgroundColor flex flex-col justify-center items-center"
              >
                <p className="text-center font-titleFontFamily text-titleColor font-semibold text-xl">
                  {printLargeValue(val.retirable.toFixed(2))} €
                </p>
                {/* {toggleSeeDetails ? (
                  <>
                    <p>Difference not black list </p>
                    <p>{val.differenceNotBlackList}</p>
                    <p> Difference in black list </p>
                    <p>{val.differenceBlackList}</p>
                  </>
                ) : null} */}
                <p>
                  Retirable par New Paradigms <br />
                  dans le fonds{" "}
                  <span className="text-mainColor font-semibold">
                    {val.fondsName}
                  </span>
                </p>
              </div>
            ))}
          </React.Fragment>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Benefices;
