import React, { ReactNode, useCallback, useEffect } from "react";
import {
  FieldErrorsImpl,
  FieldValues,
  Path,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { StringParam, useQueryParam } from "use-query-params";
import { debounce } from "../../../function/Utils";

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>;
  watch?: UseFormWatch<T>;
  errors?: FieldErrorsImpl<T>;
  componentEnd?: () => JSX.Element;
  className?: {
    input?: string;
  };
  value: Path<T>;
  required?: false;
  cursorNotAllowed?: true;
  setValue?: UseFormSetValue<T>;
  useParamBoolean?: boolean;
  rows?: number;
  getValues?: UseFormGetValues<T>;
}

const TextAreaComponent = <T extends object>(
  props: Props<T> & { children?: ReactNode }
) => {
  let [queryParams, setQueryParam] = useQueryParam(props.value, StringParam);

  useEffect(() => {
    if (props.useParamBoolean && props.getValues) {
      updateDateEnd();
    }
  }, [props.watch && props.watch(props.value)]);

  const updateDateEnd = useCallback(
    debounce(() => {
      if (props.useParamBoolean && props.getValues) {
        setQueryParam(props.getValues(props.value) as any);
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (props.useParamBoolean) {
      if (queryParams && props.setValue) {
        props.setValue(props.value, queryParams as any);
      }
    }
  }, [queryParams]);

  const jsxFunction = (): JSX.Element => {
    return (
      <textarea
        rows={props?.rows ?? 3}
        {...props.register(props.value, {
          required: props?.required === false ? false : true,
        })}
        className={`form-control w-full ${
          props?.cursorNotAllowed != undefined &&
          "pointer-events-none bg-gray-300 opacity-60"
        } ${props?.className != undefined && props.className.input} ${
          props.errors &&
          typeof props.value === "string" &&
          props.value in props.errors &&
          props.errors[props.value as unknown as keyof T]
            ? "is-invalid"
            : ""
        }`}
        required={props?.required === false ? false : true}
      />
    );
  };
  return (
    <div className="form-item">
      {props.children}
      {props.cursorNotAllowed ? (
        <div className="cursor-not-allowed w-full">{jsxFunction()}</div>
      ) : (
        jsxFunction()
      )}
    </div>
  );
};

export default TextAreaComponent;
