import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuDashBoardUserBoolState } from '../../Context/AppContext';
import Step2Component, { IStep2 } from '../../commun/Step2Component';
import WrapComponentDashboardUser from '../commun/WrapComponentDashboardUser';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumberParam, useQueryParam } from 'use-query-params';
import { formatDate, printLargeValue } from '../../../function/Utils';
import { useFetchTokenQuery } from '../../../redux/features/tokenSlice';
import {
    useDeleteSubscriptionUserMutation,
    useGetAllSubscriptionsUserQuery,
    useGetUserWithAllInfoForCustomerQuery,
} from '../../../redux/features/userSlice';
import {
    SubscriptionUserFondsDto,
    SubscriptionUserFondsStatus,
} from '../../../types/SubscriptionUserFondsDto';
import {
    StatusUserDto,
    StatusUserTypeEnum,
    UserStatusType,
} from '../../../types/user';
import {
    ErrorButton,
    OrangeButton,
    PrimaryButton,
    SuccessButton,
    WhiteButton,
} from '../../commun/Buttons';
import useDeleteItem from '../../commun/CustomHook/useDeleteItem';
import { DropDownComponentUser } from '../../commun/DropDownComponent';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import EditSubscription from './EditSubscription';
import EtapeInvestAccount, {
    TitreInvestirComponent,
} from './EtapeInvestAccount';
import InvestmentInfo from './InvestmentInfo';
import PickFunds from './PickFunds';

const Investir = () => {
    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();

    const { data: subscriptions, isLoading: isLoadingSubscriptoin } =
        useGetAllSubscriptionsUserQuery(user?.id ?? 0, {
            skip: user === undefined,
        });

    const subscriptionsPending =
        subscriptions?.filter(
            (s) => s.status === SubscriptionUserFondsStatus.PENDING
        ) || [];

    const subscriptionsValidated =
        subscriptions?.filter(
            (s) => s.status === SubscriptionUserFondsStatus.ACCEPTED
        ) || [];

    const [stepInvestir, setStepInvestir] = useQueryParam(
        'stepInvest',
        NumberParam
    );

    const navigate = useNavigate();

    const [investBool, setInvestBool] = useState(false);
    const [historiqueSub, setHistoriqueSub] = useState(true);

    return (
        <div
            className={`mx-auto  md:mt-0 lg:px-2 md:pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto `}
        >
            <WrapComponentDashboardUser
                title="Investir"
                description=""
                backButton={stepInvestir !== undefined ? true : false}
                textBackButton={
                    stepInvestir !== undefined ? 'Mes souscriptions' : undefined
                }
                classNameHeader={`${!menuBoolState?.toggleMenu ? '!pl-[40px]' : ''}`}
                classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
                // subTitle={
                //   stepInvestir !== undefined ? (
                //     <div className="flex items-center justify-end gap-2">
                //       <Link to="/DashBoardClient/investir">
                //         <p className="text-mainColor text-lg">Mes souscriptions</p>
                //       </Link>
                //       <FontAwesomeIcon icon={faArrowRight} className="text-mainColor" />
                //     </div>
                //   ) : null
                // }
            >
                {/* {import.meta.env.VITE_API_URL === "https://newparadigms.fr/api/" ? (
          <div className="flex justify-center overflow-auto text-center w-11/12 mx-auto flex-col gap-2 items-center h-[70vh]">
            <p>Cette page est en construction</p>
            <p>Si vous voulez investir envoyez nous un message</p>
            <PrimaryButton onClick={() => navigate("/Contact")}>

              Nous contacter
            </PrimaryButton>
          </div>
        ) : ( */}
                <div className=" overflow-auto h-[87vh] md:h-[87vh] scrolltop">
                    {isLoadingSubscriptoin || loadingUser ? (
                        <Loading />
                    ) : (
                        <div className="w-11/12 md:w-11/12 mx-auto">
                            {user?.status === UserStatusType.newUser ||
                            user?.status ===
                                UserStatusType.informationNeedToBeChecked ||
                            user?.status ===
                                UserStatusType.profilUnValitaded ? (
                                <div className="mt-4 mb-3">
                                    Vous devez d'abord valider vos données
                                    personnelles afin de valider votre profil.
                                    Vous pourrez ensuite créer un nombre
                                    illimité de comptes d'investissement.
                                    <div className="flex items-center justify-center">
                                        <PrimaryButton
                                            onClick={() =>
                                                navigate(
                                                    '/DashBoardClient/donnees-personnelles'
                                                )
                                            }
                                        >
                                            Valider mes données personnelles
                                        </PrimaryButton>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {stepInvestir === -1 ||
                                    stepInvestir === undefined ? (
                                        <div className="">
                                            <div className="w-full my-5 flex justify-center items-center">
                                                <PrimaryButton
                                                    onClick={() => {
                                                        setStepInvestir(0);
                                                        setInvestBool(true);
                                                    }}
                                                >
                                                    Investir
                                                </PrimaryButton>
                                            </div>
                                            <TitreInvestirComponent>
                                                Vos souscriptions en cours
                                            </TitreInvestirComponent>
                                            {subscriptionsPending?.length ===
                                            0 ? (
                                                <div className="p-3 text-center w-full md:w-11/12 mx-2">
                                                    <p className="text-lg">
                                                        Vous n'avez pas de
                                                        souscription en cours
                                                    </p>
                                                </div>
                                            ) : (
                                                subscriptionsPending.map(
                                                    (s) => (
                                                        <div
                                                            key={s.id}
                                                            className="flex mt-3 flex-col gap-4"
                                                        >
                                                            <DisplaySubscription
                                                                subscription={s}
                                                            />
                                                        </div>
                                                    )
                                                )
                                            )}
                                            <DropDownComponentUser
                                                title={`Vos souscriptions validées`}
                                                bool={historiqueSub}
                                                setBool={setHistoriqueSub}
                                            >
                                                {subscriptionsValidated?.length ===
                                                0 ? (
                                                    <div className="p-3 text-center w-full md:w-11/12 mx-2">
                                                        <p className="text-lg">
                                                            Vous n'avez pas de
                                                            souscription validée
                                                        </p>
                                                    </div>
                                                ) : (
                                                    subscriptionsValidated.map(
                                                        (s) => (
                                                            <div
                                                                key={s.id}
                                                                className="flex mt-3 flex-col gap-4"
                                                            >
                                                                <DisplaySubscription
                                                                    subscription={
                                                                        s
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    )
                                                )}
                                            </DropDownComponentUser>
                                        </div>
                                    ) : (
                                        <>
                                            <FormInvest />
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
                {/* )} */}
            </WrapComponentDashboardUser>
        </div>
    );
};

function DisplaySubscription({
    subscription,
}: {
    subscription: SubscriptionUserFondsDto;
}) {
    const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();

    const navigate = useNavigate();

    const { data: user, isLoading: isLoadingUserAll } =
        useGetUserWithAllInfoForCustomerQuery(userData?.id!, {
            skip: !userData?.id,
        });

    const statusUser = user?.statusUser?.find(
        (status) =>
            status.url?.split('subscriptionId=')[1] &&
            parseInt(status.url?.split('subscriptionId=')[1]) ===
                subscription.id
    );

    const [deleteSubscription, { isLoading: isLoadingDelete }] =
        useDeleteSubscriptionUserMutation();

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        typeTransactionId: number;
    }>({
        deleteItem: () =>
            deleteSubscription({
                userId: userData?.id!,
                subscriptionId: subscription.id,
            }),
        message: 'cette souscription',
        isLoading: isLoadingDelete,
    });
    return (
        <div className="p-3 bg-gray-100 rounded-md w-full md:w-11/12 mx-2">
            {statusUser?.type === StatusUserTypeEnum.ToDo ? (
                <DeleteComponent title="cette souscription" />
            ) : null}

            <div className="flex flex-col mb-4 mb:mb-2 gap-2 justify-center items-center md:justify-normal md:grid md:grid-cols-[auto,auto]">
                <p className="text-lg">
                    Souscription du{' '}
                    <span className=" text-mainColor font-semibold">
                        {formatDate(new Date(subscription.date))}
                    </span>
                    {subscription?.dateDone ? (
                        <>
                            {' '}
                            - Date de validation:{' '}
                            <span className=" text-mainColor font-semibold">
                                {formatDate(new Date(subscription?.dateDone))}
                            </span>
                        </>
                    ) : null}
                </p>
                <div className=" md:justify-self-end">
                    <DisplaySubscriptionStatus
                        status={statusUser}
                        statusSub={subscription.status}
                    />
                </div>
            </div>
            <div className="grid md:grid-cols-2">
                <div className="">
                    <div className="grid text-xl font-semibold text-mainColor grid-cols-3">
                        <p>Fonds</p>
                        <p>Titre</p>
                        <p>Montant</p>
                    </div>
                    {subscription.transactions.map((t) => (
                        <div className="grid grid-cols-3 text-lg ">
                            <p className="">{t?.fondsName}</p>
                            <p>
                                {t.typeShare == 'distribution'
                                    ? 'Titre D'
                                    : 'Titre C'}
                            </p>
                            <p>{printLargeValue(t.amount)} €</p>
                        </div>
                    ))}
                </div>
                <div className="flex mt-3 md:mt-0 justify-center items-center gap-3">
                    {statusUser?.type === StatusUserTypeEnum.ToDo ? (
                        <WhiteButton onClick={() => setOpenDelete(true)}>
                            Annuler
                        </WhiteButton>
                    ) : null}
                    <PrimaryButton
                        onClick={() => {
                            navigate(
                                `/DashBoardClient/investir?stepInvest=2&subscriptionId=${subscription.id}`
                            );
                        }}
                    >
                        <>
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className="text-white mr-3"
                            />
                            {statusUser?.type === StatusUserTypeEnum.ToDo
                                ? 'Finaliser'
                                : 'Accèder'}
                        </>
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
}

export function DisplaySubscriptionStatus({
    status,
    statusSub,
}: {
    status: StatusUserDto | undefined;
    statusSub: SubscriptionUserFondsStatus;
}) {
    const display = () => {
        if (statusSub === SubscriptionUserFondsStatus.ACCEPTED) {
            return <SuccessButton>Validée</SuccessButton>;
        }
        if (status === undefined) {
            <ErrorButton>Pas de statut trouvé</ErrorButton>;
        }
        if (status?.type === StatusUserTypeEnum.ToDo) {
            return <OrangeButton>Non finalisée</OrangeButton>;
        }
        if (status?.type === StatusUserTypeEnum.InComing) {
            return (
                <SuccessButton>
                    Finalisée en attente de validation
                </SuccessButton>
            );
        }
    };
    return <>{display()}</>;
}

function FormInvest() {
    const stepsInit: IStep2[] = [
        {
            id: 'Step 1',
            name: 'Compte investisseur',
            href: '#',
            status: 'current',
        },
        {
            id: 'Step 2',
            name: 'Choix des fonds et du nombre de titres',
            href: '#',
            status: 'upcoming',
        },
        {
            id: 'Step 3',
            name: 'Signature de la souscription et paiements',
            href: '#',
            status: 'upcoming',
        },
    ];

    const [steps, setSteps] = React.useState<IStep2[]>(stepsInit);

    const [stepInvestir, setStepInvestir] = useQueryParam(
        'stepInvest',
        NumberParam
    );
    useEffect(() => {
        if (stepInvestir !== undefined && stepInvestir !== null) {
            setSteps((prev) => {
                return prev.map((stepi, index) => {
                    if (index === stepInvestir) {
                        return { ...stepi, status: 'current' };
                    } else if (index > stepInvestir) {
                        return { ...stepi, status: 'upcoming' };
                    } else {
                        return { ...stepi, status: 'complete' };
                    }
                });
            });
        }
    }, [stepInvestir]);

    return (
        <>
            <Step2Component steps={steps} className={'px-2 my-2'} />
            {stepInvestir !== undefined && stepInvestir !== null ? (
                <Form />
            ) : null}
        </>
    );
}

function Form() {
    // const {
    //   register,
    //   handleSubmit,
    //   reset,
    //   watch,
    //   setValue,
    //   control,
    //   formState: { isValid },
    // } = useForm<InvestirDto>();

    // const onSubmit = async (data: InvestirDto) => {};

    const [step, setStep] = useQueryParam('stepInvest', NumberParam);
    const [toogleInvestInfo, setToogleInvestInfo] = useState(false);

    const [subscriptionId, setSubscriptionId] = useQueryParam(
        'subscriptionId',
        NumberParam
    );

    const [investAccountIdQuery, setInvestAccountIdQuery] = useQueryParam(
        'investAccountId',
        NumberParam
    );

    useEffect(() => {
        // Scroll to the top of the page
        const elementDashBoard = document.querySelector(
            '.scrolltop'
        ) as HTMLElement;
        elementDashBoard.scrollTo(0, 0);
    }, [step]);

    return (
        <div className="form_investisseur_container">
            <div
                className={`w-full px-2 mx-auto flex flex-col
       
    `}
            >
                {toogleInvestInfo ? (
                    <PopUp
                        open={toogleInvestInfo}
                        setOpen={setToogleInvestInfo}
                        buttonBoolean={false}
                        disabledOnclose={true}
                        width="w-full md:w-10/12"
                    >
                        <InvestmentInfo
                            setToogleInvestInfo={setToogleInvestInfo}
                            toogleInvestInfo={toogleInvestInfo}
                        />
                    </PopUp>
                ) : null}
                {step === 0 ? (
                    <EtapeInvestAccount
                        setToogleInvestInfo={setToogleInvestInfo}
                        toogleInvestInfo={toogleInvestInfo}
                    />
                ) : null}
                {investAccountIdQuery ? (
                    <>{step === 1 ? <PickFunds /> : null}</>
                ) : null}
                {subscriptionId && step === 2 ? (
                    <EditSubscription subscriptionId={subscriptionId} />
                ) : null}
            </div>
        </div>
    );
}

export default Investir;

function OngoingSubscription({}: {}) {}
