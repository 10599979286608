import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import CreatePasswordSecurity from "./CreatePasswordSecurity";
import UpdatePasswordSecurity from "./UpdatePasswordSecurity";
import logo from "./../../../assets/logo.png";
import { SecurityDto } from "../../../types/Security";
import { PrimaryButton } from "../../commun/Buttons";

const LoginDashBoardAdmin = () => {
  const [content, setContent] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [viewPassword, setViewPassword] = useState<boolean>(false);

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      name: "updateBackOffice",
      content: content,
    };
    await axios({
      method: "post",
      url: `${import.meta.env.VITE_API_URL}securities/allowUpdate`,
      withCredentials: true,
      data: data,
    })
      .then((value) => {
        console.log(value.data);
        setMessage(value.data.message);

        if (value.data.message === "success") {
          window.location.href = "/DashBoardAdmin/BackOffice";
        }
        setContent("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center mx-auto">
      <div className="md:-mt-24">
        <Link to="/" className="my-8 h-14 w-14 block mx-auto">
          <img src={logo} alt="logo Newparadigms" />
        </Link>
        <form
          className=" formTemplateSingUp p-5 flex flex-col justify-center items-center"
          onSubmit={(e) => login(e)}
        >
          <div
            className={`${
              message === "success" ? "text-green-600" : "text-red-600"
            }`}
          >
            {message}
          </div>
          <div className="form-item">
            <label>Password:</label>
            <div className="relative w-full">
              <input
                type={viewPassword ? "text" : "password"}
                onChange={(event) => {
                  setContent(event.target.value);
                }}
                name="password"
                className="form-control border border-grey"
              />
              <FontAwesomeIcon
                icon={viewPassword ? faEye : faEyeSlash}
                className="absolute -right-8 top-0 bottom-0 my-auto cursor-pointer"
                onClick={() => setViewPassword(!viewPassword)}
              />
            </div>
          </div>
          <div className="w-fit mt-2 justify-center items-center mx-auto">
            <PrimaryButton className="" type="submit">
              Se connecter
            </PrimaryButton>
          </div>
        </form>
        {/* <CreatePasswordSecurity /> */}
      </div>
    </div>
  );
};

export default LoginDashBoardAdmin;
