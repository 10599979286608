import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { printLargeValue } from '../../../function/Utils';
import { useGetUserWithAllInfoForCustomerQuery } from '../../../redux/features/userSlice';
import { CategoryQuestion } from '../../../types/KnowledgeDto';
import { PrimaryButton } from '../../commun/Buttons';
import AreaChart, { ISeries } from '../../commun/Charts/AreaChart';
import useDebouncedEffect from '../../commun/CustomHook/useDebouncedEffect';
import PopUp from '../../commun/PopUp';
import { NumericInput } from '../../commun/formComponent/InputComponent';

interface Props {
    userId: number;
}

interface Form {
    charge: number;
    invest: number;
    yearlyInvest: number;
    time: number;
    interest: number;
}

const AssetSimulator: React.FC<Props> = ({ userId }) => {
    const { data: user, isLoading: loading } =
        useGetUserWithAllInfoForCustomerQuery(userId);

    const simulatorAssets = useMemo(() => {
        if (user?.knowledgeUsers) {
            return user?.knowledgeUsers?.filter(
                (k) => k.question.category === CategoryQuestion.assets
            );
        } else {
            return [];
        }
    }, [user]);

    const formState = useForm<Form>({
        defaultValues: {
            charge: 0,
            invest: 0,
            yearlyInvest: 0,
            time: 20,
            interest: 8,
        },
    });

    useEffect(() => {
        if (simulatorAssets) {
            simulatorAssets?.forEach((k) => {
                const answer = parseFloat(k.answers?.[0].answer) || 0;
                if (k.question.order === 1) {
                    formState.setValue('yearlyInvest', answer);
                }
                if (k.question.order === 2) {
                    formState.setValue('invest', answer);
                }
                if (k.question.order === 3) {
                    formState.setValue('charge', answer);
                }
            });

            formState.setValue(
                'yearlyInvest',
                Math.max(
                    formState.getValues('yearlyInvest') -
                        formState.getValues('charge'),
                    0
                )
            );
            formState.setValue('invest', formState.getValues('invest'));
        }
    }, [simulatorAssets]);

    const available = useMemo(() => {
        return (
            parseFloat(formState.getValues('invest') as any) +
            parseFloat(formState.getValues('yearlyInvest') as any)
        );
    }, [formState.watch('invest'), formState.watch('yearlyInvest')]);

    // compute valorisation over time years if invest - charge is initialy invest and
    // every year yearlyInvest - charge is invested
    // the yearly interest is interest

    const [debouncedSeries, setDebouncedSeries] = useState<ISeries[]>([]);

    useDebouncedEffect(
        () => {
            const newSeries = computeSeries();
            setDebouncedSeries(newSeries);
        },
        [
            available,
            formState.watch('time'),
            formState.watch('interest'),
            formState.watch('yearlyInvest'),
        ],
        800
    );

    const computeSeries = (): ISeries[] => {
        let total =
            available - parseFloat(formState.getValues('yearlyInvest') as any);
        let date = new Date();
        const data = [
            {
                x: new Date(date.getFullYear(), date.getMonth(), 1).getTime(),
                y: total,
            },
        ];
        for (
            let i = 0;
            i < parseFloat((formState.getValues('time') || 1) as any);
            i++
        ) {
            total =
                total *
                    (1 +
                        parseFloat(formState.getValues('interest') as any) /
                            100) +
                parseFloat(formState.getValues('yearlyInvest') as any);
            date = new Date(date.getFullYear() + 1, date.getMonth(), 1);
            data.push({
                x: date.getTime(),
                y: total,
            });
        }
        return [
            {
                name: 'Valorisation €',
                data,
            },
        ];
    };
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            {open ? <PopUpUpdateInfo open={open} setOpen={setOpen} /> : null}
            <h3 className="!text-center text-mainColor font-mainFontFamily !text-2xl w-full mb-3">
                Simulateur d'investissement
            </h3>
            <div className="flex w-full items-center justify-center">
                <PrimaryButton
                    onClick={() =>
                        navigate(
                            '/DashBoardClient/donnees-personnelles?pageDonneePerso=questions&pageQuestion=assets'
                        )
                    }
                >
                    Mettre à jour mes informations
                </PrimaryButton>
            </div>

            <p className="w-full text-center mx-auto my-3">
                Votre patrimoine financier:{' '}
                <span className=" text-mainColor">
                    {printLargeValue(available)} €
                </span>
            </p>

            <div className="w-full md:w-11/12 mx-auto px-3 formTemplateSingUp">
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2 px-2">
                    <NumericInput
                        name={'time'}
                        max={100}
                        min={1}
                        formState={formState}
                    >
                        <label htmlFor="time">Horizon de temps (années)</label>
                    </NumericInput>
                    <NumericInput name={'interest'} formState={formState}>
                        <label htmlFor="interest">
                            Taux d'intérêt annuel (%)
                        </label>
                    </NumericInput>
                    <NumericInput name={'invest'} formState={formState}>
                        <label htmlFor="invest">
                            Investissement initial (€)
                        </label>
                    </NumericInput>
                    {/* <NumericInput
          name={"charge"}
          
        >
          <label htmlFor="charge">Frais annuels (€)</label>
        </NumericInput> */}

                    <NumericInput name={'yearlyInvest'} formState={formState}>
                        <label htmlFor="yearlyInvest">
                            Investissement annuel (€)
                        </label>
                    </NumericInput>
                </div>
                <div className="mt-4 w-full">
                    <AreaChart series={debouncedSeries} />
                    <p className=" italic text-justify px-2">
                        La présente projection financière, incluant toute
                        information relative au patrimoine partagé, est fournie
                        à titre indicatif uniquement et ne constitue en aucun
                        cas un conseil financier ou un conseil d'investissement.
                        Cette projection est basée sur des hypothèses générales
                        et ne tient pas compte de vos besoins spécifiques, de
                        vos contraintes de liquidité ou de tout autre facteur
                        particulier non divulgué
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AssetSimulator;

function PopUpUpdateInfo({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const navigate = useNavigate();

    return (
        <PopUp open={open} setOpen={setOpen} buttonBoolean={false}>
            <div>
                <p>
                    Vos n'avez pas encore renseigné vos informations sur votre
                    patrimoine
                </p>

                <div className="flex w-full items-center justify-center mt-4">
                    <PrimaryButton
                        onClick={() =>
                            navigate(
                                '/DashBoardClient/donnees-personnelles?pageDonneePerso=questions&pageQuestion=assets'
                            )
                        }
                    >
                        Mettre à jour mes informations
                    </PrimaryButton>
                </div>
            </div>
        </PopUp>
    );
}
