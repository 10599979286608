import React, { useEffect } from "react";
import { useNotificationContext } from "../../Context/notification-context";
import { useAddTypeInvestisseurMutation } from "../../../redux/features/typeInvestisseurSlice";
import { useGetFondsQuery } from "../../../redux/features/fondsSlice";
import { CreateTypeInvestisseurDto } from "../../../types/typeInvestisseurTypes";
import { useForm } from "react-hook-form";
import { PrimaryButton, WhiteButton } from "../../commun/Buttons";
import Loading from "../../commun/Loading";
import PopUp from "../../commun/PopUp";
import FieldArray from "../../commun/formComponent/FieldArray";
import InputComponent from "../../commun/formComponent/InputComponent";
import RemoveFieldArrayComponent from "../../commun/formComponent/RemoveFieldArrayComponent";
import SelectComponent from "../../commun/formComponent/SelectComponent";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddTypeInvestisseur: React.FC<Props> = ({ open, setOpen }) => {
  const { showError, showSuccess } = useNotificationContext();

  const { data: fonds } = useGetFondsQuery();

  const [addTypeInvestisseur, { isLoading: isLoadingAdd }] =
    useAddTypeInvestisseurMutation();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isValid },
  } = useForm<CreateTypeInvestisseurDto>();

  const updateData = () => {
    reset({
      operationFees: fonds?.map((f) => {
        return {
          fondsId: f.id,
          operationFee: 0.002,
          date: new Date().toJSON()?.split("T")[0] as any,
        };
      }),
    });
  };

  useEffect(() => {
    updateData();
  }, [fonds]);

  const onSubmit = async (data: CreateTypeInvestisseurDto) => {
    data = {
      ...data,
      operationFees: data?.operationFees?.map((o) => {
        return {
          ...o,
          date: new Date(o.date),
        };
      }),
    };
    await addTypeInvestisseur(data)
      .unwrap()
      .then((e) => {
        showSuccess("Created", "Type investisseur created");
        setOpen(false);
      })
      .catch((e) => {
        showError("Error", e.data?.message);
      });
  };

  return (
    <PopUp
      title={() => {
        return (
          <>
            <h3 className="text-2xl font-semibold leading-6 text-gray-900">
              Add type investisseur
            </h3>
          </>
        );
      }}
      open={true}
      setOpen={setOpen}
      buttonBoolean={false}
      width="md:w-2/3"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="formTemplateSingUp">
        <div className="w-11/12 md:w-[400px] mx-auto mb-3">
          <InputComponent value={"name"} register={register}>
            <p className="font-semibold text-md">Name</p>
          </InputComponent>
        </div>
        <div className="grid font-semibold text-md grid-cols-[1fr,1fr,1fr,auto] w-full">
          <p>Date</p>
          <p>Operation fee</p>
          <p>Fonds</p>
          <p></p>
        </div>
        <FieldArray control={control} className="w-full" name="operationFees">
          {({ fields, append, remove }) => (
            <>
              <div className="flex flex-col w-full mb-2">
                {fields.map((field, index) => (
                  <div
                    key={field.id}
                    className={`grid grid-cols-[1fr,1fr,1fr,auto] w-full gap-x-2 relative`}
                  >
                    <InputComponent
                      register={register}
                      type="date"
                      value={`operationFees.${index}.date`}

                      // className={{ input: "row-span-1 col-span-1" }}
                    ></InputComponent>

                    <InputComponent
                      register={register}
                      type="number"
                      required={false}
                      value={`operationFees.${index}.operationFee`}
                      // className={{ input: "row-span-1 col-span-1" }}
                    ></InputComponent>

                    <SelectComponent
                      register={register}
                      value={`operationFees.${index}.fondsId`}
                      container={true}
                      optionValues={
                        fonds?.map((val) => {
                          return {
                            value: val.id,
                            label: val.name,
                          };
                        }) ?? []
                      }
                      control={control}
                    ></SelectComponent>

                    <RemoveFieldArrayComponent remove={remove} index={index} />
                  </div>
                ))}
              </div>
              <PrimaryButton
                className=" mx-auto mb-2"
                onClick={() =>
                  append({
                    date: new Date().toJSON()?.split("T")[0],
                    operationFee: 0.002,
                    fondsId: undefined,
                    // projectId: project.current?.id!,
                  } as any)
                }
              >
                Ajouter
              </PrimaryButton>
            </>
          )}
        </FieldArray>

        <div className="mt-5 w-full flex justify-center items-center gap-3 text-center">
          <WhiteButton
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </WhiteButton>
          <PrimaryButton type="submit">
            {isLoadingAdd ? <Loading size={4} /> : "Add"}
          </PrimaryButton>
        </div>
      </form>
    </PopUp>
  );
};

export default AddTypeInvestisseur;
