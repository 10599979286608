import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import {
    CreateParentCompanyDto,
    ParentCompanyDto,
    ParentCompanyWithChildsDto,
    UpdateParentCompanyDto,
} from '../../types/parentCompanyType';
import {
    AllProjectFormDataDto,
    CashBaseInfoDto,
    CreateImpactDataDto,
    CreateProjectDto,
    CreateProjectSectorDto,
    CreateProjectSubSectorDto,
    CreateReportingDto,
    DocumentProjectDto,
    GetProjectClotureRequest,
    GetProjectsOverviewUserRequest,
    GetProjectsOverviewUserResponse,
    GetProjectsValorisationRequest,
    ImpactDataDto,
    ProjectAllImpactsDto,
    ProjectDto,
    ProjectForUserDto,
    ProjectMarketTypologyDto,
    ProjectSearchDto,
    ProjectSectorDto,
    ProjectSubSectorDto,
    ProjectWithValorisationDto,
    ProportionTransactionUserInProjectDto,
    ReportingDto,
    TypeDocumentProject,
    UpdateImpactDataDto,
    UpdateIssuedProjectDto,
    UpdateProjectAllImpactsDto,
    UpdateProjectBasicInfo,
    UpdateProjectClosureDto,
    UpdateProjectStatusDto,
    UpdateProjectSubSectorDto,
    UpdateReportingDto,
    ViewProjectValorisationDto,
    ViewTableClosureDto,
} from '../../types/project';
import { ValorisationFondsByProject } from '../../types/shareApi';
import {
    CreateShareholderDto,
    ShareholderDto,
    ShareholderWithChildsDto,
    UpdateShareholderDto,
} from '../../types/shareholderType';
import { baseQueryHandleError } from '../index.rtk';

export interface UploadDocumentProjectDto {
    projectId: number;
    file: File;
    type: TypeDocumentProject;
    fileName: string;
    comment?: string;
    projectName: string;
    reportingId?: number;
}

export interface GetDocumentByType {
    projectId: number;
    type: TypeDocumentProject;
}

export interface DeleteDocument {
    tableId: number;
    documentId: number;
}

export interface UpdateProjectInterestRequestDto {
    projectId: number;
    interests: InterestUpdateDto[];
    dateOfTheChange: Date;
}

export interface InterestUpdateDto {
    date: Date;
    value: number;
    id?: number;
}

export interface GetProjectMarketTypologyWithImpact {
    ids: number[];
    projectId: number;
}
// In this file I convert my projet.ts file in features folder to use RTK Query
// need to add withCredentials: true to all queries

export interface GetMonthProjectRequest {
    fondsId: number;
}

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}projects`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export interface RequestSearchProjectDto {
    search: string;
    fondsId: number;
}

export interface RequestValoByProjectDto {
    dateStart: Date;
    dateEnd: Date;
}
export const projectApi = createApi({
    reducerPath: 'projectApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: [
        'ProjectValo',
        'Documents',
        'SearchProject',
        'Transaction',
        'Project',
        'Reporting',
        'ImpactData',
        'Sector',
        'Shareholder',
        'ParentCompany',
    ],
    endpoints: (builder) => ({
        getProjectsSearch: builder.query<
            ProjectSearchDto[],
            RequestSearchProjectDto
        >({
            query: (payload) => ({
                url: `/search`,
                method: 'GET',
                params: {
                    search: payload.search,
                    fondsId: payload.fondsId,
                },
            }),
            providesTags: ['SearchProject'],
        }),
        getProjectById: builder.query<ProjectDto, number>({
            query: (projectId) => `/getById/${projectId}`,
            providesTags: ['Project'],
            transformResponse: (response: ProjectDto) => {
                return {
                    ...response,
                    dateEnd: new Date(response.dateEnd),
                    dateStart: new Date(response.dateStart),
                    dateInterest: response.dateInterest
                        ? new Date(response.dateInterest)
                        : undefined,
                    dateBlackList: response.dateBlackList
                        ? new Date(response.dateBlackList)
                        : undefined,
                };
            },
        }),

        getCashIds: builder.query<CashBaseInfoDto[], void>({
            query: () => `/cashIds`,
        }),
        getProjectsClosureFilter: builder.query<
            ViewTableClosureDto,
            GetProjectClotureRequest
        >({
            query: (payload) => ({
                url: `/closure`,
                method: 'GET',
                params: payload,
            }),
            providesTags: ['ProjectValo'],
        }),

        getProjectsWithProjectValorisationByFonds: builder.query<
            ViewProjectValorisationDto,
            GetProjectsValorisationRequest
        >({
            query: (payload) => ({
                url: `/projectWithValorisations`,
                method: 'GET',
                params: payload,
            }),
            providesTags: ['ProjectValo'],
        }),
        getValorisartionByProject: builder.query<
            ValorisationFondsByProject[],
            RequestValoByProjectDto
        >({
            query: (payload) => ({
                url: `/valorisationByProject`,
                method: 'GET',
                params: payload,
            }),
        }),
        getProportionInPorjectByUserIdInFondsId: builder.query<
            ProportionTransactionUserInProjectDto[],
            { userId: number; fondsId: number }
        >({
            query: (payload) => ({
                url: `/proportionUserInProjects/${payload.userId}`,
                method: 'GET',
                params: payload,
            }),
        }),
        getProjectDocumentsWithUrl: builder.query<DocumentProjectDto[], number>(
            {
                query: (projectId) => `/documents/url/${projectId}`,
                providesTags: ['Documents'],
            }
        ),
        getDocumentByTypeByProject: builder.query<
            DocumentProjectDto[],
            GetDocumentByType
        >({
            query: (payload) => ({
                url: `/documents/type/url/${payload.projectId}`,
                method: 'GET',
                params: payload,
            }),
        }),
        getProjectDocuments: builder.query<DocumentProjectDto[], number>({
            query: (projectId) => `/documents/${projectId}`,
            providesTags: ['Documents'],
        }),
        deleteDocumentProject: builder.mutation<void, DeleteDocument>({
            query: (payload) => ({
                url: `/documents/${payload.documentId}`,
                method: 'DELETE',
            }),
            async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        projectApi.util.updateQueryData(
                            'getProjectDocuments',
                            payload.tableId,
                            (draft) => {
                                return draft.filter(
                                    (Platform) =>
                                        Platform.id !== payload.documentId
                                );
                            }
                        )
                    );
                    dispatch(
                        projectApi.util.updateQueryData(
                            'getProjectDocumentsWithUrl',
                            payload.tableId,
                            (draft) => {
                                return draft.filter(
                                    (Platform) =>
                                        Platform.id !== payload.documentId
                                );
                            }
                        )
                    );
                } catch (err) {
                    console.log(err);
                }
            },
            invalidatesTags: ['Documents'],
        }),
        addProject: builder.mutation<ProjectDto, CreateProjectDto>({
            query: (payload) => ({
                url: ``,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['ProjectValo', 'SearchProject', 'Project'],
        }),
        editProjectBasicInfo: builder.mutation<void, UpdateProjectBasicInfo>({
            query: (payload) => ({
                url: `/basicInfo/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['ProjectValo', 'SearchProject', 'Project'],
        }),
        // update project status
        editProjectStatus: builder.mutation<ProjectDto, UpdateProjectStatusDto>(
            {
                query: (payload) => ({
                    url: `/status/${payload.id}`,
                    method: 'PUT',
                    body: payload,
                }),
                invalidatesTags: ['ProjectValo', 'SearchProject', 'Project'],
            }
        ),
        // update project interest
        editProjectInterest: builder.mutation<
            ProjectDto,
            UpdateProjectInterestRequestDto
        >({
            query: (payload) => ({
                url: `/interests/${payload.projectId}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['ProjectValo', 'SearchProject', 'Project'],
        }),
        editClosureProject: builder.mutation<
            ProjectWithValorisationDto,
            UpdateProjectClosureDto
        >({
            query: (payload) => ({
                url: `/closure/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['ProjectValo', 'Project'],
        }),
        editIssuedProject: builder.mutation<
            ProjectWithValorisationDto,
            UpdateIssuedProjectDto
        >({
            query: (payload) => ({
                url: `/issued/${payload.projectId}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['ProjectValo', 'Transaction', 'Project'],
        }),
        editShowProject: builder.mutation<void, { id: number; show: boolean }>({
            query: (payload) => ({
                url: `/show/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['Project'],
        }),
        deleteProjet: builder.mutation<void, number>({
            query: (projectId) => ({
                url: `/${projectId}`,
                method: 'DELETE',
            }),

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        projectApi.util.updateQueryData(
                            'getProjectById',
                            id,
                            (draft) => {
                                return undefined;
                            }
                        )
                    );
                } catch (err) {
                    console.log(err);
                }
            },
            invalidatesTags: ['ProjectValo', 'SearchProject', 'Transaction'],
        }),
        // get month project
        getMonthProject: builder.query<
            ProjectForUserDto | null,
            GetMonthProjectRequest
        >({
            query: (payload) => ({
                url: `/monthProject/${payload.fondsId}`,
                method: 'GET',
            }),
        }),

        // Project sector
        getProjectAllInfoForFormData: builder.query<
            AllProjectFormDataDto,
            void
        >({
            query: () => `/formData`,
            providesTags: ['Sector'],
        }),
        getProjectSectors: builder.query<ProjectSectorDto[], void>({
            query: () => `/sector`,
            providesTags: ['Sector'],
        }),
        // add project sector
        addProjectSector: builder.mutation<
            ProjectSectorDto,
            CreateProjectSectorDto
        >({
            query: (payload) => ({
                url: `/sector`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Sector'],
        }),
        addProjectSubSector: builder.mutation<
            ProjectSubSectorDto,
            CreateProjectSubSectorDto
        >({
            query: (payload) => ({
                url: `/subSector`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Sector'],
        }),
        updateProjectSubSector: builder.mutation<
            ProjectSubSectorDto,
            UpdateProjectSubSectorDto
        >({
            query: (payload) => ({
                url: `/subSector`,
                method: 'PUT',
                body: payload,
            }),
        }),
        deleteProjectSubSector: builder.mutation<void, number>({
            query: (id) => ({
                url: `/subSector/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Sector'],
        }),
        getProjectMarketTypologyWithImpact: builder.query<
            ProjectMarketTypologyDto[],
            GetProjectMarketTypologyWithImpact
        >({
            query: (request) => ({
                url: `/projectMarketTypology/impact/${request.projectId}`,
                method: 'GET',
                params: request,
            }),
        }),
        getProjectImpacts: builder.query<ProjectAllImpactsDto, number>({
            query: (projectId) => `/impact/${projectId}`,
            providesTags: ['Project'],
        }),
        editProjectImpacts: builder.mutation<void, UpdateProjectAllImpactsDto>({
            query: (payload) => ({
                url: `/impact/${payload.projectId}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['Project'],
        }),
        // Reporting
        getReportingByProject: builder.query<ReportingDto[], number>({
            query: (projectId) => `/reporting/${projectId}`,
            providesTags: ['Reporting'],
            transformResponse: (response: ReportingDto[]) => {
                return response.map((reporting) => {
                    return {
                        ...reporting,
                        date: new Date(reporting.date),
                    };
                });
            },
        }),
        addReporting: builder.mutation<ReportingDto, CreateReportingDto>({
            query: (payload) => ({
                url: `/reporting`,
                method: 'POST',
                body: payload,
            }),
        }),
        updateReporting: builder.mutation<void, UpdateReportingDto>({
            query: (payload) => ({
                url: `/reporting/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
        }),
        deleteReporting: builder.mutation<void, number>({
            query: (id) => ({
                url: `/reporting/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Reporting'],
        }),
        // For user dashboard
        getOverviewProjectsUser: builder.query<
            GetProjectsOverviewUserResponse,
            GetProjectsOverviewUserRequest
        >({
            query: (payload) => ({
                url: `/overview/user/${payload.userId}`,
                method: 'GET',
                params: payload,
            }),
        }),
        getProjectByIdForUser: builder.query<ProjectForUserDto, number>({
            query: (projectId) => `/forUser/${projectId}`,
            providesTags: ['Project'],
        }),
        getNbProjectsByFondsId: builder.query<number, number>({
            query: (fondsId) => `/nbProjects/${fondsId}`,
        }),
        // Imapct data
        getImpacts: builder.query<ImpactDataDto[], void>({
            query: () => `/impactData`,
            providesTags: ['ImpactData'],
        }),
        addImpactData: builder.mutation<ImpactDataDto, CreateImpactDataDto>({
            query: (payload) => ({
                url: `/impactData`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['ImpactData'],
        }),
        editImpactData: builder.mutation<ImpactDataDto, UpdateImpactDataDto>({
            query: (payload) => ({
                url: `/impactData/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['ImpactData'],
        }),
        deleteImpactData: builder.mutation<void, number>({
            query: (id) => ({
                url: `/impactData/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ImpactData'],
        }),
        //Shareholder and parent company
        addShareholder: builder.mutation<ShareholderDto, CreateShareholderDto>({
            query: (payload) => ({
                url: `/shareholder`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Shareholder'],
        }),
        getShareholders: builder.query<ShareholderDto[], void>({
            query: () => `/shareholder/getAll`,
            providesTags: ['Shareholder'],
        }),
        getShareholderById: builder.query<ShareholderWithChildsDto, number>({
            query: (id) => `/shareholder/getById/${id}`,
            providesTags: ['Shareholder'],
        }),
        deleteShareholder: builder.mutation<void, number>({
            query: (id) => ({
                url: `/shareholder/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Shareholder'],
        }),
        updateShareholder: builder.mutation<void, UpdateShareholderDto>({
            query: (payload) => ({
                url: `/shareholder/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['Shareholder', 'ParentCompany'],
        }),
        getShareholderByName: builder.query<ShareholderDto[], string>({
            query: (name) => ({
                url: `/shareholder/getByName`,
                method: 'GET',
                params: {
                    name: name,
                },
            }),
            providesTags: ['Shareholder'],
        }),
        addParentCompany: builder.mutation<
            ParentCompanyDto,
            CreateParentCompanyDto
        >({
            query: (payload) => ({
                url: `/parentCompany`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['ParentCompany'],
        }),
        getParentCompanies: builder.query<ParentCompanyDto[], void>({
            query: () => `/parentCompany/getAll`,
            providesTags: ['ParentCompany'],
        }),
        getParentCompanyById: builder.query<ParentCompanyWithChildsDto, number>(
            {
                query: (id) => `/parentCompany/getById/${id}`,
                providesTags: ['ParentCompany'],
            }
        ),
        deleteParentCompany: builder.mutation<void, number>({
            query: (id) => ({
                url: `/parentCompany/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ParentCompany'],
        }),
        updateParentCompany: builder.mutation<void, UpdateParentCompanyDto>({
            query: (payload) => ({
                url: `/parentCompany/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['ParentCompany', 'Shareholder'],
        }),
        getParentCompanyByFilter: builder.query<
            ParentCompanyDto[],
            {
                name: string;
                kBis: string;
            }
        >({
            query: (request) => ({
                url: `/parentCompany/getByFilter`,
                method: 'GET',
                params: request,
            }),
            providesTags: ['ParentCompany'],
        }),
    }),
});

export const {
    useGetProportionInPorjectByUserIdInFondsIdQuery,
    useGetNbProjectsByFondsIdQuery,
    useGetDocumentByTypeByProjectQuery,
    useGetReportingByProjectQuery,
    useGetProjectAllInfoForFormDataQuery,
    useGetProjectMarketTypologyWithImpactQuery,
    useGetProjectByIdQuery,
    useGetCashIdsQuery,
    useGetValorisartionByProjectQuery,
    useGetProjectsClosureFilterQuery,
    useGetProjectsWithProjectValorisationByFondsQuery,
    useGetProjectDocumentsWithUrlQuery,
    useGetProjectDocumentsQuery,
    useDeleteDocumentProjectMutation,
    useAddProjectMutation,
    useEditClosureProjectMutation,
    useDeleteProjetMutation,
    useGetProjectsSearchQuery,
    useEditProjectInterestMutation,
    useEditProjectBasicInfoMutation,
    useEditIssuedProjectMutation,
    useAddReportingMutation,
    useUpdateReportingMutation,
    useDeleteReportingMutation,
    useGetProjectImpactsQuery,
    useEditProjectImpactsMutation,
    useGetOverviewProjectsUserQuery,
    useEditShowProjectMutation,
    useAddImpactDataMutation,
    useDeleteImpactDataMutation,
    useGetImpactsQuery,
    useEditImpactDataMutation,
    useGetProjectByIdForUserQuery,
    useGetMonthProjectQuery,
    useGetParentCompaniesQuery,
    useGetParentCompanyByFilterQuery,
    useGetShareholderByNameQuery,
    useGetShareholdersQuery,
    useAddShareholderMutation,
    useAddParentCompanyMutation,
    useDeleteShareholderMutation,
    useDeleteParentCompanyMutation,
    useUpdateShareholderMutation,
    useUpdateParentCompanyMutation,
    useAddProjectSectorMutation,
    useAddProjectSubSectorMutation,
    useDeleteProjectSubSectorMutation,
    useUpdateProjectSubSectorMutation,
    useGetProjectSectorsQuery,
    useGetParentCompanyByIdQuery,
    useGetShareholderByIdQuery,
    useEditProjectStatusMutation,
} = projectApi;

export async function uploadDocumentProject(payload: UploadDocumentProjectDto) {
    try {
        const res1 = await axios({
            method: 'get',
            url: `${import.meta.env.VITE_API_URL}projects/documents/url/put`,
            params: {
                fileType: encodeURIComponent(payload.file.type),
                projectName: payload.projectName,
                projectId: payload.projectId,
                type: payload.type,
            },
            withCredentials: true,
        });

        const { uploadUrl, key } = res1.data as any;

        const res2 = await axios.put(uploadUrl, payload.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}projects/documents/post`,
            data: {
                projectId: payload.projectId,
                fileName: encodeURIComponent(payload.fileName),
                type: payload.type,
                comment: payload.comment,
                reportingId: payload?.reportingId,
                key: key,
            },
            withCredentials: true,
        });

        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}
