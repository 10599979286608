import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryHandleError } from '../index.rtk';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}validationCode`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export interface ValidationCodeRequest {
    userId: number;
    code: string;
}

export const validatonCodeApi = createApi({
    reducerPath: 'validatonCodeApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: ['ValidationCode'],
    endpoints: (builder) => ({
        createValidationCode: builder.mutation<void, number>({
            query: (userId) => ({
                url: `/create/${userId}`,
                method: 'POST',
            }),
        }),
        verifyValidationCode: builder.mutation<{
            message: string;
            sucess: boolean;
        }, ValidationCodeRequest>({
            query: (payload) => ({
                url: `/verifyCode/${payload.userId}`,
                method: 'POST',
                body: payload
            }),
        }),
        verifyToken: builder.mutation<string, number>({
            query: (userId) => ({
                url: `/token/${userId}`,
                method: 'GET',
            }),
        }),
    }),
})

export const { useVerifyTokenMutation, useCreateValidationCodeMutation, useVerifyValidationCodeMutation } = validatonCodeApi;
