import React, { useState } from "react";
import pictureSVG from "./../../assets/picture.svg";
import update from "../../assets/edit.svg";
import {
  Article,
  ArticleCoverInterface,
  CategoryElementInterface,
} from "../../types/Articles";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  editArticle,
  getArticles,
  uploadCoverAuteurArticle,
} from "../../redux/features/article";

interface Props {
  article: Article;
}

const ArticleAuteur: React.FC<Props> = ({ article }) => {
  const userData = useAppSelector((state) => state.userReducer.user);

  const [toggleName, setToggleName] = useState<boolean>(false);
  const [togglePost, setTogglePost] = useState<boolean>(false);
  const [toggleDate, setToggleDate] = useState<boolean>(false);
  const [editName, setEditName] = useState<string>(article.auteurname);
  const [editPost, setEditPost] = useState<string>(article.auteurpost);
  const [editDate, setEditDate] = useState<string>(
    article.datepublication instanceof Date
      ? article.datepublication.toLocaleDateString("en-GB")
      : new Date(article.datepublication).toLocaleDateString("en-GB")
  );
  const [file, setFile] = useState<File | null>(null);

  const dispatch = useAppDispatch();

  const handlePicture = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (file && article.id) {
      let data = new FormData();
      data.append("id", article.id.toString());
      data.append("file", file);

      await dispatch(uploadCoverAuteurArticle(data));
      dispatch(getArticles());
    }
  };

  const dataObject = (element: CategoryElementInterface): Article => {
    switch (element) {
      case CategoryElementInterface.name:
        return {
          ...article,
          id: article.id,
          auteurname: editName,
        };
      case CategoryElementInterface.post:
        return {
          ...article,
          id: article.id,
          auteurpost: editPost,
        };
      case CategoryElementInterface.date:
        return {
          ...article,
          id: article.id,
          datepublication: new Date(editDate),
        };
    }
  };

  const handleFormText = async (
    e: React.FormEvent<HTMLFormElement>,
    element: CategoryElementInterface
  ) => {
    e.preventDefault();

    let data = dataObject(element);

    await dispatch(editArticle(data));
    dispatch(getArticles());
    switch (element) {
      case CategoryElementInterface.name:
        return setToggleName(!toggleName);

      case CategoryElementInterface.post:
        return setTogglePost(!togglePost);

      case CategoryElementInterface.date:
        return setToggleDate(!toggleDate);
      default:
        return null;
    }
  };

  const editionFunction = (
    setToggle: React.Dispatch<React.SetStateAction<boolean>>,
    toggle: boolean
  ) => {
    return (
      <div className="edit-delete">
        <img
          onClick={(e) => {
            setToggle(!toggle);
          }}
          src={update}
          alt="edit"
          className="pointer"
        />
        {/* <img
          src={deleteSVG}
          alt="delete"
          onClick={() => handleDelete()}
          className="pointer"
        /> */}
      </div>
    );
  };

  return (
    <div className="auteur_container">
      {userData?.admin ? (
        <form className="img" onSubmit={(e) => handlePicture(e)}>
          <img className="svg pointer" src={pictureSVG} alt="img" />
          <input
            className="fileInput pointer"
            type="file"
            id="file-upload"
            name="file"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => {
              if (e.target.files) setFile(e.target.files[0]);
            }}
          />

          <img src={article.auteurcover} alt="img" className="img_auteur" />
          <br />
          <input className="pointer" type="submit" value="Envoyer" />
        </form>
      ) : (
        <img src={article.auteurcover} alt="auteur" />
      )}
      <div className="text">
        {userData?.admin && editionFunction(setToggleName, toggleName)}
        {toggleName ? (
          <form
            onSubmit={(e) => handleFormText(e, CategoryElementInterface.name)}
          >
            <textarea
              defaultValue={article.auteurname}
              onChange={(e) => setEditName(e.target.value)}
            ></textarea>
            <input
              type="submit"
              value="Valider la modification"
              className="inputHeader pointer"
            />
          </form>
        ) : (
          <h3
            className="element_modifiable"
            onClick={() => userData?.admin && setToggleName(!toggleName)}
          >
            {article.auteurname}
          </h3>
        )}
        {userData?.admin && editionFunction(setTogglePost, togglePost)}
        {togglePost ? (
          <form
            onSubmit={(e) => handleFormText(e, CategoryElementInterface.post)}
          >
            <textarea
              defaultValue={article.auteurpost}
              onChange={(e) => setEditPost(e.target.value)}
            ></textarea>
            <input
              type="submit"
              value="Valider la modification"
              className="inputHeader pointer"
            />
          </form>
        ) : (
          <p
            className="element_modifiable"
            onClick={() => userData?.admin && setTogglePost(!togglePost)}
          >
            {article.auteurpost}
          </p>
        )}
        {userData?.admin && editionFunction(setToggleDate, toggleDate)}
        {toggleDate ? (
          <form
            onSubmit={(e) => handleFormText(e, CategoryElementInterface.date)}
          >
            <input type="date" onChange={(e) => setEditDate(e.target.value)} />
            <input
              type="submit"
              value="Valider la modification"
              className="inputHeader pointer"
            />
          </form>
        ) : (
          <p
            className="element_modifiable date_publication"
            onClick={() => userData?.admin && setToggleDate(!toggleDate)}
          >
            {article.datepublication instanceof Date
              ? article.datepublication.toLocaleDateString("en-GB")
              : new Date(article.datepublication).toLocaleDateString("en-GB")}
          </p>
        )}
      </div>
    </div>
  );
};

export default ArticleAuteur;
