
import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { CreateProspectUserDto, ProspectUserDto } from "../../types/prospectUser";
import { baseQueryHandleError } from '../index.rtk';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}prospectUser`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export const prospectUserApi = createApi({
    reducerPath: 'prospectUserApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: ['prospectUser'],
    endpoints: (builder) => ({
        getProspectUsers: builder.query<{
            rows: ProspectUserDto[],
            count: number
        }, void>({
            query: () => ``,
            providesTags: ['prospectUser'],
        }),
        // post
        addProspectUser: builder.mutation<void, CreateProspectUserDto>({
            query: (payload) => ({
                url: ``,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['prospectUser'],
        }),
        // delete
        deleteProspectUser: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['prospectUser'],
        }),
    }),
});


export const { useAddProspectUserMutation, useGetProspectUsersQuery, useDeleteProspectUserMutation } = prospectUserApi