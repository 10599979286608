import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { qualiteInterface } from "../../types/data";
import { useAppSelector } from "../../redux/store";
import Page from "../../function/page_info";

interface Props {
  qualiteObject: qualiteInterface;
  keyProp: number;
}

const QualiteItems: React.FC<Props> = ({ qualiteObject, keyProp }) => {
  const pageData = useAppSelector(
    (state) => state.pageMeasurement.measurements
  );

  useEffect(() => {
    if (keyProp % 2 === 1) {
      Page.scrollFunction(qualiteObject.id, "apparitionImg", true);
    }
  }, [pageData.scrollPage]);
  return (
    <div className="qualite_container">
      <div className="qualite_texte">
        <div className="qualite">
          <h3>{qualiteObject.name}</h3>
          <h4>{qualiteObject.sous_titre}</h4>

          {qualiteObject?.qualites.map((value) => (
            <p key={value}>{value}</p>
          ))}
        </div>
      </div>
      <div
        className="img"
        id={qualiteObject.id}
        style={{ backgroundImage: `url(${qualiteObject.cover})` }}
      ></div>
    </div>
  );
};

export default QualiteItems;
