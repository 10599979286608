import { configureStore } from "@reduxjs/toolkit"
import { useSelector, TypedUseSelectorHook, useDispatch } from "react-redux";
import pageReducer, { getMeasures } from './features/page'
import userReducer from './features/user'
import articleReducer, { getArticles } from './features/article'
import fondsReducer, { getFonds } from './features/fonds'
import projetReducer, { getProjets } from './features/projet'
import paragraphArticleReducer, { getParagraphArticles } from './features/paragraphArticle'
import categoryArticleReducer, { getCategoryArticles } from './features/categoryArticle'
import reasonDeleteAccountReducer from "./features/reasonDeleteAccount";
import statusUserReducer from "./features/statusUser";
import transactionsReducer from "./features/transactions";
import blackListsReducer from "./features/blackList";
import securityReducer from "./features/security";
import userOrderCashOut from "./features/userOrderCashOut";
import share from "./features/share"
import projectsValorisationReducer from "./features/projetsValorisation";
import debtReducer from "./features/debt";
import platformReducer from "./features/platform";
import shareAPiReducer from "./features/shareApi";
import knowledgeReducer from "./features/knowledge";
import auditLogReducer from "./features/auditLog";
import { projectApi } from "./features/projectSlice";
import { shareApi } from "./features/shareApiSlice";
import { auditLogApi } from "./features/auditLogSlice";
import { blackListsApi } from "./features/blackListSlice";
import { fondsApi } from "./features/fondsSlice";
import { knowledgeApi } from "./features/knowledgeSlice";
import { platformsApi } from "./features/platformSlice";
import { projectValorisationsApi } from "./features/projectValorisationSlice";
import { transactionsApi } from "./features/transactionSlice";
import { usersApi } from "./features/userSlice";
import { sharesApi } from "./features/shareSlice";
import { tokenApi } from "./features/tokenSlice";
import { prospectUserApi } from "./features/prospectUserSlice";
import { validatonCodeApi } from "./features/validationCodeSlice";
import { sponsorApi } from "./features/sponsorSlice";
import { typeInvestisseurApi } from "./features/typeInvestisseurSlice";
import { docuSealApi } from "./features/docuSealSlice";
import { contactApi } from "./features/contactSlice";


export const store = configureStore({

    reducer: {
        pageMeasurement: pageReducer,
        userReducer: userReducer,
        articleReducer: articleReducer,
        fondsReducer: fondsReducer,
        projetReducer: projetReducer,
        paragraphArticleReducer: paragraphArticleReducer,
        categoryArticleReducer: categoryArticleReducer,
        reasonDeleteAccountReducer: reasonDeleteAccountReducer,
        statusUserReducer: statusUserReducer,
        transactionsReducer: transactionsReducer,
        blackListsReducer: blackListsReducer,
        securityReducer: securityReducer,
        userOrderCashOutReducer: userOrderCashOut,
        shareReducer: share,
        projectsValorisationReducer: projectsValorisationReducer,
        debtReducer: debtReducer,
        platformReducer: platformReducer,
        shareAPiReducer: shareAPiReducer,
        knowledgeReducer: knowledgeReducer,
        auditLogReducer: auditLogReducer,
        [projectApi.reducerPath]: projectApi.reducer,
        [shareApi.reducerPath]: shareApi.reducer,
        [auditLogApi.reducerPath]: auditLogApi.reducer,
        [blackListsApi.reducerPath]: blackListsApi.reducer,
        [fondsApi.reducerPath]: fondsApi.reducer,
        [knowledgeApi.reducerPath]: knowledgeApi.reducer,
        [platformsApi.reducerPath]: platformsApi.reducer,
        [projectValorisationsApi.reducerPath]: projectValorisationsApi.reducer,
        [transactionsApi.reducerPath]: transactionsApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [sharesApi.reducerPath]: sharesApi.reducer,
        [tokenApi.reducerPath]: tokenApi.reducer,
        [prospectUserApi.reducerPath]: prospectUserApi.reducer,
        [validatonCodeApi.reducerPath]: validatonCodeApi.reducer,
        [sponsorApi.reducerPath]: sponsorApi.reducer,
        [typeInvestisseurApi.reducerPath]: typeInvestisseurApi.reducer,
        [docuSealApi.reducerPath]: docuSealApi.reducer,
        [contactApi.reducerPath]: contactApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(projectApi.middleware)
            .concat(shareApi.middleware)
            .concat(auditLogApi.middleware)
            .concat(blackListsApi.middleware)
            .concat(fondsApi.middleware)
            .concat(knowledgeApi.middleware)
            .concat(platformsApi.middleware)
            .concat(projectValorisationsApi.middleware)
            .concat(transactionsApi.middleware)
            .concat(usersApi.middleware)
            .concat(sharesApi.middleware)
            .concat(tokenApi.middleware)
            .concat(validatonCodeApi.middleware)
            .concat(sponsorApi.middleware)
            .concat(typeInvestisseurApi.middleware)
            .concat(docuSealApi.middleware)
            .concat(contactApi.middleware)
            .concat(prospectUserApi.middleware),
    devTools: import.meta.env.VITE_API_URL === "https://newparadigms.fr/api/" ? false : true,
    // devTools: true,
})

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch


export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

window.addEventListener("resize", function () {
    store.dispatch(getMeasures());
});
window.addEventListener("scroll", function () {
    store.dispatch(getMeasures());
});

// store.dispatch(getArticles())
// store.dispatch(getParagraphArticles());
// store.dispatch(getCategoryArticles());