import React, { useContext, useEffect, useState } from "react";
import { MenuDashBoardAdminBoolState } from "../../Context/AppContext";
import WrapComponentDashboardUser from "../../DashboardUser/commun/WrapComponentDashboardUser";
import { SponsorDto } from "../../../types/Sponsor";
import { typeFiltration } from "../../../types/BackOffice";
import { typeOrderOrFiltration } from "../../../types/fonds";
import FiltrationColumnTable, {
  filtrationInterface,
} from "../BackOffice/Components/FiltrationColumnTable";
import { addDays, formatDate } from "../../../function/Utils";
import { useGetAllSponsorQuery } from "../../../redux/features/sponsorSlice";
import usePaginationTable from "../../commun/CustomHook/usePaginationTable";
import ExportComponent from "../../commun/ExportComponent";
import Loading from "../../commun/Loading";
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/outline";
import {
  DateParam,
  NumberParam,
  StringParam,
  useQueryParam,
} from "use-query-params";
import TransactionsSponsorTable from "./TransactionsSponsorTable";
import { PrimaryButton } from "../../commun/Buttons";
import AddTransactionSponsor from "./AddTransactionSponsor";

const SponsorIndex = () => {
  const menuBoolState = useContext(MenuDashBoardAdminBoolState);

  const [sponsorTable, setSponsorTable] = useQueryParam(
    "sponsorTable",
    StringParam
  );

  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto">
      <WrapComponentDashboardUser
        title="Gestion des parrainages"
        description=""
        classNameHeader={`${!menuBoolState?.toggleMenu ? "!pl-[40px]" : ""}`}
        classNameContainer={`${!menuBoolState?.toggleMenu ? "" : ""}`}
      >
        {open ? <AddTransactionSponsor open={open} setOpen={setOpen} /> : null}
        <div className="">
          <div className="flex justify-center text-gray-700 items-center gap-2">
            <button
              onClick={() => setSponsorTable("sponsors")}
              className={`${
                sponsorTable !== "transactions"
                  ? "border-secondColor border-b-2 "
                  : "bg-white "
              }  w-1/2 py-2 rounded-md text-sm font-medium`}
            >
              Parrainage
            </button>
            <button
              onClick={() => setSponsorTable("transactions")}
              className={`${
                sponsorTable === "transactions"
                  ? "border-secondColor border-b-2"
                  : "bg-white "
              }  w-1/2 py-2 rounded-md text-sm font-medium`}
            >
              Transactions
            </button>
          </div>
          <div className="flex justify-center items-center mt-4">
            <PrimaryButton onClick={() => setOpen(true)}>
              Add Transaction
            </PrimaryButton>
          </div>
        </div>
        <div className="overflow-auto h-[83vh] md:h-[88vh] relative px-2">
          {sponsorTable === "transactions" ? (
            <TransactionsSponsorTable />
          ) : (
            <SponsorTable setOpen={setOpen} />
          )}
        </div>
      </WrapComponentDashboardUser>
    </div>
  );
};

export default SponsorIndex;

function SponsorTable({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const classNameObejct = {
    head: "text-sm text-gray-900 px-6 py-4 text-left font-semibold text-left",
    item: "text-sm font-light px-6 py-4 whitespace-nowrap  text-right",
  };

  let date = new Date();
  date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const [sponsorName, setSponsorName] = useQueryParam(
    "sponsorName",
    StringParam
  );
  const [sponsoredName, setSponsoredName] = useQueryParam(
    "sponsoredName",
    StringParam
  );
  const [dateStart, setDateStart] = useQueryParam("dateStart", DateParam);
  const [dateEnd, setDateEnd] = useQueryParam("dateEnd", DateParam);
  const [used, setUsed] = useQueryParam<0 | 1 | "all">("used");
  const [refunded, setRefunded] = useQueryParam<0 | 1 | "all">("refunded");

  const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
  const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);

  const { data: sponsorsWithNbItems, isLoading } = useGetAllSponsorQuery({
    limit: nbItemsShowed,
    offset: startIntervalNbItems,
    sponsorName: sponsorName || "",
    sponsoredName: sponsoredName || "",
    // dateStart: dateStart || addDays(date, -4 * 365),
    // dateEnd: dateEnd || addDays(date, 1),
    dateStart: new Date(2000, 0, 1),
    dateEnd: new Date(2050, 0, 1),
    refunded: refunded || "all",
    used: used || "all",
  });

  const sponsors = sponsorsWithNbItems?.rows;
  const nbItems = sponsorsWithNbItems?.count;

  const { ComponentFilter, dataFiltered, setFilterColumn } =
    usePaginationTable<SponsorDto>({
      initialOrder: "date",
      data: sponsors || [],
      nbItemsShowed: nbItemsShowed,
      startIntervalNbItems: startIntervalNbItems,
      setNbItemsShowed: setNbItemsShowed,
      setStartIntervalNbItems: setStartIntervalNbItems,
    });

  return (
    <div className="mt-4">
      <ComponentFilter offset={true} nbItems={nbItems ?? 0}>
        <div className="relative md:absolute md:top-3 md:right-3">
          <ExportComponent
            headers={[
              "SponsorName",
              "SponsoredName",
              "amount",
              "Used",
              "Refunded",
              "date",
            ]}
            title="Parrainage"
            data={dataFiltered.map((sponsor) => {
              return {
                SponsorName: sponsor.sponsorName,
                SponsoredName: sponsor.sponsoredName,
                amount: sponsor.amount,
                used: sponsor.used ? "true" : "false",
                refunded: sponsor.refunded ? "true" : "false",
                date: formatDate(new Date(sponsor.date)),
              };
            })}
          />
        </div>
      </ComponentFilter>
      <div className="flex flex-col max-w-full overflow-x-auto">
        <div className="overflow-x-auto sm:-mx-6 lg:mx-auto min-w-full">
          <div className="py-2 inline-block min-w-full">
            {/* <p>
    Valeur total du fonds{" "}
    {printLargeValue(
      projectValorisationInfoData?.valorisationByProjects?.toFixed(
        2
      )
    )}
  </p> */}
            <div className="overflow-auto">
              <table className="table-auto overflow-scroll w-full">
                <thead className="bg-white border-b border-t">
                  <tr>
                    <th scope="col" className={classNameObejct.head}>
                      #
                    </th>
                    <FiltrationColumnTable
                      element={"sponsoredName"}
                      type={typeFiltration.name}
                      setFilterColumn={setFilterColumn}
                      setSearch={setSponsoredName}
                      columnName={() => (
                        <p className="inline-block">Sponsored</p>
                      )}
                      orderOrFiltration={typeOrderOrFiltration.filter}
                      textLeft={true}
                    />
                    <FiltrationColumnTable
                      element={"sponsorName"}
                      type={typeFiltration.name}
                      setFilterColumn={setFilterColumn}
                      setSearch={setSponsorName}
                      columnName={() => <p className="inline-block">Sponsor</p>}
                      orderOrFiltration={typeOrderOrFiltration.filter}
                      textLeft={true}
                    />
                    <FiltrationColumnTable
                      element={"amount"}
                      type={typeFiltration.amount}
                      setFilterColumn={setFilterColumn}
                      columnName={() => <p className="inline-block">Montant</p>}
                      orderOrFiltration={typeOrderOrFiltration.order}
                      textLeft={false}
                    />

                    <FiltrationColumnTable
                      element={"used"}
                      setSearch={setUsed}
                      search={used}
                      type={typeFiltration.exact}
                      setFilterColumn={setFilterColumn}
                      orderOrFiltration={typeOrderOrFiltration.filter}
                      optionValues={[
                        {
                          label: "All",
                          value: "all",
                        },
                        {
                          label: "Used",
                          value: 1,
                        },
                        {
                          label: "Not used",
                          value: 0,
                        },
                      ]}
                      columnName={() => <p className="inline-block">Used</p>}
                      textLeft={false}
                    />

                    <FiltrationColumnTable
                      element={"refunded"}
                      setSearch={setRefunded}
                      search={refunded}
                      type={typeFiltration.exact}
                      setFilterColumn={setFilterColumn}
                      orderOrFiltration={typeOrderOrFiltration.filter}
                      optionValues={[
                        {
                          label: "All",
                          value: "all",
                        },
                        {
                          label: "Refunded",
                          value: 1,
                        },
                        {
                          label: "Not refunded",
                          value: 0,
                        },
                      ]}
                      columnName={() => (
                        <p className="inline-block">Refunded</p>
                      )}
                      textLeft={false}
                    />

                    <FiltrationColumnTable
                      element={"date"}
                      type={typeFiltration.date}
                      setFilterColumn={setFilterColumn}
                      columnName={() => (
                        <p className="inline-block text-center">Date</p>
                      )}
                      orderOrFiltration={typeOrderOrFiltration.order}
                      textLeft={true}
                    />
                  </tr>
                </thead>
                <tbody className="bg-white ">
                  {isLoading ? (
                    <tr className="">
                      <td colSpan={7} className="pt-10 text-center mx-auto">
                        <Loading />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {dataFiltered && (
                        <>
                          {dataFiltered.length === 0 ? (
                            <tr className="w-full mx-auto">
                              <td
                                colSpan={7}
                                className="text-center text-gray-500 text-xl  pt-10"
                              >
                                Aucun parainages touvée
                              </td>
                            </tr>
                          ) : (
                            <React.Fragment>
                              {dataFiltered.map((s, key) => (
                                <RowElement
                                  s={s}
                                  key={key}
                                  number={key}
                                  setOpen={setOpen}
                                />
                              ))}
                            </React.Fragment>
                          )}
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RowElement({
  s,
  number,
  setOpen,
}: {
  s: SponsorDto;
  number: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const classNameObejct = {
    item: "text-sm  font-light px-6 py-4 whitespace-nowrap",
  };

  const [sponsorId, setSponsorId] = useQueryParam("sponsorId", NumberParam);
  const [userId, setUserId] = useQueryParam("userId", NumberParam);
  return (
    <tr
      onClick={() => {
        if (!s.refunded && s.used) {
          setSponsorId(s.id);
          setUserId(s.sponsorId);
          setOpen(true);
        }
      }}
      className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
        number % 2 === 0 ? "bg-secondBackgroundColor" : "bg-white "
      } `}
    >
      <td className={`${classNameObejct.item}`}>{number + 1}</td>
      <td className={`${classNameObejct.item}`}>{s.sponsoredName}</td>
      <td className={`${classNameObejct.item}`}>{s.sponsorName}</td>
      <td className={`${classNameObejct.item} text-right`}>
        {s?.amount ? s?.amount.toFixed(2) : "-"}
      </td>
      <td className={`${classNameObejct.item} text-center `}>
        <DisplayBinaryVariable bool={s.used} />
      </td>
      <td className={`${classNameObejct.item} text-center `}>
        {<DisplayBinaryVariable bool={s.refunded} />}
      </td>
      <td className={`${classNameObejct.item} text-center `}>
        {!!s.date &&
          (s.date instanceof Date
            ? s.date.toLocaleDateString("en-GB")
            : new Date(s.date).toLocaleDateString("en-GB"))}
      </td>
    </tr>
  );
}

function DisplayBinaryVariable({ bool }: { bool: boolean }) {
  if (bool) {
    return (
      <div className=" flex justify-center items-center">
        {" "}
        <CheckCircleIcon
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />{" "}
      </div>
    );
  } else {
    return (
      <div className=" flex justify-center items-center">
        <ExclamationIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
      </div>
    );
  }
}
