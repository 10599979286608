import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDeleteDocumentBeneficialOwnerMutation } from '../../../redux/features/userSlice';
import { DocumentBeneficialOwnerDto } from '../../../types/user';
import HoverEffect from '../animation/HoverEffect';
import { PrimaryButton } from '../Buttons';
import useDeleteItem from '../CustomHook/useDeleteItem';
import Loading from '../Loading';
import PopUp from '../PopUp';
import StatusDocument from '../StatusDocument';

function DisplayDocumentBeneficialOwner({
    document,
    userId,
    refetch,
}: {
    document: DocumentBeneficialOwnerDto;
    refetch?: any;
    userId: number;
}) {
    const type = document?.key?.split('.')?.[1];
    const [popUp, setPopUp] = useState(false);

    const [deleteDocument, { isLoading }] =
        useDeleteDocumentBeneficialOwnerMutation();

    const { setOpen: setOpenDelete, DeleteComponent } = useDeleteItem<{
        documentId: number;
        userId: number;
    }>({
        deleteItem: () =>
            deleteDocument({
                userId: userId,
                documentId: document.id,
            }),
        toDoIfSuccess: () => {
            if (refetch) {
                refetch();
            }
        },

        message: 'document',
        isLoading: isLoading,
    });

    return (
        <>
            <DeleteComponent title="ce document" />
            {isLoading || !document ? (
                <Loading />
            ) : popUp ? (
                <PopUp
                    open={popUp}
                    setOpen={setPopUp}
                    buttonBoolean={false}
                    width="w-full md:w-9/12"
                    title={() => {
                        return (
                            <h3 className="text-3xl font-semibold leading-6 text-gray-900">
                                {decodeURIComponent(document.fileName)}
                            </h3>
                        );
                    }}
                >
                    <div className="w-full flex mx-auto flex-col justify-center gap-3 md:w-10/12">
                        <img src={document?.url} className="" />
                        <div className="w-full">
                            <PrimaryButton
                                onClick={() => {
                                    setPopUp(false);
                                }}
                                className="w-fit"
                            >
                                Cancel
                            </PrimaryButton>
                        </div>
                    </div>
                </PopUp>
            ) : (
                <div
                    className={`w-full items-center gap-3 ${
                        type !== 'pdf'
                            ? 'grid grid-cols-2'
                            : 'grid space-y-2 md:grid-cols-[auto,auto,auto,auto] items-center sm:flex-nowrap '
                    }`}
                >
                    <div
                        className={`${
                            type !== 'pdf'
                                ? 'w-full cursor-pointer col-span-2 mx-auto flex justify-center items-center'
                                : 'w-10'
                        } justify-self-start`}
                    >
                        {type !== 'pdf' ? (
                            <img
                                src={document?.url}
                                className="max-w-xs"
                                onClick={() => setPopUp(true)}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faFilePdf} size={'2x'} />
                        )}
                    </div>

                    <div className="text-sm h-fit text-start w-full font-medium leading-6 text-gray-900">
                        {decodeURIComponent(document?.fileName)}
                    </div>
                    {document?.validation?.comment ? (
                        <div className="text-sm h-fit text-start w-full font-medium leading-6 text-gray-900">
                            <span className=" font-semibold uppercase text-orange-500">
                                Validation:
                            </span>{' '}
                            {document.validation?.comment}
                        </div>
                    ) : null}

                    <div
                        className={`h-fit place-self-end text-end ${
                            document?.validation?.comment ? '' : ' col-span-2'
                        }`}
                    >
                        <div className="text-sm flex gap-4 items-center font-medium leading-6  text-gray-900">
                            <StatusDocument status={document.status} />

                            <HoverEffect>
                                <a href={document.url} download={'test'}>
                                    <FontAwesomeIcon
                                        icon={faDownload}
                                        className="text-mainColor cursor-pointer hover:text-secondColor w-[20px] h-[20px]"
                                    />
                                </a>
                            </HoverEffect>
                            {refetch ? (
                                <HoverEffect
                                    onClick={() => setOpenDelete(true)}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className="text-titleColor hover:text-gray-400 w-[20px] h-[20px]"
                                    />
                                </HoverEffect>
                            ) : null}
                            {/* <HoverEffect onClick={() => handleDeleteDocument()}>
                <FontAwesomeIcon
                  icon={faTrash}
                  className="text-titleColor hover:text-gray-400 w-[20px] h-[20px]"
                />
              </HoverEffect> */}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DisplayDocumentBeneficialOwner;
