import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
    FieldErrorsImpl,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
    useForm,
} from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import * as Yup from 'yup';
import { useAddProspectUserMutation } from '../../redux/features/prospectUserSlice';
import { CreateProspectUserDto } from '../../types/prospectUser';
import { UserCreationDto } from '../../types/user';
import { PrimaryButton, WhiteButton } from '../commun/Buttons';
import InputComponent from '../commun/formComponent/InputComponent';
import PasswordInput from '../commun/formComponent/PasswordInput';
import RadioComponent from '../commun/formComponent/RadioComponent';
import { useNotificationContext } from '../Context/notification-context';
import StepSimpleComponent from '../DashboardUser/commun/StepSimpleComponent';

interface CreateUserDtoForm extends UserCreationDto {
    confirmPassword: string;
    notUseSponsorCode: string;
    acceptRisqueLost: boolean;
    acceptRisqueLiquidity: boolean;
    acceptRisqueCredit: boolean;
    sponsoring: string;
    sponsoringCode: string;
}

interface Props {
    setRegisterState?: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenMessageCreattionCompte: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

const Registration: React.FC<Props> = ({
    setRegisterState,
    setOpenMessageCreattionCompte,
}) => {
    const [message, setMessage] = useState<string>('');
    const [registerQuery, setRegisterQuery] = useQueryParam(
        'register',
        BooleanParam
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [invalidSponsorCode, setInvalidSponsorCode] =
        useState<boolean>(false);
    const [prospectUserCreated, setProspectUserCreated] =
        useState<boolean>(false);

    const [sponsorQuery, setSponsorQuery] = useQueryParam('code', StringParam);

    const { showError, showSuccess } = useNotificationContext();

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Prénom est demandé').trim(),
        lastName: Yup.string().required('Nom est demandé').trim(),
        phone: Yup.string().required('Numéro de téléphone est demandé').trim(),
        email: Yup.string()
            .required('Email est demandé')
            .email('Email est invalid')
            .lowercase()
            .trim(),
        password: Yup.string()
            .required('le mot de passe est demandé')
            .min(8, 'le mot de passe doit au moins contenir 8 caractères')
            .max(40, 'le mot de passe ne doit pas excéder 40 caractères')
            .matches(
                /^(?=.*[^A-Za-z0-9])[^&\\;<>]+$/,
                'Il faut au moins un charactère spécial mais non spécifique ne pas utiliser (&;<>)'
            ),
        confirmPassword: Yup.string()
            .required('Répéter le mot de passe est demandé')
            .oneOf(
                [Yup.ref('password'), null],
                'Les mots de passe ne correspondent pas'
            ),
        acceptTerms: Yup.bool().oneOf(
            [true],
            'Accepter les termes est demandé'
        ),
        // acceptRisque: Yup.bool().oneOf([true], "Accepter les risques est demandé"),
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors, isValid },
    } = useForm<CreateUserDtoForm>({
        resolver: yupResolver(validationSchema) as any,
        mode: 'onChange',
    });

    useEffect(() => {
        if (sponsorQuery) {
            reset({
                ...watch(),
                sponsorCode: sponsorQuery,
                sponsoring: 'true',
            });
        }
    }, [sponsorQuery]);

    useEffect(() => {
        if (watch('notUseSponsorCode')) {
            setValue('sponsoring', 'false');
        }
    }, [watch('notUseSponsorCode')]);

    const OnSubmit = async (data: CreateUserDtoForm) => {
        // setCurrent(0);

        setIsLoading((curr) => !curr);
        const createUserData: UserCreationDto = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            phone: data.phone,
            acceptRisque: data.acceptRisque,
            acceptTerms: data.acceptTerms,
            sponsorCode: data.sponsoring ? data.sponsorCode : undefined,
        };

        if (invalidSponsorCode && data.notUseSponsorCode) {
            createUserData.sponsorCode = undefined;
        }

        // TODO: check if the invitation code is valid

        await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}users/auth/register`,
            withCredentials: true,
            data: createUserData,
        })
            .then((value) => {
                setIsLoading((curr) => !curr);

                setMessage(value.data.message);
                if (value.data.message === 'Code invalid') {
                    setInvalidSponsorCode(true);
                    setCurrent(1);
                }

                if (value.data.message === 'SUCCESS') {
                    // showSuccess(
                    //   "Création de compte",
                    //   "Votre compte a bien été créé, vous allez recevoir un email de validation. Vérifiez vos spams"
                    // );
                    setOpenMessageCreattionCompte(true);
                    if (setRegisterState) {
                        setRegisterState(false);
                    } else {
                        setRegisterQuery(false);
                    }
                    reset();
                } else {
                    if (value.data.message === 'Code invalid') {
                        setCurrent(1);
                    } else {
                        setCurrent(0);
                    }
                    showError('Erreur', "Votre compte n'a pas pu être créé");
                }
            })
            .catch((err) => {
                setIsLoading((curr) => !curr);

                console.log(err);
            });
    };

    const [current, setCurrent] = useState<number>(0);
    return (
        <div className="w-full">
            <StepSimpleComponent
                current={current}
                steps={[
                    {
                        name: 'Etape 1',
                        description: 'Vos informations',
                    },
                    {
                        name: 'Etape 2',
                        description: 'Inscription',
                    },
                ]}
            ></StepSimpleComponent>

            <form onSubmit={handleSubmit(OnSubmit)} className="w-full">
                {current === 0 ? (
                    <VosInformations
                        register={register}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        message={message}
                        prospectUserCreated={prospectUserCreated}
                        setProspectUserCreated={setProspectUserCreated}
                        setCurrent={setCurrent}
                        setMessage={setMessage}
                    />
                ) : (
                    <>
                        <AuhtCode
                            register={register}
                            message={message}
                            errors={errors}
                            isLoading={isLoading}
                            watch={watch}
                            setValue={setValue}
                            setCurrent={setCurrent}
                            invalidSponsorCode={invalidSponsorCode}
                            isValidForm={isValid}
                        />
                    </>
                )}
            </form>
        </div>
    );
};

export default Registration;

function VosInformations({
    register,
    errors,
    watch,
    setValue,
    message,
    setCurrent,
    setMessage,
    prospectUserCreated,
    setProspectUserCreated,
}: {
    register: UseFormRegister<CreateUserDtoForm>;
    watch: UseFormWatch<CreateUserDtoForm>;
    errors: FieldErrorsImpl<CreateUserDtoForm>;
    setValue: UseFormSetValue<CreateUserDtoForm>;
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    prospectUserCreated: boolean;
    setProspectUserCreated: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [isValid, setIsValid] = useState<boolean>(false);

    useEffect(() => {
        if (
            // watch("confirmPassword") != "" &&
            // watch("confirmPassword") === watch("password") &&
            // watch("password") != "" &&
            watch('firstName') != '' &&
            watch('lastName') != '' &&
            watch('email') != '' &&
            watch('acceptTerms') === true &&
            watch('phone') != '' &&
            watch('firstName') != undefined &&
            watch('lastName') != undefined &&
            watch('email') != undefined &&
            watch('phone') != undefined
            // ((watch("sponsoring") === "true" && watch("sponsorCode") != "") ||
            //   watch("sponsoring") === "false")
        ) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [watch()]);

    const [addProspectUser, { isLoading: isLoadingAddProspect }] =
        useAddProspectUserMutation();

    const { showError, showSuccess } = useNotificationContext();

    const handleStep1 = async () => {
        if (!prospectUserCreated) {
            setValue('password', '');
            setValue('confirmPassword', '');
            const data: CreateProspectUserDto = {
                firstName: watch('firstName'),
                lastName: watch('lastName'),
                email: watch('email'),
                phoneNumber: watch('phone'),
            };

            await addProspectUser(data)
                .unwrap()
                .then(() => {
                    setProspectUserCreated(true);
                    setCurrent(1);
                })
                .catch((err) => {
                    setMessage(err.message);
                    showError('Erreur', "Votre compte n'a pas pu être créé");
                });
        } else {
            setCurrent(1);
        }
    };

    return (
        <>
            <div className="form-item">
                <label>
                    Prénom <span className="required">*</span>
                </label>
                <input
                    type="text"
                    {...register('firstName')}
                    className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                />
                <div className="invalid-feedback">
                    {errors.firstName?.message}
                </div>
            </div>

            <div className="form-item">
                <label>
                    Nom <span className="required">*</span>
                </label>
                <input
                    type="text"
                    {...register('lastName')}
                    className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                />
                <div className="invalid-feedback">
                    {errors.lastName?.message}
                </div>
            </div>
            <div style={{ color: 'red' }}>{message}</div>

            <div className="form-item">
                <label>
                    Email <span className="required">*</span>
                </label>
                <input
                    type="text"
                    {...register('email')}
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                />
                <div className="invalid-feedback">{errors.email?.message}</div>
            </div>

            <div className="form-item">
                <label>
                    Numéro de téléphone <span className="required">*</span>
                </label>
                <PhoneInput
                    placeholder="Entrer votre numéro de téléphone"
                    className="w-full border outline-none bg-white border-gray-300 rounded-md p-2"
                    required={true}
                    defaultCountry={'FR'}
                    // value={value}
                    // onChange={setValue}

                    onChange={(e) =>
                        setValue('phone', e || '', { shouldValidate: true })
                    }
                    value={watch('phone')}
                />
            </div>

            <div className="form-item form-check">
                <input
                    id="acceptTerms"
                    type="checkbox"
                    {...register('acceptTerms')}
                    className={`form-check-input ${
                        errors.acceptTerms ? 'is-invalid' : ''
                    }`}
                />
                <label htmlFor="acceptTerms" className="form-check-label">
                    J'ai lu et j'accepte les{' '}
                    <a
                        href="https://www.newparadigms.fr/Reglementations/conditions-generales-utilisation"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Conditons d'Utilisations
                    </a>{' '}
                    et la{' '}
                    <a
                        href="https://www.newparadigms.fr/Reglementations/Donnees-personnelles"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Politique de Confidentialité
                    </a>{' '}
                    <span className="required">*</span>
                </label>
                <div className="invalid-feedback">
                    {errors.acceptTerms?.message}
                </div>
            </div>
            <div className="form-item">
                <PrimaryButton
                    disabled={!isValid}
                    onClick={handleStep1}
                    className="mx-auto my-2"
                >
                    Suivant
                </PrimaryButton>
            </div>
        </>
    );
}

function AuhtCode({
    register,
    watch,
    errors,
    setValue,
    setCurrent,
    invalidSponsorCode,
    isValidForm,
    isLoading,
    message,
}: {
    register: UseFormRegister<CreateUserDtoForm>;
    watch: UseFormWatch<CreateUserDtoForm>;
    errors: FieldErrorsImpl<CreateUserDtoForm>;
    setValue: UseFormSetValue<CreateUserDtoForm>;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    invalidSponsorCode: boolean;
    isValidForm: boolean;
    isLoading: boolean;
    message: string;
}) {
    const [isValid, setIsValid] = useState<boolean>(false);

    useEffect(() => {
        if (
            watch('confirmPassword') != '' &&
            watch('confirmPassword') === watch('password') &&
            watch('password') != '' &&
            watch('firstName') != '' &&
            watch('lastName') != '' &&
            watch('email') != '' &&
            watch('sponsoring') != null &&
            watch('acceptTerms') === true &&
            watch('phone') != '' &&
            watch('sponsoring') === 'true' &&
            watch('sponsorCode') !== undefined &&
            watch('sponsorCode')?.length! > 0
        ) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [watch()]);

    return (
        <div className="w-full mt-3 flex flex-col gap-3">
            <p>
                L'accès à nos fonds d'investissement est exclusif et réservé
                uniquement aux clients disposant d'un code spécifique. La
                création de compte est conditionnée à la possession de ce code,
                assurant ainsi une sécurité et une exclusivité pour nos services
                financiers.
            </p>
            <RadioComponent
                register={register}
                watch={watch}
                name={'sponsoring'}
                className={{
                    container: 'flex gap-2 justify-center ',
                    label: ' !font-light text-gray-600 ',
                }}
                values={[
                    {
                        value: 'true',
                        label: 'Oui',
                    },
                    {
                        value: 'false',
                        label: 'Non',
                    },
                ]}
            >
                {' '}
                <label className="mt-2 font-bold text-[0.9rem">
                    Possédez vous un code ? <span className="required">*</span>
                </label>
            </RadioComponent>
            {/* {invalidSponsorCode ? (
        <div className="form-item form-check">
          
          <div className="invalid-feedback">{errors.acceptRisque?.message}</div>
        </div>
      ) : null} */}

            {watch('sponsoring') == 'true' ? (
                <>
                    {message === 'Code invalid' ? (
                        <div style={{ color: 'red' }}>{message}</div>
                    ) : null}
                    <InputComponent register={register} value={'sponsorCode'}>
                        <label className=" font-semibold">
                            Code <span className=" text-red-500">*</span>
                        </label>
                    </InputComponent>
                    <PasswordInput
                        register={register}
                        errors={errors}
                        label={'Mot de passe'}
                        name={'password'}
                        componentError={() => {
                            return (
                                <div className="invalid-feedback !text-sm">
                                    {errors.password?.message}
                                </div>
                            );
                        }}
                    />

                    <PasswordInput
                        register={register}
                        errors={errors}
                        label={'Confirmez le mot de passe'}
                        name={'confirmPassword'}
                        componentError={() => {
                            return (
                                <div className="invalid-feedback !text-sm">
                                    {errors.confirmPassword?.message}
                                </div>
                            );
                        }}
                    />
                </>
            ) : null}

            {watch('sponsoring') == 'false' ? (
                <div className="text-gray-600 italic text-md">
                    <p>
                        Si vous ne possédez pas de code, nous prendrons contact
                        avec vous dans les jours suivants, afin de vous
                        présenter nos solutions d'investissement.
                    </p>
                </div>
            ) : null}

            <div className="flex w-11/12 mx-auto justify-center items-center gap-2">
                <WhiteButton onClick={() => setCurrent(0)}>
                    Précédent
                </WhiteButton>
                <PrimaryButton
                    disabled={!isValid || !isValidForm}
                    type="submit"
                    loading={isLoading}
                    className=" my-2"
                >
                    S'inscrire
                </PrimaryButton>
            </div>
        </div>
    );
}
