import {
    faMinus,
    faPlus,
    faSeedling,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { Slider } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useGetDashbordUserInfoQuery } from '../../redux/features/shareApiSlice';
import { useFetchTokenQuery } from '../../redux/features/tokenSlice';
import { useGetRecapSponsorshipUserQuery } from '../../redux/features/transactionSlice';
import {
    useCreateSponsorCodeMutation,
    useGetUserWithAllInfoForCustomerQuery,
} from '../../redux/features/userSlice';
import {
    StateStatus,
    StatusUserTypeEnum,
    UserStatusType,
} from '../../types/user';
import { PrimaryButton } from '../commun/Buttons';
import CopyButton from '../commun/CopyButton';
import RadioComponent from '../commun/formComponent/RadioComponent';
import { SuccessIcon } from '../commun/IconsFeedBack';
import Loading from '../commun/Loading';
import SlideOverComponent from '../commun/SlideOverComponent';
import Spinner from '../commun/Spinner';
import { MenuDashBoardUserBoolState } from '../Context/AppContext';
import { useNotificationContext } from '../Context/notification-context';
import TableComponent from '../DashboardAdmin/BackOffice/Components/TableComponent';
import WrapComponentDashboardUser from './commun/WrapComponentDashboardUser';

const Activities = () => {
    const menuBoolState = useContext(MenuDashBoardUserBoolState);

    return (
        <div className="mx-auto  md:mt-0 lg:px-2 pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto">
            <div className="T_1 sub_item_container boxShadow_false lg:p-0">
                <div
                    className={`grid items-start justify-start grid-cols-1 lg:grid-cols-activite gap-4`}
                >
                    <WrapComponentDashboardUser
                        title="Nos Fonds"
                        classNameHeader={`${
                            !menuBoolState?.toggleMenu ? '!pl-[40px]' : ''
                        }`}
                        classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
                    >
                        <NosFonds />
                    </WrapComponentDashboardUser>
                    <WrapComponentDashboardUser
                        title="Parrainage"
                        classNameContainer={`${!menuBoolState?.toggleMenu ? '' : ''}`}
                    >
                        <Sponsor />
                    </WrapComponentDashboardUser>
                    {/* <div className="col-span-1 row-span-1 ">
            <QuickAccess /> 
          </div> */}
                    <div className="lg:col-span-2 row-span-1 ">
                        <StatusUser />
                    </div>
                    {/* <div className="col-span-1 row-span-1">
            <Questions />
          </div> */}
                </div>
            </div>
        </div>
    );
};

export default Activities;

function QuickAccess() {
    const [open, setOpen] = React.useState(false);

    return (
        <div className="md:mt-5 w-full flex flex-col gap-2 mw-auto py-4">
            <h3 className="text-center mb-2 text-mainColor text-3xl">
                Accès rapide
            </h3>
            <div className="w-fit md:w-[200px] mx-auto flex flex-col gap-3">
                <PrimaryButton onClick={() => setOpen(true)}>
                    Parrainage
                </PrimaryButton>
                <PrimaryButton>Faq</PrimaryButton>
            </div>
        </div>
    );
}

function NosFonds() {
    // const { data: fonds, isLoading } = useGetFondsQuery();
    const { data: dashBordUserAdmin, isLoading } =
        useGetDashbordUserInfoQuery();
    const classNameObejct = {
        item: 'text-sm font-light px-1 py-2 whitespace-nowrap',
    };

    const navigate = useNavigate();

    return (
        <div className="p-2 pb-4 h-full">
            {isLoading ? (
                <div className="flex justify-center items-center">
                    <Loading />
                </div>
            ) : (
                <div className="w-full h-full">
                    <React.Fragment>
                        <TableComponent
                            head={[
                                '',
                                'Performance *',
                                'Diversification % **',
                                'Taux de défaut %',
                                'Impact',
                            ]}
                        >
                            <tbody>
                                <React.Fragment>
                                    {dashBordUserAdmin?.fondsInfo?.map(
                                        (fond, key) => (
                                            <tr
                                                className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                                                    key % 2 === 1
                                                        ? 'bg-secondBackgroundColor'
                                                        : 'bg-white '
                                                } `}
                                                onClick={() =>
                                                    navigate(
                                                        `/DashBoardClient/nos-fonds`
                                                    )
                                                }
                                            >
                                                <td
                                                    className={`${classNameObejct.item} pl-1`}
                                                >
                                                    {fond.fondsName}
                                                </td>
                                                <td
                                                    className={`${classNameObejct.item}`}
                                                >
                                                    {(fond.triNotBlackListLast30Days >
                                                    0
                                                        ? fond.triNotBlackListLast30Days -
                                                          (fond.triNotBlackListLast30Days -
                                                              fond.fondsPerformance) *
                                                              0.3
                                                        : 0
                                                    ).toFixed(2)}{' '}
                                                    %
                                                </td>
                                                <td
                                                    className={`${classNameObejct.item}`}
                                                >
                                                    {fond.diversification} %
                                                </td>
                                                <td
                                                    className={`${classNameObejct.item}`}
                                                >
                                                    0.00 %
                                                </td>
                                                <DisplayFondsImpact
                                                    name={fond.fondsName}
                                                />
                                            </tr>
                                        )
                                    )}
                                </React.Fragment>
                            </tbody>
                        </TableComponent>

                        <div className="mt-2 mb-5 w-fit mx-auto">
                            <PrimaryButton
                                onClick={() =>
                                    navigate('/DashBoardClient/investir')
                                }
                            >
                                Investir
                            </PrimaryButton>
                        </div>
                    </React.Fragment>
                    <div className=" bottom-[4px] left-2 flex flex-wrap gap-2 text-gray-500 italic text-md">
                        <p className=" ">
                            * Performance des 30 derniers jours.
                        </p>
                        <p className=" ">
                            ** Exposition moyenne du portefeuille à un projet.
                        </p>
                        <p>
                            <FontAwesomeIcon
                                icon={faSeedling}
                                className="text-green-600"
                            />
                            Impact environnemental
                        </p>
                        <p>
                            <FontAwesomeIcon
                                icon={faUsers}
                                className="text-mainColor"
                            />
                            : Impact social
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export function DisplayFondsImpact({ name }: { name: string }) {
    const classNameObejct = {
        item: 'text-sm font-light px-0 py-2 whitespace-nowrap',
    };

    return (
        <div className={`${classNameObejct.item} flex items-center gap-2 `}>
            {name === 'Immobilier' ? (
                <FontAwesomeIcon icon={faUsers} className="text-mainColor" />
            ) : name === 'Agriculture durable' ? (
                <>
                    <FontAwesomeIcon
                        icon={faUsers}
                        className="text-mainColor"
                    />
                    <FontAwesomeIcon
                        icon={faSeedling}
                        className="text-green-600"
                    />
                    <FontAwesomeIcon
                        icon={faSeedling}
                        className="text-green-600"
                    />
                </>
            ) : name === 'Energie Renouvelable' ? (
                <>
                    <FontAwesomeIcon
                        icon={faUsers}
                        className="text-mainColor"
                    />
                    <FontAwesomeIcon
                        icon={faSeedling}
                        className="text-green-600"
                    />
                </>
            ) : name === 'Transition' ? (
                <>
                    <FontAwesomeIcon
                        icon={faUsers}
                        className="text-mainColor"
                    />
                    <FontAwesomeIcon
                        icon={faSeedling}
                        className="text-green-600"
                    />
                </>
            ) : (
                <>
                    <FontAwesomeIcon
                        icon={faUsers}
                        className="text-mainColor"
                    />
                    <FontAwesomeIcon
                        icon={faUsers}
                        className="text-mainColor"
                    />
                    <FontAwesomeIcon
                        icon={faSeedling}
                        className="text-green-600"
                    />
                </>
            )}
        </div>
    );
}

interface FormAmountSponsor {
    amount: number;
}

function Sponsor() {
    const [nbFilleul, setNbFilleul] = React.useState(2);

    const { register, watch, control } = useForm<FormAmountSponsor>({
        defaultValues: {
            amount: 8000,
        },
    });

    const navigate = useNavigate();

    const [slideOverToggle, setSlideOverToggle] = React.useState(false);

    const {
        field: { value: amount, onChange: setAmount, ...restPropertyField },
    } = useController({
        name: 'amount',
        control: control,
    });

    const [toogle, setToogle] = React.useState(false);

    const { showError, showSuccess } = useNotificationContext();

    const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();

    const { data: user, isLoading } = useGetUserWithAllInfoForCustomerQuery(
        userData?.id!,
        {
            skip: !userData?.id,
        }
    );
    const { data: recapSponsorShip, isLoading: isLoadingRecap } =
        useGetRecapSponsorshipUserQuery(userData?.id! || 0, {
            skip: !userData?.id,
        });

    const [createSponsorCode, { isLoading: loadingSponsorCode }] =
        useCreateSponsorCodeMutation();

    return (
        <div className="flex  flex-col justify-center items-center gap-5 w-full mx-auto">
            <SlideOverComponent
                open={slideOverToggle}
                setOpen={setSlideOverToggle}
                title="Parrainage"
            >
                <dl>
                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-500">
                            Code de parrainage
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                            {user?.sponsorCode ? (
                                !loadingSponsorCode ? (
                                    <>
                                        <p className="text-center uppercase tracking-wide bg-secondBackgroundColor rounded-sm p-2">
                                            {user?.sponsorCode}
                                        </p>

                                        <CopyButton text={user?.sponsorCode} />
                                        {!user?.sponsor ||
                                        user.sponsor.length === 0 ? (
                                            <p className="text-green-500 italic">
                                                Pas encore utilisé
                                            </p>
                                        ) : (
                                            <p className="text-green-500 italic">
                                                Utilisé {user.sponsor.length}{' '}
                                                fois
                                            </p>
                                        )}
                                    </>
                                ) : (
                                    <Spinner size={5} />
                                )
                            ) : (
                                <p
                                    className="cursor-pointer rounded-md bg-mainColor text-white transition-all p-2 hover:scale-[1.02]"
                                    onClick={async () =>
                                        user &&
                                        (await createSponsorCode(user.id)
                                            .unwrap()
                                            .then((res) => {
                                                showSuccess(
                                                    'Créé',
                                                    'Code de parrainage créé avec succès'
                                                );
                                            })
                                            .catch((err) => {
                                                showError(
                                                    'Erreur',
                                                    'Erreur lors de la création du code de parrainage'
                                                );
                                            }))
                                    }
                                >
                                    Générez votre code de parrainage
                                </p>
                            )}
                        </dd>
                    </div>
                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-500">
                            Votre lien d'invitation
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-2">
                            {user?.sponsorCode ? (
                                <>
                                    <p className="py-2 px-1 bg-secondBackgroundColor rounded-md text-center w-fit max-w-[260px] break-words whitespace-normal">
                                        {window.location.origin}
                                        /SeConnecter?register=1&code=
                                        {user?.sponsorCode}
                                    </p>
                                    <CopyButton
                                        text={`${window.location.origin}/SeConnecter?register=1&code=${user?.sponsorCode}`}
                                    />
                                </>
                            ) : (
                                <p>
                                    Vous devez d'abord générer votre code de
                                    parrainage pour pouvoir partager votre lien
                                    d'invitation
                                </p>
                            )}
                        </dd>
                    </div>
                    {/* <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-semibold text-gray-500">Avantage</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-5">
              <div className="rounded-md shadow-low p-5">
                <p>
                  <span className="text-xl text-mainColor font-semibold font-titleFontFamily">
                    1 %{" "}
                  </span>{" "}
                  pour le parrain <span className="text-red-500">*</span>
                </p>
                <p className="italic text-titleColor text-sm">
                  Sur la première entrée du filleul
                </p>
              </div>
              <div className="rounded-md shadow-low p-5">
                <p>
                  <span className="text-xl text-mainColor font-semibold font-titleFontFamily">
                    0.5 %{" "}
                  </span>{" "}
                  pour le filleul <span className="text-red-500">*</span>
                </p>
                <p className="italic text-titleColor text-sm">
                  Sur la première entrée du filleul
                </p>
              </div>
            </dd>
          </div> */}
                    <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-semibold text-gray-500">
                            Comment ça fonctionne ?
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <p>
                                Partagez ce lien avec vos ami.es afin qu'ils ou
                                elles puissent s'inscrire via celui-ci. Le
                                parrainage sera alors automatique et lors de
                                leur première entrée ils auront une réduction de
                                25% de leurs frais d'entrée (1.5% au lieu de
                                2%). Vous recevrez également une compensation
                                pour chaque filleul.le parrainé.e en fonction de
                                la valeur de leur première entrée.
                            </p>
                        </dd>
                    </div>
                </dl>
            </SlideOverComponent>
            <div className="flex mt-2 w-fit cursor-pointer mx-auto">
                <p
                    className={`py-1 px-2 rounded-sm ${
                        !toogle ? ' bg-bgDashboardClient' : ''
                    }`}
                    onClick={() => setToogle(false)}
                >
                    Réalisé
                </p>
                <p
                    className={`py-1 px-2 rounded-sm ${
                        toogle ? ' bg-bgDashboardClient' : ''
                    }`}
                    onClick={() => setToogle(true)}
                >
                    Estimé
                </p>
            </div>
            {!toogle ? (
                <>
                    {isLoadingRecap ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="flex flex-col justify-center items-center rounded-md p-2">
                                <p className="text-3xl text-titleColor">
                                    {recapSponsorShip?.totalMoneyWon || 0} €
                                </p>
                                <p>Bonus reçus</p>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <p>Ami.e.s parrainné.e.s</p>
                                <div className=" min-w-[150px] max-w-[250px] mb-5 flex items-center justify-center gap-5">
                                    <Slider
                                        aria-label="amies parrainés"
                                        max={50}
                                        min={0}
                                        defaultValue={
                                            recapSponsorShip?.nbUserSponsored ||
                                            0
                                        }
                                        value={
                                            recapSponsorShip?.nbUserSponsored ||
                                            0
                                        }
                                        // className="text-secondColor"
                                        style={{ color: 'rgb(30, 197, 184)' }}
                                    />
                                    <p>
                                        {recapSponsorShip?.nbUserSponsored || 0}
                                    </p>
                                </div>
                                <p className="px-2 text-center">
                                    Réduisez les frais d'entrée de votre filleul
                                    de 25% et recevez une compensation
                                </p>
                                <PrimaryButton
                                    onClick={() => setSlideOverToggle(true)}
                                    className="my-3"
                                >
                                    Inviter un ami
                                </PrimaryButton>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="flex flex-col justify-center items-center rounded-md p-2">
                        <p className="text-3xl text-titleColor">
                            {amount * nbFilleul * 0.01} €
                        </p>
                        <p>Bonus estimés *</p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <p>Ami.e.s parrainné.e.s</p>
                        <div className=" min-w-[150px] max-w-[250px] mb-5 flex items-center justify-center gap-5">
                            <Slider
                                aria-label="amies parrainés"
                                max={50}
                                min={0}
                                defaultValue={nbFilleul}
                                value={nbFilleul}
                                onChange={(e, value) =>
                                    setNbFilleul(value as number)
                                }
                                // className="text-secondColor"
                                style={{ color: 'rgb(30, 197, 184)' }}
                            />
                            <p>{nbFilleul}</p>
                        </div>
                        <div className="grid grid-cols-1 gap-y-2 lg:gap-x-4 lg:grid-cols-2 w-fit p-0 lg:px-2 mx-auto">
                            <RadioComponent
                                register={register}
                                name="amount"
                                values={[
                                    { value: 3000, label: '1 000 - 5 000 €' },
                                    { value: 8000, label: '5 000 - 10 000 €' },
                                    {
                                        value: 16000,
                                        label: '10 000 - 20 000 €',
                                    },
                                    {
                                        value: 35000,
                                        label: '20 000 - 50 000 €',
                                    },
                                    {
                                        value: 80000,
                                        label: '50 000 - 100 000 €',
                                    },
                                    { value: 100000, label: '> 100 000 €' },
                                ]}
                                watch={watch}
                            />
                        </div>

                        <PrimaryButton
                            onClick={() => setSlideOverToggle(true)}
                            className="my-3"
                        >
                            Inviter un ami
                        </PrimaryButton>
                        <p className="text-gray-500 italic p-2 text-sm">
                            * Vague estimation, non contractuelle et dépendante{' '}
                            <span className=" normal-case text-sm">
                                in fine
                            </span>{' '}
                            du montant exact investi par vos filleul.le.s.
                        </p>
                    </div>
                </>
            )}
        </div>
    );
}

interface InterfaceTache {
    type: StatusUserTypeEnum;
    content: string;
    url?: string;
}

function StatusUser() {
    const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();
    const { data: user, isLoading: loading } =
        useGetUserWithAllInfoForCustomerQuery(userData?.id ?? 0, {
            skip: !userData,
        });

    const navigate = useNavigate();

    const [statusTodo, setStatusTodo] = useState<InterfaceTache[]>([]);
    const [statusInProgress, setStatusInProgress] = useState<InterfaceTache[]>(
        []
    );
    const [statusDone, setStatusDone] = useState<InterfaceTache[]>([]);

    useEffect(() => {
        if (user) {
            let statusUser = [...(user?.statusUser || [])];
            let status: InterfaceTache[] = [];
            let todo = statusUser?.filter(
                (s) => s.type === StatusUserTypeEnum.ToDo
            );
            if (todo && todo?.length > 0) {
                status = [...status, ...todo];
            }
            status = [
                ...status,
                user?.status === UserStatusType.newUser
                    ? {
                          type: StatusUserTypeEnum.ToDo,
                          content:
                              'Veuillez remplir vos données personnelles afin de valider votre profil',
                          url: '/DashBoardClient/donnees-personnelles',
                      }
                    : user?.status === UserStatusType.profilUnValitaded
                      ? {
                            type: StatusUserTypeEnum.ToDo,
                            content:
                                "Votre profil n'a pas été validé, veuillez envoyer à nouveau les éléments non validés",
                            url: '/DashBoardClient/donnees-personnelles',
                        }
                      : undefined,
                user?.investAccounts?.length === 0 &&
                user.status !== UserStatusType.newUser
                    ? {
                          type: StatusUserTypeEnum.ToDo,
                          content: "Veuillez créer un compte d'investissement",
                          url: '/DashBoardClient/invest-account?pageInvestAccount=add-invest-account',
                      }
                    : undefined,
                ...(user?.investAccounts
                    ?.filter((i) => !i.iban)
                    ?.map((i) => {
                        return {
                            type: StatusUserTypeEnum.ToDo,
                            content: `Veuillez finaliser votre compte ${
                                i.physicalOrMoral === 'physical'
                                    ? 'physique'
                                    : 'moral ' + i?.moralAccount?.companyName
                            }`,
                            url: `/DashBoardClient/invest-account?pageInvestAccount=edit-invest-account&investAccountId=${i.id}`,
                        };
                    }) || []),
                ,
                ...(user?.investAccounts
                    ?.filter((i) => i.status === StateStatus.unValidated)
                    ?.map((i) => {
                        return {
                            type: StatusUserTypeEnum.ToDo,
                            content: `Votre compte ${
                                i.physicalOrMoral === 'physical'
                                    ? 'physique'
                                    : 'moral ' + i?.moralAccount?.companyName
                            } n'a pas été validé, veuillez modifier votre compte d'investissement`,
                            url: `/DashBoardClient/invest-account?pageInvestAccount=edit-invest-account&investAccountId=${i.id}`,
                        };
                    }) || []),
            ].filter((s) => s !== undefined) as any[];

            setStatusTodo(status);

            status = [];
            let inProgress = statusUser?.filter(
                (s) => s.type === StatusUserTypeEnum.InComing
            );
            if (inProgress && inProgress?.length > 0) {
                status = [...status, ...inProgress];
            }

            status = [
                ...status,
                user?.status === UserStatusType.informationNeedToBeChecked
                    ? {
                          type: StatusUserTypeEnum.InComing,
                          content:
                              "Votre profile est en cours de vérification. Cela peut prendre jusqu'à 24h.",
                      }
                    : undefined,
                ...(user?.investAccounts
                    ?.filter(
                        (i) => i.status === StateStatus.unchecked && i.iban
                    )
                    ?.map((i) => {
                        return {
                            type: StatusUserTypeEnum.InComing,
                            content: `Votre compte ${
                                i.physicalOrMoral === 'physical'
                                    ? 'physique'
                                    : 'moral ' + i?.moralAccount?.companyName
                            } est en cours de vérification`,
                        };
                    }) || []),
            ]?.filter((s) => s !== undefined) as any[];

            setStatusInProgress(status);

            status = [];
            let done = statusUser?.filter(
                (s) => s.type === StatusUserTypeEnum.Done
            );
            if (done && done?.length > 0) {
                status = [...status, ...done];
            }

            status = [
                ...status,
                user?.status === UserStatusType.profileValidated
                    ? {
                          type: StatusUserTypeEnum.Done,
                          content: 'Profil validé',
                      }
                    : undefined,
                ...(user?.investAccounts
                    ?.filter((i) => i.status === StateStatus.validated)
                    ?.map((i) => {
                        return {
                            type: StatusUserTypeEnum.Done,
                            content: `Votre compte ${
                                i.physicalOrMoral === 'physical'
                                    ? 'physique'
                                    : 'moral ' + i?.moralAccount?.companyName
                            } est validé`,
                        };
                    }) || []),
            ]?.filter((s) => s !== undefined) as any[];

            setStatusDone(status);
        }
    }, [user]);

    return (
        <div className="p-2 m-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            {loadingUser || loading ? (
                <Loading />
            ) : (
                <>
                    <div className="  shadow-low rounded-lg p-3">
                        {/* <WrapComponentDashboardUser></WrapComponentDashboardUser>  */}
                        <h3 className="text-center mb-2 text-mainColor text-2xl">
                            Vos tâches en attente
                        </h3>
                        <div className="flex flex-col gap-2">
                            {statusTodo.length === 0 ||
                            (statusTodo.length === 1 &&
                                statusTodo[0] === undefined) ? (
                                <p>Vous n'avez pas de tâches en attentes.</p>
                            ) : (
                                statusTodo.map((s) => (
                                    <div
                                        className={`w-full grid md:grid-cols-[auto,auto] text-start items-center gap-2 border-l-[3px] border-secondColor bg-secondBackgroundColor px-2 py-1 rounded-e-md ${
                                            s?.url ? '' : ''
                                        }`}
                                    >
                                        <p>{s?.content}</p>
                                        {s?.url ? (
                                            <PrimaryButton
                                                className="h-fit w-fit md:justify-self-end place-self-center mb-2 md:mb-0"
                                                onClick={() => navigate(s.url!)}
                                            >
                                                Accèder
                                            </PrimaryButton>
                                        ) : null}
                                    </div>
                                ))
                            )}
                            {/* Trucs à éffecuter par le user */}
                        </div>
                    </div>

                    <div className=" shadow-low rounded-lg p-3">
                        <h3 className="text-center mb-2 text-mainColor text-2xl">
                            Tâches en cours
                        </h3>
                        <div className="flex flex-col gap-2">
                            {statusInProgress.length === 0 ||
                            (statusInProgress.length === 1 &&
                                statusInProgress[0] === undefined) ? (
                                <p>Vous n'avez pas de tâches en cours.</p>
                            ) : (
                                statusInProgress.map((s) => (
                                    <div
                                        className={`w-full  grid md:grid-cols-[auto,auto] text-start items-center gap-2 border-l-[3px] border-titleColor bg-secondBackgroundColor px-2 py-1 rounded-e-md ${
                                            s?.url ? '' : ''
                                        }`}
                                    >
                                        <p>{s?.content}</p>
                                        {s?.url ? (
                                            <PrimaryButton
                                                className="h-fit w-fit md:justify-self-end place-self-center mb-2 md:mb-0"
                                                onClick={() => navigate(s.url!)}
                                            >
                                                Accèder
                                            </PrimaryButton>
                                        ) : null}
                                    </div>
                                ))
                            )}
                            {/* Trucs à éffecuter par le user */}
                        </div>
                    </div>
                    <div className=" shadow-low rounded-lg p-3">
                        <h3 className="text-center mb-2 text-mainColor text-2xl">
                            Tâches terminées
                        </h3>
                        <div className="flex flex-col gap-2">
                            {statusDone.length === 0 ||
                            (statusDone.length === 1 &&
                                statusDone[0] === undefined) ? (
                                <p>Vous n'avez pas de tâches terminées.</p>
                            ) : (
                                statusDone.map((s) => (
                                    <div className="w-11/12 text-start flex gap-2">
                                        <SuccessIcon />
                                        <p>{s?.content}</p>
                                    </div>
                                ))
                            )}
                            {/* Trucs à éffecuter par le user */}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

function Questions() {
    const faqs = [
        {
            question: "What's the best thing about Switzerland?",
            answer: "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
        },
        {
            question: "What's the best thing about Switzerland?",
            answer: "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
        },
        // More questions...
    ];
    return (
        <div className="border-b-2 pb-2">
            <h3 className="text-center mb-2 text-mainColor text-2xl">
                Questions
            </h3>
            <div className="">
                {faqs.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-6">
                        {({ open }) => (
                            <>
                                <dt>
                                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                        <span className="text-base font-semibold leading-7">
                                            {faq.question}
                                        </span>
                                        <span className="ml-6 flex h-7 items-center">
                                            {open ? (
                                                <FontAwesomeIcon
                                                    icon={faMinus}
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faPlus}
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </span>
                                    </Disclosure.Button>
                                </dt>
                                <Disclosure.Panel
                                    as="dd"
                                    className="mt-2 pr-12"
                                >
                                    <p className="text-base leading-7 text-gray-600">
                                        {faq.answer}
                                    </p>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                ))}
            </div>
        </div>
    );
}
