import BigTitleComponent from '@components/commun/BigTitleComponent';
import { PrimaryButton, WhiteButton } from '@components/commun/Buttons';
import AddInvestAccountComponent from '@components/commun/formComponent/InvestAccount/AddInvestAccountComponent';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import {
    onSubmitDocumentAddress,
    onSubmitDocumentIdentity,
} from '@components/commun/formComponent/OnSubmitHelpers';
import { useNotificationContext } from '@components/Context/notification-context';
import UpdateUserInfo from '@components/DashboardUser/Profile/UpdateUserInfo';
import {
    FormUpdateUserInvestAccountDto,
    UserWithAllInfoDto,
} from '@interfaces/user';
import { hasProperty } from '@utils/Utils';
import React from 'react';
import { useNavigate } from 'react-router';
import { NumberParam, useQueryParam } from 'use-query-params';

interface Props {
    user: UserWithAllInfoDto;
    setToogleInvestInfo: React.Dispatch<React.SetStateAction<boolean>>;
    isUserAccepted: boolean;
    children: React.ReactNode;
}
const AddInvestAccountInvestir: React.FC<Props> = ({
    user,
    setToogleInvestInfo,
    children,
    isUserAccepted,
}) => {
    const navigate = useNavigate();

    const { showError, showSuccess } = useNotificationContext();

    const [investAccountIdQuery, setInvestAccountIdQuery] = useQueryParam(
        'investAccountId',
        NumberParam
    );

    // const [documentPart, setDocumentPart] = useState(false)

    // const { ValidationCode, handleSubmitForm } = useValidationCode({
    //   onSubmit: handleSubmit(onSubmit),
    // });

    return (
        <div className="form_investisseur_container">
            <TitleComponentForm className="!w-full !text-secondColor">
                Information générale
            </TitleComponentForm>

            {/* <ValidationCode>
            <p className="text-lg text-titleColor">
              Afin de valider la création du compte d'investissement vous devez
              rentrer ci-dessous le code envoyé par mail
            </p>
          </ValidationCode> */}
            <AddInvestAccountComponent
                user={user}
                useSubmitBtn={false}
                onSubmitSuccess={({ investAccount }) => {
                    setInvestAccountIdQuery(investAccount.id);
                    setToogleInvestInfo((curr) => !curr);
                }}
                beforeSubmitLogic={async ({ data }) => {
                    let succeed = true;

                    if (!hasProperty(data, 'userInfoInvestAccount')) {
                        return false;
                    }

                    // update doc adress
                    succeed = await onSubmitDocumentAddress(
                        (
                            data.userInfoInvestAccount as FormUpdateUserInvestAccountDto
                        ).documentAdress,
                        showError,
                        showSuccess,
                        user?.id!
                    );

                    // update doc indentity
                    succeed = await onSubmitDocumentIdentity(
                        (
                            data.userInfoInvestAccount as FormUpdateUserInvestAccountDto
                        ).documentIdentity1,
                        showError,
                        showSuccess,
                        user?.id!
                    );
                    return succeed;
                }}
                defaultValues={{
                    userId: user.id,
                    physicalOrMoral: 'physical',
                    userInfoInvestAccount: {
                        adress: user?.userInfo?.adress ?? '',
                        phone: user?.userInfo?.phone ?? '',
                        professionalSituation:
                            user?.userInfo?.professionalSituation ?? '',
                        politicalyExposed:
                            user?.userInfo?.politicalyExposed == undefined ||
                            user?.userInfo?.politicalyExposed == null
                                ? undefined
                                : user?.userInfo?.politicalyExposed
                                  ? 'true'
                                  : 'false',
                        politicalyExposedDetails:
                            user?.userInfo?.politicalyExposedDetails ?? '',

                        usCitizen: 'false',
                    },
                }}
            >
                {({ formState, disabled, watch, loading }) => (
                    <>
                        {watch('physicalOrMoral') === 'physical' &&
                        user?.investAccounts?.find(
                            (i) => i.physicalOrMoral === 'physical'
                        ) ? null : (
                            <>
                                <BigTitleComponent className=" mt-7">
                                    Vérifiez vos informations personnelles
                                </BigTitleComponent>
                                <UpdateUserInfo formState={formState as any}>
                                    {/* <h3 className="w-full font-mainFontFamily text-center text-xl">
              Mettez à jour vos documents d'identités si nécessaire
            </h3> */}
                                </UpdateUserInfo>
                                {children}

                                <div className="w-full mt-3 mb-2 flex justify-center gap-3 items-center">
                                    <WhiteButton onClick={() => navigate(-1)}>
                                        Annuler
                                    </WhiteButton>
                                    <PrimaryButton
                                        type="submit"
                                        loading={loading}
                                        disabled={
                                            watch(
                                                'userInfoInvestAccount.usCitizen'
                                            ) == 'true' ||
                                            disabled ||
                                            !isUserAccepted
                                                ? true
                                                : false
                                        }
                                    >
                                        Valider
                                    </PrimaryButton>
                                </div>
                            </>
                        )}
                    </>
                )}
            </AddInvestAccountComponent>
        </div>
    );
};

export default AddInvestAccountInvestir;
