import React, { useEffect, useState } from "react";
import {
  faArrowLeft,
  faDownload,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import NotFound from "../../../pages/NotFound";
import update from "../../../assets/edit.svg";
import deleteSVG from "./../../../assets/delete.svg";
import pictureSVG from "./../../../assets/picture.svg";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { isEmpty, toInt } from "../../../function/Utils";
import {
  deleteProjet,
  editProjet,
  getProjectDocuments,
  getProjets,
  uploadCoverProjet,
} from "../../../redux/features/projet";
import { DocumentProjectDto, ProjectDto } from "../../../types/project";
import DisplayDocumentProject from "../../commun/DisplayDocuments/DisplayDocumentProject";

interface Props {
  idProjet_fonds: string;
}

const Projet_i: React.FC<Props> = ({ idProjet_fonds }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [projet, setProjet] = useState<ProjectDto | undefined>();
  const [idProjet, setIdProjet] = useState<number | undefined>();
  const [idFonds, setIdFonds] = useState<number | undefined>();
  const userData = useAppSelector((state) => state.userReducer.user);
  const projetsData = useAppSelector((state) => state.projetReducer.projets);

  const [titreToggle, setTitreToggle] = useState<boolean>(false);
  const [titre, setTitre] = useState<string>("");
  const [textPrensentationToggle, setTextPrensentationToggle] =
    useState<boolean>(false);
  const [textPresentation, setTextPresentation] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);

  const documents = useAppSelector(
    (state) => state.projetReducer.documentProject
  );

  useEffect(() => {
    if (idProjet) {
      dispatch(getProjectDocuments(idProjet));
    }
  }, [idProjet]);

  useEffect(() => {
    if (!isEmpty(projetsData) && !isEmpty(idProjet_fonds)) {
      let idList = idProjet_fonds.split("_");
      setIdProjet(toInt(idList[0]));
      setIdFonds(toInt(idList[1]));
      let projet_ = projetsData.filter(
        (projet) => projet.id == toInt(idList[0])
      );
      setProjet(projet_[0]);
      if (projet_[0]?.description) setTextPresentation(projet_[0]?.description);
      setTitre(projet_[0]?.name);
    }
  }, [projetsData, idProjet_fonds]);

  const handlePicture = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (file && projet?.id) {
      let data = new FormData();
      data.append("id", projet?.id.toString());
      data.append("file", file);

      await dispatch(uploadCoverProjet(data));
      dispatch(getProjets());
    }
  };

  // const handleTitreSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   if (projet) {
  //     const data = {
  //       ...projet,
  //       nom: titre,
  //     };

  //     await dispatch(editProjet(data));
  //     setTitreToggle(!titreToggle);
  //     dispatch(getProjets());
  //   }
  // };

  // const handleTextSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   if (projet) {
  //     const data = {
  //       ...projet,
  //       textPresentation: textPresentation,
  //     };

  //     await dispatch(editProjet(data));
  //     setTextPrensentationToggle(!textPrensentationToggle);
  //     dispatch(getProjets());
  //   }
  // };

  const showProjet = () => {
    // if (!projet?.show) {
    //   return (
    //     <p
    //       className="pointer"
    //       style={{ backgroundColor: "red" }}
    //       onClick={() => {
    //         changeShowProjet(true);
    //       }}
    //     >
    //       Offline
    //     </p>
    //   );
    // } else {
    //   return (
    //     <p
    //       className="pointer"
    //       style={{ backgroundColor: "green" }}
    //       onClick={() => {
    //         changeShowProjet(false);
    //       }}
    //     >
    //       Online
    //     </p>
    //   );
    // }
  };

  // const changeShowProjet = async (bool: boolean) => {
  //   if (projet) {
  //     const data = {
  //       ...projet,
  //       show: bool,
  //     };

  //     await dispatch(editProjet(data));
  //     dispatch(getProjets());
  //   }
  // };

  const handleDelete = async () => {
    let bol = window.confirm("Voulez vous vraiment supprimer ce projet ?");
    if (bol && projet && projet.id) {
      dispatch(deleteProjet(projet.id));
      await navigate(`/DashBoardAdmin/Fonds/${idFonds}`);
    }
  };

  return (
    <React.Fragment>
      {!isEmpty(projet) && (!isEmpty(userData) || projet?.show) ? (
        <div className="item_container config_1 fonds_element_container">
          <Link
            to={`/DashBoardAdmin/Fonds/${idFonds}`}
            className="margintop-30px"
          >
            <div className="absolute">
              <p>
                {" "}
                <FontAwesomeIcon icon={faArrowLeft} /> Retourner à la
                présentation du fonds
              </p>
            </div>
          </Link>
          <div className="T_1 sub_item_container boxShadow_false">
            <div className="show_container">
              {/* {!isEmpty(projet) && showProjet()} */}
            </div>
            <div className="element_modifiable_container">
              <div className="edit-delete flex space-x-4">
                <div
                  className="hidden container md:flex justify-center items-center pointer"
                  onClick={() => setTitreToggle(!titreToggle)}
                >
                  <img
                    src={update}
                    alt="edit"
                    className="pointer h-3 w-3 m-0 p-0"
                  />
                  <p>Edit title</p>
                </div>
                <div
                  className="container flex justify-center items-center pointer mt-4 md:mt-0"
                  onClick={() => handleDelete()}
                >
                  <img src={deleteSVG} alt="delete" className="pointer" />
                  <p className="">Supprimer ce projet</p>
                </div>
              </div>
              {titreToggle ? (
                <form>
                  <textarea
                    defaultValue={projet?.name}
                    onChange={(e) => setTitre(e.target.value)}
                    className="border border-texteColor"
                  ></textarea>
                  <input
                    className="submit pointer"
                    type="submit"
                    value="Valider la modification"
                  />
                </form>
              ) : (
                <h2
                  onClick={(e) => setTitreToggle(!titreToggle)}
                  className="pointer text-3xl"
                >
                  {projet?.name}
                </h2>
              )}
            </div>
            {documents ? (
              <>
                {documents?.map((d, key) => (
                  <DisplayDocumentProject document={d} key={key} />
                ))}
              </>
            ) : null}
            <div className="element_modifiable_container">
              <form
                className="formImageCover img"
                onSubmit={(e) => handlePicture(e)}
              >
                <img
                  className="svg pointer right-5"
                  src={pictureSVG}
                  alt="img"
                />
                <input
                  className="fileInput pointer right-5 top-0"
                  type="file"
                  id="file-upload"
                  name="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => {
                    if (e.target.files) setFile(e.target.files[0]);
                  }}
                />

                <img
                  // src={projet?.cover ?? undefined}
                  alt={`Image représentant le thème du fonds`}
                  className="w-screen rounded-sm md:mx-auto md:max-w-lg"
                />
                <br />
                <input
                  className="pointer submit"
                  type="submit"
                  value="Envoyer"
                />
              </form>
            </div>
            <div className="element_modifiable_container">
              <div className="edit-delete">
                <img
                  onClick={() => {
                    setTextPrensentationToggle(!textPrensentationToggle);
                  }}
                  src={update}
                  alt="edit"
                  className="pointer"
                />
              </div>
              {textPrensentationToggle ? (
                <form>
                  <textarea
                    defaultValue={projet?.description ?? undefined}
                    onChange={(e) => setTextPresentation(e.target.value)}
                    className="border border-texteColor"
                  ></textarea>
                  <input
                    className="submit pointer"
                    type="submit"
                    value="Valider la modification"
                  />
                </form>
              ) : (
                <div
                  className="textPrensentation pointer"
                  onClick={() =>
                    setTextPrensentationToggle(!textPrensentationToggle)
                  }
                >
                  <p>{projet?.description}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </React.Fragment>
  );
};

export default Projet_i;
