import React, { useEffect, useState } from "react";
import update from "../../assets/edit.svg";
import deleteSVG from "../../assets/delete.svg";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { CategoryArticleInterface } from "../../types/Articles";
import {
  deleteCategoryArticle,
  editCategoryArticle,
} from "../../redux/features/categoryArticle";

interface Props {
  categorie: CategoryArticleInterface;
}

const CategorieElement: React.FC<Props> = ({ categorie }) => {
  const dispatch = useAppDispatch();

  const userData = useAppSelector((state) => state.userReducer.user);

  const [editToggle, setEditToggle] = useState<boolean>(false);
  const [editContent, setEditContent] = useState<string>(categorie.content);
  const [editType, setEditType] = useState<string>(categorie.type);

  const handleDelete = (categorie: CategoryArticleInterface) => {
    let bol = window.confirm(
      `Voulez vous vraiment supprimer cette catégorie ?`
    );
    if (bol && categorie.id) {
      dispatch(deleteCategoryArticle(categorie.id));
    }
  };

  const handleForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let data = {
      content: editContent,
      type: editType,
      id: categorie.id,
    };

    setEditToggle(!editToggle);
    dispatch(editCategoryArticle(data));
  };

  return (
    <React.Fragment>
      {userData?.admin ? (
        <div className="categorie_element">
          <div className="edit-delete">
            <img
              onClick={() => setEditToggle(!editToggle)}
              src={update}
              alt="edit"
              className="pointer"
            />
            <img
              src={deleteSVG}
              alt="delete"
              onClick={() => handleDelete(categorie)}
              className="pointer"
            />
          </div>
          {editToggle ? (
            <form onSubmit={(e) => handleForm(e)}>
              <input
                type="text"
                name="type"
                placeholder="type"
                value={editType}
                className="input"
                onChange={(e) => setEditType(e.target.value)}
                required
              />
              <input
                type="text"
                name="content"
                className="input"
                placeholder="content"
                value={editContent}
                onChange={(e) => setEditContent(e.target.value)}
                required
              />
              <input
                type="submit"
                value="Modifier"
                className="pointer submit"
              />
            </form>
          ) : (
            <React.Fragment>
              <span>{categorie.type}</span> : {categorie.content}
            </React.Fragment>
          )}
        </div>
      ) : (
        <div className="categorie_element">
          <span>{categorie.type}</span> : {categorie.content}
        </div>
      )}
    </React.Fragment>
  );
};

export default CategorieElement;
