import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navigation from "../commun/Navigation";
import ArticleElement from "./ArticleElement";
import { useSelector } from "react-redux";
import Footer from "../commun/Footer";
import { useAppSelector } from "../../redux/store";
import { isEmpty, toInt } from "../../function/Utils";
import { Article } from "../../types/Articles";
import NotFound from "../../pages/NotFound";

const ArticleComponent = () => {
  // const navigate = useNavigate();
  const [article, setArticle] = useState<Article | null>(null);

  let { id } = useParams();
  const userData = useAppSelector((state) => state.userReducer.user);
  const articlesData = useAppSelector((state) => state.articleReducer.articles);

  useEffect(() => {
    if (!isEmpty(articlesData)) {
      let article_ = articlesData.filter((article) => article.id == id);
      setArticle(article_[0]);
    }
  }, [articlesData, id]);

  const affichageArticle = () => {
    if (!isEmpty(article)) {
      if (!isEmpty(userData) || article?.show) {
        return (
          <React.Fragment>
            <Navigation bol_nosfonds={false} bol_transparent={false} />
            {id && <ArticleElement id={toInt(id)} />}
            <Footer />
          </React.Fragment>
        );
      }
    } else {
      return <NotFound />;
    }
  };

  return <React.Fragment>{affichageArticle()}</React.Fragment>;
};

export default ArticleComponent;
