import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';
import { printLargeValue } from '../../../function/Utils';
import {
    useGetFondsQuery,
    useGetInvestFondsDocumentsQuery,
} from '../../../redux/features/fondsSlice';
import { useFetchTokenQuery } from '../../../redux/features/tokenSlice';
import {
    InvestInFundsByUserRequest,
    useGetEntranceFeeForTransactionQuery,
    useGetTransactionsUserQuery,
    useInvestInFundsByUserMutation,
} from '../../../redux/features/transactionSlice';
import {
    useGetAllSubscriptionsUserQuery,
    useGetUserWithAllInfoForCustomerQuery,
} from '../../../redux/features/userSlice';
import {
    InvestInFundsDto,
    InvestInFundsForm,
    PickFundsForm,
} from '../../../types/InvestirType';
import { TransactionStatus } from '../../../types/transactions';
import HoverTextWrapper from '../../commun/animation/HoverTextWrapper';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import FieldArray from '../../commun/formComponent/FieldArray';
import InputComponent from '../../commun/formComponent/InputComponent';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import { useNotificationContext } from '../../Context/notification-context';
import TableComponent from '../../DashboardAdmin/BackOffice/Components/TableComponent';

const PickFunds = () => {
    const { data: user, isLoading: loadingUser } = useFetchTokenQuery();
    const {
        data: transactions,
        isLoading: isLoadingTransaction,
        refetch: refecthTransactionsUser,
    } = useGetTransactionsUserQuery(user?.id ?? 0, {
        skip: !user,
    });

    const [step, setStep] = useQueryParam('stepInvest', NumberParam);

    const { data: fonds, isLoading: isLoadingFonds } = useGetFondsQuery();

    const { refetch: refetchUserAll } = useGetUserWithAllInfoForCustomerQuery(
        user?.id!,
        {
            skip: !user?.id,
        }
    );

    const { data: documents, isLoading: isLoadingDocuments } =
        useGetInvestFondsDocumentsQuery();

    const transactionsValidated =
        transactions?.filter((t) => t.status === TransactionStatus.VALIDATED) ||
        [];

    const { data: entranceFeeRes, isLoading: isLoadingEntranceFee } =
        useGetEntranceFeeForTransactionQuery(
            {
                userId: user?.id!,
            },
            {
                skip: !user,
            }
        );

    const entranceFee =
        entranceFeeRes?.entranceFee !== undefined
            ? entranceFeeRes?.entranceFee
            : 0.02;

    const {
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        control,
        formState: { isValid },
    } = useForm<PickFundsForm>({});

    useEffect(() => {
        if (
            fonds &&
            (watch('values')?.length === 0 || watch('values') === undefined)
        ) {
            let values: InvestInFundsForm[] = fonds.reduce(
                (acc: InvestInFundsForm[], f) => {
                    let value: InvestInFundsForm[];
                    if (f.id === 1) {
                        value = [
                            {
                                fondsId: f.id,
                                nbShares: 0,
                                typeShare: 'cumulative',
                                amount: 0,
                            },
                            {
                                fondsId: f.id,
                                nbShares: 0,
                                typeShare: 'distribution',
                                amount: 0,
                            },
                        ];
                    } else {
                        value = [
                            {
                                fondsId: f.id,
                                nbShares: 0,
                                typeShare: 'cumulative',
                                amount: 0,
                            },
                        ];
                    }
                    return acc.concat(value);
                },
                []
            );

            setValue('values', values);
        }
    }, [fonds]);

    const [investAccountIdQuery, setInvestAccountIdQuery] = useQueryParam(
        'investAccountId',
        NumberParam
    );

    const { refetch: refetchSubscriptions } = useGetAllSubscriptionsUserQuery(
        user?.id ?? 0,
        {
            skip: user === undefined,
        }
    );

    const [investInfunds, { isLoading: isLoadingInvestInFunds }] =
        useInvestInFundsByUserMutation();

    const { showSuccess, showError } = useNotificationContext();

    const [subscriptionId, setSubscriptionId] = useQueryParam(
        'subscriptionId',
        NumberParam
    );
    const navigate = useNavigate();
    const [investConfirmed, setInvestConfirmed] = useState(false);

    const onSubmit = async (data: PickFundsForm) => {
        if (!investConfirmed) {
            setInvestConfirmed(true);
            return;
        }
        setInvestConfirmed((curr) => !curr);

        let values: InvestInFundsDto[] = data.values.map((d) => {
            return {
                amount: parseFloat(
                    (d.nbShares * 100 * (1 + entranceFee)).toFixed(2)
                ),
                fondsId: d.fondsId,
                typeShare: d.typeShare,
            };
        });

        let request: InvestInFundsByUserRequest = {
            userId: user?.id!,
            investAccountId: investAccountIdQuery!,
            values: values,
        };

        await investInfunds(request)
            .unwrap()
            .then(async (res) => {
                showSuccess('Créée(s)', 'Transaction(s) créée(s) avec succès');

                await new Promise((resolve) => setTimeout(resolve, 500));
                await refecthTransactionsUser();
                await refetchUserAll();
                await refetchSubscriptions();
                setSubscriptionId(res.subscriptionId);
                setStep(2);
            })
            .catch((err: any) => {
                showError(
                    'Erreur',
                    'Erreur lors de la création de la transaction',
                    err.data?.message
                );
            });
    };

    const totalAmount = useMemo(() => {
        return (
            watch('values')?.reduce((acc, curr) => {
                return acc + curr.nbShares * 100 * (1 + entranceFee);
            }, 0) || 0
        );
    }, [watch(), entranceFee]);

    const totalAmountWithoutFee = useMemo(() => {
        return (
            watch('values')?.reduce((acc, curr) => {
                return acc + curr.nbShares * 100 * 1;
            }, 0) || 0
        );
    }, [watch(), entranceFee]);

    const [isValidTerm, setIsValidTerm] = useState(false);

    const isValidFormMemo = useMemo(() => {
        if (
            transactionsValidated?.length === 0 &&
            totalAmountWithoutFee < 1000
        ) {
            return false;
        }
        if (transactionsValidated?.length > 0 && totalAmountWithoutFee < 300) {
            return false;
        }
        if (!isValidTerm) {
            return false;
        }
        return true;
    }, [transactionsValidated, isValidTerm, totalAmountWithoutFee]);

    const contactUs = [1, 2, 4, 6];

    return (
        <div className="mt-2 px-2 w-full">
            {investConfirmed ? (
                <ConfirmInvest
                    open={investConfirmed}
                    setOpen={setInvestConfirmed}
                    totalAmount={totalAmount}
                    onClick={handleSubmit(onSubmit)}
                />
            ) : null}
            {isLoadingTransaction || loadingUser || isLoadingFonds ? (
                <Loading />
            ) : (
                <>
                    <h2 className="text-center font-mainFontFamily text-2xl mb-3">
                        Enregistrez vos intentions d'investissement
                    </h2>

                    {/* {transactionsValidated?.length === 0 ? (
            <p className=" text-center">
              Pour un première investissement le montant total de votre
              investissement doit etre au minimum{" "}
              <span className="text-mainColor">1000€</span>.
            </p>
          ) : (
            <p className=" text-center">
              Ce n'est pas votre premier investissement vous pouvez réinvestir à
              partir de <span className="text-mainColor">300€</span>
            </p>
          )} */}
                    <p className=" text-center">
                        Pour le premier investissement, vous devez investir un
                        minimum de{' '}
                        <span className="text-mainColor">1000 €</span>, et pour
                        tout réinvestissement, au moins{' '}
                        <span className="text-mainColor">300 €</span> sont
                        requis.
                    </p>

                    {/* <p className=" italic text-orange-400">
            Pour le moment, les titres D sont uniquement disponible pour le
            fonds Immobilier
          </p> */}
                    <div className="form_investisseur_container">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className={``}
                            style={{ width: `98%` }}
                        >
                            <div
                                className={`w-full mt-4 mx-auto flex flex-col
         
      `}
                            >
                                <FieldArray control={control} name="values">
                                    {({ fields, append, remove }) => (
                                        <>
                                            <TableComponent
                                                head={[
                                                    'Type de titre',
                                                    'Fonds',
                                                    'Documents',
                                                    'Nombre de titres',
                                                    'Montant',
                                                    "Frais d'entrée",
                                                    'Montant total',
                                                ]}
                                            >
                                                <tbody>
                                                    {fields.map(
                                                        (field, index) => (
                                                            <tr
                                                                key={field.id}
                                                                className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                                                                    index %
                                                                        2 ===
                                                                    0
                                                                        ? 'bg-gray-100'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <td className="pl-1">
                                                                    {watch(
                                                                        `values.${index}.typeShare`
                                                                    ) ===
                                                                    'cumulative'
                                                                        ? 'Titre C'
                                                                        : 'Titre D'}
                                                                    {index ===
                                                                        0 ||
                                                                    index === 1
                                                                        ? '*'
                                                                        : ''}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        fonds?.find(
                                                                            (
                                                                                f
                                                                            ) =>
                                                                                f.id ===
                                                                                watch(
                                                                                    `values.${index}.fondsId`
                                                                                )
                                                                        )?.name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <div className="flex gap-2">
                                                                        {documents
                                                                            ?.filter(
                                                                                (
                                                                                    d
                                                                                ) =>
                                                                                    d.fondsId ===
                                                                                    watch(
                                                                                        `values.${index}.fondsId`
                                                                                    )
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    document
                                                                                ) => (
                                                                                    <a
                                                                                        key={
                                                                                            document.id
                                                                                        }
                                                                                        href={
                                                                                            document.url
                                                                                        }
                                                                                        target="_blank"
                                                                                        className="text-mainColor"
                                                                                    >
                                                                                        <HoverTextWrapper
                                                                                            hoverText={
                                                                                                document.type
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={
                                                                                                    faFilePdf
                                                                                                }
                                                                                                className="text-titleColor w-5 h-5"
                                                                                            />
                                                                                        </HoverTextWrapper>
                                                                                    </a>
                                                                                )
                                                                            )}
                                                                    </div>
                                                                </td>
                                                                {contactUs.includes(
                                                                    index
                                                                ) ? (
                                                                    <td
                                                                        className=""
                                                                        colSpan={
                                                                            4
                                                                        }
                                                                    >
                                                                        <div className="flex items-center justify-center w-full h-10 bg-gray-200 ">
                                                                            <Link
                                                                                to={
                                                                                    '/Contact'
                                                                                }
                                                                                className="text-mainColor"
                                                                            >
                                                                                Contactez-nous
                                                                                pour
                                                                                investir
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                ) : (
                                                                    <>
                                                                        <InputComponent
                                                                            register={
                                                                                register
                                                                            }
                                                                            type="number"
                                                                            min={
                                                                                0
                                                                            }
                                                                            value={`values.${index}.nbShares`}
                                                                            className={{
                                                                                input: 'max-w-[140px]',
                                                                            }}
                                                                        />
                                                                        <td className=" self-center">
                                                                            {printLargeValue(
                                                                                (
                                                                                    (watch(
                                                                                        `values.${index}.nbShares`
                                                                                    ) ||
                                                                                        0) *
                                                                                    100
                                                                                ).toFixed(
                                                                                    2
                                                                                )
                                                                            )}{' '}
                                                                            €
                                                                        </td>
                                                                        <td className=" self-center">
                                                                            {printLargeValue(
                                                                                (
                                                                                    (watch(
                                                                                        `values.${index}.nbShares`
                                                                                    ) ||
                                                                                        0) *
                                                                                    100 *
                                                                                    entranceFee
                                                                                ).toFixed(
                                                                                    2
                                                                                )
                                                                            )}{' '}
                                                                            €
                                                                        </td>
                                                                        <td className=" self-center">
                                                                            {printLargeValue(
                                                                                (
                                                                                    (watch(
                                                                                        `values.${index}.nbShares`
                                                                                    ) ||
                                                                                        0) *
                                                                                    100 *
                                                                                    (1 +
                                                                                        entranceFee)
                                                                                ).toFixed(
                                                                                    2
                                                                                )
                                                                            )}{' '}
                                                                            €
                                                                        </td>
                                                                    </>
                                                                )}
                                                            </tr>
                                                        )
                                                    )}
                                                    <tr className="bg-gray-200 font-semibold">
                                                        <td className="pl-1">
                                                            Total
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="pl-2">
                                                            {printLargeValue(
                                                                watch('values')
                                                                    ?.reduce(
                                                                        (
                                                                            acc,
                                                                            curr
                                                                        ) =>
                                                                            acc +
                                                                            parseFloat(
                                                                                curr.nbShares as any
                                                                            ),
                                                                        0
                                                                    )
                                                                    .toFixed(2)
                                                            )}
                                                        </td>
                                                        <td>
                                                            {printLargeValue(
                                                                watch('values')
                                                                    ?.reduce(
                                                                        (
                                                                            acc,
                                                                            curr
                                                                        ) =>
                                                                            acc +
                                                                            parseFloat(
                                                                                curr.nbShares as any
                                                                            ) *
                                                                                100,
                                                                        0
                                                                    )
                                                                    .toFixed(2)
                                                            )}{' '}
                                                            €
                                                        </td>
                                                        <td>
                                                            {printLargeValue(
                                                                watch('values')
                                                                    ?.reduce(
                                                                        (
                                                                            acc,
                                                                            curr
                                                                        ) =>
                                                                            acc +
                                                                            parseFloat(
                                                                                curr.nbShares as any
                                                                            ) *
                                                                                100 *
                                                                                entranceFee,
                                                                        0
                                                                    )
                                                                    .toFixed(2)
                                                            )}{' '}
                                                            €
                                                        </td>
                                                        <td>
                                                            {printLargeValue(
                                                                watch('values')
                                                                    ?.reduce(
                                                                        (
                                                                            acc,
                                                                            curr
                                                                        ) =>
                                                                            acc +
                                                                            parseFloat(
                                                                                curr.nbShares as any
                                                                            ) *
                                                                                100 *
                                                                                (1 +
                                                                                    entranceFee),
                                                                        0
                                                                    )
                                                                    .toFixed(2)
                                                            )}{' '}
                                                            €
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </TableComponent>

                                            <div className=" bottom-[4px] left-2 flex flex-wrap gap-2 text-gray-500 italic text-md">
                                                <p className=" ">
                                                    * Titre C: Optimisation de
                                                    votre performance
                                                </p>
                                                <p>-</p>
                                                <p>
                                                    * Titre D: Complément de
                                                    revenu mensuel
                                                </p>
                                            </div>
                                            <div className="w-full md:w-fit bg-bgDashboardClient rounded-md mx-auto mt-10 px-2 gap-x-2 grid grid-cols-[2fr,1fr]">
                                                <p>
                                                    Montant total de votre
                                                    investissement
                                                </p>
                                                <p className=" text-secondColor font-semibold text-center">
                                                    {printLargeValue(
                                                        totalAmount.toFixed(2)
                                                    )}{' '}
                                                    €
                                                </p>
                                            </div>
                                            <div className="w-full text-center mx-auto">
                                                {transactionsValidated?.length ===
                                                    0 &&
                                                totalAmountWithoutFee < 1000 ? (
                                                    <p className=" text-red-400">
                                                        Le montant total n'est
                                                        pas suffisant, vous
                                                        devez investir au moins
                                                        1000 €
                                                    </p>
                                                ) : null}
                                                {transactionsValidated?.length >
                                                    0 &&
                                                totalAmountWithoutFee < 300 ? (
                                                    <p className=" text-red-400">
                                                        Le montant total n'est
                                                        pas suffisant, vous
                                                        devez investir au moins
                                                        300 €
                                                    </p>
                                                ) : null}
                                            </div>
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                            <p className=" font-mainFontFamily text-lg mt-4 md:mt-[30px] text-center">
                                Vous pouvez choisir deux types de titre
                            </p>
                            <div className="flex flex-col gap-1 mt-1 mb-5 md:mb-[40px] ">
                                <p className=" text-mainColor">
                                    Titre C - Optimisation de votre performance
                                </p>
                                <p>
                                    Les titres C{' '}
                                    <span className="text-mainColor">
                                        capitalisent{' '}
                                    </span>
                                    votre performance en générant des intérêts
                                    sur des intérêts, sans imposition
                                    intermédiaire.
                                </p>
                                <p className=" text-mainColor mt-4">
                                    Titre D - Complément de revenu mensuel
                                </p>
                                <p>
                                    Les titres D{' '}
                                    <span className="text-mainColor">
                                        distribuent
                                    </span>{' '}
                                    votre performance sous forme de revenu
                                    mensuel.
                                </p>
                            </div>
                            <AcceptTerms
                                isValidTerm={isValidTerm}
                                setIsValidTerm={setIsValidTerm}
                            />
                            <div className="flex mt-3 items-center justify-center">
                                <PrimaryButton
                                    loading={isLoadingInvestInFunds}
                                    disabled={!isValidFormMemo}
                                    type="submit"
                                >
                                    Valider
                                </PrimaryButton>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </div>
    );
};

function ConfirmInvest({
    totalAmount,
    open,
    onClick,
    setOpen,
}: {
    totalAmount: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClick: (data: any) => void;
}) {
    return (
        <PopUp
            open={open}
            buttonBoolean={false}
            setOpen={setOpen}
            width="w-full md:max-w-[500px]"
            title={() => {
                return (
                    <div className="text-lg text-center font-semibold">
                        Confirmez votre investissement
                    </div>
                );
            }}
        >
            <div className="flex flex-col items-center justify-center">
                <div className="w-full flex flex-col gap-3 mt-2">
                    <p>
                        Vous êtes sur le point d'investir un montant total de{' '}
                        <br />
                        <span className="text-mainColor font-semibold">
                            {printLargeValue(totalAmount.toFixed(2))} €
                        </span>
                    </p>
                    <p className=" text-justify">
                        Une fois confirmée, vous ne pourrez plus modifier votre
                        souscription. Si vous souhaitez modifier votre
                        souscription, veuillez supprimer celle-ci et en créer
                        une nouvelle.
                    </p>
                </div>
                <div className="flex mt-3 justify-center items-center gap-3">
                    <WhiteButton onClick={() => setOpen(false)}>
                        Annuler
                    </WhiteButton>
                    <PrimaryButton onClick={onClick}>Confirmer</PrimaryButton>
                </div>
            </div>
        </PopUp>
    );
}

export default PickFunds;

interface AcceptTermsForm {
    term1: boolean;
    term2: boolean;
    term3: boolean;
    term4: boolean;
    term5: boolean;
}

function AcceptTerms({
    isValidTerm,
    setIsValidTerm,
}: {
    isValidTerm: boolean;
    setIsValidTerm: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { register, watch } = useForm<AcceptTermsForm>({
        defaultValues: {
            term1: false,
            term2: false,
            term3: false,
            term4: false,
            term5: false,
        },
    });

    useEffect(() => {
        if (
            watch('term1') &&
            watch('term2') &&
            watch('term3') &&
            watch('term4') &&
            watch('term5')
        ) {
            setIsValidTerm(true);
        } else {
            setIsValidTerm(false);
        }
    }, [watch()]);

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="w-full grid md:grid-cols-[auto,1fr] gap-x-2 mt-2">
                <input
                    type="checkbox"
                    className="w-4 h-4 mr-2 self-center"
                    id="term1"
                    {...register('term1')}
                />
                <label className=" text-justify cursor-pointer" htmlFor="term1">
                    Je confirme avoir pris connaissance du document
                    d’information synthétique ainsi que du Document
                    d'informations clés pour l’investisseur (DICI).
                    <span className="text-red-500">*</span>
                </label>
            </div>
            <div className="w-full grid md:grid-cols-[auto,1fr] gap-x-2 mt-2">
                <input
                    type="checkbox"
                    className="w-4 h-4 mr-2 self-center"
                    id="term2"
                    {...register('term2')}
                />
                <label className=" text-justify cursor-pointer" htmlFor="term2">
                    Je reconnais réserver cette souscription sans avoir été
                    sollicité par une offre publique de vente ou une démarche de
                    placement financier directe ou indirecte, conformément à la
                    législation luxembourgeoise en vigueur.
                    <span className=" text-red-500">*</span>
                </label>
            </div>
            <div className="w-full grid md:grid-cols-[auto,1fr] gap-x-2 mt-2">
                <input
                    type="checkbox"
                    className="w-4 h-4 mr-2 self-center"
                    id="term3"
                    {...register('term3')}
                />
                <label className=" text-justify cursor-pointer" htmlFor="term3">
                    Je certifie que les fonds qui seront utilisés pour ma
                    souscription ne proviennent pas d'une activité illicite et
                    ne sont pas destinés au blanchiment d'argent ou au
                    financement du terrorisme, conformément à la loi
                    luxembourgeoise relative à la lutte contre le blanchiment
                    d'argent et le financement du terrorisme.
                    <span className=" text-red-500">*</span>
                </label>
            </div>
            <div className="w-full grid md:grid-cols-[auto,1fr] gap-x-2 mt-2">
                <input
                    type="checkbox"
                    className="w-4 h-4 mr-2 self-center"
                    id="term4"
                    {...register('term4')}
                />
                <label className=" text-justify cursor-pointer" htmlFor="term4">
                    J'accepte de recevoir les convocations aux assemblées
                    générales par voie électronique en lieu et place d'un envoi
                    postal, si cela est prévu.
                    <span className=" text-red-500">*</span>
                </label>
            </div>
            <div className="w-full grid md:grid-cols-[auto,1fr] gap-x-2 mt-2">
                <input
                    type="checkbox"
                    className="w-4 h-4 mr-2 self-center"
                    id="term5"
                    {...register('term5')}
                />
                <label className=" text-justify cursor-pointer" htmlFor="term5">
                    En poursuivant, je reconnais accepter les risques associés à
                    cet investissement, y compris le risque de perte en capital
                    et le risque d’illiquidité.
                    <span className=" text-red-500">*</span>
                </label>
            </div>
        </div>
    );
}
