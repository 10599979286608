import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    CategoryQuestion,
    KnowledgeQuestionDto,
} from '../../../../../types/KnowledgeDto';
import { PrimaryButton, WhiteButton } from '../../../../commun/Buttons';
import PopUp from '../../../../commun/PopUp';
import { EtapeForm } from '../../../../Context/AppContext';
import DisplayCategoryQuestion from './DisplayCategoryQuestion';
import DisplayGoodAnswerQuestion from './DisplayGoodAnswerQuestion';

export interface CategoryQuestionConfig {
    category: CategoryQuestion;
    showGoogdAnswer: boolean;
}

interface Props {
    categories: CategoryQuestionConfig[];
    stepQuestionName:
        | 'stepQuestionFormProfil'
        | 'stepKnowledgeQuestionFormProfil';
}

const DisplayCategoriesQuestion: React.FC<Props> = ({
    categories,
    stepQuestionName,
}) => {
    const [stepQuestions, setStepQuestions] = useState<number>(
        parseInt(sessionStorage.getItem(stepQuestionName) || '0')
    );

    const etapeObject = useContext(EtapeForm);

    useEffect(() => {
        sessionStorage.setItem(stepQuestionName, stepQuestions.toString());
        const elementDashBoard = document.querySelector(
            '.form_investisseur_container'
        ) as HTMLElement;
        elementDashBoard.scrollTo(0, 0);
    }, [stepQuestionName, stepQuestions]);

    const categoryConfig = useMemo(
        () => categories[stepQuestions],
        [categories, stepQuestions]
    );

    const [toogleShowGoodAnswer, setToogleShowGoodAnswer] = useState(false);

    return (
        <DisplayCategoryQuestion
            className="w-full px-auto md:w-11/12 md:mx-auto lg:w-9/12"
            category={categoryConfig.category as CategoryQuestion}
            renderButtons={({ isValid, isLoadingEditUser }) => (
                <div className="flex flex-col justify-center items-center gap-3 my-10">
                    <div className=" flex gap-3">
                        {stepQuestions > 0 && (
                            <WhiteButton
                                onClick={() =>
                                    setStepQuestions(stepQuestions - 1)
                                }
                            >
                                Précedent
                            </WhiteButton>
                        )}
                        <PrimaryButton
                            type="submit"
                            disabled={!isValid}
                            loading={isLoadingEditUser}
                        >
                            Continuer
                        </PrimaryButton>
                    </div>
                    <p
                        className="pointer"
                        onClick={() => {
                            etapeObject?.setStep(etapeObject?.step - 1);
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} /> Revenir à l'étape
                        précendente
                    </p>
                </div>
            )}
            beforeSubmitLogic={({ isDirty }) => {
                if (!isDirty) {
                    if (stepQuestions === categories.length - 1) {
                        etapeObject?.setStep(etapeObject?.step + 1);
                        return false;
                    }

                    setStepQuestions(stepQuestions + 1);
                    return false;
                } else {
                    return true;
                }
            }}
            afterSubmitLogic={() => {
                if (categoryConfig.showGoogdAnswer) {
                    setToogleShowGoodAnswer(true);
                    return;
                }

                if (stepQuestions === categories.length - 1) {
                    etapeObject?.setStep(etapeObject?.step + 1);
                }

                setStepQuestions(stepQuestions + 1);
            }}
            showGoogdAnswer={({ questions }) => (
                <>
                    {categoryConfig.showGoogdAnswer && toogleShowGoodAnswer ? (
                        <ShowGoodAnswerComponent
                            open={toogleShowGoodAnswer}
                            setOpen={setToogleShowGoodAnswer}
                            questions={questions}
                            onValidate={() => {
                                if (stepQuestions === categories.length - 1) {
                                    setToogleShowGoodAnswer(false);
                                    etapeObject?.setStep(etapeObject?.step + 1);
                                }

                                setStepQuestions(stepQuestions + 1);
                            }}
                        />
                    ) : null}
                </>
            )}
        />
    );
};

export default DisplayCategoriesQuestion;

function ShowGoodAnswerComponent({
    open,
    setOpen,
    questions,
    onValidate,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    questions: KnowledgeQuestionDto[];
    onValidate: () => void;
}) {
    return (
        <PopUp
            setOpen={setOpen}
            open={open}
            disabledOnclose={true}
            title={() => {
                return <></>;
            }}
            buttonBoolean={false}
            width="w-full md:w-10/12 lg:w-9/12"
        >
            <DisplayGoodAnswerQuestion
                questions={questions}
                onValidate={onValidate}
                category={CategoryQuestion.funds_functionning}
            />
            {/* <div className="flex my-6 flex-col w-fit mx-auto gap-3">
                <PrimaryButton onClick={() => onValidate()}>
                    Continuer
                </PrimaryButton>
            </div> */}
        </PopUp>
    );
}
