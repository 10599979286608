import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetSponsorTable, SponsorCodeDto, SponsorDto } from "../../types/Sponsor";
import { baseQueryHandleError } from '../index.rtk';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}sponsor`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export const sponsorApi = createApi({
    reducerPath: 'sponsorApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: ['Sponsor'],
    endpoints: (builder) => ({
        getAllSponsor: builder.query<{
            rows: SponsorDto[],
            count: number
        }, GetSponsorTable>({
            query: (payload) => ({
                url: `/`,
                method: 'GET',
                params: payload
            }),
        }),
        getSponsorToBeRefund: builder.query<SponsorDto[], number>({
            query: (id) => `/getSponsorToBeRefund/${id}`,
        }),
    }),
})

export const { useGetAllSponsorQuery, useGetSponsorToBeRefundQuery } = sponsorApi