import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cover from "../../../assets/immo_home.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { CreateFondsDto, FondsDto } from "../../../types/fonds";
import { addFonds, getFonds } from "../../../redux/features/fonds";
import { isEmpty } from "../../../function/Utils";
import { addProjet } from "../../../redux/features/projet";
import { useGetFondsQuery } from "../../../redux/features/fondsSlice";
import { MenuDashBoardAdminBoolState } from "../../Context/AppContext";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryParam, StringParam, NumberParam } from "use-query-params";
import { useGetDashbordUserInfoQuery } from "../../../redux/features/shareApiSlice";
import { DisplayFondsImpact } from "../../DashboardUser/Activities";
import FondsUser from "../../DashboardUser/Fonds/FondsUser";
import ProjectUser from "../../DashboardUser/Fonds/ProjectUser";
import WrapComponentDashboardUser from "../../DashboardUser/commun/WrapComponentDashboardUser";
import { WhiteButton, PrimaryButton } from "../../commun/Buttons";
import Loading from "../../commun/Loading";
import {
  LazyImage,
  LazyImageBackground,
} from "../../commun/animation/LazyImage";
import FondsAdmin from "./FondsAdmin";
import PopUp from "../../commun/PopUp";
import AddFonds from "./AddFonds";

const IndexFondsAdmin = () => {
  const menuBoolState = useContext(MenuDashBoardAdminBoolState);

  const [queryPage, setQueryPage] = useQueryParam(
    "pageFondsAdmin",
    StringParam
  );
  const [queryFondsId, setQueryFondsId] = useQueryParam("fondsId", NumberParam);
  const [queryProjectId, setQueryProjectId] = useQueryParam(
    "projectId",
    NumberParam
  );
  const [queryPageEdit, setQueryPageEdit] = useQueryParam(
    "pageFondsEdit",
    StringParam
  );

  const { data: dashBordUserAdmin, isLoading } = useGetDashbordUserInfoQuery();

  const handleClickReturn = () => {
    switch (queryPage) {
      case "fonds":
        setQueryPage(undefined);
        setQueryFondsId(undefined);
        setQueryPageEdit(undefined);
        break;
      case "project":
        setQueryPage("fonds");
        setQueryProjectId(undefined);
        break;
      default:
        setQueryPage(undefined);
        setQueryFondsId(undefined);
        setQueryProjectId(undefined);
        break;
    }
  };

  const displaySubtitle = (): string => {
    switch (queryPage) {
      case "fonds":
        return (
          dashBordUserAdmin?.fondsInfo.find((f) => f.fondsId === queryFondsId)
            ?.fondsName ?? ""
        );
      default:
        return "";
    }
  };

  const selectComponent = () => {
    if (queryPage === "fonds" && queryFondsId) {
      return <FondsAdmin />;
    } else {
      return <OverviewFonds />;
    }
  };

  return (
    <div
      className={`mx-auto  md:mt-0 lg:px-2 md:pt-[7px] pb-[10px] md:w-[100%] h-[100vh] overflow-auto `}
    >
      <WrapComponentDashboardUser
        title="Nos fonds"
        description="Découvrez nos différents fonds d'investissement."
        subTitle={
          <div className="flex gap-3 justify-end items-center">
            <p className="text-xl text-titleColor">{displaySubtitle()}</p>
            <WhiteButton className="" onClick={() => handleClickReturn()}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="mr-2 text-secondColor"
              />
              Retour
            </WhiteButton>
          </div>
        }
        classNameHeader={`${!menuBoolState?.toggleMenu ? "!pl-[40px]" : ""}`}
        classNameContainer={`${!menuBoolState?.toggleMenu ? "" : ""}`}
      >
        <div className=" overflow-auto h-[83vh] md:h-[84vh]">
          {isLoading ? <Loading /> : <>{selectComponent()}</>}
        </div>
      </WrapComponentDashboardUser>
    </div>
  );
};

function OverviewFonds() {
  const { data: dashBordUserAdmin, isLoading } = useGetDashbordUserInfoQuery();

  const [queryPage, setQueryPage] = useQueryParam(
    "pageFondsAdmin",
    StringParam
  );
  const [queryFondsId, setQueryFondsId] = useQueryParam("fondsId", NumberParam);

  const [pageQuery, setPageQuery] = useQueryParam("pageFondsEdit", StringParam);

  const [open, setOpen] = useState(false);

  return (
    <div className="gap-5 mt-5 justify-center md:justify-normal flex flex-wrap w-full md:w-11/12 mx-auto mb-3">
      <div className="flex w-full my-3 items-center justify-center">
        <PrimaryButton type="button" onClick={() => setOpen(true)}>
          Ajouter un fonds
        </PrimaryButton>
      </div>
      {open ? <AddFondsComponent open={open} setOpen={setOpen} /> : null}
      {dashBordUserAdmin?.fondsInfo.map((f) => (
        <div
          key={f.fondsId}
          onClick={() => {
            setQueryPage("fonds");
            setQueryFondsId(f.fondsId);
            setPageQuery("basic-info");
          }}
          className="grid py-1 w-[300px] md:w-[350px] bg-secondBackgroundColor rounded-md grid-rows-fondsCard cursor-pointer transition-all hover:opacity-70 hover:scale-[1.01]"
        >
          <h3 className="my-1 text-center font-mainFontFamily text-2xl text-gray-500">
            {f.fondsName}
          </h3>
          <LazyImage
            src={f.cover || ""}
            className={{
              img: "w-full h-[200px] bg-cover bg-center rounded-sm",
              div: "h-[200px] my-2",
            }}
          />
          <div className="grid grid-cols-3 place-items-center font-semibold text-center">
            <p>TRI</p>
            <p>Taux de défaut</p>
            <p>Impact</p>
          </div>
          <div className="grid grid-cols-3 place-items-center text-center">
            <p className="text-secondColor">
              {f.triNotBlackListLast30Days.toFixed(2)} %
            </p>
            <p className="">0.00 %</p>
            <div className="flex justify-center items-start mx-auto w-fit">
              <DisplayFondsImpact name={f.fondsName} />
            </div>
          </div>
          <div className="flex w-full mw-auto justify-center items-center my-2">
            <PrimaryButton>Découvrir</PrimaryButton>
          </div>
        </div>
      ))}
    </div>
  );
}

export default IndexFondsAdmin;

function AddFondsComponent({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <PopUp
      open={open}
      setOpen={setOpen}
      buttonBoolean={false}
      width="w-full md:w-9/12"
      title={() => {
        return (
          <h3 className="text-3xl font-semibold leading-6 text-gray-900">
            Ajouter un fonds
          </h3>
        );
      }}
    >
      <AddFonds open={open} setOpen={setOpen} />
    </PopUp>
  );
}
