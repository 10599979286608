import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { transformDate } from '../../../function/Utils';
import {
    UpdateProjectInterestRequestDto,
    useEditProjectInterestMutation,
    useGetProjectByIdQuery,
} from '../../../redux/features/projectSlice';
import { useNotificationContext } from '../../Context/notification-context';
import { PrimaryButton } from '../../commun/Buttons';
import Loading from '../../commun/Loading';
import FieldArray from '../../commun/formComponent/FieldArray';
import InputComponent from '../../commun/formComponent/InputComponent';
import RemoveFieldArrayComponent from '../../commun/formComponent/RemoveFieldArrayComponent';
export function Interest({ projectId }: { projectId: number }) {
    const {
        data: project,
        isLoading: isLoadingProject,
        isFetching: isFetchingProject,
        refetch: refetchProject,
    } = useGetProjectByIdQuery(projectId ?? 0, {
        skip: projectId === undefined,
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        reset,
        control,
    } = useForm<UpdateProjectInterestRequestDto>();

    const [editProject, { isLoading: isLoadingEditProject }] =
        useEditProjectInterestMutation();

    const setDefautlValues = (): void => {
        if (project) {
            reset({
                projectId: project?.id,
                dateOfTheChange: new Date().toJSON()?.split('T')[0] as any,
                interests:
                    project?.interests.map((pi) => {
                        return {
                            value: pi.value,
                            id: pi.id,
                            date: new Date(pi.date)
                                .toJSON()
                                ?.split('T')[0] as any,
                        };
                    }) ?? [],
            });
        }
    };

    const { showError, showSuccess } = useNotificationContext();

    useEffect(() => {
        if (project) {
            setDefautlValues();
        }
    }, [project]);

    const onSubmit = async (data: UpdateProjectInterestRequestDto) => {
        await editProject({
            projectId: data.projectId,
            dateOfTheChange: transformDate(data.dateOfTheChange),
            interests: data.interests.map((i) => {
                return {
                    date: transformDate(i.date),
                    value: i.value,
                    id: i.id,
                };
            }),
        })
            .unwrap()
            .then(async (res) => {
                showSuccess('Edited', 'Project interest edited successfully');
            })
            .catch((err) => {
                showError('Error', 'Error editing project interest');
            });
    };

    return (
        <>
            {isLoadingProject ? (
                <Loading />
            ) : (
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={`formTemplateSingUp`}
                    style={{ width: `92%` }}
                >
                    <div className="w-full">
                        <div
                            className={`w-full mx-auto grid grid-cols-1
        `}
                        >
                            <TitleComponentForm>
                                List des intérêt en fonction de la date
                            </TitleComponentForm>
                            <FieldArray
                                control={control}
                                name="interests"
                                className=" max-w-lg mx-auto"
                            >
                                {({ fields, append, remove }) => (
                                    <div className="flex flex-col w-full justify-center">
                                        <div className="flex flex-col w-full space-y-2 mb-2">
                                            {fields.map((field, index) => (
                                                <div
                                                    key={field.id}
                                                    className="grid grid-cols-2 w-full space-x-2 relative"
                                                >
                                                    <InputComponent
                                                        register={register}
                                                        type="date"
                                                        value={`interests.${index}.date`}
                                                        cursorNotAllowed={
                                                            index === 0
                                                                ? true
                                                                : undefined
                                                        }
                                                        // className={{ input: "row-span-1 col-span-1" }}
                                                    >
                                                        <h3 className="text-left">
                                                            Date{' '}
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </h3>
                                                    </InputComponent>

                                                    <InputComponent
                                                        register={register}
                                                        type="number"
                                                        value={`interests.${index}.value`}
                                                        // className={{ input: "row-span-1 col-span-1" }}
                                                    >
                                                        <h3 className="text-left">
                                                            Intérêt{' '}
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </h3>
                                                    </InputComponent>
                                                    {index > 0 ? (
                                                        <RemoveFieldArrayComponent
                                                            remove={remove}
                                                            index={index}
                                                        />
                                                    ) : null}
                                                </div>
                                            ))}
                                        </div>
                                        <PrimaryButton
                                            className=" mx-auto mb-2"
                                            onClick={() =>
                                                append({
                                                    date: new Date()
                                                        .toJSON()
                                                        ?.split('T')[0] as any,
                                                    value: 0,
                                                    // projectId: project.current?.id!,
                                                })
                                            }
                                        >
                                            Ajouter
                                        </PrimaryButton>
                                    </div>
                                )}
                            </FieldArray>
                        </div>
                        <div className="w-full md:max-w-sm mx-auto">
                            <InputComponent
                                value={`dateOfTheChange`}
                                register={register}
                                type="date"
                            >
                                <h3 className="text-left">
                                    Date de la modification{' '}
                                    <span className="required">*</span>
                                </h3>
                            </InputComponent>
                        </div>

                        <div className="mt-5 mx:auto flex justify-center items-center text-center">
                            <PrimaryButton
                                type="submit"
                                className="w-fit"
                                loading={isLoadingEditProject}
                            >
                                Edit
                            </PrimaryButton>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
}
