import { Chart } from "chart.js";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  addDays,
  compareTwoDate,
  createDateArray,
  debounce,
  formatDate,
  lastMonth,
  monthNumberToString,
  setDate,
  transformDate,
} from "../../../../function/Utils";
import { useAppSelector } from "../../../../redux/store";
import { TransactionDto } from "../../../../types/transactions";
import {
  BackOfficeState,
  MenuDashBoardAdminBoolState,
  RootBlackOfficeState,
} from "../../../Context/AppContext";

import { fondsColor } from "../../../../data/FondsData";
import { Line } from "react-chartjs-2";
import { FeeValueDto } from "../../../../types/shareApi";
import { DataSets } from "../../MetricsPage/MetricsFeeValue";
import Loading from "../../../commun/Loading";
import { useGetLiquidityForDashboardAdminQuery } from "../../../../redux/features/shareApiSlice";

const Liquidity = () => {
  const pageState = useAppSelector(
    (state) => state.pageMeasurement.measurements
  );

  const backOfficeState = useContext(BackOfficeState);

  const menuBoolState = useContext(MenuDashBoardAdminBoolState);

  const rootState = useContext(RootBlackOfficeState);

  const date = setDate(
    backOfficeState?.year!,
    backOfficeState?.month!,
    backOfficeState?.day ?? 1
  );

  const {
    data: feeValuesfromdb,
    isLoading: isLoadingFeeValues,
    isFetching: isFetchingFeeValues,
  } = useGetLiquidityForDashboardAdminQuery(addDays(date, 1), {
    skip: !date,
  });

  return (
    <React.Fragment>
      {isLoadingFeeValues ? (
        <Loading />
      ) : (
        <GraphComponent data={feeValuesfromdb || []} groupby="day" />
      )}
    </React.Fragment>
  );
};

export default Liquidity;

const GraphComponent = ({
  data,
  groupby,
}: {
  data: FeeValueDto[];
  groupby: "day" | "month" | "year" | "week";
}) => {
  const [graphData, setGraphData] = useState<any>({
    datasets: [],
  });

  useEffect(() => {
    // Filter data based on feeId

    // Group data by fondsId and prepare datasets
    const datasets = data.reduce((acc: DataSets, currentValue, i) => {
      if (!acc[currentValue.fondsId]) {
        acc[currentValue.fondsId] = {
          label: `${currentValue?.fondsName}`,
          data: [],
          borderColor: fondsColor[i], // Implement this function to assign different colors
          fill: false,
        };
      }
      acc[currentValue.fondsId].data.push({
        x:
          groupby === "day" || groupby === "month"
            ? formatDate(new Date(currentValue.date))
            : (currentValue.date.toString() as any),
        y: currentValue.value,
      });
      return acc;
    }, {});

    // Set the graph data
    setGraphData({
      datasets: Object.values(datasets),
    });
  }, [data]);

  return (
    <div className="">
      <h2 className=" bg-bgDashboardClient mr-1 pl-4 p-2 uppercase rounded-md mt-2 font-mainFontFamily text-2xl text-mainColor">
        {data?.[0]?.feeName}
      </h2>

      <Line data={graphData} />
    </div>
  );
};
