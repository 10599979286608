import { CreateProjectInterestDto, CreateProjectInterestDtoForm, ProjectInterestDto, UpdateProjectInterestDto, UpdateProjectInterestDtoForm } from "./ProjectInterest";
import { BlackListDto } from "./blackList";
import { TypePayement } from "./fonds";
import { ParentCompanyDto } from "./parentCompanyType";
import { CreateProjectsValorisationDto, ProjectValorisationDto, UpdateProjectsValorisationDto } from "./projectValorisation";
import { ShareholderDto } from "./shareholderType";
import { TransactionDto, TransactionStatus, TypeInvestisseurEnum, TypeTransaction, TypeTransactionProject2Cash, UpdateTransactionDtoForm } from "./transactions";

export interface GetProjectClotureRequest {
    fondsId: number;
    filter: FilterClosure;
    searchProjectName: string;
    searchPlatformName: string;
    limit: number;
    offset: number;
}

export interface ProportionTransactionUserInProjectDto {
    // transactionId: number;
    // valoTransaction: number;
    // results: {
    //     projectId: number;
    //     proportion: number;
    // }[]
    projectId: number;
    proportion: number;
}

export interface GetProjectsValorisationRequest {
    fondsId: number;
    dateStart: Date;
    dateEnd: Date;
    searchProjectName: string;
    searchPlatformName: string;
    offset: number;
    limit: number;
}

export type FilterClosure = "open" | "closed" | "all";

export interface ProjectTableClosureDto {
    id: number;
    name: string;
    dateStart: Date;
    dateEnd: Date;
    platformName: string;
    closed: boolean;
    amount?: number;
}

export interface ViewTableClosureDto {
    rows: ProjectTableClosureDto[];
    count: number;
}

export interface UpdateProjectStatusDto {
    id: number;
    status: ProjectStatus;
}


export interface ProjectDto {
    id: number;
    realName: string;
    notationPlatform?: null | string;
    name: string;
    description: null | string;
    content?: string
    show: boolean;
    closed: boolean;
    dateStart: Date;
    dateEnd: Date;
    fondsId: number;
    fondsName: string;
    duration: null | number;
    url?: null | string;
    platformId?: null | number;
    platformName?: null | string;
    payment?: null | TypePayement;
    delayAccepted?: null | number;
    compoundInterestBool?: null | boolean;
    compoundInterest?: null | number;
    latitude?: null | number;
    longitude?: null | number;
    interests: ProjectInterestDto[];
    documents?: DocumentProjectDto[];
    address?: string;
    confidential: boolean;
    status: ProjectStatus;
    projectInfo?: ProjectInfoDto;
    businessContributorIncome: number;
    dateInterest?: Date;
    dateBlackList?: Date;
    dateBlackListDeletedAt?: Date;
    blacklist?: BlackListDto;
    shareholders?: ShareholderDto[];
    parentCompanies?: ParentCompanyDto[];


}

export interface ProjectForUserDto {
    id: number;
    name: string;
    description: null | string;
    content?: string;
    show: boolean;
    valorisation: number;
    cover?: string;
    closed: boolean;
    subSector?: string;
    sector?: string;
    notationPlatform?: null | string;
    projectMarketTypology?: string[];
    realName: string;
    dateBlackList?: Date;
    notationRisque?: string; // take the last reporting and take the notation
    dateStart: Date;
    dateEnd: Date;
    fondsId: number;
    fondsName: string;
    delayAccepted?: null | number;
    duration: null | number;
    url?: null | string;
    platformId?: null | number;
    platformName?: null | string;
    latitude?: null | number;
    longitude?: null | number;
    interests: ProjectInterestDto[];
    address?: string;
    confidential: boolean;
    status: ProjectStatus;
    projectInfo?: ProjectInfoDto;

}

export interface ProjectInfoDto {
    id: number;
    monthProject: boolean;
    capital?: number;
    projectSponsorTypology?: string;
    projectSponsorTypologyOther?: string;
    fundingType?: string;
    fundingTypeOther?: string;
    obligationType?: string;
    comment?: string;
    projectId: number;
    projectMarketTypologyOther?: string;
    projectMarketTypology?: ProjectMarketTypologyDto[];
    seniorityLevel?: SeniorityLevelDto[];
}


export interface GetProjectsOverviewUserRequest {
    fondsId: number;
    dateStart: Date;
    dateEnd: Date;
    searchProjectName: string;
    searchPlatformName: string;
    limit: number;
    offset: number;
    userId: number;
    closed?: boolean;
}

export interface ProjectsOverviewUserDto {
    id: number;
    name: string;
    interests: ProjectInterestDto[];
    dateStart: Date;
    dateEnd: Date;
    platformName: string;
    cover?: string;
    description?: string;
    address?: string;
    latitude?: number;
    longitude?: number;
    fundingType?: string;
    projectMarketTypology?: string[];
    sector?: string;
    subSector?: string;
    duration?: number;
    payment?: TypePayement;
    notationPlatform?: string;
    status: ProjectStatus;
    notationRisque?: string; // take the last reporting and take the notation
    dateBlackList?: Date;

}

export interface GetProjectsOverviewUserResponse {
    rows: ProjectsOverviewUserDto[];
    count: number;
    subSector: string[]

}

export enum ProjectStatus {
    subscriptionOnGoing = "En cours de souscription",
    ntr = "Rien à signaler",
    contractualDelay = 'En retard contractuel',
    noncontractualDelay = 'En retard non contractuel',
    recoverySituation = 'En situation de recouvrement',
    default = 'Defaut',
    closed = 'Terminé',
}

export interface ProjectSearchDto {
    id: number;
    name: string;
}


export interface ProjectSeedInfoDto {
    id: number;
    name: string;
    show: boolean;
    closed: boolean;
    dateStart: Date;
    dateEnd: Date;
    fondsId: number;
    fondsName: string;
    platformId?: null | number;
    platformName?: null | string;
    interests: ProjectInterestDto[];
    confidential: boolean;
    status: ProjectStatus;
    dateInterest?: Date;
}
export interface ProjectWithValorisationDto extends ProjectSeedInfoDto {
    valorisation: ProjectValorisationDto[];
}

export interface ViewProjectValorisationDto {
    rows: ProjectWithValorisationDto[]
    count: number
}

export interface CashBaseInfoDto {
    id: number;
    fondsId: number;
}

export interface CreateProjectInfoDto {

    capital?: number;
    projectSponsorTypology?: string;
    projectSponsorTypologyOther?: string;
    fundingType?: string;
    fundingTypeOther?: string;
    obligationType?: string;
    comment?: string;
    projectId?: number;
    projectMarketTypologyOther?: string;
    projectMarketTypology?: number[];
    seniorityLevel?: number[];
    monthProject?: boolean | string;

}

export interface UpdateProjectInfoDto extends CreateProjectInfoDto {
    id: number;
}


export interface CreateProjectDto {
    name: string;
    description?: null | string;
    notationPlatform?: null | string;
    parentCompanyIds?: number[]
    shareholderIds?: number[]
    fondsId: number;
    platformId?: null | number;
    dateStart: Date;
    url: null | string;
    dateEnd: Date;
    show: boolean;
    delayAccepted?: null | number;
    payment?: null | TypePayement;
    compoundInterest?: null | number;
    compoundInterestBool: boolean;
    duration: number;
    latitude?: null | number;
    longitude?: null | number;
    interests: CreateProjectInterestDto[];
    address?: string;
    confidential: boolean;
    status: ProjectStatus;
    projectInfo?: CreateProjectInfoDto;
    businessContributorIncome: number;
    sectorId: number;
    subSectorId?: number;

}

export interface CreateProjectDtoForm {
    name: string;
    description?: string;
    content?: string;
    fondsId: number;
    platformId?: null | number;
    notationPlatform?: null | string;
    dateStart: Date | string;
    dateEnd: Date | string;
    show: boolean;
    parentCompanyIds?: number[]
    shareholderIds?: number[]
    url: null | string;
    delayAccepted?: null | number;
    payment?: null | TypePayement;
    compoundInterest?: null | number;
    compoundInterestBool: boolean;
    duration: number
    latitude?: null | number;
    longitude?: null | number;
    interests: CreateProjectInterestDtoForm[];
    amount?: number;
    address?: string;
    confidential: boolean | string;
    status: ProjectStatus;
    projectInfo?: CreateProjectInfoDto;
    businessContributorIncome: number;
    sectorId: number;
    subSectorId?: number;


}

export interface UpdateProjectDto extends CreateProjectDto {
    id: number;
    closed: boolean;
    interests: UpdateProjectInterestDto[];
}



export interface TransactionDtoForm {
    id: number;
    type: TypeTransaction;
    from: number;
    to: number;
    amount: number;
    date: Date | string;
    entranceFee: null | number;
    donationFee: null | number;
    comment: null | string;
    interest: null | number;
    initialValueOfShare: null | number;
    typeInvestisseurId: number;
    feesValue: null | number;
    actualValueOfShareBool: null | boolean;
    fondsId: number;
    transactionUserId: null | number;
    emptiedTransaction: null | boolean;
    dateInterest: null | Date | string;
    typeTransactionProject2Cash?: TypeTransactionProject2Cash;
    status: TransactionStatus

}

export interface UpdateProjectDtoForm extends CreateProjectDtoForm {
    typeTransactionProject2Cash: any;
    id: number;
    closed: boolean;
    interests: UpdateProjectInterestDtoForm[];
    transactionsSubscription?: TransactionDtoForm[]
    transactionsOut?: TransactionDtoForm[]
}

export interface UpdateProjectBasicInfo extends CreateProjectDto {
    id: number;
    interests: UpdateProjectInterestDto[];

}
export interface UpdateProjectBasicInfoFormDtoForm extends CreateProjectDtoForm {
    id: number
    interests: UpdateProjectInterestDtoForm[];

}

export interface UpdateProjectClosureDto {
    id: number;
    closed: boolean;
    status: ProjectStatus
    dateEnd: Date | string;
    valorisation: CreateProjectsValorisationDto;
}

export enum TypeDocumentProject {
    cover = 'Cover',
    shareIssue = 'ShareIssue',
    project = 'Project',
    sponsorProject = 'SponsorProject',
    reporting = 'Reporting',
    schedule_Payment = 'Schedule_Payment',
    other = 'autre'
}

export interface DocumentProjectDto {
    id: number;
    type: TypeDocumentProject;
    key: string;
    fileName: string;
    comment?: string;
    projectId: number;
    date: Date;
    url?: string;
}

export interface CreateDocumentProjectDto {
    type: TypeDocumentProject;
    fileName: string;
    comment?: string;
    projectId: number;
    fileType: string;
    key: string;

}

export interface UpdateDocumentProjectDto extends Partial<CreateDocumentProjectDto> {
    id: number;
}

export interface CreateSeniorityLevelDto {
    value: string;
}

export interface SeniorityLevelDto {
    id: number;
    value: string;
}

export interface CreateProjectMarketTypologyDto {
    value: string;
    typeAssociation: "all" | "specific";
    fondsIds?: number[];
}

export interface UpdateProjectMarketTypologyDto extends Partial<CreateProjectMarketTypologyDto> {
    id: number;
}


export enum TypeVariable {
    string = "string",
    number = "number",
    boolean = "boolean",
}


export interface ProjectMarketTypologyDto {
    id: number;
    value: string;
    marketTypologyImpacts: MarketTypologyImpactDto[];
    typeAssociation: "all" | "specific";
    fonds: {
        name: string;
        id: number;
    }[];
}

export interface ReportingDto {
    id: number,
    notation?: string,
    fondsId: number,
    comment?: string,
    date: Date,
    projectId: number,
    documents: DocumentProjectDto[]
}

export interface CreateReportingDto {
    notation?: string,
    fondsId: number,
    comment?: string,
    date: Date,
    projectId: number,
}

export interface CreateReportingDtoForm {
    notation?: string,
    fondsId: number,
    comment?: string,
    date: Date | string,
    projectId: number,
}

export interface UpdateReportingDto extends CreateReportingDto {
    id: number;
}

export interface UpdateReportingDtoForm extends CreateReportingDtoForm {
    id: number;
}


export interface UpdateIssuedProjectDto {
    projectId: number;
    valorisation: number;
    date: Date;
    dateInterest: Date;
    fondsId: number;

}

export interface UpdateIssuedProjectDtoForm {
    projectId: number;
    valorisation: number;
    date: Date | string;
    dateInterest: Date | string;
    fondsId: number;
}



export interface ProjectAllImpactsDto {
    projectImpactGeneral?: ProjectImpactDto;
    projectImpactMarketTypology?: ProjectMarketTypologyDto[];
    projectImpactAccordingFunds?: ImpactsAccordingFundsDto[];
    projectImpactSectors?: ProjectSectorDto;
}

export interface UpdateProjectAllImpactsDto {
    projectId: number;
    projectImpactGeneral: UpdateProjectImpactDto,
    projectImpactMarketTypology: UpdateProjectImpactMarketTypologyDto[]
    projectImpactAccordingFunds: UpdateImpactValuesAccordingFundsDto[]
    projectImpactSectors: UpdateProjectSectorValueDto[]
}

export interface ProjectImpactDto {
    id: number;
    sectorId: number;
    subSectorId?: number;

    sectorName: string;
    equityCapital: number;
    equityCrowdfunding: number;
    projectId: number;
    environmentalComment: { value: string }[]
    socialComment: { value: string }[]
    economicalComment: { value: string }[]
    E_score: number
    S_score: number
    G_score: number
}

export interface CreateProjectImpactDto {
    sectorId: number;
    equityCapital: number;
    subSectorId?: number;

    equityCrowdfunding: number;
    projectId: number;
    fondsId: number;
    environmentalComment: { value: string }[]
    socialComment: { value: string }[]
    economicalComment: { value: string }[]
    E_score: number
    S_score: number
    G_score: number
}

export interface UpdateProjectImpactDto extends CreateProjectImpactDto {
    id?: number;
}

export interface MarketTypologyImpactDto {
    id: number;
    variable: string;
    typeVariable: TypeVariable;
    title?: string;
    comment?: string;
    result?: ProjectImpactMarketTypologyDto
    // marketTypology: ProjectMarketTypologyDto
}

export interface CreateMarketTypologyImpactDto {
    variable: string;
    typeVariable: TypeVariable;
    title?: string;
    comment?: string;
    projectMarketTypologyId: number;
}

export interface ProjectImpactMarketTypologyDto {
    id: number;
    value: string;
    projectId: number;
    marketTypologyImpactId: number;
}

export interface CreateProjectImpactMarketTypologyDto {
    value: string;
    projectId: number;
    marketTypologyImpactId: number;
}

export interface UpdateProjectImpactMarketTypologyDto extends CreateProjectImpactMarketTypologyDto {
    id?: number;
}

export interface ImpactsAccordingFundsDto {
    id: number;
    variable: string;
    typeVariable: TypeVariable;
    title?: string;
    comment?: string;
    impactValue?: ImpactValuesAccordingFundsDto;

}

export interface ImpactValuesAccordingFundsDto {
    id: number;
    value: string;
    impactVariableId: number;
    projectId: number;
}

export interface CreateImpactValuesAccordingFundsDto {
    value: string;
    impactVariableId: number;
    projectId: number;
}

export interface CreateImpactVariablesAccordingFundsDto {
    variable: string;
    typeVariable: TypeVariable;
    title?: string;
    comment?: string;
    fondsIds: number[];
}

export interface UpdateImpactValuesAccordingFundsDto extends CreateImpactValuesAccordingFundsDto {
    id?: number;
}

export interface ProjectSectorDto {
    id: number;
    value: string;
    subSectors?: ProjectSubSectorDto[];
    sectorVariables?: ProjectSectorVariableWithValueDto[];
}

export interface ProjectSubSectorDto {
    id: number;
    value: string;
    sectorId: number;
    sectorVariables?: ProjectSectorVariableWithValueDto[];
}

export interface CreateProjectSubSectorDto {
    value: string;
    sectorId: number;
}

export interface UpdateProjectSubSectorDto extends CreateProjectSubSectorDto {
    id: number;
}

export interface CreateProjectSectorDto {
    value: string;
    sectorVariables: CreateProjectSectorVariableDto[];
}

export interface CreateProjectSectorVariableDto {
    variable: string;
    typeVariable: TypeVariable;
    title?: string;
    comment?: string;
}


export interface ProjectSectorVariableWithValueDto {
    id: number;
    variable: string;
    typeVariable: TypeVariable;
    title?: string;
    comment?: string;
    sectorId: number;
    sectorValue?: ProjectSectorValueDto;
}

export interface ProjectSectorVariableDto {
    id: number;
    variable: string;
    typeVariable: TypeVariable;
    title?: string;
    comment?: string;
    sectorId: number;
}

export interface ProjectSectorValueDto {
    id: number;
    value: string;
    sectorVariableId: number;
    projectId: number;
}

export interface CreateProjectSectorValueDto {
    value: string;
    sectorVariableId: number;
    projectId: number;
}

export interface UpdateProjectSectorValueDto extends CreateProjectSectorValueDto {
    id?: number;
}

export interface AllProjectFormDataDto {
    seniorityLevel: SeniorityLevelDto[];
    projectMarketTypology: ProjectMarketTypologyDto[];

}

export interface ImpactDataDto {
    id: number;
    value: number;
    unit: string;
    projectMarketTypologyId?: number;
    sectorId?: number;
    subSectorId?: number;
}

export interface CreateImpactDataDto {
    value: number;
    unit: string;
    projectMarketTypologyId?: number;
    sectorId?: number;
    subSectorId?: number;
}

export interface UpdateImpactDataDto extends CreateImpactDataDto {
    id: number;
}