import React, { useMemo } from "react";
import { ApexOptions } from "apexcharts";
import ApexCharts from "react-apexcharts";
import { addDays, printLargeValue } from "../../../function/Utils";
import { useAppSelector } from "../../../redux/store";
import { formatInTimeZone } from "date-fns-tz";

const download = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
</svg>`;

export interface ISeries {
  data: {
    x: number;
    y: number;
  }[];
  name: string;
}

interface Props {
  series: ISeries[];
  start?: number;
  multiYaxes?: boolean;
  historic?: boolean;
  area?: {
    dateStart: number;
    dateEnd: number;
  };
  height?: {
    graph: {
      sm: number;
      md: number;
    };
    area?: {
      sm: number;
      md: number;
    };
  };
}

const AreaChart: React.FC<Props> = ({
  series,
  start = 0,
  multiYaxes = false,
  historic = false,
  height = { graph: { sm: 200, md: 500 }, area: { sm: 100, md: 170 } },
  area,
}) => {
  if (series?.length === 0) {
    // return empty graph
    series = [
      {
        name: "Part €",
        data: [
          {
            x: new Date().getTime(),
            y: 0,
          },
        ],
      },
    ];
  }
  const toolbar = {
    show: true,
    offsetX: 0,
    offsetY: 0,
    tools: {
      download: download,
      selection: true,
      zoom: true,
      zoomin: true,
      zoomout: true,
      pan: true,
      reset: true,
      customIcons: [],
    },
    // export: {
    //   // csv: {
    //   //   filename: undefined,
    //   //   columnDelimiter: ",",
    //   //   headerCategory: "category",
    //   //   headerValue: "value",
    //   //   dateFormatter(timestamp) {
    //   //     return new Date(timestamp).toDateString();
    //   //   },
    //   // },
    //   // svg: {
    //   //   filename: undefined,
    //   // },
    //   // png: {
    //   //   filename: undefined,
    //   // },
    // },
    // autoSelected: "zoom",
  };

  let yAxes: any[] = [];
  if (multiYaxes) {
    series?.forEach((item, k) => {
      yAxes.push({
        title: {
          text: item.name,
        },
        opposite: k % 2 === 1,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          // color: "#008FFB",
        },
        decimalsInFloat: 2,
        labels: {
          formatter: (value: number) => {
            return printLargeValue(value.toFixed(2));
          },
        },
      });
    });
  } else {
    yAxes.push({
      title: {
        text: series[0].name,
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        // color: "#008FFB",
      },
      decimalsInFloat: 2,
      labels: {
        formatter: (value: number) => {
          return printLargeValue(value.toFixed(2));
        },
      },
    });
  }

  const options: ApexOptions = {
    chart: {
      id: "chart1",
      type: "line",
      // height: 350,
      animations: {
        enabled: false,
      },
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "pan",
        show: false,
        tools: {
          download: download,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 3, // Size of the dots
      strokeWidth: 2, // Width of the connecting lines
      strokeOpacity: 0.9,
      strokeColors: "#fff",
      colors: undefined, // Color of the dots
      hover: {
        size: 7,
      },
    },

    yaxis: yAxes,
    // yaxis: {
    //   tickAmount: 6,
    //   decimalsInFloat: 4,
    // },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: function (value, timestamp) {
          return formatInTimeZone(
            new Date(timestamp || 0),
            "Europe/Paris",
            "dd/MM/yyyy"
          ); // The formatter function overrides format property
        },
      },
      // categories: series.data.map((d) => d.x),

      // min: new Date("01 Mar 2012").getTime(),
      // tickAmount: 6,
    },
    // tooltip: {
    //     x: {
    //         format: "dd MMM yyyy HH:mm",
    //     },
    //     y: yAxesTooltip,
    // },
    stroke: {
      width: 2,
      curve: "straight",
      lineCap: "butt",
    },
  };

  // max length of series data
  const maxLength = useMemo(() => {
    let max = 0;
    let indexMax = 0;
    series?.forEach((item) => {
      if (item.data.length > max) {
        max = item.data.length;
        indexMax = series.indexOf(item);
      }
    });
    return {
      maxLength: max,
      indexMax: indexMax,
    };
  }, [series]);

  let min =
    start > 0 && series?.length > 0
      ? Math.min(start, maxLength.maxLength)
      : maxLength.maxLength;

  const xaxis = {
    min: area
      ? area.dateStart
      : addDays(
          new Date(
            series[maxLength.indexMax].data[
              series[maxLength.indexMax].data.length - 1
            ]?.x
          ),
          -min * 30
        ).getTime(),
    max: area
      ? area.dateEnd
      : new Date(
          series[maxLength.indexMax].data[
            series[maxLength.indexMax].data.length - 1
          ]?.x
        ).getTime(),
  };

  const optionsLine: ApexOptions = {
    chart: {
      id: "chart2",
      height: 130,
      type: "area",
      brush: {
        target: "chart1",
        enabled: true,
      },
      selection: {
        enabled: true,
        xaxis: xaxis,
      },
    },
    colors: ["#008FFB"],
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1,
      },
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    yaxis: {
      tickAmount: 2,
      decimalsInFloat: 2,
      min: 100,
    },
  };

  const pageState = useAppSelector(
    (state) => state.pageMeasurement.measurements
  );

  return (
    <>
      {series?.length > 0 && options ? (
        <div className="w-full mx-auto px-2">
          <ApexCharts
            type="line"
            series={series as any}
            options={options}
            height={
              pageState.heightScreen > 1000 ? height.graph.md : height.graph.sm
            }
          />
          {historic ? (
            <ApexCharts
              type="area"
              series={series as any}
              options={optionsLine}
              height={
                pageState.heightScreen > 1000
                  ? height.area!.md
                  : height.area!.sm
              }
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default AreaChart;
