import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { CreateAssociationBetweenFondsAndUserDto } from '../../components/DashboardAdmin/UserManage/UserManage';
import {
    BankAccountFondsDto,
    BankAccountFondsForUserDto,
    CreateBankAccountFondsDto,
    CreateFondsDto,
    DocumentFondsDto,
    FondsDto,
    FondsUserDto,
    GetBankInfoRequest,
    UpdateBankAccountFondsDto,
    UpdateFondsDto,
    UploadDocumentFondsDto,
} from '../../types/fonds';
import { baseQueryHandleError } from '../index.rtk';
import { DeleteDocument } from './projectSlice';

export interface GetSyntheticInfoFondsForUserRequest {
    fondsId: number;
    newInvestisor: boolean;
}

export interface GetSyntheticInfoFondsForUserResponse {
    nb_project: number;
    nb_project_funded: number;
    proportion_bl: number;
    proportion_delayed: number;
    proportion_default: number;
    totalInvested: number;
    nb_project_yearly: {
        year: number;
        nb_project: number;
    }[];
    proportion_sector: {
        proportion_sub_sector: {
            name: string;
            proportion: number;
        }[];
        name: string;
        proportion: number;
    }[];
    proportion_market_typology: {
        name: string;
        proportion: number;
    }[];
    proportion_platform: {
        name: string;
        proportion: number;
    }[];
    proportion_project: {
        name: string;
        proportion: number;
    }[];
}

export interface GetPerformancePerFondsForUserResponse {
    monthlyValues: {
        date: Date;
        fondsId: number;
        share: number;
    }[];
    yearlyValues: {
        year: number;
        perf: number;
        fondsId: number;
        startDate: Date;
        endDate: Date;
    }[];
}

export interface GetFondsBasicMetricsForUserResponse {
    date: Date;
    data: {
        name: string;
        value: string;
    }[];
}

export interface StoreFondsHistoricalPerfRequest {
    dateStart: Date;
    dateEnd: Date;
    fondsIds: number[];
}

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}fonds`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export const fondsApi = createApi({
    reducerPath: 'fondsApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: ['FondsUser', 'Documents', 'Fonds', 'BankAccount'],
    endpoints: (builder) => ({
        getFonds: builder.query<FondsDto[], void>({
            query: () => ``,
            providesTags: ['Fonds'],
        }),
        getFondsById: builder.query<FondsDto, number>({
            query: (id) => `/${id}`,
            providesTags: ['Fonds'],
        }),
        getFondsUser: builder.query<FondsUserDto | false, number>({
            query: (userId) => `/fondsUser/${userId}`,
            providesTags: ['FondsUser'],
        }),
        getSyntheticInfoFonds: builder.query<
            GetSyntheticInfoFondsForUserResponse,
            GetSyntheticInfoFondsForUserRequest
        >({
            query: (payload) => ({
                url: `/syntheticInfo/${payload.fondsId}`,
                params: {
                    newInvestisor: payload.newInvestisor,
                },
            }),
        }),
        getPerformancePerFonds: builder.query<
            GetPerformancePerFondsForUserResponse,
            number
        >({
            query: (fondsId) => ({
                url: `/performancePerFondsForUser/${fondsId}`,
                method: 'GET',
            }),
        }),
        storeFondHistoricalPerformances: builder.mutation<
            void,
            StoreFondsHistoricalPerfRequest
        >({
            query: (payload) => ({
                url: `/storeHistoricalPerf`,
                method: 'POST',
                body: payload,
            }),
        }),
        addFonds: builder.mutation<FondsDto, CreateFondsDto>({
            query: (payload) => ({
                url: ``,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Fonds'],
        }),
        deleteFonds: builder.mutation<FondsDto, number>({
            query: (id) => ({
                url: `/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Fonds'],
        }),
        editFonds: builder.mutation<FondsDto, UpdateFondsDto>({
            query: (payload) => ({
                url: `/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['FondsUser', 'Fonds'],
        }),
        createAssociationFondsUser: builder.mutation<
            void,
            CreateAssociationBetweenFondsAndUserDto
        >({
            query: (payload) => ({
                url: `/associationFondsUser/${payload.fondsId}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['FondsUser', 'Fonds'],
        }),
        getFondsBasicMetricForUser: builder.query<
            GetFondsBasicMetricsForUserResponse,
            number
        >({
            query: (fondsId) => `/valorisation/${fondsId}`,
        }),
        getFondsDocuments: builder.query<DocumentFondsDto[], number>({
            query: (fondsId) => `/documents/url/${fondsId}`,
            providesTags: ['Documents'],
        }),
        getInvestFondsDocuments: builder.query<DocumentFondsDto[], void>({
            query: () => `/documents/invest`,
            providesTags: ['Documents'],
        }),
        getFondsOverviewDocuments: builder.query<DocumentFondsDto[], number>({
            query: (fondsId) => `/documents/overview/${fondsId}`,
            providesTags: ['Documents'],
        }),
        deleteDocumentFonds: builder.mutation<void, DeleteDocument>({
            query: (payload) => ({
                url: `/documents/${payload.documentId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Documents'],
        }),
        getBankFondsInfoForSubscription: builder.query<
            BankAccountFondsForUserDto[],
            GetBankInfoRequest
        >({
            query: (payload) => ({
                url: `/bankAccount/forSubscription/${payload.userId}`,
                method: 'GET',
                params: {
                    subscriptionUserFondsId: payload.subscriptionId,
                },
            }),
            transformResponse: (response: BankAccountFondsForUserDto[]) => {
                return response.map((bankAccount) => {
                    return {
                        ...bankAccount,
                        iban: bankAccount.iban
                            ? bankAccount.iban.replace(/(.{4})/g, '$1 ')
                            : '',
                    };
                });
            },
        }),
        getBankInfoByFondsId: builder.query<
            BankAccountFondsDto | undefined,
            number
        >({
            query: (fondsId) => ({
                url: `/bankAccount/admin/${fondsId}`,
                method: 'GET',
            }),
            providesTags: ['BankAccount'],
            transformResponse: (response: BankAccountFondsDto | undefined) => {
                if (response) {
                    return {
                        ...response,
                        iban: response.iban
                            ? response.iban.replace(/(.{4})/g, '$1 ')
                            : '',
                    };
                } else {
                    return undefined;
                }
            },
        }),
        addBanckAccount: builder.mutation<void, CreateBankAccountFondsDto>({
            query: (payload) => ({
                url: `/bankAccount/admin`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['BankAccount'],
        }),
        updateBankAccount: builder.mutation<void, UpdateBankAccountFondsDto>({
            query: (payload) => ({
                url: `/bankAccount/admin/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['BankAccount'],
        }),
        deleteBankAccount: builder.mutation<void, number>({
            query: (id) => ({
                url: `/bankAccount/admin/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['BankAccount'],
        }),
    }),
});

export const {
    useGetFondsQuery,
    useAddFondsMutation,
    useDeleteFondsMutation,
    useEditFondsMutation,
    useAddBanckAccountMutation,
    useDeleteBankAccountMutation,
    useGetBankFondsInfoForSubscriptionQuery,
    useGetBankInfoByFondsIdQuery,
    useGetFondsUserQuery,
    useGetFondsByIdQuery,
    useGetFondsDocumentsQuery,
    useDeleteDocumentFondsMutation,
    useUpdateBankAccountMutation,
    useGetInvestFondsDocumentsQuery,
    useGetSyntheticInfoFondsQuery,
    useGetFondsBasicMetricForUserQuery,
    useGetFondsOverviewDocumentsQuery,
    useCreateAssociationFondsUserMutation,
    useGetPerformancePerFondsQuery,
    useStoreFondHistoricalPerformancesMutation,
} = fondsApi;

export async function uploadDocumentFonds(request: UploadDocumentFondsDto) {
    try {
        const response = await axios.get(
            `${import.meta.env.VITE_API_URL}/fonds/documents/url/put`,
            {
                params: {
                    fileType: encodeURIComponent(request.file.type),
                    fondsName: request.fondsName,
                    fondsId: request.fondsId,
                    type: request.type,
                },
                withCredentials: true,
            }
        );
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}fonds/documents/post`,
            data: {
                fondsId: request.fondsId,
                fileName: encodeURIComponent(request.fileName),
                type: request.type,
                comment: request.comment,
                category: request.category,
                key: key,
            },
            withCredentials: true,
        });

        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}
