import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { StatusUserDto } from "../../types/user";

interface statusUserState {
    statusUsers: StatusUserDto[];
    statusUser: StatusUserDto[];
    loading: boolean;
    errors: any;
}

const initialState: statusUserState = {
    statusUsers: [],
    statusUser: [],
    loading: false,
    errors: null,
};

// actions are processes that get data from backend
export const getStatusUsers = createAsyncThunk<
    StatusUserDto[]
>("statusUser/getStatusUsers", async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}statusUser`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getStatusUser = createAsyncThunk<StatusUserDto[], number>("statusUser/getStatusUser", async (id, thunkAPI) => {
    try {
        const response: AxiosResponse<any, any> = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}statusUser/${id}`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const addStatusUser = createAsyncThunk<
    StatusUserDto,
    StatusUserDto
>("statusUser/addStatusUser", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "post",
            url: `${import.meta.env.VITE_API_URL}statusUser`,
            data: data,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const editStatusUser = createAsyncThunk<
    StatusUserDto,
    StatusUserDto
>("statusUser/editStatusUser", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "put",
            url: `${import.meta.env.VITE_API_URL}statusUser/byUser/${data.id}`,
            data: { ...data },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const editStatusUserByAdmin = createAsyncThunk<
    StatusUserDto,
    StatusUserDto
>("statusUser/editStatusUserByAdmin", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "put",
            url: `${import.meta.env.VITE_API_URL}statusUser/byAdmin${data.id}`,
            data: { ...data },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const deleteStatusUser = createAsyncThunk<number, number>(
    "statusUser/deleteStatusUser",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}statusUser/${id}`,
                withCredentials: true,
            });
            return id;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);


// reducers -> reduce to a specific state -> changes state

export const StatusUserlice = createSlice({
    name: "statusUser",
    initialState,
    reducers: {
        setStatusUsers: (
            state,
            action: PayloadAction<StatusUserDto[]>
        ) => {
            state.statusUsers = action.payload;
        },
        setStatusUser: (
            state,
            action: PayloadAction<StatusUserDto[]>
        ) => {
            state.statusUser = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStatusUsers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getStatusUsers.fulfilled, (state, action) => {
            state.statusUsers = action.payload;
            state.loading = false;
        });
        builder.addCase(getStatusUsers.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(getStatusUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getStatusUser.fulfilled, (state, action) => {
            state.statusUser = action.payload;
            state.loading = false;
        });
        builder.addCase(getStatusUser.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addStatusUser.fulfilled, (state, action) => {
            state.statusUsers = [...state.statusUsers, action.payload];
            state.loading = false;
        });
        builder.addCase(addStatusUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addStatusUser.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editStatusUser.fulfilled, (state, action) => {
            state.loading = false;
            state.statusUser = state.statusUser.map((status) => {
                if (status.id === action.payload.id) {
                    return {
                        ...status,
                        content: action.payload?.content,
                        type: action.payload?.type,
                    };
                } else return status;
            });
        });
        builder.addCase(editStatusUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editStatusUser.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editStatusUserByAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.statusUsers = state.statusUsers.map((statusUser) => {
                if (statusUser.id === action.payload.id) {
                    return {
                        ...statusUser,
                        content: action.payload?.content,
                        type: action.payload?.type,
                    };
                } else return statusUser;
            });
        });
        builder.addCase(editStatusUserByAdmin.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editStatusUserByAdmin.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteStatusUser.fulfilled, (state, action) => {
            state.loading = false;
            state.statusUsers = state.statusUsers.filter(
                (statusUser) => statusUser.id !== action.payload
            );
        });
        builder.addCase(deleteStatusUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteStatusUser.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
    },
});

export default StatusUserlice.reducer;
export const { setStatusUser, setStatusUsers } = StatusUserlice.actions;
