import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import PasswordInput from '../../commun/formComponent/PasswordInput';

interface ChangePasswordInterface {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

interface formObjectInterface {
    label: string;
    name: keyof ChangePasswordInterface;
}

const UpdatePasswordSecurity = () => {
    const [message, setMessage] = useState<string | undefined>();

    const [, setSaveSucceed] = useState<boolean | undefined>();

    const validationSchema: Yup.SchemaOf<ChangePasswordInterface> =
        Yup.object().shape({
            oldPassword: Yup.string().required(
                "L'ancien mot de passe est demandé"
            ),
            newPassword: Yup.string()
                .required('le mot de passe est demandé')
                .min(8, 'le mot de passe doit au moins contenir 8 caractères')
                .max(40, 'le mot de passe ne doit pas excéder 40 caractères')
                .matches(
                    /^(?=.*[^A-Za-z0-9])[^&\\;<>]+$/,
                    'Il faut au moins un charactère spécial mais non spécifique ne pas utiliser (&;<>)'
                ),
            confirmNewPassword: Yup.string()
                .required('Répéter le mot de passe est demandé')
                .oneOf(
                    [Yup.ref('newPassword'), null],
                    'Les mots de passe ne correspondent pas'
                ),
        });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<ChangePasswordInterface>({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data: ChangePasswordInterface) => {
        await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}securities/checkPassword`,
            withCredentials: true,
            data: { ...data, name: 'updateBackOffice' },
        })
            .then((value) => {
                if (value.data.message === 'success') {
                    axios({
                        method: 'put',
                        url: `${import.meta.env.VITE_API_URL}securities`,
                        withCredentials: true,
                        data: {
                            content: data.newPassword,
                            name: 'updateBackOffice',
                        },
                    })
                        .then(() => {
                            setMessage('Mot de passe modifié !');
                            setSaveSucceed(true);
                            reset();
                        })
                        .catch((err) => {
                            console.log(err);
                            setSaveSucceed(false);
                        });
                } else {
                    setMessage("L'ancien mot de passe est erroné");
                    setSaveSucceed(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setSaveSucceed(false);
            });
    };

    const formObject: formObjectInterface[] = [
        {
            label: 'Ancien mot de passe admin',
            name: 'oldPassword',
        },
        {
            label: 'Nouveau mot de passe admin',
            name: 'newPassword',
        },
        {
            label: 'Confirmez le nouveau mot de passe',
            name: 'confirmNewPassword',
        },
    ];

    return (
        <form className="formTemplateSingUp" onSubmit={handleSubmit(onSubmit)}>
            <p
                className={
                    message === 'Mot de passe modifié !'
                        ? 'text-green-600'
                        : 'text-red-600'
                }
            >
                {message}
            </p>
            {formObject.map(({ name, label }, key) => (
                <PasswordInput
                    register={register}
                    errors={errors}
                    label={label}
                    name={name}
                    key={key}
                    componentError={() => {
                        return (
                            <div className="invalid-feedback">
                                {errors[name]?.message}
                            </div>
                        );
                    }}
                />
            ))}
            <button type="submit" className="submit pointer">
                Modifier le mot de passe
            </button>
        </form>
    );
};

export default UpdatePasswordSecurity;
