import RadioComponent from "../components/commun/formComponent/RadioComponent";
import { KnowledgeQuestionDto, TypeQuestion } from "../types/KnowledgeDto";
import { TypeTransaction } from "../types/transactions";

export const DisplayTypeTransaction = (type: TypeTransaction): JSX.Element => {
  switch (type) {
    case TypeTransaction.userCash:
      return <span className="text-green-600">Investis</span>;
    case TypeTransaction.cashUser:
      return <span className="text-red-600">Retrait</span>;

    default:
      return <span className="text-red-600">Erreur</span>;
  }
};

export const getImageDataUrl = async (imageUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";

    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      const context = canvas.getContext("2d");
      context?.drawImage(image, 0, 0);

      const dataUrl = canvas.toDataURL("image/png");
      resolve(dataUrl);
    };

    image.onerror = (error) => {
      reject(error);
    };

    image.src = imageUrl;
  });
};
