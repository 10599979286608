import React, { useEffect, useState } from "react";
import update from "../../assets/edit.svg";
import deleteSVG from "../../assets/delete.svg";
import pictureSVG from "../../assets/picture.svg";
import pdfSVG from "../../assets/download-solid.svg";
import {
  ParagraphArticleInterface,
  paragraphArticleType,
  UploadFiles,
} from "../../types/Articles";
import {
  deleteParagraphArticle,
  editParagraphArticle,
  getParagraphArticles,
  uploadEditParagraphArticle,
} from "../../redux/features/paragraphArticle";
import { useAppDispatch, useAppSelector } from "../../redux/store";

interface Props {
  paragraph: ParagraphArticleInterface;
  order: number;
  setOrder: React.Dispatch<React.SetStateAction<number>>;
  id: number;
}

const ArticleElementParagraph: React.FC<Props> = ({
  paragraph,
  order,
  setOrder,
  id,
}) => {
  const userData = useAppSelector((state) => state.userReducer.user);

  const dispatch = useAppDispatch();
  const [editToggle, setEditToggle] = useState<boolean>(false);
  const [editContent, setEditContent] = useState<string | null>(
    paragraph.text ? paragraph.text : null
  );
  const [file, setFile] = useState<File | null>(null);
  const [filePdf, setFilePdf] = useState<File | null>(null);

  const [type, setType] = useState<paragraphArticleType>(paragraph.type);

  useEffect(() => {
    if (paragraph.order > order) {
      setOrder(paragraph.order);
    }
  });

  const handleForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (file && paragraph.id) {
      let data = new FormData();

      data.append("id", paragraph.id.toString());
      data.append("type", type);
      data.append("order", paragraph.order.toString());
      data.append("ArticleId", id.toString());
      data.append("file", file);

      let ObjectUploadFile: UploadFiles = {
        data: data,
        type: "img",
      };

      await dispatch(uploadEditParagraphArticle(ObjectUploadFile));
      setEditToggle(!editToggle);
      setFile(null);
      dispatch(getParagraphArticles());
    } else if (filePdf && editContent && paragraph.id) {
      let data = new FormData();

      data.append("id", paragraph.id.toString());
      data.append("type", type);
      data.append("text", editContent);
      data.append("order", paragraph.order.toString());
      data.append("ArticleId", id.toString());
      data.append("file", filePdf);

      let ObjectUploadFile: UploadFiles = {
        data: data,
        type: "pdf",
      };

      setEditToggle(!editToggle);
      dispatch(uploadEditParagraphArticle(ObjectUploadFile));
      setFilePdf(null);
      dispatch(getParagraphArticles());
    } else if (editContent) {
      let data: ParagraphArticleInterface = {
        id: paragraph.id,
        ArticleId: id,
        text: editContent,
        order: paragraph.order,
        type: type,
      };
      await dispatch(editParagraphArticle(data));
      setEditToggle(!editToggle);
    }
  };

  const textDelete = () => {
    switch (paragraph.type) {
      case paragraphArticleType.p:
        return "ce paragraphe";
      case paragraphArticleType.h3:
        return "ce sous-titre";
      case paragraphArticleType.img:
        return "cette image";
      case paragraphArticleType.pdf:
        return "ce pdf";
      case paragraphArticleType.list:
        return;
      default:
        return "posts";
    }
  };

  const handleDelete = () => {
    let post = textDelete();
    let bol = window.confirm(`Voulez vous vraiment supprimer ${post} ?`);
    if (bol && paragraph.id) {
      dispatch(
        deleteParagraphArticle({
          id: paragraph.id,
          body: {
            path: paragraph?.path,
          },
        })
      );
    }
  };

  function text() {
    switch (paragraph.type) {
      case paragraphArticleType.p:
        return (
          <p
            className="element_modifiable"
            onClick={() => userData?.admin && setEditToggle(!editToggle)}
          >
            {paragraph.text}
          </p>
        );
      case paragraphArticleType.h3:
        return (
          <h3
            className="element_modifiable text-xl"
            onClick={() => userData?.admin && setEditToggle(!editToggle)}
          >
            {paragraph.text}
          </h3>
        );
      case paragraphArticleType.list:
        return (
          <p
            className="listElement element_modifiable"
            onClick={() => userData?.admin && setEditToggle(!editToggle)}
          >
            {paragraph.text}
          </p>
        );
      case paragraphArticleType.img:
        return (
          <div
            className="imgParagraph_container element_modifiable"
            onClick={() => userData?.admin && setEditToggle(!editToggle)}
          >
            <img className="imgParagraph" src={paragraph.path} alt="cover" />
          </div>
        );
      case paragraphArticleType.pdf:
        return (
          <div
            className="pdfParagraph_container element_modifiable"
            onClick={() => userData?.admin && setEditToggle(!editToggle)}
          >
            <a
              className="pointer pdfLink"
              href={paragraph.path}
              download={paragraph.text}
            >
              {paragraph.text} (PDF)
            </a>
          </div>
        );

      default:
        return null;
    }
  }

  const choixType = (type: paragraphArticleType) => {
    setType(type);
    let p = document
      ?.querySelector(".EditForm")
      ?.querySelector(".typeEdit")
      ?.querySelector(`.${type}`) as HTMLElement;
    let ps = document
      ?.querySelector(".EditForm")
      ?.querySelector(".typeEdit")
      ?.querySelectorAll("p");
    if (ps) {
      for (let val of ps) {
        // val.style.fontWeight = "300";
        val.style.backgroundColor = "rgb(241, 240, 239)";
        val.style.color = "rgb(20, 93, 136)";
      }
    }

    // p.style.fontWeight = "600";
    p.style.backgroundColor = "green";
    p.style.color = "white";
  };

  useEffect(() => {
    if (editToggle) {
      choixType(type);
    }
  }, [editToggle]);

  return (
    <div className="paragraph_element">
      {!!userData?.admin && (
        <div className="edit-delete">
          <img
            onClick={() => setEditToggle(!editToggle)}
            src={update}
            alt="edit"
            className="pointer"
          />
          <img
            src={deleteSVG}
            alt="delete"
            onClick={() => handleDelete()}
            className="pointer"
          />
        </div>
      )}

      {editToggle ? (
        <form className="EditForm" onSubmit={(e) => handleForm(e)}>
          <div className="typeEdit">
            <p
              className="pointer p"
              onClick={() => choixType(paragraphArticleType.p)}
            >
              Paragraphe
            </p>
            <p
              className="pointer h3"
              onClick={() => choixType(paragraphArticleType.h3)}
            >
              Titre
            </p>
            <p
              className="pointer list"
              onClick={() => choixType(paragraphArticleType.list)}
            >
              List
            </p>
            <p
              className="pointer img"
              onClick={() => choixType(paragraphArticleType.img)}
            >
              <img src={pictureSVG} alt="img" />
              <input
                type="file"
                id="file-upload"
                name="file"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  choixType(paragraphArticleType.img);
                  if (e.target.files) setFile(e.target.files[0]);
                }}
              />
            </p>
            <p
              className="pointer pdf"
              onClick={() => choixType(paragraphArticleType.pdf)}
            >
              <img src={pdfSVG} alt="pdf" />
              <input
                type="file"
                id="file-upload-pdf"
                name="file"
                accept=".pdf"
                onChange={(e) => {
                  choixType(paragraphArticleType.pdf);
                  if (e.target.files) setFilePdf(e.target.files[0]);
                }}
              />
            </p>
          </div>
          <textarea
            defaultValue={paragraph.text}
            onChange={(e) => setEditContent(e.target.value)}
            rows={6}
          ></textarea>
          <br />
          <input
            className="pointer"
            type="submit"
            value="Valider la modification"
          />
        </form>
      ) : (
        text()
      )}
    </div>
  );
};

export default ArticleElementParagraph;
