import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { BlackListDto, CreateBlackListDto, DeleteProjectInBlackList, UpdateBlackListDto } from "../../types/blackList";
import { baseQueryHandleError } from '../index.rtk';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}blackLists`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export const blackListsApi = createApi({
    reducerPath: 'blackListsApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: ['BlackLists'],
    endpoints: (builder) => ({
        getBlackLists: builder.query<BlackListDto[], void>({
            query: () => `/`,
        }),
        getBlackListById: builder.query<BlackListDto, number>({
            query: (id) => `/${id}`,
        }),
        getBlackListsByFonds: builder.query<BlackListDto[], number>({
            query: (fondsId) => `/fonds/${fondsId}`,
            providesTags: ['BlackLists']
        }),

        addBlackList: builder.mutation<BlackListDto, CreateBlackListDto>({
            query: (payload) => ({
                url: `/`,
                method: 'POST',
                body: payload,
            }),
            async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                try {
                    const { data: newBlackList, meta } = await queryFulfilled
                    dispatch(
                        blackListsApi.util.updateQueryData('getBlackLists', undefined, (draft) => {
                            if (!draft) draft = []
                            return draft.concat([newBlackList])
                        })
                    )
                    dispatch(
                        blackListsApi.util.updateQueryData('getBlackListsByFonds', payload.fondsId, (draft) => {
                            if (!draft) draft = []
                            return draft.concat([newBlackList])
                        })
                    )
                } catch (err) {
                    console.log(err)
                }
            },

        }),
        deleteBlackList: builder.mutation<BlackListDto, DeleteProjectInBlackList>({
            query: (request) => ({
                url: `/${request.blackListId}`,
                method: 'DELETE',
                params: request
            }),
            async onQueryStarted(request, { dispatch, queryFulfilled }) {
                try {

                    await queryFulfilled;
                    dispatch(blackListsApi.util.updateQueryData('getBlackLists', undefined, (draft) => {
                        return draft.filter(
                            (Platform) => Platform.id !== request.blackListId

                        );
                    }
                    ))

                } catch (err) {
                    console.log(err)
                }
            },
            invalidatesTags: ['BlackLists']
        }),
        editBlackList: builder.mutation<BlackListDto, UpdateBlackListDto>({
            query: (payload) => ({
                url: `/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedPost } = await queryFulfilled
                    dispatch(
                        blackListsApi.util.updateQueryData('getBlackLists', undefined, (draft) => {

                            return draft.map((platform) => {
                                if (platform.id === updatedPost.id) {
                                    return {
                                        ...platform,
                                        ...updatedPost,

                                    };
                                } else return platform;
                            })

                        })
                    )
                    dispatch(
                        blackListsApi.util.updateQueryData('getBlackListsByFonds', payload.fondsId, (draft) => {

                            return draft.map((platform) => {
                                if (platform.id === updatedPost.id) {
                                    return {
                                        ...platform,
                                        ...updatedPost,

                                    };
                                } else return platform;
                            })

                        })
                    )
                } catch (err) {
                    console.log(err)
                }
            },
        }),
    }),
})

export const { useGetBlackListByIdQuery, useGetBlackListsQuery, useGetBlackListsByFondsQuery, useAddBlackListMutation, useDeleteBlackListMutation, useEditBlackListMutation } = blackListsApi;
