import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  useGetMonthProjectQuery,
  useGetNbProjectsByFondsIdQuery,
  useGetOverviewProjectsUserQuery,
  useGetProportionInPorjectByUserIdInFondsIdQuery,
} from "../../../redux/features/projectSlice";
import Loading from "../../commun/Loading";
import {
  useQueryParam,
  NumberParam,
  StringParam,
  DateParam,
  useQueryParams,
  BooleanParam,
} from "use-query-params";
import { PrimaryButton } from "../../commun/Buttons";
import {
  LazyImage,
  LazyImageBackground,
} from "../../commun/animation/LazyImage";
import { DisplayFondsImpact } from "../Activities";
import {
  formatDate,
  getInterest,
  lastDay,
  printLargeValue,
  transformDate,
} from "../../../function/Utils";
import {
  ProjectDto,
  ProjectForUserDto,
  ProjectsOverviewUserDto,
  ProportionTransactionUserInProjectDto,
} from "../../../types/project";
import { Waypoint } from "react-waypoint";
import {
  useGetFondsBasicMetricForUserQuery,
  useGetFondsByIdQuery,
  useGetSyntheticInfoFondsQuery,
} from "../../../redux/features/fondsSlice";
import FilterApparition from "../../DashboardAdmin/BackOffice/Components/FilterApparition";
import { useFetchTokenQuery } from "../../../redux/features/tokenSlice";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { displayHideLongText } from "../../DashboardAdmin/ProjectManage/Suivi/Suivis";
import { MenuDashBoardUserBoolState } from "../../Context/AppContext";
import SearchComponent from "../../commun/SearchComponent";
import DisplayTextEditor from "../../commun/DisplayTextEditor";
import ProjectsMap from "../../commun/Map/ProjectsMap";
import { LiquidityFutureUser } from "./LiquidityFuture";
import PerformanceFonds from "./PerformanceFonds";
import SyntheticInfoFonds from "./SyntheticInfoFonds";
import { useQueryParamCustom } from "../../commun/CustomHook/useQueryParamCustom";
// @ts-ignore
import palette from "google-palette";
import { IMenu } from "../../DashboardAdmin/UserManage/UserManage";
import { TabsMenu } from "../../commun/HorizontalMenu";
import {
  displayReportRiskNotation,
  getColorReportRiskNotation,
} from "../../../function/projectHelpers";
import { useGetDashbordUserInfoQuery } from "../../../redux/features/shareApiSlice";
import DisplayStatsV1 from "../../commun/Stats/DisplayStatsV1";

import coverTest from "./../../../assets/image_home2.jpg";
import FonctioningFonds from "./FonctioningFonds";

const FondsUser = ({
  fondsId,
  listRef,
}: {
  fondsId: number;
  listRef: React.RefObject<HTMLDivElement>;
}) => {
  const [tooggleLiquidity, setToggleLiquidity] = useState<boolean>(false);

  const [pageQuery, setPageQuery] = useQueryParamCustom(
    "pageFondsInfo",
    StringParam,
    "general"
  );

  const items: IMenu[] = [
    {
      queryPage: "general",
      label: "Général",
    },
    {
      queryPage: "synthetic",
      label: "Vue d'ensemble",
    },
    {
      queryPage: "fonctioning",
      label: "Fonctionnement",
    },
  ];

  const [loading, setLoading] = useState(true);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    // Simulate a delay to show the loading state (e.g., fetching data)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 600); // Adjust the delay as needed

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [trigger]);

  let scrollRef = useRef<HTMLDivElement>(null);
  let tabRef = useRef<HTMLDivElement>(null);

  const scrollToSticky = () => {
    if (listRef.current && scrollRef.current) {
      const stickyRect =
        scrollRef.current.offsetTop +
        scrollRef.current.getBoundingClientRect().height;

      listRef.current.scrollTo({
        top: stickyRect,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="w-full">
      <FondsInfo fondsId={fondsId} />
      <div ref={scrollRef} className=" h-10 md:h-[20px] "></div>
      <div
        ref={tabRef}
        className=" bg-backgroundColor w-full flex justify-center items-center   sticky top-[-1px] z-10 border-t-2 md:border-y-2 mb-10 md:mb-[60px] mx-auto"
      >
        <TabsMenu
          className="  "
          items={items}
          queryPage="pageFondsInfo"
          onClick={() => {
            scrollToSticky();
            setTrigger((cur) => !cur);
            setLoading(true);
          }}
        />
      </div>
      {pageQuery === "general" ? (
        <GeneralComponent fondsId={fondsId} listRef={listRef} />
      ) : pageQuery === "synthetic" ? (
        <>
          <SyntheticInfoFonds
            fondsId={fondsId}
            loading={loading}
            tabRef={tabRef}
          />
        </>
      ) : (
        <FonctioningFonds fondsId={fondsId} />
      )}
    </div>
  );
};

function GeneralComponent({
  fondsId,
  listRef,
}: {
  fondsId: number;
  listRef: React.RefObject<HTMLDivElement>;
}) {
  const { data: fonds, isLoading: isLoadingFonds } =
    useGetFondsByIdQuery(fondsId);

  const { data: monthProject, isLoading: isLoadingMonthProject } =
    useGetMonthProjectQuery({ fondsId: fondsId });

  return (
    <>
      <div className="w-full px-4 my-4 md:w-11/12 mx-auto">
        <DisplayTextEditor content={fonds?.description ?? undefined} />
      </div>

      {monthProject ? (
        <>
          <TitreFondsUser>Investissement du mois</TitreFondsUser>
          <MonthProject fondsId={fondsId} monthProject={monthProject} />
        </>
      ) : null}

      <TitreFondsUser>Performance historique du fonds</TitreFondsUser>
      <PerformanceFonds fondsId={fondsId} />

      <TitreFondsUser> Répartition géographique des projets</TitreFondsUser>
      <ProjectsMap
        fondsId={fondsId}
        width={"75%"}
        height={"500px"}
        listRef={listRef}
      />
      {/* <Titre>Liquidités futures</Titre>
      <LiquidityFutureUser fondsId={fondsId} /> */}
      <TitreFondsUser> Projets investis dans ce fonds</TitreFondsUser>
      {/* <h3 className=" mt-5 px-2 text-mainColor text-center font-mainFontFamily text-2xl">
       
      </h3> */}
      <DisplayProject fondsId={fondsId} listRef={listRef} />
    </>
  );
}

export function TitreFondsUser({
  children,
  className,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <h2
      className={` w-full md:w-11/12 bg-bgDashboardClient mx-auto pl-4 p-2 uppercase rounded-md mt-6 mb-2 font-mainFontFamily text-2xl text-mainColor ${className}`}
    >
      {children}
    </h2>
  );
}

export default FondsUser;

function MonthProject({
  fondsId,
  monthProject,
}: {
  fondsId: number;
  monthProject: ProjectForUserDto;
}) {
  const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();

  const { data: proportionProject } =
    useGetProportionInPorjectByUserIdInFondsIdQuery(
      {
        fondsId: fondsId,
        userId: userData?.id || 0,
      },
      {
        skip: userData?.id === undefined,
      }
    );

  const data = useMemo(() => {
    if (!monthProject || !proportionProject) return [];
    return [
      {
        label: "Type de financement",
        value:
          monthProject.projectInfo?.fundingType ||
          monthProject.projectInfo?.fundingTypeOther,
      },
      {
        label: "Secteur",
        value: monthProject?.projectMarketTypology?.map((v, index) => (
          <div key={index} className="  flex gap-1 items-center">
            <FontAwesomeIcon
              icon={faCircle}
              className="text-mainColor h-2 w-2"
            />{" "}
            <p>{v}</p>
          </div>
        )),
      },
      { label: "Plateforme", value: monthProject.platformName },
      {
        label: "Taux",
        value: `${getInterest(
          monthProject.interests,
          lastDay(new Date(Date.now()))
        )} %`,
      },
      { label: "Durée", value: monthProject.duration + " mois" },
      {
        label: "Exposition",
        value: `${
          proportionProject
            ?.find((prop) => prop.projectId === monthProject.id)
            ?.proportion?.toFixed(2) || 0
        }
      %`,
      },
      {
        label: "Valorisation",
        value: printLargeValue(monthProject.valorisation.toFixed(2)) + " €",
      },
      { label: "Échéance", value: formatDate(new Date(monthProject.dateEnd)) },
      {
        label: "Santé",
        value: displayReportRiskNotation(monthProject.notationRisque),
        color: getColorReportRiskNotation(monthProject.notationRisque),
      },
      {
        label: "Projet immobilisé",
        value: monthProject.dateBlackList
          ? `Depuis ${formatDate(new Date(monthProject.dateBlackList))} `
          : "Non",
      },
    ];
  }, [monthProject, proportionProject]);

  return (
    <div className="w-full mx-auto bg-secondBackgroundColor rounded-md my-4 transition-all grid gap-x-2  md:grid-cols-[1fr,2fr] md:w-11/12">
      <div className=" relative">
        <LazyImageBackground
          src={monthProject?.cover || ""}
          // src={coverBackground}
          className=" w-full h-[250px] md:h-full rounded-l-md"
          // className={{
          //   img: "w-full max-w-md bg-cover bg-center rounded-md",
          //   div: `w-full h-full md:w-[24rem]  rounded-md ${
          //     menuBoolState?.toggleMenu ? "" : "lg:w-[28rem]"
          //   }`,
          // }}
        />
        <div className="absolute top-0 bg-white w-full opacity-80">
          <p className="text-center font-semibold text-lg italic px-2">
            {monthProject?.realName}
          </p>
        </div>
      </div>
      <div className="grid md:gap-x-4 md:grid-cols-2 p-2">
        <div className=" md:col-span-2">
          {" "}
          {displayHideLongText(
            "Pas de description",
            monthProject?.description || undefined,
            200
          )}
        </div>
        {data.map((item, index) => (
          <div
            key={item.label}
            className={`grid md:mt-3 grid-cols-1 md:grid-cols-2 border-b-2  py-2 space-y-2 md:space-y-0 `}
          >
            <h3
              className={`text-lg font-mainFontFamily font-semibold text-center md:text-start`}
            >
              {item.label}
            </h3>
            <p
              className={`flex items-center justify-center md:block md:text-start`}
              style={item.color ? { color: item.color } : {}}
            >
              {item.value}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

function DisplayProject({
  fondsId,
  listRef,
}: {
  fondsId: number;
  listRef: React.RefObject<HTMLDivElement>;
}) {
  const [searchPlatformName, setSearchPlatformName] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [rangeChosen, setRangeChosen] = useState<number>(1);

  const [scrollPos, setScrollPos] = useState(0);

  const [searchProjectQuery, setSearchProjectQuery] = useQueryParam(
    "search",
    StringParam
  );

  const { data: user } = useFetchTokenQuery();

  const userId = user?.id || 0;

  const {
    data: projectsOverview,
    isLoading: isLoadingProjects,
    isFetching,
  } = useGetOverviewProjectsUserQuery(
    {
      dateStart: new Date(2000, 0, 1),
      dateEnd: new Date(2030, 0, 1),
      fondsId: fondsId,
      searchProjectName: searchProjectQuery || "",
      searchPlatformName: searchPlatformName,
      limit: limit,
      offset: offset,
      closed: false,
      userId: userId,
    },
    {
      skip: !(userId > 0),
    }
  );

  const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();

  const { data: proportionProject } =
    useGetProportionInPorjectByUserIdInFondsIdQuery(
      {
        fondsId: fondsId,
        userId: userData?.id || 0,
      },
      {
        skip: userData?.id === undefined,
      }
    );

  const projectsNew = projectsOverview?.rows;
  const nbProjects = projectsOverview?.count;

  const handleFectchMore = () => {
    if (
      !isFetching &&
      nbProjects !== undefined &&
      nbProjects > offset + limit
    ) {
      setScrollPos(listRef.current?.scrollTop || 0);
      setOffset((current) => current + limit);
    }
  };

  const [queryPage, setQueryPage] = useQueryParam("pageFondsUser", StringParam);

  const COLORS: { sub_sector: string; color: string }[] = useMemo(() => {
    if (!projectsOverview) return [];
    let subSector = projectsOverview?.subSector;
    const colors = palette(["sequential"], subSector.length + 1, 1).map(
      (col: any) => `#${col}`
    );
    return subSector.map((s, index) => ({
      sub_sector: s,
      color: colors[index + 1],
    }));
  }, [projectsOverview]);

  return (
    <>
      {isLoadingProjects ? (
        <Loading />
      ) : (
        <div className="gap-5 mt-5 justify-center md:justify-normal flex flex-wrap w-full md:w-11/12 mx-auto mb-3">
          <Filter />
          <div className="w-full mx-auto  mb-[-15px]">
            <FilterApparition
              startIntervalNbItems={offset}
              setNbItemsShowed={setLimit}
              nbItemsShowed={limit}
              setStartIntervalNbItems={setOffset}
              array={projectsNew ?? []}
              offset={true}
              nbItems={nbProjects ?? 0}
              rangeChosen={rangeChosen}
              setRangeChosen={setRangeChosen}
            />
          </div>
          <div className="flex flex-col gap-3 mx-auto w-full mt-1">
            {projectsNew && projectsNew?.length > 0 ? (
              projectsNew?.map((p) => (
                <ProjectCard
                  p={p}
                  proportionProject={proportionProject}
                  listRef={listRef}
                  color={COLORS}
                />
              ))
            ) : (
              <p className="text-center">Aucun projet n'a été trouvé</p>
            )}
          </div>

          {isFetching && (
            <div className="h-[50px] w-full">
              <Loading />
            </div>
          )}

          {/* {nbProjects !== undefined &&
          projects?.length < nbProjects &&
          !isFetching ? (
            <Waypoint onEnter={handleFectchMore} />
          ) : null} */}
          <p className="w-fit px-2 text-center md:text-start italic">
            <span className=" font-semibold">Exposition*</span>: proportion de
            votre portefeuille exposé à cet investissement.
          </p>
          <div className="w-full mx-auto md:w-11/12 my-1">
            <FilterApparition
              startIntervalNbItems={offset}
              setNbItemsShowed={setLimit}
              nbItemsShowed={limit}
              setStartIntervalNbItems={setOffset}
              array={projectsNew ?? []}
              offset={true}
              nbItems={nbProjects ?? 0}
              rangeChosen={rangeChosen}
              setRangeChosen={setRangeChosen}
            />
          </div>
          <div className="h-[50px] w-full"></div>
        </div>
      )}
    </>
  );
}

function Filter() {
  const [searchProjectQuery, setSearchProjectQuery] = useQueryParams({
    searchPlatformName: StringParam,
    dateStart: DateParam,
    dateEnd: DateParam,
  });

  const [searchQuery, setSearchQuery] = useQueryParam("search", StringParam);
  const [choseInfoQuery, setChoseInfoQuery] = useQueryParamCustom(
    "choseInfo",
    StringParam,
    "follow"
  );

  const infoValues = [
    {
      value: "follow",
      label: "Suivi",
    },
    {
      value: "impact",
      label: "Impact",
    },
  ];

  return (
    <div className="w-11/12 mx-auto mt-5 flex gap-1 flex-col mb-5">
      <div className="w-full flex flex-col  justify-center items-center">
        <SearchComponent
          setSearch={setSearchQuery as any}
          search={searchQuery as any}
        />
      </div>
      {/* <div className="w-full flex mt-2 gap-2 items-center">
        <p className=" font-semibold">Montrer les informations sur</p>
        {infoValues.map((v, index) => (
          <p
            onClick={() => setChoseInfoQuery(v.value)}
            className={`${
              choseInfoQuery === v.value ? "bg-secondBackgroundColor" : ""
            } rounded-md py-2 px-3 cursor-pointer`}
          >
            {v.label}
          </p>
        ))}

      </div> */}
    </div>
  );
}

function FondsInfo({ fondsId }: { fondsId: number }) {
  const { data: fonds, isLoading: isLoadingFonds } =
    useGetFondsByIdQuery(fondsId);

  const { data: metrics, isLoading: isLoadingValo } =
    useGetFondsBasicMetricForUserQuery(fondsId);

  const { data: dashBordUserAdmin, isLoading: isLoadingRate } =
    useGetDashbordUserInfoQuery();

  const fonds_i = dashBordUserAdmin?.fondsInfo?.find(
    (f) => f.fondsId === fondsId
  );

  const statsInfo = useMemo(() => {
    if (fonds) {
      return {
        subTitle: fonds?.name,
        title: fonds?.overviewInfo?.subTitle || "",
        text: fonds?.overviewInfo?.text || "",
      };
    } else {
      return {
        subTitle: "",
        title: "",
        text: "",
      };
    }
  }, [fonds]);

  const stats = useMemo(() => {
    if (!metrics) return [];
    let data = metrics.data;
    let res = [
      {
        name: data[0]?.name,
        value: data[0]?.value,
      },
      {
        name: (
          <>
            {" "}
            Intérêts <br /> (Performance d'un nouvel investisseur){" "}
          </>
        ),
        value: `${(fonds_i && fonds_i?.triNotBlackListLast30Days > 0
          ? fonds_i?.triNotBlackListLast30Days -
            (fonds_i?.triNotBlackListLast30Days - fonds_i?.fondsPerformance) *
              0.3
          : 0
        )?.toFixed(2)} %`,
      },
    ];
    data.slice(2).forEach((d) => {
      res.push({
        name: d?.name,
        value: d?.value,
      });
    });
    return res;
  }, [metrics, dashBordUserAdmin]);

  return (
    <>
      {isLoadingFonds || isLoadingValo || isLoadingRate ? (
        <div className=" w-full flex items-center justify-center h-[70vh]">
          <Loading />
        </div>
      ) : (
        <>
          <DisplayStatsV1
            stats={stats}
            title={statsInfo.title}
            subTitle={statsInfo.subTitle}
            text={statsInfo.text}
            className=" mb-20"
          >
            <div className="flex justify-center w-full h-full items-center box-border flex-col">
              {/* <LazyImage
                // src={fonds?.cover || ""}
                src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80"
                className={{
                  img: "h-56 w-full bg-gray-50 object-cover box-border  lg:inset-y-0 lg:left-0 lg:h-[80vh]",
                  div: "flex justify-center items-center",
                }}
              /> */}
              <LazyImageBackground
                src={fonds?.cover || ""}
                className=" h-[50vh] w-full bg-gray-50 object-cover lg:inset-y-0 lg:left-0 lg:min-h-full lg:h-[90vh]"
              />
            </div>
          </DisplayStatsV1>
          {/* <h3 className=" mt-5  text-center text-3xl md:text-4xl font-mainFontFamily mb-2 text-mainColor">
            {fonds?.name}
          </h3>
          <LazyImage
            src={fonds?.cover || ""}
            className={{
              img: "my-2 h-[300px] bg-cover bg-center rounded-sm", 
              div: "flex justify-center items-center",
            }}
          /> */}
        </>
      )}
    </>
  );
}

function ProjectCard({
  p,
  listRef,
  color,
  proportionProject,
}: {
  p: ProjectsOverviewUserDto;
  proportionProject: ProportionTransactionUserInProjectDto[] | undefined;
  listRef: React.RefObject<HTMLDivElement>;
  color: { sub_sector: string; color: string }[];
}) {
  const [queryProjectId, setQueryProjectId] = useQueryParam(
    "projectId",
    NumberParam
  );

  const [queryPage, setQueryPage] = useQueryParam("pageFondsUser", StringParam);
  const [queryProjectPage, setQueryProjectPage] = useQueryParam(
    "pageProjectUser",
    StringParam
  );
  const menuBoolState = useContext(MenuDashBoardUserBoolState);

  const [queryScroll, setQueryScroll] = useQueryParam(
    "scrollHeight",
    NumberParam
  );

  useEffect(() => {
    if (queryScroll) {
      listRef.current?.scrollTo(0, queryScroll);
    }
  }, []);

  const [choseInfo, setChoseInfo] = useState("follow");

  const [choseInfoQuery, setChoseInfoQuery] = useQueryParam(
    "choseInfo",
    StringParam
  );

  useEffect(() => {
    if (choseInfoQuery !== undefined && choseInfoQuery !== null) {
      setChoseInfo(choseInfoQuery);
    }
  }, [choseInfoQuery]);

  const DescriptionInfo = () => {
    return (
      <>
        <h3
          className={`relative my-1 cursor-pointer font-mainFontFamily text-2xl `}
        >
          {p.name}
        </h3>
        <div className="flex flex-col">
          <div className="flex gap-2">
            <p>{p?.fundingType}</p>
            <p
              style={{
                color: color?.find((c) => c.sub_sector === p.subSector)?.color,
              }}
              className={` `}
            >
              {p.subSector}
            </p>
          </div>
          <div className="flex gap-1 flex-wrap">
            {p?.projectMarketTypology?.map((v, index) => (
              <div
                key={index}
                className=" font-semibold flex gap-1 items-center"
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  className="text-mainColor h-2 w-2"
                />{" "}
                <p>{v}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="flex gap-1">
          <p>{p?.duration} mois - </p>

          <p className=" text-secondColor">
            {getInterest(p.interests, lastDay(new Date(Date.now())))} %
          </p>

          {/* <p>Notation: {p?.notationPlatform}</p> */}
        </div>
        <div className="mt-2">
          <p className=" text-justify px-1 ">
            {displayHideLongText("Pas de description", p.description, 150)}
          </p>
        </div>
        <div className="flex w-full mw-auto justify-center items-center my-2 self-end pb-2">
          <PrimaryButton
            onClick={() => {
              setQueryScroll(listRef.current?.scrollTop || 0);
              setQueryPage("project");
              setQueryProjectId(p.id);
              setQueryProjectPage("general-info");
            }}
          >
            Découvrir
          </PrimaryButton>
        </div>
      </>
    );
  };

  const SuiviImpactComponent = () => {
    return (
      <>
        <div className="flex gap-2">
          <h3
            onClick={() => setChoseInfo("follow")}
            className={`relative my-1 font-mainFontFamily cursor-pointer text-xl ${
              choseInfo === "follow"
                ? "border-b-4 border-b-mainColor text-gray-700"
                : "text-gray-500"
            }`}
          >
            Suivi
          </h3>
          <h3
            onClick={() => setChoseInfo("impact")}
            className={`relative my-1 cursor-pointer font-mainFontFamily text-xl  ${
              choseInfo === "impact"
                ? "border-b-4 border-b-mainColor text-gray-500"
                : "text-gray-500"
            }`}
          >
            Impact
          </h3>
        </div>
        {choseInfo === "impact" ? (
          <>
            <div className="flex flex-col gap-2 mt-2">
              <div className="grid grid-cols-2 relative  items-center">
                <p>Environemental</p>
                <p>Information indisponible</p>
                <span className="border-b-[0.5px] border-gray-400 absolute bottom-[-2px] right-[8.3%] w-10/12"></span>
              </div>
              <div className="grid grid-cols-2 relative  items-center">
                <p>Social</p>
                <p>Information indisponible</p>
                <span className="border-b-[0.5px] border-gray-400 absolute bottom-[-2px] right-[8.3%] w-10/12"></span>
              </div>
              <div className="grid grid-cols-2 relative  items-center">
                <p>Economique</p>
                <p>Information indisponible</p>
                <span className="border-b-[0.5px] border-gray-400 absolute bottom-[-2px] right-[8.3%] w-10/12"></span>
              </div>
            </div>
            <div className="flex w-full h-full mw-auto justify-center items-end my-2 self-end pb-2">
              <PrimaryButton
                onClick={() => {
                  setQueryScroll(listRef.current?.scrollTop || 0);
                  setQueryPage("project");
                  setQueryProjectId(p.id);
                  setQueryProjectPage("Impact");
                }}
              >
                Impact
              </PrimaryButton>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-2 mt-2">
              <div className="grid grid-cols-2 relative ">
                <p>Exposition*</p>
                <p className=" text-titleColor">
                  {proportionProject
                    ?.find((prop) => prop.projectId === p.id)
                    ?.proportion?.toFixed(2) || 0}{" "}
                  %
                </p>
                <span className="border-b-[0.5px] border-gray-400 absolute bottom-[-2px] right-[8.3%] w-10/12"></span>
              </div>
              <div className="grid grid-cols-2 relative ">
                <p>Echéance</p>
                <p>{formatDate(new Date(p.dateEnd))}</p>
                <span className="border-b-[0.5px] border-gray-400 absolute bottom-[-2px] right-[8.3%] w-10/12"></span>
              </div>
              <div className="grid grid-cols-2 relative ">
                <p>Situation</p>
                <p>
                  {p.status === "Rien à signaler"
                    ? "En souscription"
                    : p.status}
                </p>
                <span className="border-b-[0.5px] border-gray-400 absolute bottom-[-2px] right-[8.3%] w-10/12"></span>
              </div>
              <div className="grid grid-cols-2 relative ">
                <p>Santé</p>
                <p
                  style={{
                    color: getColorReportRiskNotation(p.notationRisque),
                  }}
                  className={``}
                >
                  {displayReportRiskNotation(p.notationRisque)}
                </p>
                <span className="border-b-[0.5px] border-gray-400 absolute bottom-[-2px] right-[8.3%] w-10/12"></span>
              </div>
              <div className="grid grid-cols-2 relative ">
                <p>Projet immobilisé</p>
                <p>
                  {p.dateBlackList
                    ? `Depuis ${formatDate(new Date(p.dateBlackList))} `
                    : "non"}
                </p>
                <span className="border-b-1 border-gray-400 absolute bottom-0 left-1/4 w-1/2"></span>
              </div>
            </div>
            <div className="flex w-full h-full mw-auto justify-center items-end my-2 self-end pb-2">
              <PrimaryButton
                onClick={() => {
                  setQueryScroll(listRef.current?.scrollTop || 0);
                  setQueryPage("project");
                  setQueryProjectId(p.id);
                  setQueryProjectPage("Documents");
                }}
              >
                Documents
              </PrimaryButton>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div
      key={p.id}
      className="grid gap-x-2 md:grid-cols-2 lg:grid-cols-[1.2fr,1.5fr,1.5fr] xxxl:grid-cols-[1fr,1.5fr,1.5fr]  bg-secondBackgroundColor rounded-md transition-all "
    >
      <div className="relative">
        {/* <div className="absolute top-0 bg-white opacity-80">
          <p className="text-center font-semibold px-2 text-2xl text-gray-700">
            {p?.name}
          </p>
        </div> */}
        <LazyImageBackground
          src={p.cover || ""}
          // src={coverBackground}
          className=" w-full h-[250px] md:h-full rounded-l-md"
          // className={{
          //   img: "w-full max-w-md bg-cover bg-center rounded-md",
          //   div: `w-full h-full md:w-[24rem]  rounded-md ${
          //     menuBoolState?.toggleMenu ? "" : "lg:w-[28rem]"
          //   }`,
          // }}
        />
        <div className="absolute bottom-0 bg-white opacity-80">
          <p className="text-center font-semibold text-sm italic px-2">
            {p?.address}
          </p>
        </div>
      </div>
      <div className="grid md:hidden lg:grid mt-2 md:mt-0 grid-rows-projectUserPresentation px-2 md:px-0">
        <DescriptionInfo />
      </div>

      <div className="flex flex-col lg:border-l-2 px-2 md:px-1 h-full">
        <SuiviImpactComponent />
      </div>

      <div className="hidden px-2 md:grid md:col-span-2 lg:hidden mt-2 md:mt-0 grid-rows-projectUserPresentation">
        <DescriptionInfo />
      </div>
    </div>
  );
}
