import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { useGetFondsQuery } from '../../../../redux/features/fondsSlice';
import {
    uploadDocumentPlatform,
    useDeletePlatformMutation,
    useEditPlatformMutation,
    useGetPlatformQuery,
    useGetPlatformsQuery,
} from '../../../../redux/features/platformSlice';
import {
    CategoryDocumentPlatform,
    FormCreateDocumentPlatformRequest,
    TypeDocumentPlatform,
} from '../../../../types/documentPlatform';
import { FormUpdatePlatform } from '../../../../types/platform';
import { PrimaryButton, WhiteButton } from '../../../commun/Buttons';
import DisplayDocumentPlatform from '../../../commun/DisplayDocuments/DisplayDocumentPlatform';
import FieldArray from '../../../commun/formComponent/FieldArray';
import InputComponent from '../../../commun/formComponent/InputComponent';
import RemoveFieldArrayComponent from '../../../commun/formComponent/RemoveFieldArrayComponent';
import SelectComponent from '../../../commun/formComponent/SelectComponent';
import SelectMultiComponent from '../../../commun/formComponent/SelectMultiComponent';
import TextEditorComponent from '../../../commun/formComponent/TextEditorComponent';
import UploaderSingle from '../../../commun/formComponent/UploaderSingle';
import Loading from '../../../commun/Loading';
import PopUp from '../../../commun/PopUp';
import { BackOfficeState } from '../../../Context/AppContext';
import { useNotificationContext } from '../../../Context/notification-context';
import TableComponent from '../Components/TableComponent';

import LabelComponentForm from '@components/commun/formComponent/LayoutComponents/LabelComponentForm';
import TitleComponentForm from '@components/commun/formComponent/LayoutComponents/TitleComponentForm';
import useDeleteItem from '../../../commun/CustomHook/useDeleteItem';

const ViewTablePlatform = () => {
    const backOfficeState = useContext(BackOfficeState);
    const navigate = useNavigate();

    const { data: platformData, isLoading: isLoadingPlatform } =
        useGetPlatformsQuery();

    const [editToggle, setEditToggle] = useState<boolean>(false);

    const [startIntervalNbItems, setStartIntervalNbItems] = useState<number>(0);
    const [nbItemsShowed, setNbItemsShowed] = useState<number>(50);

    const [queryParam, setQueryParam] = useQueryParam(
        'platformId',
        StringParam
    );

    const classNameObejct = {
        head: 'text-sm text-gray-900 px-6 py-4 text-left font-semibold',
        item: 'text-sm font-light px-6 py-4 whitespace-nowrap',
    };
    return (
        <React.Fragment>
            {editToggle ? (
                <EditPlatForm
                    setEditToggle={setEditToggle}
                    editToggle={editToggle}
                />
            ) : null}

            {!platformData ? (
                <Loading />
            ) : (
                <TableComponent
                    head={['#', 'Platforme', 'fonds']}
                    array={platformData}
                    startIntervalNbItems={startIntervalNbItems}
                    setNbItemsShowed={setNbItemsShowed}
                    nbItemsShowed={nbItemsShowed}
                    setStartIntervalNbItems={setStartIntervalNbItems}
                >
                    <tbody>
                        {!!(backOfficeState?.fondsId !== undefined) && (
                            <React.Fragment>
                                {platformData?.map((item, key) => (
                                    <tr
                                        onClick={() => {
                                            setQueryParam(item.id?.toString());
                                            setEditToggle(true);
                                        }}
                                        key={key}
                                        className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
                                            key % 2 === 0
                                                ? 'bg-gray-100'
                                                : 'bg-white'
                                        } `}
                                    >
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            {key + 1}
                                        </td>
                                        <td className={classNameObejct.item}>
                                            {item?.name}
                                        </td>
                                        <td
                                            className={`${classNameObejct.item} flex gap-2`}
                                        >
                                            {item?.fonds?.map((val) => (
                                                <span key={val.id}>
                                                    {val.name}
                                                </span>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        )}
                    </tbody>
                </TableComponent>
            )}
        </React.Fragment>
    );
};

export default ViewTablePlatform;

function EditPlatForm({
    setEditToggle,
    editToggle,
}: {
    setEditToggle: React.Dispatch<React.SetStateAction<boolean>>;
    editToggle: boolean;
}) {
    const { data: fondsData } = useGetFondsQuery();

    const { showError, showSuccess } = useNotificationContext();

    const [queryParam, setQueryParam] = useQueryParam(
        'platformId',
        StringParam
    );
    const platformId: number | undefined = queryParam
        ? parseInt(queryParam)
        : undefined;

    const {
        data: platform,
        isLoading,
        refetch,
    } = useGetPlatformQuery(platformId || 0, {
        skip: queryParam === undefined,
    });

    const {
        register,
        handleSubmit,
        watch,
        control,
        getValues,
        setValue,
        reset,
    } = useForm<FormUpdatePlatform>({});

    useEffect(() => {
        refetch();
    }, []);

    const setDefautlValues = (): void => {
        reset({
            id: platform?.id,
            name: platform?.name,
            fondsIds: platform?.fonds?.map((val) => val.id),
            description: platform?.description ?? undefined,
            documents: [],
        });
    };

    useEffect(() => {
        if (platform !== undefined) {
            setDefautlValues();
            //   console.log("test", platForm.current);
        }
    }, [platform, queryParam]);

    const [
        editPlatform,
        { isLoading: isLoadingEdit, isSuccess: isSuccessEdit },
    ] = useEditPlatformMutation();

    const [
        deletePlatform,
        { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
    ] = useDeletePlatformMutation();

    const { setOpen, DeleteComponent } = useDeleteItem<{
        platformId: number;
    }>({
        deleteItem: () => deletePlatform(platform?.id!),
        toDoIfSuccess: () => setEditToggle(false),
        message: 'Project in blackList',
        isLoading: isLoadingDelete,
    });

    const onSubmit = async (data: FormUpdatePlatform) => {
        await editPlatform(data)
            .unwrap()
            .then(async (res) => {
                if (data?.documents?.length > 0) {
                    for (let i = 0; i < data?.documents?.length; i++) {
                        const document = data.documents[i];

                        let dataFile: FormCreateDocumentPlatformRequest = {
                            file: document.file[0] as File,
                            type: document.type,
                            platformId: platform?.id!,
                            category: CategoryDocumentPlatform.public,
                            platformName: data.name!,
                            fileName: document.file[0].name,
                            comment: document?.comment,
                        };

                        await uploadDocumentPlatform(dataFile)
                            .then((res) => {
                                if (res.sucess) {
                                    showSuccess(
                                        'Envoyé',
                                        'Le document a été envoyé avec le succès'
                                    );
                                } else {
                                    showError(
                                        'Erreur',
                                        `Erreur lors de l'envoie de ${dataFile.fileName}`
                                    );
                                }
                            })
                            .catch((res) => {
                                showError(
                                    'Erreur',
                                    `Erreur lors de l'envoie de ${dataFile.fileName}`
                                );
                            });
                    }
                }

                showSuccess('Created', 'Platform edited successfully');
                setEditToggle(false);
            })
            .catch((err) => {
                showError('Error', 'Error creating this platform');
            });
    };

    const {
        field: { onChange: onChangeComment, value: comment },
    } = useController<FormUpdatePlatform>({
        name: 'description',
        control: control,
    });

    return (
        <PopUp
            open={editToggle}
            setOpen={setEditToggle}
            submitItemName={`Editer`}
            buttonBoolean={false}
            width="w-full md:w-10/12"
            onClickSubmit={() => {
                onSubmit(watch());
            }}
            title={() => {
                return (
                    <>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Editer cette plateforme
                        </h3>
                        {platform && platform?.id ? (
                            <FontAwesomeIcon
                                onClick={() => setOpen(true)}
                                icon={faTrash}
                                className="absolute top-[-10px] cursor-pointer right-5 transition-all hover:scale-105"
                            />
                        ) : null}
                    </>
                );
            }}
        >
            <DeleteComponent title="cette platforme" />

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="formTemplateSingUp text-start"
            >
                <InputComponent register={register} value={'name'}>
                    <LabelComponentForm>
                        Nom de la plateforme{' '}
                        <span className="text-red-500">*</span>
                    </LabelComponentForm>
                </InputComponent>

                <SelectMultiComponent
                    register={register}
                    setValue={setValue}
                    value={'fondsIds'}
                    optionValues={
                        fondsData
                            ? fondsData?.map((fond) => {
                                  return { value: fond.id, label: fond.name };
                              })
                            : []
                    }
                    control={control}
                    optionsRender={(option) => option.label}
                    valueRender={(option) => option.value}
                >
                    <LabelComponentForm>
                        Fonds <span className="text-red-500">*</span>
                    </LabelComponentForm>
                </SelectMultiComponent>

                <TextEditorComponent
                    content={comment as any as string}
                    setContent={onChangeComment}
                    className=" w-full"
                >
                    <LabelComponentForm>Description</LabelComponentForm>
                </TextEditorComponent>

                <TitleComponentForm className="!w-full mt-3">
                    Document
                </TitleComponentForm>

                <FieldArray
                    control={control}
                    className="w-full"
                    name="documents"
                >
                    {({ fields, append, remove }) => (
                        <>
                            <div className="flex flex-col w-full mb-2">
                                {fields.map((field, index) => (
                                    <div key={field.id} className={``}>
                                        <SelectComponent
                                            register={register}
                                            control={control}
                                            value={`documents.${index}.type`}
                                            getValues={getValues}
                                            setValue={setValue}
                                            optionValues={Object.values(
                                                TypeDocumentPlatform
                                            ).map((v) => {
                                                return {
                                                    label: v,
                                                    value: v,
                                                };
                                            })}
                                        >
                                            <h3 className=" font-semibold mt-3">
                                                Choisisser le type du document
                                                <span className="required">
                                                    *
                                                </span>
                                            </h3>
                                        </SelectComponent>

                                        <UploaderSingle
                                            register={register}
                                            value={`documents.${index}.file`}
                                            watch={watch}
                                            setValue={setValue}
                                            loading={isLoading}
                                            button={false}
                                        ></UploaderSingle>
                                        <div className=" w-full flex justify-center items-center">
                                            <RemoveFieldArrayComponent
                                                remove={remove}
                                                index={index}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className=" w-full flex justify-center items-center">
                                <PrimaryButton
                                    className=" mx-auto mb-2"
                                    onClick={() =>
                                        append({
                                            // projectId: project.current?.id!,
                                        } as any)
                                    }
                                >
                                    Ajouter un document
                                </PrimaryButton>
                            </div>
                        </>
                    )}
                </FieldArray>

                <div className="mt-3">
                    {platform?.documents &&
                        platform?.documents?.map((d, index) => (
                            <DisplayDocumentPlatform
                                key={index}
                                document={d}
                                refetch={refetch}
                            />
                        ))}
                </div>

                <div className="mt-5 w-full sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 text-center">
                    <WhiteButton
                        onClick={() => {
                            setEditToggle(false);
                        }}
                    >
                        Cancel
                    </WhiteButton>
                    <PrimaryButton type="submit">Edit</PrimaryButton>
                </div>
            </form>
        </PopUp>
    );
}
