import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { number } from "yup";
import { formatDate, isDateBetween, isDateClosed } from "../../function/Utils";
import { CashDto, CreateProjectsValorisationDto, ProjectValorisationDto, UpdateProjectsValorisationDto } from "../../types/projectValorisation";
import { GetByFondsByDateBetween } from "../../types/query";



interface projetsValorisationState {
    projectsValorisation: ProjectValorisationDto[];
    projectValorisationByProjectId: ProjectValorisationDto[];
    loading: boolean;
    errors: any;
}

const initialState: projetsValorisationState = {
    projectsValorisation: [],
    projectValorisationByProjectId: [],
    loading: false,
    errors: null,
};

// actions are processes that get data from backend
export const getProjectsValorisations = createAsyncThunk<
    ProjectValorisationDto[]
>("ProjectsValorisations/getProjectsValorisations", async (_, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}projectValorisations`,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

// Get by projectId
export const getProjectsValorisationsByProjectId = createAsyncThunk<
    ProjectValorisationDto[],
    number
>("ProjectsValorisations/getProjectsValorisationsByProjectId", async (id, thunkAPI) => {
    try {
        const response = await axios({
            method: "get",
            url: `${import.meta.env.VITE_API_URL}projectValorisations/projectId`,
            withCredentials: true,
            params: {
                projectId: id
            }
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});




export const addProjectsValorisation = createAsyncThunk<
    ProjectValorisationDto,
    CreateProjectsValorisationDto
>("ProjectsValorisations/addProjectsValorisation", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "post",
            url: `${import.meta.env.VITE_API_URL}projectValorisations`,
            data: data,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const addProjectsValorisations = createAsyncThunk<
    ProjectValorisationDto[],
    CreateProjectsValorisationDto[]
>("ProjectsValorisations/addProjectsValorisations", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "post",
            url: `${import.meta.env.VITE_API_URL}projectValorisations/array`,
            data: data,
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const editProjectsValorisation = createAsyncThunk<
    ProjectValorisationDto,
    UpdateProjectsValorisationDto
>("ProjectsValorisations/editProjectsValorisation", async (data, thunkApi) => {
    try {
        const response = await axios({
            method: "put",
            url: `${import.meta.env.VITE_API_URL}projectValorisations/${data.id}`,
            data: { ...data },
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});



export const deleteProjectsValorisation = createAsyncThunk<number, number>(
    "ProjectsValorisations/deleteProjectsValorisation",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}projectValorisations/${id}`,
                withCredentials: true,
            });
            return id;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteProjectsValorisationByProjectId = createAsyncThunk<number, number>(
    "ProjectsValorisations/deleteProjectsValorisationByProjectId",
    async (projectId: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}projectValorisations/projectId/${projectId}`,
                withCredentials: true,
            });
            return projectId;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);


export const deleteProjectsValorisationByDate = createAsyncThunk<string, string>(
    "ProjectsValorisations/deleteProjectsValorisationByDate",
    async (date: string, thunkApi) => {
        try {

            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}projectValorisations/date/${date}`,
                withCredentials: true,
            });
            return date;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }

    }
);

export interface GetBetweenDates {
    dateStart: Date;
    dateEnd: Date;
}

export const deleteProjectsValorisationBetweenDates = createAsyncThunk<GetBetweenDates, GetBetweenDates>(
    "ProjectsValorisations/deleteProjectsValorisationBetweenDates",
    async (dates: GetBetweenDates, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}projectValorisations/betweenDates`,
                withCredentials: true,
                params: {
                    dateStart: dates.dateStart,
                    dateEnd: dates.dateEnd
                }
            });
            return dates;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);


export const deleteAllProjectsValorisations = createAsyncThunk(
    "ProjectsValorisations/deleteAllProjectsValorisations",
    async (_, thunkAPI) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}projectValorisations`,
                withCredentials: true,
            });

        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


// reducers -> reduce to a specific state -> changes state

export const ProjectsValorisationSlice = createSlice({
    name: "ProjectsValorisations",
    initialState,
    reducers: {
        setProjectsValorisations: (
            state,
            action: PayloadAction<ProjectValorisationDto[]>
        ) => {
            state.projectsValorisation = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectsValorisations.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProjectsValorisations.fulfilled, (state, action) => {
            state.projectsValorisation = action.payload;
            state.loading = false;
        });
        builder.addCase(getProjectsValorisations.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(getProjectsValorisationsByProjectId.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getProjectsValorisationsByProjectId.fulfilled, (state, action) => {
            // add if does not exist in state.cash 
            state.projectValorisationByProjectId = action.payload
            state.loading = false;
        });
        builder.addCase(getProjectsValorisationsByProjectId.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });

        builder.addCase(addProjectsValorisations.fulfilled, (state, action) => {
            state.projectsValorisation = [...state.projectsValorisation, ...action.payload];
            state.loading = false;
        });
        builder.addCase(addProjectsValorisations.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addProjectsValorisations.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addProjectsValorisation.fulfilled, (state, action) => {
            state.projectsValorisation = [...state.projectsValorisation, action.payload];
            state.loading = false;
        });
        builder.addCase(addProjectsValorisation.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addProjectsValorisation.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editProjectsValorisation.fulfilled, (state, action) => {
            state.loading = false;
            state.projectsValorisation = state.projectsValorisation.map((ProjectsValorisation) => {
                if (ProjectsValorisation.id === action.payload.id) {
                    return {
                        ...ProjectsValorisation,
                        ...action.payload,

                    };
                } else return ProjectsValorisation;
            });
            state.projectValorisationByProjectId = state.projectValorisationByProjectId.map((ProjectsValorisation) => {
                if (ProjectsValorisation.id === action.payload.id) {
                    return {
                        ...ProjectsValorisation,
                        ...action.payload,

                    };
                } else return ProjectsValorisation;
            });
        });
        builder.addCase(editProjectsValorisation.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editProjectsValorisation.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });

        builder.addCase(deleteProjectsValorisation.fulfilled, (state, action) => {
            state.loading = false;
            state.projectsValorisation = state.projectsValorisation.filter(
                (ProjectsValorisation) => ProjectsValorisation.id !== action.payload
            );
        });
        builder.addCase(deleteProjectsValorisation.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteProjectsValorisation.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteProjectsValorisationByProjectId.fulfilled, (state, action) => {
            state.loading = false;
            state.errors = false;
            state.projectsValorisation = state.projectsValorisation.filter(
                (ProjectsValorisation) => ProjectsValorisation.projectId !== action.payload
            );
        });
        builder.addCase(deleteProjectsValorisationByProjectId.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteProjectsValorisationByProjectId.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteAllProjectsValorisations.fulfilled, (state, action) => {
            state.loading = false;
            state.projectsValorisation = []
        });
        builder.addCase(deleteAllProjectsValorisations.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteAllProjectsValorisations.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteProjectsValorisationByDate.fulfilled, (state, action) => {
            state.loading = false;
            state.projectsValorisation = state.projectsValorisation.filter(
                (ProjectsValorisation) => !isDateClosed(new Date(ProjectsValorisation.date), new Date(action.payload))
            );
        });
        builder.addCase(deleteProjectsValorisationByDate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteProjectsValorisationByDate.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteProjectsValorisationBetweenDates.fulfilled, (state, action) => {
            state.loading = false;
            state.projectsValorisation = state.projectsValorisation.filter(
                (ProjectsValorisation) => !isDateBetween(action.payload.dateStart, action.payload.dateEnd, new Date(ProjectsValorisation.date)))

        });
        builder.addCase(deleteProjectsValorisationBetweenDates.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteProjectsValorisationBetweenDates.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
    },
});

export default ProjectsValorisationSlice.reducer;
export const { setProjectsValorisations } = ProjectsValorisationSlice.actions;
