import React from "react";
import { Link } from "react-router-dom";
import { fondInterface } from "../../types/data";
import CareScaleFond from "../commun/CareScaleFond";

interface Props {
  fond: fondInterface;
}

const VueEnsFondItems: React.FC<Props> = ({ fond }) => {
  return (
    <div className="VueEnsFondItems fond_sub_item">
      <h2 className="text-xl">{fond.name}</h2>
      <img
        className="fond_item_cover"
        src={fond.cover}
        alt={`${fond.name} cover`}
      />
      <p>
        Rendement :{" "}
        <span className="title-color lighter">{fond.rendement} %</span>
      </p>
      <p>
        Liquidité :{" "}
        <span className="title-color lighter">
          {fond.liquidite} ans minimum
        </span>
      </p>
      <div className="texteFond">{fond.textePresentation?.()}</div>
      <div className="careScale_container">
        <p className="colorTitle">Impact</p>
        <div className="careScaleFond">
          <div>Social :</div>
          <div className="cercle_container">
            <CareScaleFond scaleValue={fond.social} />
          </div>
        </div>
        <div className="careScaleFond">
          <div>Environnemental :</div>
          <div className="cercle_container">
            <CareScaleFond scaleValue={fond.environnemental} />
          </div>
        </div>
      </div>
      <div className="discover_container">
        <div className="line"></div>
        {!fond.path ? (
          <p className="text_center">Découvrir prochainement</p>
        ) : (
          <Link to={fond.path} className="pointer">
            Découvrir
          </Link>
        )}
        <div className="line"></div>
      </div>
      <div className="line_fin"></div>
    </div>
  );
};

export default VueEnsFondItems;
