import React from "react";
import { ProjectDto, ProjectForUserDto } from "../../../types/project";
import { useGetPlatformQuery } from "../../../redux/features/platformSlice";
import DisplayTextEditor from "../../commun/DisplayTextEditor";
import Loading from "../../commun/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMousePointer,
  faCogs,
  faDollarSign,
  faChartLine,
  faBullhorn,
  faVoteYea,
  faGavel,
  faBalanceScale,
  faCheckCircle,
  faStream,
  faWater,
} from "@fortawesome/free-solid-svg-icons";
import { ErrorIcon, SuccessIcon } from "../../commun/IconsFeedBack";

export const RiskNotationInfo = () => {
  const data = [
    {
      grade: "A",
      description: "Rien à signaler",
      color: "bg-green-800",
      pc: true,
      pi: false,
    },
    {
      grade: "A-",
      description:
        "Situation robuste avec des éléments de préoccupation mineurs",
      color: "bg-green-600",
      pc: true,
      pi: false,
    },
    {
      grade: "B",
      description: "Situation robuste mais avec un retard significatif",
      color: "bg-green-400",
      pc: true,
      pi: false,
    },
    {
      grade: "B-",
      description: "Situation défavorable mais non critique",
      color: "bg-yellow-300",
      pc: false,
      pi: true,
    },
    {
      grade: "C",
      description: "Situation défavorable et critique",
      color: "bg-yellow-500",
      pc: false,
      pi: true,
    },
    {
      grade: "C-",
      description: "En phase de recouvrement judiciaire",
      color: "bg-red-500",
      pc: false,
      pi: true,
    },
    {
      grade: "D",
      description: "Défaut acté",
      color: "bg-black",
      pc: false,
      pi: true,
    },
  ];

  return (
    <div className="container mx-auto mt-4 p-4">
      <div className="overflow-x-auto max-w-2xl mx-auto">
        <table className="min-w-full border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 border border-gray-300">Grade</th>
              <th className="px-4 py-2 border border-gray-300">Description</th>
              <th className="px-4 py-2 border border-gray-300">
                Portefeuille <br /> commun
              </th>
              <th className="px-4 py-2 border border-gray-300">
                Projet <br /> immobilisé
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className={""}>
                <td
                  className={`px-4 py-2 border border-gray-300 ${item.color} ${
                    index === 6 ? " text-white" : ""
                  }`}
                >
                  {item.grade}
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  {item.description}
                </td>
                <td className="px-4 py-2 border  border-gray-300">
                  <div className="flex justify-center items-center">
                    {item.pc ? <SuccessIcon /> : null}
                  </div>
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  <div className="flex justify-center items-center">
                    {item.pi ? <ErrorIcon /> : null}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className=" mt-[50px] flex flex-col gap-2">
        {/* <h3 className="font-mainFontFamily text-xl mb-3">
          Notion de portefeuille du niveau de santé de cet investissement
        </h3> */}
        <p>
          <span className=" font-semibold">Portefeuille commun:</span> ce
          portefeuille est commun à tous les investisseurs du fonds. Tout nouvel
          investissement dans le fonds est uniquement exposé à ce portefeuille
          commun.
        </p>
        <p>
          <span className=" font-semibold">Projet immobilisé:</span> projet
          retiré du portefeuille commun en raison de doutes concernant sa
          capacité à remplir ses obligations. Les investisseurs ayant cet actif
          dans leur portefeuille commun, lorsqu'il est retiré et placé sur la
          liste des projets immobilisés, verront la partie de leur
          investissement associée à cet actif gelée jusqu'à la conclusion de
          l'investigation.
        </p>
      </div>
    </div>
  );
};

export const OperationFollowInfo = ({
  project,
}: {
  project?: ProjectForUserDto;
}) => {
  const { data: platform, isLoading } = useGetPlatformQuery(
    project?.platformId || 0,
    {
      skip: !project?.platformId,
    }
  );

  const data = [
    {
      title: "Sélectionner",
      icon: faMousePointer,
      description:
        "Sélectionner le projet à financer parmi l'ensemble des demandes de financement reçues (analyse de la robustesse du projet).",
    },
    {
      title: "Structurer",
      icon: faCogs,
      description:
        "Structurer celui-ci (négociation des garanties, du taux d'intérêt, etc.).",
    },
    {
      title: "Financer",
      icon: faDollarSign,
      description:
        "Lever les fonds en proposant le projet structuré à son réseau d'investisseurs.",
    },
    {
      title: "Suivre",
      icon: faChartLine,
      description: "S'assurer du bon déroulement de l'opération.",
    },
    {
      title: "Communiquer",
      icon: faBullhorn,
      description:
        "Communiquer de manière régulière l'état de l'opération aux investisseurs.",
    },
    {
      title: "Soumettre au vote",
      icon: faVoteYea,
      description:
        "Saisir les investisseurs au vote en cas de besoin de prise de décision (modification des termes, activation de garantie, etc.).",
    },
    {
      title: "Acter les décisions",
      icon: faGavel,
      description:
        "Piloter l'activation de garantie voire de recouvrement si nécessaire en se reposant sur son expertise interne ou en faisant appel à des cabinets spécialisés externes.",
    },
  ];

  return (
    <div className="">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mt-3">
            <p className="mt-2 text-center text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
              Le rôle de {platform?.name}
            </p>

            <div className="mx-auto mt-8 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                {data.map((feature, index) => (
                  <div key={feature.title} className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-secondColor">
                        <FontAwesomeIcon
                          icon={feature.icon}
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      {index + 1}. {feature.title}
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export function OurRoleInfo() {
  const data = [
    {
      title: "Sélectionner",
      icon: faCheckCircle,
      description:
        "Sélectionner les opérations les mieux structurées parmi les offres d'un large panel d'originateurs sélectionnés.",
    },
    {
      title: "Diversifier",
      icon: faBalanceScale,
      description:
        "Limiter votre exposition aux risques de chaque projet sélectionné grâce à une forte diversification.",
    },
    {
      title: "Simplifier",
      icon: faStream,
      description: "Simplifier la gestion de centaines de projets investis.",
    },
    {
      title: "Liquidité",
      icon: faWater,
      description:
        "Faciliter la gestion de votre investissement (sortie, distribution).",
    },
    {
      title: "Optimiser",
      icon: faChartLine,
      description:
        "Optimiser votre performance (commission inversée, capitalisation, frais très réduits).",
    },
  ];
  return (
    <div className="container mx-auto p-4">
      <div className="mt-8">
        <p className="mt-2 text-center text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
          Le rôle de New Paradigms
        </p>

        <div className="mx-auto mt-8 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {data.map((feature) => (
              <div key={feature.title} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-mainColor">
                    <FontAwesomeIcon
                      icon={feature.icon}
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.title}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
