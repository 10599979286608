import React, { useContext } from 'react';
import { useFetchTokenQuery } from '../../../../redux/features/tokenSlice';
import { useGetUserWithAllInfoForCustomerQuery } from '../../../../redux/features/userSlice';
import { UserWithAllInfoDto } from '../../../../types/user';
import { EtapeForm } from '../../../Context/AppContext';

import Loading from '../../../commun/Loading';

import { PrimaryButton, WhiteButton } from '@components/commun/Buttons';
import UpdateInvestAccountComponent from '@components/commun/formComponent/InvestAccount/UpdateInvestAccountComponent';
import AddInvestAccountComponent from '../../../commun/formComponent/InvestAccount/AddInvestAccountComponent';
import TitleComponentForm from '../../../commun/formComponent/LayoutComponents/TitleComponentForm';

interface Props {}

const FormEtapeInvestAccount: React.FC<Props> = ({}) => {
    const { data: userData, isLoading: loadingUser } = useFetchTokenQuery();
    const { data: user, isLoading: loading } =
        useGetUserWithAllInfoForCustomerQuery(userData?.id ?? 0, {
            skip: !userData,
        });

    return (
        <div>
            {loadingUser || loading ? (
                <Loading />
            ) : (
                <div>
                    <TitleComponentForm className="!w-11/12 mx-auto !mb-4 !text-secondColor">
                        Compte d'investissement
                    </TitleComponentForm>
                    <p className="w-full px-2 md:w-11/12 mt-2 mb-4 text-center mx-auto">
                        Créez un compte d'investissement vous pourrez en créer
                        d'autres dans votre espace personnel.
                    </p>
                    {user?.investAccounts && user.investAccounts.length > 0 ? (
                        <EditInvestAccount
                            investAccountId={user.investAccounts?.[0].id!}
                            user={user!}
                        />
                    ) : (
                        <AddInvestAccount user={user!} />
                    )}
                </div>
            )}
        </div>
    );
};

export default FormEtapeInvestAccount;

function AddInvestAccount({ user }: { user: UserWithAllInfoDto }) {
    const etapeObject = useContext(EtapeForm);

    const next = etapeObject?.stepInsideStep0!;
    const setNext = etapeObject?.setStepInsideStep0!;

    return (
        <div className="form_investisseur_container">
            <AddInvestAccountComponent
                user={user}
                useSubmitBtn={false}
                beforeSubmitLogic={async ({ isDirty, isDirtyForm, watch }) => {
                    if (
                        !isDirty ||
                        !isDirtyForm ||
                        watch('iban')?.length === 0 ||
                        watch('bic')?.length === 0 ||
                        watch('documentRib')?.length === 0
                    ) {
                        setNext(next + 1);
                        return false;
                    } else {
                        return true;
                    }
                }}
                defaultValues={{
                    userId: user.id,
                    physicalOrMoral: 'physical',
                }}
                onSubmitSuccess={() => {
                    setNext(next + 1);
                }}
            >
                {({ disabled, loading }) => (
                    <div className="flex items-center gap-2 justify-center mt-10 mb-10 ">
                        <WhiteButton onClick={() => setNext(next - 1)}>
                            Précedent
                        </WhiteButton>
                        <PrimaryButton
                            type="submit"
                            disabled={disabled}
                            loading={loading}
                        >
                            Continuer
                        </PrimaryButton>
                    </div>
                )}
            </AddInvestAccountComponent>
        </div>
    );
}

function EditInvestAccount({
    investAccountId,
    user,
}: {
    investAccountId: number;
    user: UserWithAllInfoDto;
}) {
    const etapeObject = useContext(EtapeForm);

    const next = etapeObject?.stepInsideStep0!;
    const setNext = etapeObject?.setStepInsideStep0!;

    // const { ValidationCode, handleSubmitForm } = useValidationCode({
    //   onSubmit: handleSubmit(onSubmit),
    // });
    return (
        <UpdateInvestAccountComponent
            user={user}
            onSubmitSuccess={() => {
                setNext(next + 1);
            }}
            useSubmitBtn={false}
            investAccountId={investAccountId}
            beforeSubmitLogic={async ({ isDirty }) => {
                if (isDirty) {
                    setNext(next + 1);
                    return false;
                } else {
                    return true;
                }
            }}
        >
            {({ disabled, loading }) => (
                <div className="flex items-center gap-2 justify-center mt-10 mb-10 ">
                    <WhiteButton onClick={() => setNext(next - 1)}>
                        Précedent
                    </WhiteButton>
                    <PrimaryButton
                        type="submit"
                        disabled={disabled}
                        loading={loading}
                    >
                        Continuer
                    </PrimaryButton>
                </div>
            )}
        </UpdateInvestAccountComponent>
    );
}
