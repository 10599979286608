import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';
import { CreateFondsDto, FondsDto, UpdateFondsDto } from '../../types/fonds';



interface fondsState {
    fonds: FondsDto[];
    loading: boolean;
    errors: any;
}

const initialState: fondsState = {
    fonds: [],
    loading: false,
    errors: null,
}

// actions are processes that get data from backend
export const getFonds = createAsyncThunk<FondsDto[]>(
    "fonds/getFonds",
    async (_, thunkAPI) => {
        try {
            const response = await axios({
                method: "get",
                url: `${import.meta.env.VITE_API_URL}fonds`,
                withCredentials: true,
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const addFonds = createAsyncThunk<FondsDto, CreateFondsDto>(
    "fonds/addFonds",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "post",
                url: `${import.meta.env.VITE_API_URL}fonds`,
                data: data,
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const editFonds = createAsyncThunk<FondsDto, UpdateFondsDto>(
    "fonds/editFonds",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "put",
                url: `${import.meta.env.VITE_API_URL}fonds/${data.id}`,
                data: { ...data },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const deleteFonds = createAsyncThunk<number, number>(
    "fonds/deleteFonds",
    async (id: number, thunkApi) => {
        try {
            const response = await axios({
                method: "delete",
                url: `${import.meta.env.VITE_API_URL}fonds/${id}`,
                withCredentials: true,
            })
            return id
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const uploadCoverFonds = createAsyncThunk<FormData, FormData>(
    "fonds/uploadCoverFonds",
    async (data, thunkApi) => {
        try {
            const response = await axios({
                method: "post",
                url: `${import.meta.env.VITE_API_URL}fonds/upload`,
                data: data,
                headers: { "Content-Type": 'multipart/form-data' },
                withCredentials: true,
            })
            return response.data
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);


// reducers -> reduce to a specific state -> changes state 

export const fondsSlice = createSlice({
    name: "fonds",
    initialState,
    reducers: {
        setFonds: (state, action: PayloadAction<FondsDto[]>) => {
            state.fonds = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFonds.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getFonds.fulfilled, (state, action) => {
            state.fonds = action.payload;
            state.loading = false;
        });
        builder.addCase(getFonds.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(addFonds.fulfilled, (state, action) => {
            state.fonds = [...state.fonds, action.payload]
            state.loading = false;
        });
        builder.addCase(addFonds.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addFonds.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(editFonds.fulfilled, (state, action) => {
            state.loading = false;
            state.fonds = state.fonds.map((fond) => {
                if (fond.id === action.payload.id) {
                    return {
                        ...fond,
                        ...action.payload

                    };
                } else return fond;

            });
        });
        builder.addCase(editFonds.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editFonds.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
        builder.addCase(deleteFonds.fulfilled, (state, action) => {
            state.loading = false;
            state.fonds = state.fonds.filter((fond) => fond.id !== action.payload);
        });
        builder.addCase(deleteFonds.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteFonds.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.error;
        });
    }

});

export default fondsSlice.reducer;
export const { setFonds } = fondsSlice.actions;


