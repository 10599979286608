import React, { useState } from "react";
import { useGetOverviewProjectsUserQuery } from "../../../redux/features/projectSlice";
import { useQueryParam, StringParam, NumberParam } from "use-query-params";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Loading from "../Loading";
import { LazyImage } from "../animation/LazyImage";
import { Icon } from "leaflet";
// import LocationIcon from "./../../../assets/LocationIcon.svg";
import LocationIcon from "./../../../assets/map-marker-svgrepo-com.svg";

import { Link } from "react-router-dom";
import { PrimaryButton } from "../Buttons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getInterest, lastDay } from "../../../function/Utils";
import { displayHideLongText } from "../../DashboardAdmin/ProjectManage/Suivi/Suivis";
import { useFetchTokenQuery } from "../../../redux/features/tokenSlice";

interface Props {
  fondsId: number;
  height: string;
  width: string;
  className?: string;
  listRef: React.RefObject<HTMLDivElement>;
}

const ProjectsMap: React.FC<Props> = ({
  fondsId,
  width,
  height,
  className,
  listRef,
}) => {
  const [searchPlatformName, setSearchPlatformName] = useState<string>("");
  const [limit, setLimit] = useState<number>(100);
  const [offset, setOffset] = useState<number>(0);
  const [rangeChosen, setRangeChosen] = useState<number>(1);

  const [searchProjectQuery, setSearchProjectQuery] = useQueryParam(
    "search",
    StringParam
  );

  const { data: user } = useFetchTokenQuery();

  const userId = user?.id || 0;

  const {
    data: res,
    isLoading: isLoadingProjects,
    isFetching,
  } = useGetOverviewProjectsUserQuery(
    {
      dateStart: new Date(2000, 0, 1),
      dateEnd: new Date(2030, 0, 1),
      fondsId: fondsId,
      searchProjectName: searchProjectQuery || "",
      searchPlatformName: searchPlatformName,
      limit: limit,
      offset: offset,
      closed: false,
      userId: userId,
    },
    {
      skip: !(userId > 0),
    }
  );

  const projectsNew = res?.rows;
  const nbProjects = res?.count;

  const [queryProjectId, setQueryProjectId] = useQueryParam(
    "projectId",
    NumberParam
  );

  const [queryPage, setQueryPage] = useQueryParam("pageFondsUser", StringParam);
  const [queryProjectPage, setQueryProjectPage] = useQueryParam(
    "pageProjectUser",
    StringParam
  );

  const [queryScroll, setQueryScroll] = useQueryParam(
    "scrollHeight",
    NumberParam
  );

  return (
    <div>
      {isLoadingProjects ? (
        <Loading />
      ) : (
        <div
          className={`flex z-0 w-full justify-center items-center ${className}`}
        >
          <MapContainer
            center={[46.41, 2.332]}
            zoom={5}
            style={{ height: height, width: width }}
            scrollWheelZoom={false}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {projectsNew?.map((project) => (
              <>
                {project.latitude && project.longitude ? (
                  <Marker
                    icon={
                      new Icon({
                        iconUrl: LocationIcon,
                        iconSize: [25, 41],
                        iconAnchor: [10, 41],
                      })
                    }
                    eventHandlers={{
                      mouseover: (event) => event.target.openPopup(),
                    }}
                    key={project.id}
                    position={[project.latitude, project.longitude]}
                  >
                    <Popup
                      //   eventHandlers={{
                      //     mouseout: (event) => event.target.closePopup(),
                      //   }}
                      className=""
                    >
                      <div className="font-mainFontFamily text-[1.1rem] w-full">
                        <h3 className=" text-center font-mainFontFamily text-2xl text-mainColor">
                          {project.name}
                        </h3>
                        <LazyImage
                          src={project?.cover || ""}
                          className={{
                            img: "my-2 w-[95%] min-h-[100px] mx-auto bg-cover bg-center rounded-sm",
                            div: "flex justify-center items-center",
                          }}
                        />
                        <div className="flex flex-col mt-0">
                          <p className="!p-0 !m-0">{project?.fundingType}</p>
                          <div className="flex mt-1 gap-1 mb-1 flex-wrap">
                            {project?.projectMarketTypology?.map((v, index) => (
                              <div
                                key={index}
                                className=" font-semibold flex gap-1 items-center"
                              >
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  className="text-mainColor h-2 w-2"
                                />{" "}
                                <p className="!p-0 !m-0">{v}</p>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="flex my-1 gap-1">
                          <p className="!p-0 !m-0">
                            {project?.duration} mois -{" "}
                          </p>

                          <p className=" text-secondColor !p-0 !m-0">
                            {getInterest(
                              project.interests,
                              lastDay(new Date(Date.now()))
                            )}{" "}
                            % -{" "}
                          </p>

                          <p className="!p-0 !m-0">
                            Notation: {project?.notationPlatform}
                          </p>
                        </div>
                        <p className="text-[1rem] !p-0 !m-0 text-justify font-mainFontFamily">
                          {displayHideLongText(
                            "Pas de description",
                            project.description,
                            150
                          )}
                        </p>
                        <div className="w-full mt-1 flex justify-center items-center">
                          <PrimaryButton
                            onClick={() => {
                              setQueryScroll(listRef.current?.scrollTop || 0);
                              setQueryPage("project");
                              setQueryProjectId(project.id);
                              setQueryProjectPage("general-info");
                            }}
                          >
                            Détails
                          </PrimaryButton>
                        </div>
                      </div>
                    </Popup>
                  </Marker>
                ) : null}
              </>
            ))}
          </MapContainer>
        </div>
      )}
    </div>
  );
};

export default ProjectsMap;
