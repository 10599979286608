import axios from 'axios';

export const logout = async () => {
    await axios({
        method: 'get',
        url: `${import.meta.env.VITE_API_URL}users/auth/logout`,
        withCredentials: true,
    })
        .then((res) => {
            console.log(res);
            window.location.href = '/';
        })
        .catch((err) => console.log(err));

    // window.location.href = "/";
};
