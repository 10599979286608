import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ProjectWithValorisationDto } from "../../types/project";
import { FeeValueDto, GetByDateBetweenByFundsDto, OverviewAdminDto, ProjectValorisationForAdminDto } from "../../types/shareApi";

interface shareApiState {
    overviewAdmin: OverviewAdminDto | null;
    projectValorisationForAdminDto: ProjectValorisationForAdminDto | null;
    cash: FeeValueDto[];
    lastDateComputed: Date | null;
    loading: boolean;
    date: Date | null;
    error: any;
}

const initialState: shareApiState = {
    overviewAdmin: null,
    projectValorisationForAdminDto: null,
    cash: [],
    lastDateComputed: null,
    loading: false,
    date: null,
    error: null,
}

export interface GetOverViewAdminInfoProps {
    date: Date;
    applyFees: boolean;
}

export const getOverViewAdminInfo = createAsyncThunk<OverviewAdminDto, GetOverViewAdminInfoProps>('shareApi/getOverViewAdminInfo', async (paylaod, thunkApi) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${import.meta.env.VITE_API_URL}shareApi/overviewAdmin`,
            withCredentials: true,
            params: {
                date: paylaod.date,
                applyFees: paylaod.applyFees
            }
        })

        return response.data
    }
    catch (error) {
        return thunkApi.rejectWithValue(error)
    }

}
)



export const getCashByFondsIdBetweenDates = createAsyncThunk<
    FeeValueDto[],
    GetByDateBetweenByFundsDto
>('shareApi/getCashByFondsIdBetweenDates', async (payload, thunkApi) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${import.meta.env.VITE_API_URL}shareApi/cash`,
            withCredentials: true,
            params: {
                dateEnd: payload.dateEnd,
                fondsId: payload.fondsId,
                dateStart: payload.dateStart
            }
        })

        return response.data
    }
    catch (error) {
        return thunkApi.rejectWithValue(error)
    }
}
)

export const getLatestDateComputed = createAsyncThunk<
    Date
>('shareApi/getLatestDateComputed', async (_, thunkApi) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${import.meta.env.VITE_API_URL}shareApi/latestDateComputed`,
            withCredentials: true,
        })

        return response.data
    }
    catch (error) {
        return thunkApi.rejectWithValue(error)
    }
}
)


export const shareApiSlice = createSlice({
    name: 'shareApi',
    initialState,
    reducers: {
        setOverviewAdmin: (state, action: PayloadAction<OverviewAdminDto | null>) => {
            state.overviewAdmin = action.payload;
        },
        setProjectsWithValorisations: (state, action: PayloadAction<ProjectWithValorisationDto[]>) => {
            state.projectValorisationForAdminDto = {
                projectWithValorisations: action.payload,
                valorisationByProjects: state.projectValorisationForAdminDto?.valorisationByProjects ?? []


            };
        },
        setProjectWithValorisation: (state, action: PayloadAction<ProjectWithValorisationDto>) => {

            state.projectValorisationForAdminDto = {
                projectWithValorisations: state.projectValorisationForAdminDto?.projectWithValorisations?.map(p => p.id === action.payload.id ? action.payload : p) ?? [],
                valorisationByProjects: state.projectValorisationForAdminDto?.valorisationByProjects ?? []
            };

        }
    },
    extraReducers: (builder) => {
        builder.addCase(getOverViewAdminInfo.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getOverViewAdminInfo.fulfilled, (state, action) => {
            state.overviewAdmin = action.payload;
            state.date = action.meta.arg.date;
            if (!action.meta.arg.applyFees) {
                state.date = null;
            }
            state.loading = false;
        })
        builder.addCase(getOverViewAdminInfo.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })

        builder.addCase(getCashByFondsIdBetweenDates.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getCashByFondsIdBetweenDates.fulfilled, (state, action) => {
            state.cash = action.payload;
            state.loading = false;
        })
        builder.addCase(getCashByFondsIdBetweenDates.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        })
        // builder.addCase(getLatestDateComputed.pending, (state) => {
        //     state.loading = true;
        // })
        builder.addCase(getLatestDateComputed.fulfilled, (state, action) => {
            state.lastDateComputed = action.payload;

        })
        builder.addCase(getLatestDateComputed.rejected, (state, action) => {
            state.error = action.payload;
            // state.loading = false;
        })
    }
})

export const { setOverviewAdmin, setProjectWithValorisation, setProjectsWithValorisations } = shareApiSlice.actions;

export default shareApiSlice.reducer;


