import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import {
    CreateCustomTransactionProjectInComing,
    CreateTransactionDto,
    CreateTransactionSponsorDto,
    DocumentTransactionDto,
    GetLiquidityFuture,
    IRecapSponsorShip,
    LiquidityFutureDto,
    TransactionDto,
    TransactionSponsorDto,
    TransactionStatus,
    TransactionUserDto,
    TypeTransaction,
    UpdateTransactionDto,
    UpdateTransactionProjectDto,
    UpdateTransactionSponsorDto,
    ViewTransactionsDto,
    ViewTransactionsSponsorDto,
} from '../../types/transactions';
import { baseQueryHandleError } from '../index.rtk';
import { DeleteDocument } from './projectSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}transactions`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export interface UploadDocumentTransactionDto {
    transactionId: number;
    file: File;
    // type: TypeDocumentTransaction;
    fileName: string;
    comment?: string;
}

export interface GetTransactionTable {
    dateStart: Date;
    dateEnd: Date;
    limit: number;
    offset: number;
    fondsId: number;
    type: TypeTransaction | string;
    from: string;
    to: string;
    status: string | TransactionStatus;
}

export interface GetTransactionSponsorRefund {
    dateStart: Date;
    dateEnd: Date;
    limit: number;
    offset: number;
    sponsorName: string;
    sponsoredName: string;
    status: TransactionStatus | string;
}

export interface ManageTransactionOutShareDRequest {
    fondsId: number;
    limit: number;
    offset: number;
    investisor: string;
    status: string;
}
export interface GetFutureTransactionOutShareDRequest {
    fondsId: number;
}

export interface AmountTransactionOutShareD {
    date: Date;
    amount: number;
}

export interface GetFutureTransactionOutShareDResponse {
    amountTransactionOutShareDDay: AmountTransactionOutShareD[];
    amountTransactionOutShareDMonth: AmountTransactionOutShareD[];
}

export interface ManageTransactionOutShareDResponse {
    transactions: TransactionDto[];
    nbTransactions: number;
}

export interface ValidSubscriptionRequest {
    sId: number;
    statusId: number;
    date: Date;
    userId: number;
    dateInterest: Date;
}

export interface ValidTransationSubscriptionRequest {
    transactionId: number;
    date: Date;
    dateInterest: Date;
}

export interface InvestInFundsByUserRequest {
    investAccountId: number;
    values: InvestInFundsDto[];
    userId: number;
}

export interface InvestInFundsDto {
    fondsId: number;
    amount: number;
    typeShare: 'distribution' | 'cumulative';
}

export interface GetEntranceFeeForTransactionRequest {
    userId: number;
}

export interface GetEntranceFeeForTransactionResponse {
    entranceFee: number;
    sponsorId?: number;
}

export const transactionsApi = createApi({
    reducerPath: 'transactionsApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: [
        'Documents',
        'Transaction',
        'Project',
        'TransactionProject',
        'TransactionShareD',
    ],
    endpoints: (builder) => ({
        getTransactions: builder.query<TransactionDto[], void>({
            query: () => `/`,
        }),
        getTransactionsUser: builder.query<TransactionUserDto[], number>({
            query: (id) => `/user/transactions/${id}`,
            transformResponse: (response: TransactionUserDto[]) => {
                return response.map((r) => {
                    return {
                        ...r,
                        date: new Date(r.date),
                    };
                });
            },
        }),
        // Investir
        investInFundsByUser: builder.mutation<
            {
                subscriptionId: number;
            },
            InvestInFundsByUserRequest
        >({
            query: (payload) => ({
                url: `/user/investir/${payload.userId}`,
                method: 'POST',
                body: payload,
            }),
        }),
        // Get transaction entranceFee
        getEntranceFeeForTransaction: builder.query<
            GetEntranceFeeForTransactionResponse,
            GetEntranceFeeForTransactionRequest
        >({
            query: (request) => `/user/entranceFee/${request.userId}`,
        }),
        // transaction done by user
        transactionDoneByUser: builder.mutation<
            void,
            {
                transactionId: number;
                subscriptionId: number;
                statusUserId: number;
                userId: number;
            }
        >({
            query: (payload) => ({
                url: `/user/transactionDone/${payload.userId}`,
                method: 'PUT',
                params: {
                    subscriptionId: payload.subscriptionId,
                    statusUserId: payload.statusUserId,
                    transactionId: payload.transactionId,
                },
            }),
        }),
        // transaction received successfully
        transactionReceived: builder.mutation<void, number>({
            query: (transactionId) => ({
                url: `/user/transactionReceived/${transactionId}`,
                method: 'PUT',
            }),
        }),
        // valid invest
        validSubscription: builder.mutation<
            { success: boolean },
            ValidSubscriptionRequest
        >({
            query: (payload) => ({
                url: `user/validSubscription/${payload.sId}`,
                method: 'PUT',
                body: {
                    subscriptionId: payload.sId,
                    statusId: payload.statusId,
                    date: payload.date,
                    userId: payload.userId,
                    dateInterest: payload.dateInterest,
                },
            }),
        }),
        validTransactionFromSubscription: builder.mutation<
            void,
            ValidTransationSubscriptionRequest
        >({
            query: (payload) => ({
                url: `user/validTransactionSubscription/${payload.transactionId}`,
                method: 'PUT',
                params: {
                    transactionId: payload.transactionId,
                    date: payload.date,
                    dateInterest: payload.dateInterest,
                },
            }),
        }),
        getTransactionSponsorId: builder.query<
            {
                sponsorId: number | false;
            },
            number
        >({
            query: (id) => `/user/getTransactionUserSponsorId/${id}`,
        }),
        getRecapSponsorshipUser: builder.query<IRecapSponsorShip, number>({
            query: (id) => `/user/recapSponsorShip/${id}`,
        }),
        getTransactionById: builder.query<TransactionDto, number>({
            query: (id) => `/transactionId/${id}`,
            providesTags: ['Transaction'],
        }),
        getTransactionSponsorById: builder.query<TransactionSponsorDto, number>(
            {
                query: (id) => `/sponsor/transactionId/${id}`,
                providesTags: ['Transaction'],
            }
        ),
        getTransactionsTable: builder.query<
            ViewTransactionsDto,
            GetTransactionTable
        >({
            query: (payload) => ({
                url: `/table`,
                method: 'GET',
                params: payload,
            }),
            providesTags: ['Transaction'],
        }),
        getTransactionsSponsor: builder.query<
            ViewTransactionsSponsorDto,
            GetTransactionSponsorRefund
        >({
            query: (payload) => ({
                url: `/tableSponsor`,
                method: 'GET',
                params: payload,
            }),
            providesTags: ['Transaction'],
        }),
        addTransaction: builder.mutation<TransactionDto, CreateTransactionDto>({
            query: (payload) => ({
                url: `/`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Transaction', 'Project'],
        }),
        addTransactionSponsor: builder.mutation<
            TransactionDto,
            CreateTransactionSponsorDto
        >({
            query: (payload) => ({
                url: `/sponsor`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Transaction', 'Project'],
        }),
        deleteTransaction: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Transaction', 'Project'],
        }),
        deleteTransactionSponsor: builder.mutation<void, number>({
            query: (id) => ({
                url: `/sponsor/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Transaction', 'Project'],
        }),
        editTransactionSponsor: builder.mutation<
            TransactionDto,
            UpdateTransactionSponsorDto
        >({
            query: (payload) => ({
                url: `/sponsor/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['Transaction', 'Project'],
        }),
        // Share D
        manageShareD: builder.query<
            ManageTransactionOutShareDResponse,
            ManageTransactionOutShareDRequest
        >({
            query: (payload) => ({
                url: `/shareD/transactions`,
                method: 'GET',
                params: payload,
            }),
            providesTags: ['TransactionShareD'],
        }),
        futureTransaction: builder.query<
            GetFutureTransactionOutShareDResponse,
            GetFutureTransactionOutShareDRequest
        >({
            query: (payload) => ({
                url: `/shareD/future`,
                method: 'GET',
                params: payload,
            }),
        }),
        editTransaction: builder.mutation<TransactionDto, UpdateTransactionDto>(
            {
                query: (payload) => ({
                    url: `/${payload.id}`,
                    method: 'PUT',
                    body: payload,
                }),
                async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                    try {
                        const { data: updatedPost } = await queryFulfilled;
                        const patchResult = dispatch(
                            transactionsApi.util.updateQueryData(
                                'getTransactions',
                                undefined,
                                (draft) => {
                                    return draft.map((platform) => {
                                        if (platform.id === updatedPost.id) {
                                            return {
                                                ...platform,
                                                ...updatedPost,
                                            };
                                        } else return platform;
                                    });
                                }
                            )
                        );
                    } catch (err) {
                        console.log(err);
                    }
                },
                invalidatesTags: [
                    'Transaction',
                    'Project',
                    'TransactionShareD',
                ],
            }
        ),
        getTransactionDocuments: builder.query<
            DocumentTransactionDto[],
            number
        >({
            query: (id) => `/documents/${id}`,
            providesTags: ['Documents'],
        }),
        deleteTransactionDocument: builder.mutation<void, DeleteDocument>({
            query: (payload) => ({
                url: `/documents/${payload.documentId}`,
                method: 'DELETE',
            }),
            async onQueryStarted(payload, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        transactionsApi.util.updateQueryData(
                            'getTransactionDocuments',
                            payload.tableId,
                            (draft) => {
                                return draft.filter(
                                    (Platform) =>
                                        Platform.id !== payload.documentId
                                );
                            }
                        )
                    );
                } catch (err) {
                    console.log(err);
                }
            },
            invalidatesTags: ['Documents'],
        }),
        // Project Transactions
        getProjectTransactions: builder.query<TransactionDto[], number>({
            query: (projectId) => `/project/${projectId}`,
            providesTags: ['TransactionProject'],
            transformResponse: (response: TransactionDto[]) => {
                return response.map((r) => {
                    return {
                        ...r,
                        date: new Date(r.date),
                    };
                });
            },
        }),
        updateTransactionProject: builder.mutation<
            void,
            UpdateTransactionProjectDto
        >({
            query: (payload) => ({
                url: `/project/${payload.projectId}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['TransactionProject'],
        }),
        createCustomTransactionProjectInComing: builder.mutation<
            void,
            CreateCustomTransactionProjectInComing
        >({
            query: (payload) => ({
                url: `/project/${payload.projectId}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['TransactionProject'],
        }),
        getLiquidityFutureAdmin: builder.query<
            LiquidityFutureDto[],
            GetLiquidityFuture
        >({
            query: (payload) => ({
                url: `fonds/liquidityFutureAdmin`,
                method: 'GET',
                params: payload,
            }),
            providesTags: ['TransactionProject', 'Transaction'],
        }),
        getLiquidityFutureUser: builder.query<LiquidityFutureDto[], number>({
            query: (fondsId) => ({
                url: `fonds/liquidityFutureUser`,
                method: 'GET',
                params: {
                    fondsId: fondsId,
                },
            }),
            providesTags: ['TransactionProject', 'Transaction'],
        }),
    }),
});

export const {
    useGetProjectTransactionsQuery,
    useGetTransactionByIdQuery,
    useGetTransactionsQuery,
    useGetTransactionsUserQuery,
    useAddTransactionMutation,
    useGetTransactionsTableQuery,
    useDeleteTransactionMutation,
    useEditTransactionMutation,
    useGetTransactionDocumentsQuery,
    useDeleteTransactionDocumentMutation,
    useCreateCustomTransactionProjectInComingMutation,
    useUpdateTransactionProjectMutation,
    useGetTransactionSponsorIdQuery,
    useGetRecapSponsorshipUserQuery,
    useInvestInFundsByUserMutation,
    useGetLiquidityFutureAdminQuery,
    useGetLiquidityFutureUserQuery,
    useGetEntranceFeeForTransactionQuery,
    useGetTransactionsSponsorQuery,
    useAddTransactionSponsorMutation,
    useEditTransactionSponsorMutation,
    useDeleteTransactionSponsorMutation,
    useGetTransactionSponsorByIdQuery,
    useValidSubscriptionMutation,
    useTransactionDoneByUserMutation,
    useTransactionReceivedMutation,
    useManageShareDQuery,
    useFutureTransactionQuery,
    useValidTransactionFromSubscriptionMutation,
} = transactionsApi;

export async function uploadDocumentTransaction(
    request: UploadDocumentTransactionDto
) {
    try {
        const response = await axios({
            method: 'get',
            url: `${import.meta.env.VITE_API_URL}transactions/documents/url/put`,
            params: {
                fileType: encodeURIComponent(request.file.type),
                transactionId: request.transactionId,
            },
            withCredentials: true,
        });
        const { uploadUrl, key } = response.data as any;

        const res = await axios.put(uploadUrl, request.file);
        const postDoc = await axios({
            method: 'post',
            url: `${import.meta.env.VITE_API_URL}transactions/documents/post`,
            data: {
                transactionId: request.transactionId,
                fileName: encodeURIComponent(request.fileName),
                // type: request.type,
                comment: request.comment,
                key: key,
            },
            withCredentials: true,
        });

        return {
            message: 'Document uploaded',
            sucess: true,
        };
    } catch (error: any) {
        // Handle error
        console.log(error);
        return {
            message: error.response.data,
            sucess: false,
        };
    }
}
