import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { formatDate, isDateBetween, isDateClosed } from "../../function/Utils";
import { CashDto, CreateProjectsValorisationDto, GetValorisationsProjectRequest, ProjectValorisationDto, UpdateProjectsValorisationDto } from "../../types/projectValorisation";
import { baseQueryHandleError } from '../index.rtk';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}projectValorisations`,
    credentials: 'include', // Use 'include' to include cookies in the request
});

export const projectValorisationsApi = createApi({
    reducerPath: 'projectValorisationsApi',
    baseQuery: baseQueryHandleError(baseQuery),
    tagTypes: ["ProjectValo"],
    endpoints: (builder) => ({
        // addProjectValorisation: builder.mutation<ProjectValorisationDto, CreateProjectsValorisationDto>({
        //     query: (payload) => ({
        //         url: `/`,
        //         method: 'POST',
        //         body: payload,
        //     }),
        // }),
        // deleteProjectValorisation: builder.mutation<void, number>({
        //     query: (id) => ({
        //         url: `/${id}`,
        //         method: 'DELETE',
        //     }),
        // }),
        editProjectValorisation: builder.mutation<ProjectValorisationDto, UpdateProjectsValorisationDto>({
            query: (payload) => ({
                url: `/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ["ProjectValo"]

        }),
        getProjectValorisationByProjectId: builder.query<ProjectValorisationDto[], GetValorisationsProjectRequest>({
            query: (request) => ({
                url: `/project/${request.projectId}`,
                method: 'GET',
                params: {
                    date: request?.date
                }
            }),
            providesTags: ["ProjectValo"]
        }),
        getProjectLastComputedValorisationByProjectId: builder.query<ProjectValorisationDto, number>({
            query: (id) => `/project/lastComputed/${id}`,
            providesTags: ["ProjectValo"]
        }),
        getProjectTriByProjectId: builder.query<number, number>({
            query: (id) => `/project/tri/${id}`,
            providesTags: ["ProjectValo"],
            transformResponse: (response: { tri: number }) => response.tri

        }),
        deleteProjectsValorisationByProjectId: builder.mutation<void, number>({
            query: (id) => ({
                url: `/project/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["ProjectValo"],
        }),
        deleteProjectsValorisationById: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["ProjectValo"],
        }),
        deleteProjectsValorisationByDate: builder.mutation<void, string>({
            query: (date) => ({
                url: `/date/${date}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["ProjectValo"]

        }),
    })
})

export const { useDeleteProjectsValorisationByIdMutation, useGetProjectTriByProjectIdQuery, useGetProjectLastComputedValorisationByProjectIdQuery, useEditProjectValorisationMutation, useGetProjectValorisationByProjectIdQuery, useDeleteProjectsValorisationByProjectIdMutation, useDeleteProjectsValorisationByDateMutation } = projectValorisationsApi;

