import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    uploadDocumentFonds,
    useGetFondsByIdQuery,
    useGetFondsDocumentsQuery,
} from '../../../redux/features/fondsSlice';
import {
    CategoryDocumentFonds,
    DocumentFondsDto,
    FondsDto,
    TypeDocumentFonds,
    UploadDocumentFondsDto,
} from '../../../types/fonds';
import { useNotificationContext } from '../../Context/notification-context';
import { PrimaryButton, WhiteButton } from '../../commun/Buttons';
import DisplayDocumentFonds from '../../commun/DisplayDocuments/DisplayDocumentFonds';
import { DropDownComponentAdmin } from '../../commun/DropDownComponent';
import Loading from '../../commun/Loading';
import PopUp from '../../commun/PopUp';
import TitleComponentForm from '../../commun/formComponent/LayoutComponents/TitleComponentForm';
import SelectComponent from '../../commun/formComponent/SelectComponent';
import UploaderSingle from '../../commun/formComponent/UploaderSingle';
interface DocumentFondsForm {
    documentCover: File[];
    documentOther: {
        file: File;
        type: TypeDocumentFonds;
        category?: CategoryDocumentFonds;
    }[];
}

export default function DocumentFonds({ fondsId }: { fondsId: number }) {
    const { showError, showSuccess } = useNotificationContext();

    const {
        data: documents,
        isLoading: loadingDocuments,
        refetch: refetchDocuments,
    } = useGetFondsDocumentsQuery(fondsId ?? 0, {
        skip: fondsId === undefined,
    });

    const { data: fonds, isLoading: isLoadingFonds } = useGetFondsByIdQuery(
        fondsId ?? 0,
        {
            skip: fondsId === undefined,
        }
    );

    useEffect(() => {
        refetchDocuments();
    }, []);

    const documentCover = documents?.find(
        (document) => document.type === TypeDocumentFonds.cover
    );

    const documentOther = documents?.filter(
        (document) => document.type === TypeDocumentFonds.other
    );

    const {
        register,
        watch,
        setValue,
        reset,
        control,
        getValues,
        handleSubmit,
    } = useForm<DocumentFondsForm>({
        defaultValues: {
            documentCover: [],
            documentOther: [],
        },
    });

    // get all the unique categories of documents
    const categories = documents
        ?.map((doc) =>
            doc.category ? doc.category : CategoryDocumentFonds.other
        )
        .filter((value, index, self) => self.indexOf(value) === index) || [
        CategoryDocumentFonds.other,
    ];

    const [loading, setLoading] = useState(false);

    const onSubmit = async (dataForm: DocumentFondsForm) => {
        setLoading((curr) => !curr);
        const coverImg = dataForm.documentCover?.[0];
        const fileOther = dataForm.documentOther;

        if (coverImg) {
            let data: UploadDocumentFondsDto = {
                fondsId: fondsId!,
                type: TypeDocumentFonds.cover,
                file: coverImg,
                fileName: coverImg.name,
                fondsName: fonds?.name!,
            };

            await uploadDocumentFonds(data)
                .then((res) => {
                    if (res.sucess) {
                        showSuccess('Created', 'File uploaded');
                    } else {
                        showError(
                            'Error',
                            `Error uploading file ${data.fileName}: ${res.message}`
                        );
                    }
                })
                .catch((res) => {
                    console.log(res);

                    showError('Error', 'Error uploading file');
                });
        }

        if (fileOther.length > 0) {
            fileOther.forEach(async (file) => {
                let data: UploadDocumentFondsDto = {
                    fondsId: fondsId!,
                    type: file.type,
                    file: file.file,
                    category: file.category,
                    fileName: file.file.name,
                    fondsName: fonds?.name!,
                };

                await uploadDocumentFonds(data)
                    .then((res) => {
                        if (res.sucess) {
                            showSuccess('Created', 'File uploaded');
                        } else {
                            showError(
                                'Error',
                                `Error uploading file ${data.fileName}: ${res.message}`
                            );
                        }
                    })
                    .catch((res) => {
                        console.log(res);
                        showError('Error', 'Error uploading file');
                    });
            });
        }
        await new Promise((r) => setTimeout(r, 1000));
        await refetchDocuments();
        reset();
        setLoading((curr) => !curr);
    };

    const [openAddDocument, setOpenAddDocument] = useState(false);

    return (
        <div className="">
            <div className="flex justify-center items-center w-full my-3">
                <PrimaryButton onClick={() => setOpenAddDocument(true)}>
                    Ajouter un document
                </PrimaryButton>
            </div>
            {openAddDocument ? (
                <AddDocument
                    open={openAddDocument}
                    setOpen={setOpenAddDocument}
                    fonds={fonds!}
                />
            ) : null}

            {loadingDocuments ? (
                <Loading />
            ) : (
                <>
                    <form onSubmit={handleSubmit(onSubmit)} className="">
                        <TitleComponentForm>Image du fonds</TitleComponentForm>
                        <UploaderSingle
                            register={register}
                            value={'documentCover'}
                            watch={watch}
                            setValue={setValue}
                            typeFile={['png', 'jpg', 'jpeg']}
                            button={false}
                        ></UploaderSingle>
                        <div className="w-full md:w-10:12">
                            <div className="w-9/12 mx-auto gap-2 flex justify-center items-center flex-col">
                                {loadingDocuments ? (
                                    <Loading />
                                ) : (
                                    <>
                                        {documentCover !== undefined ? (
                                            <DisplayDocumentFonds
                                                refetch={refetchDocuments}
                                                document={documentCover!}
                                                key={1}
                                            />
                                        ) : (
                                            <p>Aucune photo n'a été trouvé</p>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="w-10/12 mx-auto flex justify-center items-center my-3">
                                <PrimaryButton type="submit" loading={loading}>
                                    Ajouter
                                </PrimaryButton>
                            </div>
                        </div>
                    </form>

                    {categories.map((category, index) => (
                        <DisplayEditDocument
                            key={index}
                            refetch={refetchDocuments}
                            title={category}
                            subTitle={category}
                            documents={documents?.filter(
                                (doc) =>
                                    doc.category === category ||
                                    (!doc.category &&
                                        category ===
                                            CategoryDocumentFonds.other &&
                                        doc.type !== TypeDocumentFonds.cover &&
                                        doc.type !== TypeDocumentFonds.rib)
                            )}
                            loadingDocuments={loadingDocuments}
                        />
                    ))}
                </>
            )}
        </div>
    );
}

function DisplayEditDocument({
    title,
    subTitle,
    documents,
    refetch,
    loadingDocuments,
}: {
    title: string;
    subTitle: string;
    documents?: DocumentFondsDto[];
    loadingDocuments: boolean;
    refetch: any;
}) {
    const [dropdownbool, setDropdownbool] = useState(false);
    return (
        <DropDownComponentAdmin
            title={`Documents ${title}`}
            bool={dropdownbool}
            setBool={setDropdownbool}
        >
            <div className="w-full md:w-10:12">
                {/* <h3 className="text-xl font-semibold mb-3 items-center text-center">
                        {subTitle}
                      </h3> */}
                <div className="w-full sm:w-10/12  mx-auto gap-2 flex justify-center items-center flex-col">
                    {loadingDocuments ? (
                        <Loading />
                    ) : (
                        <>
                            {documents && documents?.length! > 0 ? (
                                documents.map((doc, index) => (
                                    <DisplayDocumentFonds
                                        refetch={refetch}
                                        document={doc}
                                        key={index}
                                    />
                                ))
                            ) : (
                                <p>Aucun document n'a été trouvé</p>
                            )}
                        </>
                    )}
                </div>
            </div>
        </DropDownComponentAdmin>
    );
}

interface CreateDocument {
    file: File[];
    type: TypeDocumentFonds;
    category?: CategoryDocumentFonds;
}

function AddDocument({
    open,
    setOpen,
    fonds,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    fonds: FondsDto;
}) {
    const {
        register,
        watch,
        getValues,
        setValue,
        reset,
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm<CreateDocument>({
        defaultValues: {
            type: TypeDocumentFonds.other,
            category: CategoryDocumentFonds.other,
        },
    });

    const {
        data: documents,
        isLoading: loadingDocuments,
        refetch: refetchDocuments,
    } = useGetFondsDocumentsQuery(fonds.id ?? 0, {
        skip: fonds.id === undefined,
    });

    const { showError, showSuccess } = useNotificationContext();
    const [isLoadingUpload, setIsLoadingUpload] = React.useState(false);

    const onSubmit = async (dataForm: CreateDocument) => {
        setIsLoadingUpload((curr) => !curr);
        let file = dataForm.file[0];

        let data: UploadDocumentFondsDto = {
            fondsId: fonds.id,
            type: dataForm.type,
            file: file,
            category: dataForm.category,
            fileName: file.name,
            fondsName: fonds.name,
        };

        await uploadDocumentFonds(data)
            .then(async (res) => {
                if (res.sucess) {
                    showSuccess('Created', 'File uploaded');
                    await refetchDocuments();
                } else {
                    showError(
                        'Error',
                        `Error uploading file ${data.fileName}: ${res.message}`
                    );
                }
            })
            .catch((res) => {
                console.log(res);
                showError('Error', 'Error uploading file');
            });
        setIsLoadingUpload((curr) => !curr);
    };

    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            buttonBoolean={false}
            width="w-full md:w-9/12 min-h-[500px]"
            title={() => {
                return (
                    <>
                        <h3 className="text-2xl font-semibold leading-6 text-gray-900">
                            Ajouter un document
                        </h3>
                    </>
                );
            }}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-3"
            >
                <div className="flex flex-col gap-3 justify-center items-center w-full h-full">
                    <div
                        className={`mx-auto w-11/12 md:w-2/3 flex justify-start items-start flex-col 
              
              `}
                        // ${
                        //   !watch("name") ? "mt-[-30%]" : ""
                        // }
                    >
                        <SelectComponent
                            register={register}
                            control={control}
                            value={'type'}
                            getValues={getValues}
                            setValue={setValue}
                            optionValues={Object.values(TypeDocumentFonds).map(
                                (v) => {
                                    return {
                                        label: v,
                                        value: v,
                                    };
                                }
                            )}
                        >
                            <h3 className=" font-semibold">
                                Choisisser un type de document{' '}
                                <span className="required">*</span>
                            </h3>
                        </SelectComponent>

                        <SelectComponent
                            register={register}
                            control={control}
                            value={'category'}
                            getValues={getValues}
                            setValue={setValue}
                            optionValues={Object.values(
                                CategoryDocumentFonds
                            ).map((v) => {
                                return {
                                    label: v,
                                    value: v,
                                };
                            })}
                        >
                            <h3 className=" font-semibold">
                                Choisisser une catégorie
                                <span className="required">*</span>
                            </h3>
                        </SelectComponent>
                    </div>
                </div>
                {watch('type') ? (
                    <>
                        <UploaderSingle
                            register={register}
                            value={'file'}
                            watch={watch}
                            setValue={setValue}
                            loading={isLoadingUpload}
                            button={false}
                        ></UploaderSingle>

                        <div className="flex items-center gap-2 justify-center mt-10 mb-4 ">
                            <WhiteButton onClick={() => setOpen(false)}>
                                Cancel
                            </WhiteButton>
                            <PrimaryButton
                                type="submit"
                                loading={isLoadingUpload}
                                disabled={
                                    (watch('file') as any[])?.length > 0
                                        ? false
                                        : true
                                }
                            >
                                Ajouter
                            </PrimaryButton>
                        </div>
                    </>
                ) : null}
            </form>
        </PopUp>
    );
}
