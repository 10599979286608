import React, { useState } from "react";
import { IFondsInfoUser } from "../../../function/ComputationUserDashboard";
import { formatDate, printLargeValue } from "../../../function/Utils";
import { ChevronRightIcon } from "@heroicons/react/outline";

interface Props {
  fondsInfo: IFondsInfoUser;
  index1: number;
  dateSelected: Date;
}

const ElementTable: React.FC<Props> = ({ fondsInfo, index1, dateSelected }) => {
  const classNameObejct = {
    head: "text-sm text-gray-900 px-6 py-4 text-left font-semibold text-right",
    item: "text-sm font-light px-6 py-4 whitespace-nowrap text-right",
  };

  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <tr
        key={index1}
        className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 ${
          index1 % 2 === 0 ? "bg-gray-100" : "bg-white"
        } `}
        onClick={() => setExpanded(!expanded)}
      >
        {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
          {index1 + 1}
        </td> */}
        <td className={`${classNameObejct.item} flex gap-3`}>
          <ChevronRightIcon
            aria-hidden="true"
            className={`w-4 h-4  mx-auto transform transition ease-in-out duration-200 ${
              expanded ? "rotate-90" : "rotate-0"
            }`}
          ></ChevronRightIcon>{" "}
          {fondsInfo.fondsName}
        </td>
        <td className={classNameObejct.item}>
          {formatDate(fondsInfo.infoCombined.firstDate)}
        </td>

        <td className={classNameObejct.item}>
          {printLargeValue(fondsInfo.infoCombined.initialAmount)}
        </td>
        <td className={classNameObejct.item}>
          {printLargeValue(fondsInfo.infoCombined.nbShare.toFixed(2))} <br />
        </td>
        <td className={classNameObejct.item}>
          {fondsInfo.infoCombined.initialValueOfShare.toFixed(2)}
        </td>
        <td className={classNameObejct.item}>
          {printLargeValue(fondsInfo.infoCombined.share.toFixed(2))}
          <br />
        </td>
        <td className={classNameObejct.item}>
          {fondsInfo.infoCombined.performances.toFixed(2)} %
        </td>
        <td className={classNameObejct.item}>
          {fondsInfo.infoCombined.tri.toFixed(2)} %
        </td>
        <td className={classNameObejct.item}>
          {printLargeValue(
            (
              fondsInfo.infoCombined.share * fondsInfo.infoCombined.nbShare
            ).toFixed(2)
          )}
        </td>
        <td className={classNameObejct.item}>{formatDate(dateSelected)}</td>
      </tr>
      {expanded ? (
        <>
          {fondsInfo.transactions.map((t, index) => (
            <tr
              key={index}
              className={`border-b cursor-pointer hover:bg-slate-500 hover:opacity-80 bg-gray-300 `}
            >
              {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
                {index + 1}
              </td> */}
              <td className={classNameObejct.item}>Transaction {index + 1}</td>
              <td className={classNameObejct.item}>
                {formatDate(t.dateStartInvestisseur)}
              </td>
              <td className={classNameObejct.item}>
                {printLargeValue(t.initialAmount)}
              </td>
              <td className={classNameObejct.item}>
                {printLargeValue(t.nbShare.toFixed(2))} <br />
              </td>
              <td className={classNameObejct.item}>
                {t.initialValueOfShare.toFixed(2)}
              </td>
              <td className={classNameObejct.item}>
                {printLargeValue(t.share.toFixed(2))}
                <br />
              </td>
              <td className={classNameObejct.item}>
                {t.performance.toFixed(2)} %
              </td>
              <td className={classNameObejct.item}>{t.tri.toFixed(2)} %</td>
              <td className={classNameObejct.item}>
                {printLargeValue((t.share * t.nbShare).toFixed(2))}
              </td>
              <td className={classNameObejct.item}>
                {formatDate(dateSelected)}
              </td>
            </tr>
          ))}
        </>
      ) : null}
    </>
  );
};

export default ElementTable;
